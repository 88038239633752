import { array, date, ord } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { z } from 'zod';
import { DateFromIsoString } from '~/common/utils';
import { InfiniteMetadata, UploadedFile } from '~/root/domain';
export const Message = z.object({
    content: z.string(),
    date: DateFromIsoString,
    files: z.array(UploadedFile).transform((files) => (array.isNonEmpty(files) ? files : null)),
    id: z.number().int().positive(),
    is_note: z.boolean(),
    is_read: z.boolean(),
    is_email_reply: z.boolean(),
    sender: z.object({
        is_customer: z.boolean(),
        id: z.number().int().positive(),
        name: z.string(),
        initials: z.string(),
        avatar: UploadedFile.nullable(),
    }),
});
export const ordMessage = pipe(date.Ord, ord.contramap((message) => message.date));
export const infiniteMessages = (message) => {
    return z.object({
        items: z
            .array(message)
            // TODO do we really need a map? a few lookups in arrays
            // to replace or append the message should be cheap
            .transform((messages) => new Map(messages.map((message) => [message.id, message]))),
        metadata: InfiniteMetadata,
    });
};
export const Messages = infiniteMessages(Message);
export const MessageRemoved = z
    .object({ id: z.number().int().positive() })
    .transform(({ id }) => id);
