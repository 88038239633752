import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faReceipt } from '@fortawesome/pro-regular-svg-icons';
import { Button, PageDetailsCard, useModalContext } from '~/common/components';
import { formatPhoneNumber, joinTruthy } from '~/common/utils';
import { useCustomerData, useJoinedCustomerData } from '~/customers/hooks';
import { useCurrentUserData } from '~/root';
import { Empty } from '../Empty';
import { EditBillingAddress } from './EditBillingAddress';
export const BillingAddress = () => {
    const customer = useCustomerData();
    const { permissions } = useCurrentUserData();
    const { modalOpener } = useModalContext();
    const { billingPhoneDialCode, country } = useJoinedCustomerData();
    return (_jsx(PageDetailsCard, { label: "Billing address", actionButton: permissions.customersUpdate && (_jsx(Button, { onClick: modalOpener(EditBillingAddress), color: "grey", children: "Edit" })), bodyClassName: "font-brand-b1 py-3", children: customer.billing_address ? (_jsxs("div", { className: "grid grid-cols-[100px,1fr] gap-x-1 gap-y-2", children: [_jsx(BillingAddressRow, { label: "Company", value: customer.billing_address.company }), _jsx(BillingAddressRow, { label: "Address", value: joinTruthy([
                        customer.billing_address.address,
                        customer.billing_address.city,
                        customer.billing_address.zip_code,
                        country === null || country === void 0 ? void 0 : country.name,
                    ], ', ') }), _jsx(BillingAddressRow, { label: "VAT", value: customer.billing_address.vat }), _jsx(BillingAddressRow, { label: "PO", value: customer.billing_address.po_number }), _jsx(BillingAddressRow, { label: "Phone", value: customer.billing_address.phone_number &&
                        formatPhoneNumber(billingPhoneDialCode, customer.billing_address.phone_number.number) })] })) : (_jsx(Empty, { icon: faReceipt, title: "No billing address yet" })) }));
};
const BillingAddressRow = ({ label, value }) => (_jsxs(_Fragment, { children: [_jsx("span", { className: "text-greyscale-500 font-brand-t4m", children: label }), _jsx("span", { className: "truncate", children: value || '—' })] }));
