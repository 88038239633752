import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { cx } from '~/common/utils';
export const SubscriptionChangesTable = ({ data }) => {
    return (_jsx("div", { className: "space-y-2", children: Object.entries(data)
            .filter(([_, changes]) => changes.length)
            .map(([title, changes]) => (_jsxs("div", { className: "grid gap-x-2 gap-y-1 grid-cols-3 py-1 px-2 justify-center items-center min-w-[400px] border border-solid border-greyscale-200 rounded-lg", children: [_jsx(IndividualPricingTableHead, { title: title }), changes.map((row) => (_jsx(IndividualPricingTableRow, { row: row }, row.name)))] }, title))) }));
};
const headerCellClassnames = 'bg-greyscale-100 rounded text-greyscale-500 font-brand-t5 h-4 flex justify-center items-center';
const IndividualPricingTableHead = ({ title }) => (_jsxs(_Fragment, { children: [_jsx("span", { className: "capitalize font-brand-t4m", children: title }), _jsx("div", { className: headerCellClassnames, children: "Old" }), _jsx("div", { className: headerCellClassnames, children: "New" })] }));
const cellClassnames = 'flex justify-around items-center font-brand-t5 border-b border-greyscale-200 p-1 h-full text-center';
export const IndividualPricingTableRow = ({ row: { after, before, name }, }) => (_jsxs(_Fragment, { children: [_jsx("span", { className: "text-greyscale-500 font-brand-t5 border-b border-greyscale-200 p-1", children: name }), _jsx("div", { className: cx(cellClassnames, 'min-w-10 font-brand-b2r'), children: before !== null && before !== void 0 ? before : '-' }), _jsx("div", { className: cx(cellClassnames, 'min-w-10 font-brand-b2r'), children: after !== null && after !== void 0 ? after : '-' })] }));
