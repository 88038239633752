import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useRef } from 'react';
import { Button } from '~/common/components';
import { formatDayMonthYear, formatMoney, pluralizeWord } from '~/common/utils';
import { useInvoicePreviewMutation } from '~/customers/hooks';
import { SubscriptionSection } from './SubscriptionSection';
export const Summary = ({ price, ownerId, billing_date, invoicePreviewData, proratedProduct, }) => {
    const getInvoicePreviewMutation = useInvoicePreviewMutation();
    const invoiceUrl = useRef(null);
    useEffect(() => {
        invoiceUrl.current = null;
    }, [invoicePreviewData]);
    const openInvoicePreview = async () => {
        if (!invoicePreviewData) {
            return;
        }
        if (invoiceUrl.current) {
            window.open(invoiceUrl.current, '_blank');
            return;
        }
        getInvoicePreviewMutation
            .mutateAsync({ data: invoicePreviewData, id: ownerId })
            .then(({ invoice_url }) => {
            invoiceUrl.current = invoice_url;
            window.open(invoice_url, '_blank');
        });
    };
    return (_jsx(SubscriptionSection, { title: "Summary", className: "border-none", children: _jsxs("div", { className: "w-[452px] ml-auto", children: [_jsxs("div", { className: "space-y-2", children: [_jsx(SummaryRow, { title: "Plan price", value: (price === null || price === void 0 ? void 0 : price.unit) && price.currency ? formatMoney(price.unit, price.currency) : undefined }), !!(price === null || price === void 0 ? void 0 : price.remaining_billing_cycle_days) && !!price.unit_per_day && (_jsxs("div", { children: [_jsx(SummaryRow, { title: "Prorated amount", value: (price === null || price === void 0 ? void 0 : price.remaining_billing_cycle_days) && price.unit_per_day && price.currency
                                        ? formatMoney(price.remaining_billing_cycle_days * price.unit_per_day, price.currency)
                                        : undefined }), _jsxs("div", { className: "flex justify-between font-brand-b1 text-greyscale-500 mt-[2px]", children: [_jsx("span", { className: "capitalize", children: proratedProduct }), _jsxs("span", { children: [pluralizeWord('day', price.remaining_billing_cycle_days), " *", ' ', formatMoney(price.unit_per_day, price.currency)] })] })] })), _jsx(SummaryRow, { title: "Discount", value: (price === null || price === void 0 ? void 0 : price.discount) && price.currency
                                ? formatMoney(price.discount, price.currency)
                                : undefined }), _jsx(SummaryRow, { title: 'VAT' + ((price === null || price === void 0 ? void 0 : price.vat_percentage) ? ` (+${price.vat_percentage}%)` : ''), value: (price === null || price === void 0 ? void 0 : price.vat_unit) && price.currency
                                ? `+ ${formatMoney(price.vat_unit, price.currency)}`
                                : undefined }), _jsx(SummaryRow, { title: "Total to pay", value: (price === null || price === void 0 ? void 0 : price.amount) && price.currency
                                ? formatMoney(price.amount, price.currency)
                                : undefined })] }), billing_date && (_jsxs("p", { className: "text-greyscale-500 font-brand-b1", children: ["Will be billed on", ' ', _jsx("span", { className: "text-greyscale-600", children: formatDayMonthYear(billing_date) })] })), price && invoicePreviewData && (_jsxs(_Fragment, { children: [_jsx("p", { className: "font-brand-b1 text-greyscale-500 mt-3 mb-1", children: "Check the invoice preview before sending it to the customer" }), _jsx(Button, { color: "text-secondary", size: "xs", icon: faEye, loading: getInvoicePreviewMutation.isLoading, onClick: openInvoicePreview, disabled: true, "data-tt": "Will be available soon", children: "Invoice preview" })] }))] }) }));
};
const SummaryRow = ({ title, value }) => (_jsxs("div", { className: "flex justify-between font-brand-t2m", children: [_jsx("span", { className: " text-greyscale-500", children: title }), _jsx("span", { className: "text-right", children: value || '—' })] }));
