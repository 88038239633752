import { number } from 'fp-ts';
import { z } from 'zod';
import { unsafeRefine } from './common';
export function isNonNegativeNumber(n) {
    return n >= 0;
}
export const nonNegativeNumber = unsafeRefine(isNonNegativeNumber);
export const NonNegativeNumber = z.number().refine(isNonNegativeNumber);
export function isPositiveNumber(n) {
    return n > 0;
}
export const positiveNumber = unsafeRefine(isPositiveNumber);
export const PositiveNumber = z.number().refine(isPositiveNumber);
export function isInteger(n) {
    return Number.isInteger(n);
}
export const integer = unsafeRefine(isInteger);
export const Integer = z.number().refine(isInteger);
export function isNonNegativeInteger(n) {
    return isNonNegativeNumber(n) && isInteger(n);
}
export const nonNegativeInteger = unsafeRefine(isNonNegativeInteger);
export const NonNegativeInteger = z.number().refine(isNonNegativeInteger);
export function isPositiveInteger(n) {
    return isPositiveNumber(n) && isInteger(n);
}
export const positiveInteger = unsafeRefine(isPositiveInteger);
export const PositiveInteger = z.number().refine(isPositiveInteger);
export const eqPositiveInteger = number.Eq;
export const ordPositiveInteger = number.Ord;
