import { useEffect, useState } from 'react';
import { useEvent } from './useEvent';
export const useTickState = (callback, interval = 60) => {
    const [value, setValue] = useState(callback);
    const stableCallback = useEvent(callback);
    useEffect(() => {
        const intervalInstance = setInterval(() => {
            setValue(stableCallback);
        }, interval * 1000);
        return () => clearInterval(intervalInstance);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return [value, setValue];
};
