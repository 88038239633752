import { decodeOrNull } from './decoder';
// TODO this feels bad, API should be better, decoding safety should be everywhere
export const setLocalStorage = (key, value) => {
    try {
        window.localStorage.setItem(key, JSON.stringify(value));
    }
    catch (error) {
        console.error(error);
    }
};
export const getLocalStorage = (key, fallback, decoder) => {
    var _a, _b;
    try {
        const parsed = JSON.parse((_a = window.localStorage.getItem(key)) !== null && _a !== void 0 ? _a : 'null');
        return (_b = (decoder ? decodeOrNull(parsed, decoder) : parsed)) !== null && _b !== void 0 ? _b : fallback;
    }
    catch (error) {
        console.error(error);
        return fallback;
    }
};
export const updateLocalStorageRecord = (key, fallback, updateFn) => {
    const prev = getLocalStorage(key, fallback);
    setLocalStorage(key, updateFn(prev));
};
