import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { faCalendarDay, faExternalLink, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { Badge, Button, IconBox, Link, Tooltip, useModalContext } from '~/common/components';
import { formatDayMonthYear, pluralize } from '~/common/utils';
import { useCurrentUserData } from '~/root';
import { useSubscriptionData } from '~/subscriptions/hooks';
import { EditUpcomingEvent } from './EditUpcomingEvent';
import { SubscriptionChangesTable } from './SubscriptionChangesTable';
export const UpcomingEvents = () => {
    const { events } = useSubscriptionData();
    if (!(events === null || events === void 0 ? void 0 : events.length))
        return null;
    return (_jsxs("div", { className: "px-3 py-2", children: [_jsxs("h3", { className: "font-brand-t2m text-greyscale-500 mb-2", children: ["Upcoming event", pluralize(events.length)] }), _jsx("div", { className: "grid grid-cols-[auto_1fr_auto] gap-y-1 items-center", children: events.map((event) => event.type === 'scheduled_update' ? (_jsx(ScheduledEvent, { event: event }, event.type)) : (_jsx(SystemEvent, { event: event }, event.type))) })] }));
};
const SystemEvent = ({ event }) => (_jsx(UpcomingEvent, { title: "System:", event: event, children: event.invoice_preview_url && (_jsx(Link.Icon, { href: event.invoice_preview_url, newTab: true, icon: faExternalLink, className: "ml-1" })) }));
const ScheduledEvent = ({ event }) => (_jsx(UpcomingEvent, { title: "Scheduled:", event: event, children: _jsx(Tooltip, { className: "font-brand-c1m", content: _jsx(SubscriptionChangesTable, { data: event.changes }), placement: "top", children: _jsx(IconBox, { size: "s", className: "text-greyscale-500 ml-[2px] cursor-help", icon: faInfoCircle }) }) }));
const UpcomingEvent = ({ event, title, children, }) => {
    const { permissions } = useCurrentUserData();
    const { modalOpener } = useModalContext();
    return (_jsxs(_Fragment, { children: [_jsx("span", { className: "font-brand-t4m text-greyscale-400 mr-2 shrink-0", children: title }), _jsxs("div", { className: "flex items-center min-w-0", children: [_jsxs(Badge, { color: "primary", className: "mr-1 shrink-0 gap-[4px]", children: [_jsx(IconBox, { size: "s", icon: faCalendarDay, className: "text-inherit" }), _jsx("span", { children: formatDayMonthYear(event.date) })] }), _jsxs("div", { className: "flex min-w-0 items-center max-w-full overflow-hidden", children: [_jsx("p", { className: "font-brand-b1 truncate", children: event.description }), children] })] }), permissions.subscriptionsUpdate ? (_jsx(Button, { color: "text-secondary", size: "xs", onClick: modalOpener(EditUpcomingEvent, { event }), children: "Edit" })) : (_jsx("div", {}))] }));
};
