import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useForm } from 'formoid';
import { Fragment } from 'react';
import { ModalHeader, ModalShell, Switcher } from '~/common/components';
import { propagateBackendValidationErrors, record } from '~/common/utils';
export const EditFeatureFlags = ({ onClose, formValues, items, mutation }) => {
    const { fieldProps, handleSubmit, setErrors, isSubmitting } = useForm({
        initialValues: formValues,
        validationStrategy: 'onBlur',
        validators: () => record.fromEntries(record.keys(formValues).map((key) => [key, null])),
    });
    const submit = () => handleSubmit((values) => {
        return mutation
            .mutateAsync(values)
            .then(onClose)
            .catch((error) => {
            propagateBackendValidationErrors(error, setErrors);
        });
    });
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Edit feature flags" }), _jsx(ModalShell, { className: "p-3 w-[608px]", onClose: onClose, onSubmit: submit, loading: isSubmitting, children: items.map((flag) => {
                    return (_jsxs(Fragment, { children: [_jsxs("div", { className: "flex items-center justify-between gap-4", children: [_jsxs("div", { children: [_jsx("p", { className: "font-brand-t4m text-greyscale-600", children: flag.label }), flag.description && (_jsx("p", { className: "font-brand-c1r text-greyscale-500", children: flag.description }))] }), _jsx(Switcher, { ...fieldProps(flag.id) })] }), _jsx("hr", { className: "text-greyscale-200 h-[1px] w-full my-3 last-of-type:hidden" })] }, flag.id));
                }) })] }));
};
