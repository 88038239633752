import { array } from 'fp-ts';
import { z } from 'zod';
import { LexicalEditorState } from '~/common/kits/editor';
import { DateFromIsoString, lastItem } from '~/common/utils';
import { InfiniteMetadata, UploadedFile } from '~/root/domain';
export const CollaborationMessage = z.object({
    id: z.number().int().positive(),
    order_id: z.number().int().positive(),
    date: DateFromIsoString,
    content: LexicalEditorState,
    edited_at: DateFromIsoString.nullable(),
    files: z
        .array(UploadedFile)
        .transform((files) => (array.isNonEmpty(files) ? files : null))
        .nullable()
        .catch(null),
    sender: z.object({
        id: z.number().int().positive(),
        name: z.string(),
        initials: z.string(),
        avatar: UploadedFile.nullable(),
    }),
});
export const CollaborationMessages = z
    .object({
    items: z.array(CollaborationMessage),
    metadata: InfiniteMetadata,
})
    .transform((page) => {
    var _a;
    return ({
        items: new Map(page.items.map((message) => [message.id, message])),
        metadata: {
            prev: null,
            next: (page.metadata.next && ((_a = lastItem(page.items)) === null || _a === void 0 ? void 0 : _a.id)) || null,
        },
    });
});
