import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faBriefcase, faLink, faUser } from '@fortawesome/pro-regular-svg-icons';
import { AvatarPane, Button, GeneralInfoHeader, IconBox, InfoBar, Link, useModalContext, } from '~/common/components';
import { UserCard } from '~/common/kits/usercard';
import { getColorsFromNumber } from '~/common/utils';
import { CUSTOMERS_URL } from '~/customers/hooks';
import { useOrganizationData } from '~/organizations/hooks';
import { useCurrentUserData } from '~/root';
import { EditOrganizationInfoPopup } from './EditOrganizationInfoPopup';
export const OrganizationInfo = () => {
    var _a;
    const { permissions } = useCurrentUserData();
    const organization = useOrganizationData();
    const { modalOpener } = useModalContext();
    const colorSet = getColorsFromNumber(organization.id);
    return (_jsxs("div", { className: "bg-greyscale-0 rounded", children: [_jsx(AvatarPane, { avatarLink: ((_a = organization.logo) === null || _a === void 0 ? void 0 : _a.link) || '', name: organization.name, colorSet: colorSet, avatarChildren: _jsx(IconBox, { size: "l", className: "text-inherit", icon: faBriefcase }), children: permissions.organizationsUpdate && (_jsx(Button, { color: "grey", onClick: modalOpener(EditOrganizationInfoPopup), children: "Edit" })) }), _jsxs("div", { className: "px-4", children: [_jsx(GeneralInfoHeader, { name: organization.name, created_at: organization.created_at, last_update: organization.updated_at }), _jsxs("ul", { className: "mt-1", children: [_jsx(InfoBar, { icon: faUser, text: organization.owner && (_jsx(UserCard, { id: organization.owner.id, children: _jsx(Link, { size: "m", to: `${CUSTOMERS_URL}/${organization.owner.id}`, children: organization.owner.name }) })), hint: "Owner" }), _jsx(InfoBar, { icon: faLink, text: organization.url && (_jsx(Link, { newTab: true, external: true, size: "m", href: organization.url, children: organization.url })), hint: "Company URL" })] })] })] }));
};
