import { useQuery } from '@tanstack/react-query';
import { useIdParam } from '~/common/hooks';
import { httpClient } from '~/common/utils';
import { GetBriefAnswers } from '~/orders/domain';
import { qk } from '~/root/query-keys';
export const usePreviousBriefDetails = () => {
    const id = useIdParam();
    return useQuery({
        queryKey: qk.orderPreviousBriefDetails(id),
        queryFn: () => httpClient.get(`v1/staff/orders/${id}/previous-order-answers`, { decoder: GetBriefAnswers }),
    });
};
