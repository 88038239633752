export const getColorByShiftRange = (range) => {
    const startTime = parseInt(range.split('-')[0]);
    if (startTime >= 7 && startTime <= 11) {
        return 'blue';
    }
    if (startTime >= 12 && startTime <= 15) {
        return 'green';
    }
    if (startTime >= 16 && startTime <= 20) {
        return 'yellow';
    }
    return 'violet';
};
