import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faEnvelope, faMessageLines, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { faFileCheck } from '@fortawesome/pro-solid-svg-icons';
import { useEffect, useMemo, useRef } from 'react';
import { tfIcon } from '~/assets/images';
import { Avatar, Badge, Button, IconBox, IconButton, LineBreaks, Link, ParseLinks, Show, } from '~/common/components';
import { useToggle } from '~/common/hooks';
import { UserCard } from '~/common/kits/usercard';
import { cx, formatDate, getColorsFromNumber } from '~/common/utils';
import { useConfirmDeletion, useIsVisible, useRemoveMessage, useShouldReadMessage, } from '~/orders/hooks';
import { ConfirmDeletion } from '~/orders/Order';
import { DownloadButton } from './DownloadButton';
import { MAX_FILES_BEFORE_COLLAPSE } from './Files';
const MIN_FILES_TO_SHOW_DOWNLOAD_ALL = 3;
export const MessageFiles = ({ files, commentId, }) => {
    const [isCollapsed, toggleCollapsed] = useToggle(files.length > MAX_FILES_BEFORE_COLLAPSE);
    const amountOfCollapsedFiles = files.length - MAX_FILES_BEFORE_COLLAPSE;
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "flex flex-col items-start space-y-1", children: (isCollapsed ? files.slice(0, 5) : files).map((file) => (_jsx(Link, { icon: faFileCheck, size: "m-bold", download: true, href: file.link, children: file.name }, file.id))) }), files.length >= MIN_FILES_TO_SHOW_DOWNLOAD_ALL && (_jsxs("div", { className: "flex gap-1", children: [files.length > MAX_FILES_BEFORE_COLLAPSE && (_jsx(Button, { color: "grey", size: "small", onClick: toggleCollapsed, children: isCollapsed ? `Show ${amountOfCollapsedFiles} more` : 'Show less' })), _jsx(DownloadButton, { commentId: commentId, children: "Download all" })] }))] }));
};
const NOTE_COLOR_SET_INDEX = 3;
const NoteAvatar = () => {
    const colorSet = getColorsFromNumber(NOTE_COLOR_SET_INDEX);
    colorSet.borderColor = colorSet.color;
    return (_jsx(Avatar, { colorSet: colorSet, children: _jsx(IconBox, { size: "s", className: "text-inherit", icon: faMessageLines }) }));
};
export const Message = ({ canRemove, message, ownerId, onRead }) => {
    var _a;
    const ref = useRef(null);
    const isVisible = useIsVisible(ref);
    useEffect(() => {
        if (isVisible)
            onRead();
    }, [isVisible, onRead]);
    const { handleCancel, handleMouseEnter, handleMouseLeave, handleRemove, showConfirmation, showRemoveButton, } = useConfirmDeletion(canRemove);
    const [removeMessage, isLoading] = useRemoveMessage();
    const shouldRead = useShouldReadMessage();
    const isUnread = useMemo(() => shouldRead(message), [message, shouldRead]);
    const isDeleteButtonAccessible = showRemoveButton && !showConfirmation;
    return (_jsxs(_Fragment, { children: [_jsx(Show, { if: message.is_note, children: _jsx("span", { className: "font-brand-c1r text-greyscale-500", children: "Customer does not see this message" }) }), _jsxs("div", { ref: ref, className: cx('space-y-1 px-1 py-[4px]', message.is_note
                    ? 'bg-warning-50 border border-warning-100 rounded p-1 mb-1'
                    : isUnread && 'bg-greyscale-100'), onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, children: [_jsxs("div", { className: "flex justify-between relative", children: [_jsxs("div", { className: "flex items-center gap-2 w-full", children: [message.is_note ? (_jsx(NoteAvatar, {})) : (_jsx(UserCard, { id: message.sender.id, name: message.sender.name, avatar_link: ((_a = message.sender.avatar) === null || _a === void 0 ? void 0 : _a.link) || null, 
                                        // TSLUL
                                        type: (message.sender.is_customer ? 'customer' : 'staff'), owner_id: ownerId })), _jsxs("div", { className: "flex flex-col w-full max-w-[calc(100%-48px)]", children: [_jsxs("div", { className: "flex items-center gap-[4px]", children: [_jsx("span", { className: "truncate font-brand-t5 text-greyscale-600", children: message.sender.name }), _jsx(Show, { if: !message.sender.is_customer, children: _jsx("img", { src: tfIcon, className: "w-[11px] h-[12px]" }) }), _jsx(Show, { if: message.is_email_reply, children: _jsxs("div", { className: "flex flex-none items-center h-2 gap-[4px] px-1 font-brand-c2m text-secondary-400 rounded-3xl bg-secondary-100", children: [_jsx(IconBox, { size: "s", className: "text-inherit", icon: faEnvelope }), _jsx("span", { className: "flex-none", children: "via email" })] }) }), _jsx(Show, { if: isUnread && !showRemoveButton && !showConfirmation, children: _jsx(Badge, { className: "flex-none ml-auto", size: "small", color: "primary", children: "New" }) }), _jsx(Show, { if: showConfirmation, children: _jsx(ConfirmDeletion, { isLoading: isLoading, onCancel: handleCancel, onDelete: () => removeMessage(message.id) }) })] }), _jsx("span", { className: "font-brand-c1r text-greyscale-500", children: formatDate(message.date) })] })] }), _jsx(Show, { if: isDeleteButtonAccessible, children: _jsx(IconButton, { size: "s", className: "absolute right-[4px] top-0 outline-none transition-brand text-inherit hover:text-danger-400 active:text-danger-400", icon: faTrashAlt, onClick: handleRemove }) })] }), _jsx(LineBreaks, { className: "font-brand-b1 text-greyscale-500 break-words", renderParagraph: (paragraph) => (_jsx(ParseLinks, { className: "flex flex-wrap font-brand-b1 text-greyscale-500 min-h-[22px] gap-x-[3px]", children: paragraph })), children: message.content }), message.files && _jsx(MessageFiles, { commentId: message.id, files: message.files })] })] }));
};
