export const getCSSTransitionClassNames = (styles) => {
    const validClassNames = {};
    const CSSTransitionClassNames = [
        'appear',
        'appearActive',
        'appearDone',
        'enter',
        'enterActive',
        'enterDone',
        'exit',
        'exitActive',
        'exitDone',
    ];
    for (const key in styles) {
        if (CSSTransitionClassNames.includes(key)) {
            validClassNames[key] = styles[key];
        }
    }
    return validClassNames;
};
