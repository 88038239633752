import { useMutation } from '@tanstack/react-query';
import { useIdParam } from '~/common/hooks';
import { httpClient } from '~/common/utils';
import { Revision } from '~/orders/domain';
import { qk } from '~/root/query-keys';
import { useUpdateOrderCache } from '../useUpdateOrderCache';
export function useCreateRevision() {
    const id = useIdParam();
    const update = useUpdateOrderCache();
    return useMutation({
        mutationKey: qk.addRevision(id),
        mutationFn: (data) => httpClient.post(`/v1/staff/orders/${id}/revisions`, {
            data,
            decoder: Revision,
        }),
        onSuccess: (revision) => {
            update((order) => ({
                ...order,
                revisions: [...order.revisions, revision],
            }));
        },
    });
}
