import { addMinutes, addMonths, addSeconds, isAfter } from 'date-fns';
import { validator, } from 'formoid';
import { array, nonEmptyArray, option, ord, record, string, tuple } from 'fp-ts';
import { tuple as makeTuple, pipe } from 'fp-ts/function';
import { customValidator, date, decodeBackendErrors, getDateWithoutTime, isNonNullable, } from '~/common/utils';
import { ordOrderStatus, } from '~/orders/domain';
/**
 * Order status
 */
export function isStatusAfter(status) {
    return (currentStatus) => ord.geq(ordOrderStatus)(currentStatus, status);
}
/**
 * CancellationReason
 */
export const otherReason = { name: 'Other', value: 'Other' };
export const appendOtherReason = (reasons) => pipe(reasons, array.map((reason) => ({ name: reason, value: reason })), array.appendW(otherReason));
export const isOtherReason = (reason) => reason === otherReason.value;
export const getInitialCancellationReason = (reasons, cancellationReason) => pipe(cancellationReason, option.fromNullable, option.chain((reason) => pipe(reasons, array.findFirst((value) => value === reason), option.map((cancellationReason) => ({ cancellationReason, customReason: null })), option.altW(() => option.some({
    cancellationReason: otherReason.value,
    customReason: reason,
})))), option.getOrElseW(() => ({ cancellationReason: null, customReason: null })));
/**
 * Deadline
 */
export const isDeadlineExpired = (deadline) => isAfter(new Date(), deadline);
/**
 * Discount
 */
export function discountToString(discount, currency) {
    return `-${discount.value}${discount.type === 'percent' ? '%' : currency}`;
}
export function is100PercentBounty(bounty) {
    return bounty.type === 'percent' && bounty.value === 100;
}
/**
 * Timeline
 */
export function formatTimeInStatus(timeInStatus, currentDate) {
    return date.formatDateDifference(currentDate, addSeconds(currentDate, timeInStatus));
}
/**
 * Common validators
 */
export function customReasonValidator(reason) {
    return isOtherReason(reason)
        ? validator.sequence(customValidator.required(), customValidator.nonBlankString())
        : null;
}
export function tagTeamValidator(values) {
    return validator.sequence(customValidator.required(), validator.fromPredicate((value) => values.filter(({ team }) => isNonNullable(team) && team === value).length < 2, 'This team is already chosen, choose another one!'));
}
export function capacityStartDateValidator(orderCreated) {
    return validator.fromPredicate(
    // TODO timezone should this get the date of order.created based on UTC time ?
    (start) => ord.geq(ord.ordDate)(start, getDateWithoutTime(orderCreated)), 'Start date should be greater than or equal to order creation date');
}
export function capacityEndDateValidator(start) {
    return validator.parallel(validator.fromPredicate((end) => ord.geq(ord.ordDate)(end, start), 'End date should be greater than or equal to start date'), validator.fromPredicate((end) => {
        return ord.leq(ord.ordDate)(end, addMonths(start, 1));
    }, 'End date should less than or equal to start date + 1 month'));
}
/**
 * Data
 */
export function getTransitions(statuses, currentStatus) {
    return pipe(statuses, array.findFirst((status) => status.value === currentStatus), option.chainNullableK(({ transitions }) => transitions), option.chain((transitions) => pipe(transitions, nonEmptyArray.traverse(option.Applicative)((status) => pipe(statuses, array.findFirst(({ value }) => value === status))))), option.toNullable);
}
export function getSelectedManger(managers, pm) {
    return pipe(managers, array.findFirst(({ value }) => value === pm), option.toNullable);
}
export function getAssignedDesignTeam(teams, team) {
    return pipe(team, option.fromNullable, option.chain((team) => pipe(teams, array.findFirst(({ value }) => value === team.id), option.map(({ designers, ...rest }) => ({
        ...rest,
        designers: pipe(designers, array.filter((designer) => team.designers.map(({ value }) => value).includes(designer.value))),
    })))), option.toNullable);
}
export function hasAssignedDesigners(team) {
    return team ? array.isNonEmpty(team.designers) : false;
}
export function normalizeCustomerTreatments(customerTreatments, treatments) {
    return pipe(customerTreatments, record.map(record.collect(string.Ord)((id, pricing) => ({ id: Number(id), pricing }))), record.collect(string.Ord)((hour, entries) => makeTuple(hour, pipe(treatments, array.map((treatment) => pipe(entries, array.findFirst((customerTreatment) => customerTreatment.id === treatment.id), option.fold(() => makeTuple(0, 0), ({ pricing }) => tuple.swap(pricing))))))));
}
/**
 * Iterations
 */
export const iterationToColor = [
    'bg-primary-300',
    'bg-warning-500',
    'bg-secondary-300',
    'bg-danger-400',
    'bg-success-300',
    'bg-info-400',
    'bg-accent-400',
    'bg-primary-400',
    'bg-danger-500',
    'bg-warning-400',
    'bg-secondary-400',
];
/** MIME types */
const mimeTypesManager = (mimeTypes) => ({
    accept: mimeTypes.join(','),
    isAllowed: (file) => mimeTypes.includes(file.type),
});
export const mimeTypes = {
    message: mimeTypesManager([
        '.ai',
        '.cdr',
        '.doc',
        '.docm',
        '.docx',
        '.eps',
        '.otf',
        '.potm',
        '.ppt',
        '.pptx',
        '.psd',
        '.ttf',
        '.woff',
        '.woff2',
        '.xls',
        '.xlsx',
        'application/ms-powerpoint',
        'application/msexcel',
        'application/mspowerpnt',
        'application/mspowerpoint',
        'application/msword',
        'application/octet-stream',
        'application/pdf',
        'application/photoshop',
        'application/powerpoint',
        'application/psd',
        'application/vnd-mspowerpoin',
        'application/vnd.apple.keynote',
        'application/vnd.apple.numbers',
        'application/vnd.apple.pages',
        'application/vnd.ms-excel',
        'application/vnd.ms-powerpoint [official]',
        'application/vnd.ms-powerpoint',
        'application/vnd.ms-powerpoint.template.macroEnabled.12',
        'application/vnd.ms-word.document.macroenabled.12',
        'application/vnd.ms-word.template.macroenabled.12',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
        'application/vnd.openxmlformats-officedocument.presentationml.template',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/x-7z-compressed',
        'application/x-dos_ms_excel',
        'application/x-excel',
        'application/x-iwork-keynote-sffkey',
        'application/x-iwork-numbers-sffnumbers',
        'application/x-iwork-pages-sffpages',
        'application/x-m',
        'application/x-ms-excel',
        'application/x-msexcel',
        'application/x-photoshop',
        'application/x-powerpoint',
        'application/x-rar-compressed',
        'application/x-zip-compressed',
        'application/xls',
        'application/zip',
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/psd',
        'image/vnd.adobe.photoshop',
        'multipart/x-zip',
        'text/plain',
        'video/mp4',
    ]),
    preview: mimeTypesManager(['image/png', 'image/jpg', 'image/jpeg']),
};
export function getTimezoneDate(currentDate, offset) {
    return date.formatDate(addMinutes(currentDate, currentDate.getTimezoneOffset() + offset));
}
export const propagateTreatmentsBackendValidationErrors = (fieldArray, setFormErrors) => (error) => {
    const formErrors = decodeBackendErrors(error);
    if (formErrors) {
        for (const key in formErrors) {
            if (key.includes('treatments')) {
                const fieldId = Number(key.split('.')[1]);
                const index = fieldArray.groups.findIndex(({ id }) => id.value === fieldId);
                if (index !== -1) {
                    fieldArray.setErrors(index, 'value', formErrors[key]);
                }
            }
            else {
                setFormErrors(key, formErrors[key]);
            }
        }
    }
    else {
        throw error;
    }
};
