import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { addDays, differenceInDays, isToday, isWeekend } from 'date-fns';
import { useMemo } from 'react';
import { Chart } from 'react-chartjs-2';
import { Button, Collapse, IconBox, LayoutContainer } from '~/common/components';
import { useToggle } from '~/common/hooks';
import { barChartScaleParams, getBarParams, LegendItem, registerChartJSPlugins, stickyYAxisPlugin, tooltipCallbacks, tooltipParams, } from '~/common/other';
import { formatDayFullMonthYear, isNonNullable, range } from '~/common/utils';
import { useRmsContext } from '../context';
import { chartMinWidth, dateFormatter } from './utils';
registerChartJSPlugins();
export const arbitraryLine = {
    id: 'arbitraryLine',
    beforeDraw: (chart, _, options) => {
        if (options.index === null) {
            return;
        }
        const { ctx, chartArea: { top, height }, scales: { x }, } = chart;
        ctx.save();
        ctx.strokeStyle = '#cdd7e5';
        ctx.setLineDash([4]);
        ctx.strokeRect(x.getPixelForValue(options.index), top, 0, height);
        ctx.restore();
    },
};
const getProportionalCapacity = (item) => {
    if (!item) {
        return { regular_orders: null, subscription_orders: null };
    }
    const totals = item.regular_orders + item.subscription_orders;
    const regularProportion = item.regular_orders / totals || 0;
    const subscriptionProportion = item.subscription_orders / totals || 0;
    const regularAdjusted = Math.round(item.regular_orders - item.over_capacity * regularProportion);
    const subscriptionAdjusted = Math.round(item.subscription_orders - item.over_capacity * subscriptionProportion);
    return { regular_orders: regularAdjusted, subscription_orders: subscriptionAdjusted };
};
const getChartData = ({ dataset, start, end, allTeams }) => {
    const chartData = {
        subscription_orders: [],
        regular_orders: [],
        over_capacity: [],
        day_capacity: [],
        day_capacity_left: [],
        weekend: new Set(),
        labels: [],
        tooltips: [],
        allTeams,
        today: formatDayFullMonthYear(start),
        todayIndex: null,
    };
    if (allTeams) {
        const sortedData = dataset.filter(isNonNullable).sort((a, b) => b.subscription_orders + b.regular_orders - a.subscription_orders - a.regular_orders);
        range(0, sortedData.length - 1).forEach((index) => {
            const currentItem = sortedData[index];
            const { regular_orders, subscription_orders } = getProportionalCapacity(currentItem);
            chartData.subscription_orders.push(subscription_orders);
            chartData.regular_orders.push(regular_orders);
            chartData.over_capacity.push(currentItem.over_capacity);
            chartData.day_capacity.push(currentItem.day_capacity);
            chartData.day_capacity_left.push(currentItem.day_capacity_left);
            chartData.labels.push(currentItem.team);
        });
        chartData.tooltips = chartData.labels;
        return chartData;
    }
    const diff = differenceInDays(end, start);
    range(0, diff - 1).forEach((index) => {
        const newDate = addDays(start, index);
        const isDateToday = isToday(newDate);
        const currentItem = dataset[index];
        const { regular_orders, subscription_orders } = getProportionalCapacity(currentItem);
        chartData.subscription_orders.push(subscription_orders);
        chartData.regular_orders.push(regular_orders);
        chartData.over_capacity.push(currentItem ? currentItem.over_capacity : null);
        chartData.day_capacity.push(currentItem ? currentItem.day_capacity : null);
        chartData.day_capacity_left.push(currentItem ? currentItem.day_capacity_left : null);
        chartData.labels.push(dateFormatter(dataset.length, newDate));
        chartData.tooltips.push(formatDayFullMonthYear(newDate));
        if (isDateToday) {
            chartData.todayIndex = index;
        }
        if (isWeekend(newDate)) {
            chartData.weekend.add(index);
        }
    });
    return chartData;
};
const getChartProps = (chartData) => ({
    type: 'bar',
    plugins: [arbitraryLine, stickyYAxisPlugin],
    options: {
        plugins: {
            arbitraryLine: {
                index: chartData.todayIndex,
            },
            stickyYAxis: {
                draw: chartData.allTeams,
                nodeId: 'rms-daily-utilization-canvas',
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: tooltipCallbacks(chartData.tooltips),
                ...tooltipParams,
            },
            legend: { display: false },
        },
        ...barChartScaleParams(chartData),
    },
    data: {
        labels: chartData.labels,
        datasets: [
            {
                label: 'Regular orders',
                backgroundColor: '#46B688',
                data: chartData.regular_orders,
                ...getBarParams({ flatBottom: true }),
            },
            {
                label: 'Subscription orders',
                backgroundColor: '#5F9AF2',
                data: chartData.subscription_orders,
                ...getBarParams(),
            },
            {
                label: 'Remaining capacity',
                backgroundColor: '#EDE9F9',
                data: chartData.day_capacity_left,
                ...getBarParams(),
            },
            {
                label: 'Day capacity',
                data: chartData.day_capacity.map((x) => (x === null ? null : x + 1)),
                type: 'bubble',
                borderWidth: 1,
                hitRadius: 0,
                hoverRadius: 0,
                pointStyle: 'line',
                borderColor: '#6D7395',
                radius: 12,
            },
            {
                label: 'Over capacity',
                backgroundColor: '#F86666',
                data: chartData.over_capacity,
                ...getBarParams(),
            },
        ],
    },
});
const DayCapacity = () => (_jsxs("div", { className: "flex items-center gap-1", children: [_jsx("div", { className: "relative h-[2px] w-2 bg-greyscale-500" }), _jsx("div", { className: "font-brand-b2r text-greyscale-500", children: "Max day capacity" })] }));
export const Utilization = ({ utilization }) => {
    const { date, endDate, allTeams } = useRmsContext();
    const chartData = useMemo(() => getChartData({ dataset: utilization, start: date, end: endDate, allTeams }), [allTeams, utilization, endDate, date]);
    const [show, toggleShow] = useToggle(true);
    const chartProps = useMemo(() => getChartProps(chartData), [chartData]);
    const itemsAmount = chartData.labels.length;
    return (_jsxs(LayoutContainer, { children: [_jsxs("div", { className: "flex items-center justify-between", children: [_jsxs("div", { className: "flex items-center justify-between h-5 gap-1 font-brand-t1r", children: [_jsx("span", { children: "Day Capacity Overview, " }), _jsx("span", { className: "text-greyscale-500", children: "points" }), _jsx(IconBox, { "data-tt": `Capacity points ${allTeams ? 'per team' : 'for team per day'}`, size: "m", className: "inline text-greyscale-300", icon: faInfoCircle })] }), _jsx(Button.Icon, { className: "ml-2", size: "m", color: "grey-outline", icon: show ? faChevronUp : faChevronDown, onClick: toggleShow })] }), _jsxs(Collapse, { in: show, children: [_jsx("div", { className: "flex items-center py-2", children: _jsxs("div", { className: "flex items-center gap-5", children: [_jsx(DayCapacity, {}), _jsx(LegendItem, { label: "Remaining capacity", className: "bg-info-50" }), _jsx(LegendItem, { label: "Regular orders", className: "bg-primary-300" }), _jsx(LegendItem, { label: "Subscription orders", className: "bg-secondary-300" }), _jsx(LegendItem, { label: "Over capacity", className: "bg-danger-400" })] }) }), _jsxs("div", { className: "relative mt-3", children: [_jsx("div", { className: "overflow-x-auto", children: _jsx("div", { className: "relative h-[375px]", style: chartMinWidth(itemsAmount, allTeams), children: _jsx(Chart, { ...chartProps }) }) }), allTeams && (_jsx("div", { className: "absolute left-0 top-0 bg-greyscale-0 pr-[4px]", children: _jsx("canvas", { id: "rms-daily-utilization-canvas", className: "h-[375px]" }) }))] })] })] }));
};
