import { useMutation } from '@tanstack/react-query';
import { useIdParam } from '~/common/hooks';
import { httpClient } from '~/common/utils';
import { qk } from '~/root/query-keys';
import { useUpdateAnnotationCache } from './useUpdateAnnotationCache';
export function useRemoveAnnotationComment() {
    const id = useIdParam();
    const updateAnnotationCache = useUpdateAnnotationCache();
    return useMutation({
        mutationKey: qk.removeAnnotationComment(id),
        mutationFn: ({ annotationId, commentId }) => httpClient.delete(`/v1/staff/orders/${id}/annotations/${annotationId}/comments/${commentId}`),
        onSuccess: (_, params) => {
            updateAnnotationCache(params, (annotation) => ({
                ...annotation,
                comments: annotation.comments.filter((comment) => comment.id !== params.commentId),
            }));
        },
    });
}
