import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faChevronDown, faTag } from '@fortawesome/pro-solid-svg-icons';
import { memo } from 'react';
import { IconBox, Pattern, StickyLeftTableCell } from '~/common/components';
import { UserCard } from '~/common/kits/usercard';
import { cx } from '~/common/utils';
import { TableCell } from '../common';
const MAX_VIEW_PERFORMANCE = 999;
const CapacityCell = memo(({ actual, planned }) => {
    const performance = Math.min(Math.round((actual / planned) * 100 || 0), MAX_VIEW_PERFORMANCE);
    const textColor = performance > 100 ? 'text-danger-400' : 'text-primary-300';
    const bgColor = performance > 100 ? 'bg-danger-400' : 'bg-primary-300';
    return (_jsxs("div", { className: "absolute top-0 left-0 flex items-end justify-between h-full w-full px-1 py-[4px]", children: [_jsxs("div", { className: "font-brand-b2r space-y-[2px]", children: [_jsx("div", { children: planned }), _jsx("div", { children: actual })] }), _jsxs("div", { className: cx('text-[11px] leading-[18px] ml-auto', textColor), children: [performance, "%"] }), _jsxs("div", { className: "relative h-5 w-[4px] ml-1", children: [_jsx("div", { className: "absolute bottom-0 h-full w-full rounded-2xl bg-greyscale-200" }), _jsx("div", { style: { height: `${Math.min(100, performance)}%` }, className: cx('absolute bottom-0 w-full rounded-2xl', bgColor) })] })] }));
});
const GenericRow = ({ days, daysData, children, onClick, onCellClick, darkBackground, }) => {
    const makeCell = (_, index) => {
        const data = daysData[index];
        const restDay = !data || data.is_holiday || data.is_weekend;
        const onClick = onCellClick === null || onCellClick === void 0 ? void 0 : onCellClick(index, data === null || data === void 0 ? void 0 : data.plan);
        return (_jsxs(TableCell, { className: cx('relative', onClick && 'cursor-pointer'), onClick: onClick, children: [restDay && _jsx(Pattern, { className: "text-greyscale-200" }), data && _jsx(CapacityCell, { actual: data.actual, planned: data.plan })] }, index));
    };
    return (_jsxs("tr", { className: cx(darkBackground && 'bg-greyscale-50'), children: [_jsx(StickyLeftTableCell, { className: cx(darkBackground && 'bg-greyscale-50', onClick && 'cursor-pointer'), width: 248, onClick: onClick, children: _jsxs("div", { className: "flex items-center justify-between h-full pl-1 pointer-events-auto", children: [children, _jsxs("div", { className: "h-full font-brand-c1r text-greyscale-500 border-l border-solid border-greyscale-200", children: [_jsx("div", { className: "flex items-center h-1/2 px-1 border-b border-solid border-greyscale-200", children: "Plan" }), _jsx("div", { className: "flex items-center h-1/2 px-1", children: "Actual" })] })] }) }), days.map(makeCell)] }));
};
export const TeamRow = ({ name, opened, ...props }) => (_jsxs(GenericRow, { ...props, children: [_jsx("div", { className: "font-brand-t3 truncate uppercase", children: name }), _jsx(IconBox, { size: "s", icon: faChevronDown, className: cx('h-6 w-6 ml-auto text-secondary-300 transition-brand', {
                '-rotate-180': opened,
            }) })] }));
export const DesignerRow = ({ designer, leader, ...props }) => {
    var _a;
    return (_jsxs(GenericRow, { ...props, daysData: designer.days, children: [_jsxs("div", { className: "relative mr-1", children: [_jsx(UserCard, { id: designer.id, name: designer.name, avatar_link: ((_a = designer.avatar) === null || _a === void 0 ? void 0 : _a.link) || null }), leader && (_jsx(IconBox, { size: "s", className: "absolute bottom-0 -right-[6px] text-greyscale-500", icon: faTag }))] }), _jsx("div", { className: "mx-1 font-brand-t4m text-greyscale-500 truncate", children: designer.name }), _jsx("div", { "data-tt": leader ? 'Design manager' : 'Designer', "data-tt-placement": "right", className: "ml-auto mr-1 font-brand-t4m text-greyscale-500", children: leader ? 'DM' : 'D' })] }));
};
