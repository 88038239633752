import { jsx as _jsx } from "react/jsx-runtime";
import { faShapes } from '@fortawesome/pro-regular-svg-icons';
import { LayoutContent } from '~/common/components';
import { Nothing, Table, useBackendTable } from '~/common/kits/table';
import { addSlug } from '~/common/utils';
import { ORDERS_URL } from '~/orders';
import { useOrders } from '~/orders/hooks';
import { tableConfig } from './table-config';
export const ListTable = () => {
    const orders = useOrders();
    const view = useBackendTable({
        data: orders.data,
        tableConfig,
        isFetching: orders.isFetching,
    });
    if (!view) {
        return _jsx(LayoutContent, { loading: true });
    }
    return (_jsx(Table, { ...view, noDataPlaceholder: _jsx(Nothing, { icon: faShapes }), linkGetter: (row) => `${ORDERS_URL}/${row.id}-${row.slides}${addSlug(row.user.name)}` }));
};
