import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FeatureFlags } from '~/common/other';
import { useCurrentUserData } from '~/root';
import { useCapacityBreakdown, useSubscriptionFeatureFlags } from '~/subscriptions/hooks';
import { PaymentsWidget } from '~/subscriptions/Payments';
import { CapacityBreakdown } from './CapacityBreakdown';
import { DedicatedTeam } from './DedicatedTeam';
import { IndividualPricing } from './IndividualPricing';
import { PlanInfo } from './PlanInfo';
import { SubscriptionInfo } from './SubscriptionInfo';
import { SuccessTeam } from './SuccessTeam';
import { UploadWindow } from './UploadWindow';
export const General = () => {
    const { permissions } = useCurrentUserData();
    const breakdown = useCapacityBreakdown();
    const featuresFlagsData = useSubscriptionFeatureFlags();
    return (_jsxs("div", { className: "grid grid-cols-[400px_minmax(0,1fr)] gap-x-2", children: [_jsxs("div", { className: "space-y-2", children: [_jsx(SubscriptionInfo, {}), _jsx(FeatureFlags, { ...featuresFlagsData, canEdit: permissions.customersUpdate }), _jsx(SuccessTeam, {}), _jsx(DedicatedTeam, {})] }), _jsxs("div", { className: "space-y-2", children: [_jsx(PlanInfo, {}), breakdown.data && _jsx(CapacityBreakdown, {}), _jsx(PaymentsWidget, {}), _jsxs("div", { className: "grid grid-cols-2 gap-x-2 items-start", children: [_jsx(UploadWindow, {}), _jsx(IndividualPricing, {})] })] })] }));
};
