import { jsx as _jsx } from "react/jsx-runtime";
import { cx } from '~/common/utils';
import { IconBox } from '../IconContainers';
import css from './PaginationItem.module.scss';
export const PaginationItem = ({ icon, disabled, active, children, onClick, ...props }) => {
    const Content = icon ? _jsx(IconBox, { className: "text-inherit w-1", size: "s", icon: icon }) : children;
    return (_jsx("li", { children: _jsx("a", { ...props, onClick: disabled ? undefined : onClick, className: cx(css.paginationItem, {
                [css.active]: active,
                [css.disabled]: disabled,
                [css.icon]: icon,
            }), children: Content }) }));
};
