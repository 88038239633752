import { useEffect } from 'react';
import { subscribePresence } from './shared-ws';
export const usePresenceChannel = ({ channelName, enabled = true }) => {
    useEffect(() => {
        if (!enabled) {
            return;
        }
        return subscribePresence(channelName);
    }, [channelName, enabled]);
};
