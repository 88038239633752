import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Banner, IconBox, Input, ModalHeader, ModalShell } from '~/common/components';
import { ORDERS_URL } from '~/orders';
import { useDeleteOrder, useOrderData } from '~/orders/hooks';
export const ConfirmDelete = ({ onClose }) => {
    const { customer } = useOrderData();
    const [email, setEmail] = useState('');
    const { mutateAsync, isLoading } = useDeleteOrder();
    const history = useHistory();
    const goBack = () => history.push(ORDERS_URL);
    const handleDelete = () => mutateAsync().then(goBack);
    const canDelete = customer.email === email;
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose }), _jsxs(ModalShell, { className: "w-[494px] px-3 mb-5", loading: isLoading, onSubmit: handleDelete, onClose: onClose, cancelText: "No, keep the order", submitText: "Yes, delete the order", disabled: !canDelete, disabledHint: "Wrong customer's email", negativeAction: true, children: [_jsxs("div", { className: "flex items-center gap-1 mt-1 mb-3", children: [_jsx("div", { className: "font-brand-t1m text-greyscale-600", children: "Delete the order?" }), _jsx(IconBox, { size: "m", className: "text-danger-400", icon: faTimesCircle })] }), _jsx(Banner, { color: "warning", children: "After deleting the customer and inner users won't be able to see this order, but it will be possible to restore it" }), _jsxs("div", { className: "font-brand-b1 mt-3 mb-1", children: [_jsx("span", { children: "To confirm, please enter the customer\u2019s email -" }), _jsx("br", {}), _jsx("span", { className: "font-brand-t4m", children: customer.email })] }), _jsx(Input, { placeholder: "Customer's email", value: email, onChange: (e) => setEmail(e.target.value) })] })] }));
};
