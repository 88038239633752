import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { Button } from '~/common/components';
import { isNonBlankString } from '~/common/utils';
import { useAddAnnotationComment, useAnnotationInfo } from '~/orders/hooks';
export const AddComment = ({ annotationId }) => {
    const [comment, setComment] = useState('');
    const { revisionId, slideId } = useAnnotationInfo();
    const addAnnotationComment = useAddAnnotationComment();
    const handleChange = (event) => {
        setComment(event.currentTarget.value);
    };
    const handleAddComment = () => {
        if (isNonBlankString(comment)) {
            addAnnotationComment
                .mutateAsync({ revisionId, slideId, annotationId, comment })
                .then(() => setComment(''));
        }
    };
    return (_jsxs("div", { className: "flex items-center gap-1 h-6 px-2 border-t border-t-greyscale-100", children: [_jsx("input", { className: "font-brand-b1 flex-grow placeholder:text-greyscale-400", onChange: handleChange, placeholder: "Leave a message...", value: comment }), _jsx(Button.Icon, { size: "m", color: "secondary", icon: faPaperPlane, loading: addAnnotationComment.isLoading, onClick: handleAddComment })] }));
};
