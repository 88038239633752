import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { LayoutContent } from '~/common/components';
import { useInit, useOrdersForBoard } from '~/orders/hooks';
import { qk } from '~/root/query-keys';
import { Header } from '../Header';
import { BoardTable } from './BoardTable';
import { useBoardChannel, useBoardCollaborationChannel, useBoardFilterPresets, useUpdateUnreadChannel, } from './hooks';
export const BoardView = () => {
    const init = useInit();
    const boardOrders = useOrdersForBoard();
    const client = useQueryClient();
    /**
     * We only keep ws subscription while on this page. To avoid incomplete data
     * when coming back we invalidate when leaving to show stale and immediately
     * refetch once user comes back
     * */
    useEffect(() => {
        return () => {
            setTimeout(() => client.invalidateQueries(qk.ordersBoard));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useBoardFilterPresets();
    useBoardChannel();
    useBoardCollaborationChannel();
    useUpdateUnreadChannel();
    return (_jsxs(LayoutContent, { className: "grid-rows-[min-content,_1fr]", children: [init.data && _jsx(Header, {}), boardOrders.data && init.data ? (_jsx("div", { className: "overflow-auto -mx-2 px-2", children: _jsx(BoardTable, {}) })) : (_jsx(LayoutContent, { loading: true }))] }));
};
