import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Button } from '~/common/components';
import { CommonProductFields } from './CommonProductFields';
import { RetainerProduct } from './RetainerProduct';
import { TechpackProduct } from './TechpackProduct';
export const SubscriptionProduct = ({ product: { type }, index, form, productOptions, supportedCurrencies, renewalPeriodOptions, showIndividualPricingBanner, projectManagers, designTeams, onRemove, onPlanChange, onPriceRelatedFieldBlur, onPriceRelatedFieldChange, }) => {
    const onAmountChange = (value) => {
        form.fieldProps(`${type}_amount`).onChange(value);
        if (type === 'retainer') {
            form.fieldProps(`${type}_credits`).onChange(value);
        }
    };
    return (_jsxs("div", { className: "pt-2 px-3 pb-3 border border-solid border-greyscale-200 rounded-lg", children: [_jsx(ProductHeader, { index: index, onRemove: onRemove }), _jsx(CommonProductFields, { type: type, form: form, productOptions: productOptions, supportedCurrencies: supportedCurrencies, renewalPeriodOptions: renewalPeriodOptions, className: "mb-4", onPlanChange: onPlanChange, onAmountChange: onAmountChange, onPriceRelatedFieldBlur: onPriceRelatedFieldBlur, onPriceRelatedFieldChange: onPriceRelatedFieldChange }), type === 'retainer' && (_jsx(RetainerProduct, { form: form, projectManagers: projectManagers, designTeams: designTeams, showIndividualPricingBanner: showIndividualPricingBanner, onPriceRelatedFieldChange: onPriceRelatedFieldChange, onPriceRelatedFieldBlur: onPriceRelatedFieldBlur })), type === 'techpack' && _jsx(TechpackProduct, { form: form })] }));
};
const ProductHeader = ({ index, onRemove, }) => {
    return (_jsxs("div", { className: "flex items-center justify-between mb-1", children: [_jsxs("h3", { className: "font-brand-t2m inline", children: ["Product ", index + 1] }), onRemove && _jsx(Button.Icon, { icon: faTimes, color: "secondary", size: "m", onClick: onRemove })] }));
};
