import { useLayoutEffect, useRef } from 'react';
export const useChatScrollFetch = ({ ref, isFetched, isFetchingNextPage, scrollToBottom, hasNextPage, fetchNextPage, isSuccess, }) => {
    const previousScrollHeight = useRef(0);
    // Scroll to bottom when chat messages are initially loaded
    useLayoutEffect(() => {
        if (isFetched)
            scrollToBottom();
    }, [isFetched, scrollToBottom]);
    // Fetch next messages page and save previous scroll height
    useLayoutEffect(() => {
        const handler = (event) => {
            const { scrollHeight, scrollTop } = event.currentTarget;
            if (scrollTop === 0 && hasNextPage) {
                previousScrollHeight.current = scrollHeight;
                fetchNextPage();
            }
        };
        const container = ref.current;
        if (isSuccess)
            container === null || container === void 0 ? void 0 : container.addEventListener('scroll', handler);
        return () => container === null || container === void 0 ? void 0 : container.removeEventListener('scroll', handler);
    }, [fetchNextPage, hasNextPage, isSuccess, ref]);
    // Update scroll position when next page is being loaded
    useLayoutEffect(() => {
        if (ref.current) {
            ref.current.scrollTop = ref.current.scrollHeight - previousScrollHeight.current;
        }
    }, [isFetchingNextPage, ref]);
};
