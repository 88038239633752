/** Adds value to set efficiently and immutably */
export const add = (set, value) => {
    const next = new Set(set);
    next.add(value);
    return next;
};
/** Removes value from set efficiently and immutably */
export const remove = (set, value) => {
    const next = new Set(set);
    next.delete(value);
    return next;
};
/** Toggles a value in set efficiently and immutably */
export const toggle = (set, value) => {
    const next = new Set(set);
    if (next.has(value)) {
        next.delete(value);
    }
    else {
        next.add(value);
    }
    return next;
};
/** Combines multiple sets efficiently */
export const combine = (args) => {
    const result = new Set();
    args.forEach((set) => {
        set.forEach((value) => {
            result.add(value);
        });
    });
    return result;
};
