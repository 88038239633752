import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';
import { IconButton, Input, Tooltip } from '~/common/components';
import { Errors } from './Errors';
export const PasswordField = ({ errors, onChange, touched, ...props }) => {
    const [type, setType] = useState('password');
    const [showTooltip, setShowTooltip] = useState(false);
    const toggleType = () => setType((type) => (type === 'password' ? 'text' : 'password'));
    return (
    // TODO consider moving tooltip out when password field will be needed in other places
    _jsxs("div", { children: [_jsx(Tooltip, { compensateOffset: 28, content: showTooltip && (_jsxs("ul", { className: "list-disc ml-2", children: [_jsx("li", { children: "One lowercase character" }), _jsx("li", { children: "One uppercase character" }), _jsx("li", { children: "One number" }), _jsx("li", { children: "One special character" }), _jsx("li", { children: "8 character minimum" })] })), placement: "top-end", children: _jsx(Input, { ...props, onFocus: () => {
                        setShowTooltip(true);
                    }, onBlur: (e) => {
                        props.onBlur(e);
                        setShowTooltip(false);
                    }, onChange: (event) => onChange(event.currentTarget.value), error: errors !== null, type: type, children: _jsx(IconButton, { size: "s", icon: type === 'password' ? faEye : faEyeSlash, onClick: toggleType }) }) }), errors && _jsx(Errors, { errors: errors, color: true })] }));
};
