import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { faCreditCard } from '@fortawesome/pro-regular-svg-icons';
import { ModalBody, ModalHeader, useModalContext } from '~/common/components';
import { pluralize } from '~/common/utils';
import { useOrderData } from '~/orders/hooks';
import { SectionButton } from '../../Section';
import { Method } from './Method';
const PaymentMethodsContent = ({ onClose }) => {
    const { customer } = useOrderData();
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Payment methods" }), _jsx(ModalBody, { className: "w-[712px]", children: _jsx("div", { className: "space-y-2", children: customer.payment_methods.map((method, key) => (_jsx(Method, { ...method }, key))) }) })] }));
};
export const PaymentMethods = () => {
    const { customer } = useOrderData();
    const paymentsCount = customer.payment_methods.length;
    const { modalOpener } = useModalContext();
    return (_jsx(SectionButton, { disabled: !paymentsCount, onClick: modalOpener(PaymentMethodsContent), icon: faCreditCard, children: `${paymentsCount} Payment method${pluralize(paymentsCount)}` }));
};
