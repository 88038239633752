import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faUserTag } from '@fortawesome/pro-regular-svg-icons';
import { BackButton, LayoutContent, LayoutHeader, LayoutTitle, LayoutTwinPane, Menu, NotFound, useModalContext, withModalContext, } from '~/common/components';
import { is404 } from '~/common/utils';
import { useCurrentUserData } from '~/root';
import { roleToLink } from '..';
import { roleToListRole } from '../domain';
import { useInit, useResendInvitation, USERS_URL, useUser, useUserData } from '../hooks';
import { DeletePrompt } from './Delete';
import { Permissions } from './Permissions';
import { UserInfo } from './UserInfo';
export const User = withModalContext(() => {
    const user = useUser();
    const init = useInit();
    if (is404(user.error)) {
        return (_jsx(NotFound, { icon: faUserTag, text: "There's no user with this id", safeLocation: USERS_URL }));
    }
    if (!user.data || !init.data) {
        return _jsx(LayoutContent, { loading: true });
    }
    return (_jsxs(LayoutContent, { children: [_jsxs(LayoutHeader, { className: "flex justify-between items-center", children: [_jsx(BackButton, { to: roleToLink[roleToListRole(user.data.role)] }), _jsx(LayoutTitle, { className: "mr-auto", children: "User profile" }), _jsx(UserMenu, {})] }), _jsx("div", { className: "h-full overflow-auto", children: _jsx(LayoutTwinPane, { side: _jsx(UserInfo, {}), main: _jsx(Permissions, {}) }) })] }));
});
const UserMenu = () => {
    const { modalOpener } = useModalContext();
    const { permissions } = useCurrentUserData();
    const user = useUserData();
    const resend = useResendInvitation();
    if (!permissions.usersDelete || !permissions.usersCreate) {
        return null;
    }
    return (_jsx(Menu, { children: (onCloseMenu) => (_jsxs(_Fragment, { children: [user.invitation_expired && (_jsx(Menu.Item, { disabled: resend.isLoading, loading: resend.isLoading, onClick: () => resend.mutateAsync().then(onCloseMenu), children: "Resend invitation" })), _jsx(Menu.Item, { disabled: resend.isLoading, onClick: modalOpener(DeletePrompt), children: "Delete user" })] })) }));
};
