import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useForm } from 'formoid';
import { Button, DatepickerField, Link, ModalFooter, ModalHeader } from '~/common/components';
import { propagateBackendValidationErrors } from '~/common/utils';
import { useDeleteUpcomingEvent, useEditUpcomingEvent } from '~/subscriptions/hooks';
export const EditUpcomingEvent = ({ onClose, event }) => {
    const initialValues = {
        type: event.type,
        date: event.date,
    };
    const { mutateAsync: mutateEditEvent } = useEditUpcomingEvent();
    const { mutateAsync: mutateDeleteEvent } = useDeleteUpcomingEvent();
    const { fieldProps, handleSubmit, setErrors, isSubmitting } = useForm({
        initialValues: initialValues,
        validationStrategy: 'onBlur',
        validators: () => ({
            date: null,
            type: null,
        }),
    });
    const submit = () => handleSubmit((values) => {
        return mutateEditEvent(values)
            .then(onClose)
            .catch((error) => {
            propagateBackendValidationErrors(error, setErrors);
        });
    });
    const cancelEvent = () => handleSubmit((values) => {
        return mutateDeleteEvent(values)
            .then(onClose)
            .catch((error) => {
            propagateBackendValidationErrors(error, setErrors);
        });
    });
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Edit upcoming event" }), _jsxs("div", { className: "py-5 px-3 w-[608px] flex flex-col gap-3 font-brand-t2r text-greyscale-600", children: [event.description, _jsxs("div", { className: "flex items-center gap-2", children: [_jsx(DatepickerField, { ...fieldProps('date') }), event.cancellable && (_jsx(Button, { color: "text-secondary", size: "xs", className: "text-danger-400", onClick: cancelEvent, children: "Cancel the event" }))] })] }), _jsxs(ModalFooter, { className: "flex gap-3 px-3 items-center", children: ['invoice_preview_url' in event && event.invoice_preview_url && (_jsx(Link, { newTab: true, size: "m-bold", href: event.invoice_preview_url, children: "Invoice preview" })), _jsx(Button, { className: "ml-auto", color: "grey", disabled: isSubmitting, size: "medium", onClick: onClose, children: "Cancel" }), _jsx(Button, { disabled: isSubmitting, loading: isSubmitting, size: "medium", onClick: submit, children: "Update the event" })] })] }));
};
