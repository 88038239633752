import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faShapes } from '@fortawesome/pro-regular-svg-icons';
import { LayoutContent, withModalContext } from '~/common/components';
import { Nothing, Table, useBackendTable } from '~/common/kits/table';
import { SUBSCRIPTIONS_URL, useInit, useSubscriptionsList } from '../hooks';
import { Header } from './Header';
import { tableConfig } from './table-config';
const getSubscriptionLink = (row) => {
    return `${SUBSCRIPTIONS_URL}/${row.id}/customer/${row.owner_id}`;
};
export const SubscriptionsList = withModalContext(() => {
    const init = useInit();
    const subscriptions = useSubscriptionsList();
    const view = useBackendTable({
        data: subscriptions.data,
        tableConfig,
        isFetching: subscriptions.isFetching,
    });
    if (!subscriptions.data || !init.data || !view) {
        return _jsx(LayoutContent, { loading: true });
    }
    return (_jsxs(LayoutContent, { children: [_jsx(Header, { tableFilters: init.data.filter_options, subscriptionsCount: subscriptions.data.metadata.total }), _jsx(Table, { ...view, noDataPlaceholder: _jsx(Nothing, { icon: faShapes }), linkGetter: getSubscriptionLink })] }));
});
