import { jsx as _jsx } from "react/jsx-runtime";
import { faChartBar } from '@fortawesome/pro-regular-svg-icons';
import { Link } from '~/common/components';
import { addQueryParams } from '~/common/utils';
import { useOrderData } from '~/orders/hooks';
import { RMS_URL } from '~/rms/hooks';
export function ResourceOverviewLink(props) {
    var _a;
    const { team } = useOrderData();
    const teamId = (_a = team === null || team === void 0 ? void 0 : team.id) !== null && _a !== void 0 ? _a : props.teamId;
    const rmsLink = teamId ? addQueryParams(RMS_URL, { team: teamId }) : RMS_URL;
    return (_jsx(Link, { ...props, size: "m-bold", className: props.className, href: rmsLink, icon: faChartBar, children: "Resource overview" }));
}
