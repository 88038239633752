import { formatYearMonthDay, getColorByShiftRange } from '~/common/utils';
export const transformFormValues = (values) => ({
    designer_id: values.designer_id,
    date_from: formatYearMonthDay(values.date_range.start),
    date_to: formatYearMonthDay(values.date_range.end),
    reason: values.reason,
    capacity: values.capacity,
    comment: values.comment,
});
export const getLabelByShiftRange = (range) => {
    const color = getColorByShiftRange(range);
    return {
        blue: 'Morning',
        green: 'Day',
        yellow: 'Evening',
        violet: 'Night',
    }[color];
};
