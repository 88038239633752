import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { changeStatusPayload } from '~/orders/hooks';
import { Content } from './Content';
export const TransitionOther = ({ currentStatusLabel, nextStatus, nextStatusLabel, onChangeStatus, onClose, onSubmit, transitions, }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleSubmit = () => {
        setIsSubmitting(true);
        onSubmit(changeStatusPayload.other(nextStatus)).finally(() => setIsSubmitting(false));
    };
    const customerReason = 'Customer left feedback in the discussion';
    return (_jsx(Content, { currentStatusLabel: currentStatusLabel, loading: isSubmitting, nextStatus: nextStatus, nextStatusLabel: nextStatusLabel, onChangeNextStatus: onChangeStatus, onClose: onClose, onSubmit: handleSubmit, transitions: transitions, children: nextStatus === 'FeedbackReceived' && (_jsxs("div", { className: "font-normal text-base mt-2", children: [_jsx("p", { className: "text-greyscale-500", children: "Reason" }), _jsx("p", { children: customerReason })] })) }));
};
