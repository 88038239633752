import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, PageDetailsCard, useModalContext } from '~/common/components';
import { DedicatedTeamRow, MultipleInnerUsersRow } from '~/customers/Customer';
import { useCurrentUserData } from '~/root';
import { useSubscriptionData } from '~/subscriptions/hooks';
import { EditDedicatedTeam } from './EditDedicatedTeam';
export const DedicatedTeam = () => {
    const { dedicated_team, project_managers } = useSubscriptionData();
    const { permissions } = useCurrentUserData();
    const { modalOpener } = useModalContext();
    if (!project_managers.length && !dedicated_team.design_team) {
        return null;
    }
    return (_jsxs(PageDetailsCard, { label: "Dedicated team", actionButton: permissions.subscriptionsUpdate && (_jsx(Button, { color: "grey", onClick: modalOpener(EditDedicatedTeam), children: "Edit" })), bodyClassName: "grid grid-cols-[1fr,1fr] gap-x-1 gap-y-2 font-brand-b1 py-3", children: [_jsx(MultipleInnerUsersRow, { label: "Project manager", users: project_managers }), _jsx(DedicatedTeamRow, { label: "Design team", team: dedicated_team.design_team })] }));
};
