import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { qk } from '~/root/query-keys';
import { httpClient, map } from '../../utils';
import { CardStore } from './domain';
import { useUserCardIdsStore } from './usercardIdsStore';
const FIVE_MINUTES = 5 * 60 * 1000;
const isEntryStale = (entry) => {
    return entry ? entry.timestamp + FIVE_MINUTES < Date.now() : false;
};
export const useUserCard = (id) => {
    var _a;
    const client = useQueryClient();
    const cardStore = useCardStore();
    const entry = (_a = cardStore.data) === null || _a === void 0 ? void 0 : _a.get(id);
    const isMissing = !entry;
    const isStale = isEntryStale(entry);
    // if we hovered on a user that doesn't have a usercard in cache yet or entry
    // is stale, we trigger a refetch
    useEffect(() => {
        if (isMissing || isStale) {
            client.invalidateQueries(qk.usercards, { fetchStatus: 'idle' });
        }
    }, [client, isMissing, isStale]);
    return {
        ...cardStore,
        data: entry === null || entry === void 0 ? void 0 : entry.card,
        // show spinner for the hook user while loading missing card
        isLoading: !cardStore.data ? cardStore.isLoading : isMissing,
    };
};
export const useUserCards = (ids) => {
    const client = useQueryClient();
    const cardStore = useCardStore();
    const cardEntries = ids.map((id) => { var _a; return (_a = cardStore.data) === null || _a === void 0 ? void 0 : _a.get(id); });
    const haveMissingCards = !cardEntries.some(Boolean);
    const haveStaleCards = cardEntries.some(isEntryStale);
    // if we hovered on stacked cards like customer members and some cards are
    // not in cache yet or stale, we trigger a refetch
    useEffect(() => {
        if (haveMissingCards || haveStaleCards) {
            client.invalidateQueries(qk.usercards, { fetchStatus: 'idle' });
        }
    }, [client, haveMissingCards, haveStaleCards]);
    return {
        ...cardStore,
        data: cardEntries.map((entry) => entry === null || entry === void 0 ? void 0 : entry.card),
        // TODO consider loading cards individually instead like it's done in
        // regular UsersGroup?
        //
        // show spinner for the hook user while loading missing cards
        isLoading: !cardStore.data ? cardStore.isLoading : haveMissingCards,
    };
};
const useCardStore = () => {
    const idsAmount = useUserCardIdsStore((store) => store.idsAmount);
    const client = useQueryClient();
    return useQuery({
        queryKey: qk.usercards,
        queryFn: async () => {
            const accumulatedIds = Array.from(useUserCardIdsStore.getState().ids.keys());
            const prevCards = client.getQueryData(qk.usercards);
            // we'll start with list of missing cards
            const ids = prevCards ? accumulatedIds.filter((id) => !prevCards.has(id)) : accumulatedIds;
            // we'll also add stale cards if any to keep them fresh
            if (prevCards) {
                const now = Date.now();
                for (const [id, { timestamp }] of prevCards.entries()) {
                    if (timestamp + FIVE_MINUTES < now) {
                        ids.push(id);
                    }
                }
            }
            if (!ids.length) {
                return prevCards;
            }
            const nextCards = await httpClient.get(`/v1/staff/users/cards?ids=${ids.join(',')}`, {
                decoder: CardStore(ids),
            });
            return prevCards ? map.combine([prevCards, nextCards]) : nextCards;
        },
        enabled: idsAmount > 0,
        // we'll rely on individual card's stale timestamp and individual card
        // invalidations based on staleness
        staleTime: Infinity,
        cacheTime: Infinity,
    });
};
