import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Errors, SelectFactory } from '~/common/components';
import { cardBrandToImg, cx, formatNumber } from '~/common/utils';
import { methodToString } from './domain';
import css from './ManualCharge.module.scss';
const Option = (props) => {
    switch (props.type) {
        case 'card':
            return (_jsxs(_Fragment, { children: [_jsx("img", { className: "mr-2", src: cardBrandToImg(props.value.brand), alt: "Card" }), _jsxs("span", { className: "font-brand-b1 text-greyscale-600 mr-1", children: ["**** **** **** ", props.value.last4] }), props.value.default && (_jsx("span", { className: "font-brand-b2r text-greyscale-500", children: "(Default)" }))] }));
        case 'credits':
            return (_jsxs(_Fragment, { children: [_jsx("span", { className: "font-brand-b1 text-greyscale-600 mr-1", children: "Charge with credits" }), _jsxs("span", { className: "font-brand-b1 text-greyscale-500", children: ["(available ", formatNumber(props.value), " credits)"] })] }));
    }
};
export const PaymentMethodSelect = ({ errors, methods, value, ...props }) => (_jsxs("div", { children: [_jsx(SelectFactory, { ...props, containerClassName: "max-h-15", inputField: ({ ref, children, ...inputProps }) => (_jsxs("label", { ref: ref, children: [_jsx("span", { className: "text-greyscale-500 text-[14px] leading-8", children: "Payment method" }), _jsxs("div", { className: css.wrapper, children: [_jsx("input", { ...inputProps, className: cx(css.input, 'font-brand-t1') }), _jsx(Option, { ...value }), _jsx("div", { className: css.chevron, onMouseDown: (event) => event.preventDefault(), children: children })] })] })), itemRenderer: ({ className, inputValue, option, ...props }) => (_createElement("li", { ...props, key: methodToString(option.value), className: cx(className, 'h-5') },
                _jsx(Option, { ...option.value }))), noSearch: true, options: methods.map((method) => ({ name: method.type, value: method })), placement: "bottom-end", value: value }), errors && _jsx(Errors, { color: true, errors: errors })] }));
