import { z } from 'zod';
import { DateFromIsoString, Email } from '~/common/utils';
import { getListDecoder, Office, OfficeForSelect, Role, UploadedFile } from '~/root';
/*
 *
 * Basic user */
export const BasicUserTable = z.object({
    id: z.number().int().positive(),
    name: z.string(),
    avatar: UploadedFile.nullable().catch(null),
    job_title: z.string().nullable(),
    office: Office,
    updated: DateFromIsoString,
});
export const BasicUser = BasicUserTable.extend({
    email: Email,
    permissions: z.array(z.string()),
    invitation_expired: z.boolean(),
    invitation_pending: z.boolean(),
});
/*
 *
 * Designer & Design manager */
const DesignTeam = z.object({
    id: z.number().int().positive(),
    name: z.string(),
});
const DesignerFields = z.object({
    // TODO remove workaround after this is fixed on BE
    capacity: z.number().nonnegative().catch(0),
    team: DesignTeam.nullable(),
});
export const DesignerTable = BasicUserTable.merge(DesignerFields);
export const Designer = BasicUser.merge(DesignerFields).extend({
    role: z.enum(['designer', 'design_manager']),
});
/*
 *
 * Regular project manager */
export const RegularPMTable = BasicUserTable;
export const RegularPM = BasicUser.extend({
    role: z.literal('regular_pm'),
    trello_id: z.string().nullable(),
});
/*
 *
 * Subscription project manager */
export const SubscriptionPMTable = BasicUserTable;
export const SubscriptionPM = BasicUser.extend({
    role: z.literal('subscription_pm'),
    trello_id: z.string().nullable(),
    calendly_url: z.string().nullable(),
});
/*
 *
 * Customer supporter */
export const CustomerSupporterTable = BasicUserTable;
export const CustomerSupporter = BasicUser.extend({
    role: z.literal('customer_supporter'),
});
/*
 *
 * Sales manager */
export const SalesManagerTable = BasicUserTable;
export const SalesManager = BasicUser.extend({
    role: z.literal('sales_manager'),
});
/*
 *
 * Admin */
export const AdminTable = BasicUserTable;
export const Admin = BasicUser.extend({
    role: z.literal('admin'),
});
/*
 *
 * OtherUser */
export const OtherUserTable = BasicUserTable;
export const OtherUser = BasicUser.extend({
    role: z.literal('other'),
});
export const listRoleToDecoder = {
    designer: DesignerTable,
    regular_pm: RegularPMTable,
    subscription_pm: SubscriptionPMTable,
    customer_supporter: CustomerSupporterTable,
    sales_manager: SalesManagerTable,
    admin: AdminTable,
    other: OtherUserTable,
};
export const AnyUser = z.union([
    Designer,
    RegularPM,
    SubscriptionPM,
    CustomerSupporter,
    SalesManager,
    Admin,
    OtherUser,
]);
export const listRoleToListHeading = {
    designer: 'Designers',
    regular_pm: 'Regular PMs',
    subscription_pm: 'Subscription PMs',
    customer_supporter: 'Customer support',
    sales_manager: 'Sales',
    admin: 'Admins',
    other: 'Other users',
};
export const roleToTitle = {
    designer: 'Designer',
    design_manager: 'Design Manager',
    regular_pm: 'Regular PM',
    subscription_pm: 'Subscription PM',
    customer_supporter: 'Customer support',
    sales_manager: 'Sales Manager',
    admin: 'Admin',
    other: 'Other user',
};
export const getDecoderFromListRole = (role) => getListDecoder(listRoleToDecoder[role]);
export const isRoleDesigner = (role) => {
    return ['designer', 'design_manager'].includes(role);
};
export const hasTrelloId = (role) => {
    return ['regular_pm', 'subscription_pm'].includes(role);
};
export const hasCalendlyUrl = (role) => {
    return ['subscription_pm'].includes(role);
};
export const roleToListRole = (role) => role === 'design_manager' ? 'designer' : role;
const Permission = z.object({
    name: z.string(),
    label: z.string(),
    category: z.string(),
});
export const Init = z
    .object({
    permissions: z.array(Permission),
    roles: z.array(z.object({
        name: Role,
        label: z.string(),
    })),
    teams: z.array(DesignTeam),
    offices: z.array(OfficeForSelect),
})
    .transform(({ roles, teams, ...init }) => ({
    ...init,
    teams: teams.map(({ id, name }) => ({ name, value: id })),
    roles: roles.map(({ name, label }) => ({ name: label, value: name })),
}));
