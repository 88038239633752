import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { z } from 'zod';
import { useIdParam } from '~/common/hooks';
import { usePresenceChannel, usePrivateChannel } from '~/common/kits/socket';
import { addSlug } from '~/common/utils';
import { useCurrentUserData } from '~/root';
import { qk } from '~/root/query-keys';
import { BaseOrder, FollowersUpdated, orderTransform } from '../domain';
import { useOrder, useUpdateOrderCache, useMessageEvents as useCustomerMessageEvents, } from '../hooks';
import { useCheckListEvents, useChecklist, useMessageEvents, useMessages } from './TeamSpace';
const OrderUpdate = z
    .object({
    order: BaseOrder.omit({
        collaboration: true,
        can_unfollow: true,
    }).transform(orderTransform),
})
    .transform(({ order }) => order);
const useOrderSync = () => {
    const id = useIdParam();
    const updateOrder = useUpdateOrderCache();
    usePrivateChannel({
        channelName: `staff.order.${id}`,
        events: [
            {
                event: '.order.updated',
                decoder: OrderUpdate,
                callback: (order) => {
                    updateOrder((prev) => ({ ...prev, ...order, collaboration: prev.collaboration }));
                },
            },
        ],
    });
};
const useTeamSpaceSync = () => {
    const { hash } = useLocation();
    const insideListening = hash.includes('team-space');
    /**
     * Mutually exclusive listening for checklist and message events, since we
     * have onCreated listener inside that scrolls to the last message
     */
    useMessageEvents({ enabled: !insideListening });
    useCheckListEvents({ enabled: !insideListening });
    /**
     * It's expensive to keep being subscribed to team space chat and checklists
     * for background cache updates when user has left the order, so we're just
     * going to invalidate those when leaving to show stale and refetch when/if
     * user comes back
     */
    const id = useIdParam();
    const client = useQueryClient();
    useEffect(() => {
        return () => {
            setTimeout(() => client.invalidateQueries(qk.collaborationAll(id)));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
const TEAM_SPACE_ROLES = ['design_manager', 'designer', 'regular_pm', 'subscription_pm'];
const useTeamSpacePrefetch = () => {
    /**
     * Prefetch team space things for users that would use it the most
     */
    const { role } = useCurrentUserData();
    const shouldPrefetchTeamSpace = TEAM_SPACE_ROLES.includes(role);
    useMessages({ enabled: shouldPrefetchTeamSpace });
    useChecklist({ enabled: shouldPrefetchTeamSpace });
};
const useCustomerChatSync = () => {
    /**
     * TODO kinda bad, maybe even footgunny, since we're mutually exluding
     * listening for customer chat events based on the routing, which is
     * described in a separate place, so later if we add one more order subroute
     * we should not forget about this... so consider splitting useMessageEvents
     * into two things:
     * 1. for updating cache that is used outside the chat here
     * 2. for using onCreated to scroll down without doing anything cache-related
     * that way we can just update data here regardless and be safe
     */
    const { pathname } = useLocation();
    useCustomerMessageEvents({ enabled: pathname.includes('revisions') });
    /**
     * It's expensive to keep being subscribed to customer chat for background
     * cache updates when user has left the order, so we're just going to
     * invalidate it when leaving to show stale and refetch when/if user comes
     * back
     */
    const id = useIdParam();
    const client = useQueryClient();
    useEffect(() => {
        return () => {
            setTimeout(() => client.invalidateQueries(qk.orderChatMessages(id)));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
const useOrderSlug = () => {
    const order = useOrder();
    const history = useHistory();
    const { pathname, hash } = useLocation();
    /**
     * TODO we're doing this because we could go to the order page from
     * a notification, which doesn't have customer name in it
     */
    useEffect(() => {
        if (!order.data) {
            return;
        }
        const slug = addSlug(order.data.customer.name);
        if (!pathname.includes('-')) {
            history.replace(`${pathname}-${order.data.slides}${slug}${hash}`);
        }
    }, [pathname, hash, history, order.data]);
};
const useOrderTitle = () => {
    const order = useOrder();
    /**
     * Update document title with order data
     */
    const [title] = useState(document.title);
    useEffect(() => {
        if (!order.data) {
            return;
        }
        document.title = `${order.data.id} | ${order.data.slides} | ${order.data.customer.name}`;
        return () => {
            document.title = title;
        };
    }, [order.data, title]);
};
const useOrderFollowingSync = () => {
    const updateOrder = useUpdateOrderCache();
    usePrivateChannel({
        channelName: 'staff.orders',
        events: [
            {
                event: '.order.followers.updated',
                decoder: FollowersUpdated,
                callback: ({ followers }) => {
                    updateOrder((order) => ({ ...order, followers }));
                },
            },
        ],
    });
};
/**
 * Since we have separate components for Order page and Revisions page, which
 * in reality looks like a modal we need a centralized place to sync
 * order-related stuff so we don't miss cache updates and don't differentiate
 * in behavior. Hence this wrapper.
 */
export const OrderSync = ({ children }) => {
    useOrderSync();
    useOrderFollowingSync();
    useOrderTitle();
    useOrderSlug();
    useTeamSpaceSync();
    useTeamSpacePrefetch();
    useCustomerChatSync();
    const id = useIdParam();
    usePresenceChannel({ channelName: `staff.order.${id}` });
    return _jsx(_Fragment, { children: children });
};
