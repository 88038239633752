import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ModalHeader, ModalShell, useModalContext } from '~/common/components';
import { AdvancedInvoiceOptions, PaymentMethod, Summary } from '~/customers/Customer';
import { useTopupForm } from './hooks';
import { SubscriptionPlan } from './SubscriptionPlan';
export const Topup = ({ onClose }) => {
    const { modalOpener } = useModalContext();
    const { form, price, ownerId, products, currency, isLoading, paymentMethods, isCustomerTrusted, invoicePreviewData, supportedCurrencies, isInvoicePaymentMethodSelected, onPriceRelatedFieldChange, onPaymentMethodChange, resetForm, submit, } = useTopupForm();
    const onModalClose = () => {
        onClose();
        resetForm();
    };
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onModalClose, title: "Top up subscription" }), _jsxs(ModalShell, { className: "px-5 py-4 space-y-5 w-[806px]", onClose: onModalClose, onSubmit: () => submit(onClose), loading: isLoading, submitText: "Top up subscription", children: [_jsx(SubscriptionPlan, { form: form, currency: currency, products: products, supportedCurrencies: supportedCurrencies, onPriceRelatedFieldChange: onPriceRelatedFieldChange }), _jsx(PaymentMethod, { form: form, paymentMethods: paymentMethods, isCustomerTrusted: isCustomerTrusted, onPaymentMethodChange: onPaymentMethodChange, onInvoiceMethodModalClose: modalOpener(Topup) }), isInvoicePaymentMethodSelected && _jsx(AdvancedInvoiceOptions, { form: form }), _jsx(Summary, { price: price, invoicePreviewData: invoicePreviewData, ownerId: ownerId })] })] }));
};
