import { format } from 'date-fns';
import { z } from 'zod';
import { capitalize, TimelessDateFromIsoString, isNonNullable } from '~/common/utils';
import { getListDecoder, Shift } from '~/root';
export const Country = z
    .object({
    id: z.number(),
    name: z.string(),
    country_code: z.string(),
})
    .transform(({ id, ...rest }) => ({
    ...rest,
    value: id,
}));
const OfficeShift = Shift.transform((shift) => ({
    id: shift.id,
    sign: shift.sign,
    label: shift.range,
}));
export const OfficeType = z.enum(['business', 'design', 'development', 'sales']);
export const Init = z
    .object({
    countries: z.array(Country),
    shifts: z.array(Shift.transform((shift) => ({
        id: shift.id,
        sign: shift.sign,
        label: shift.range,
    }))),
    office_type: z.array(OfficeType),
})
    .transform((init) => ({
    shifts: init.shifts.filter(isNonNullable),
    office_type: init.office_type.map((value) => ({ name: capitalize(value), value })),
    countries: init.countries.filter(isNonNullable),
}));
const Holiday = z
    .object({
    name: z.string(),
    start: TimelessDateFromIsoString,
})
    .transform(({ name, start: date }) => ({ name, date }));
const groupHolidaysByMonth = (holidays) => {
    const result = {};
    for (const holiday of holidays) {
        const month = format(holiday.date, 'MMMM');
        if (!result[month]) {
            result[month] = [];
        }
        result[format(holiday.date, 'MMMM')].push(holiday);
    }
    return result;
};
export const Office = z
    .object({
    id: z.number().int().positive(),
    name: z.string().nullable(),
    type: OfficeType,
    opens_at: z.number(),
    closes_at: z.number(),
    postal_code: z.string(),
    city: z.string(),
    address: z.string(),
    country: Country,
    region: z.string().nullable(),
    shifts: z.array(OfficeShift),
    timezone: z.number(),
    holidays: z.array(Holiday),
})
    .transform((office) => ({
    ...office,
    shifts: office.shifts.filter(isNonNullable),
    working_hours: `${office.opens_at}:00 - ${office.closes_at}:00`,
    holidays: groupHolidaysByMonth(office.holidays),
}));
export const OfficeList = getListDecoder(Office);
