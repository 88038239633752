export const checkSoftStoppedPropagation = (event) => event.target.closest('[data-stop-propagation]');
export const ignoreHandled = (cb) => {
    const callback = (event) => {
        if (checkSoftStoppedPropagation(event)) {
            return;
        }
        cb(event);
    };
    return callback;
};
