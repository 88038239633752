import { isAbortError } from './httpClient';
// TODO should we move these two below to tusFileUpload.ts ?
function getTusErrorMessage(error) {
    var _a, _b, _c, _d;
    const responseText = (_b = (_a = error === null || error === void 0 ? void 0 : error.originalResponse) === null || _a === void 0 ? void 0 : _a._xhr) === null || _b === void 0 ? void 0 : _b.response;
    try {
        return (_d = (_c = JSON.parse(responseText)) === null || _c === void 0 ? void 0 : _c.message) !== null && _d !== void 0 ? _d : null;
    }
    catch (_e) {
        return null;
    }
}
export function getFriendlyUploadError(error) {
    if (isAbortError(error)) {
        return { message: ['Aborted'], shouldThrow: false };
    }
    const uploadError = getTusErrorMessage(error);
    if (uploadError) {
        return { message: [uploadError], shouldThrow: false };
    }
    return {
        message: [
            error instanceof Error
                ? error.message
                : 'An error occured while uploading this file, please try to remove it and upload once more',
        ],
        shouldThrow: true,
    };
}
export function generateFileKey(file) {
    return `${file.type}_${file.size}_${file.name}_${file.lastModified}`;
}
export function isFileBroken(file) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(false);
        };
        reader.onerror = () => {
            resolve(true);
        };
        reader.readAsDataURL(file);
    });
}
