import { jsx as _jsx } from "react/jsx-runtime";
import { useHistory } from 'react-router-dom';
import { DeleteShell } from '~/common/components';
import { USERS_URL, useUserDeletion } from '../hooks';
export const DeletePrompt = ({ onClose }) => {
    const { mutateAsync, isLoading } = useUserDeletion();
    const history = useHistory();
    const goBack = () => history.push(USERS_URL);
    const handleDelete = () => mutateAsync().then(goBack);
    return (_jsx(DeleteShell, { className: "w-[496px]", loading: isLoading, heading: "Delete the user?", description: "You will still see all the actions this user has done", submitText: "Yes, delete user", cancelText: "No, keep user", onSubmit: handleDelete, onClose: onClose }));
};
