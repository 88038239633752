import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faBriefcase } from '@fortawesome/pro-regular-svg-icons';
import { Avatar, IconBox, Link, PageDetailsCard } from '~/common/components';
import { getColorsFromNumber, pluralizeWord } from '~/common/utils';
import { useCustomerData } from '~/customers/hooks';
import { ORGANIZATIONS_URL } from '~/organizations/hooks';
import { useCurrentUserData } from '~/root';
const colorSet = getColorsFromNumber(7);
export const Organization = () => {
    var _a;
    const customer = useCustomerData();
    const { permissions } = useCurrentUserData();
    if (!customer.organisation) {
        return null;
    }
    return (_jsxs(PageDetailsCard, { label: "Organization", bodyClassName: "flex gap-2", actionButton: permissions.organizationsView && (_jsx(Link.Button, { to: `${ORGANIZATIONS_URL}/${customer.organisation.id}`, children: "View" })), children: [_jsx(Avatar, { size: "big", colorSet: colorSet, url: (_a = customer.organisation.logo) === null || _a === void 0 ? void 0 : _a.link, children: _jsx(IconBox, { size: "l", className: "text-inherit", icon: faBriefcase }) }), _jsxs("div", { className: "flex flex-col min-w-0 py-[5px]", children: [_jsx("h3", { className: "font-brand-t1m", children: customer.organisation.name }), _jsx("p", { className: "font-brand-b2r text-greyscale-400", children: pluralizeWord('customer', customer.organisation.amount) }), customer.organisation.portal_url && (_jsxs(Link, { external: true, newTab: true, size: "m-bold", color: "grey", href: customer.organisation.portal_url, className: "mt-[4px]", children: [customer.organisation.name, " custom portal"] }))] })] }));
};
