import { useCompositeForm } from 'formoid';
import { useCallback, useMemo } from 'react';
import { customValidator } from '~/common/utils';
import { changeStatusPayload, useInitData, useOrderData } from '~/orders/hooks';
import { getAssignedDesignTeam, tagTeamValidator } from '~/orders/utils';
export function useTransitionDesignInProgress(onSubmit) {
    const { teams } = useInitData();
    const { tag_teams, team } = useOrderData();
    const assignedTeam = getAssignedDesignTeam(teams, team);
    const initialValues = useMemo(() => ({
        team: assignedTeam ? assignedTeam.value : null,
        designers: assignedTeam ? assignedTeam.designers.map(({ value }) => value) : [],
    }), [assignedTeam]);
    const tagTeamsInitialValues = useMemo(() => tag_teams.map(({ id, designers }) => ({
        team: id,
        designers: designers.map(({ value }) => value),
    })), [tag_teams]);
    const { form, fieldArray, handleSubmit, isSubmitting } = useCompositeForm({
        fieldArray: {
            initialValues: { tagTeams: tagTeamsInitialValues },
            validators: ({ fieldArray }) => ({
                tagTeams: {
                    team: tagTeamValidator(fieldArray.tagTeams),
                    designers: null,
                },
            }),
            validationStrategy: 'onBlur',
        },
        form: {
            initialValues,
            validators: () => ({
                team: customValidator.required(),
                designers: null,
            }),
            validationStrategy: 'onBlur',
        },
    });
    const handleDesignTeamChange = (value) => {
        if (form.values.team === value)
            return;
        form.fieldProps('team').onChange(value);
        form.fieldProps('designers').onChange([]);
        fieldArray.tagTeams.groups.forEach(({ team }, index) => {
            if (team.value === value)
                fieldArray.tagTeams.remove(index);
        });
    };
    const getDesignerOptions = useCallback((designTeamId) => {
        if (designTeamId) {
            const team = teams.find(({ value }) => value === designTeamId);
            return team ? team.designers : [];
        }
        return [];
    }, [teams]);
    const availableTagTeams = useMemo(() => teams.filter((team) => team.value !== form.values.team), [teams, form.values.team]);
    const submit = () => handleSubmit(({ form, fieldArray }) => onSubmit(changeStatusPayload.designInProgress({
        design_team: {
            id: form.team,
            designers: form.designers,
        },
        tag_teams: fieldArray.tagTeams.map(({ team, designers }) => ({ id: team, designers })),
    })));
    return {
        fieldArray,
        form,
        handleDesignTeamChange,
        handleSubmit: submit,
        isSubmitting,
        options: {
            designTeams: teams,
            getDesigners: getDesignerOptions,
            tagTeams: availableTagTeams,
        },
    };
}
