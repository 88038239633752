import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { Errors } from '~/common/components';
import { cx } from '~/common/utils';
import { FormElementLabel } from '../FormElementLabel';
import styles from './Textarea.module.scss';
export const TextArea = forwardRef(({ errors, touched, className, title, optional, hint, ...props }, ref) => {
    const handleChange = (event) => {
        props.onChange(event.currentTarget.value);
    };
    return (_jsxs("label", { ref: ref, className: cx('flex flex-col', className), children: [_jsx(FormElementLabel, { title: title, optional: optional, hint: hint }), _jsx("textarea", { ...props, className: cx(styles.field, { [styles.invalid]: errors }), onChange: handleChange }), errors && _jsx(Errors, { color: true, errors: errors })] }));
});
