import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Button, PageDetailsCard, useModalContext } from '~/common/components';
import { IndividualPricingTable, normalizeTreatments } from '~/common/other';
import { useCustomerData, useInitData } from '~/customers/hooks';
import { useCurrentUserData } from '~/root';
import { EditIndividualPricing } from './EditIndividualPricing';
export const IndividualPricing = () => {
    const init = useInitData();
    const customer = useCustomerData();
    const { permissions } = useCurrentUserData();
    const { modalOpener } = useModalContext();
    const data = useMemo(() => {
        return normalizeTreatments(customer.treatments, init.treatments, init.default_treatments, {
            is10hEnabled: customer.features.ten_turnaround,
        });
    }, [customer.features, customer.treatments, init.default_treatments, init.treatments]);
    return (_jsx(PageDetailsCard, { label: "Individual pricing", actionButton: permissions.customersUpdate && (_jsx(Button, { onClick: modalOpener(EditIndividualPricing), color: "grey", children: "Edit" })), children: _jsx(IndividualPricingTable, { data: data }) }));
};
