import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover } from '~/common/components';
import { cx, lastItem, noop } from '~/common/utils';
import { useOrderData } from '~/orders/hooks';
import { InlineInput, InlineInputField, SectionCell } from '~/orders/Order';
import { AdjustExtraCapacity } from './AdjustExtraCapacity';
export const DesignCapacity = ({ fieldProps, onApply, reasons }) => {
    const { iterations } = useOrderData();
    // TODO refactor condition
    const extraCapacityDisabled = iterations ? lastItem(iterations).name !== 'First draft' : false;
    return (_jsxs("div", { className: "space-y-1", children: [_jsx(SectionCell, { title: "Design capacity", titleClassName: "font-brand-t4m text-greyscale-500" }), _jsx(InlineInputField, { ...fieldProps('designCapacity.total'), id: "orderCapacity", readOnly: true, size: "small", textAlignment: "right", title: "Order capacity", type: "number" }), _jsx(Popover, { className: "w-[332px]", compensateOffset: 6, content: (props) => (_jsx(AdjustExtraCapacity, { ...props, onApply: onApply, reasons: reasons, value: fieldProps('designCapacity.extra').value })), trigger: (props) => (_jsx(InlineInput, { ...props, "data-tt": cx(extraCapacityDisabled && 'First draft extra capacity can no longer be changed'), onChange: noop, size: "small", textAlignment: "right", title: "Extra capacity", type: "number", disabled: extraCapacityDisabled, value: fieldProps('designCapacity.extra').value })) }), _jsx(InlineInput, { id: "totalCapacity", readOnly: true, size: "small", textAlignment: "right", title: "Total capacity", type: "number", value: fieldProps('designCapacity.total').value })] }));
};
