import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useRef } from 'react';
import { ModalHeader, ModalShell, useModalContext } from '~/common/components';
import { record, scrollToError } from '~/common/utils';
import { AdvancedInvoiceOptions, PaymentMethod, SubscriptionProducts, Summary, getProductInitialValues, } from '~/customers/Customer';
import { useInitData } from '~/subscriptions/hooks';
import { ConfirmUpdate } from './ConfirmUpdate';
import { useSubscriptionForm, useUpdateSubscriptionForm, } from './hooks';
import { UpdateSchedule } from './UpdateSchedule';
export const UpdateSubscription = ({ isScheduled, values, initialValues, initialProducts, onClose, }) => {
    const { modalOpener } = useModalContext();
    const { filter_options: { plan: productsList }, } = useInitData();
    const productsTypes = useMemo(() => {
        const { all, ...types } = productsList;
        return record.keys(types);
    }, [productsList]);
    const fullInitialValues = useRef({
        ...productsTypes.reduce((initialValues, productType) => {
            initialValues = {
                ...initialValues,
                ...getProductInitialValues(productType),
            };
            return initialValues;
        }, {}),
        ...initialValues,
    });
    const { form, products } = useSubscriptionForm({
        initialValues: fullInitialValues.current,
        productsList: productsTypes,
        initialProducts,
        isScheduled,
        values,
    });
    const { designTeams, isGracePeriod, paymentMethods, projectManagers, isCustomerTrusted, supportedCurrencies, renewalPeriodOptions, haveIndividualPricing, isInvoicePaymentMethodSelected, invoicePreviewData, isLoading, productsMap, ownerId, isDirty, updates, price, submit, addProduct, onPlanChange, removeProduct, getPayloadFromForm, onPaymentMethodChange, onPriceRelatedFieldBlur, onPriceRelatedFieldChange, } = useUpdateSubscriptionForm({
        form,
        products,
        values,
        initialValues: fullInitialValues.current,
    });
    const title = isScheduled ? 'Schedule subscription update' : 'Update subscription';
    const submitText = isScheduled ? 'Schedule updates' : 'Update subscription';
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: title }), _jsxs(ModalShell, { className: "px-5 py-4 space-y-5 w-[1010px]", onClose: onClose, onSubmit: () => {
                    if (Object.entries(updates).filter(([_, changes]) => changes.length).length) {
                        form.handleSubmit({
                            onSuccess: async (values) => modalOpener(ConfirmUpdate, {
                                data: updates,
                                payload: getPayloadFromForm(values),
                                onCancel: modalOpener(UpdateSubscription, {
                                    isScheduled,
                                    values,
                                    initialValues: fullInitialValues.current,
                                    initialProducts: products.list,
                                }),
                            })(),
                            onFailure: scrollToError,
                        });
                    }
                    else {
                        submit(onClose);
                    }
                }, loading: isLoading, submitText: submitText, disabled: !isDirty, children: [_jsx(SubscriptionProducts, { form: form, showIndividualPricingBanner: haveIndividualPricing &&
                            form.values.retainer_plan !== fullInitialValues.current.retainer_plan, isGracePeriod: isGracePeriod, removeProduct: removeProduct, addProduct: addProduct, productsMap: productsMap, supportedCurrencies: supportedCurrencies, renewalPeriodOptions: renewalPeriodOptions, projectManagers: projectManagers, designTeams: designTeams, onPlanChange: onPlanChange, onPriceRelatedFieldBlur: onPriceRelatedFieldBlur, onPriceRelatedFieldChange: onPriceRelatedFieldChange }), isScheduled && _jsx(UpdateSchedule, { form: form }), _jsx(PaymentMethod, { form: form, paymentMethods: paymentMethods, isCustomerTrusted: isCustomerTrusted, onPaymentMethodChange: onPaymentMethodChange, onInvoiceMethodModalClose: modalOpener(UpdateSubscription, {
                            isScheduled,
                            values: form.values,
                            initialValues: fullInitialValues.current,
                            initialProducts: products.list,
                        }) }), isInvoicePaymentMethodSelected && _jsx(AdvancedInvoiceOptions, { form: form }), _jsx(Summary, { price: price, ownerId: ownerId, invoicePreviewData: invoicePreviewData, proratedProduct: products.list
                            .filter(({ id }) => Boolean(id))
                            .map(({ type }) => type)
                            .join(' + '), billing_date: isScheduled ? form.values.schedule_update_at : undefined })] })] }));
};
