import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useForm, validator } from 'formoid';
import { array } from 'fp-ts';
import { ModalHeader, ModalShell, TextArea } from '~/common/components';
import { customValidator } from '~/common/utils';
import { useSendMessage } from '~/orders/hooks';
import { FilesUploadField } from './FilesUploadField';
const MAX_UPLOADED_FILES_AMOUNT = 20;
export const SendFiles = ({ onClose }) => {
    const [sendMessage] = useSendMessage({ isNote: false });
    const { fieldProps, handleSubmit, isSubmitting } = useForm({
        initialValues: {
            files: [],
            message: '',
        },
        validationStrategy: 'onSubmit',
        validators: () => ({
            files: validator.fromPredicate(array.isNonEmpty, 'Please upload at least one file (only images are allowed)'),
            message: customValidator.nonBlankString('Please enter a message'),
        }),
    });
    const submit = () => handleSubmit((values) => sendMessage(values).then(onClose));
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Upload files" }), _jsxs(ModalShell, { className: "space-y-2 p-3 w-[712px]", loading: isSubmitting, onClose: onClose, onSubmit: submit, submitText: "Send", children: [_jsx(FilesUploadField, { ...fieldProps('files'), maxFilesAmount: MAX_UPLOADED_FILES_AMOUNT }), _jsx(TextArea, { ...fieldProps('message'), title: "Leave a message", placeholder: "Leave your message" })] })] }));
};
