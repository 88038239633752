import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faCalendarCircleMinus } from '@fortawesome/pro-regular-svg-icons';
import { format, formatDistanceStrict } from 'date-fns';
import { Fragment } from 'react';
import { Button, IconBox } from '~/common/components';
import { useToggle } from '~/common/hooks';
import { record } from '~/common/utils';
import { useOfficeData } from '../hooks';
const HolidayItem = ({ name, date }) => {
    const [day, weekDay] = format(date, 'd eee').toLowerCase().split(' ');
    return (_jsxs("div", { className: "flex min-h-[54px] box-content border border-solid border-greyscale-200 rounded-lg mb-1", children: [_jsxs("div", { className: "flex flex-col items-center justify-center w-7 bg-greyscale-100 rounded-l-lg", children: [_jsx("div", { className: "text-[19px] leading-6 font-bold text-primary-300", children: day }), _jsx("div", { className: "font-brand-c1r text-greyscale-500", children: weekDay })] }), _jsxs("div", { className: "flex flex-col justify-center w-full p-1 rounded-r-lg bg-greyscale-50", children: [_jsx("div", { className: "font-brand-b1", children: name }), _jsx("div", { className: "font-brand-c1r text-greyscale-400", children: formatDistanceStrict(date, new Date(), { addSuffix: true }) })] })] }));
};
export const Holidays = () => {
    const office = useOfficeData();
    const [more, toggleMore] = useToggle();
    const entries = record.entries(office.holidays);
    const shownEntries = more ? entries : entries.slice(0, 1);
    const hasEntries = entries.length > 0;
    return (_jsxs("div", { className: "w-1/4 max-w-[400px] pb-2 rounded bg-greyscale-0", children: [_jsxs("div", { className: "flex items-center justify-between pl-3 pr-1 h-6 border-b border-b-greyscale-200 border-solid", children: [_jsx("span", { className: "font-brand-t3 text-greyscale-500 uppercase", children: "Upcoming holidays" }), hasEntries && (_jsx(Button, { color: "text-secondary", onClick: toggleMore, children: more ? 'Show less' : 'Show more' }))] }), _jsx("div", { className: "px-3 overflow-y-scroll", children: hasEntries ? (shownEntries.map(([month, holidays]) => (_jsxs(Fragment, { children: [_jsx("div", { className: "font-brand-t1m my-2", children: month }), holidays.map((holiday) => (_jsx(HolidayItem, { ...holiday }, holiday.date.toString())))] }, month)))) : (_jsxs("div", { className: "flex flex-col items-center justify-center h-[116px] pt-1", children: [_jsx(IconBox, { size: "m", icon: faCalendarCircleMinus }), _jsx("div", { className: "mt-1 font-brand-t4m text-greyscale-500", children: "No upcoming holidays" })] })) })] }));
};
