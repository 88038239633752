import { useMutation } from '@tanstack/react-query';
import { Toast } from '~/common/components';
import { useUpdateCache } from '~/common/hooks';
import { httpClient, tusUpload } from '~/common/utils';
import { User, userStore } from '~/root';
import { qk } from '~/root/query-keys';
const PATCH_PROFILE_ENDPOINT = '/v1/staff/users';
export function useUpdateCurrentUserCache() {
    return useUpdateCache(qk.init, 'Current user cache is not defined!');
}
export const useProfileMutation = () => {
    const updateCurrentUser = useUpdateCurrentUserCache();
    return useMutation({
        mutationFn: (data) => httpClient.patch(PATCH_PROFILE_ENDPOINT, { data, decoder: User }),
        onSuccess: (user) => {
            userStore.setState({
                id: String(user.id),
                name: user.name,
                email: user.email,
                role: user.role,
            });
            updateCurrentUser((init) => ({ ...init, ...user }));
            Toast.success({
                title: '✔️ Successfully saved',
                subTitle: 'Your changes have been saved',
            });
        },
    });
};
export const useAvatarUploadMutation = () => {
    return useMutation({
        mutationFn: (file) => tusUpload({ file, tag: 'user_avatar' }),
    });
};
