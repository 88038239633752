import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Badge, FormElementLabel, SegmentedButton } from '~/common/components';
const className = 'w-fit flex gap-1';
export const Tags = () => {
    const [size, setSize] = useState('small');
    return (_jsxs("div", { className: "bg-greyscale-0 p-2 rounded space-y-3", children: [_jsx("div", { className: "font-brand-h1 w-full mb-3", children: "Tags" }), _jsxs("div", { className: "flex gap-2 items-center", children: [_jsx(FormElementLabel, { title: "Size: " }), _jsx(SegmentedButton, { value: size, setValue: setSize, options: [
                            { value: 'small', children: 'Small' },
                            { value: 'medium', children: 'Medium' },
                        ] })] }), _jsxs("div", { className: "flex flex-col w-fit gap-2", children: [_jsx(Badge, { className: className, size: size, color: "danger", children: _jsx("span", { children: "danger" }) }), _jsx(Badge, { className: className, size: size, color: "success", children: "success" }), _jsx(Badge, { className: className, size: size, color: "warning", children: "warning" }), _jsx(Badge, { className: className, size: size, color: "secondary", children: "secondary" }), _jsx(Badge, { className: className, size: size, color: "accent", children: "accent" }), _jsx(Badge, { className: className, size: size, color: "primary", children: "primary" }), _jsx(Badge, { className: className, size: size, color: "info", children: "info" }), _jsx(Badge, { className: className, size: size, color: "accent-red", children: "accent-red" }), _jsx(Badge, { className: className, size: size, color: "accent-salad-green", children: "accent-salad-green" }), _jsx(Badge, { className: className, size: size, color: "grey", children: "grey" }), _jsx(Badge, { className: className, size: size, color: "lighter-grey", children: "lighter-grey" }), _jsx(Badge, { className: className, size: size, color: "grey-solid", children: "grey-solid" }), _jsx(Badge, { className: className, size: size, color: "danger-solid", children: "danger-solid" }), _jsx(Badge, { className: className, size: size, color: "secondary-solid", children: "secondary-solid" })] })] }));
};
