import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { faList, faListOl, faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import { faBlockQuote, faBold, faH1, faH2, faH3, faH4, faH5, faH6, faItalic, faLink, faStrikethrough, faTextSlash, faUnderline, } from '@fortawesome/pro-solid-svg-icons';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Button } from '~/common/components';
import { record } from '~/common/utils';
import { useFormatting } from './useFormatting';
const ENABLE_HEADINGS = false;
const headingIcons = {
    h1: faH1,
    h2: faH2,
    h3: faH3,
    h4: faH4,
    h5: faH5,
    h6: faH6,
};
export const ToolbarPlugin = ({ children }) => {
    const [editor] = useLexicalComposerContext();
    const f = useFormatting(editor);
    return (_jsxs("div", { className: "flex items-center gap-1", children: [_jsx(Button.Icon, { icon: faBold, active: f.isBold, onClick: f.formatBold, "data-tt": "Bold (CTRL+B)" }), _jsx(Button.Icon, { icon: faItalic, active: f.isItalic, onClick: f.formatItalic, "data-tt": "Italic (CTRL+I)" }), _jsx(Button.Icon, { icon: faUnderline, active: f.isUnderline, onClick: f.formatUnderline, "data-tt": "Underline (CTRL+U)" }), _jsx(Button.Icon, { icon: faStrikethrough, active: f.isStrikethrough, onClick: f.formatStrikethrough, "data-tt": "Strikethrough" }), _jsx("div", { className: "w-[1px] h-3 bg-greyscale-200" }), _jsx(Button.Icon, { icon: faLink, active: f.isLink, onClick: f.toggleLink, "data-tt": "Add link (CTRL+K, CTRL+V on selected text)" }), _jsx("div", { className: "w-[1px] h-3 bg-greyscale-200" }), _jsx(Button.Icon, { icon: faList, active: f.isBullet, onClick: f.formatBulletList, "data-tt": "Bullet list (-<space>)" }), _jsx(Button.Icon, { icon: faListOl, active: f.isNumber, onClick: f.formatNumberedList, "data-tt": "Ordered list (1.<space>)" }), _jsx("div", { className: "w-[1px] h-3 bg-greyscale-200" }), _jsx(Button.Icon, { icon: faBlockQuote, active: f.isQuote, onClick: f.formatQuote, "data-tt": "Quote (><space>)" }), _jsx("div", { className: "w-[1px] h-3 bg-greyscale-200" }), _jsx(Button.Icon, { icon: faTextSlash, onClick: f.clearFormatting, "data-tt": "Clear formatting" }), _jsx("div", { className: "w-[1px] h-3 bg-greyscale-200" }), _jsx(Button.Icon, { icon: faPaperclip, disabled: true, "data-tt": "File uploading is under consideration for the next iteration \uD83D\uDE80" }), ENABLE_HEADINGS && (_jsxs(_Fragment, { children: [_jsx("div", { className: "w-[1px] h-3 bg-greyscale-200" }), record.entries(headingIcons).map(([heading, icon]) => (_jsx(Button.Icon, { icon: icon, active: f.blockType === heading, onClick: () => f.formatHeading(heading), "data-tt": `Heading ${heading.toUpperCase()}` }, heading)))] })), children] }));
};
