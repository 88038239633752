import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faCheckCircle, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { Avatar, Collapse, IconBox } from '~/common/components';
import { useToggle } from '~/common/hooks';
import { cx } from '~/common/utils';
export const AvatarList = ({ title, colorSets, isColorSelected, toggleColor, children, }) => {
    const [show, toggleShow] = useToggle(true);
    return (_jsxs("div", { className: "flex flex-wrap bg-greyscale-0 py-2 px-3 rounded", children: [_jsxs("div", { className: "flex font-brand-h1 w-full cursor-pointer -m-2 p-2", onClick: toggleShow, children: [_jsx("span", { children: title }), _jsx(IconBox, { size: "l", icon: faChevronUp, className: cx('ml-1 transition-brand', {
                            'rotate-180': show,
                        }) })] }), _jsx(Collapse, { in: show, className: "w-full", children: _jsxs("div", { className: "flex mt-3", children: [_jsx("div", { className: "flex flex-wrap gap-8 w-full", children: colorSets.map((set) => (_jsxs("div", { className: "relative flex items-center justify-center h-15 w-20 cursor-pointer", onClick: () => toggleColor(set), children: [_jsx("div", { className: "absolute top-1 w-full h-6 rounded", style: { backgroundColor: set.color } }), _jsx(Avatar, { size: "big", colorSet: set, className: "z-10", children: "AB" }), _jsx(IconBox, { size: "l", icon: faCheckCircle, className: cx('absolute bottom-[20px] right-[4px] text-greyscale-500 transition-brand', isColorSelected(set) ? 'opacity-1' : 'opacity-0') })] }, set.color))) }), children && _jsx("div", { className: "flex flex-col space-y-3", children: children })] }) })] }));
};
