// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { POSTHOG_HOST, POSTHOG_KEY } from '../src/env';
import { throwPlaceholderErrorIf } from './utils';
throwPlaceholderErrorIf(!!(POSTHOG_HOST && POSTHOG_KEY), 'posthog-js');
// This is a placeholder for development, since we don't want to have posthog in dev bundle
// We have alias in webpack config to point to this file in devmode
// You should use
// import posthog from 'posthog-js'
// There's no need to import directly from this file
export default {
    init: (...args) => console.info('posthog init', ...args),
    capture: (...args) => console.info('posthog capture', ...args),
    identify: (...args) => console.info('posthog identify', ...args),
    reset: () => console.info('posthog reset'),
};
