import { format, formatDistanceStrict, isAfter } from 'date-fns';
import { differenceInYears, differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds, subDays, subHours, subMinutes, } from 'date-fns/fp';
import { array, option } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { cx, isPositiveNumber, pluralizeWord } from '~/common/utils';
/**
 * Format date - the only date format that should be used in all places
 */
export function formatDate(date) {
    return format(date, 'd MMM yyyy HH:mm');
}
export function formatDateShort(date) {
    return format(date, 'd MMM y');
}
export function formatYearMonthDay(date) {
    return format(date, 'yyyy-MM-dd');
}
export function formatDayMonthYear(date) {
    return format(date, 'd MMM yyyy');
}
export function formatDayFullMonthYear(date) {
    return format(date, 'd MMMM yyyy');
}
export function formatMonthDay(date) {
    return format(date, 'iii d').toLowerCase();
}
export function formatTimeAtDay(date) {
    return format(date, 'd MMM yyyy - k:mm').replace('-', 'at');
}
export function formatDateDifference(left, right) {
    const years = differenceInYears(left, right);
    const days = differenceInDays(left, right);
    const hours = pipe(right, subDays(days), differenceInHours(left));
    const minutes = pipe(right, subDays(days), subHours(hours), differenceInMinutes(left));
    const daysHoursMinutes = array
        .compact([
        pipe(years, option.fromPredicate(isPositiveNumber), option.map((years) => `${years}y`)),
        pipe(days, option.fromPredicate(isPositiveNumber), option.map((days) => `${days % 365}d`)),
        pipe(hours, option.fromPredicate(isPositiveNumber), option.map((hours) => `${hours}h`)),
        pipe(minutes, option.fromPredicate(isPositiveNumber), option.map((minutes) => `${minutes}m`)),
    ])
        .join(' ');
    if (!daysHoursMinutes) {
        const seconds = pipe(right, subDays(days), subHours(hours), subMinutes(minutes), differenceInSeconds(left));
        return `${seconds}s`;
    }
    return daysHoursMinutes;
}
/**
 * Gets timezoned localtime, timezone format: 0, 60, 120 etc.
 */
export function getTimezonedLocalTime(timezone) {
    const date = new Date();
    date.setMinutes(date.getMinutes() + timezone);
    return date;
}
/**
 * Gets date without time to calculate date intervals
 * correctly with timeless dates coming from backend
 */
export function getDateWithoutTime(date = new Date()) {
    date.setHours(0, 0, 0, 0);
    return date;
}
export const formatDateDistance = (to, from = new Date()) => {
    const expired = isAfter(from, to);
    const output = formatDistanceStrict(to, from);
    return expired ? `${output} ago` : `in ${output}`;
};
export function formatMonthToYearsAndMonth(numberOfMonths) {
    const years = Math.floor(numberOfMonths / 12);
    const months = numberOfMonths % 12;
    return cx(years && pluralizeWord('year', years), months && pluralizeWord('month', months));
}
