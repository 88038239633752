import { AutoLinkNode, LinkNode } from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
export const LinksNewTabPlugin = () => {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        const updateLink = (node) => {
            var _a;
            (_a = editor.getElementByKey(node.getKey())) === null || _a === void 0 ? void 0 : _a.setAttribute('target', '_blank');
        };
        let linkNodeCleanup;
        let autoLinkNodeCleanup;
        // Register the transform after the initial editor state is rendered
        const unsubscribe = editor.registerUpdateListener(() => {
            linkNodeCleanup = editor.registerNodeTransform(LinkNode, updateLink);
            autoLinkNodeCleanup = editor.registerNodeTransform(AutoLinkNode, updateLink);
            // Unregister the update listener to avoid repeated registrations
            unsubscribe();
        });
        // Return a cleanup function to unregister the node transforms
        return () => {
            linkNodeCleanup === null || linkNodeCleanup === void 0 ? void 0 : linkNodeCleanup();
            autoLinkNodeCleanup === null || autoLinkNodeCleanup === void 0 ? void 0 : autoLinkNodeCleanup();
            unsubscribe();
        };
    }, [editor]);
    return null;
};
