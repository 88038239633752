import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef } from 'react';
import { Show } from '~/common/components';
import { cx } from '~/common/utils';
import { useCurrentUserData } from '~/root';
import { Header } from './Header';
import { Messages } from './Messages';
import { SendMessage } from './SendMessage';
import css from './Chat.module.scss';
export const Chat = ({ customer, isExtended = false }) => {
    const { permissions } = useCurrentUserData();
    const ref = useRef(null);
    const scrollToBottom = useCallback(() => {
        if (ref.current)
            ref.current.scrollTop = ref.current.scrollHeight;
    }, [ref]);
    return (_jsxs("div", { className: cx(css.container, isExtended && css.extended), children: [_jsx(Header, { customer: customer, isExtended: isExtended }), _jsx(Messages, { isExtended: isExtended, messagesRef: ref, scrollToBottom: scrollToBottom }), _jsx(Show, { if: permissions.ordersChat, children: _jsx(SendMessage, { isExtended: isExtended, scrollToBottom: scrollToBottom }) })] }));
};
