import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { Button, ButtonGroup, Input, ModalHeader, Tooltip } from '~/common/components';
import { IndividualPricingTableHead, PRICING_HOURS, } from '~/common/other';
import { cx } from '~/common/utils';
import { useCustomerData, useInitData, useTreatmentsPricingMutation } from '~/customers/hooks';
const isLastIndex = (index, array) => index === array.length - 1;
const getRowClassName = (borderBottom, other) => cx('flex items-center justify-center h-5 box-content', borderBottom && 'border-greyscale-200 border-b', other);
const Row = ({ hour, entries, borderBottom }) => (_jsxs(_Fragment, { children: [_jsx("div", { className: getRowClassName(borderBottom, 'font-brand-t4m'), children: _jsxs("span", { children: [hour, " hours"] }) }), entries.map((entry, index) => {
            var _a;
            return (_jsxs("div", { className: getRowClassName(borderBottom, 'grid grid-cols-2'), children: [_jsx("div", { className: "flex items-center justify-center", children: _jsx("span", { className: "font-brand-b1 text-greyscale-400", children: entry.old !== null ? `$${entry.old}` : '—' }) }), _jsx("div", { className: "flex items-center justify-center", children: _jsx(Tooltip, { content: entry.disabled && 'We temporary disabled this pricing editing', children: _jsx(Input, { className: cx('w-5', entry.individual
                                    ? 'font-brand-t4m text-secondary-400'
                                    : 'font-brand-b1 text-greyscale-500'), onChange: (e) => {
                                    const value = Math.max(e.target.valueAsNumber, 0);
                                    entry.onChange(isNaN(value) ? null : value);
                                }, size: "small", textAlignment: "center", type: "number", placeholder: "\u2014", disabled: entry.disabled, value: (_a = entry.value) !== null && _a !== void 0 ? _a : '' }) }) })] }, index));
        })] }));
export const EditIndividualPricing = ({ onClose }) => {
    const init = useInitData();
    const customer = useCustomerData();
    return (_jsx(EditIndividualPricingPropsable, { onClose: onClose, treatments: init.treatments, defaultTreatments: init.default_treatments, customerTreatments: customer.treatments }));
};
export const EditIndividualPricingPropsable = ({ onClose, treatments, defaultTreatments, customerTreatments, }) => {
    const treatmentsMutation = useTreatmentsPricingMutation();
    const gridTemplateColumns = useMemo(() => {
        return `80px ${treatments.map(() => '1fr').join(' ')}`;
    }, [treatments]);
    const [values, setValues] = useState(() => customerTreatments);
    const onSubmit = () => {
        treatmentsMutation.mutateAsync({ treatments: values }).then(onClose);
    };
    const reset = () => setValues(defaultTreatments);
    const getEntries = (hour) => {
        return treatments.map((treatment) => {
            var _a, _b;
            const formValue = (_a = values[treatment.id]) === null || _a === void 0 ? void 0 : _a[hour];
            const defaultValue = (_b = defaultTreatments[treatment.id]) === null || _b === void 0 ? void 0 : _b[hour];
            // TODO: Remove together with "disabled" field in "Entry" interface
            const disabled = hour === '10' && treatment.id === '8'; // LUD 10h pricing condition
            return {
                old: defaultValue,
                value: formValue,
                individual: formValue !== defaultValue,
                disabled,
                onChange: (value) => {
                    !disabled &&
                        setValues((prev) => ({
                            ...prev,
                            [treatment.id]: {
                                ...prev[treatment.id],
                                [hour]: value,
                            },
                        }));
                },
            };
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Individual pricing" }), _jsx("div", { className: "p-3 space-y-3 w-[688px]", children: _jsxs("div", { className: "grid gap-x-3 overflow-x-scroll", style: { gridTemplateColumns }, children: [_jsx(IndividualPricingTableHead, { treatments: treatments }), _jsx("div", {}), treatments.map((_, index) => (_jsxs("div", { className: "grid grid-cols-2 mt-1 mb-[4px] font-brand-c1r text-greyscale-500 text-center", children: [_jsx("span", { children: "Old" }), _jsx("span", { children: "New" })] }, index))), PRICING_HOURS.map((hour, index) => (_jsx(Row, { hour: hour, entries: getEntries(hour), borderBottom: !isLastIndex(index, PRICING_HOURS) }, hour)))] }) }), _jsxs(ButtonGroup, { children: [_jsx(Button, { className: "mr-auto", color: "text-secondary", size: "xs", onClick: reset, children: "Reset changes" }), _jsx(Button, { size: "medium", onClick: onClose, disabled: treatmentsMutation.isLoading, children: "Cancel" }), _jsx(Button, { color: "primary", size: "medium", onClick: onSubmit, loading: treatmentsMutation.isLoading, children: "Save" })] })] }));
};
