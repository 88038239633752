import { z } from 'zod';
import { LexicalEditorState } from '~/common/kits/editor';
import { DateFromIsoString } from '~/common/utils';
export const ChecklistItem = z
    .object({
    id: z.number().int().positive(),
    content: LexicalEditorState,
    done: z.boolean(),
    index: z.number(),
    marked_by: z
        .object({
        actor: z.object({
            id: z.number(),
            name: z.string(),
            avatar_link: z.string().nullable(),
        }),
        date: DateFromIsoString,
    })
        .nullable(),
})
    .transform((item) => ({ ...item, name: '' }));
export const Checklist = z.object({
    items: z.array(ChecklistItem),
});
