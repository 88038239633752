import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faCalendarClock } from '@fortawesome/pro-regular-svg-icons';
import { addDays, isFuture } from 'date-fns';
import { useMemo } from 'react';
import { Button, Menu, useModalContext } from '~/common/components';
import { ActivateSubscription, SubscriptionInfo, transformProductToFormValues, } from '~/customers/Customer';
import { subscriptionStatusMap, } from '~/customers/domain';
import { useCurrentUserData } from '~/root';
import { useInitData, useSubscriptionData } from '~/subscriptions/hooks';
import { UpdateSubscription } from '../UpdateSubscription';
import { useCreateSubscriptionData } from '../useCreateSubscriptionData';
import { CancelSubscriptionPopup } from './CancelSubscriptionPopup';
export const Header = () => {
    const { products, status, ends_at, trial_ends_at, starts_at } = useSubscriptionData();
    return (_jsxs("div", { className: "flex items-center justify-between px-3 py-2 bg-greyscale-50", children: [_jsx(SubscriptionInfo, { products: products, status: status, dates: { starts_at, trial_ends_at, ends_at } }), _jsx(SubscriptionActions, {})] }));
};
const updatableStatuses = [
    subscriptionStatusMap.active,
    subscriptionStatusMap.on_trial,
    subscriptionStatusMap.past_due,
    subscriptionStatusMap.unpaid,
    subscriptionStatusMap.scheduled,
];
const reactivateStatuses = [subscriptionStatusMap.incomplete];
const SubscriptionActions = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    const subscription = useSubscriptionData();
    const { filter_options: { plan: { all: productsOptions }, }, } = useInitData();
    const { permissions } = useCurrentUserData();
    const { modalOpener } = useModalContext();
    const isUpdatable = useMemo(() => permissions.subscriptionsUpdate &&
        (updatableStatuses.includes(subscription.status.value) ||
            // Grace period conditions
            (subscription.status.value === subscriptionStatusMap.cancelled &&
                subscription.ends_at &&
                isFuture(subscription.ends_at))), [permissions.subscriptionsUpdate, subscription.ends_at, subscription.status.value]);
    const canReactivate = useMemo(() => permissions.subscriptionsCreate &&
        (reactivateStatuses.includes(subscription.status.value) ||
            (subscription.status.value === subscriptionStatusMap.cancelled && !subscription.ends_at)), [permissions.subscriptionsCreate, subscription.ends_at, subscription.status.value]);
    const initialProducts = useMemo(() => subscription.products.map(({ id, plan: { type } }) => ({
        id,
        type,
    })), [subscription.products]);
    const successTeamValidation = useMemo(() => {
        var _a;
        const retainerProductId = (_a = subscription.products.find(({ plan }) => plan.type === 'retainer')) === null || _a === void 0 ? void 0 : _a.plan.value;
        if (retainerProductId) {
            const retainerProductSettings = productsOptions.find(({ value }) => value === retainerProductId);
            return {
                success_manager_id_required: retainerProductSettings.success_manager_id_required,
                business_partner_id_required: retainerProductSettings.business_partner_id_required,
                account_manager_id_required: retainerProductSettings.account_manager_id_required,
            };
        }
        return {
            success_manager_id_required: false,
            business_partner_id_required: false,
            account_manager_id_required: false,
        };
    }, [productsOptions, subscription.products]);
    return (_jsxs("div", { className: "flex gap-x-2", children: [isUpdatable && (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: modalOpener(UpdateSubscription, {
                            initialValues: {
                                ...mapSubscriptionProductsToFormValues(subscription.products),
                                retainer_project_manager_id: ((_a = subscription.project_managers_list[0]) === null || _a === void 0 ? void 0 : _a.value) || null,
                                retainer_design_team_id: ((_b = subscription.dedicated_team.design_team) === null || _b === void 0 ? void 0 : _b.value) || null,
                                currency: subscription.currency,
                                renewal_period: subscription.renewal_period,
                                payment_method_id: (_d = (_c = subscription.payment_method) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : null,
                                po_number: subscription.payment_method &&
                                    'po_number' in subscription.payment_method &&
                                    subscription.payment_method.po_number !== null
                                    ? subscription.payment_method.po_number
                                    : '',
                                po_expires_at: subscription.payment_method && 'po_expires_at' in subscription.payment_method
                                    ? subscription.payment_method.po_expires_at
                                    : null,
                                prevent_sending_invoice: !subscription.send_invoice,
                                attention: subscription.payment_method &&
                                    'attention' in subscription.payment_method &&
                                    subscription.payment_method.attention !== null
                                    ? subscription.payment_method.attention
                                    : '',
                                custom_lines: subscription.payment_method &&
                                    'invoice_lines' in subscription.payment_method &&
                                    subscription.payment_method.invoice_lines !== null
                                    ? subscription.payment_method.invoice_lines
                                    : '',
                                schedule_update_at: null,
                            },
                            initialProducts,
                        }), color: "primary", children: "Update subscription" }), _jsx(Button.Icon, { "data-tt": "Schedule subscription plan updates", icon: faCalendarClock, color: "grey-outline", size: "m", onClick: modalOpener(UpdateSubscription, {
                            isScheduled: true,
                            initialValues: {
                                ...mapSubscriptionProductsToFormValues(subscription.products),
                                retainer_project_manager_id: ((_e = subscription.project_managers_list[0]) === null || _e === void 0 ? void 0 : _e.value) || null,
                                retainer_design_team_id: ((_f = subscription.dedicated_team.design_team) === null || _f === void 0 ? void 0 : _f.value) || null,
                                currency: subscription.currency,
                                renewal_period: subscription.renewal_period,
                                payment_method_id: (_h = (_g = subscription.payment_method) === null || _g === void 0 ? void 0 : _g.id) !== null && _h !== void 0 ? _h : null,
                                po_number: subscription.payment_method &&
                                    'po_number' in subscription.payment_method &&
                                    subscription.payment_method.po_number !== null
                                    ? subscription.payment_method.po_number
                                    : '',
                                po_expires_at: subscription.payment_method && 'po_expires_at' in subscription.payment_method
                                    ? subscription.payment_method.po_expires_at
                                    : null,
                                prevent_sending_invoice: !subscription.send_invoice,
                                attention: subscription.payment_method &&
                                    'attention' in subscription.payment_method &&
                                    subscription.payment_method.attention !== null
                                    ? subscription.payment_method.attention
                                    : '',
                                custom_lines: subscription.payment_method &&
                                    'invoice_lines' in subscription.payment_method &&
                                    subscription.payment_method.invoice_lines !== null
                                    ? subscription.payment_method.invoice_lines
                                    : '',
                                schedule_update_at: addDays(new Date(), 1),
                            },
                            initialProducts,
                        }) })] })), canReactivate && (_jsx(Button, { onClick: modalOpener(ActivateSubscription, {
                    useCreateSubscriptionData,
                    initialValues: {
                        ...mapSubscriptionProductsToFormValues(subscription.products),
                        retainer_project_manager_id: ((_j = subscription.project_managers_list[0]) === null || _j === void 0 ? void 0 : _j.value) || null,
                        retainer_design_team_id: ((_k = subscription.dedicated_team.design_team) === null || _k === void 0 ? void 0 : _k.value) || null,
                        currency: subscription.currency,
                        renewal_period: subscription.renewal_period,
                        is_trial: false,
                        trial_ends_at: null,
                        activate_at: new Date(),
                        payment_method_id: (_m = (_l = subscription.payment_method) === null || _l === void 0 ? void 0 : _l.id) !== null && _m !== void 0 ? _m : null,
                        po_number: subscription.payment_method &&
                            'po_number' in subscription.payment_method &&
                            subscription.payment_method.po_number !== null
                            ? subscription.payment_method.po_number
                            : '',
                        po_expires_at: subscription.payment_method && 'po_expires_at' in subscription.payment_method
                            ? subscription.payment_method.po_expires_at
                            : null,
                        prevent_sending_invoice: !subscription.send_invoice,
                        success_manager_id: (_p = (_o = subscription.success_team.success_manager) === null || _o === void 0 ? void 0 : _o.id) !== null && _p !== void 0 ? _p : null,
                        business_partner_id: (_r = (_q = subscription.success_team.business_partner) === null || _q === void 0 ? void 0 : _q.id) !== null && _r !== void 0 ? _r : null,
                        account_manager_id: (_t = (_s = subscription.success_team.account_manager) === null || _s === void 0 ? void 0 : _s.id) !== null && _t !== void 0 ? _t : null,
                        ...successTeamValidation,
                        attention: subscription.payment_method &&
                            'attention' in subscription.payment_method &&
                            subscription.payment_method.attention !== null
                            ? subscription.payment_method.attention
                            : '',
                        custom_lines: subscription.payment_method &&
                            'invoice_lines' in subscription.payment_method &&
                            subscription.payment_method.invoice_lines !== null
                            ? subscription.payment_method.invoice_lines
                            : '',
                    },
                    initialProducts: initialProducts.map(({ type }) => ({ type })),
                }), children: "Activate subscription" })), permissions.subscriptionsCancel &&
                subscription.status.value !== subscriptionStatusMap.cancelled && (_jsx(Menu, { children: subscription.status.value !== subscriptionStatusMap.cancelled && (_jsx(Menu.Item, { onClick: modalOpener(CancelSubscriptionPopup), children: "Cancel" })) }))] }));
};
const mapSubscriptionProductsToFormValues = (products) => products.reduce((accum, product) => {
    accum = {
        ...accum,
        ...transformProductToFormValues(product),
    };
    return accum;
}, {});
