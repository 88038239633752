import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { getLocalStorage, handleStateAction, setLocalStorage } from '../utils';
// TODO this feels bad, API should be better, decoding safety should be everywhere
//
const useLocalStorageEffect = (key, value) => {
    useLayoutEffect(() => {
        setLocalStorage(key, value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);
};
export const useSharedState = (queryKey, initialData) => {
    const client = useQueryClient();
    const state = useQuery({
        queryKey: [queryKey],
        initialData,
        staleTime: Infinity,
        cacheTime: Infinity,
    }).data;
    const setState = useCallback((stateOrCb) => {
        client.setQueryData([queryKey], (prev) => handleStateAction(stateOrCb, prev));
    }, [queryKey, client]);
    return [state, setState];
};
export const usePersistentState = (key, initialData, decoder) => {
    const [state, setState] = useState(() => getLocalStorage(key, initialData, decoder));
    useLocalStorageEffect(key, state);
    return [state, setState];
};
export const usePersistentSharedState = (queryKey, initialData, decoder) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const localStorageOrInitial = useMemo(() => getLocalStorage(queryKey, initialData, decoder), []);
    const [state, setState] = useSharedState(queryKey, localStorageOrInitial);
    useLocalStorageEffect(queryKey, state);
    return [state, setState];
};
