import { useMutation } from '@tanstack/react-query';
import { array } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { useIdParam } from '~/common/hooks';
import { httpClient } from '~/common/utils';
import { GetOrder } from '~/orders/domain';
import { useOrderData, useUpdateOrderCache } from '~/orders/hooks';
import { qk } from '~/root/query-keys';
import { getErrorMessage, makeCardMethod } from './domain';
export function usePaymentMethods() {
    const { customer } = useOrderData();
    const cards = pipe(customer.payment_methods, array.filterMap(makeCardMethod));
    const credits = { type: 'credits', value: customer.credits };
    if (credits.value > 0) {
        return pipe(cards, array.prependW(credits));
    }
    return pipe(cards, array.appendW(credits));
}
export function useManualCharge({ onError, onSuccess }) {
    const id = useIdParam();
    const updateCache = useUpdateOrderCache();
    const { mutateAsync } = useMutation({
        mutationFn: (data) => httpClient.post(`v1/staff/orders/${id}/charge`, {
            data: data.type === 'credits' ? {} : { method: data.value.id },
            decoder: GetOrder,
        }),
        mutationKey: qk.orderManualCharge(id),
        onSuccess: (order) => {
            updateCache((previous) => ({ ...previous, ...order }));
            onSuccess();
        },
        onError: (error) => {
            const message = getErrorMessage(error);
            if (message)
                onError(message);
        },
    });
    return mutateAsync;
}
