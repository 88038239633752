import { useState } from 'react';
function sortNullable({ a, b, ascending }) {
    if (a === b)
        return -1;
    // nulls sort after anything else
    if (a === null)
        return 1;
    if (b === null)
        return -1;
    return ascending ? a - b : b - a;
}
const sortTable = ({ teams, dayIndex, shiftId, ascending = true }) => {
    if (dayIndex === null || shiftId === null) {
        return teams;
    }
    return teams.slice().sort((teamA, teamB) => {
        const getCapacity = (team) => {
            var _a, _b;
            return ((_b = (_a = team.daysByShiftId[dayIndex]) === null || _a === void 0 ? void 0 : _a.find((item) => item.id === shiftId)) === null || _b === void 0 ? void 0 : _b.capacity) || null;
        };
        return sortNullable({ a: getCapacity(teamA), b: getCapacity(teamB), ascending });
    });
};
const filterTable = ({ teams, officeId, shiftId }) => {
    if (officeId === null && shiftId === null) {
        return teams;
    }
    let result = teams;
    if (officeId) {
        result = teams.filter((row) => row.office_id === officeId);
    }
    if (shiftId) {
        result = result
            .filter((row) => { var _a; return (_a = row.daysByShiftId.find((day) => day.find((item) => item.id === shiftId))) === null || _a === void 0 ? void 0 : _a.length; })
            .map((row) => ({
            ...row,
            daysByShiftId: row.daysByShiftId.map((day) => day.filter((item) => item.id === shiftId)),
        }));
    }
    return result;
};
export const useCapacityPerShiftTable = (teams) => {
    const [sort, setSort] = useState({ shiftId: null, dayIndex: null, ascending: null });
    const [filter, setFilter] = useState({ shiftId: null, officeId: null });
    const filteredTeams = filterTable({ teams, ...filter });
    const sortedCapacityTable = sortTable({ teams: filteredTeams, ...sort });
    return {
        data: sortedCapacityTable,
        setSort,
        sort,
        setFilter,
        filter,
    };
};
