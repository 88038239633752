import { browserTracingIntegration, init, replayIntegration, setUser, showReportDialog, } from '@sentry/react';
import { ENV, SENTRY_DSN, SENTRY_RELEASE, SENTRY_REPLAY_SESSION_RATE, SENTRY_TRACES_SAMPLE_RATE, } from '~/env';
import { userStore } from './Auth';
// https://docs.sentry.io/platforms/javascript/guides/react/configuration/options
export const sentryInit = () => {
    init({
        attachStacktrace: true,
        debug: false,
        dsn: SENTRY_DSN,
        environment: ENV.AS_STRING,
        integrations: !ENV.DEVELOPMENT
            ? [
                browserTracingIntegration(),
                replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
            ]
            : undefined,
        release: SENTRY_RELEASE,
        tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
        replaysSessionSampleRate: SENTRY_REPLAY_SESSION_RATE,
        replaysOnErrorSampleRate: 1.0,
        ignoreErrors: [
            'AxiosError',
            'ResizeObserver loop',
            'Blocked a frame with origin',
            'WebWorker: writer not initialised. Service Should be Started.',
        ],
        beforeSend(event, _hint) {
            var _a, _b, _c, _d;
            const isDecodingError = ((_c = (_b = (_a = event.exception) === null || _a === void 0 ? void 0 : _a.values) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.type) === 'DecodingError';
            if (event.exception && !isDecodingError) {
                showReportDialog({ eventId: event.event_id, user: (_d = userStore.getState()) !== null && _d !== void 0 ? _d : undefined });
            }
            if (ENV.DEVELOPMENT) {
                console.error('sentry captured error', JSON.stringify(event, null, 2), _hint);
                return null;
            }
            return event;
        },
        maxValueLength: 3000,
        tunnel: `https://24slides.${ENV.PRODUCTION ? 'com' : 'dev'}/sentry`,
    });
    userStore.subscribe(setUser);
};
