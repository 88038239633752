import { useMutation } from '@tanstack/react-query';
import { useIdParam } from '~/common/hooks';
import { httpClient } from '~/common/utils';
import { qk } from '~/root/query-keys';
export function useDeleteRemoteFile() {
    const id = useIdParam();
    const { mutateAsync, isLoading } = useMutation({
        mutationKey: qk.orderFiles(id),
        mutationFn: (file) => httpClient.delete(`/v1/staff/orders/${id}/files/${file}`),
    });
    return [mutateAsync, isLoading];
}
