import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { z } from 'zod';
import { useEvent } from '~/common/hooks';
// TODO check if it works :D
export const getSort = (option, sort) => {
    if ((sort === null || sort === void 0 ? void 0 : sort.option) !== option) {
        return { option, order: 'asc' };
    }
    if ((sort === null || sort === void 0 ? void 0 : sort.option) === option && (sort === null || sort === void 0 ? void 0 : sort.order) === 'asc') {
        return { option, order: 'desc' };
    }
    if ((sort === null || sort === void 0 ? void 0 : sort.option) === option && (sort === null || sort === void 0 ? void 0 : sort.order) === 'desc') {
        return undefined;
    }
};
const deserializeParams = (params) => {
    return Object.fromEntries(new URLSearchParams(params).entries());
};
const serializeTruthyParams = (params) => {
    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([k, v]) => {
        // filter falsy values from query string, but allow 0 to be safe
        if (v && v !== 0) {
            searchParams.set(k, String(v));
        }
    });
    return searchParams;
};
const sortingString = /\w+:(asc|desc)$/;
export const paginationSchema = (limit = 5) => z.object({
    page: z.coerce.number().catch(1),
    limit: z.coerce.number().catch(limit),
    'filter[sort]': z
        .custom((s) => {
        return typeof s === 'string' ? sortingString.test(s) : false;
    })
        .transform((sort) => {
        if (sort !== undefined) {
            const [option, order] = sort.split(':');
            return { option, order: order };
        }
    })
        .catch(undefined),
});
export const omitDefaultPaginationParams = (next, prev) => ({
    ...next,
    // avoid populating url with default parameters
    page: prev.page === next.page || next.page === 1 ? undefined : next.page,
    limit: next.limit === 5 ? undefined : next.limit,
});
/**
 * Example
 *
 * const ordersParamSchema = z.object({
 *   page: z.coerce.number().catch(1),
 *   limit: z.coerce.number().catch(5),
 *   'filter[search]': z.string().optional().catch(undefined),
 *   'filter[status]': z.coerce.number().optional().catch(undefined),
 * });
 *
 * export const useOrdersQueryState = createQueryState(ordersParamSchema, (params) => ({
 *   ...params,
 *   // avoid populating url with default parameters
 *   page: params.page === 1 ? undefined : params.page,
 *   limit: params.limit === 5 ? undefined : params.limit,
 * }));
 *
 * const [params, setParams] = useOrdersQueryState();
 *
 * const handleSearchChange = (value: string) => {
 *   setParams((prev) => ({ ...prev, search: value }));
 * };
 */
export const createQueryState = (schema, transform) => () => {
    const { search } = useLocation();
    const history = useHistory();
    const state = useMemo(() => {
        const params = deserializeParams(search);
        const parsed = schema.parse(params);
        return parsed;
    }, [search]);
    const setState = useEvent((stateOrCb) => {
        const next = stateOrCb instanceof Function ? stateOrCb(state) : stateOrCb;
        // we won't do anything if parameters weren't changed
        const currentKeys = Object.keys(state);
        const nextKeys = Object.keys(next);
        if (currentKeys.length === nextKeys.length &&
            currentKeys.every((key) => state[key] === next[key])) {
            return;
        }
        const searchParams = serializeTruthyParams(transform ? transform(next, state) : next);
        history.push({ search: searchParams.toString() });
    });
    return [state, setState];
};
