export function html5Saver(string, fileName) {
    const blob = new Blob([string], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    a.addEventListener('click', () => setTimeout(() => window.URL.revokeObjectURL(url), 100), {
        once: true,
    });
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
