import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { faDownToLine } from '@fortawesome/pro-regular-svg-icons';
import { Badge, Link } from '~/common/components';
import { formatDayMonthYear } from '~/common/utils';
import { ORDERS_URL } from '~/orders';
export const tableConfig = {
    columns: [
        {
            key: 'id',
            name: 'ID',
            width: '100px',
        },
        {
            key: 'product',
            name: 'Product',
            width: '130px',
        },
        {
            key: 'description',
            name: 'Description',
            width: 'minmax(200px, 1fr)',
            cellRenderer: ({ cellData, rowData }) => {
                if (rowData.product !== 'Order' || !(cellData === null || cellData === void 0 ? void 0 : cellData.match(/Order \d+ - \d+ slides/))) {
                    return cellData;
                }
                const split = cellData === null || cellData === void 0 ? void 0 : cellData.split(' ');
                const orderId = Number(split[1]);
                const elements = [
                    split[0],
                    _jsx(Link, { newTab: true, to: `${ORDERS_URL}/${orderId}`, className: "mx-[4px]", children: orderId }, "order-link"),
                    split.slice(2).join(' '),
                ];
                return _jsx(_Fragment, { children: elements });
            },
            getClassName: () => 'truncate',
        },
        {
            key: 'created_at',
            name: 'Date',
            cellRenderer: ({ cellData }) => formatDayMonthYear(cellData),
            width: '110px',
        },
        {
            key: 'payment_method',
            name: 'Payment method',
            cellRenderer: ({ cellData }) => cellData.name,
            width: 'minmax(min-content, 200px)',
        },
        {
            key: 'status',
            name: 'Status',
            width: '110px',
            cellRenderer: ({ cellData }) => _jsx(Badge, { color: cellData.color, children: cellData.name }),
        },
        {
            key: 'amount',
            name: 'Amount',
            width: '100px',
        },
        {
            key: 'credit_balance',
            name: 'Credit balance',
            width: '130px',
        },
        {
            key: 'invoice_url',
            name: 'Invoice number',
            width: '128px',
            cellRenderer: ({ cellData, rowData }) => cellData && (_jsxs(_Fragment, { children: [rowData.invoice_number && _jsx("span", { className: "mr-1", children: rowData.invoice_number }), _jsx(Link.Icon, { size: "m", icon: faDownToLine, href: cellData, newTab: true })] })),
            getClassName: () => 'justify-center',
        },
    ],
    sortable: ['id', 'amount', 'created_at', 'credit_balance'],
};
