import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faBell, faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { useLayoutEffect, useRef } from 'react';
import { ChunkRenderer, IconBox } from '~/common/components';
import { cx } from '~/common/utils';
import { Notification } from './Notification';
import { OrderNotifications } from './OrderNotifications';
import css from './Notifications.module.scss';
export const Notifications = ({ activeTab, fetchNextPage, hasNextPage, isFetchingNextPage, notifications, onClose, status, }) => {
    const ref = useRef(null);
    /**
     * When entering 'unread' tab, check if it is smaller than max popover size,
     * then check if there is more pages to load and then load remaining pages
     */
    useLayoutEffect(() => {
        const container = ref.current;
        const maxHeight = window.innerHeight - 100;
        const isSmallerThanMax = container && container.offsetHeight < maxHeight;
        if (activeTab === 'unread' && isSmallerThanMax && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [activeTab, fetchNextPage, hasNextPage, isFetchingNextPage]);
    const handleScroll = ({ currentTarget: { clientHeight, scrollHeight, scrollTop }, }) => {
        const scrolledToBottom = scrollHeight - scrollTop === clientHeight;
        if (hasNextPage && scrolledToBottom && !isFetchingNextPage) {
            fetchNextPage();
        }
    };
    switch (status) {
        case 'loading': {
            return (_jsx("div", { className: cx(css.container, css.center), children: _jsx(IconBox, { size: "l", className: " text-secondary-300 animate-spin", icon: faSpinnerThird }) }));
        }
        case 'error': {
            return (_jsxs("div", { className: cx(css.container, css.center), children: [_jsx(IconBox, { size: "l", className: "text-greyscale-300", icon: faCircleExclamation }), _jsx("span", { className: "font-brand-b1 text-greyscale-300", children: "Could not load notifications" })] }));
        }
        case 'success': {
            return (_jsx("div", { className: cx(css.container, notifications ? 'overflow-y-scroll overscroll-contain' : css.center), onScroll: status === 'success' ? handleScroll : undefined, ref: ref, children: notifications ? (_jsxs(_Fragment, { children: [notifications.left && (_jsx("div", { className: "space-y-1 border-b border-b-greyscale-300 p-2", children: _jsx(ChunkRenderer, { component: Notification, propsList: notifications.left.map((notification) => ({
                                    key: notification.value.id,
                                    notification,
                                    onClose,
                                })) }) })), notifications.right && (_jsx(ChunkRenderer, { component: OrderNotifications, propsList: notifications.right.map(([key, notifications]) => ({
                                key,
                                id: key,
                                notifications,
                                onClose,
                            })) }))] })) : (_jsxs(_Fragment, { children: [_jsx(IconBox, { size: "m", className: "text-greyscale-300", icon: faBell }), _jsx("span", { className: "font-brand-b1 text-greyscale-300", children: activeTab === 'unread' ? 'You are all set' : 'No notifications yet' })] })) }));
        }
    }
};
