import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { useEffect, useMemo, useState } from 'react';
import { IconButton, Input, Select } from '~/common/components';
import { capitalize, cx } from '~/common/utils';
export const Search = ({ filters, selectedFilter, value, onChange, updateFilters, placeholderMapper, className, }) => {
    const [search, setSearch] = useState(value);
    const [filter, setFilter] = useState(selectedFilter);
    const selectOptions = useMemo(() => filters.map((option) => ({ name: capitalize(option), value: option })), [filters]);
    const handleFilterChange = (newFilter) => {
        setSearch('');
        setFilter(newFilter);
        updateFilters((filterMap) => {
            const newFilterMap = { ...filterMap };
            for (const option of filters) {
                newFilterMap[option] = undefined;
            }
            return newFilterMap;
        });
    };
    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };
    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            onChange(filter, search);
        }
    };
    const handleClear = () => {
        onChange(filter, '');
        setSearch('');
    };
    useEffect(() => setSearch(value), [value]);
    return (_jsxs("div", { className: cx('flex', className), children: [_jsx(Select, { className: "w-16 focus-within:z-10", value: filter, onChange: handleFilterChange, options: selectOptions, size: "small", theme: "dark", noSearch: true, square: "right" }), _jsx(Input, { className: "w-35 ml-[-1px] focus-within:z-10", value: search, onChange: handleSearchChange, onKeyDown: handleEnter, placeholder: placeholderMapper === null || placeholderMapper === void 0 ? void 0 : placeholderMapper(filter), size: "small", square: "left", children: search && (_jsx(IconButton, { size: "s", className: "text-greyscale-300 hover:text-greyscale-500 focus-within:text-greyscale-500", icon: faTimesCircle, onClick: handleClear })) })] }));
};
