import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faSlack, faTrello } from '@fortawesome/free-brands-svg-icons';
import { faCommentAltLines, faUserFriends } from '@fortawesome/pro-regular-svg-icons';
import { useForm, validator } from 'formoid';
import { useEffect } from 'react';
import { FileUploadField, InputField, ModalHeader, ModalStepShell, OfficeSelect, Show, } from '~/common/components';
import { useFileUploadState, useStep } from '~/common/hooks';
import { isNonNullable, notifyBackendError, propagateBackendValidationErrors, record, requiredMessage, } from '~/common/utils';
import { useInitData, useTeamCreation } from '../hooks';
const MAX_STEP = 1;
export const AddTeam = ({ onClose }) => {
    var _a, _b;
    const init = useInitData();
    const { mutateAsync } = useTeamCreation();
    const { step, toPrevStep, toNextStep, toStep } = useStep(MAX_STEP);
    const initialValues = {
        name: '',
        description: '',
        slack_channel: '',
        trello_board_id: '',
        office_id: (_b = (_a = init.offices[0]) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : null,
        team_photo_id: null,
    };
    const { fieldProps, handleSubmit, isSubmitting, errors, setErrors } = useForm({
        initialValues,
        validationStrategy: 'onBlur',
        validators: () => ({
            name: step === 0 ? validator.minLength(1, requiredMessage('Name')) : null,
            description: step === 0 ? validator.minLength(1, requiredMessage('Description')) : null,
            slack_channel: step === 0 ? validator.minLength(1, requiredMessage('Slack channel')) : null,
            trello_board_id: validator.minLength(1, requiredMessage('Trello board ID')),
            office_id: null,
            team_photo_id: validator.defined('Team photo is required.'),
        }),
    });
    const uploadFieldProps = useFileUploadState({
        tag: 'team_photo',
        props: fieldProps('team_photo_id'),
        onError: (errors) => setErrors('team_photo_id', errors),
    });
    const submit = () => handleSubmit((values) => {
        if (step < MAX_STEP) {
            toNextStep();
            return Promise.resolve();
        }
        return mutateAsync(values)
            .then(onClose)
            .catch((error) => propagateBackendValidationErrors(error, setErrors))
            .catch(notifyBackendError);
    });
    useEffect(() => {
        if (record.values(errors).find(isNonNullable)) {
            toStep(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Add design team" }), _jsxs(ModalStepShell, { className: "min-h-[516px] w-[600px] p-3 space-y-3", submitText: "Add a team", step: step, toPrevStep: toPrevStep, maxStep: MAX_STEP, onClose: onClose, onSubmit: submit, loading: isSubmitting, children: [_jsxs(Show, { if: step === 0, children: [_jsx(InputField, { ...fieldProps('name'), icon: faUserFriends, title: "Name", placeholder: "Enter a team name", type: "text" }), _jsx(InputField, { ...fieldProps('description'), icon: faCommentAltLines, title: "Description", placeholder: "Enter a description", type: "text" }), _jsx(InputField, { ...fieldProps('slack_channel'), icon: faSlack, title: "Slack channel", placeholder: "Enter a slack channel", type: "text" }), _jsx(InputField, { ...fieldProps('trello_board_id'), icon: faTrello, title: "Trello board ID", placeholder: "Enter trello board ID", type: "text" }), _jsx(FileUploadField, { ...uploadFieldProps, title: "Team photo", hintLeft: "Supports: PNG, JPG", hintRight: "Max size is 2 MB and 600x600 px", accept: ".png, .jpg, .jpeg" })] }), _jsx(Show, { if: step === 1, children: _jsx(OfficeSelect, { ...fieldProps('office_id'), options: init.offices }) })] })] }));
};
