import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useModalContext } from '~/common/components';
import { useOrderData } from '~/orders/hooks';
import { useCurrentUserData } from '~/root';
import { EditBriefDetails } from '../Modals';
import { SubSection } from '../Section';
const DetailsItem = ({ title, value }) => {
    return (_jsxs("div", { children: [_jsx("p", { className: "font-brand-c1r text-greyscale-500 mb-[2px]", children: title }), _jsx("p", { className: "font-brand-b1 text-greyscale-600 break-words", children: value || '—' })] }));
};
export const BriefDetails = () => {
    var _a, _b, _c, _d;
    const { brief } = useOrderData();
    const { modalOpener } = useModalContext();
    const findAnswer = (fieldName) => {
        var _a, _b;
        const questions = brief.questions[fieldName];
        const answer = (_a = brief.answers) === null || _a === void 0 ? void 0 : _a[fieldName];
        return (_b = questions.find(({ value }) => value === answer)) === null || _b === void 0 ? void 0 : _b.name;
    };
    const { permissions } = useCurrentUserData();
    return (_jsx(SubSection, { title: "Details", isEditable: permissions.ordersBriefUpdate, onEdit: modalOpener(EditBriefDetails), children: _jsxs("div", { className: "grid grid-cols-3 gap-x-3 gap-y-2 px-1", children: [_jsx(DetailsItem, { value: findAnswer('aspect_ration'), title: "Page size" }), _jsx(DetailsItem, { value: (_a = brief.answers) === null || _a === void 0 ? void 0 : _a.color_preference, title: "Color preferences" }), _jsx(DetailsItem, { value: (_b = brief.answers) === null || _b === void 0 ? void 0 : _b.preferred_fonts, title: "Font" }), _jsx(DetailsItem, { value: findAnswer('illustration_preference'), title: "Photo vs Illustration" }), _jsx(DetailsItem, { value: findAnswer('file_output'), title: "File output" }), _jsx(DetailsItem, { value: (_c = brief.answers) === null || _c === void 0 ? void 0 : _c.redrawable_elements, title: "Redraw slide" }), _jsx(DetailsItem, { value: (_d = brief.answers) === null || _d === void 0 ? void 0 : _d.branding_sources, title: "Web reference" })] }) }));
};
