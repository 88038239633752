import { array, number, ord } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { z } from 'zod';
import { DateFromIsoString, Link } from '~/common/utils';
import { UploadedFile, UploadedFileId } from '~/root';
const PRECISION = 10000;
/**
 * AnnotationPoint
 * Value from 0 to 1 that is multiplied by 10000 (value in range 0 - 10000)
 * If value is in range 0-10000, then decoder divides it by 10000
 */
export const AnnotationPoint = pipe(z
    .number()
    .int()
    .nonnegative()
    .refine((value) => value <= PRECISION, { message: 'Invalid annotation point provided!' })
    .transform((value) => value / 100 + '%'));
export const AnnotationComment = z.object({
    comment: z.string(),
    created: DateFromIsoString,
    id: z.number().int().positive(),
    user: z.object({
        id: z.number().int().positive(),
        initials: z.string(),
        name: z.string(),
        avatar: UploadedFile.nullable(),
    }),
});
/**
 * AnnotationPosition
 * Relative position to top-left corner of slide preview
 */
export const AnnotationPosition = z
    .object({
    x: AnnotationPoint,
    y: AnnotationPoint,
})
    .transform(({ x, y }) => ({ top: y, left: x }));
export const Annotation = z.object({
    comments: z.array(AnnotationComment),
    created: DateFromIsoString,
    id: z.number().int().positive(),
    index: z.number().int().nonnegative(),
    position: AnnotationPosition,
    resolved: z.boolean(),
});
export const Slide = z
    .object({
    annotations: z.array(Annotation),
    id: UploadedFileId,
    index: z.number(),
    name: z.string(),
    total_annotations: z.number().int().nonnegative(),
    total_comments: z.number().int().nonnegative(),
    updated: DateFromIsoString.nullable(),
    url: Link,
})
    .transform(({ updated, url, ...rest }) => ({ ...rest, link: url, date: updated }));
export const ordSlide = pipe(number.Ord, ord.contramap((slide) => slide.index));
export const Revision = z
    .object({
    created: DateFromIsoString,
    file: UploadedFile.nullable(),
    id: z.number().int().positive(),
    name: z.string(),
    slides: z.array(Slide),
    total_slides: z.number().int().nonnegative(),
    total_annotations: z.number().int().nonnegative(),
    unresolved_annotations: z.number(),
    updated: DateFromIsoString,
    url: Link.nullable(),
})
    .transform(({ file, url, slides, ...revision }) => {
    var _a, _b;
    const sortedSlides = pipe(slides, array.sort(ordSlide));
    return {
        file: file || undefined,
        url: url || undefined,
        preview_url: (_b = (_a = sortedSlides[0]) === null || _a === void 0 ? void 0 : _a.link) !== null && _b !== void 0 ? _b : null,
        slides: sortedSlides,
        ...revision,
    };
});
export const Revisions = z.array(Revision);
