import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ModalBody, ModalHeader } from '~/common/components';
import { useInitData, useOrderData } from '~/orders/hooks';
import { TimeLineItem } from './TimeLineItem';
export const TimeLine = (props) => {
    const init = useInitData();
    const { timeline } = useOrderData();
    const statuses = [...init.statuses.order, ...init.statuses.order_legacy];
    const placeholder = (_jsx("div", { className: "flex items-center justify-center", children: _jsx("p", { className: "font-normal text-greyscale-400", children: "No timelines available yet" }) }));
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: props.onClose, title: "Status timeline" }), _jsx(ModalBody, { className: "w-[712px]", children: timeline.length
                    ? timeline.map((item) => (_jsx(TimeLineItem, { item: item, statuses: statuses }, item.date.toString())))
                    : placeholder })] }));
};
