import { z } from 'zod';
import { DateFromIsoString, Email, nonNullable } from '~/common/utils';
export const TeamMembersListItem = (roles, statuses) => {
    return z.object({
        id: z.number(),
        name: z.string().nullable().catch(null),
        email: Email,
        role: z.number().transform((id) => nonNullable(roles.find((role) => role.value === id))),
        status: z.number().transform((id) => nonNullable(statuses.find((role) => role.value === id))),
        joined_at: DateFromIsoString.nullable().catch(null),
        expires_at: DateFromIsoString.nullable().catch(null),
    });
};
export const TeamMembersList = (roles, statuses) => {
    return z.array(TeamMembersListItem(roles, statuses)).transform((members) => {
        return members.map((member, index) => ({ ...member, id: index, member_id: member.id }));
    });
};
export const InviteTeamMembers = z.object({
    emails: z.array(z.string()),
    role: z.number(),
});
