import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from '~/common/components';
import { cx, tw } from '~/common/utils';
const buttonsClassName = tw `
  flex items-center justify-end gap-3
  px-3 py-2 w-full
  border-t border-solid border-greyscale-200
`;
export const ButtonGroup = ({ children }) => (_jsx("div", { className: buttonsClassName, children: children }));
export const ModalShell = ({ loading = false, disabled, disabledHint, className, negativeAction = false, submitText = 'Save', cancelText = 'Cancel', buttonsSize = 'medium', onClose, onSubmit, children, }) => (_jsxs(_Fragment, { children: [_jsx("div", { className: className, children: children }), _jsxs(ButtonGroup, { children: [_jsx(Button, { color: "grey", size: buttonsSize, onClick: onClose, disabled: loading, children: cancelText }), _jsx(Button, { "data-tt": cx(disabled && disabledHint), className: cx(disabled && 'cursor-not-allowed'), color: negativeAction ? 'danger' : 'primary', size: buttonsSize, onClick: onSubmit, loading: loading, disabled: disabled, children: submitText })] })] }));
