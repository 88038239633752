import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCompositeForm } from 'formoid';
import { useMemo, useRef, useState } from 'react';
import { Checkbox, InputField, ModalHeader, ModalShell } from '~/common/components';
import { customValidator, record } from '~/common/utils';
import { eqTreatments, OrderStatusMap } from '~/orders/domain';
import { useCalculateOrderPrice, useInitData, useOrderData, useUpdateOrder, } from '~/orders/hooks';
import { Addons, InlineSectionedSelectField, InlineSelectField, PriceChangeBanner, Treatment, } from '~/orders/Order';
import { appendOtherReason, customReasonValidator, getInitialCancellationReason, isOtherReason, propagateTreatmentsBackendValidationErrors, } from '~/orders/utils';
function getCancellationReason({ cancellationReason, customReason, orderStatus, }) {
    return orderStatus === 'Cancelled'
        ? (isOtherReason(cancellationReason) ? customReason : cancellationReason) || undefined
        : undefined;
}
export const EditGeneralInfo = (props) => {
    var _a, _b;
    const { addons, reasons, statuses } = useInitData();
    const order = useOrderData();
    const updateOrder = useUpdateOrder();
    const initialCancellationReason = getInitialCancellationReason(reasons.order_cancellation, order.cancellation_reason);
    const initialValues = {
        ...initialCancellationReason,
        addons: order.addons.map(({ value }) => value),
        orderStatus: order.status,
        orderedSlides: order.slides,
        style: (_b = (_a = order.style) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null,
    };
    const { form, fieldArray, handleSubmit, isSubmitting } = useCompositeForm({
        form: {
            initialValues,
            validators: ({ form: values }) => ({
                addons: null,
                cancellationReason: values.orderStatus === 'Cancelled' ? customValidator.required() : null,
                customReason: customReasonValidator(values.cancellationReason),
                orderStatus: customValidator.required(),
                orderedSlides: null,
                style: null,
            }),
            validationStrategy: 'onBlur',
        },
        fieldArray: {
            initialValues: { treatments: order.treatments },
            validators: () => ({
                treatments: {
                    id: null,
                    name: null,
                    value: customValidator.nonNegativeInteger(),
                },
            }),
            validationStrategy: 'onBlur',
        },
    });
    const handleStatusChange = (value) => {
        form.fieldProps('orderStatus').onChange(value);
        if (value === 'Cancelled') {
            return;
        }
        return form.setValues((values) => ({
            ...values,
            orderStatus: value,
            cancellationReason: null,
            customReason: null,
        }));
    };
    const [acceptPrice, setAcceptPrice] = useState(true);
    const [updatedPrice, setUpdatedPrice] = useState(null);
    const { mutateAsync, isLoading } = useCalculateOrderPrice({
        onSuccess: ({ unit }) => setUpdatedPrice(unit),
        onError: propagateTreatmentsBackendValidationErrors(fieldArray.treatments, form.setErrors),
    });
    const orderedSlidesProps = useMemo(() => ({
        ...form.fieldProps('orderedSlides'),
        value: fieldArray.treatments.values.reduce((result, treatment) => result + treatment.value, 0),
    }), [fieldArray.treatments.values, form]);
    const treatmentsPreviousValue = useRef(fieldArray.treatments.values);
    const treatmentFieldGroups = useMemo(() => {
        return fieldArray.treatments.groups.map((group) => ({
            ...group,
            value: {
                ...group.value,
                onBlur: () => {
                    if (!eqTreatments.equals(treatmentsPreviousValue.current, fieldArray.treatments.values)) {
                        mutateAsync({
                            treatments: record.fromEntries(fieldArray.treatments.values.map(({ id, value }) => [id, value])),
                            addons: form.values.addons,
                        });
                        treatmentsPreviousValue.current = fieldArray.treatments.values;
                    }
                    group.value.onBlur();
                },
            },
        }));
    }, [fieldArray.treatments.groups, fieldArray.treatments.values, form.values.addons, mutateAsync]);
    const addonProps = {
        ...form.fieldProps('addons'),
        onChange: (newAddons) => {
            form.fieldProps('addons').onChange(newAddons);
            mutateAsync({
                treatments: record.fromEntries(fieldArray.treatments.values.map(({ id, value }) => [id, value])),
                addons: newAddons,
            });
        },
    };
    const submit = () => handleSubmit(({ form, fieldArray }) => {
        const cancellationReason = getCancellationReason(form);
        const payload = {
            addons: form.addons,
            cancellation_reason: cancellationReason,
            status: OrderStatusMap.forward[form.orderStatus],
            style: form.style
                ? {
                    id: Number(form.style.split('.')[1]),
                    style_type: form.style.split('.')[0],
                }
                : undefined,
            treatments: record.fromEntries(fieldArray.treatments.map(({ id, value }) => [id, value])),
            payment: acceptPrice ? undefined : { unit: order.payment.unit },
        };
        return updateOrder(payload).then(props.onClose);
    });
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { title: "General", onClose: props.onClose }), _jsx(ModalShell, { className: "p-3 w-[712px]", disabled: isLoading, loading: isSubmitting, onClose: props.onClose, onSubmit: submit, children: _jsxs("div", { className: "space-y-2", children: [_jsxs("div", { className: "flex items-start space-x-2", children: [_jsxs("div", { className: "space-y-1 w-1/2", children: [_jsx(InlineSelectField, { ...form.fieldProps('orderStatus'), onChange: handleStatusChange, options: statuses.order, title: "Design status" }), _jsx(InlineSectionedSelectField, { ...form.fieldProps('style'), options: order.customer.sectionedStyleOptions })] }), _jsxs("div", { className: "space-y-1 w-1/2", children: [form.values.orderStatus === 'Cancelled' && (_jsx(InlineSelectField, { ...form.fieldProps('cancellationReason'), options: appendOtherReason(reasons.order_cancellation), title: "Cancellation reason" })), isOtherReason(form.values.cancellationReason) && (_jsx(InputField, { ...form.fieldProps('customReason'), type: "text" }))] })] }), _jsxs("div", { className: "flex items-start space-x-2", children: [_jsx("div", { className: "w-1/2", children: _jsx(Addons, { ...addonProps, addons: addons }) }), _jsx("div", { className: "w-1/2", children: _jsx(Treatment, { treatmentFieldGroups: treatmentFieldGroups, orderedSlidesProps: orderedSlidesProps }) })] }), !!updatedPrice && updatedPrice !== order.payment.unit && (_jsxs("div", { className: "space-y-1", children: [_jsx(PriceChangeBanner, { currency: order.payment.currency, from: order.payment.unit, to: updatedPrice }), _jsx(Checkbox, { text: "Accept price change", onChange: setAcceptPrice, value: acceptPrice })] }))] }) })] }));
};
