import { CSS_CELL_WIDTH, CSS_TOTAL_CELL_WIDTH } from '~/common/components';
import { nonNullable, range } from '~/common/utils';
import { isShift, offsetWidthToDiscrete, } from './domain';
export const SHIFTS_GRID_CONTAINER_ID = 'shifts-grid-container';
export const TEAM_COLUMN_WIDTH = 232;
export const getBarPositionStyle = (offset, width, holiday = false) => ({
    top: holiday ? 0 : 4,
    left: `calc(var(${CSS_CELL_WIDTH}) * ${offset})`,
    width: `calc(var(${CSS_CELL_WIDTH}) * ${width} - ${holiday ? 0 : 8}px)`,
});
export const getTimelineContainerPositionStyle = (index) => ({
    top: index * 40 + 4,
    left: 0,
    width: `var(${CSS_TOTAL_CELL_WIDTH})`,
});
export const pointerMoveUtils = ({ event, onMove, onDown, onUp, }) => {
    // storing previous values to evaluate whether we should trigger react
    const pos = {
        startX: 0,
        offset: 0,
        width: 0,
    };
    const node = event.currentTarget;
    // since this is only needed for imperative usage in certain conditions it
    // is wasteful to subscribe to this through hooks or similar means
    const container = nonNullable(document.getElementById(SHIFTS_GRID_CONTAINER_ID));
    const startScrollLeft = container.scrollLeft;
    const cellWidth = parseInt(container.style.getPropertyValue(CSS_CELL_WIDTH).replace('px', ''));
    const timelineWidth = parseInt(container.style.getPropertyValue(CSS_TOTAL_CELL_WIDTH).replace('px', ''));
    const getOffset = (x, move) => x - container.offsetLeft - TEAM_COLUMN_WIDTH + (move ? container.scrollLeft : startScrollLeft);
    const getPlacement = (e) => {
        const currentOffset = Math.min(timelineWidth - 1, Math.max(getOffset(e.clientX, true), 0));
        const startInDays = Math.floor(getOffset(pos.startX, false) / cellWidth);
        const currentInDays = Math.floor(currentOffset / cellWidth);
        const distanceInDays = currentInDays - startInDays;
        return {
            // for backwards moving selection
            offset: distanceInDays > 0 ? startInDays : startInDays + distanceInDays,
            width: Math.abs(distanceInDays) + 1,
            relativeOffset: distanceInDays,
        };
    };
    const handleMove = (e) => {
        const placement = getPlacement(e);
        if (placement.offset !== pos.offset || placement.width !== pos.width) {
            pos.offset = placement.offset;
            pos.width = placement.width;
            onMove(placement);
        }
    };
    const handleUp = (e) => {
        node.releasePointerCapture(e.pointerId);
        node.removeEventListener('pointermove', handleMove);
        node.removeEventListener('lostpointercapture', handleUp);
        onUp === null || onUp === void 0 ? void 0 : onUp();
    };
    pos.startX = event.clientX;
    const placement = getPlacement(event);
    if ((onDown === null || onDown === void 0 ? void 0 : onDown(placement)) === false) {
        return;
    }
    onMove(placement);
    node.setPointerCapture(event.pointerId);
    node.addEventListener('pointermove', handleMove);
    node.addEventListener('lostpointercapture', handleUp);
};
export const getOccupationIdIfAllSame = (items) => {
    let id = null;
    for (const { occupation } of items) {
        if (id === null) {
            id = occupation.id;
        }
        if (occupation !== null && id !== occupation.id) {
            return null;
        }
    }
    return id;
};
export const getInfoFromSelection = ({ offset, width }, timeline) => {
    const items = [];
    const days = range(0, width - 1);
    for (const day of days) {
        const item = timeline[offset + day];
        if (item) {
            items.push(item);
        }
    }
    const hasSelectedItems = items.length > 0;
    const allDaysManager = items.every((item) => item.is_manager);
    const allDaysNonManager = items.every((item) => !item.is_manager);
    const previousManagerState = hasSelectedItems && !allDaysManager && !allDaysNonManager
        ? null
        : hasSelectedItems && allDaysManager;
    return {
        selectedItems: items,
        hasSelectedItems,
        previousManagerState,
        previousOccupationId: days.length === items.length ? getOccupationIdIfAllSame(items) : null,
    };
};
export const isInValidRange = (range, placement) => {
    return placement.offset >= range.start && placement.offset + placement.width <= range.end;
};
export const getInvalidRangesFromValid = (range, timelineRange) => {
    const result = [];
    if (range.start > 0) {
        result.push({
            offset: 0,
            width: Math.min(range.start, timelineRange.end),
        });
    }
    if (range.end < timelineRange.end) {
        result.push({
            offset: range.end,
            width: timelineRange.end - range.end,
        });
    }
    return result;
};
export const areSamePlacements = (a, b) => {
    return a.offset === b.offset && a.width === b.width;
};
export const getDeltaFromTwoPlacements = (prev, next) => {
    const prevStart = prev.offset;
    const nextStart = next.offset;
    const prevEnd = prev.offset + prev.width;
    const nextEnd = next.offset + next.width;
    const resizedLeft = nextStart !== prevStart;
    return {
        // starting point is smallest of them, extend or delete tbd
        offset: resizedLeft ? Math.min(prevStart, nextStart) : Math.min(prevEnd, nextEnd),
        width: resizedLeft ? Math.abs(nextStart - prevStart) : Math.abs(nextEnd - prevEnd),
        extend: nextStart < prevStart || nextEnd > prevEnd,
    };
};
export const isDesignTeamId = (teamId) => teamId > 0;
export const isDesignTeam = (team) => team.id > 0;
export const getTeamAndMemberFromIds = (teamId, userId, teams) => {
    const team = nonNullable(teams.find(({ id }) => id === teamId));
    const member = nonNullable(team.members.find(({ id }) => id === userId));
    return { team, member };
};
export const isPrimaryManager = (team, userId) => {
    return 'primary_manager_id' in team && team.primary_manager_id === userId;
};
export const getPrimaryManagerHasShifts = (team, isPrimary, selection, date) => {
    if (!isDesignTeam(team) || isPrimary || !team.primary_manager_id) {
        return false;
    }
    const primaryManagerTimeline = nonNullable(team.members.find((member) => member.id === team.primary_manager_id)).timeline;
    for (const item of offsetWidthToDiscrete(selection, date)) {
        const itemByOffset = primaryManagerTimeline[item.offset];
        if (itemByOffset && isShift(itemByOffset.occupation)) {
            return true;
        }
    }
    return false;
};
