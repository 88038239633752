import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { Banner, Button, ModalBody, ModalFooter, ModalHeader, Show } from '~/common/components';
import { cx, pluralizeWord } from '~/common/utils';
import { useOrderData } from '~/orders/hooks';
import { CustomerCapacityLink } from '~/orders/Order';
import { DeliverySection } from './DeliverySection';
import { ExtraCapacityReason } from './ExtraCapacityReason';
import { useCapacityDistribution } from './hooks';
import { Planning } from './Planning';
const getBannerMessage = (remainingPoints, isFirstDraft) => {
    if (isFirstDraft) {
        return remainingPoints < 0 ? 'Please reduce your distribution by ' : 'You need to distribute ';
    }
    return 'Order treatments were updated, please distribute ';
};
const DistributionBanner = memo(({ remainingPoints, isFirstDraft }) => {
    if (remainingPoints === 0 || (!isFirstDraft && remainingPoints < 0)) {
        return null;
    }
    return (_jsxs(Banner, { color: remainingPoints < 0 ? 'error' : 'warning', children: [getBannerMessage(remainingPoints, isFirstDraft), _jsx("span", { className: "font-bold", children: pluralizeWord('point', Math.abs(remainingPoints)) })] }));
});
export function CapacityDistribution({ onClose, statusTransition = false, type }) {
    const order = useOrderData();
    const iterations = order.iterations;
    const { currentIteration, distribution, fieldProps, isLoading, iterationSelect, onSubmit } = useCapacityDistribution({ iterations });
    const isFirstDraft = currentIteration.type === 'firstDraft';
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { title: `Capacity ${type}`, onClose: onClose }), _jsx(ModalBody, { className: cx('w-[712px]', type === 'overview' ? 'mb-2' : undefined), children: _jsxs("div", { className: "flex flex-col w-full space-y-2", children: [_jsx(Show, { if: type === 'distribution', children: _jsx(DistributionBanner, { remainingPoints: distribution.remainingPoints, isFirstDraft: isFirstDraft }) }), _jsx(Show, { if: order.customer.subscription !== null, children: _jsx(CustomerCapacityLink, { className: "mr-auto" }) }), _jsx(Show, { if: type === 'overview' || !statusTransition, children: _jsx(DeliverySection, { ...iterationSelect }) }), _jsx(Show, { if: type === 'distribution' && !isFirstDraft, children: _jsx(ExtraCapacityReason, { fieldProps: fieldProps }) }), _jsx(Planning, { ...(type === 'overview'
                                ? {
                                    type: 'overview',
                                }
                                : {
                                    type: 'planning',
                                    onUpdatePoints: distribution.onUpdatePoints,
                                    remainingPoints: distribution.remainingPoints,
                                }), isFirstDraft: isFirstDraft, dates: currentIteration.dates, state: distribution.state })] }) }), type === 'distribution' && (_jsx(ModalFooter, { className: "flex items-center justify-end", children: _jsx(Button, { disabled: isFirstDraft && distribution.remainingPoints < 0, loading: isLoading, size: "medium", onClick: () => onSubmit(onClose), children: "Save distribution" }) }))] }));
}
