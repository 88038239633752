import { z } from 'zod';
import { Link } from '~/common/utils';
export const LoginResponse = z.object({
    token: z.string(),
    redirectUrl: Link,
});
export const OauthData = z.object({
    redirectUrl: Link,
    channel: z.string(),
});
export const HttpErrorOauthData = z
    .object({
    response: z.object({
        data: OauthData,
    }),
})
    .transform((error) => error.response.data);
