import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faTag } from '@fortawesome/pro-solid-svg-icons';
import { CountryFlag, IconBox } from '~/common/components';
import { UserCard } from '~/common/kits/usercard';
import { cx } from '~/common/utils';
import { useHoveredUserStore } from '../context';
import { isPrimaryManager } from '../utils';
// amazing optimization, hover will only re-render 4 divs at max
const TeamContainer = ({ userId, children }) => {
    const hovered = useHoveredUserStore((store) => store.userId === userId);
    const setUserId = useHoveredUserStore((store) => store.setUserId);
    return (_jsx("div", { className: cx('flex items-center px-2 h-5 transition-colors pointer-events-auto', hovered && 'bg-greyscale-50'), onPointerOver: () => setUserId(userId), onPointerOut: () => setUserId(null), children: children }));
};
export const TeamView = ({ team }) => (_jsxs(_Fragment, { children: [_jsxs("div", { className: "sticky top-0 flex items-center justify-between z-10 h-5 -mt-5 mb-[4px] pl-2 pr-[15px] font-brand-t1m bg-greyscale-0 border-b border-greyscale-200 pointer-events-auto", children: [_jsx("span", { className: "truncate", children: team.name }), 'office' in team && _jsx(CountryFlag, { countryCode: team.office.country_code, className: "ml-1" })] }), team.members.map((member) => {
            var _a;
            return (_jsxs(TeamContainer, { userId: member.id, children: [_jsxs("div", { className: "relative mr-1", children: [_jsx(UserCard, { id: member.id, name: member.name, avatar_link: ((_a = member.avatar) === null || _a === void 0 ? void 0 : _a.link) || null }), isPrimaryManager(team, member.id) && (_jsx(IconBox, { size: "s", className: "absolute bottom-0 -right-[6px] text-greyscale-500", icon: faTag }))] }), _jsx("div", { className: "ml-1 mr-[4px] font-brand-t4m text-greyscale-500 truncate", children: member.name })] }, member.id));
        })] }));
