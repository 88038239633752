import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { Link } from '~/common/components';
import { formatDate } from '~/common/utils';
export const Action = ({ action }) => {
    var _a;
    return (_jsxs("div", { className: "w-full", children: [action.text && (_jsxs("p", { className: "font-brand-b1 inline-flex gap-1 items-center", children: [action.text, action.displayUrl && _jsx(Link.Icon, { icon: faExternalLink, href: action.displayUrl })] })), (_a = action.meta) === null || _a === void 0 ? void 0 : _a.map(({ key, value }, index) => {
                return (_jsxs("p", { className: "font-brand-b1 text-greyscale-500 truncate", children: [_jsxs("span", { className: "font-brand-t4m  text-greyscale-600", children: [key, ": "] }), typeof value === 'string' ? value : formatDate(value)] }, index));
            })] }));
};
