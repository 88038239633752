import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faCircleInfo, faDownToLine } from '@fortawesome/pro-regular-svg-icons';
import { Button, IconBox, ImageLoader, Link, ModalHeader, } from '~/common/components';
import { cx, formatDayMonthYear } from '~/common/utils';
import { useCustomerData } from '~/customers/hooks';
const Heading = ({ title, hint }) => (_jsxs("div", { className: "mb-2 flex items-center gap-1", children: [_jsx("div", { className: "font-brand-t2m", children: title }), _jsx(IconBox, { icon: faCircleInfo, className: "text-greyscale-400", "data-tt": hint, "data-tt-placement": "right", "data-tt-class": "max-w-[230px]" })] }));
const Card = (props) => (_jsxs("div", { className: "p-[12px] pt-1 rounded-lg bg-greyscale-50 border border-solid border-greyscale-200", children: [_jsxs("div", { className: "flex items-center justify-between mb-1", children: [_jsx("div", { className: "font-brand-t4m truncate", children: props.name }), props.disabled && (_jsx("div", { className: "font-brand-c1r text-greyscale-400 ml-[4px] mr-auto", children: "(disabled)" })), props.file_url ? (_jsx(Link.Icon, { className: "ml-1", href: props.file_url, icon: faDownToLine, size: "s", download: true })) : (props.file_url === null && (_jsx(Button.Icon, { className: "ml-1", icon: faDownToLine, size: "s", disabled: true, "data-tt": "There was no file for this style, reach out to product team with a file to add it!" })))] }), _jsx(ImageLoader, { src: props.preview_url, className: cx('mb-1 w-full object-cover object-center rounded-md aspect-video before:mt-[56.25%]', (props.archived || props.disabled) && 'opacity-40') }), props.created_at && (_jsxs("div", { className: "font-brand-c1r text-greyscale-400", children: ["Created on: ", formatDayMonthYear(props.created_at)] }))] }));
const StyleSection = ({ styles, title, hint, archived = false, }) => {
    if (!styles.length) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx("hr", { className: "my-3 text-greyscale-200 first-of-type:hidden" }), _jsx(Heading, { title: title, hint: hint }), _jsx("div", { className: "grid grid-cols-4 grid-flow-row gap-2", children: styles.map((style) => (_jsx(Card, { ...style, archived: archived }, style.id))) })] }));
};
export const AccountStyles = ({ onClose }) => {
    const { styles } = useCustomerData();
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Account styles" }), _jsxs("div", { className: "p-3 w-[976px]", children: [_jsx(StyleSection, { title: "Customer's styles", hint: "Styles that the customer has uploaded and are available only for their team members", styles: styles.custom_styles }), _jsx(StyleSection, { title: "Custom Portal styles", hint: "Styles that are available within the custom portal users", styles: styles.custom_portal_styles }), _jsx(StyleSection, { title: "Archived styles", hint: "The last 10 deleted styles that were available to this customer", styles: styles.archived_styles, archived: true })] })] }));
};
