import { useCallback } from 'react';
/**
 * const setPeriod = useStateSliceSetter(setState, 'period');
 *
 * instead of
 *
 * const setPeriod = (period: Period) => {
 *   setState((prev) => ({ ...prev, period }));
 * }
 */
export const useStateSliceSetter = (setContext, selector) => {
    return useCallback((stateOrCb) => {
        setContext((prev) => {
            const next = typeof stateOrCb === 'function' ? stateOrCb(prev[selector]) : stateOrCb;
            return { ...prev, [selector]: next };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
