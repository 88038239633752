import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { generateFileKey, getFriendlyUploadError, getPercentage, tusUpload } from '~/common/utils';
import { qk } from '~/root/query-keys';
export const useFileUploadProgress = ({ file, tag, signal, onSuccess }) => {
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);
    const fileUpload = useMutation({
        mutationKey: qk.uploadFile(generateFileKey(file)),
        mutationFn: () => tusUpload({
            file,
            tag,
            signal,
            onProgress: (bytesSent, bytesTotal) => setProgress(getPercentage(bytesSent, bytesTotal)),
        }).catch((error) => {
            // the reason we're catching here is because otherwise we can't prevent
            // react-query from screaming about handled errors, and we can't make
            // it vocal about not handled errors
            const { message, shouldThrow } = getFriendlyUploadError(error);
            setError(message[0]);
            if (shouldThrow) {
                throw error;
            }
            return undefined;
        }),
        onMutate: () => {
            setError(null);
        },
        onSuccess: (uploadedFile) => {
            if (onSuccess && uploadedFile) {
                onSuccess(uploadedFile);
            }
        },
    });
    return { ...fileUpload, progress, error };
};
