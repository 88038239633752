import { useForm } from 'formoid';
import { useMemo } from 'react';
import { customValidator } from '~/common/utils';
import { changeStatusPayload, useInitData, useOrderData } from '~/orders/hooks';
import { appendOtherReason, customReasonValidator, getInitialCancellationReason, isOtherReason, } from '~/orders/utils';
export function useTransitionCancelled(onSubmit) {
    const { reasons } = useInitData();
    const { cancellation_reason } = useOrderData();
    const cancellationOptions = appendOtherReason(reasons.order_cancellation);
    const initialValues = useMemo(() => getInitialCancellationReason(reasons.order_cancellation, cancellation_reason), [cancellation_reason, reasons.order_cancellation]);
    const { fieldProps, handleSubmit, isSubmitting, values } = useForm({
        initialValues,
        validationStrategy: 'onBlur',
        validators: (values) => ({
            cancellationReason: customValidator.required(),
            customReason: customReasonValidator(values.cancellationReason),
        }),
    });
    const submit = () => handleSubmit((values) => {
        const cancellationReason = isOtherReason(values.cancellationReason)
            ? values.customReason
            : values.cancellationReason;
        if (cancellationReason) {
            return onSubmit(changeStatusPayload.cancelled({ cancellation_reason: cancellationReason }));
        }
        return Promise.reject(null);
    });
    return {
        fieldProps,
        handleSubmit: submit,
        isSubmitting,
        options: {
            cancellationReason: cancellationOptions,
        },
        values,
    };
}
