import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faCircleExclamation, faSpinnerThird, faSquare, faTrashCan, faXmark, } from '@fortawesome/pro-regular-svg-icons';
import { faArrowCircleUp, faCirclePlus, faPencil, faSquareCheck, } from '@fortawesome/pro-solid-svg-icons';
import { forwardRef, useState } from 'react';
import { Button, CircularProgress, DragDrop, IconBox } from '~/common/components';
import { useScrollIntoView, useToggle } from '~/common/hooks';
import { LexicalEditor } from '~/common/kits/editor';
import { UserCard } from '~/common/kits/usercard';
import { cx, formatDate, getPercentage, toggleAny } from '~/common/utils';
import { Container, EditorContainer, RichTextField, useRichTextField } from '../components';
import { usePersistentChecklistItem } from '../hooks';
import { useAddChecklistItem, useCheckListEvents, useChecklist, useDeleteChecklistItem, useReorderChecklist, useToggleChecklistItem, useUpdateChecklistItem, } from './hooks';
export const Checklist = () => {
    var _a, _b;
    useCheckListEvents();
    const checklist = useChecklist();
    const reorder = useReorderChecklist();
    const [editingId, setEditingId] = useState(null);
    const doneItems = ((_a = checklist.data) === null || _a === void 0 ? void 0 : _a.items.filter((item) => item.done).length) || 0;
    const totalItems = ((_b = checklist.data) === null || _b === void 0 ? void 0 : _b.items.length) || 0;
    return (_jsxs("div", { className: "pb-2 basis-5/12 flex flex-col", children: [_jsxs("div", { className: "flex-none flex items-center gap-1 h-8", children: [_jsx("div", { className: "flex items-center justify-center h-3 w-3", children: _jsx(CircularProgress, { percentage: getPercentage(doneItems, totalItems), size: 20, strokeWidth: 2 }) }), _jsx("div", { className: "font-brand-t2m text-greyscale-500", children: `${doneItems}/${totalItems}` }), _jsx("div", { className: "font-brand-t2m text-greyscale-500", children: "tasks completed" })] }), _jsx(Container, { className: cx(checklist.isSuccess && 'flex flex-col flex-grow overflow-y-auto'), center: !checklist.isSuccess, children: checklist.isLoading ? (
                // TODO consider making a more generic loading component
                _jsx(IconBox, { size: "l", className: "text-secondary-300 animate-spin", icon: faSpinnerThird })) : checklist.isError ? (_jsxs("div", { className: "flex flex-col items-center justify-center space-y-1", children: [_jsx(IconBox, { size: "m", className: "text-greyscale-300", icon: faCircleExclamation }), _jsx("span", { className: "font-brand-b2r text-greyscale-300", children: "Could not load checklist" })] })) : (_jsxs(_Fragment, { children: [_jsx(DragDrop, { items: checklist.data.items, onChange: (items) => {
                                reorder.mutate(items.map((item, i) => ({ ...item, index: i + 1 })));
                            }, containerClassName: "w-full space-y-1", disabled: !!editingId, itemRenderer: ({ item, isDraggedOver, isSnapshot, isDragging, disabled }) => (_jsx("div", { className: cx(isDraggedOver && '[&_*]:invisible outline-greyscale-100 bg-greyscale-100', isSnapshot && 'outline-secondary-300', !disabled && !isDragging && 'hover:outline-greyscale-400'), children: _jsx(ChecklistItem, { ...item, noHover: isDragging, isEditing: item.id === editingId, toggleEditing: () => setEditingId((prev) => toggleAny(prev, item.id)) }) })) }), _jsx(AddChecklistItem, {})] })) })] }));
};
const AddChecklistItem = () => {
    const addItem = useAddChecklistItem();
    const { state, onChange, onSuccess } = usePersistentChecklistItem();
    const [isEditing, toggleEditing] = useToggle(!!state);
    const props = useRichTextField({
        onSubmit: (content) => {
            addItem.mutate({ content }, {
                onSuccess: () => {
                    toggleEditing();
                    onSuccess();
                },
            });
        },
        onChange,
    });
    const ref = useScrollIntoView(isEditing);
    if (isEditing) {
        return (_jsx(ItemContainer, { done: false, children: _jsx(EditorContainer, { children: _jsx(RichTextField, { ...props, inititalState: state, isLoading: addItem.isLoading, placeholder: "Add item...", ref: ref, icon: faCirclePlus, children: _jsx(Button.Icon, { className: "ml-auto", size: "s", icon: faXmark, onClick: toggleEditing }) }) }) }));
    }
    return (_jsxs(ItemContainer, { done: false, onClick: toggleEditing, checkboxDisabled: true, children: [_jsx("span", { className: "text-greyscale-400 mt-[5px]", children: "Add an item..." }), _jsx(Button.Icon, { className: "ml-auto text-secondary-300", size: "m", icon: faCirclePlus })] }));
};
const ChecklistItem = ({ isEditing, toggleEditing, noHover, ...props }) => {
    const toggleItem = useToggleChecklistItem();
    const updateItem = useUpdateChecklistItem();
    const deleteItem = useDeleteChecklistItem();
    const fieldProps = useRichTextField({
        onSubmit: (content) => {
            updateItem.mutate({ id: props.id, content }, { onSuccess: () => setTimeout(toggleEditing) });
        },
    });
    const ref = useScrollIntoView(isEditing);
    if (isEditing) {
        return (_jsx(ItemContainer, { done: false, children: _jsx(EditorContainer, { children: _jsx(RichTextField, { ...fieldProps, inititalState: props.content, isLoading: updateItem.isLoading, ref: ref, icon: faArrowCircleUp, children: _jsx(Button.Icon, { className: "ml-auto", size: "s", icon: faXmark, onClick: toggleEditing }) }) }) }));
    }
    return (_jsxs(ItemContainer, { done: props.done, noHover: noHover, onCheckboxClick: () => toggleItem.mutate(props.id), className: "cursor-grab group flex-wrap", children: [_jsxs("div", { className: "relative flex-1 flex", children: [_jsx(LexicalEditor, { initialState: props.content, className: "mt-[5px] grow" }), _jsx("div", { className: "flex flex-none transition-all opacity-0 w-0 group-hover:w-6" }), _jsxs("div", { className: "absolute right-1 flex flex-none transition-all opacity-0 group-hover:opacity-100", children: [_jsx(Button.Icon, { className: "h-4 w-3", iconSize: "s", icon: faPencil, onClick: toggleEditing }), _jsx(Button.Icon, { className: "h-4 w-3", iconSize: "s", icon: faTrashCan, onClick: () => deleteItem.mutate(props.id) })] })] }), props.marked_by && props.done && (_jsxs("div", { className: "w-full flex items-center gap-1 ml-5", children: [_jsx(UserCard, { ...props.marked_by.actor, size: "xs" }), _jsx("span", { "data-tt": "Marked as done", className: "text-greyscale-400 font-brand-c1r", children: formatDate(props.marked_by.date) })] }))] }));
};
const ItemContainer = forwardRef(({ done, children, onCheckboxClick, noHover, checkboxDisabled, ...props }, ref) => (_jsxs("div", { ref: ref, ...props, className: cx('flex gap-1 p-1 group transition-colors rounded font-brand-b1', !noHover && 'hover:bg-greyscale-100', done && 'text-greyscale-400', props.className), children: [_jsx(Button.Icon, { "data-stop-propagation": true, 
            // TODO extract this since this container component props are kinda ugly :(
            className: cx(done && 'text-primary-300'), size: "m", icon: done ? faSquareCheck : faSquare, onClick: onCheckboxClick, disabled: checkboxDisabled }), children] })));
