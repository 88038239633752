import { getSort } from '../utils';
import { useParsedQuery } from './useParsedQuery';
export const useBackendTable = ({ data, tableConfig, isFetching, }) => {
    var _a, _b;
    const { queryParams, setQueryParams } = useParsedQuery();
    // TODO fix type, or whatever
    const onSort = (option) => {
        setQueryParams((params) => {
            var _a;
            return ({
                ...params,
                sort: getSort(((_a = params.sort) === null || _a === void 0 ? void 0 : _a.option) === option ? params.sort : option),
            });
        });
    };
    const onPageChange = (page) => {
        setQueryParams((params) => ({ ...params, page }));
    };
    if (!data) {
        return null;
    }
    return {
        tableConfig,
        items: data.items,
        totalPages: data.metadata.pages,
        page: (_a = queryParams.page) !== null && _a !== void 0 ? _a : 1,
        onPageChange,
        sort: (_b = queryParams.sort) !== null && _b !== void 0 ? _b : null,
        onSort,
        isFetching,
    };
};
