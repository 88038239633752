import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { cx } from '~/common/utils';
import styles from './InlineInput.module.scss';
export const InlineInput = forwardRef(({ className, title, size = 'regular', error = false, textAlignment, children, type = 'text', ...props }, ref) => {
    // this is needed to prevent annoying input blurring and re-focusing
    const handleMouseDown = (e) => {
        if (e.target instanceof HTMLElement && e.target.matches('input')) {
            return;
        }
        e.preventDefault();
    };
    const handleFocus = (e) => {
        var _a;
        const input = e.currentTarget;
        // this is so funny man
        input.type = 'text';
        input.setSelectionRange(0, input.value.length);
        input.type = type;
        (_a = props.onFocus) === null || _a === void 0 ? void 0 : _a.call(props, e);
    };
    return (_jsxs("label", { ref: ref, className: cx(styles.wrapper, size && styles[size], textAlignment && styles[textAlignment], {
            [styles.disabled]: props.disabled,
            [styles.readOnly]: props.readOnly,
            [styles.inputDisabled]: props.disabled,
            [styles.hasChildren]: children,
            [styles.error]: error,
        }, className), onMouseDown: handleMouseDown, children: [_jsx("span", { className: styles.title, children: title }), _jsxs("div", { className: styles.inputWrapper, children: [_jsx("input", { ...props, className: styles.input, disabled: props.disabled || props.readOnly, type: type, onFocus: handleFocus }), children && _jsx("div", { className: styles.children, children: children })] })] }));
});
