import { hashQueryKey, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { z } from 'zod';
import { useDebounce, useDocumentVisibility, useUpdateCache } from '~/common/hooks';
import { usePrivateChannel } from '~/common/kits/socket';
import { Badger, map } from '~/common/utils';
import { VisitorsUpdated } from '~/orders/domain';
import { qk } from '~/root/query-keys';
import { locationStore } from './Auth';
import { CustomerLastActiveUpdate } from './domain';
import { useRootStore } from './store';
const orderRefetch = z.union([
    z.object({ order_id: z.number() }).transform(({ order_id: id }) => id),
    z
        .any()
        .array()
        .transform(() => null),
]);
const userIdRefetch = z.object({
    user_id: z.number(),
});
export const Effects = ({ userId }) => {
    const visitedModules = useRootStore((store) => store.visitedModules);
    const client = useQueryClient();
    const history = useHistory();
    /**
     * Query invalidation batcher
     */
    const queriesRef = useRef(new Set());
    const [invalidateCount, setInvalidateCount] = useState(0);
    const debouncedCount = useDebounce(invalidateCount, 400);
    const documentVisible = useDocumentVisibility();
    const invalidateQuery = (queryKey) => {
        const hashedKey = hashQueryKey(queryKey);
        if (!queriesRef.current.has(hashedKey)) {
            queriesRef.current.add(hashedKey);
        }
        setInvalidateCount((c) => c + 1);
    };
    useEffect(() => {
        if (!queriesRef.current.size || !documentVisible) {
            return;
        }
        for (const query of queriesRef.current) {
            client.invalidateQueries(JSON.parse(query));
        }
        queriesRef.current = new Set();
    }, [client, documentVisible, debouncedCount]);
    /**
     * Update favicon with unread notifications
     */
    useEffect(() => {
        const badger = new Badger({ backgroundColor: '#E14949', position: 'bottom-right', size: 0.6 });
        const unsubscribe = useRootStore.subscribe(({ unreadCount }) => badger.update(unreadCount));
        badger.update(useRootStore.getState().unreadCount);
        return () => {
            unsubscribe();
            badger.update(0);
        };
    }, []);
    /**
     * Subscribe to users update websocket
     */
    usePrivateChannel({
        channelName: 'staff.users',
        events: [
            {
                event: '.users.refetch',
                callback: () => {
                    invalidateQuery(qk.usersInit);
                    invalidateQuery(qk.usersList);
                },
            },
            {
                event: '.user.refetch',
                decoder: z.object({ user_id: z.number() }),
                callback: ({ user_id: id }) => {
                    invalidateQuery(qk.usersInit);
                    invalidateQuery(qk.user(id));
                    // we're closing on userId and still completely safe :D
                    if (userId === id) {
                        // also reset logged in user if changed
                        invalidateQuery(qk.init);
                    }
                },
            },
        ],
    });
    /**
     * Subscribe to teams update websocket if visited once
     */
    usePrivateChannel({
        channelName: 'staff.teams',
        enabled: visitedModules.has('teams'),
        events: [
            {
                event: '.teams.refetch',
                callback: () => {
                    invalidateQuery(qk.teamsInit);
                    invalidateQuery(qk.teamsList);
                },
            },
            {
                event: '.team.refetch',
                decoder: z.object({ team_id: z.number() }),
                callback: ({ team_id: id }) => {
                    invalidateQuery(qk.teamsInit);
                    invalidateQuery(qk.team(id));
                },
            },
        ],
    });
    /**
     * Subscribe to rms update websocket if visited once
     */
    usePrivateChannel({
        channelName: 'staff.rms',
        enabled: visitedModules.has('rms'),
        events: [
            {
                event: '.rms.distributed',
                callback: () => invalidateQuery(qk.rmsInit),
            },
            {
                event: '.rms.refetch',
                callback: () => invalidateQuery(qk.rmsAll),
            },
        ],
    });
    const updateOrdersInit = useUpdateCache(qk.ordersInit, 'Init cache is not defined');
    /**
     * Subscribe to orders update websocket if visited once
     */
    usePrivateChannel({
        channelName: 'staff.orders',
        enabled: visitedModules.has('orders') || visitedModules.has('rms'),
        events: [
            {
                event: '.orders.refetch',
                callback: () => invalidateQuery(qk.ordersList),
            },
            {
                event: '.order.refetch',
                decoder: orderRefetch,
                callback: (id) => {
                    if (id) {
                        // active orders are updated in-place, and batch invalidating
                        // inactive orders gives no benefits
                        client.invalidateQueries(qk.orderBase(id), { type: 'inactive' });
                        invalidateQuery(qk.orderActivity(id));
                        invalidateQuery(qk.rmsOrder(id));
                    }
                    else {
                        invalidateQuery(qk.ordersAll);
                    }
                },
            },
            {
                event: '.order.visitors.updated',
                decoder: VisitorsUpdated,
                callback: ({ order_id, visitors: newVisitors }) => {
                    try {
                        updateOrdersInit((initData) => ({
                            ...initData,
                            visitors: {
                                ...initData.visitors,
                                [order_id]: newVisitors,
                            },
                        }));
                    }
                    catch (_a) { }
                },
            },
        ],
    });
    /**
     * Subscribe to shifts update websocket if visited once
     */
    usePrivateChannel({
        channelName: 'staff.shifts',
        enabled: visitedModules.has('shifts'),
        events: [
            {
                event: '.shifts.refetch',
                callback: () => invalidateQuery(qk.shiftsAll),
            },
            {
                event: '.shift.timeline.refetch',
                callback: () => invalidateQuery(qk.shiftsAll),
            },
        ],
    });
    /*
     * Subscribe to customers update websocket if visited once
     */
    usePrivateChannel({
        channelName: 'staff.customers',
        enabled: visitedModules.has('customers'),
        events: [
            {
                event: '.customers.refetch',
                callback: () => {
                    invalidateQuery(qk.customersList);
                },
            },
            {
                event: '.customer.refetch',
                decoder: userIdRefetch,
                callback: ({ user_id }) => {
                    invalidateQuery(qk.customer(user_id));
                },
            },
        ],
    });
    /*
     * Subscribe to subscriptions update websocket if visited once
     */
    usePrivateChannel({
        channelName: 'staff.subscriptions',
        enabled: visitedModules.has('subscriptions'),
        events: [
            {
                event: '.subscriptions.refetch',
                callback: () => {
                    invalidateQuery(qk.subscriptionsList);
                },
            },
            {
                event: '.customer.subscription.refetch',
                decoder: userIdRefetch,
                callback: ({ user_id }) => {
                    invalidateQuery(qk.subscription(user_id));
                },
            },
        ],
    });
    /*
     * Subscribe to customers last active update websocket
     */
    usePrivateChannel({
        channelName: 'staff.customers',
        events: [
            {
                event: '.customer.last_activity',
                decoder: CustomerLastActiveUpdate,
                callback: ({ user_id, last_active_at }) => {
                    const queryKey = qk.usercards;
                    client.setQueryData(queryKey, (prev) => {
                        if (!prev) {
                            return;
                        }
                        const customerCard = prev.get(user_id);
                        if (!customerCard || !customerCard.card) {
                            return prev;
                        }
                        return map.add(prev, user_id, {
                            card: { ...customerCard.card, last_active_at },
                            timestamp: Date.now(),
                        });
                    });
                    // Update last_visited_at in orders where customer id matches
                    client.setQueriesData({
                        predicate: (query) => {
                            return ((query === null || query === void 0 ? void 0 : query.queryKey[0]) === 'orders' &&
                                (query === null || query === void 0 ? void 0 : query.queryKey[1]) === 'item' &&
                                (query === null || query === void 0 ? void 0 : query.queryKey[3]) === 'base');
                        },
                    }, (order) => {
                        return !order || order.customer.id !== user_id
                            ? order
                            : {
                                ...order,
                                customer: {
                                    ...order.customer,
                                    last_active_at,
                                },
                            };
                    });
                },
            },
        ],
    });
    /**
     * Notify interested parties in location changes
     */
    useEffect(() => {
        return history.listen((location) => locationStore.setState(location));
    }, [history]);
    return null;
};
