import { customValidator } from '~/common/utils';
export const initialSubscriptionScheduleValues = {
    is_trial: false,
    trial_ends_at: null,
    activate_at: null,
};
export const subscriptionScheduleFormValidators = ({ is_trial, trial_ends_at, }) => ({
    is_trial: null,
    trial_ends_at: is_trial ? customValidator.required() : null,
    activate_at: !trial_ends_at ? customValidator.required() : null,
});
