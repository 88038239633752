import { jsx as _jsx } from "react/jsx-runtime";
import { nonEmptyArray } from 'fp-ts';
import { useCallback, useState } from 'react';
import { getOptionLabel } from '~/common/utils';
import { useChangeOrderStatus } from '~/orders/hooks';
import { TransitionApproved, TransitionCancelled, TransitionCompleted, TransitionDesignInProgress, TransitionOpen, TransitionOther, } from '~/orders/Order';
export const StatusTransition = ({ currentStatusLabel, onClose, onOpenCapacityDistribution, transitions, }) => {
    const [nextStatus, setNextStatus] = useState(nonEmptyArray.head(transitions).value);
    const getNextStatusLabel = useCallback((nextStatus) => getOptionLabel(transitions, nextStatus), [transitions]);
    const changeStatus = useChangeOrderStatus();
    const commonProps = {
        currentStatusLabel,
        onChangeStatus: setNextStatus,
        onClose,
        onSubmit: (data) => changeStatus(data).then(onClose),
        nextStatus,
        nextStatusLabel: getNextStatusLabel(nextStatus),
        transitions,
    };
    switch (nextStatus) {
        case 'Open':
            return _jsx(TransitionOpen, { ...commonProps });
        case 'Approved':
            return _jsx(TransitionApproved, { ...commonProps });
        case 'DesignInProgress':
            return (_jsx(TransitionDesignInProgress, { ...commonProps, onSubmit: (data) => commonProps.onSubmit(data).then(onOpenCapacityDistribution) }));
        case 'ReadyForReview':
        case 'ReadyToSend':
        case 'WaitingForCustomer':
        case 'FeedbackReceived':
            const nextStatusLabel = getNextStatusLabel(nextStatus);
            return (_jsx(TransitionOther, { ...commonProps, nextStatus: nextStatus, nextStatusLabel: nextStatusLabel }));
        // TODO this transition might not be needed and will be removed in the
        // future (same for FeedbackReceived)
        case 'Completed':
            return _jsx(TransitionCompleted, { ...commonProps });
        case 'Cancelled':
            return _jsx(TransitionCancelled, { ...commonProps });
        default:
            return null;
    }
};
