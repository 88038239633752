import { $isAutoLinkNode, $isLinkNode } from '@lexical/link';
import { $isAtNodeEnd } from '@lexical/selection';
import { $findMatchingParent } from '@lexical/utils';
import { $getSelection, $isLineBreakNode, $isRangeSelection, $isTextNode, } from 'lexical';
import { z } from 'zod';
import { jsonOrUndefined } from '~/common/utils';
// sugar for lean mergeRegister listeners stack, prettier makes it really
// verbose with inline callbacks
export const notHandled = (cb) => {
    return () => {
        cb();
        return false;
    };
};
export const getIsClickedAtEndOfTheNode = (node, selection) => {
    const anchorOffset = selection.anchor.offset;
    const focusOffset = selection.focus.offset;
    const textLength = node.getTextContentSize();
    return anchorOffset === textLength || focusOffset === textLength;
};
export const $selectNodeAsWhole = (node, selection) => {
    const anchorNode = node.getFirstDescendant() || node;
    const focusNode = node.getLastDescendant() || node;
    const anchorOffset = 0;
    const focusOffset = focusNode.getTextContentSize();
    if ($isTextNode(anchorNode) && $isTextNode(focusNode)) {
        selection.setTextNodeRange(anchorNode, anchorOffset, focusNode, focusOffset);
    }
};
export const getNativeSelectionInsideEditor = (editor) => {
    const nativeSelection = window.getSelection();
    const rootElement = editor.getRootElement();
    if (nativeSelection !== null &&
        editor.isEditable() &&
        rootElement !== null &&
        rootElement.contains(nativeSelection.anchorNode)) {
        return nativeSelection;
    }
    return null;
};
export function getSelectedNode(selection) {
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
        return anchorNode;
    }
    const isBackward = selection.isBackward();
    if (isBackward) {
        return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    }
    else {
        return $isAtNodeEnd(anchor) ? anchorNode : focusNode;
    }
}
export function getIsSelectionInLink() {
    const selection = $getSelection();
    if (!$isRangeSelection(selection)) {
        return false;
    }
    const focusNode = getSelectedNode(selection);
    const focusLinkNode = $findMatchingParent(focusNode, $isLinkNode);
    const focusAutoLinkNode = $findMatchingParent(focusNode, $isAutoLinkNode);
    if (!(focusLinkNode || focusAutoLinkNode)) {
        return false;
    }
    const badNode = selection
        .getNodes()
        .filter((node) => !$isLineBreakNode(node))
        .find((node) => {
        const linkNode = $findMatchingParent(node, $isLinkNode);
        const autoLinkNode = $findMatchingParent(node, $isAutoLinkNode);
        return ((focusLinkNode && !focusLinkNode.is(linkNode)) ||
            (linkNode && !linkNode.is(focusLinkNode)) ||
            (focusAutoLinkNode && !focusAutoLinkNode.is(autoLinkNode)) ||
            (autoLinkNode && !autoLinkNode.is(focusAutoLinkNode)));
    });
    return !badNode;
}
const SUPPORTED_URL_PROTOCOLS = new Set(['http:', 'https:', 'mailto:', 'sms:', 'tel:']);
export function sanitizeUrl(url) {
    try {
        const parsedUrl = new URL(url);
        // eslint-disable-next-line no-script-url
        if (!SUPPORTED_URL_PROTOCOLS.has(parsedUrl.protocol)) {
            return 'about:blank';
        }
    }
    catch (_a) {
        return url;
    }
    return url;
}
export const getEditorStateJSON = (editorState) => {
    if (!editorState) {
        return null;
    }
    const serializedNodes = editorState.toJSON();
    // Check recursively for nodes that have text
    const hasTextNode = (node) => {
        if (node.text && node.text.trim()) {
            return true;
        }
        if (node.children) {
            return node.children.some(hasTextNode);
        }
        return false;
    };
    const hasText = serializedNodes.root.children.some(hasTextNode);
    if (!hasText) {
        return null;
    }
    return JSON.stringify(serializedNodes);
};
export const LexicalEditorState = z.string().refine((value) => {
    var _a;
    const parsed = jsonOrUndefined(value);
    return Array.isArray((_a = parsed === null || parsed === void 0 ? void 0 : parsed.root) === null || _a === void 0 ? void 0 : _a.children);
});
