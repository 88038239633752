import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faChevronRight, faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { Badge, IconBox } from '~/common/components';
import { cx, date, getOptionLabel, isNonEmptyString } from '~/common/utils';
import styles from './TimeLineItem.module.scss';
export const TimeLineItem = ({ item, statuses }) => {
    const statusFrom = getOptionLabel(statuses, item.statusFrom);
    const statusTo = getOptionLabel(statuses, item.statusTo);
    return (_jsxs("div", { className: cx('flex space-x-2', styles.container), children: [_jsx("div", { className: styles.arrow, children: _jsx(IconBox, { size: "s", icon: faChevronRight }) }), _jsxs("div", { className: "flex flex-col space-y-1", children: [_jsxs("div", { className: "flex items-center space-x-1", children: [_jsx(Badge, { color: "grey-solid", children: statusFrom }), isNonEmptyString(item.timeInStatus) && _jsx(Badge, { children: item.timeInStatus })] }), _jsxs("div", { className: "flex items-center space-x-1", children: [_jsx("span", { className: "font-normal text-greyscale-500 text-sm", children: statusFrom }), _jsx(IconBox, { size: "s", className: "text-greyscale-500", icon: faLongArrowRight }), _jsx("span", { className: "font-normal text-greyscale-500 text-sm", children: statusTo }), _jsxs("span", { className: "font-light text-greyscale-400 text-sm", children: ["by ", item.user.name] })] }), _jsx("span", { className: "font-light text-xs text-greyscale-400", children: date.formatDate(item.date) })] })] }));
};
