import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Errors, FormElementLabel, Badge } from '~/common/components';
import { cx } from '~/common/utils';
// TODO extract this to some MultiAnySelect component that is just logic w/o presentation
export const BadgeSelect = ({ title, badges, value, onChange, errors, optional }) => {
    const makeBadge = (badge) => {
        const selected = value.includes(badge.id);
        return (_jsxs(Badge.Button, { className: "flex items-center gap-2", color: selected ? 'primary' : 'lighter-grey', onClick: () => onChange(selected ? value.filter((vId) => vId !== badge.id) : [...value, badge.id]), children: [_jsx("span", { className: "font-brand-t4b", children: badge.sign }), _jsx("span", { className: cx('font-brand-c1r', selected ? 'text-success-400' : 'text-greyscale-400'), children: badge.label })] }, badge.id));
    };
    return (_jsxs("div", { className: "space-y-1", children: [_jsx(FormElementLabel, { title: title, optional: optional }), _jsx("div", { className: "flex flex-wrap gap-2", children: badges.map(makeBadge) }), errors && _jsx(Errors, { errors: errors, color: true })] }));
};
