import { jsx as _jsx } from "react/jsx-runtime";
import { faShapes } from '@fortawesome/pro-regular-svg-icons';
import { LayoutContent } from '~/common/components';
import { Nothing, Table, useBackendTable } from '~/common/kits/table';
import { cx } from '~/common/utils';
import { defaultTableConfig } from './table-config';
export const DefaultActivityTable = ({ data, tableConfig = defaultTableConfig, isFetching, containerClassName, className, }) => {
    const view = useBackendTable({
        data: {
            items: data.items,
            metadata: 'metadata' in data && data.metadata ? data.metadata : { pages: 1 },
        },
        tableConfig,
        isFetching,
    });
    if (!view) {
        return _jsx(LayoutContent, { loading: true });
    }
    return (_jsx(Table, { ...view, className: className, containerClassName: cx(containerClassName, 'def:mt-0'), noDataPlaceholder: _jsx(Nothing, { icon: faShapes }) }));
};
