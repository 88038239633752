import { jsx as _jsx } from "react/jsx-runtime";
import { UsersGroup } from '~/common/components';
import { useAddUserCardId } from '~/common/kits/usercard';
import { useInitData } from '~/orders/hooks';
export const OrderViewers = ({ id }) => {
    const init = useInitData();
    const visitors = init.visitors[id] || [];
    useAddUserCardId(...visitors.map((visitor) => visitor.id));
    if (!visitors.length) {
        return null;
    }
    return _jsx(UsersGroup, { users: visitors });
};
