import { useMutation } from '@tanstack/react-query';
import { useIdParam } from '~/common/hooks';
import { httpClient } from '~/common/utils';
import { Order } from '~/orders/domain';
import { qk } from '~/root/query-keys';
import { useUpdateOrderCache } from './useUpdateOrderCache';
export function useUpdateIteration(iterationId) {
    const id = useIdParam();
    const updateOrderCache = useUpdateOrderCache();
    const { mutateAsync, isLoading } = useMutation({
        mutationFn: (data) => httpClient.put(`v1/staff/orders/${id}/iterations/${iterationId}`, {
            data,
            decoder: Order,
            params: { full_response: true },
        }),
        mutationKey: qk.orderUpdateIteration(id),
        onSuccess: (response) => {
            updateOrderCache((order) => ({ ...order, ...response }));
        },
    });
    return { updateIteration: mutateAsync, isLoading };
}
