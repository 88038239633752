import { useQuery } from '@tanstack/react-query';
import { Activity } from '~/common/kits/activity';
import { getFlatQuery, useParsedQuery } from '~/common/kits/table';
import { httpClient } from '~/common/utils';
import { qk } from '~/root/query-keys';
import { useCustomerParams } from '../Customer';
export const useCustomerActivity = () => {
    const { queryParams } = useParsedQuery();
    const params = {
        ...getFlatQuery(queryParams),
        limit: 25,
    };
    const { customer_id } = useCustomerParams();
    return useQuery({
        queryKey: [...qk.customerActivity(customer_id), params],
        queryFn: () => httpClient.get(`/v1/staff/customers/${customer_id}/activity`, {
            decoder: Activity,
            params,
        }),
        keepPreviousData: true,
    });
};
