import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useForm } from 'formoid';
import { nonEmptyArray } from 'fp-ts';
import { Button, Link, ModalHeader, ModalShell, useModalContext } from '~/common/components';
import { twoDecimalsPrecision } from '~/common/utils';
import { useOrderData } from '~/orders/hooks';
import { discountToString, is100PercentBounty } from '~/orders/utils';
import { SectionCell } from '../Section';
import { useManualCharge, usePaymentMethods } from './hooks';
import { PaymentMethodSelect } from './PaymentMethodSelect';
// TODO this is suboptimal to calculate on FE due to float rounding
const getDiscountedPrice = (price, discount) => {
    if ((discount === null || discount === void 0 ? void 0 : discount.type) === 'absolute') {
        return twoDecimalsPrecision(price - discount.value);
    }
    if ((discount === null || discount === void 0 ? void 0 : discount.type) === 'percent') {
        return twoDecimalsPrecision(price - (discount.value * price) / 100);
    }
    return price;
};
const PaymentSection = ({ methodType }) => {
    var _a;
    const { payment } = useOrderData();
    return (_jsxs("div", { className: "flex flex-col gap-1", children: [_jsx(SectionCell, { title: "Order price", children: _jsxs("span", { className: "font-brand-t4m text-greyscale-600", children: [payment.currency, " ", payment.unit] }) }), _jsx(SectionCell, { title: "Coupon", children: _jsx("span", { className: "font-brand-t4m text-greyscale-600", children: (_a = payment.coupon) !== null && _a !== void 0 ? _a : '—' }) }), _jsx(SectionCell, { title: "Discount", children: _jsx("span", { className: "font-brand-t4m text-greyscale-600", children: payment.discount ? discountToString(payment.discount, payment.currency) : '—' }) }), _jsx(SectionCell, { title: "VAT", children: _jsxs("span", { className: "font-brand-t4m text-greyscale-600", children: [payment.currency, " ", methodType === 'credits' ? 0 : payment.vat] }) }), _jsxs("div", { className: "font-brand-t4m text-greyscale-600 flex items-center justify-between w-full p-[4px]", children: [_jsx("span", { children: "Total" }), _jsxs("span", { children: [payment.currency, ' ', methodType === 'credits'
                                ? getDiscountedPrice(payment.unit, payment.discount)
                                : payment.amount] })] })] }));
};
const Content = ({ onClose }) => {
    const methods = usePaymentMethods();
    const { customer } = useOrderData();
    const { fieldProps, handleSubmit, isSubmitting, setErrors, values } = useForm({
        initialValues: { method: nonEmptyArray.head(methods) },
        validators: () => ({ method: null }),
        validationStrategy: 'onBlur',
    });
    const chargeManually = useManualCharge({
        onError: (message) => setErrors('method', [message]),
        onSuccess: onClose,
    });
    const submit = () => handleSubmit(({ method }) => chargeManually(method));
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { title: "Manual charge", onClose: onClose }), _jsxs(ModalShell, { className: "flex flex-col gap-3 p-3 w-[712px]", loading: isSubmitting, onClose: onClose, onSubmit: submit, submitText: "Charge manually", children: [_jsxs("span", { className: "font-brand-b1", children: ["You are about to charge", ' ', _jsx(Link, { size: "m", href: `mailto:${customer.email}`, children: customer.email }), ' ', "and close this order. Refunds are pain, so make sure you are doing it right!"] }), _jsx(PaymentMethodSelect, { ...fieldProps('method'), methods: methods }), _jsx(PaymentSection, { methodType: values.method.type })] })] }));
};
export const ManualCharge = () => {
    const { status, payment } = useOrderData();
    const { modalOpener } = useModalContext();
    if (status === 'Completed' ||
        status === 'Cancelled' ||
        payment.status === 'paid' ||
        (payment.discount && is100PercentBounty(payment.discount))) {
        return null;
    }
    return (_jsx(Button, { onClick: modalOpener(Content), className: "mt-1 mr-auto", color: "grey", children: "Charge manually" }));
};
