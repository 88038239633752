import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Switcher as SwitcherComponent, Tooltip } from '~/common/components';
import { cx } from '~/common/utils';
import styles from './Switcher.module.scss';
export const Switcher = ({ disabled = false, errors, title, ...switcherProps }) => {
    // TODO use <Errors />
    const errorsContent = errors === null || errors === void 0 ? void 0 : errors.map((error, key) => (_jsx("span", { className: styles.error, children: error }, key)));
    return (_jsx(Tooltip, { color: "danger", content: errorsContent, children: _jsxs("div", { className: cx(styles.container, {
                [styles.disabled]: disabled,
                [styles.invalid]: errors,
            }), children: [_jsx("label", { className: styles.label, children: title }), _jsx(SwitcherComponent, { ...switcherProps, disabled: disabled })] }) }));
};
