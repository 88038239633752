import { array, map, nonEmptyArray as nonEmptyArrayFpts, option, set } from 'fp-ts';
import { flow, pipe } from 'fp-ts/function';
export function toggleElement(target, eq, ord) {
    return flow(set.fromArray(eq), set.toggle(eq)(target), set.toArray(ord));
}
export function swapElements(firstIndex, secondIndex) {
    return (as) => {
        const copy = as.slice();
        const draggedPreview = copy[firstIndex];
        copy.splice(firstIndex, 1);
        copy.splice(secondIndex, 0, draggedPreview);
        return copy;
    };
}
export function groupBy(eq, groupFn) {
    return array.reduce(new Map(), (result, current) => {
        const key = groupFn(current);
        return pipe(result, map.modifyAt(eq)(key, array.append(current)), option.getOrElse(() => pipe(result, map.upsertAt(eq)(key, nonEmptyArrayFpts.of(current)))));
    });
}
/**
 * Inserts an element between each two elements of the array
 * [A, A, A] becomes [A, B, A, B, A]
 */
export function intersperse(array, element) {
    const result = [];
    let i = 0;
    if (i < array.length) {
        result.push(array[i++]);
    }
    while (i < array.length) {
        result.push(element, array[i++]);
    }
    return result;
}
export function nonEmptyArray(arr) {
    return array.isNonEmpty(arr) ? arr : undefined;
}
export function modifyLast(arr, value) {
    if (!arr.length) {
        return arr;
    }
    const next = [...arr];
    next.pop();
    next.push(value);
    return next;
}
export function range(start, end, fn) {
    const result = [];
    for (let i = start; i <= end; i++) {
        result.push(fn ? fn(i + 1) : i);
    }
    return result;
}
export function lastItem(array) {
    return array[array.length - 1];
}
export function joinTruthy(array, delimeter = '\n') {
    return array.filter(Boolean).join(delimeter);
}
