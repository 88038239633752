import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Banner, DatepickerField, InputField, SwitcherField } from '~/common/components';
import { SubscriptionSection } from '../SubscriptionSection';
import { AddInvoicePaymentMethod } from './AddInvoicePaymentMethod';
import { AddInvoicePaymentMethodButton } from './AddInvoicePaymentMethodButton';
import { PaymentMethodSelectField } from './PaymentMethodSelectField';
export const PaymentMethod = ({ form, paymentMethods, isCustomerTrusted, onPaymentMethodChange, onInvoiceMethodModalClose, }) => {
    return (_jsx(SubscriptionSection, { title: "Payment method", children: _jsx("div", { className: "grid grid-cols-[502px_1fr] gap-3", children: paymentMethods.length !== 0 ? (_jsx(SelectPaymentMethodForm, { form: form, paymentMethods: paymentMethods, isCustomerTrusted: isCustomerTrusted, onPaymentMethodChange: onPaymentMethodChange, onInvoiceMethodModalClose: onInvoiceMethodModalClose })) : (_jsx(AddInvoicePaymentMethod, { form: form, onInvoiceMethodModalClose: onInvoiceMethodModalClose })) }) }));
};
const SelectPaymentMethodForm = ({ form: { fieldProps, values }, paymentMethods, isCustomerTrusted, onPaymentMethodChange, onInvoiceMethodModalClose, }) => {
    const invoicePaymentMethod = useMemo(() => paymentMethods.find((method) => 'address' in method), [paymentMethods]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { children: [_jsx(PaymentMethodSelectField, { ...fieldProps('payment_method_id'), onChange: onPaymentMethodChange, options: paymentMethods }), !invoicePaymentMethod && (_jsxs(_Fragment, { children: [_jsx("p", { className: "text-greyscale-500 font-brand-b1 mt-2 mb-1", children: "Does the customer want to pay with invoice?" }), _jsx(AddInvoicePaymentMethodButton, { onInvoiceMethodModalClose: onInvoiceMethodModalClose })] }))] }), values.payment_method_id === (invoicePaymentMethod === null || invoicePaymentMethod === void 0 ? void 0 : invoicePaymentMethod.id) && (_jsxs(_Fragment, { children: [_jsx("div", {}), _jsx(InputField, { ...fieldProps('po_number'), title: "PO number", hint: "PO number for subscription can be different from PO number for payment method.", optional: true, placeholder: "Enter PO number", type: "text" }), _jsx(DatepickerField, { ...fieldProps('po_expires_at'), title: "Expiration date", hint: "When selected, CS and Finance will be reminded to update the PO number", optional: true, nullable: true }), _jsxs("div", { className: "flex flex-col items-baseline gap-y-3 col-span-2", children: [_jsx(SwitcherField, { ...fieldProps('prevent_sending_invoice'), title: "Do not send invoice directly to the customer", hint: "By default, an invoice is sent to a customer automatically, but if the switcher is on (green) it\u2019ll not happen and it needs to be done manually", className: "inline-flex flex-row-reverse gap-x-1" }), !isCustomerTrusted && (_jsx(Banner, { color: "warning", title: "Warning", className: "w-full", children: "This customer is currently untrusted. The subscription will be activated once the payment is received." }))] })] }))] }));
};
