import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { useIdParam } from '~/common/hooks';
import { getFlatQuery, useParsedQuery } from '~/common/kits/table';
import { assertQueryData, httpClient } from '~/common/utils';
import { qk } from '~/root/query-keys';
import { AnyUser, getDecoderFromListRole, Init, roleToListRole } from './domain';
export const USERS_URL = '/users';
export const useInit = () => {
    return useQuery({
        queryKey: qk.usersInit,
        queryFn: () => httpClient.get(`/v1/staff/users/init`, { decoder: Init }),
    });
};
export const useInitData = () => assertQueryData(useInit());
export const useUsersList = (role) => {
    const { queryParams } = useParsedQuery();
    const listRole = roleToListRole(role);
    const params = {
        ...getFlatQuery(queryParams),
        'filter[role]': listRole === 'designer' ? 'designer|design_manager' : listRole,
        limit: 25,
    };
    return useQuery({
        queryKey: [...qk.usersList, role, params],
        queryFn: () => httpClient.get('/v1/staff/users', { decoder: getDecoderFromListRole(role), params }),
        keepPreviousData: true,
    });
};
export const useUser = () => {
    const id = useIdParam();
    return useQuery({
        queryKey: qk.user(id),
        queryFn: () => httpClient.get(`/v1/staff/users/${id}`, { decoder: AnyUser }),
    });
};
export const useUserData = () => assertQueryData(useUser());
export const useUserCreation = () => {
    const client = useQueryClient();
    const history = useHistory();
    return useMutation({
        mutationFn: (data) => httpClient.post('/v1/staff/users', { data, decoder: AnyUser }),
        onSuccess: (next) => {
            const id = next.id;
            client.setQueryData(qk.user(id), next);
            history.push(`${USERS_URL}/${id}`);
        },
    });
};
export const useUserMutation = () => {
    const client = useQueryClient();
    const id = useIdParam();
    return useMutation({
        mutationFn: (data) => httpClient.patch(`/v1/staff/users/${id}`, { data, decoder: AnyUser }),
        onSuccess: (next) => {
            client.setQueryData(qk.user(id), (prev) => ({ ...prev, ...next }));
            client.invalidateQueries(qk.usersList);
        },
    });
};
export const usePermissionsMutation = () => {
    const client = useQueryClient();
    const id = useIdParam();
    return useMutation({
        mutationFn: (data) => httpClient.put(`/v1/staff/users/${id}/permissions`, { data, decoder: AnyUser }),
        onSuccess: (next) => {
            client.setQueryData(qk.user(id), (prev) => ({ ...prev, ...next }));
        },
    });
};
export const useResendInvitation = () => {
    const client = useQueryClient();
    const id = useIdParam();
    return useMutation({
        mutationFn: () => httpClient.post(`/v1/staff/users/${id}/resend-invitation`),
        onSuccess: () => {
            client.invalidateQueries(qk.usersList);
        },
    });
};
export const useUserDeletion = () => {
    const client = useQueryClient();
    const id = useIdParam();
    return useMutation({
        mutationFn: () => httpClient.delete(`/v1/staff/users/${id}`),
        onSuccess: () => {
            client.removeQueries(qk.user(id));
        },
    });
};
