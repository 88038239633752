import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CountryFlag, Errors, SelectFactory, } from '~/common/components';
import { useDialCodesData } from '~/root';
// TODO this should include phone input and moved out to ~/common/components
// it should accept two fieldProps, one for country code, other for the phone number
const filterCountryCodes = (search) => (option) => option.name.toLowerCase().includes(search) || option.country_name.toLowerCase().includes(search);
export const CountryDialCodeSelectField = ({ className, errors, touched, value, onChange, ...props }) => {
    var _a;
    const codes = useDialCodesData();
    const countryCode = value
        ? (_a = codes.find(({ value: country_id }) => value === country_id)) === null || _a === void 0 ? void 0 : _a.country_code
        : undefined;
    return (_jsxs("div", { children: [_jsx(SelectFactory, { ...props, options: codes, onChange: onChange, nullable: true, value: value, filterOptions: filterCountryCodes, inputField: ({ ref, children, ...inputProps }) => {
                    return (_jsxs("label", { ref: ref, className: "flex flex-col", children: [_jsx("span", { className: "font-brand-b1 mb-[4px] whitespace-nowrap", children: props.title }), _jsxs("div", { className: "flex items-center gap-x-1 relative h-5 px-1 rounded border border-solid border-greyscale-300", children: [countryCode && (_jsx(CountryFlag, { countryCode: countryCode, className: "w-3 h-2 rounded-none flex-none" })), _jsx("input", { ...inputProps, className: "font-brand-b1 min-w-0 h-full", placeholder: "Code", autoComplete: "off" }), _jsx("div", { className: "flex justify-center items-center text-greyscale-500 h-4 w-4 ml-auto", onMouseDown: (event) => event.preventDefault(), children: children })] })] }));
                }, itemRenderer: countryCodeRenderer }), errors && _jsx(Errors, { color: true, errors: errors })] }));
};
const countryCodeRenderer = ({ option, inputValue, ...props }) => (_jsx("li", { ...props, children: _jsxs("div", { className: "flex gap-1 items-center w-full", children: [_jsx(CountryFlag, { countryCode: option.country_code, className: "ml-1 w-3 h-2 rounded-none" }), option.name] }) }));
