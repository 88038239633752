import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, FilterSwitcher, FiltersButton, LayoutHeader, LayoutTitle, ListCount, Popover, } from '~/common/components';
import { Filter, Search, getFilterProps, getSearchFilterDataFromOptions, useParsedQuery, } from '~/common/kits/table';
const searchFilters = ['customer', 'company'];
const resetFilters = (filterMap) => {
    const newFilterMap = { ...filterMap };
    const filters = [
        'customer_type',
        'custom_portal',
        'customer_role',
        'show_deleted',
    ];
    for (const option of filters) {
        newFilterMap[option] = undefined;
    }
    return newFilterMap;
};
const placeholderMapper = (option) => {
    switch (option) {
        case 'customer':
            return 'Enter email';
        case 'company':
            return 'Enter company name';
    }
};
export const Header = ({ tableFilters, customersCount }) => {
    const { queryParams, onFilterChange, updateFilters } = useParsedQuery();
    const searchFilterData = getSearchFilterDataFromOptions(searchFilters, queryParams.filter);
    const filterProps = getFilterProps(tableFilters, queryParams.filter, onFilterChange);
    const appliedFiltersAmount = Object.keys(queryParams.filter).filter((key) => !searchFilters.includes(key)).length;
    return (_jsxs(LayoutHeader, { className: "flex justify-between items-center", children: [_jsxs("div", { className: "flex items-baseline", children: [_jsx(LayoutTitle, { children: "Customers" }), _jsx(ListCount, { name: "Total user", className: "font-brand-b1 text-greyscale-400 ml-2", amount: customersCount })] }), _jsx(Search, { className: "ml-auto mr-3", filters: searchFilters, selectedFilter: searchFilterData.selectedFilter, value: searchFilterData.value, onChange: onFilterChange, updateFilters: updateFilters, placeholderMapper: placeholderMapper }), _jsx(Popover, { compensateOffset: 8, className: "space-y-2 p-2 pt-[12px]", placement: "bottom-end", trigger: (props) => (_jsx(FiltersButton, { ...props, appliedFiltersAmount: appliedFiltersAmount })), content: () => (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex items-center justify-between mb-[-4px]", children: [_jsx("h3", { className: "font-brand-t2m text-greyscale-600", children: "Filters" }), appliedFiltersAmount > 0 && (_jsx(Button, { color: "text-secondary", size: "xs", onClick: () => updateFilters(resetFilters), children: "Reset" }))] }), _jsx(Filter, { ...filterProps('customer_type'), placeholder: "Customer type", className: "max-w-[240px]", theme: "light" }), _jsx(Filter, { ...filterProps('custom_portal'), placeholder: "Custom portal", className: "max-w-[240px]", theme: "light" }), _jsx(Filter, { ...filterProps('customer_role'), placeholder: "Customer role", className: "max-w-[240px]", theme: "light" }), _jsx("hr", { className: "h-[1px] w-full text-greyscale-200" }), _jsx(FilterSwitcher, { label: "Show deleted customers", className: "min-w-fit max-w-fit", value: queryParams.filter.show_deleted, onChange: (value) => onFilterChange('show_deleted', value) })] })) })] }));
};
