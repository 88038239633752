import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { faCalendarCircleUser, faKey, faLink } from '@fortawesome/pro-regular-svg-icons';
import { Button, Link, InfoBar, PageDetailsCard, useModalContext } from '~/common/components';
import { useCustomerData } from '~/customers/hooks';
import { useCurrentUserData } from '~/root';
import { EditCustomerLinks } from './EditCustomerLinks';
export const CustomerLinks = () => {
    const customer = useCustomerData();
    const { permissions } = useCurrentUserData();
    const { modalOpener } = useModalContext();
    return (_jsx(PageDetailsCard, { label: "Customer links", actionButton: permissions.customersUpdate && (_jsx(Button, { onClick: modalOpener(EditCustomerLinks), color: "grey", children: "Edit" })), children: _jsxs("ul", { className: "-mt-1", children: [_jsx(InfoBar, { icon: faCalendarCircleUser, text: customer.calendly_link && (_jsx(Link, { newTab: true, external: true, size: "m", className: "self-center", href: customer.calendly_link, children: customer.calendly_link })), hint: "Customer support Calendly link" }), _jsx(InfoBar, { icon: faKey, text: customer.sign_on_link && (_jsx(Link, { className: "self-center", size: "m", href: customer.sign_on_link, newTab: true, external: true, children: customer.sign_on_link })), hint: _jsxs(_Fragment, { children: [_jsx("span", { children: "Single Sign On Login URL. " }), _jsx(Link, { external: true, newTab: true, href: "https://wiki.24slides.dev/doc/single-sign-on-sso-HnMztDAPTv", size: "xs", children: "Learn more" })] }), hintClickable: true }), _jsx(InfoBar, { icon: faLink, text: customer.links && (_jsx("div", { className: "flex flex-col items-baseline gap-[2px] min-w-0", children: customer.links.map((props) => (_jsx(Link, { newTab: true, external: true, size: "m", href: props.url, children: props.label }, props.url))) })), hint: "Custom links" })] }) }));
};
