import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import { forwardRef, useState } from 'react';
import { Button, Popover, IconBox } from '~/common/components';
import { cx } from '~/common/utils';
import styles from './Menu.module.scss';
const MenuItem = forwardRef(({ className, children, selected = false, loading = false, ...props }, ref) => (_jsxs(Button.Base, { ...props, disabled: loading || props.disabled, ref: ref, className: cx(styles.item, selected && styles.selected, props.disabled && styles.disabled, className), children: [_jsx("span", { children: children }), loading && (_jsx(IconBox, { size: "s", icon: faSpinnerThird, className: "text-secondary-300 animate-spin" }))] })));
// TODO should we refactor this or overridable default trigger button is fine?
export const Menu = ({ children, className, trigger = (props) => (_jsx(Button.Icon, { ...props, size: "m", iconSize: "s", color: "grey-outline", "aria-label": "Toggle menu", icon: faEllipsisV })), }) => {
    const state = useState(false);
    const [, setOpened] = state;
    return (_jsx(Popover, { externalState: state, onClick: children instanceof Function ? undefined : () => setOpened(false), className: cx(styles.container, className), content: (props) => (children instanceof Function ? children(props.onClose) : children), placement: "bottom-end", trigger: trigger }));
};
Menu.Item = MenuItem;
