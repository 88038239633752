import { jsx as _jsx } from "react/jsx-runtime";
import { useHistory } from 'react-router-dom';
import { DeleteShell } from '~/common/components';
import { CUSTOMERS_URL, useCustomerData, useCustomerDeletion } from '../hooks';
export const DeleteCustomer = ({ onClose }) => {
    const history = useHistory();
    const { subscription } = useCustomerData();
    const { mutateAsync, isLoading } = useCustomerDeletion();
    const handleDelete = () => mutateAsync(subscription === null || subscription === void 0 ? void 0 : subscription.id).then(() => history.push(CUSTOMERS_URL));
    return (_jsx(DeleteShell, { className: "w-[525px]", loading: isLoading, heading: "Delete the customer?", description: "You will still see all the actions this customer has done", submitText: "Yes, delete customer", cancelText: "No, keep customer", onSubmit: handleDelete, onClose: onClose }));
};
