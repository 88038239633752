import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Banner, Button, Link } from '~/common/components';
import { useInitData, useInvoiceMethodMutation } from '~/customers/hooks';
import { BillingForm, emptyInitialValues, useBillingForm } from '../BillingForm';
export const AddInvoicePaymentMethod = ({ onClose }) => {
    const { mutateAsync } = useInvoiceMethodMutation();
    const init = useInitData();
    const { fillWithBillingInfo, ...form } = useBillingForm({
        ...emptyInitialValues,
        currency: init.currencies[0].value,
    });
    return (_jsx(BillingForm, { title: "Add invoice payment method", onClose: onClose, onSubmit: mutateAsync, header: _jsxs("div", { className: "flex justify-between items-center bg-greyscale-100 rounded-t pl-1 h-4 mb-2", children: [_jsx("span", { className: "text-greyscale-500 font-brand-t4m", children: "Billing information" }), _jsx(Button, { color: "text-secondary", onClick: fillWithBillingInfo, children: "Copy from billing address" })] }), ...form, children: _jsxs(Banner, { color: "info", className: "mt-3", children: ["By saving, you will automatically", ' ', _jsx(Link, { external: true, newTab: true, href: "https://wiki.24slides.dev/doc/invoice-payment-method-xdmqQYXMwU", children: "enable invoice payment" }), ' ', "method for the customer"] }) }));
};
