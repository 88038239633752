import { jsx as _jsx } from "react/jsx-runtime";
import { AvatarList } from './AvatarList';
const uiKitColors = {
    primary: {
        50: '#e8f5f1',
        100: '#cbeadd',
        300: '#46b688',
        400: '#2c9475',
    },
    secondary: {
        50: '#f3f7ff',
        100: '#e5eefd',
        300: '#7394e8',
        400: '#537adb',
    },
    greyscale: {
        0: '#ffffff',
        50: '#f8fafc',
        100: '#f0f4f9',
        200: '#e3e9f2',
        300: '#cdd7e5',
        400: '#9196af',
        500: '#6d7395',
        525: '#444b5e',
        550: '#393f4f',
        600: '#2a3142',
    },
    success: {
        50: '#e9fdfd',
        100: '#abeae2',
        300: '#1ec1a4',
        400: '#24a78f',
    },
    warning: {
        50: '#fff9eb',
        100: '#ffedc2',
        300: '#ffd37c',
        400: '#ffba0a',
        500: '#ee9c16',
    },
    danger: {
        50: '#fff4f5',
        100: '#ffe1e1',
        400: '#f86666',
        500: '#e14949',
    },
    info: {
        25: '#f8f6ff',
        50: '#ede7f6',
        100: '#d1c4e9',
        400: '#7e57c2',
    },
    accent: {
        50: '#fff7eb',
        100: '#ffe8c6',
        500: '#ff7b4b',
    },
};
const uiKitSet = [
    {
        backgroundColor: uiKitColors.warning[50],
        borderColor: uiKitColors.warning[100],
        color: uiKitColors.warning[400],
    },
    {
        backgroundColor: uiKitColors.primary[50],
        borderColor: uiKitColors.primary[100],
        color: uiKitColors.primary[300],
    },
    {
        backgroundColor: uiKitColors.danger[50],
        borderColor: uiKitColors.danger[100],
        color: uiKitColors.danger[400],
    },
    {
        backgroundColor: uiKitColors.secondary[50],
        borderColor: uiKitColors.secondary[100],
        color: uiKitColors.secondary[300],
    },
    {
        backgroundColor: uiKitColors.info[50],
        borderColor: uiKitColors.info[100],
        color: uiKitColors.info[400],
    },
    {
        backgroundColor: uiKitColors.accent[50],
        borderColor: uiKitColors.accent[100],
        color: uiKitColors.accent[500],
    },
    {
        backgroundColor: uiKitColors.success[50],
        borderColor: uiKitColors.success[100],
        color: uiKitColors.success[300],
    },
];
export const UIKitAvatars = ({ isColorSelected, toggleColor }) => (_jsx(AvatarList, { title: "UI Kit Avatars", colorSets: uiKitSet, isColorSelected: isColorSelected, toggleColor: toggleColor }));
