import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { addDays, addMonths, format, isSameDay, max, min, startOfYear } from 'date-fns';
import Dayzed from 'dayzed';
import { useState, } from 'react';
import { cx, range } from '~/common/utils';
import { Button } from '../Interactives';
import { Tooltip } from '../Tooltip';
import css from './Datepicker.module.scss';
const getMonthNames = () => {
    const start = startOfYear(new Date());
    return range(0, 11).map((index) => format(addMonths(start, index), 'MMMM'));
};
const monthNames = getMonthNames();
const weekdayNames = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
const UnselectableTooltip = ({ selectable, children, }) => {
    if (selectable) {
        return children;
    }
    return (_jsx(Tooltip, { compensateOffset: 5, content: "No data for this date", children: children }));
};
const Day = ({ date, selected, selectable, prevMonth, nextMonth, today, ...props }) => (_jsx(UnselectableTooltip, { selectable: selectable, children: _jsx("button", { ...props, className: cx(css.item, 'rounded-full', {
            'text-greyscale-200': !selectable,
            'text-greyscale-500': selectable && (prevMonth || nextMonth),
            'bg-greyscale-100': selected,
            [css.hoverable]: selectable,
        }), children: date.getDate() }) }));
const defaultDayMapper = (key, dateObj, getDateProps) => (_jsx(Day, { ...getDateProps({ dateObj }), ...dateObj }, key));
const Calendar = ({ calendars, getBackProps, getForwardProps, getDateProps, dayMapper = defaultDayMapper, ...props }) => {
    if (!calendars.length) {
        return null;
    }
    return (_jsx("div", { ...props, className: "font-brand-b1 text-greyscale-600", children: calendars.map((calendar) => (_jsxs("div", { children: [_jsxs("div", { className: "flex items-center justify-between gap-2 py-[4px] px-1", children: [_jsx(Button.Icon, { size: "m", ...getBackProps({ calendars }), icon: faChevronLeft }), _jsxs("div", { className: "uppercase font-brand-t3", children: [monthNames[calendar.month], " ", calendar.year] }), _jsx(Button.Icon, { size: "m", ...getForwardProps({ calendars }), icon: faChevronRight })] }), _jsxs("div", { className: "grid grid-cols-7", children: [weekdayNames.map((weekday) => (_jsx("div", { className: cx(css.item, 'cursor-default'), children: weekday }, `${calendar.month}${calendar.year}${weekday}`))), calendar.weeks.map((week, weekIndex) => week.map((dateObj, index) => {
                            const key = `${calendar.month}${calendar.year}${weekIndex}${index}`;
                            return dateObj ? (dayMapper(key, dateObj, getDateProps)) : (_jsx("div", { className: css.item }, key));
                        }))] })] }, `${calendar.month}${calendar.year}`))) }));
};
export const Datepicker = ({ minDate, maxDate, value, onChange }) => {
    const handleDateSelection = ({ date }) => {
        onChange(date);
    };
    return (_jsx(Dayzed, { minDate: minDate ? addDays(minDate, -1) : undefined, maxDate: maxDate, date: value, selected: value, onDateSelected: handleDateSelection, showOutsideDays: true, firstDayOfWeek: 1, render: (dayzedData) => _jsx(Calendar, { ...dayzedData }) }));
};
const RangeDay = ({ date, selected, selectable, prevMonth, nextMonth, isInRange, isHovered, roundSelected, forceRoundSelected, roundedSide, ...props }) => (_jsx(UnselectableTooltip, { selectable: selectable, children: _jsx("button", { ...props, style: { '--content': date.getDate() }, className: cx(css.item, css.rangeDay, {
            'text-greyscale-200': !selectable,
            'text-greyscale-500': selectable && (prevMonth || nextMonth),
            'rounded-l-full': roundedSide === 'left',
            'rounded-r-full': roundedSide === 'right',
            'rounded-full': (isHovered && !roundedSide && !isInRange) ||
                (isHovered && selected && roundSelected) ||
                forceRoundSelected,
            [css.fill]: selected || isInRange || isHovered,
            [css.rangeDaySelected]: selected,
        }) }) }));
export const DateRangePicker = ({ minDate, maxDate, value, onChange }) => {
    const [hoveredDate, setHoveredDate] = useState(null);
    const [dates, setDates] = useState(value);
    const [offset, setOffset] = useState(0);
    const getRangeDayProps = (date) => {
        const time = date.getTime();
        const startTime = dates.start.getTime();
        const result = {
            isInRange: false,
            roundedSide: null,
        };
        if (hoveredDate) {
            const hoveredTime = hoveredDate.getTime();
            if (isSameDay(hoveredDate, date) && !dates.end) {
                result.roundedSide = hoveredTime > startTime ? 'right' : 'left';
            }
            if (isSameDay(dates.start, date) && !dates.end) {
                result.roundedSide = startTime > hoveredTime ? 'right' : 'left';
            }
            result.isInRange =
                (hoveredTime < time && time < startTime) || (startTime < time && time < hoveredTime);
        }
        if (dates.end) {
            const endTime = dates.end.getTime();
            if (isSameDay(dates.start, date)) {
                result.roundedSide = 'left';
            }
            if (isSameDay(dates.end, date)) {
                result.roundedSide = 'right';
            }
            result.isInRange = startTime < time && time < endTime;
        }
        return result;
    };
    const handleDateSelection = ({ date }) => {
        if (!dates.end) {
            const newRange = { start: min([date, dates.start]), end: max([date, dates.start]) };
            setDates(newRange);
            onChange(newRange);
            if (date.getTime() < dates.start.getTime()) {
                setOffset(0);
            }
        }
        else {
            setDates({ start: date, end: null });
            setOffset(0);
        }
    };
    const handleMouseLeave = () => setHoveredDate(null);
    const dayMapper = (key, dateObj, getDateProps) => (_jsx(RangeDay, { ...dateObj, ...getDateProps({ dateObj }), ...getRangeDayProps(dateObj.date), isHovered: hoveredDate ? isSameDay(hoveredDate, dateObj.date) : false, roundSelected: dates.end === null, forceRoundSelected: dates.end === null && !hoveredDate, onMouseEnter: () => setHoveredDate(dateObj.date) }, key));
    return (_jsx(Dayzed, { minDate: minDate ? addDays(minDate, -1) : undefined, maxDate: maxDate, date: dates.start, selected: dates.end ? [dates.start, dates.end] : [dates.start], onDateSelected: handleDateSelection, showOutsideDays: true, firstDayOfWeek: 1, offset: offset, onOffsetChanged: setOffset, render: (dayzedData) => (_jsx(Calendar, { ...dayzedData, dayMapper: dayMapper, onMouseLeave: handleMouseLeave })) }));
};
