import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Button } from '~/common/components';
import { getFilterProps, useParsedQuery } from '~/common/kits/table';
import { FilterBarRow } from '../components';
import { useInitData } from '../hooks';
import { useOmittedFilters } from './hooks';
export const FilterBar = () => {
    const omittedFilters = useOmittedFilters();
    const { queryParams, onFilterChange, updateFilters } = useParsedQuery();
    const { filter_options } = useInitData();
    const filterProps = getFilterProps(filter_options, queryParams.filter, onFilterChange);
    const filters = useMemo(() => {
        return [
            {
                title: 'Status',
                name: 'status',
            },
            {
                title: 'Type',
                name: 'customer_type',
            },
            {
                title: 'PM',
                name: 'manager',
            },
            {
                title: 'Design team',
                name: 'team',
            },
            {
                title: 'Payment status',
                name: 'payment_status',
            },
            {
                title: 'Portal',
                name: 'custom_portal',
            },
            {
                title: 'Organization',
                name: 'organisation',
            },
            {
                title: 'Completed',
                name: 'completed',
            },
        ]
            .filter((item) => !omittedFilters.includes(item.name))
            .map((item) => ({ ...item, ...filterProps(item.name) }));
    }, [filterProps, omittedFilters]);
    const appliedFilters = filters.filter((filter) => queryParams.filter[filter.name]);
    const resetFilters = () => {
        updateFilters(() => ({}));
    };
    if (!appliedFilters.length) {
        return null;
    }
    return (_jsx("div", { className: "flex flex-col gap-1", children: appliedFilters.map((props, index) => {
            var _a;
            return (_jsx(FilterBarRow, { ...props, children: index === appliedFilters.length - 1 && (_jsx(Button, { size: "xs", color: "text-secondary", className: "ml-2", onClick: resetFilters, children: "Clear All" })) }, (_a = props.value) === null || _a === void 0 ? void 0 : _a.toString()));
        }) }));
};
