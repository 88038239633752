import { Upload } from 'tus-js-client';
import { CLIENT_VERSION, SERVER_URL } from '~/env';
import { qk } from '~/root/query-keys';
import { queryClient } from '~/root/queryClient';
import { createAbortError } from './async';
import { megaBytesToBytes } from './data';
export const tusUpload = ({ file, tag, endpoint = '/uploads/', signal, chunkSize = megaBytesToBytes(50), metadata, ...options }) => {
    return new Promise((resolve, reject) => {
        if (signal === null || signal === void 0 ? void 0 : signal.aborted) {
            reject(createAbortError());
        }
        // TODO I was just lazy to inject this token each time we use tusUpload
        // Drawback is that this util is now dependent on root, while also
        // importing queryClient directly instead getting it from the context
        //
        // At least we should make tusUpload instantiable to put some getHeaders
        // function when creating an app-wide instance
        //
        // Should work fine for now though
        const user = queryClient.getQueryData(qk.init);
        const auth = (user === null || user === void 0 ? void 0 : user.token) ? { Authorization: `Bearer ${user.token}` } : undefined;
        const upload = new Upload(file, {
            ...options,
            headers: {
                ...auth,
                'X-Client-Version': CLIENT_VERSION,
            },
            endpoint: SERVER_URL + endpoint,
            chunkSize,
            metadata: {
                ...metadata,
                filename: file.name,
                filetype: file.type,
                tag,
            },
            removeFingerprintOnSuccess: true,
            onSuccess: () => {
                resolve({
                    date: new Date(),
                    id: upload.url.split('+')[0].split('/').pop(),
                    link: upload.url,
                    name: file.name,
                });
            },
            onError: reject,
        });
        upload.findPreviousUploads().then((previousUploads) => {
            // Found previous uploads so we select the first one.
            if (previousUploads.length) {
                upload.resumeFromPreviousUpload(previousUploads[0]);
            }
            // Start the upload
            upload.start();
        });
        signal === null || signal === void 0 ? void 0 : signal.addEventListener('abort', () => {
            upload
                // TODO should we remove not fully uploaded file here by passing true to abort fn?
                .abort()
                .then(() => reject(createAbortError()))
                .catch(reject);
        }, { once: true });
    });
};
