import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip } from '~/common/components';
import { cx, tw } from '~/common/utils';
const placements = [
    'top',
    'right',
    'bottom',
    'left',
    'top-start',
    'top-end',
    'right-start',
    'right-end',
    'bottom-start',
    'bottom-end',
    'left-start',
    'left-end',
];
const buttonClassName = tw `
  flex items-center justify-center
  w-25 m-1 px-2 h-4
  bg-greyscale-100 border border-solid border-greyscale-300
  hover:bg-greyscale-200 hover:border-greyscale-400
  rounded
  cursor-pointer
`;
export const Tooltips = () => {
    return (_jsxs("div", { className: "bg-greyscale-0 p-2 rounded", children: [_jsx("div", { className: "font-brand-h1 w-full mb-3 truncate", children: "Tooltips" }), _jsxs("div", { className: "grid grid-cols-4 gap-x-20 gap-y-10 px-10 mt-8", children: [_jsx(Tooltip, { color: "grey", className: "py-2 px-3 w-22", content: _jsxs(_Fragment, { children: [_jsx("div", { className: "font-brand-t3 pb-1 border-b border-solid border-b-greyscale-400", children: "Heading" }), _jsx("div", { className: "font-brand-b1 mt-1", children: "Lorem ipsum dancing monkeys on the floor" })] }), placement: "right-start", children: _jsx("button", { className: cx(buttonClassName, 'col-start-2'), children: "Rich content tooltip" }) }), _jsx(Tooltip, { color: "danger", className: "py-2 px-3 w-22 bg-danger-500", content: _jsxs(_Fragment, { children: [_jsx("div", { className: "font-brand-t3 pb-1 border-b border-solid border-b-greyscale-0", children: "OH NO!" }), _jsx("div", { className: "font-brand-b1 mt-1", children: "OR YES?!" })] }), placement: "left-start", children: _jsx("button", { className: buttonClassName, children: "Rich error" }) }), placements.map((placement, index) => (_jsx(Tooltip, { content: "Hi! I'm tooltip :)", placement: placement, children: _jsxs("button", { className: cx(buttonClassName, index === 0 && 'col-start-1'), children: ["Placed ", placement] }) }, placement)))] })] }));
};
