import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Button, LayoutHeader, LayoutTitle, ListCount, SearchField, useModalContext, } from '~/common/components';
import { Multiselect, getFilterProps, stringify, useParsedQuery } from '~/common/kits/table';
import { useCurrentUserData } from '~/root';
import { AddOrganizationPopup } from './AddOrganizationPopup';
export const Header = ({ tableFilters, organizationsCount }) => {
    const { permissions } = useCurrentUserData();
    const { queryParams, onFilterChange } = useParsedQuery();
    const { modalOpener } = useModalContext();
    const filterProps = getFilterProps(tableFilters, queryParams.filter, onFilterChange);
    return (_jsxs(LayoutHeader, { className: "flex justify-between items-center", children: [_jsxs("div", { className: "flex items-baseline", children: [_jsx(LayoutTitle, { children: "Organizations" }), _jsx(ListCount, { name: "organization", className: "font-brand-b1 text-greyscale-400 ml-2", amount: organizationsCount })] }), _jsxs("div", { className: "flex gap-x-2", children: [_jsx(SearchField, { className: "w-[272px]", placeholder: "Search", value: stringify(queryParams.filter.search), onChange: (value) => onFilterChange('search', value) }), _jsx("div", { className: "w-[170px]", children: _jsx(Multiselect, { placeholder: "Organization tag", ...filterProps('tag') }) }), permissions.organizationsCreate && (_jsx(Button, { icon: faPlus, onClick: modalOpener(AddOrganizationPopup), children: "Add" }))] })] }));
};
