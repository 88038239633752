import { addMinutes, isWithinInterval } from 'date-fns';
import { apply, array, date, map, nonEmptyArray, option, ord, separated, string } from 'fp-ts';
import { flow, pipe } from 'fp-ts/function';
import { eqPositiveInteger, groupBy, ordPositiveInteger } from '~/common/utils';
import { ORDERS_URL } from '~/orders';
import { SUBSCRIPTIONS_URL } from '~/subscriptions/hooks';
import { USERS_URL } from '~/users';
import { ordNotification } from './domain';
const INTERVAL_LENGTH = 30;
function makeInterval(start) {
    return { start, end: addMinutes(start, INTERVAL_LENGTH) };
}
const makeRegularNotification = (notification) => ({
    type: 'regular',
    value: notification,
});
const makeBulkNotification = (notifications) => {
    return pipe(notifications, nonEmptyArray.fromArray, option.filter((notifications) => notifications.length > 1), option.map((value) => ({ type: 'bulk', value })));
};
const fromParsedNotification = (notification) => {
    switch (notification.type) {
        case 'regular':
            return notification.value;
        case 'bulk':
            return nonEmptyArray.last(notification.value);
    }
};
export function fromParsedNotifications(notifications) {
    return notifications.flatMap((notification) => {
        switch (notification.type) {
            case 'regular':
                return array.of(notification.value);
            case 'bulk':
                return notification.value;
        }
    });
}
export const isRead = (notification) => {
    switch (notification.type) {
        case 'regular':
            return notification.value.is_read;
        case 'bulk':
            return notification.value.every(({ is_read }) => is_read);
    }
};
export const getContent = (notification) => {
    switch (notification.type) {
        case 'regular':
            return notification.value.content;
        case 'bulk': {
            const last = nonEmptyArray.last(notification.value);
            if (last.event.model === 'Order' && last.event.groupable) {
                const userNames = pipe(notification.value, array.filterMap((n) => {
                    var _a;
                    return option.fromNullable(n.event.model === 'Order' && n.event.groupable ? (_a = n.event.bindings) === null || _a === void 0 ? void 0 : _a.name : null);
                }), array.uniq(string.Eq), array.sort(string.Ord), nonEmptyArray.fromArray, option.getOrElse(() => nonEmptyArray.of('Unknown')));
                return `${last.event.name === 'order.annotation.created' ? '💬' : '📩'} ${userNames.join(', ')} ${userNames.length > 1 ? 'have' : 'has'} left ${notification.value.length} ${last.event.name === 'order.annotation.created' ? 'annotations' : 'messages'}`;
            }
            return null;
        }
    }
};
export const getDate = (notification) => {
    return fromParsedNotification(notification).created;
};
export const getId = (notification) => {
    return fromParsedNotification(notification).id;
};
const ordParsedNotification = pipe(date.Ord, ord.contramap(getDate), ord.reverse);
function toParsedNotifications(data) {
    if (data.notifications.length === 1) {
        return [makeRegularNotification(nonEmptyArray.head(data.notifications))];
    }
    const { left: unread, right: read } = pipe(data.notifications, array.partition(({ is_read }) => is_read), separated.map((read) => pipe(makeBulkNotification(read), option.alt(() => pipe(read, array.head, option.map(makeRegularNotification))), option.map(array.of))), separated.mapLeft((unread) => pipe(makeBulkNotification(unread), option.alt(() => pipe(unread, array.head, option.map(makeRegularNotification))), option.map(array.of))));
    return pipe(apply.sequenceS(option.Applicative)({
        read,
        unread,
    }), option.map(({ read, unread }) => pipe([...read, ...unread], array.sort(ordParsedNotification))), option.alt(() => read), option.alt(() => unread), option.getOrElse(() => []));
}
function groupByInterval(notifications) {
    return pipe(notifications, array.sort(ord.reverse(ordNotification)), array.reduce([], (result, notification) => pipe(result, array.findIndex(({ interval }) => isWithinInterval(notification.created, interval)), option.chain((index) => pipe(result, array.modifyAt(index, (item) => ({
        ...item,
        notifications: pipe(item.notifications, array.append(notification)),
    })))), option.getOrElse(() => [
        ...result,
        {
            interval: makeInterval(notification.created),
            notifications: nonEmptyArray.of(notification),
        },
    ]))), array.chain(toParsedNotifications));
}
function parseOrderNotifications(notifications) {
    const { left, right } = pipe(notifications, array.partition((notification) => notification.event.groupable), separated.map(flow(groupBy(string.Eq, (notification) => notification.event.name), map.map(groupByInterval), map.toArray(string.Ord), array.chain(([_, notifications]) => notifications))), separated.mapLeft(array.map(makeRegularNotification)));
    return pipe([...left, ...right], array.sort(ordParsedNotification));
}
const ordNotificationGroup = pipe(array.getOrd(ordParsedNotification), ord.contramap(([, notifications]) => notifications));
export function group(notifications) {
    return pipe(notifications, array.partition((notification) => notification.event.model === 'Order'), separated.map(flow(groupBy(eqPositiveInteger, (notification) => notification.event.id), map.map(parseOrderNotifications), map.toArray(ordPositiveInteger), array.sort(ordNotificationGroup), nonEmptyArray.fromArray, option.toNullable)), separated.mapLeft(flow(array.map(makeRegularNotification), nonEmptyArray.fromArray, option.toNullable)));
}
export function setReadState(isRead) {
    return (notification) => ({ ...notification, is_read: isRead });
}
export function getNotificationLink(event) {
    const { id, model: type, name } = event;
    if (type === 'Order') {
        if (name === 'order.collaboration.mentioned') {
            return `${ORDERS_URL}/${id}#team-space`;
        }
        return `${ORDERS_URL}/${id}`;
    }
    if (type === 'User') {
        return `${USERS_URL}/${id}`;
    }
    if (type === 'Subscription') {
        return `${SUBSCRIPTIONS_URL}/${id}`;
    }
}
