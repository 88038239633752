import { z } from 'zod';
import { DateFromIsoString } from '~/common/utils';
export const DeviceActivity = z.object({
    location: z.string(),
    ip: z.string(),
    timestamp: DateFromIsoString,
});
export const ActivityEntry = z.object({
    os: z.string().nullable(),
    browser: z.string().nullable(),
    device: z.string().nullable(),
    activity: z.array(DeviceActivity),
});
export const LoginActivity = z
    .object({
    data: z.array(ActivityEntry),
})
    .transform(({ data }) => data);
