import { jsx as _jsx } from "react/jsx-runtime";
import { cx } from '~/common/utils';
import { Button } from '../Interactives';
import styles from './Switcher.module.scss';
export const Switcher = ({ className, disabled = false, onBlur, onChange, value, theme = 'light', }) => {
    const handleClick = () => {
        onChange(!value);
        onBlur();
    };
    return (_jsx(Button.Base, { className: cx(styles.switcher, 'bg-greyscale-300', { 'bg-primary-300': value, 'bg-greyscale-400': theme === 'dark' }, className), disabled: disabled, onClick: handleClick, children: _jsx("span", { className: cx('absolute top-[2px] bg-greyscale-0 rounded-full h-[20px] w-[20px]', value ? 'right-[2px]' : 'left-[2px]') }) }));
};
