import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faUserPlus, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { IconBox, Show, StickyLeftTableCell } from '~/common/components';
import { cx } from '~/common/utils';
import { TableCell } from '~/rms/common';
export const Row = ({ name, pointsData, onClick, isTeam = false, isSingleTeam = false, isTagTeam = false, opened, }) => (_jsxs("tr", { className: cx(!isSingleTeam && !isTeam && 'bg-greyscale-50'), children: [_jsx(StickyLeftTableCell, { className: cx(!isSingleTeam && !isTeam && 'bg-greyscale-50'), width: 160, onClick: onClick, children: _jsxs("div", { className: cx('flex items-center justify-between h-full pl-1 pointer-events-auto', {
                    'cursor-pointer': isTeam,
                }), children: [_jsxs(Show, { if: isTeam, children: [_jsx("div", { className: "ml-[4px] font-brand-t3 uppercase truncate", children: name }), isTagTeam && (_jsx(IconBox, { size: "s", className: "text-greyscale-500 ml-2 mr-[12px]", icon: faUserPlus })), _jsx(IconBox, { size: "s", icon: faChevronUp, className: cx('h-5 w-5 ml-auto text-secondary-300 transition-brand', {
                                    '-rotate-180': opened,
                                }) })] }), _jsx(Show, { if: !isTeam, children: _jsx("div", { className: cx('font-brand-t4m truncate', !isSingleTeam && 'text-greyscale-500'), children: name }) })] }) }), pointsData.map((points, index) => (_jsx(TableCell, { children: _jsx("div", { className: cx('flex items-center justify-center h-full', (isTeam || isSingleTeam) && 'font-brand-t3'), children: points }) }, index)))] }));
