import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Button, ButtonGroup, FileUploadField, ModalBody, ModalCore, ModalHeader, Toast, } from '~/common/components';
import { Crop } from '~/common/kits/crop';
import { getDimensionsFromFile, getImageElementFromSrc, isFileBroken } from '~/common/utils';
import { useAvatarUploadMutation, useProfileMutation } from '~/settings/profile';
import { useCurrentUserData } from './Auth';
const REMIND_ME_KEY = '_remind_me_after';
const remindMeIn = (minutes) => {
    localStorage.setItem(REMIND_ME_KEY, String(Date.now() + minutes * 60000));
};
export const PHOTO_NAGGING_ELIGIBLE_ROLES = [
    'customer_supporter',
    'design_manager',
    'designer',
    'regular_pm',
    'sales_manager',
    'subscription_pm',
];
export const UploadPhoto = () => {
    var _a, _b;
    const user = useCurrentUserData();
    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);
    const [shouldReupload, setShouldReupload] = useState(false);
    const [naggingPostponed, setNaggingPostponed] = useState(true);
    const profile = useProfileMutation();
    const avatar = useAvatarUploadMutation();
    const isLoading = profile.isLoading || avatar.isLoading;
    useEffect(() => {
        var _a;
        if ((_a = user.avatar) === null || _a === void 0 ? void 0 : _a.link) {
            getImageElementFromSrc(user.avatar.link)
                .then((img) => setShouldReupload(img.naturalWidth < 150))
                .catch(() => setShouldReupload(true));
        }
    }, [(_a = user.avatar) === null || _a === void 0 ? void 0 : _a.link]);
    useEffect(() => {
        var _a;
        if (!((_a = user.avatar) === null || _a === void 0 ? void 0 : _a.link) || shouldReupload) {
            const interval = setInterval(() => {
                setNaggingPostponed(Number(localStorage.getItem(REMIND_ME_KEY)) > Date.now());
            }, 5000);
            return () => clearInterval(interval);
        }
    }, [shouldReupload, (_b = user.avatar) === null || _b === void 0 ? void 0 : _b.link]);
    const onFileSelect = async (file) => {
        const isSelectedFileBroken = await isFileBroken(file);
        const isImage = ['jpg', 'jpeg', 'png'].some((type) => file.type.includes(type));
        const size = isImage ? await getDimensionsFromFile(file) : null;
        const isValidSize = size ? size.width >= 360 && size.height >= 360 : false;
        if (!isSelectedFileBroken && isImage && isValidSize) {
            setError(null);
            setFile(file);
            return;
        }
        const error = 'Selected file does not meet an avatar requirements';
        setError(error);
        Toast.error({ title: 'Error', subTitle: error });
    };
    const upload = async (file) => {
        const handlers = {
            onError: () => {
                Toast.error({ title: 'Error', subTitle: 'Upload unsuccessful. Try pressing save again.' });
            },
        };
        const uploadedFile = await avatar.mutateAsync(file, handlers);
        await profile.mutateAsync({ avatar_file_id: uploadedFile.id }, handlers);
        setFile(null);
        setShouldReupload(false);
    };
    if (file) {
        return (_jsxs(ModalCore, { show: true, close: () => null, className: "w-[540px]", children: [_jsx(ModalHeader, { title: "Upload your profile picture" }), _jsx(Crop, { file: file, onResize: upload, contentRenderer: (props) => (_jsxs(_Fragment, { children: [_jsx(ModalBody, { children: props.children }), _jsxs(ButtonGroup, { children: [_jsx(Button, { color: "text-secondary", size: "xs", className: "mr-auto", onClick: () => setFile(null), disabled: isLoading, children: "Upload new picture" }), _jsx(Button, { color: "primary", size: "medium", onClick: () => {
                                            props.handleSave().catch(() => setError('Something went wrong when resizing'));
                                        }, loading: isLoading, children: "Save" })] })] })) })] }, "static"));
    }
    const handleRemind = () => {
        remindMeIn(15);
        setNaggingPostponed(true);
    };
    return (_jsxs(ModalCore, { show: !naggingPostponed && (!user.avatar || shouldReupload), close: () => null, className: "w-[540px]", children: [_jsx(ModalHeader, { title: "Upload your profile picture" }), _jsxs("div", { className: "pt-10 px-3 pb-9", children: [_jsx("h3", { className: "font-brand-t1m mb-2 text-center", children: "Hey there!" }), shouldReupload ? (_jsxs("p", { className: "font-brand-b1 text-greyscale-500 text-center mb-2", children: ["It looks like your photo didn\u2019t resize well. Sorry for the inconvenience, please re-upload, and we\u2019ll do our best to make it great this time.", _jsx("br", {}), "This will help us ensure they look great in emails and other places where they're displayed."] })) : (_jsxs("p", { className: "font-brand-b1 text-greyscale-500 text-center mb-2", children: ["Please upload your profile picture.", _jsx("br", {}), "We will use it to attach to the emails we send to customers to introduce their team."] })), _jsx(FileUploadField, { onChange: (file) => file && onFileSelect(file), errors: error ? [error] : null, hintLeft: "Supports: PNG, JPG", hintRight: "Minimum size: 360x360", accept: ".png, .jpg, .jpeg" })] }), _jsxs(ButtonGroup, { children: [_jsx(Button, { color: "text-secondary", size: "xs", className: "mr-auto", onClick: handleRemind, disabled: isLoading, children: "Remind me in 15 minutes" }), _jsx(Button, { color: "primary", size: "medium", disabled: true, "data-tt": "Add a file or postpone", children: "Save" })] })] }, "static"));
};
