import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SearchField, Tabs } from '~/common/components';
import { Filter } from '~/common/kits/table';
import { useCustomersTableContext, withCustomersTableContext } from './context';
import { Table } from './Table';
export const CustomersTable = withCustomersTableContext(() => {
    const { tab, tabs, setTab, search, setSearch, filter, setFilter } = useCustomersTableContext();
    return (_jsxs("div", { className: "grid h-full col-start-2 grid-rows-[repeat(3,auto)_1fr] bg-greyscale-0 mt-2 px-3 pb-2 overflow-hidden", children: [_jsxs("header", { className: "py-1 flex items-center", children: [_jsx("span", { className: "font-brand-t3 text-greyscale-500 uppercase mr-2", children: "Customers" }), _jsx(SearchField, { className: "ml-auto w-[272px]", value: search, onChange: setSearch, onChangeStrategy: "onChange" }), _jsx(Filter, { noSearch: true, value: filter, 
                        //TODO fix type
                        onChange: setFilter, options: [
                            {
                                name: 'Regular',
                                value: 'regular', //regular consists of New and Returning
                            },
                            {
                                name: 'Subscription',
                                value: 'subscription',
                            },
                        ], placeholder: "Customer type", className: "max-w-[240px] ml-2" })] }), _jsx(Tabs, { className: "flex items-center gap-5 px-3 -mx-3 bg-greyscale-0 def:font-brand-t1m leading-6 border-b-2 border-b-solid border-b-greyscale-200", tabs: tabs, active: tab, onChange: setTab }), _jsx(Table, {})] }));
});
