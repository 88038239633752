import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { IconBox } from '~/common/components';
import { useOrderData, useSortedOrderCountries } from '~/orders/hooks';
import { CountriesTime, Section } from '~/orders/Order';
import { Delivery, General, PmAndDesignTeam, Price } from './SubSections';
export const InformationSection = () => {
    const order = useOrderData();
    const countries = useSortedOrderCountries();
    return (_jsxs(Section, { type: "info", children: [order.title && (_jsxs("div", { className: "flex gap-[4px] mb-1 items-center", children: [_jsx("h3", { className: "inline font-brand-t1m truncate", children: order.title }), _jsx(IconBox, { "data-tt": "Customer's order name", "data-tt-placement": "right", "data-tt-class": "max-w-[180px]", size: "m", className: "cursor-help text-greyscale-300", icon: faInfoCircle })] })), _jsxs("div", { className: "flex", children: [_jsx("span", { className: "mr-2 font-brand-b1 text-greyscale-400", children: "Creation time:" }), _jsx(CountriesTime, { date: order.created, countries: countries })] }), _jsxs("div", { className: "mt-3 space-y-3", children: [_jsx(General, {}), _jsx(Delivery, {}), _jsx(Price, {}), _jsx(PmAndDesignTeam, {})] })] }));
};
