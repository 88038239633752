import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { nonEmptyArray, option } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { formatDateDistance, getOptionLabel } from '~/common/utils';
import { useInitData, useOrderData } from '~/orders/hooks';
import { InlineInput, InlineSelect, SectionCell } from '~/orders/Order';
export const DeliverySection = (props) => {
    const init = useInitData();
    const order = useOrderData();
    const orderStatus = getOptionLabel(init.statuses.order, order.status);
    const timeInStatus = pipe(order.timeline, nonEmptyArray.fromArray, option.map((timelines) => nonEmptyArray.last(timelines).timeInStatus), option.toUndefined);
    const deadline = formatDateDistance(order.deadline);
    return (_jsxs("div", { children: [_jsx(SectionCell, { className: "mb-1", title: "Delivery", titleClassName: "font-brand-t4m text-greyscale-500" }), _jsxs("div", { className: "flex space-x-4", children: [_jsxs("div", { className: "space-y-1 w-1/2", children: [_jsx(InlineInput, { title: "Status", readOnly: true, textAlignment: "right", value: orderStatus }), _jsx(InlineInput, { title: "Time in status", readOnly: true, textAlignment: "right", value: timeInStatus })] }), _jsxs("div", { className: "space-y-1 w-1/2", children: [_jsx(InlineSelect, { ...props, title: "Revision" }), _jsx(InlineInput, { title: "Deadline", readOnly: true, textAlignment: "right", value: deadline })] })] })] }));
};
