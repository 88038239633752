import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faCommentAltPlus, faExpandArrows } from '@fortawesome/pro-regular-svg-icons';
import { Button, DateDistance, Show, useModalContext } from '~/common/components';
import { cx } from '~/common/utils';
import { useCurrentUserData } from '~/root';
import { ChatExtended } from '../ChatExtended';
import { SendNote } from '../SendNote';
import { UsersGroup } from './UsersGroup';
// TODO structure_refactoring, it shouldn't depend on the root, either
export const Header = ({ customer, isExtended }) => {
    const { permissions } = useCurrentUserData();
    const { modalOpener } = useModalContext();
    return (_jsxs("div", { className: cx('flex items-center justify-between gap-1', isExtended ? 'pl-3 px-2 py-1 border-b border-b-greyscale-100 ' : 'pr-1 pl-2'), children: [_jsxs("div", { className: "flex items-center space-x-1 min-w-0", children: [_jsx(UsersGroup, { customer: customer }), _jsxs("div", { className: "flex flex-col min-w-0", children: [_jsx("span", { className: "font-brand-t4m text-greyscale-500 truncate", children: customer.name }), customer.last_active_at && (_jsxs("span", { className: "font-brand-c1r text-greyscale-500 whitespace-nowrap", children: ["Last active", ' ', _jsx(DateDistance, { to: customer.last_active_at }, customer.last_active_at.getTime())] }))] })] }), _jsxs("div", { className: "flex items-center space-x-1", children: [_jsx(Show, { if: permissions.ordersChat, children: _jsx(Button.Icon, { "data-tt": "Add a note. Customer will not see it.", icon: faCommentAltPlus, onClick: modalOpener(SendNote), size: "m" }) }), !isExtended && (_jsx(Button.Icon, { "data-tt": "Extend chat", icon: faExpandArrows, size: "m", onClick: modalOpener(ChatExtended, { customer }) }))] })] }));
};
