import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faCloudUpload, faFileCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { Button, Errors, IconBox } from '~/common/components';
import { cx, tw } from '~/common/utils';
// TODO rewrite styles in css modules
const wrapperClassName = tw `
  flex items-center justify-center
  h-7
  def:bg-greyscale-100 def:text-greyscale-400
  border def:border-dashed def:border-greyscale-300 rounded
  focus-within:border-secondary-300 focus-within:border-solid
  cursor-pointer
  pointer-events-auto
`;
export const FileUploadField = ({ disabled, errors, touched, onChange, onBlur, title, loading, hintLeft, hintRight, size = 'regular', className, children, value: filename, ...props }) => {
    const [draggedOver, setDraggedOver] = useState(false);
    const handleFile = (e) => {
        e.preventDefault();
        const files = ('files' in e.target && e.target.files) || ('dataTransfer' in e && e.dataTransfer.files);
        if (files) {
            const file = files[0];
            onChange(file);
            if ('value' in e.target) {
                e.target.value = '';
            }
        }
    };
    const handleDrag = (e) => {
        e.preventDefault();
        setDraggedOver(e.type === 'dragover');
        if (e.type === 'drop') {
            handleFile(e);
        }
    };
    const clear = () => {
        onChange(null);
    };
    return (_jsxs("label", { className: "block pointer-events-none", children: [title && (_jsx("span", { className: cx('block mb-[4px]', size === 'small' ? 'font-brand-b2r' : 'font-brand-b1'), children: title })), _jsxs("div", { className: cx(wrapperClassName, className, {
                    'border-solid border-secondary-300': draggedOver,
                    'border-0 bg-greyscale-50': filename && !loading,
                    'text-greyscale-300': disabled,
                    'text-danger-400 bg-danger-50 border-solid border-danger-400': errors,
                }), onDragOver: handleDrag, onDragLeave: handleDrag, onDrop: handleDrag, children: [_jsx("input", { ...props, className: "absolute -z-10 opacity-0 pointer-events-none", type: "file", onChange: handleFile, onDrop: handleFile }), filename ? (_jsxs(_Fragment, { children: [_jsx(IconBox, { size: "l", icon: loading ? faSpinnerThird : faFileCheck, className: cx('mx-[12px]', loading && 'animate-spin') }), _jsx("span", { className: cx('flex-grow truncate', errors ? 'text-danger-400' : 'text-greyscale-500'), children: filename }), _jsx(Button.Icon, { icon: faTimes, size: "m", className: "mx-[12px]", onClick: clear })] })) : (_jsxs("div", { className: "flex items-center gap-1", children: [_jsx(IconBox, { size: "m", icon: faCloudUpload }), _jsx("span", { className: "font-brand-t4m", children: "Choose a file" })] }))] }), (hintLeft || hintRight) && (_jsxs("div", { className: "flex mt-[4px] font-brand-c1r text-greyscale-400", children: [_jsx("span", { children: hintLeft }), _jsx("span", { className: "ml-auto", children: hintRight })] })), errors && _jsx(Errors, { errors: errors, color: true })] }));
};
