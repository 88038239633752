import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faClipboardList } from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';
import { Button } from '~/common/components';
import { avatarColorSets, copyToClipboard, getColorSetFromString, getStringFromColorSet, toggleSet, } from '~/common/utils';
import { AvatarList } from './AvatarList';
import { GeneratedAvatars } from './Generated';
import { TailwindAvatars } from './Tailwind';
import { UIKitAvatars } from './UIKit';
export const Avatars = () => {
    const [colorSets, setColorSets] = useState(new Set(avatarColorSets));
    const toggleColor = (set) => toggleSet(setColorSets)(getStringFromColorSet(set));
    const isColorSelected = (set) => colorSets.has(getStringFromColorSet(set));
    const pickedSets = [...colorSets].map(getColorSetFromString);
    const handleCopy = () => {
        copyToClipboard(JSON.stringify([...colorSets].sort(() => (Math.random() > 0.5 ? -1 : 1)), null, 2));
    };
    return (_jsxs("div", { className: "space-y-6", children: [_jsx(GeneratedAvatars, { isColorSelected: isColorSelected, toggleColor: toggleColor }), _jsx(UIKitAvatars, { isColorSelected: isColorSelected, toggleColor: toggleColor }), _jsx(TailwindAvatars, { isColorSelected: isColorSelected, toggleColor: toggleColor }), _jsx(AvatarList, { title: "Picked Avatars", colorSets: pickedSets, isColorSelected: isColorSelected, toggleColor: toggleColor, children: _jsx(Button, { icon: faClipboardList, onClick: handleCopy, children: "Copy colors" }) })] }));
};
