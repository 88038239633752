import { z } from 'zod';
import { TimelessDateFromIsoString } from '~/common/utils';
export const Day = z.object({
    date: TimelessDateFromIsoString,
    remaining_capacity: z.number(),
    used_capacity: z.number(),
    over_capacity: z.number(),
    is_holiday: z.boolean(),
    is_weekend: z.boolean(),
});
export const CapacityBreakdown = z.object({
    daily_capacity: z.number(),
    days: z.array(Day),
});
