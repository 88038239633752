import { jsx as _jsx } from "react/jsx-runtime";
import { useLayoutEffect, useRef, useState } from 'react';
export const CSS_CELL_WIDTH = '--cell-width';
export const CSS_CELL_HEIGHT = '--cell-height';
export const CSS_TOTAL_CELL_WIDTH = '--total-cell-width';
export const getCellHeightStyle = (height) => ({ [CSS_CELL_HEIGHT]: height + 'px' });
export const getCellWidthStyle = (width) => width !== undefined ? { [CSS_CELL_WIDTH]: width + 'px' } : undefined;
export const Container = ({ id, infoColumnWidth, minColumnWidth = 80, columns, children, className, }) => {
    const [style, setStyle] = useState({});
    const ref = useRef(null);
    useLayoutEffect(() => {
        const resizeListener = () => {
            if (ref.current) {
                const cellWidth = Math.max((ref.current.clientWidth - infoColumnWidth) / columns, minColumnWidth);
                setStyle({
                    [CSS_CELL_WIDTH]: cellWidth + 'px',
                    [CSS_TOTAL_CELL_WIDTH]: cellWidth * columns + 'px',
                });
            }
        };
        resizeListener();
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, [columns, infoColumnWidth, minColumnWidth]);
    return (_jsx("div", { id: id, className: className, ref: ref, style: style, children: children }));
};
