import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faCalendarDay } from '@fortawesome/pro-regular-svg-icons';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { BorderlessSelect, Button, Datepicker, LayoutHeader, LayoutTitle, Popover, Select, } from '~/common/components';
import { cx, formatDayFullMonthYear, getDateWithoutTime, moveDate, periods } from '~/common/utils';
import { useRmsContext } from '../context';
import { useInitData } from '../hooks';
export const Header = () => {
    const { date, setDate, period, setPeriod, teamId, setTeamId } = useRmsContext();
    const init = useInitData();
    const setToday = () => setDate(getDateWithoutTime());
    const chosenPeriod = (forward) => moveDate(date, teamId ? period : null, forward);
    const handlePrev = () => setDate(chosenPeriod());
    const handleNext = () => setDate(chosenPeriod(true));
    const isMin = date.getTime() <= init.min_date.getTime();
    const isMax = date.getTime() >= init.max_date.getTime();
    return (_jsxs(LayoutHeader, { className: "flex items-center justify-between", children: [_jsxs("div", { className: "flex items-baseline", children: [_jsx(LayoutTitle, { children: "Resource overview" }), _jsx(BorderlessSelect, { className: "ml-3 relative top-[2px]", options: init.teams_header_options, onChange: setTeamId, value: teamId })] }), _jsxs("div", { className: "flex items-center gap-2", children: [_jsx(Button.Icon, { "data-tt": cx(isMin && 'No data available for the date'), icon: faChevronLeft, size: "m", color: "secondary", onClick: handlePrev, disabled: isMin, "aria-label": "Go to previous date/period" }), _jsx(Button.Icon, { "data-tt": cx(isMax && 'No data available for the date'), icon: faChevronRight, size: "m", color: "secondary", onClick: handleNext, disabled: isMax, className: "-ml-2", "aria-label": "Go to next date/period" }), _jsx(Button, { "aria-label": "Go to current date", color: "grey", onClick: setToday, children: "Today" }), teamId && (_jsx(Select, { "aria-label": "Set period", className: "w-13 font-brand-t4m", value: period, onChange: setPeriod, options: periods, theme: "dark", size: "small", noSearch: true })), _jsx(Popover, { trigger: (props) => (_jsx(Button, { "aria-label": "Date picker", icon: faCalendarDay, color: "grey", ...props, children: formatDayFullMonthYear(date) })), content: ({ onClose }) => (_jsx(Datepicker, { minDate: init.min_date, maxDate: init.max_date, value: date, onChange: (date) => {
                                setDate(date);
                                onClose();
                            } })) })] })] }));
};
