import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faUserTag } from '@fortawesome/pro-regular-svg-icons';
import { useForm, validator } from 'formoid';
import { ModalHeader, ModalShell, SelectField, ChipsInputField, extendedSplitter, } from '~/common/components';
import { customValidator, isEmail, pluralize, decodeBackendErrors, } from '~/common/utils';
import { useInitData, useInviteTeamMembers } from '~/customers/hooks';
const EMAILS_ERROR = 'All emails should be valid. Delete or correct invalid ones';
export const roleTooltipContent = (_jsxs(_Fragment, { children: [_jsxs("p", { children: [_jsx("strong", { className: "font-brand-c1m", children: "Managers" }), " can work on their assigned orders, complete orders, and have discussions with designers."] }), _jsx("br", {}), _jsxs("p", { children: [_jsx("strong", { className: "font-brand-c1m", children: "Admin" }), " members can invite others to orders, assign roles and do everything a manager can do."] })] }));
export const InviteTeamMember = ({ onClose }) => {
    const { mutateAsync } = useInviteTeamMembers();
    const init = useInitData();
    const initialValues = {
        emails: [],
        role: null,
    };
    const { fieldProps, handleSubmit, setErrors, setValues, isSubmitting, values } = useForm({
        initialValues,
        validationStrategy: 'onBlur',
        validators: () => ({
            emails: validator.fromPredicate((value) => {
                return value.filter(({ isValid }) => isValid).length === value.length;
            }, EMAILS_ERROR),
            role: customValidator.required(),
        }),
    });
    const submit = () => {
        handleSubmit(({ emails, role }) => {
            return mutateAsync({ emails: emails.map(({ value }) => value), role })
                .then(onClose)
                .catch((error) => {
                const errors = decodeBackendErrors(error);
                if (errors && values.emails.some((_chip, index) => errors[`emails.${index}`])) {
                    setValues((values) => ({
                        ...values,
                        emails: values.emails.map((chip, index) => ({
                            ...chip,
                            isValid: !errors[`emails.${index}`],
                        })),
                    }));
                    setErrors('emails', [EMAILS_ERROR]);
                }
            });
        });
    };
    const amountOfMembers = values.emails.filter(({ isValid }) => isValid).length;
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Invite team member" }), _jsxs(ModalShell, { className: "p-3 pb-5 space-y-3 w-[608px]", onClose: onClose, onSubmit: submit, submitText: amountOfMembers
                    ? `Invite ${amountOfMembers} member${pluralize(amountOfMembers)}`
                    : 'Invite', loading: isSubmitting, children: [_jsx(ChipsInputField, { ...fieldProps('emails'), title: "Email", hint: "For inviting multiple members use space \u201C,\u201D \u201C;\u201D or new line to separate them", splitter: extendedSplitter, validateChip: isEmail }), _jsx(SelectField, { ...fieldProps('role'), title: "Role", placeholder: "Select a role", hint: roleTooltipContent, options: init.invite_roles, icon: faUserTag })] })] }));
};
