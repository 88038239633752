import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { getOptionLabel } from '~/common/utils';
import { useInitData } from '~/orders/hooks';
import { SectionCell } from '~/orders/Order';
export const DesignTeam = ({ isTagTeam, team }) => {
    const { teams } = useInitData();
    if (team) {
        const teamLabel = getOptionLabel(teams, team.id);
        return (_jsxs(_Fragment, { children: [_jsx(SectionCell, { title: isTagTeam ? 'Tag team' : 'Design team', children: _jsxs("span", { className: "font-brand-t4m", children: [teamLabel, " (", team.points, ")"] }) }), _jsx("div", { className: "flex justify-end w-full", children: _jsx("div", { className: "space-y-1 w-2/3", children: team.designers.map((designer, key) => (_jsx(SectionCell, { title: designer.name, children: _jsx("span", { className: "ml-1 font-brand-t4m", children: designer.points }) }, key))) }) })] }));
    }
    return (_jsx(SectionCell, { title: isTagTeam ? 'Tag team' : 'Design team', children: _jsx("span", { className: "font-brand-t4m", children: "\u2014" }) }));
};
