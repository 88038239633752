import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faBriefcase, faBuilding, faChartPieAlt, faPaintbrushAlt, faUsdSquare, } from '@fortawesome/pro-regular-svg-icons';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Avatar, Badge, IconBox, Link, Tooltip } from '~/common/components';
import { formatDateDistance, getColorsFromNumber, getInitials } from '~/common/utils';
import { useAddUserCardId } from './usercardIdsStore';
import { useUserCard } from './useUserCard';
const CUSTOMERS_URL = '/customers';
const USERS_URL = '/users';
// TODO consider making it more useful
// sometimes it's used as a tooltip with user content and redone from scratch on the spot
export const UserCard = ({ id, owner_id, name, avatar_link, type = 'staff', placement = 'right', children, className, ...props }) => {
    useAddUserCardId(id);
    return (_jsx(Tooltip, { className: "p-0 w-[240px]", content: _jsx(UserCardContent, { id: id }), placement: placement, clickable: true, children: children || (_jsx(AvatarWrapper, { id: id, type: type, owner_id: owner_id || null, children: _jsx(Avatar, { colorSet: getColorsFromNumber(id), url: avatar_link !== null && avatar_link !== void 0 ? avatar_link : undefined, className: className, ...props, children: getInitials(name) }) })) }));
};
const AvatarWrapper = forwardRef(({ id, type, owner_id, children }, ref) => (_jsx("div", { ref: ref, children: type === 'staff' || owner_id ? (_jsx(RouterLink, { to: type === 'staff' ? `${USERS_URL}/${id}` : `${CUSTOMERS_URL}/${owner_id}`, children: children })) : (children) })));
export const UserCardContent = ({ id }) => {
    const user = useUserCard(id);
    if (user.isLoading) {
        return (_jsx("div", { className: "flex items-center justify-center w-full h-10", children: _jsx(IconBox, { size: "l", icon: faSpinnerThird, className: "h-fit w-fit animate-spin" }) }));
    }
    if (!user.data) {
        return (_jsx("div", { className: "px-[12px] py-1", children: _jsx("span", { children: "Sorry, we can't show it to you \uD83D\uDE14" }) }));
    }
    return (_jsx("div", { className: "flex flex-col gap-y-1 px-[12px] py-1", children: 'owner_id' in user.data ? (_jsx(AnyCustomerCard, { customer: user.data })) : (_jsx(AnyUserCard, { user: user.data })) }));
};
const Header = ({ name, title, children, }) => (_jsxs("div", { children: [_jsxs("div", { className: "flex items-center gap-x-[4px] justify-between", children: [typeof name === 'string' ? (_jsx("span", { className: "font-brand-t4m text-greyscale-600 truncate", children: name })) : (name), _jsx("div", { className: "shrink-0 font-brand-c1r", children: children })] }), _jsx("span", { className: "font-brand-c1r text-greyscale-400", children: title })] }));
const ShiftStatus = ({ onShift }) => (_jsx("span", { className: onShift ? 'text-success-400' : 'text-danger-500', children: `${onShift ? 'on' : 'off'} shift` }));
const InfoItem = ({ icon, title }) => (_jsxs("li", { className: "flex items-center gap-1", children: [_jsx(IconBox, { size: "s", icon: icon }), _jsx("span", { className: "font-brand-c1r text-greyscale-600", children: title || '-' })] }));
const InfoItems = ({ children }) => (_jsx("ul", { className: "space-y-[4px]", children: children }));
const AnyUserCard = ({ user }) => (_jsxs(_Fragment, { children: [_jsx(Header, { name: _jsx(Link, { size: "s-bold", to: `${USERS_URL}/${user.id}`, children: user.name }), title: user.job_title, children: 'on_shift' in user && _jsx(ShiftStatus, { onShift: user.on_shift }) }), _jsxs(InfoItems, { children: [_jsx(InfoItem, { icon: faBuilding, title: user.office }), 'design_team' in user && _jsx(InfoItem, { icon: faPaintbrushAlt, title: user.design_team }), 'capacity' in user && _jsx(InfoItem, { icon: faChartPieAlt, title: user.capacity })] })] }));
const AnyCustomerCard = ({ customer }) => (_jsxs(_Fragment, { children: [_jsx(Header, { name: 
            // TODO could we have a case where we don't have a customer anymore,
            // but for some reason can get his or his team member's usercard by id?
            customer.owner_id ? (_jsx(Link, { size: "s-bold", to: `${CUSTOMERS_URL}/${customer.owner_id}`, children: customer.name })) : (customer.name), title: customer.role, children: formatDateDistance(customer.last_active_at) }), _jsx("div", { className: "flex gap-[4px] flex-wrap", children: customer.type.map(({ value, name, color }) => (_jsx(Badge, { color: color, size: "small", children: name }, value))) }), _jsxs(InfoItems, { children: [_jsx(InfoItem, { icon: faBriefcase, title: customer.company_name }), 'products' in customer && (_jsx(InfoItem, { icon: faUsdSquare, title: customer.products.map(({ name }) => name).join(' + ') })), 'capacity' in customer && _jsx(InfoItem, { icon: faChartPieAlt, title: customer.capacity })] })] }));
