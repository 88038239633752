import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Banner, Link } from '~/common/components';
import { cx } from '~/common/utils';
import { VERSION } from '~/env';
import { useRootStore } from './store';
const getMajorVersion = (version) => {
    const num = Number(version.split('.')[0]);
    return isNaN(num) ? null : num;
};
export const VERSION_MISMATCH = 'Version mismatch';
export const checkVersion = (headers) => {
    const versionHeader = (headers['x-version'] || `${VERSION}.0.0`);
    const backendVersion = getMajorVersion(versionHeader);
    const versionMismatch = backendVersion && VERSION < backendVersion;
    if (versionMismatch) {
        useRootStore.setState(() => ({ updateRequired: true }));
    }
    return versionMismatch ? `${VERSION_MISMATCH}, FE: ${VERSION}, BE: ${backendVersion}` : null;
};
export const UpdateAvailable = () => {
    const updateRequired = useRootStore((store) => store.updateRequired);
    if (!updateRequired) {
        return null;
    }
    return (_jsxs(Banner, { color: "warning", className: cx('fixed top-2 right-2 px-2 py-[7px] z-40 cursor-default', 'shadow-md transition-brand text-greyscale-600 font-brand-t2r rounded-lg'), children: [_jsx("span", { children: "\uD83D\uDCA5 New app version available, please" }), ' ', _jsx(Link, { size: "l-bold", href: window.location.href, children: "refresh the page" }), ' ', _jsx("span", { children: "to avoid unexpected behaviour" })] }));
};
