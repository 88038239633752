import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useForm, validator } from 'formoid';
import { FileUploadField, InputField, ModalHeader, ModalShell, SelectField, } from '~/common/components';
import { useFileUploadState } from '~/common/hooks';
import { customValidator, minLengthMessage, propagateBackendValidationErrors, record, } from '~/common/utils';
import { useCustomerData, useGeneralInfoMutation } from '~/customers/hooks';
import { useCountriesData } from '~/root';
import { CountryDialCodeSelectField } from '../CountryDialCodeSelectField';
const MIN_PHONE_NUMBER_LENGTH = 4; // ?
export const EditCustomerInfo = ({ onClose }) => {
    var _a, _b, _c, _d, _e;
    const customer = useCustomerData();
    const countries = useCountriesData();
    const { mutateAsync } = useGeneralInfoMutation();
    const initialValues = {
        phone_country_id: ((_a = customer.phone_number) === null || _a === void 0 ? void 0 : _a.country_id) || null,
        phone_number: ((_b = customer.phone_number) === null || _b === void 0 ? void 0 : _b.number) || '',
        country_id: customer.country_id,
        company_name: customer.company || '',
        department: customer.department,
        partnership_logo_id: ((_c = customer.partnership_logo) === null || _c === void 0 ? void 0 : _c.id) || null,
    };
    const { fieldProps, handleSubmit, setErrors, isSubmitting, values } = useForm({
        initialValues,
        validationStrategy: 'onBlur',
        validators: ({ phone_number }) => ({
            phone_country_id: phone_number !== '' ? customValidator.required() : null,
            phone_number: phone_number !== ''
                ? validator.minLength(MIN_PHONE_NUMBER_LENGTH, minLengthMessage('Phone number', MIN_PHONE_NUMBER_LENGTH))
                : null,
            country_id: null,
            company_name: customValidator.required(),
            department: null,
            partnership_logo_id: null,
        }),
    });
    const isDirty = !record.shallowEqual(values, initialValues);
    const uploadFieldProps = useFileUploadState({
        tag: 'customer_partnership_logo',
        props: fieldProps('partnership_logo_id'),
        onError: (errors) => setErrors('partnership_logo_id', errors),
        existingFilename: (_e = (_d = customer.partnership_logo) === null || _d === void 0 ? void 0 : _d.name) !== null && _e !== void 0 ? _e : null,
    });
    const submit = () => handleSubmit((values) => {
        return mutateAsync({
            ...values,
            partnership_logo_id: values.partnership_logo_id === initialValues.partnership_logo_id
                ? undefined
                : values.partnership_logo_id,
        })
            .then(onClose)
            .catch((error) => {
            propagateBackendValidationErrors(error, setErrors);
        });
    });
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Edit customer information" }), _jsxs(ModalShell, { className: "p-3 pb-5 space-y-3 w-[494px]", onClose: onClose, onSubmit: submit, loading: isSubmitting, disabled: !isDirty, children: [_jsxs("div", { className: "grid grid-cols-[124px_auto] gap-x-2", children: [_jsx(CountryDialCodeSelectField, { ...fieldProps('phone_country_id'), title: "Contact phone" }), _jsx(InputField, { ...fieldProps('phone_number'), placeholder: "Enter phone number", className: "mt-[26px]", type: "text", autoComplete: "off" })] }), _jsx(SelectField, { ...fieldProps('country_id'), title: "Country", options: countries }), _jsx(InputField, { ...fieldProps('company_name'), title: "Company name", placeholder: "Enter company name", type: "text" }), _jsx(InputField, { ...fieldProps('department'), title: "Department", placeholder: "Enter customer's department", hint: "Only for internal use", type: "text" }), _jsx(FileUploadField, { ...uploadFieldProps, title: "Partnership logo", hintLeft: "Supports: PNG, JPG", hintRight: "Up to 2 MB", accept: ".png, .jpg, .jpeg" })] })] }));
};
