import { useMutation } from '@tanstack/react-query';
import { httpClient, lastItem, modifyLast } from '~/common/utils';
import { Revision } from '~/orders/domain';
import { isFile, isSlide, useOrderData, useUpdateOrderCache } from '~/orders/hooks';
import { qk } from '~/root/query-keys';
export const getPreviewsUpdatePayload = (previews) => {
    const indexedPreviews = previews.map((preview, index) => ({ ...preview, index: index + 1 }));
    const payload = {
        slide_file_ids: indexedPreviews.filter(isFile).map(({ id, index }) => ({ id, index })),
        slide_ids: indexedPreviews.filter(isSlide).map(({ id, index }) => ({ id, index })),
    };
    return payload;
};
export function useUpdateRevision() {
    const order = useOrderData();
    const id = order.id;
    const lastRevision = lastItem(order.revisions);
    const revisionId = lastRevision === null || lastRevision === void 0 ? void 0 : lastRevision.id;
    const updateOrderCache = useUpdateOrderCache();
    return useMutation({
        mutationKey: qk.updateRevision(id),
        mutationFn: (data) => {
            if (!revisionId) {
                throw new Error('No revision id');
            }
            return httpClient.put(`/v1/staff/orders/${id}/revisions/${revisionId}`, {
                data,
                decoder: Revision,
            });
        },
        onSuccess: (response) => updateOrderCache((order) => ({
            ...order,
            revisions: modifyLast(order.revisions, response),
        })),
    });
}
