import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { array, either, nonEmptyArray, option, separated } from 'fp-ts';
import { flow, pipe } from 'fp-ts/function';
import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { IconBox } from '~/common/components';
import { cx, date } from '~/common/utils';
import { ORDERS_URL } from '~/orders';
import { CheckBox } from '~/orders/Order';
import { useUpdateNotifications } from './hooks';
import { getContent, getDate, getNotificationLink, isRead } from './utils';
const renderIcon = ({ checked, loading }) => {
    if (loading) {
        return _jsx(IconBox, { size: "s", className: "animate-spin", icon: faSpinnerThird });
    }
    if (checked) {
        return _jsx(IconBox, { size: "s", icon: faCheckCircle });
    }
    return _jsx(IconBox, { size: "s", className: "text-success-300", icon: faCircle });
};
export const Notification = memo(({ notification, onClose }) => {
    const [{ read, unread }, loading] = useUpdateNotifications();
    const history = useHistory();
    const isClickable = notification.type === 'regular'
        ? notification.value.event.model === 'Order' || notification.value.external_link
        : true;
    const handleRead = () => {
        switch (notification.type) {
            case 'regular': {
                const { id, is_read } = notification.value;
                if (is_read)
                    return unread(id);
                return read(id);
            }
            case 'bulk': {
                const { left, right } = pipe(notification.value, array.partitionMap((n) => (n.is_read ? either.right(n.id) : either.left(n.id))), separated.bimap(flow(nonEmptyArray.fromArray, option.toNullable), flow(nonEmptyArray.fromArray, option.toNullable)));
                if (left)
                    return read(left);
                if (right)
                    return unread(right);
            }
        }
    };
    const handleClick = () => {
        switch (notification.type) {
            case 'regular': {
                if (!notification.value.is_read) {
                    read(notification.value.id);
                }
                if (notification.value.event.model === 'Order' ||
                    notification.value.event.model === 'Subscription') {
                    const link = getNotificationLink(notification.value.event);
                    if (link) {
                        history.push(link);
                    }
                    onClose();
                }
                else if (notification.value.external_link) {
                    window.open(notification.value.external_link, '_blank', 'noopener,noreferrer');
                }
                break;
            }
            case 'bulk': {
                const id = nonEmptyArray.last(notification.value).event.id;
                history.push(`${ORDERS_URL}/${id}`);
                onClose();
                if (!isRead(notification)) {
                    read(pipe(notification.value, nonEmptyArray.map(({ id }) => id)));
                }
                break;
            }
        }
    };
    return (_jsxs("div", { className: "flex gap-2", children: [_jsx(CheckBox, { "data-tt": `Mark as ${isRead(notification) ? 'unread' : 'read'}`, checked: isRead(notification), className: "h-max py-[4px]", loading: loading, onChange: handleRead, renderIcon: renderIcon }), _jsxs("button", { className: cx('flex flex-col border rounded p-1 w-full', isRead(notification)
                    ? 'bg-greyscale-0 border-greyscale-300'
                    : 'bg-success-50 border-success-300', isClickable || 'cursor-default'), onClick: handleClick, children: [_jsx("span", { className: "block font-brand-b1 text-greyscale-600 text-start", children: getContent(notification) }), _jsx("span", { className: "block font-brand-b2r text-greyscale-400 text-start", children: date.formatDate(getDate(notification)) })] })] }));
});
