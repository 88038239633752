import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Tabs } from '~/common/components';
import { LoginActivity } from './activity';
import { Profile } from './profile';
const tabs = [
    { key: 'profile', label: 'My profile' },
    { key: 'logins', label: 'Login activity' },
];
export const Settings = ({ user }) => {
    const [activeTab, setActiveTab] = useState(tabs[0].key);
    return (_jsxs(_Fragment, { children: [_jsx(Tabs, { className: "flex items-center gap-5 mb-2 def:font-brand-t1m leading-6 border-b-2 border-b-solid border-b-greyscale-200", tabs: tabs, active: activeTab, onChange: setActiveTab }), activeTab === 'profile' && _jsx(Profile, { user: user }), activeTab === 'logins' && _jsx(LoginActivity, {})] }));
};
