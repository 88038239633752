import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { COMMAND_PRIORITY_CRITICAL, KEY_ENTER_COMMAND } from 'lexical';
import { useEffect } from 'react';
export const ChangeHotkeysPlugin = () => {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        // we're just skipping CTRL+Enter here to avoid passing onSubmit too deep
        // maybe this is not a great idea :shrug:
        return editor.registerCommand(KEY_ENTER_COMMAND, (e) => Boolean(e && e.ctrlKey && e.code === 'Enter'), COMMAND_PRIORITY_CRITICAL);
    }, [editor]);
    return null;
};
