import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faShapes } from '@fortawesome/pro-regular-svg-icons';
import { BackButton, LayoutContent, LayoutHeader, LayoutTitle, NotFound, Tabs, } from '~/common/components';
import { is404 } from '~/common/utils';
import { ORGANIZATIONS_URL, useInit, useOrganization, useOrganizationData } from '../hooks';
import { tabs, useOrganizationsContext, withOrganizationsContext } from './context';
export const Organization = withOrganizationsContext(({ children }) => {
    const init = useInit();
    const organization = useOrganization();
    if (is404(organization.error)) {
        return (_jsx(NotFound, { icon: faShapes, text: "There's no organization with this id", safeLocation: ORGANIZATIONS_URL }));
    }
    if (!organization.data || !init.data)
        return _jsx(LayoutContent, { loading: true });
    return _jsx(OrganizationContent, { children: children });
});
export const OrganizationContent = ({ children }) => {
    const organization = useOrganizationData();
    const { activeTab, setActiveTab } = useOrganizationsContext();
    return (_jsxs(LayoutContent, { className: "grid-rows-[auto_1fr]", children: [_jsxs("div", { className: "sticky top-0 z-10 mb-2", children: [_jsxs(LayoutHeader, { className: "flex justify-between items-center gap-2 mb-0", children: [_jsx(BackButton, { to: ORGANIZATIONS_URL }), _jsx(LayoutTitle, { className: "-ml-2 mr-auto truncate", children: organization.name })] }), _jsx(Tabs, { className: "flex items-center gap-5 pt-2 bg-greyscale-75 def:font-brand-t1m leading-6 border-b-2 border-b-solid border-b-greyscale-200", tabs: tabs, active: activeTab, onChange: setActiveTab })] }), children] }));
};
