import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { autoUpdate, FloatingNode, FloatingPortal, offset, size, useClick, useDismiss, useFloating, useFloatingNodeId, useFocus, useInteractions, useListNavigation, useTransitionStatus, } from '@floating-ui/react';
import { faChevronUp, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { Fragment, useMemo, useRef, useState } from 'react';
import { defaultOptionsFilter, IconBox, Input, } from '~/common/components';
import { useEvent } from '~/common/hooks';
import { cx, noop } from '~/common/utils';
import fade from '~/styles/fade.module.scss';
import styles from './Select.module.scss';
// TODO this is blatant Select copypasta
//
// think what we could extract reuse between this, table filter multiselect
// and original select
const getIndexedOptions = (optionSections, filter = () => true) => {
    let index = 0;
    const indexedSectionOptions = optionSections
        .filter((section) => section.options.some(filter))
        .map((section) => ({
        ...section,
        options: section.options.filter(filter).map((option) => ({ ...option, index: index++ })),
    }));
    return [
        indexedSectionOptions,
        indexedSectionOptions.map((entry) => entry.options).flat(),
    ];
};
export const SectionedSelectFactory = ({ value, onChange, options: sectionedOptions, inputField, onBlur: formOnBlur = noop, filterOptions = defaultOptionsFilter, noSearch = false, containerClassName, placement = 'bottom-start', nullable, singleIcon, }) => {
    const [open, setOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    // kbd nav
    const [activeIndex, setActiveIndex] = useState(null);
    // kbd nav
    const listRef = useRef([]);
    const nodeId = useFloatingNodeId();
    const [options, flatIndexedOptions] = useMemo(() => {
        return getIndexedOptions(sectionedOptions);
    }, [sectionedOptions]);
    const { optionName, selectedIndex } = useMemo(() => {
        var _a, _b;
        // kbd nav
        const index = flatIndexedOptions.findIndex((option) => option.value === value);
        const optionName = (_b = (_a = flatIndexedOptions[index]) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '';
        return { optionName, selectedIndex: index === -1 ? null : index };
    }, [flatIndexedOptions, value]);
    const inputValue = open && !noSearch ? searchText : optionName;
    const { context, refs, floatingStyles } = useFloating({
        nodeId,
        placement,
        open,
        onOpenChange: setOpen,
        whileElementsMounted: autoUpdate,
        middleware: [
            size({
                apply({ rects, elements }) {
                    Object.assign(elements.floating.style, {
                        width: `${rects.reference.width}px`,
                    });
                },
                padding: 10,
            }),
            offset({ mainAxis: 5 }),
        ],
    });
    const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
        useClick(context, { keyboardHandlers: false }),
        useFocus(context),
        useDismiss(context),
        // kbd nav
        useListNavigation(context, {
            listRef,
            activeIndex,
            selectedIndex,
            onNavigate: setActiveIndex,
            virtual: true,
            loop: true,
        }),
    ]);
    const { isMounted, status } = useTransitionStatus(context);
    const [filteredSections, flatFilteredOptions] = open && !noSearch
        ? getIndexedOptions(sectionedOptions, filterOptions(searchText.toLowerCase()))
        : [options, flatIndexedOptions];
    // a hack to have updated form state when validating
    const freshOnBlur = useEvent(formOnBlur);
    const onBlur = (_event) => setTimeout(freshOnBlur, 100);
    const handleChange = (event) => {
        setSearchText(event.target.value);
        // kbd nav
        setActiveIndex(0);
        setOpen(true);
    };
    const handleFocus = () => {
        setSearchText('');
    };
    // kbd nav
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && activeIndex != null && flatFilteredOptions[activeIndex]) {
            onChange(flatFilteredOptions[activeIndex].value);
            onBlur(null);
            setOpen(false);
        }
    };
    const inputFieldProps = getReferenceProps({
        ref: refs.setReference,
        value: inputValue,
        onChange: handleChange,
        onFocus: handleFocus,
        onBlur,
        onKeyDown: handleKeyDown,
        readOnly: noSearch,
        children: (_jsxs(_Fragment, { children: [nullable && value !== null && (_jsx(IconBox, { size: "s", className: "cursor-pointer", icon: faTimes, onClick: (e) => {
                        e.preventDefault();
                        onChange(null);
                    } })), !singleIcon && !(nullable && value !== null) && (_jsx(IconBox, { size: "s", className: cx(styles.chevron, open && styles.chevronActive), icon: faChevronUp }))] })),
    });
    if (!isMounted) {
        return _jsx(_Fragment, { children: inputField(inputFieldProps) });
    }
    return (_jsxs(_Fragment, { children: [inputField(inputFieldProps), _jsx(FloatingNode, { id: nodeId, children: _jsx(FloatingPortal, { children: _jsx("div", { ...getFloatingProps({ ref: refs.setFloating, style: floatingStyles }), className: cx('pt-1 bg-greyscale-50 rounded-md border border-solid border-greyscale-300 shadow-sm max-h-[320px] overflow-y-auto z-[35]', fade.floating, containerClassName), "data-status": status, children: filteredSections.length ? (filteredSections
                            .filter((section) => section.options.length)
                            .map((section) => (_jsxs(Fragment, { children: [_jsx("hr", { className: "mb-1 text-greyscale-300 first-of-type:hidden" }), _jsx("div", { className: "mx-1 font-brand-c1m text-greyscale-400", children: section.name }), _jsx("ul", { children: section.options.map((option) => (_createElement("li", { ...getItemProps({
                                            ref(node) {
                                                listRef.current[option.index] = node;
                                            },
                                            onClick() {
                                                onChange(option.value);
                                                onBlur(null);
                                                setOpen(false);
                                            },
                                        }), key: String(option.value), className: cx('h-4 px-2 flex items-center font-brand-b1 cursor-pointer', activeIndex === option.index
                                            ? 'text-secondary-400'
                                            : 'text-greyscale-500') }, option.name))) })] }, section.name)))) : (_jsx("div", { className: "mx-1 mb-1 font-brand-c1m text-greyscale-400", children: "Nothing found :(" })) }) }) })] }));
};
export const SectionedSelect = ({ value, onChange, onBlur, options, noSearch, placement, containerClassName, nullable = false, singleIcon, ...props }) => (_jsx(SectionedSelectFactory, { value: value, onChange: onChange, onBlur: onBlur, options: options, noSearch: noSearch, placement: placement, filterOptions: props.filterOptions, containerClassName: containerClassName, inputField: (inputProps) => _jsx(Input, { ...props, ...inputProps }), 
    // TODO lol
    nullable: nullable, singleIcon: singleIcon }));
