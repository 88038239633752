import { useEffect } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { handleStateAction } from '~/common/utils';
export const useRootStore = create()((set, get) => ({
    visitedModules: new Set(),
    updateRequired: false,
    addVisited: (mod) => {
        const { visitedModules } = get();
        if (!visitedModules.has(mod)) {
            const next = new Set(visitedModules);
            next.add(mod);
            set({ visitedModules: next });
        }
    },
    unreadCount: null,
    setUnreadCount: (unreadCount) => set(() => ({ unreadCount })),
    setUpdateRequired: (updateRequired) => set(() => ({ updateRequired })),
}));
export const useVisited = (mod) => {
    const addVisited = useRootStore((store) => store.addVisited);
    useEffect(() => {
        addVisited(mod);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
export const useSettings = create()(persist((set, get) => ({
    sections: ['brief', 'files'],
    setSections: (stateOrCb) => set({ sections: handleStateAction(stateOrCb, get().sections) }),
    entries: [],
    setEntries: (stateOrCb) => set({ entries: handleStateAction(stateOrCb, get().entries) }),
}), { name: 'inner-platform-settings' }));
