import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm } from 'formoid';
import { Banner, ModalHeader, ModalShell, TextArea } from '~/common/components';
import { customValidator } from '~/common/utils';
import { useSendMessage } from '~/orders/hooks';
import { FilesUploadField } from './FilesUploadField';
const MAX_UPLOADED_FILES_AMOUNT = 20;
export const SendNote = ({ onClose }) => {
    const [sendMessage] = useSendMessage({ isNote: true });
    const { isSubmitting, fieldProps, handleSubmit } = useForm({
        initialValues: {
            files: [],
            message: '',
        },
        validationStrategy: 'onSubmit',
        validators: () => ({
            files: null,
            message: customValidator.nonBlankString('Please enter a message'),
        }),
    });
    const submit = () => {
        handleSubmit(async (values) => {
            await sendMessage(values);
            onClose();
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Add a note" }), _jsxs(ModalShell, { className: "space-y-2 p-3 w-[712px]", submitText: "Add a note", onClose: onClose, onSubmit: submit, loading: isSubmitting, children: [_jsx(Banner, { color: "info", title: "The customer will not see this message" }), _jsx(TextArea, { ...fieldProps('message'), title: "Leave a message", placeholder: "Leave your message" }), _jsx(FilesUploadField, { ...fieldProps('files'), maxFilesAmount: MAX_UPLOADED_FILES_AMOUNT, title: _jsxs(_Fragment, { children: ["Add files ", _jsx("span", { className: "text-greyscale-400", children: "(optional)" })] }) })] })] }));
};
