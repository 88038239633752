import { z } from 'zod';
import { FeatureValues, InitFeatures } from '~/common/kits/features';
import { DateFromIsoString } from '~/common/utils';
import { CustomerType, NumericIdNamePair, UploadedFile, getListDecoder } from '~/root';
const Label = CustomerType;
export const FilterOptions = z.object({
    tag: z.array(Label),
});
export const Init = z.object({
    features: InitFeatures,
    filter_options: FilterOptions,
});
export const OrganizationListItem = z.object({
    id: z.number(),
    name: z.string(),
    owner: z
        .object({
        id: z.number(),
        name: z.string(),
        email: z.string(),
    })
        .nullable(),
    tags: z.array(Label),
    orders_count: z.number(),
    customers_count: z.number(),
    members_count: z.number(),
    custom_portal_url: z.string().nullable().catch(null),
    updated_at: DateFromIsoString,
    created_at: DateFromIsoString,
});
export const OrganizationList = getListDecoder(OrganizationListItem);
export const Customer = z.object({
    id: z.number(),
    name: z.string(),
    email: z.string(),
    members_count: z.number(),
    orders_count: z.number(),
    products: z
        .array(NumericIdNamePair.and(z.object({
        plan_id: z.number(),
    })))
        .nullable(),
    is_owner: z.boolean().nullable().catch(null),
});
export const Organization = OrganizationListItem.extend({
    url: z.string().nullable().catch(null),
    allowed_domains: z.array(z.string()),
    custom_portal_enabled: z.boolean(),
    autoassign_on_signup: z.boolean(),
    autoassign_on_new_order: z.boolean(),
    custom_billing_enabled: z.boolean(),
    customers: z.array(Customer),
    features: FeatureValues,
    independent_customers: z.array(Customer),
    logo: UploadedFile.nullable().catch(null),
}).transform((organization) => ({
    ...organization,
    allowed_domains: organization.allowed_domains.map((domain) => ({
        value: domain,
        isValid: true,
    })),
    users_count: organization.members_count + organization.customers_count,
}));
export const Stats = z
    .object({
    revenue_regular: z.array(z.number()),
    revenue_subscription: z.array(z.number()),
    monthly_orders_value: z.array(z.number()),
    orders: z.array(z.number()),
    average_score: z.array(z.number()),
})
    .transform((stats) => {
    const ratings = stats.average_score.filter((rating) => rating !== 0);
    return {
        ...stats,
        revenue: stats.revenue_regular.map((revenue, i) => revenue + stats.revenue_subscription[i]),
        total_regular_revenue: stats.revenue_regular.reduce((a, b) => a + b, 0),
        total_subscription_revenue: stats.revenue_subscription.reduce((a, b) => a + b, 0),
        avg_monthly_orders_value: stats.monthly_orders_value.reduce((a, b) => a + b, 0) / stats.monthly_orders_value.length,
        average_score: Math.round((ratings.reduce((a, b) => a + b, 0) * 10) / ratings.length) / 10,
    };
});
