import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faBuilding, faPlus, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { faPaintBrushAlt, faUser } from '@fortawesome/pro-solid-svg-icons';
import { Link as RouterLink } from 'react-router-dom';
import { Avatar, Button, IconBox, ListCount, Tooltip, useModalContext } from '~/common/components';
import { formatDateShort, getColorsFromNumber, getInitials, tw } from '~/common/utils';
import { useCurrentUserData } from '~/root';
import { USERS_URL } from '~/users';
import { useTeamData } from '../hooks';
import { EditMembers } from './EditMembers';
const membersHeadingClassName = tw `
  flex items-center justify-between
  pl-3 pr-1 h-6
  border-b border-b-greyscale-200 border-solid
`;
const tableHeadingClassName = 'flex items-center h-5 px-3 bg-greyscale-50 text-greyscale-500';
const tableCellClassName = 'flex items-center min-h-[52px] py-1 px-3 border-t border-solid border-greyscale-200';
export const Members = () => {
    const team = useTeamData();
    const hasMembers = team.designers.length > 0;
    const { permissions } = useCurrentUserData();
    const { modalOpener } = useModalContext();
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: membersHeadingClassName, children: [_jsxs("div", { children: [_jsx("span", { className: "font-brand-t3 text-greyscale-500 uppercase", children: "Members" }), _jsx(ListCount, { name: "active user", className: "inline font-brand-b2r text-greyscale-400 ml-1", amount: team.designers.length })] }), permissions.designTeamsUpdate && (_jsx(Button, { onClick: modalOpener(EditMembers), className: "ml-auto", icon: hasMembers ? undefined : faPlus, color: "grey", children: hasMembers ? 'Edit' : 'Add a team member' }))] }), hasMembers ? (_jsxs("div", { "aria-label": "Assigned team members", className: "grid grid-cols-4 m-2", children: [_jsxs("div", { className: "contents font-brand-t5 text-greyscale-500", children: [_jsx("div", { className: tableHeadingClassName, children: "Name" }), _jsx("div", { className: tableHeadingClassName, children: "Job title" }), _jsx("div", { className: tableHeadingClassName, children: "Daily capacity" }), _jsx("div", { className: tableHeadingClassName, children: "Added" })] }), team.designers.map((designer) => {
                        var _a;
                        return (_jsxs("div", { className: "contents font-brand-b2r", children: [_jsxs("div", { className: tableCellClassName, children: [_jsxs("div", { className: "relative mr-1", children: [_jsx(Avatar, { colorSet: getColorsFromNumber(designer.id), url: (_a = designer.avatar) === null || _a === void 0 ? void 0 : _a.link, children: getInitials(designer.name) }), designer.leader && (_jsx(IconBox, { size: "s", className: "absolute bottom-0 -right-[6px] text-greyscale-500", icon: faUser }))] }), _jsx(Tooltip, { content: _jsxs("div", { className: "font-brand-c1r text-greyscale-600", children: [_jsxs("div", { className: "flex items-center justify-between", children: [_jsx("span", { className: "font-brand-t4m mr-3", children: designer.name }), _jsx("span", { className: designer.on_shift ? 'text-success-400' : 'text-danger-500', children: designer.on_shift ? 'on shift' : 'off shift' })] }), _jsx("div", { className: "text-greyscale-400", children: designer.job_title }), _jsxs("div", { className: "flex items-center mt-[12px]", children: [_jsx(IconBox, { size: "s", className: "mr-1", icon: faBuilding }), _jsx("span", { children: `${team.office.country} Office` })] }), _jsxs("div", { className: "flex items-center mt-[4px]", children: [_jsx(IconBox, { size: "s", className: "mr-1", icon: faPaintBrushAlt }), _jsx("span", { children: team.name })] })] }), placement: "right", children: _jsx("div", { className: "cursor-pointer hover:text-secondary-300 transition-brand", children: _jsx(RouterLink, { to: `${USERS_URL}/${designer.id}`, children: designer.name }) }) })] }), _jsx("div", { className: tableCellClassName, children: designer.job_title }), _jsx("div", { className: tableCellClassName, children: designer.capacity }), _jsx("div", { className: tableCellClassName, children: formatDateShort(designer.added) })] }, designer.id));
                    })] })) : (_jsxs("div", { className: "flex flex-col items-center justify-center h-27 text-greyscale-400", children: [_jsx(IconBox, { size: "l", icon: faUsers }), _jsx("div", { className: "font-brand-b1 mt-[4px]", children: "No team members yet" })] }))] }));
};
