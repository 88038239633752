import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faMoneyCheckDollar } from '@fortawesome/pro-regular-svg-icons';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { SUBSCRIPTIONS_URL } from './hooks';
import { Activity, General, Payments, Subscription, SubscriptionRedirect } from './Subscription';
import { SubscriptionsList } from './SubscriptionsList';
// fixes circular dependency
// eslint-disable-next-line import/order
import { useVisited } from '~/root';
const Subscriptions = () => {
    const { path } = useRouteMatch();
    useVisited('subscriptions');
    return (_jsxs(Switch, { children: [_jsx(Route, { exact: true, path: path, component: SubscriptionsList }), _jsx(Route, { exact: true, path: `${path}/:subscription_id(\\d+)/customer/:customer_id(\\d+)`, render: () => (_jsx(Subscription, { children: _jsx(General, {}) })) }), _jsx(Route, { exact: true, path: `${path}/:subscription_id(\\d+)/customer/:customer_id(\\d+)/payments`, render: () => (_jsx(Subscription, { children: _jsx(Payments, {}) })) }), _jsx(Route, { exact: true, path: `${path}/:subscription_id(\\d+)/customer/:customer_id(\\d+)/activity`, render: () => (_jsx(Subscription, { children: _jsx(Activity, {}) })) }), _jsx(Route, { exact: true, path: `${path}/:subscription_id(\\d+)`, component: SubscriptionRedirect })] }));
};
const nav = {
    icon: faMoneyCheckDollar,
    label: 'Subscriptions',
    path: SUBSCRIPTIONS_URL,
};
export const subscriptionsModule = {
    canShow: (permissions) => permissions.subscriptionsView,
    route: {
        path: SUBSCRIPTIONS_URL,
        component: Subscriptions,
    },
    nav,
};
