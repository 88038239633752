import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Button } from '~/common/components';
import { nonNullable } from '~/common/utils';
import { useCustomerData, useInitData, useInvoiceMethodMutation } from '~/customers/hooks';
import { BillingForm, getBillingInitialValues, useBillingForm } from '../BillingForm';
export const EditInvoicePaymentMethod = ({ onClose }) => {
    const init = useInitData();
    const customer = useCustomerData();
    const { mutateAsync } = useInvoiceMethodMutation();
    // invoiceMethod is defined if we are able to edit it
    const invoicePaymentMethod = nonNullable(customer.invoicePaymentMethod);
    const [initialValues] = useState(getBillingInitialValues(invoicePaymentMethod, init.currencies[0].value));
    const { fillWithBillingInfo, ...form } = useBillingForm(getBillingInitialValues(invoicePaymentMethod, init.currencies[0].value));
    return (_jsx(BillingForm, { title: "Edit invoice payment method", initialValues: initialValues, onClose: onClose, onSubmit: mutateAsync, header: _jsxs("div", { className: "flex justify-between items-center bg-greyscale-100 rounded-t pl-1 h-4 mb-2", children: [_jsx("span", { className: "text-greyscale-500 font-brand-t4m", children: "Billing information" }), _jsx(Button, { color: "text-secondary", onClick: fillWithBillingInfo, children: "Copy from billing address" })] }), ...form }));
};
