import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { number } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { toggleElement } from '~/common/utils';
import { BadgeMultiSelect, SectionCell } from '~/orders/Order';
export const Addons = ({ addons, disabled, onChange, value }) => {
    const onToggleAddon = (addon) => {
        onChange(pipe(value, toggleElement(addon.value, number.Eq, number.Ord)));
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "mb-1", children: _jsx(SectionCell, { title: "Addons", titleClassName: "font-brand-t4m text-greyscale-500" }) }), _jsx(BadgeMultiSelect, { disabled: disabled, onToggleOption: onToggleAddon, options: addons, selectedOptions: value, showOptionValue: number.Show })] }));
};
