import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faShapes } from '@fortawesome/pro-solid-svg-icons';
import { LayoutContent, withModalContext } from '~/common/components';
import { Nothing, Table, useBackendTable } from '~/common/kits/table';
import { ORGANIZATIONS_URL, useInit, useOrganizationsList } from '~/organizations/hooks';
import { Header } from './Header';
import { tableConfig } from './table-config';
export const OrganizationsList = withModalContext(() => {
    const organizations = useOrganizationsList();
    const init = useInit();
    const view = useBackendTable({
        ...organizations,
        tableConfig,
    });
    if (!organizations.data || !init.data || !view) {
        return _jsx(LayoutContent, { loading: true });
    }
    return (_jsxs(LayoutContent, { children: [_jsx(Header, { organizationsCount: organizations.data.metadata.total, tableFilters: init.data.filter_options }), _jsx(Table, { ...view, noDataPlaceholder: _jsx(Nothing, { icon: faShapes }), linkGetter: ({ id }) => `${ORGANIZATIONS_URL}/${id}` })] }));
});
