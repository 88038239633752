import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, PageDetailsCard, useModalContext } from '~/common/components';
import { InnerUserRow } from '~/customers/Customer';
import { useCustomerData } from '~/customers/hooks';
import { useCurrentUserData } from '~/root';
import { EditSuccessTeam } from './EditSuccessTeam';
export const SuccessTeam = () => {
    const { success_team } = useCustomerData();
    const { permissions } = useCurrentUserData();
    const { modalOpener } = useModalContext();
    if (!Object.values(success_team).some(Boolean)) {
        return null;
    }
    return (_jsxs(PageDetailsCard, { label: "Sales and Customer success", actionButton: permissions.customersUpdate && (_jsx(Button, { color: "grey", onClick: modalOpener(EditSuccessTeam), children: "Edit" })), bodyClassName: "grid grid-cols-2 gap-x-1 gap-y-2 font-brand-b1 py-3", children: [_jsx(InnerUserRow, { label: "New business", user: success_team.business_partner, placement: "left" }), _jsx(InnerUserRow, { label: "Customer success", user: success_team.success_manager, placement: "left" }), _jsx(InnerUserRow, { label: "Account manager", user: success_team.account_manager, placement: "left" })] }));
};
