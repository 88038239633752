export const getColorSetFromString = (s) => {
    const [color, backgroundColor, borderColor] = s.split(' ');
    return { color, backgroundColor, borderColor };
};
export const getStringFromColorSet = ({ color, backgroundColor, borderColor }) => `${color} ${backgroundColor} ${borderColor}`;
export const avatarColorSets = [
    '#f86666 #fff4f5 #ffe1e1',
    '#46b688 #e8f5f1 #cbeadd',
    '#7394e8 #f3f7ff #e5eefd',
    '#ffba0a #fff9eb #ffedc2',
    '#ff7b4b #fff7eb #ffe8c6',
    '#7e57c2 #ede7f6 #d1c4e9',
    '#84cc16 #ecfccb #d9f99d',
    '#1ec1a4 #e9fdfd #abeae2',
    '#ec4899 #fce7f3 #fbcfe8',
    '#a855f7 #f3e8ff #e9d5ff',
    '#06b6d4 #cffafe #a5f3fc',
    '#f43f5e #ffe4e6 #fecdd3',
    '#3b82f6 #dbeafe #bfdbfe',
    '#22c55e #dcfce7 #bbf7d0',
    '#d946ef #fae8ff #f5d0fe',
    '#6366f1 #e0e7ff #c7d2fe',
];
const colorSets = avatarColorSets.map(getColorSetFromString);
export const getColorsFromNumber = (n) => colorSets[n % colorSets.length];
export const getInitials = (name) => {
    const words = name.split(' ');
    if (words.length === 1) {
        return words[0].slice(0, 2).toUpperCase();
    }
    return words
        .map((word) => word.slice(0, 1).toUpperCase())
        .slice(0, 2)
        .join('');
};
