import { z } from 'zod';
import { DateFromIsoString } from '~/common/utils';
import { getListDecoder } from '~/root';
const DISPLAY_URL_KEY = 'Display url';
export const ActivityActor = z
    .object({
    id: z.number(),
    name: z.string(),
    avatar_link: z.string().nullable(),
    is_customer: z.boolean().nullable().catch(null),
    owner_id: z.number().nullable(),
})
    .nullable();
export const ActivityItem = z
    .object({
    id: z.number(),
    date: DateFromIsoString,
    user: ActivityActor,
    text: z.string().optional(),
    meta: z
        .array(z.object({
        key: z.string(),
        value: z.union([DateFromIsoString, z.string()]),
    }))
        .optional(),
})
    .transform(({ user, text, meta, ...item }) => {
    var _a;
    const displayUrl = (_a = meta === null || meta === void 0 ? void 0 : meta.find((item) => (item === null || item === void 0 ? void 0 : item.key) === DISPLAY_URL_KEY)) === null || _a === void 0 ? void 0 : _a.value;
    return {
        actor: user,
        action: {
            text,
            meta: (meta === null || meta === void 0 ? void 0 : meta.filter((item) => item.key !== DISPLAY_URL_KEY)) || null,
            displayUrl: displayUrl,
        },
        ...item,
    };
});
export const ActivityWithoutMeta = z.object({ items: z.array(ActivityItem) });
export const Activity = getListDecoder(ActivityItem);
