import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm } from 'formoid';
import { useRef } from 'react';
import { Banner, DragDrop, ModalHeader, ModalShell, SelectField } from '~/common/components';
import { cx, propagateBackendValidationErrors, record } from '~/common/utils';
import { useDedicatedTeamMutation, useProjectManagersMutation } from '~/customers/hooks';
export const EditDedicatedTeamModal = ({ dedicated_team, project_managers, assigned_project_managers, design_teams, onClose, }) => {
    var _a;
    const dedicatedTeam = useDedicatedTeamMutation();
    const projectManagers = useProjectManagersMutation();
    const initialValues = useRef({
        project_managers: assigned_project_managers,
        design_team_id: ((_a = dedicated_team.design_team) === null || _a === void 0 ? void 0 : _a.value) || null,
    });
    const { fieldProps, handleSubmit, setErrors, isSubmitting, values } = useForm({
        initialValues: initialValues.current,
        validationStrategy: 'onBlur',
        validators: () => ({
            design_team_id: null,
            project_managers: null,
        }),
    });
    const submit = () => handleSubmit((values) => {
        return dedicatedTeam
            .mutateAsync({
            design_team_id: values.design_team_id || undefined,
        })
            .then(() => projectManagers.mutateAsync({
            project_managers: values.project_managers.map((pm) => pm.value),
        }))
            .then(onClose)
            .catch((error) => {
            propagateBackendValidationErrors(error, setErrors);
        });
    });
    const isDirty = !record.shallowEqual(values, initialValues.current);
    const isPMSelectDisabled = values.project_managers.length >= 6;
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Edit dedicated team" }), _jsxs(ModalShell, { className: "p-3 pb-5 space-y-3 w-[608px]", onClose: onClose, onSubmit: submit, loading: isSubmitting, disabled: !isDirty, children: [_jsx(SelectField, { ...fieldProps('design_team_id'), title: "Design team", options: design_teams, placeholder: "Choose from the list", nullable: true }), _jsxs("div", { children: [_jsx(SelectField, { ...fieldProps('project_managers'), title: "Project manager", placeholder: "Choose from the list", value: null, disabled: isPMSelectDisabled, onChange: (id) => {
                                    const pm = project_managers.find((pm) => pm.value === id);
                                    fieldProps('project_managers').onChange([...values.project_managers, pm]);
                                }, options: project_managers.filter((pm) => !values.project_managers.some((member) => member.value === pm.value)), nullable: true, hint: _jsxs(_Fragment, { children: [_jsx("p", { children: "You can add a maximum of 6 PMs." }), _jsx("p", { children: "Use dragging to set the priority for assigning order to the most available manager." })] }), hintClickable: true, "data-tt": cx(isPMSelectDisabled && 'You can add a maximum of 6 PMs'), "data-tt-placement": "bottom" }), !!values.project_managers.length && (_jsx(DragDrop, { size: "s", ...fieldProps('project_managers'), onChange: (value) => fieldProps('project_managers').onChange(value.map((item) => ({ name: item.name, value: item.id }))), items: fieldProps('project_managers').value.map((pm) => ({
                                    id: pm.value,
                                    name: pm.name,
                                })), containerClassName: "w-full mt-[4px] space-y-[4px]" }))] }), values.project_managers.length > 1 && (_jsx(Banner, { color: "info", children: "Please, use dragging to set the priority for assigning orders to PM" }))] })] }));
};
