import { array, date, map, ord, semigroup } from 'fp-ts';
import { pipe, tuple } from 'fp-ts/function';
import { z } from 'zod';
import { DateFromIsoString, eqUUID, UUID } from '~/common/utils';
import { InfiniteMetadata } from '~/root';
const GroupableOrderEvent = z.object({
    id: z.number().int().positive(),
    model: z.literal('Order'),
    name: z.string(),
    bindings: z.object({ name: z.string() }).nullable(),
    groupable: z.undefined().transform(() => true),
});
const NonGroupableOrderEvent = z.object({
    id: z.number().int().positive(),
    model: z.literal('Order'),
    name: z.string(),
    groupable: z.undefined().transform(() => false),
});
const OrderEvent = z.union([GroupableOrderEvent, NonGroupableOrderEvent]);
const OtherEvent = z.object({
    id: z.number().int().positive(),
    model: z.string().transform(() => 'Other'),
    name: z.string(),
    groupable: z.undefined().transform(() => false),
});
const UserEvent = z.object({
    id: z.number().int().positive(),
    model: z.literal('User'),
    name: z.string(),
    groupable: z.undefined().transform(() => false),
});
const SubscriptionEvent = z.object({
    id: z.number().int().positive(),
    model: z.literal('Subscription'),
    name: z.string(),
    groupable: z.undefined().transform(() => false),
});
export const NotificationEvent = z.union([
    GroupableOrderEvent,
    NonGroupableOrderEvent,
    UserEvent,
    SubscriptionEvent,
    OtherEvent,
]);
export const Notification = z.object({
    content: z.string(),
    created: DateFromIsoString,
    event: NotificationEvent,
    external_link: z.string().nullable(),
    id: UUID,
    is_read: z.boolean(),
});
export const ordNotification = pipe(date.Ord, ord.contramap((notification) => notification.created), ord.reverse);
export const Notifications = z.object({
    items: z.array(Notification).transform((notifications) => pipe(notifications, array.map((notification) => tuple(notification.id, notification)), map.fromFoldable(eqUUID, semigroup.last(), array.Foldable))),
    metadata: InfiniteMetadata,
});
export const NotificationUpdated = z.object({
    id: UUID,
    content: z.string(),
    event: NotificationEvent,
    external_link: z.string().nullable(),
    is_read: z.boolean(),
});
export const FlashMessage = z.object({
    type: z.enum(['error', 'info', 'success', 'warning']),
    message: z.string(),
});
export const LastReadsUpdated = z.object({
    groupable_id: z.number().int().positive(),
    groupable_type: z.string(),
    readable_id: z.number().int().positive(),
    readable_items_count: z.number().int().nonnegative(),
    readable_type: z.string(),
    unread_items_count: z.number().int().nonnegative(),
    user_id: z.number().int().positive(),
});
