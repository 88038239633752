/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useRef } from 'react';
import { subscribe } from './shared-ws';
import { handleEvent } from './utils';
// this ugly generic hack gets the job done when we need to check callback against the decoder
export const usePrivateChannel = ({ channelName, events, enabled = true, }) => {
    // this will keep event handlers and decoders fresh, so we can close on
    // outside values in them without useEvent and re-subscription
    const eventsRef = useRef(events);
    useEffect(() => {
        eventsRef.current = events;
    }, [events]);
    // this is for determining whether events array was actually changed, while
    // skipping react's stale closure problem
    const eventNames = events.reduce((names, params) => names + params.event, '');
    useEffect(() => {
        if (!enabled) {
            return;
        }
        const unsubs = eventsRef.current.map((params, index) => {
            // the reason we're creating additional callback wrapper is to close on
            // eventsRef.current, so callback goes for the decoder and "onMessage"
            // handler from always fresh events ref
            const callback = (data) => handleEvent(eventsRef.current[index])(data);
            return subscribe({ channel: channelName, event: params.event, callback });
        });
        return () => {
            // instead of leaving the channel and possibly breaking other
            // same-channel instances by doing so, we're just unsubscribing our
            // callbacks from events
            //
            // this also allows having a events list of dynamic size
            unsubs.forEach((unsub) => unsub());
        };
    }, [channelName, enabled, eventNames]);
};
