import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { Fragment, useMemo } from 'react';
import { Button, Collapse, Container, LayoutContainer, Select, StickyLeftTableCell, } from '~/common/components';
import { useToggle } from '~/common/hooks';
import { Header } from './Header';
import { LegendItem } from './LegendItem';
import { TeamRow } from './Row';
import { useCapacityPerShiftTable } from './useTable';
import css from './CapacityPerShift.module.scss';
export const CapacityPerShift = ({ teams, days, globalAvailableShifts, availableOffices, }) => {
    const [show, toggleShow] = useToggle(true);
    const { data: displayedTeams, setSort, sort, setFilter, filter, } = useCapacityPerShiftTable(teams);
    const officeFilterOptions = useMemo(() => [{ value: null, name: 'All offices' }, ...availableOffices], [availableOffices]);
    const shiftFilterOptions = useMemo(() => [
        { value: null, name: 'All shifts' },
        ...globalAvailableShifts.map((shift) => ({
            value: shift.id,
            name: `Shift ${shift.sign}`,
        })),
    ], [globalAvailableShifts]);
    return (_jsxs(LayoutContainer, { children: [_jsxs("div", { className: "font-brand-t1r flex justify-between", children: [_jsx("span", { children: "Remaining capacity per shift" }), _jsxs("div", { className: "flex gap-2", children: [_jsx(Select, { className: "w-14 font-brand-t4m", containerClassName: "w-fit", value: filter.officeId, placeholder: "All offices", 
                                // TODO fix select types
                                onChange: (officeId) => setFilter({ ...filter, officeId }), options: officeFilterOptions, theme: "dark", size: "small", noSearch: true }), _jsx(Select, { className: "w-14 font-brand-t4m", value: filter.shiftId, placeholder: "All shifts", 
                                // TODO fix select types
                                onChange: (shiftId) => setFilter({ ...filter, shiftId }), options: shiftFilterOptions, theme: "dark", size: "small", noSearch: true }), _jsx(Button.Icon, { size: "m", color: "grey-outline", icon: show ? faChevronUp : faChevronDown, onClick: toggleShow })] })] }), _jsxs(Collapse, { in: show, children: [_jsx("div", { className: "flex gap-5 my-1", children: globalAvailableShifts.map((shift) => (_jsx(LegendItem, { ...shift }, shift.id))) }), _jsx(Container, { className: "overflow-x-auto overflow-y-hidden", minColumnWidth: 100, infoColumnWidth: 248, columns: Math.max(days.length, 3), children: _jsxs("table", { className: css.table, children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx(StickyLeftTableCell, { width: 248 }), _jsx(Header, { days: days, setSort: setSort, sort: sort, filter: filter })] }) }), _jsx("tbody", { children: displayedTeams.map((team, index) => (_jsx(Fragment, { children: _jsx(TeamRow, { name: team.name, days: days, capacityPerShift: displayedTeams[index].daysByShiftId }) }, team.id))) })] }) })] })] }));
};
