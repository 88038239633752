import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useMemo, useState } from 'react';
import { Container, DayHeader, getCellHeightStyle, StickyLeftTableCell } from '~/common/components';
import { formatMonthDay, toggleSet } from '~/common/utils';
import { TableCell } from '~/rms/common';
import css from '../../Breakdown/Breakdown.module.scss';
import { Row } from './Row';
export const Breakdown = ({ teams, dates, }) => {
    const today = useMemo(() => formatMonthDay(new Date()), []);
    const [openedTeamIds, setOpenedTeamIds] = useState(new Set());
    const toggleTeam = toggleSet(setOpenedTeamIds);
    const singleTeam = teams.length === 1;
    const shouldShowDesigners = (teamId) => openedTeamIds.has(teamId) || teams.length === 1;
    return (_jsx(Container, { className: "overflow-x-auto overflow-y-hidden mt-1", minColumnWidth: 100, infoColumnWidth: 160, columns: Math.max(dates.length, 7), children: _jsxs("table", { className: css.table, style: getCellHeightStyle(40), children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx(StickyLeftTableCell, { width: 160 }), dates.map((day, index) => (_jsx(TableCell, { active: today === day, children: _jsx(DayHeader, { label: day, today: today === day }) }, index)))] }) }), _jsx("tbody", { children: teams.map((team) => (_jsxs(Fragment, { children: [!singleTeam && (_jsx(Row, { name: team.name, pointsData: team.pointsData, onClick: () => toggleTeam(team.id), opened: openedTeamIds.has(team.id), isTeam: true, isTagTeam: team.tagTeam })), shouldShowDesigners(team.id) &&
                                team.designers.map((designer) => (_jsx(Row, { name: designer.name, pointsData: designer.pointsData, isSingleTeam: singleTeam }, designer.id)))] }, team.id))) })] }) }));
};
