import { z } from 'zod';
import { create } from 'zustand';
import { createQueryState } from '~/common/hooks';
import { createContextPair } from '~/common/kits/context';
import { DateWithFallback, formatYearMonthDay, moveDate, PeriodWithFallback, } from '~/common/utils';
import { isValidTab } from './domain';
const useShiftsQuery = createQueryState(z.object({
    date: DateWithFallback,
    period: PeriodWithFallback,
    officeId: z.coerce.number().int().positive().nullable().catch(null),
    tab: z.string().refine(isValidTab).catch('all'),
}), ({ date, officeId, ...rest }) => ({
    ...rest,
    officeId: officeId ? String(officeId) : undefined,
    date: formatYearMonthDay(date),
}));
const useContextData = () => {
    const [params, setParams] = useShiftsQuery();
    const endDate = moveDate(params.date, params.period, true);
    const setDate = (date) => {
        setParams((prev) => ({ ...prev, date }));
    };
    const setOffice = (officeId) => {
        setParams((prev) => ({ ...prev, officeId }));
    };
    const setPeriod = (period) => {
        setParams((prev) => ({ ...prev, period }));
    };
    const setTab = (tab) => {
        setParams((prev) => ({ ...prev, tab }));
    };
    return {
        ...params,
        endDate,
        formattedStart: formatYearMonthDay(params.date),
        formattedEnd: formatYearMonthDay(endDate),
        setPeriod,
        setDate,
        setTab,
        setOffice,
    };
};
const [ShiftsContext, useShiftsContext] = createContextPair(useContextData);
export { ShiftsContext, useShiftsContext };
export const useHoveredUserStore = create()((set) => ({
    userId: null,
    setUserId: (userId) => set(() => ({ userId })),
}));
