import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faClock, faUserTag } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { forwardRef, useEffect, useState } from 'react';
import { Button, IconBox, Popover } from '~/common/components';
import { cx, nonNullable, getColorByShiftRange } from '~/common/utils';
import { useShiftsContext } from '../context';
import { isShift, offsetWidthToDiscrete, timelineToDiscrete, } from '../domain';
import { useInitData, useShiftsData, useTimelineCreation, useTimelineDeletion } from '../hooks';
import { areSamePlacements, getBarPositionStyle, getDeltaFromTwoPlacements, getInfoFromSelection, getTeamAndMemberFromIds, isDesignTeam, isInValidRange, isPrimaryManager, pointerMoveUtils, } from '../utils';
import css from './TimelineItem.module.scss';
const Updater = ({ teamId, userId, occupationId, isManager, prev, next, onClose, trigger, }) => {
    const [action, setAction] = useState(null);
    // TODO should probably just pass occupation object from above
    const { occupations } = useInitData();
    const { date } = useShiftsContext();
    const { teams } = useShiftsData();
    const creation = useTimelineCreation();
    const deletion = useTimelineDeletion();
    const isLoading = creation.isLoading || deletion.isLoading;
    useEffect(() => {
        const { team, member } = getTeamAndMemberFromIds(teamId, userId, teams);
        const { extend, ...delta } = getDeltaFromTwoPlacements(prev, next);
        const info = getInfoFromSelection(delta, member.timeline);
        const isPrimary = isPrimaryManager(team, userId);
        // for checking if growing selection with a design manager
        const primaryManagerTimeline = isDesignTeam(team) && isManager && !isPrimary
            ? nonNullable(team.members.find((member) => member.id === team.primary_manager_id)).timeline
            : null;
        if (!extend) {
            deletion
                .mutateAsync({
                team_id: teamId,
                user_id: userId,
                itemsToDelete: offsetWidthToDiscrete(delta, date),
            })
                .then(onClose);
            return;
        }
        // TODO Refactor it, so this logic doesn't leak into the view
        // I guess view is only interested in passing bars there, and all discrete
        // handling logic should be outside
        const update = () => {
            const discreteTimeline = timelineToDiscrete([
                {
                    ...delta,
                    occupation: occupations[occupationId],
                    is_manager: isManager,
                },
            ], date);
            creation
                .mutateAsync({
                team_id: teamId,
                user_id: userId,
                timeline: primaryManagerTimeline
                    ? discreteTimeline.map((item) => {
                        // remove designer manager status when extending a bar with temporary
                        // dm set on days where primary dm has shifts
                        const primaryManagerItem = primaryManagerTimeline[item.offset];
                        return {
                            ...item,
                            is_manager: primaryManagerItem && isShift(primaryManagerItem.occupation)
                                ? false
                                : item.is_manager,
                        };
                    })
                    : discreteTimeline,
                isPrimary,
            })
                .then(onClose);
        };
        if (info.hasSelectedItems) {
            setAction(() => update);
        }
        else {
            update();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsx(Popover, { externalState: [Boolean(action), onClose], className: "w-[326px] text-greyscale-600 focus-visible:outline-none rounded-lg", placement: "bottom-start", compensateOffset: 6, trigger: ({ ref }) => trigger({ ref, updating: isLoading }), content: () => (_jsxs("div", { "data-stop-propagation": true, children: [_jsx("div", { className: "mt-1 font-brand-t4m", children: "Do you want to override selected shifts?" }), _jsxs("div", { className: "flex items-center justify-end gap-2 mt-2", children: [_jsx(Button, { color: "grey", onClick: onClose, disabled: isLoading, children: "Cancel" }), _jsx(Button, { color: "secondary", onClick: () => action === null || action === void 0 ? void 0 : action(), loading: isLoading, children: "Apply" })] })] })) }));
};
const TimelineItemView = forwardRef(({ placement, sign, range, isManager, resizing, updating, resizeable, onClick, handleDown }, ref) => {
    const color = range ? getColorByShiftRange(range) : null;
    return (_jsxs("div", { ref: ref, style: getBarPositionStyle(placement.offset, placement.width), className: cx(css.timelineItem, css[color !== null && color !== void 0 ? color : 'red'], isManager && css.isManager, resizing && 'z-[2]', updating && css.updating, resizeable && css.resizeable), children: [_jsx("div", { className: css.sign, children: sign }), resizeable && (_jsx(IconBox, { "data-stop-propagation": true, icon: faChevronDown, className: css.chevron, onClick: onClick })), isManager && _jsx(IconBox, { icon: faUserTag, className: css.managerIcon }), range && (_jsxs("div", { className: css.range, children: [_jsx(IconBox, { icon: faClock, className: css.icon }), _jsx("span", { className: css.time, children: range })] })), resizeable && (_jsxs(_Fragment, { children: [_jsx("div", { "data-stop-propagation": true, "data-left": true, className: css.resizeLeft, onPointerDown: handleDown }), _jsx("div", { "data-stop-propagation": true, className: css.resizeRight, onPointerDown: handleDown })] }))] }));
});
export const TimelineItem = ({ placement, validRange, ...props }) => {
    const [resizing, setResizing] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [resizePlacement, setResizePlacement] = useState(placement);
    const handleDown = props.resizeable
        ? (event) => {
            // left click only
            if (event.button !== 0) {
                return;
            }
            const isLeft = event.currentTarget.matches('[data-left]');
            pointerMoveUtils({
                event,
                onMove: (placement) => {
                    setResizePlacement((prev) => {
                        const next = isLeft
                            ? {
                                width: resizePlacement.width - placement.relativeOffset,
                                offset: resizePlacement.offset + placement.relativeOffset,
                            }
                            : {
                                ...prev,
                                width: resizePlacement.width + placement.relativeOffset,
                            };
                        return next.width >= 1 && isInValidRange(validRange, placement) ? next : prev;
                    });
                },
                onDown: () => setResizing(true),
                onUp: () => setResizing(false),
            });
        }
        : undefined;
    // unfortunately placements can't be checked for equality in onUp callback easily enough
    // and in case of no changes, updater shouldn't be triggered at all
    useEffect(() => {
        if (!resizing && !areSamePlacements(placement, resizePlacement)) {
            setIsUpdating(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resizing, resizePlacement.offset, resizePlacement.width]);
    const handleClose = () => {
        setIsUpdating(false);
        setResizePlacement(placement);
    };
    if (isUpdating) {
        return (_jsx(Updater, { teamId: props.teamId, userId: props.userId, occupationId: props.occupationId, isManager: props.isManager, prev: placement, next: resizePlacement, onClose: handleClose, trigger: (triggerProps) => (_jsx(TimelineItemView, { ...props, ...triggerProps, placement: resizePlacement, handleDown: handleDown, resizing: resizing || isUpdating })) }));
    }
    return (_jsx(TimelineItemView, { ...props, placement: resizePlacement, handleDown: handleDown, resizing: resizing || isUpdating, updating: isUpdating }));
};
