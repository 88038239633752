import { customValidator } from '~/common/utils';
export const initialSuccessTeamValues = {
    success_manager_id: null,
    business_partner_id: null,
    account_manager_id: null,
    success_manager_id_required: false,
    business_partner_id_required: false,
    account_manager_id_required: false,
};
export const successTeamFormValidators = ({ success_manager_id_required, business_partner_id_required, account_manager_id_required, }) => ({
    success_manager_id: success_manager_id_required
        ? customValidator.required('Please select a success manager')
        : null,
    business_partner_id: business_partner_id_required
        ? customValidator.required('Please select a business partner')
        : null,
    account_manager_id: account_manager_id_required
        ? customValidator.required('Please select an account manager')
        : null,
    success_manager_id_required: null,
    business_partner_id_required: null,
    account_manager_id_required: null,
});
