import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faCheckCircle, faCircleXmark } from '@fortawesome/pro-regular-svg-icons';
import { IconBox, Link } from '~/common/components';
import { formatDayMonthYear, formatMonthToYearsAndMonth } from '~/common/utils';
import { PlanInfoRow, PlanPaymentRow } from '~/customers/Customer';
import { useCurrentUserData } from '~/root';
import { useSubscriptionData } from '~/subscriptions/hooks';
import { Header } from './Header';
import { UpcomingEvents } from './UpcomingEvents';
export const PlanInfo = () => {
    const { renews_at, payment_method, renewal_period, products } = useSubscriptionData();
    return (_jsxs("div", { className: "rounded overflow-hidden bg-greyscale-0", children: [_jsx(Header, {}), _jsxs("div", { className: "px-3 py-2 flex gap-x-2 justify-between border-b border-solid border-greyscale-200", children: [products.map((product) => (_jsx(ProductInfoBlock, { product: product }, product.id))), _jsxs(InfoBlock, { title: "General", children: [_jsx(PlanInfoRow, { title: "Billing cycle", text: formatMonthToYearsAndMonth(renewal_period) }), _jsx(PlanInfoRow, { title: "Next payment", text: renews_at && formatDayMonthYear(renews_at) }), _jsx(PlanPaymentRow, { payment_method: payment_method })] })] }), _jsx(UpcomingEvents, {})] }));
};
const InfoBlock = ({ title, children }) => (_jsxs("div", { className: "px-2 pt-[12px] pb-2 w-full border border-solid border-greyscale-200 rounded-lg", children: [_jsx("h4", { className: "font-brand-t4m mb-1", children: title }), _jsx("div", { className: "grid grid-cols-[1fr_2fr] gap-x-3 items-center", children: children })] }));
const ProductInfoBlock = ({ product }) => 'credits' in product ? (_jsx(RetainerInfoBlock, { product: product })) : (_jsx(TechpackInfoBlock, { product: product }));
const RetainerInfoBlock = ({ product }) => {
    const { permissions } = useCurrentUserData();
    const { capacity_overview_link } = useSubscriptionData();
    return (_jsxs(InfoBlock, { title: `Retainer - ${product.plan.name}`, children: [_jsx(PlanInfoRow, { title: "Monthly credits", text: product.plan.supports_credits ? product.credits : _jsx(Disabled, {}) }), _jsx(PlanInfoRow, { title: "Daily points", text: product.daily_points === null ? (_jsx(Disabled, {})) : (_jsxs("div", { className: "flex gap-x-1 items-center w-full", children: [_jsxs("span", { className: "whitespace-nowrap", children: [product.daily_points, " per day"] }), permissions.customersView && capacity_overview_link && (_jsx(Link, { external: true, newTab: true, size: "m-bold", href: capacity_overview_link }))] })) }), _jsx(PlanInfoRow, { title: "Top up", text: product.enable_topup ? _jsx(Enabled, {}) : _jsx(Disabled, {}) })] }));
};
const TechpackInfoBlock = ({ product }) => (_jsxs(InfoBlock, { title: "Tech pack", children: [_jsx(PlanInfoRow, { title: "Custom portal", text: product.enable_custom_portal ? _jsx(Enabled, {}) : _jsx(Disabled, {}) }), _jsx(PlanInfoRow, { title: "Custom fields", text: product.enable_custom_fields ? _jsx(Enabled, {}) : _jsx(Disabled, {}) }), _jsx(PlanInfoRow, { title: "Single sign on", text: product.enable_sso ? _jsx(Enabled, {}) : _jsx(Disabled, {}) })] }));
const Disabled = () => (_jsxs("div", { className: "font-brand-b2r inline-flex items-center gap-x-[4px]", children: [_jsx(IconBox, { icon: faCircleXmark, className: "text-danger-400" }), _jsx("span", { children: "Disabled" })] }));
const Enabled = () => (_jsxs("div", { className: "font-brand-b2r inline-flex items-center gap-x-[4px]", children: [_jsx(IconBox, { icon: faCheckCircle, className: "text-primary-300" }), _jsx("span", { children: "Enabled" })] }));
