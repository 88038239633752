export const theme = {
    blockCursor: 'lexical-blockCursor',
    characterLimit: 'lexical-characterLimit',
    code: 'lexical-code',
    codeHighlight: {
        atrule: 'lexical-tokenAttr',
        attr: 'lexical-tokenAttr',
        boolean: 'lexical-tokenProperty',
        builtin: 'lexical-tokenSelector',
        cdata: 'lexical-tokenComment',
        char: 'lexical-tokenSelector',
        class: 'lexical-tokenFunction',
        'class-name': 'lexical-tokenFunction',
        comment: 'lexical-tokenComment',
        constant: 'lexical-tokenProperty',
        deleted: 'lexical-tokenProperty',
        doctype: 'lexical-tokenComment',
        entity: 'lexical-tokenOperator',
        function: 'lexical-tokenFunction',
        important: 'lexical-tokenVariable',
        inserted: 'lexical-tokenSelector',
        keyword: 'lexical-tokenAttr',
        namespace: 'lexical-tokenVariable',
        number: 'lexical-tokenProperty',
        operator: 'lexical-tokenOperator',
        prolog: 'lexical-tokenComment',
        property: 'lexical-tokenProperty',
        punctuation: 'lexical-tokenPunctuation',
        regex: 'lexical-tokenVariable',
        selector: 'lexical-tokenSelector',
        string: 'lexical-tokenSelector',
        symbol: 'lexical-tokenProperty',
        tag: 'lexical-tokenProperty',
        url: 'lexical-tokenOperator',
        variable: 'lexical-tokenVariable',
    },
    embedBlock: {
        base: 'lexical-embedBlock',
        focus: 'lexical-embedBlockFocus',
    },
    hashtag: 'lexical-hashtag',
    heading: {
        h1: 'lexical-h1 lexical-heading',
        h2: 'lexical-h2 lexical-heading',
        h3: 'lexical-h3 lexical-heading',
        h4: 'lexical-h4 lexical-heading',
        h5: 'lexical-h5 lexical-heading',
        h6: 'lexical-h6 lexical-heading',
    },
    image: 'editor-image',
    indent: 'lexical-indent',
    inlineImage: 'inline-editor-image',
    layoutContainer: 'lexical-layoutContainer',
    layoutItem: 'lexical-layoutItem',
    link: 'lexical-link',
    list: {
        checklist: 'lexical-checklist',
        listitem: 'lexical-listItem',
        listitemChecked: 'lexical-listItemChecked',
        listitemUnchecked: 'lexical-listItemUnchecked',
        nested: {
            listitem: 'lexical-nestedListItem',
        },
        olDepth: ['lexical-ol1', 'lexical-ol2', 'lexical-ol3', 'lexical-ol4', 'lexical-ol5'],
        ul: 'lexical-ul',
    },
    ltr: 'lexical-ltr',
    mark: 'lexical-mark',
    markOverlap: 'lexical-markOverlap',
    paragraph: 'lexical-paragraph',
    quote: 'lexical-quote',
    rtl: 'lexical-rtl',
    table: 'lexical-table',
    tableAddColumns: 'lexical-tableAddColumns',
    tableAddRows: 'lexical-tableAddRows',
    tableCell: 'lexical-tableCell',
    tableCellActionButton: 'lexical-tableCellActionButton',
    tableCellActionButtonContainer: 'lexical-tableCellActionButtonContainer',
    tableCellEditing: 'lexical-tableCellEditing',
    tableCellHeader: 'lexical-tableCellHeader',
    tableCellPrimarySelected: 'lexical-tableCellPrimarySelected',
    tableCellResizer: 'lexical-tableCellResizer',
    tableCellSelected: 'lexical-tableCellSelected',
    tableCellSortedIndicator: 'lexical-tableCellSortedIndicator',
    tableResizeRuler: 'lexical-tableCellResizeRuler',
    tableSelected: 'lexical-tableSelected',
    tableSelection: 'lexical-tableSelection',
    text: {
        bold: 'lexical-textBold',
        code: 'lexical-textCode',
        italic: 'lexical-textItalic',
        strikethrough: 'lexical-textStrikethrough',
        subscript: 'lexical-textSubscript',
        superscript: 'lexical-textSuperscript',
        underline: 'lexical-textUnderline',
        underlineStrikethrough: 'lexical-textUnderlineStrikethrough',
    },
};
