import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { IconBox, Link, StatsBlock, StatsCard } from '~/common/components';
import { addQueryParams, formatMoney } from '~/common/utils';
import { ORDERS_URL } from '~/orders';
import { useOrganizationData, useStatsData } from '~/organizations/hooks';
const METABASE_DASHBOARD_URL = 'https://insights.24slides.dev/public/dashboard/0895edd6-24ae-4cc3-a824-88098edab07f';
export const Stats = () => {
    const stats = useStatsData();
    const organization = useOrganizationData();
    return (_jsxs("div", { className: "grid gap-2 grid-cols-6 row-start-1 row-end-2", children: [_jsx(StatsCard, { className: "col-span-2", generalProps: {
                    heading: 'Total customers',
                    value: organization.customers_count,
                    hint: 'Total account is the number of owners',
                } }), _jsx(StatsCard, { className: "col-span-2", generalProps: {
                    heading: 'Total members',
                    value: organization.members_count,
                    hint: 'Total members include managers, and admins.',
                } }), _jsx(StatsCard, { className: "col-span-2 gap-x-2", generalProps: {
                    heading: 'Total users',
                    value: organization.users_count,
                    hint: 'Total users include owners, managers, and admins.',
                }, action: _jsx(Link, { newTab: true, external: true, size: "m-bold", href: addQueryParams(METABASE_DASHBOARD_URL, {
                        tab: '63-users-overview',
                        id: organization.id,
                    }), children: "Overview" }) }), _jsxs(StatsBlock, { className: "col-span-3", children: [_jsx(StatsBlock.Item, { generalProps: {
                            heading: 'Average rating',
                            value: (_jsxs("div", { className: "flex gap-[4px]", children: [_jsx(IconBox, { icon: faStar, className: "h-3 w-3 text-[20px] text-warning-400" }), _jsx("span", { children: stats.average_score ? `${stats.average_score}/5` : '—' })] })),
                            description: 'All time',
                        }, action: _jsx(Link, { newTab: true, external: true, size: "m-bold", href: addQueryParams(METABASE_DASHBOARD_URL, {
                                tab: '61-response-time-and-satisfaction',
                                id: organization.id,
                            }), children: "Overview" }) }), _jsx(StatsBlock.Item, { generalProps: {
                            heading: 'Orders',
                            value: organization.orders_count,
                            description: 'All time',
                        }, action: _jsx(Link, { size: "m-bold", to: `${ORDERS_URL}?filter[organisation]=[${organization.id}]`, children: "All orders" }), graphProps: { data: stats.orders, color: '#F86666' } }), _jsx(StatsBlock.Item, { generalProps: {
                            heading: 'Orders value',
                            value: formatMoney(stats.avg_monthly_orders_value),
                            description: 'for last 12 months',
                        }, graphProps: { data: stats.monthly_orders_value, color: '#7394E8' }, action: _jsx(Link, { newTab: true, external: true, size: "m-bold", href: addQueryParams(METABASE_DASHBOARD_URL, {
                                tab: '60-orders-overview',
                                id: organization.id,
                            }), children: "Overview" }) })] }), _jsxs(StatsBlock, { className: "col-span-3", children: [_jsx(StatsBlock.Item, { generalProps: {
                            heading: 'Total Revenue',
                            value: formatMoney(stats.total_subscription_revenue + stats.total_regular_revenue),
                            description: 'for last 12 months',
                        }, graphProps: { data: stats.revenue, color: '#46B688' }, action: _jsx(Link, { newTab: true, external: true, size: "m-bold", href: addQueryParams(METABASE_DASHBOARD_URL, {
                                tab: '62-finance-overview',
                                id: organization.id,
                            }), children: "Overview" }) }), _jsx(StatsBlock.Item, { generalProps: {
                            heading: 'Subscriptions revenue',
                            value: formatMoney(stats.total_subscription_revenue),
                            description: 'for last 12 months',
                        }, graphProps: { data: stats.monthly_orders_value, color: '#FFBA0A' }, action: _jsx(Link, { newTab: true, external: true, size: "m-bold", href: addQueryParams(METABASE_DASHBOARD_URL, {
                                tab: '62-finance-overview',
                                id: organization.id,
                                customer_type: 'Subscription',
                            }), children: "Overview" }) }), _jsx(StatsBlock.Item, { generalProps: {
                            heading: 'Regular revenue',
                            value: formatMoney(stats.total_regular_revenue),
                            description: 'for last 12 months',
                        }, graphProps: { data: stats.monthly_orders_value, color: '#8A6DD9' }, action: _jsx(Link, { newTab: true, external: true, size: "m-bold", href: addQueryParams(METABASE_DASHBOARD_URL, {
                                tab: '62-finance-overview',
                                id: organization.id,
                                customer_type: 'Regular',
                            }), children: "Overview" }) })] })] }));
};
