import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { z } from 'zod';
import { useUpdateCache } from '~/common/hooks';
import { usePrivateChannel } from '~/common/kits/socket';
import { LastReadsUpdated, Notification, NotificationUpdated } from '~/notifications/domain';
import { OrdersBoardItemUpdate, CollaborationMessage, } from '~/orders/domain';
import { useIsMyMessage } from '~/orders/hooks';
import { useCurrentUserData } from '~/root';
import { qk } from '~/root/query-keys';
export function useUpdateBoardCache() {
    return useUpdateCache(qk.ordersBoard, 'Orders board cache is not defined!');
}
export const useBoardChannel = () => {
    const updateCache = useUpdateBoardCache();
    usePrivateChannel({
        channelName: 'staff.orders.board',
        events: [
            {
                event: '.order.updated',
                decoder: OrdersBoardItemUpdate,
                callback: ({ order }) => {
                    updateCache(({ items }) => ({
                        items: items.map((item) => {
                            return order.id === item.id
                                ? {
                                    ...item,
                                    ...order,
                                }
                                : item;
                        }),
                    }));
                },
            },
            {
                event: '.order.created',
                decoder: OrdersBoardItemUpdate,
                callback: ({ order }) => {
                    updateCache(({ items }) => ({
                        items: [
                            {
                                ...order,
                                collaboration: {
                                    checklist_items_done: 0,
                                    checklist_items_total: 0,
                                    total_comments_count: 0,
                                    unread_comments_count: 0,
                                },
                                notifications: 0,
                            },
                            ...items,
                        ],
                    }));
                },
            },
        ],
    });
};
export const useUpdateUnreadChannel = () => {
    const currentUser = useCurrentUserData();
    const updateCache = useUpdateBoardCache();
    usePrivateChannel({
        channelName: `user.${currentUser.id}`,
        events: [
            {
                event: '.last-reads.updated',
                decoder: LastReadsUpdated,
                callback: (reads) => {
                    if (reads.groupable_type !== 'Order' &&
                        reads.readable_type !== 'OrderCollaborationComment') {
                        return;
                    }
                    updateCache(({ items }) => ({
                        items: items.map((order) => {
                            return order.id === reads.groupable_id
                                ? {
                                    ...order,
                                    collaboration: {
                                        ...order.collaboration,
                                        total_comments_count: reads.readable_items_count,
                                        unread_comments_count: reads.unread_items_count,
                                    },
                                }
                                : order;
                        }),
                    }));
                },
            },
            {
                event: '.notification.created',
                decoder: Notification,
                callback: (notification) => {
                    if (notification.event.model === 'Order') {
                        updateCache(({ items }) => ({
                            items: items.map((order) => {
                                return order.id === notification.event.id
                                    ? {
                                        ...order,
                                        notifications: order.notifications + 1,
                                    }
                                    : order;
                            }),
                        }));
                    }
                },
            },
            {
                event: '.notification.updated',
                decoder: NotificationUpdated,
                callback: (notification) => {
                    if (notification.event.model === 'Order') {
                        updateCache(({ items }) => ({
                            items: items.map((order) => {
                                return order.id === notification.event.id
                                    ? {
                                        ...order,
                                        notifications: notification.is_read
                                            ? order.notifications - 1
                                            : order.notifications + 1,
                                    }
                                    : order;
                            }),
                        }));
                    }
                },
            },
        ],
    });
};
export const useBoardCollaborationChannel = () => {
    const updateCache = useUpdateBoardCache();
    const isMyMessage = useIsMyMessage();
    usePrivateChannel({
        channelName: 'staff.orders',
        events: [
            {
                event: '.order.collaboration.comment.created',
                decoder: CollaborationMessage,
                callback: (message) => {
                    updateCache(({ items }) => ({
                        items: items.map((order) => {
                            return order.id === message.order_id
                                ? {
                                    ...order,
                                    collaboration: {
                                        ...order.collaboration,
                                        total_comments_count: order.collaboration.total_comments_count + 1,
                                        unread_comments_count: isMyMessage(message)
                                            ? 0
                                            : order.collaboration.unread_comments_count + 1,
                                    },
                                }
                                : order;
                        }),
                    }));
                },
            },
            {
                event: '.order.collaboration.checklist.counter',
                decoder: z.object({
                    order_id: z.number().int().positive(),
                    items_done: z.number().int().nonnegative(),
                    items_total: z.number().int().nonnegative(),
                }),
                callback: (collaboration) => {
                    updateCache(({ items }) => ({
                        items: items.map((item) => {
                            return collaboration.order_id === item.id
                                ? {
                                    ...item,
                                    collaboration: {
                                        ...item.collaboration,
                                        checklist_items_done: collaboration.items_done,
                                        checklist_items_total: collaboration.items_total,
                                    },
                                }
                                : item;
                        }),
                    }));
                },
            },
        ],
    });
};
export const useBoardFilterPresets = () => {
    const history = useHistory();
    const { search } = useLocation();
    const { id, team, role } = useCurrentUserData();
    useEffect(() => {
        if (!search && (team || ['regular_pm', 'subscription_pm'].includes(role))) {
            history.replace({
                search: (team ? `filter[team]=[${team.id}]` : `filter[manager]=[${id}]`) +
                    '&filter[sort]=deadline:asc',
            });
        }
    }, [history, id, role, search, team]);
};
