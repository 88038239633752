import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconBox, LayoutContainer } from '~/common/components';
import { cx } from '~/common/utils';
export const Nothing = ({ icon, className, text = 'Nothing found', size = 'large', }) => (_jsxs(LayoutContainer, { className: cx('flex flex-col justify-center items-center def:h-[600px] text-greyscale-300', className), children: [_jsx(IconBox, { className: cx('text-inherit', {
                'h-5 w-5 text-[32px]': size === 'large',
                'h-4 w-4 text-[24px]': size === 'medium',
            }), icon: icon }), _jsx("p", { className: cx({
                'font-brant-t1r mt-2': size === 'large',
                'font-brand-b1 mt-[4px]': size === 'medium',
            }), children: text })] }));
