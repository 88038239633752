import { isToday } from 'date-fns';
import { validateForm } from 'formoid';
import { useEffect, useMemo, useState } from 'react';
import { useEvent } from '~/common/hooks';
import { propagateBackendValidationErrors, scrollToError } from '~/common/utils';
import { useSubscriptionCreateMutation } from '~/customers/hooks';
import { paymentMethodIdValidator, transformFormValuesToProductInput, productsSharedValidators, getProductInitialValues, transformProductPlanToFormValues, } from '../forms';
import { useProductPrice } from './useProductPrice';
export const useCreateSubscriptionForm = ({ form, ownerId, products, productsList, invoicePaymentMethod, }) => {
    const createSubscription = useSubscriptionCreateMutation();
    const productPrice = useProductPrice(ownerId);
    const isInvoicePaymentMethodSelected = form.values.payment_method_id === (invoicePaymentMethod === null || invoicePaymentMethod === void 0 ? void 0 : invoicePaymentMethod.id);
    const productsMap = useMemo(() => {
        const freeOptionsMap = products.list.reduce((accum, { type }) => {
            if (type) {
                accum[type] = false;
            }
            return accum;
        }, Object.keys(productsList).reduce((accum, key) => {
            if (key !== 'all') {
                accum[key] = true;
            }
            return accum;
        }, {}));
        const freeOptions = Object.entries(freeOptionsMap).reduce((accum, [key, val]) => {
            if (val) {
                accum = [
                    ...accum,
                    ...productsList[key].map(({ value, name }) => ({ name, value })),
                ];
            }
            return accum;
        }, []);
        return products.list.map((product) => {
            let options = freeOptions;
            if (product.type) {
                options = [
                    ...options,
                    ...productsList[product.type].map(({ value, name }) => ({ name, value })),
                ];
            }
            return {
                product,
                options,
                removable: true,
            };
        });
    }, [products.list, productsList]);
    const calculatePrice = async (payload) => {
        productPrice.calculate(payload).catch((error) => {
            propagateBackendValidationErrors(error, form.setErrors);
            productPrice.reset();
        });
    };
    const validatePriceCalculation = useEvent(async (values) => {
        const productValidators = products.validators(values);
        const validateValues = await validateForm(values, {
            ...productValidators,
            ...productsSharedValidators,
            ...paymentMethodIdValidator,
        });
        if (validateValues.id === 'Success' &&
            products.list.length &&
            !products.list.some(({ type }) => type === null)) {
            calculatePrice({
                product_type: 'subscription',
                payment_method_id: values.payment_method_id,
                currency: values.currency,
                renewal_period: values.renewal_period,
                products: products.list
                    .map(({ id, type }) => transformFormValuesToProductInput({
                    id,
                    type: type,
                    values,
                }))
                    .filter(Boolean),
            });
        }
        else {
            productPrice.reset();
        }
    });
    const onPriceRelatedFieldChange = (fieldName) => (value) => {
        form.fieldProps(fieldName).onChange(value);
        if (value === null) {
            productPrice.reset();
        }
        else {
            validatePriceCalculation({
                ...form.values,
                [fieldName]: value,
            });
        }
    };
    const onPriceRelatedFieldBlur = (fieldName) => () => {
        form.fieldProps(fieldName).onBlur();
        validatePriceCalculation(form.values);
    };
    const onPaymentMethodChange = (value) => {
        onPriceRelatedFieldChange('payment_method_id')(value);
        const invoiceMethodPO = invoicePaymentMethod === null || invoicePaymentMethod === void 0 ? void 0 : invoicePaymentMethod.po_number;
        if (form.values.payment_method_id === (invoicePaymentMethod === null || invoicePaymentMethod === void 0 ? void 0 : invoicePaymentMethod.id)) {
            form.setValues((prevFormValues) => ({
                ...prevFormValues,
                po_number: '',
                po_expires_at: null,
                prevent_sending_invoice: false,
            }));
        }
        else if (value === (invoicePaymentMethod === null || invoicePaymentMethod === void 0 ? void 0 : invoicePaymentMethod.id) && invoiceMethodPO) {
            form.setValues((prevFormValues) => ({
                ...prevFormValues,
                po_number: invoiceMethodPO,
            }));
        }
    };
    const setPlanValues = useEvent(({ value, type }) => {
        form.setValues((prevFormValues) => {
            const plan = productsList.all.find((product) => product.value === value);
            let success_manager_id_required = false;
            let business_partner_id_required = false;
            let account_manager_id_required = false;
            if (plan.type === 'retainer') {
                success_manager_id_required = plan.success_manager_id_required;
                business_partner_id_required = plan.business_partner_id_required;
                account_manager_id_required = plan.account_manager_id_required;
            }
            const transformedPlanValues = transformProductPlanToFormValues(plan);
            validatePriceCalculation({
                ...prevFormValues,
                ...transformedPlanValues,
                [`${type}_plan`]: value,
            });
            return {
                ...prevFormValues,
                ...transformedPlanValues,
                success_manager_id_required,
                business_partner_id_required,
                account_manager_id_required,
            };
        });
    });
    const onPlanChange = (index) => (value) => {
        var _a;
        const type = (_a = productsList.all.find(({ value: productId }) => value === productId)) === null || _a === void 0 ? void 0 : _a.type;
        form.fieldProps(`${type}_plan`).onChange(value);
        products.updateProductType({
            index,
            type,
        }, () => setPlanValues({ value, type }));
    };
    const addProduct = (type) => {
        products.addProduct(type);
    };
    const removeProduct = (index) => {
        const { type } = products.removeProduct(index, () => validatePriceCalculation(form.values));
        if (type) {
            const removedProductResetValues = getProductInitialValues(type);
            form.setValues((values) => ({
                ...values,
                ...removedProductResetValues,
            }));
        }
    };
    const resetCreateSubscriptionForm = () => {
        productPrice.reset();
        form.handleReset();
    };
    const submit = (onSuccess) => form.handleSubmit({
        onSuccess: (formValues) => {
            const data = formValuesToPayload({
                formValues,
                invoicePaymentMethod,
                products: products.list,
            });
            return createSubscription
                .mutateAsync({ data, id: ownerId })
                .then(() => {
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
                resetCreateSubscriptionForm();
            })
                .catch((error) => {
                scrollToError();
                propagateBackendValidationErrors(error, form.setErrors);
            });
        },
        onFailure: scrollToError,
    });
    useEffect(() => {
        validatePriceCalculation(form.values);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [invoicePreviewData, setInvoicePreviewData] = useState();
    useEffect(() => {
        if (form.values.payment_method_id === null ||
            !isInvoicePaymentMethodSelected ||
            !form.values.renewal_period) {
            return setInvoicePreviewData(undefined);
        }
        validateForm(form.values, {
            ...products.validators(form.values),
            ...productsSharedValidators,
            ...paymentMethodIdValidator,
        }).then((result) => {
            setInvoicePreviewData(result.id === 'Success'
                ? {
                    product_type: 'subscription',
                    payment_method_id: result.success.payment_method_id,
                    products: products.list
                        .map(({ id, type }) => transformFormValuesToProductInput({
                        id,
                        type: type,
                        values: form.values,
                    }))
                        .filter(Boolean),
                    currency: result.success.currency,
                    renewal_period: result.success.renewal_period,
                    custom_lines: result.success.custom_lines,
                    attention: result.success.attention,
                }
                : undefined);
        });
        // products object is getting re-created after a re-render, leading to eternal setState
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.values, isInvoicePaymentMethodSelected, products.list]);
    return {
        addProduct,
        onPlanChange,
        removeProduct,
        onPaymentMethodChange,
        onPriceRelatedFieldBlur,
        onPriceRelatedFieldChange,
        productsMap,
        price: productPrice.price,
        isInvoicePaymentMethodSelected,
        invoicePreviewData,
        billingDate: getBillingDate(form),
        isLoading: createSubscription.isLoading || productPrice.isLoading,
        submit,
        resetCreateSubscriptionForm,
    };
};
const getBillingDate = (form) => {
    if (form.values.activate_at) {
        return form.values.activate_at;
    }
    if (form.values.is_trial && form.values.trial_ends_at) {
        return form.values.trial_ends_at;
    }
    return null;
};
const formValuesToPayload = ({ formValues: { currency, renewal_period, is_trial, activate_at, trial_ends_at, payment_method_id, po_number, po_expires_at, success_manager_id, business_partner_id, account_manager_id, attention, custom_lines, prevent_sending_invoice, ...values }, invoicePaymentMethod, products, }) => {
    const subscription = {
        currency,
        renewal_period,
        is_trial,
        activate_at: activate_at === null || isToday(activate_at) ? null : activate_at,
    };
    if (is_trial) {
        subscription.trial_ends_at = trial_ends_at;
    }
    const payload = {
        subscription,
        payment_method: {
            payment_method_id,
            po_number: po_number || undefined,
            po_expires_at: po_expires_at || undefined,
        },
        success_team: {
            success_manager_id,
            business_partner_id,
            account_manager_id,
        },
        products: products
            .filter(({ type }) => Boolean(type))
            .map(({ id, type }) => transformFormValuesToProductInput({
            id,
            type: type,
            values,
        })),
    };
    if (payment_method_id === (invoicePaymentMethod === null || invoicePaymentMethod === void 0 ? void 0 : invoicePaymentMethod.id)) {
        payload.invoice_details = { attention, custom_lines, prevent_sending_invoice };
    }
    return payload;
};
