import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { cx, getSelectOptions, tw } from '~/common/utils';
import { CountryFlagLabel } from './Country';
const itemClassName = tw `
  flex flex-col items-center justify-center
  h-7 w-[166px]
  rounded-lg def:border border-solid def:border-greyscale-300
  cursor-pointer
  focus-visible:ring
`;
export const OfficeSelect = (props) => {
    const options = useMemo(() => getSelectOptions(props), [props]);
    return (_jsx("ul", { className: "flex flex-wrap gap-3", children: options.map(({ value, selected, onClick, country, country_code }) => (_jsx("li", { children: _jsx("button", { className: cx(itemClassName, {
                    'border-2 border-secondary-300': selected,
                }), onClick: onClick, children: _jsx("div", { className: cx('px-1 font-brand-b1 def:text-greyscale-600', {
                        'text-secondary-400': selected,
                    }), children: _jsx(CountryFlagLabel, { size: 24, country: country, countryCode: country_code }) }) }, value) }, value))) }));
};
