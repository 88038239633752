import { useMemo } from 'react';
import { useCustomerData, useInitData } from '~/customers/hooks';
export const useCreateSubscriptionData = () => {
    const customer = useCustomerData();
    const init = useInitData();
    const haveIndividualPricing = useMemo(() => JSON.stringify(customer.treatments) !== JSON.stringify(init.default_treatments), [customer.treatments, init.default_treatments]);
    return {
        sales: init.sales,
        supportedCurrencies: init.supported_currencies,
        renewalPeriodOptions: init.product_options.subscription.allowed_renewal_periods,
        projectManagers: init.project_managers,
        designTeams: init.design_teams,
        ownerId: customer.id,
        invoicePaymentMethod: customer.invoicePaymentMethod,
        paymentMethods: customer.payment_methods,
        isCustomerTrusted: customer.billing_settings.add_credits_immediately,
        productsList: init.plans,
        haveIndividualPricing,
    };
};
