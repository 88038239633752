import { validator } from 'formoid';
import { customValidator } from '~/common/utils';
const addPrefixToKeys = (obj, prefix) => {
    const newObj = {};
    for (const key in obj) {
        newObj[`${prefix}_${key}`] = obj[key];
    }
    return newObj;
};
export const initialProductsSharedValues = {
    currency: 'USD',
    renewal_period: 1,
};
export const getProductCommonInitialValues = (type) => ({
    [`${type}_plan`]: null,
    [`${type}_amount`]: 0,
    [`${type}_discount`]: null,
    [`${type}_discount_type`]: 'absolute',
});
export const retainerProductInitialValues = {
    ...getProductCommonInitialValues('retainer'),
    retainer_supports_credits: false,
    retainer_credits: 0,
    retainer_include_credits_oneoff: false,
    retainer_credits_oneoff: 0,
    retainer_credits_oneoff_type: 'absolute',
    retainer_enable_topup: false,
    retainer_supports_daily_points: false,
    retainer_daily_points: 0,
    retainer_enable_addons: false,
    retainer_supports_design_team: false,
    retainer_design_team_id: null,
    retainer_supports_project_manager: true,
    retainer_project_manager_id: null,
    retainer_keep_individual_prices: false,
};
export const techpackProductInitialValues = {
    ...getProductCommonInitialValues('techpack'),
    techpack_enable_custom_fields: false,
    techpack_enable_sso: false,
    techpack_enable_custom_portal: false,
};
export const getProductInitialValues = (type) => {
    switch (type) {
        case 'retainer':
            return retainerProductInitialValues;
        case 'techpack':
            return techpackProductInitialValues;
        default:
            return getProductCommonInitialValues(type);
    }
};
export const subscriptionProductCommonValidators = (type, enabled = true) => (values) => ({
    [`${type}_plan`]: enabled ? customValidator.required() : null,
    [`${type}_amount`]: values[`${type}_plan`] == null
        ? null
        : validator.sequence(customValidator.required(), customValidator.nonNegativeNumber()),
    [`${type}_discount`]: values[`${type}_discount`] !== null && values[`${type}_plan`] !== null
        ? customValidator.bounty(values[`${type}_discount_type`])
        : null,
    [`${type}_discount_type`]: null,
});
export const subscriptionRetainerProductFormValidators = (values, enabled = true) => ({
    ...subscriptionProductCommonValidators('retainer', enabled)(values),
    retainer_supports_credits: null,
    retainer_credits: enabled && values.retainer_supports_credits
        ? validator.sequence(customValidator.required(), customValidator.nonNegativeInteger())
        : null,
    retainer_include_credits_oneoff: null,
    retainer_credits_oneoff: enabled
        ? customValidator.bounty(values.retainer_credits_oneoff_type)
        : null,
    retainer_credits_oneoff_type: null,
    retainer_enable_topup: null,
    retainer_supports_daily_points: null,
    retainer_daily_points: enabled && values.retainer_supports_daily_points
        ? validator.sequence(customValidator.required(), customValidator.nonNegativeInteger())
        : null,
    retainer_enable_addons: null,
    retainer_supports_design_team: null,
    retainer_design_team_id: enabled && values.retainer_supports_daily_points ? customValidator.required() : null,
    retainer_supports_project_manager: null,
    retainer_project_manager_id: enabled ? customValidator.required() : null,
    retainer_keep_individual_prices: null,
});
export const subscriptionTechpackProductFormValidators = (values, enabled = true) => ({
    ...subscriptionProductCommonValidators('techpack', enabled)(values),
    techpack_enable_custom_fields: null,
    techpack_enable_sso: null,
    techpack_enable_custom_portal: null,
});
export const productsSharedValidators = {
    currency: null,
    renewal_period: customValidator.required(),
};
export const getUUIDValidators = ({ type, values, enabled, }) => {
    switch (type) {
        case 'retainer':
            return subscriptionRetainerProductFormValidators(values, enabled);
        case 'techpack':
            return subscriptionTechpackProductFormValidators(values, enabled);
        default:
            return subscriptionProductCommonValidators(type, enabled)(values);
    }
};
const transformRetainerProductToFormValues = (product) => {
    var _a, _b;
    return ({
        ...retainerProductInitialValues,
        retainer_plan: product.plan.value,
        retainer_amount: product.amount,
        retainer_supports_credits: product.credits !== null,
        retainer_credits: (_a = product.credits) !== null && _a !== void 0 ? _a : 0,
        retainer_enable_topup: product.enable_topup,
        retainer_supports_daily_points: product.daily_points !== null,
        retainer_daily_points: (_b = product.daily_points) !== null && _b !== void 0 ? _b : 0,
        retainer_enable_addons: product.enable_addons,
        retainer_supports_design_team: product.daily_points !== null,
        retainer_keep_individual_prices: product.keep_individual_prices,
    });
};
const transformTechpackProductToFormValues = (product) => ({
    ...techpackProductInitialValues,
    techpack_plan: product.plan.value,
    techpack_amount: product.amount,
    techpack_enable_custom_fields: Boolean(product.enable_custom_fields),
    techpack_enable_custom_portal: Boolean(product.enable_custom_portal),
    techpack_enable_sso: Boolean(product.enable_sso),
});
export const transformProductToFormValues = (product) => {
    if (product.plan.type === 'retainer') {
        return transformRetainerProductToFormValues(product);
    }
    return transformTechpackProductToFormValues(product);
};
const transformRetainerProductPlanToFormValues = (plan) => addPrefixToKeys(plan, 'retainer');
const transformTechpackProductPlanToFormValues = (plan) => ({
    techpack_amount: plan.amount,
    techpack_enable_custom_fields: plan.supports_custom_fields,
    techpack_enable_custom_portal: plan.supports_custom_portal,
    techpack_enable_sso: plan.supports_sso,
});
export const transformProductPlanToFormValues = (plan) => {
    switch (plan.type) {
        case 'retainer':
            return transformRetainerProductPlanToFormValues(plan);
        case 'techpack':
            return transformTechpackProductPlanToFormValues(plan);
    }
};
const transformCommonFormValues = ({ id, type, values, }) => ({
    id: id !== null && id !== void 0 ? id : null,
    product_plan_id: values[`${type}_plan`],
    amount: values[`${type}_amount`],
    discount: values[`${type}_discount`],
    discount_type: values[`${type}_discount_type`],
});
const transformRetainerFormValuesToProductInput = ({ id, values, }) => ({
    ...transformCommonFormValues({ id, type: 'retainer', values: values }),
    credits: values.retainer_supports_credits ? values.retainer_credits : 0,
    daily_points: values.retainer_supports_daily_points ? values.retainer_daily_points : 0,
    credits_oneoff: values.retainer_credits_oneoff,
    credits_oneoff_type: values.retainer_credits_oneoff_type,
    enable_topup: values.retainer_enable_topup,
    enable_addons: values.retainer_enable_addons,
    keep_individual_prices: values.retainer_keep_individual_prices,
    design_team_id: values.retainer_design_team_id,
    project_managers: values.retainer_project_manager_id
        ? [values.retainer_project_manager_id]
        : [],
});
const transformTechpackFormValuesToProductInput = ({ id, values, }) => ({
    ...transformCommonFormValues({ id, type: 'techpack', values: values }),
    enable_custom_fields: values.techpack_enable_custom_fields,
    enable_sso: values.techpack_enable_sso,
    enable_custom_portal: values.techpack_enable_custom_portal,
});
export const transformFormValuesToProductInput = ({ id, type, values, }) => {
    switch (type) {
        case 'retainer':
            return transformRetainerFormValuesToProductInput({
                id,
                values: values,
            });
        case 'techpack':
            return transformTechpackFormValuesToProductInput({
                id,
                values: values,
            });
        default:
            return null;
    }
};
