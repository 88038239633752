import { useMutation } from '@tanstack/react-query';
import { useIdParam } from '~/common/hooks';
import { httpClient } from '~/common/utils';
import { AnnotationComment } from '~/orders/domain';
import { qk } from '~/root/query-keys';
export function useAddAnnotationComment() {
    const id = useIdParam();
    return useMutation({
        mutationKey: qk.addAnnotationComment(id),
        mutationFn: ({ annotationId, comment }) => httpClient.post(`/v1/staff/orders/${id}/annotations/${annotationId}/comments`, {
            data: { comment },
            decoder: AnnotationComment,
        }),
    });
}
