import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { format } from 'date-fns';
import { useMemo } from 'react';
import { Chart } from 'react-chartjs-2';
import { Link } from '~/common/components';
import { barChartScaleParams, getBarParams, LegendItem, registerChartJSPlugins, tooltipCallbacks, tooltipParams, } from '~/common/other';
import { formatDayFullMonthYear } from '~/common/utils';
import { useCapacityBreakdownData, useSubscriptionData } from '~/subscriptions/hooks';
registerChartJSPlugins();
const getChartData = ({ daily_capacity, days }) => {
    const chartData = {
        daily_capacity,
        used_capacity: [],
        remaining_capacity: [],
        over_capacity: [],
        weekend: new Set(),
        labels: [],
        tooltips: [],
    };
    days.forEach((currentItem, index) => {
        const notWorking = currentItem.is_weekend || currentItem.is_holiday;
        chartData.used_capacity.push(notWorking ? null : currentItem.used_capacity);
        chartData.remaining_capacity.push(notWorking ? null : currentItem.remaining_capacity);
        chartData.over_capacity.push(notWorking ? null : currentItem.over_capacity);
        chartData.labels.push(String(currentItem.date.getDate()));
        chartData.tooltips.push(formatDayFullMonthYear(currentItem.date));
        if (currentItem.is_weekend || currentItem.is_holiday) {
            chartData.weekend.add(index);
        }
    });
    return chartData;
};
const getChartProps = (chartData) => ({
    type: 'bar',
    plugins: [
        {
            id: 'arbitraryLine',
            afterDraw: (chart, _) => {
                const { ctx, chartArea: { left, width }, scales: { y }, } = chart;
                ctx.save();
                ctx.fillStyle = '#46B688';
                ctx.fillRect(left, y.getPixelForValue(chartData.daily_capacity), width, 1);
                ctx.restore();
            },
        },
    ],
    options: {
        plugins: {
            title: {
                display: false,
            },
            tooltip: {
                callbacks: tooltipCallbacks(chartData.tooltips),
                ...tooltipParams,
            },
            legend: { display: false },
        },
        ...barChartScaleParams(chartData),
    },
    data: {
        labels: chartData.labels,
        datasets: [
            {
                label: 'Used capacity',
                backgroundColor: '#5F9AF2',
                data: chartData.used_capacity,
                ...getBarParams({ barThickness: 16 }),
            },
            {
                label: 'Remaining capacity',
                backgroundColor: '#EDE9F9',
                data: chartData.remaining_capacity,
                ...getBarParams({ barThickness: 16 }),
            },
            {
                label: 'Over capacity',
                backgroundColor: '#F86666',
                data: chartData.over_capacity,
                ...getBarParams({ barThickness: 16 }),
            },
        ],
    },
});
export const CapacityBreakdown = () => {
    const subscription = useSubscriptionData();
    const breakdown = useCapacityBreakdownData();
    const chartProps = useMemo(() => {
        if (!breakdown) {
            return null;
        }
        return getChartProps(getChartData(breakdown));
    }, [breakdown]);
    if (!chartProps || !breakdown) {
        return null;
    }
    return (_jsxs("div", { className: "bg-greyscale-0 rounded p-2", children: [_jsxs("div", { className: "flex items-center justify-between h-4 gap-1 font-brand-t1r", children: [_jsxs("span", { children: ["Capacity Overview for ", format(breakdown.days[0].date, 'MMMM yyyy'), ", "] }), _jsx("span", { className: "text-greyscale-500", children: "points" }), subscription.capacity_overview_link && (_jsx(Link, { external: true, newTab: true, size: "m-bold", className: "ml-auto self-start", href: subscription.capacity_overview_link, children: "See full overview" }))] }), _jsxs("div", { className: "flex items-center gap-5 py-2", children: [_jsx(LegendItem, { label: "Daily capacity", className: "bg-primary-300" }), _jsx(LegendItem, { label: "Used capacity", className: "bg-secondary-300" }), _jsx(LegendItem, { label: "Remaining capacity", className: "bg-info-50" }), _jsx(LegendItem, { label: "Over capacity", className: "bg-danger-400" })] }), _jsx("div", { className: "relative h-[216px]", children: _jsx(Chart, { ...chartProps }) })] }));
};
