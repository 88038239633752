import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InputField, Select, SelectField } from '~/common/components';
import { SubscriptionProductTableViewItem, SubscriptionSection } from '~/customers/Customer';
export const SubscriptionPlan = ({ form: { fieldProps }, products, currency, supportedCurrencies, onPriceRelatedFieldChange, }) => {
    const onPriceChange = (value) => {
        onPriceRelatedFieldChange('amount')(value);
        if (!fieldProps('credits').touched) {
            fieldProps('credits').onChange(value);
        }
    };
    return (_jsx(SubscriptionSection, { title: "Subscription plan", children: _jsxs("div", { className: "grid grid-cols-[284px_262px_204px] gap-y-1", children: [_jsx(SubscriptionProductTableViewItem, { title: "Subscription product", children: _jsx(SelectField, { ...fieldProps('subscription_product_id'), size: "small", disabled: true, options: products }) }), _jsx(SubscriptionProductTableViewItem, { title: "Price", children: _jsxs("div", { className: "flex gap-x-[4px]", children: [_jsx(InputField, { ...fieldProps('amount'), onChange: onPriceChange, type: "number", size: "small" }), _jsx(Select, { size: "small", onChange: () => { }, value: currency, options: supportedCurrencies, disabled: true, className: "w-10 shrink-0" })] }) }), _jsx(SubscriptionProductTableViewItem, { title: "Credits", className: "mr-[0px]", children: _jsx(InputField, { ...fieldProps('credits'), type: "number", size: "small" }) })] }) }));
};
