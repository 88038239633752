import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Button, LayoutHeader, LayoutTitle, useModalContext, withModalContext, } from '~/common/components';
import { useCurrentUserData } from '~/root';
import { CreateOffice } from './CreateOffice';
export const Header = withModalContext(() => {
    const { permissions } = useCurrentUserData();
    const { modalOpener } = useModalContext();
    return (_jsxs(LayoutHeader, { className: "flex justify-between items-center", children: [_jsx(LayoutTitle, { children: "Offices" }), permissions.officesManage && (_jsx(Button, { onClick: modalOpener(CreateOffice), color: "primary", icon: faPlus, className: "ml-2", children: "Add office" }))] }));
});
