import { useQuery } from '@tanstack/react-query';
import { assertQueryData, httpClient } from '~/common/utils';
import { Timezones } from '~/root';
import { qk } from '~/root/query-keys';
export const useTimezones = () => {
    return useQuery({
        queryKey: qk.timezones,
        queryFn: () => httpClient.get('v1/timezones', { decoder: Timezones }),
    });
};
export const useTimezonesData = () => assertQueryData(useTimezones());
