import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from '~/common/components';
import { useCustomerData } from '~/customers/hooks';
import { SUBSCRIPTIONS_URL } from '~/subscriptions/hooks';
import { SubscriptionInfo } from './SubscriptionInfo';
export const Header = () => {
    const { subscription } = useCustomerData();
    if (!subscription)
        return null;
    return (_jsxs("div", { className: "flex items-center justify-between px-3 py-2 bg-greyscale-50", children: [_jsx(SubscriptionInfo, { products: subscription.products, status: subscription.status, dates: subscription }), _jsx(Link.Button, { to: `${SUBSCRIPTIONS_URL}/${subscription.id}/customer/${subscription.owner_id}`, children: "View" })] }));
};
