import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge, Button, Link, PageDetailsCard, useModalContext } from '~/common/components';
import { useOrganizationData } from '~/organizations/hooks';
import { useCurrentUserData } from '~/root';
import { EditPortalAndDomainsInfoPopup } from './EditPortalAndDomainsInfoPopup';
export const CustomPortalAndDomainsInfo = () => {
    const { permissions } = useCurrentUserData();
    const organization = useOrganizationData();
    const { modalOpener } = useModalContext();
    return (_jsx(PageDetailsCard, { label: "Allowed Domains and custom portal", actionButton: permissions.organizationsUpdate && (_jsx(Button, { color: "grey", onClick: modalOpener(EditPortalAndDomainsInfoPopup), children: "Edit" })), children: _jsxs("div", { className: "grid grid-cols-[100px_1fr] gap-x-1 gap-y-2 text-greyscale-500", children: [_jsx("div", { className: "font-brand-t4m", children: "Domains" }), _jsxs("div", { className: "min-w-0", children: [_jsx("div", { className: "flex gap-1 flex-wrap", children: organization.allowed_domains.map((domain) => (_jsx(Badge, { color: "grey", className: "block truncate", children: domain.value }, domain.value))) }), organization.autoassign_on_signup && (_jsx("p", { className: "font-brand-c1r mt-1", children: "Enabled auto-adding new customers matching domains" }))] }), _jsx("div", { className: "text-greyscale-500 font-brand-t4m", children: "Portal" }), organization.custom_portal_enabled ? (_jsxs("div", { className: "min-w-0", children: [organization.custom_portal_url && (_jsx(Link, { newTab: true, external: true, size: "m", href: organization.custom_portal_url, children: organization.name })), organization.autoassign_on_new_order && (_jsx("p", { className: "font-brand-c1r mt-1", children: "Enabled auto-adding customers while placing an order using it" }))] })) : (_jsx("span", { className: "font-brand-b1", children: "\u2014" }))] }) }));
};
