import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { faCheckDouble, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { array, nonEmptyArray, option } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { memo, useMemo } from 'react';
import { IconButton, Link, Show } from '~/common/components';
import { cx } from '~/common/utils';
import { ORDERS_URL } from '~/orders';
import { useUpdateNotifications } from './hooks';
import { Notification } from './Notification';
import { fromParsedNotifications, getId } from './utils';
export const OrderNotifications = memo(({ id, notifications, onClose }) => {
    const [{ read }, isLoading] = useUpdateNotifications();
    const hasUnread = useMemo(() => {
        return fromParsedNotifications(notifications).filter(({ is_read }) => !is_read).length > 1;
    }, [notifications]);
    const handleRead = () => {
        const payload = pipe(notifications, fromParsedNotifications, array.filterMap((notification) => pipe(notification, option.fromPredicate(({ is_read }) => !is_read), option.map(({ id }) => id))), nonEmptyArray.fromArray, option.toNullable);
        if (payload)
            read(payload);
    };
    return (_jsxs("div", { className: "space-y-1 border-b border-b-greyscale-300 last:border-0 p-2", children: [_jsxs("div", { className: "flex items-center justify-between", children: [_jsxs(Link, { size: "m-bold", color: "grey", to: `${ORDERS_URL}/${id}`, onClick: onClose, children: ["Order ", id] }), _jsx(Show, { if: hasUnread, children: _jsx(IconButton, { size: "s", "data-tt": "Mark as read", className: cx('mx-1', isLoading && 'animate-spin'), icon: isLoading ? faSpinnerThird : faCheckDouble, onClick: handleRead }) })] }), notifications.map((notification) => (_jsx(Notification, { notification: notification, onClose: onClose }, getId(notification))))] }));
});
