import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faLink, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useForm } from 'formoid';
import { Banner, Button, InputField, Popover } from '~/common/components';
import { customValidator } from '~/common/utils';
import { LinkItem } from '~/orders/Order';
export const RevisionLink = ({ link, editable, addLinkToRevision, removeLinkFromRevision, }) => {
    if (!link) {
        return (_jsx(Popover, { className: "flex flex-column p-2 gap-y-2 w-[360px]", placement: "bottom-start", content: (props) => _jsx(PopoverContent, { ...props, onSubmit: addLinkToRevision }), trigger: (props) => (_jsx(Button, { ...props, size: "xs", color: "text-secondary", icon: faLink, children: "Add link" })) }));
    }
    return (_jsxs("div", { className: "flex items-center gap-1", children: [_jsx(LinkItem, { link: link }), editable && (_jsx(Button.Icon, { color: "grey-dark", icon: faTimes, onClick: removeLinkFromRevision }))] }));
};
function PopoverContent({ onClose, onSubmit, }) {
    const { fieldProps, handleSubmit } = useForm({
        initialValues: {
            url: '',
        },
        validationStrategy: 'onSubmit',
        validators: () => ({
            url: customValidator.link('Please enter a link'),
        }),
    });
    const submit = () => {
        handleSubmit(async (values) => {
            onSubmit(values.url);
            onClose();
        });
    };
    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            submit();
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Banner, { color: "info", children: "Please, make sure that the customer has an access to the file" }), _jsx(InputField, { ...fieldProps('url'), onKeyDown: handleEnter, placeholder: "Add link here", size: "small", type: "text" }), _jsxs("div", { className: "flex items-center justify-end gap-2", children: [_jsx(Button, { color: "grey", onClick: onClose, children: "Cancel" }), _jsx(Button, { color: "secondary", onClick: submit, children: "Save" })] })] }));
}
