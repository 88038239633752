import { useMemo, useRef } from 'react';
export function usePatchedBlur({ blurCallback, eq, fieldProps }) {
    const previousValue = useRef(fieldProps.value);
    return useMemo(() => {
        return {
            ...fieldProps,
            onBlur: () => {
                if (!eq.equals(fieldProps.value, previousValue.current)) {
                    blurCallback();
                    previousValue.current = fieldProps.value;
                }
                fieldProps.onBlur();
            },
        };
    }, [blurCallback, eq, fieldProps]);
}
