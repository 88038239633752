const isOption = (sort) => typeof sort === 'string';
export const getSort = (sort) => {
    if (isOption(sort)) {
        return {
            option: sort,
            order: 'asc',
        };
    }
    if (sort.order === 'desc') {
        return undefined;
    }
    return { ...sort, order: 'desc' };
};
const isValueString = (value) => typeof value === 'string';
const isValueBoolean = (value) => value === 'true' || value === 'false';
const isValueNumber = (value) => !isNaN(+value);
const isValueAnArray = (value) => {
    return value.startsWith('[') && value.endsWith(']');
};
const normalizeValue = (value) => {
    if (!isValueString(value))
        throw new TypeError(`Query normalization failed. Value: ${value} of type ${typeof value} must be a string!`);
    if (isValueNumber(value)) {
        return Number(value);
    }
    if (isValueBoolean(value))
        return JSON.parse(value);
    return value;
};
const isKeyBracketed = (key) => key.includes('[') && key.endsWith(']');
const getArrayValue = (value) => {
    return value.slice(1, -1).split(',').map(normalizeValue);
};
const getPrimitiveValue = (value) => {
    return normalizeValue(value);
};
export const parseQuery = (search) => {
    const searchParams = new URLSearchParams(search);
    const searchParamsMap = { filter: {} };
    for (const [spKey, spValue] of searchParams.entries()) {
        const parsedValue = isValueAnArray(spValue)
            ? getArrayValue(spValue)
            : getPrimitiveValue(spValue);
        if (isKeyBracketed(spKey)) {
            const [category, key] = spKey.slice(0, -1).split('[');
            if (category === 'filter') {
                if (key === 'sort') {
                    const [option, order] = spValue.split(':');
                    searchParamsMap.sort = { option, order };
                }
                else {
                    if (!searchParamsMap.filter) {
                        searchParamsMap.filter = {};
                    }
                    searchParamsMap.filter[key] = parsedValue;
                }
            }
        }
        else
            searchParamsMap[spKey] = parsedValue;
    }
    return searchParamsMap;
};
export const getFlatQuery = (parsedQuery) => {
    var _a;
    const searchParams = {};
    for (const [k, v] of Object.entries((_a = parsedQuery.filter) !== null && _a !== void 0 ? _a : {})) {
        if (v != null && v !== '') {
            if (Array.isArray(v)) {
                if (v.length) {
                    searchParams[`filter[${k}]`] = `[${v.join(',')}]`;
                }
                continue;
            }
            searchParams[`filter[${k}]`] = String(v);
        }
    }
    if (parsedQuery.sort) {
        searchParams['filter[sort]'] = `${String(parsedQuery.sort.option)}:${parsedQuery.sort.order}`;
    }
    if (parsedQuery.page != null && parsedQuery.page !== 1) {
        searchParams.page = String(parsedQuery.page);
    }
    if (parsedQuery.limit != null) {
        searchParams.limit = String(parsedQuery.limit);
    }
    return searchParams;
};
export const encodeQueryString = (parsedQuery) => {
    return `?${Object.entries(getFlatQuery(parsedQuery))
        .map(([k, v]) => `${k}=${v}`)
        .join('&')}`;
};
export const getSearchFilterDataFromOptions = (options, filterMap) => {
    var _a, _b;
    const selectedFilter = (_a = options.find((option) => filterMap === null || filterMap === void 0 ? void 0 : filterMap[option])) !== null && _a !== void 0 ? _a : options[0];
    return { selectedFilter, value: String((_b = filterMap === null || filterMap === void 0 ? void 0 : filterMap[selectedFilter]) !== null && _b !== void 0 ? _b : '') };
};
export const getFilterProps = (filters, queryParamsFilter, onFilterChange) => (filter) => ({
    options: filters[filter],
    value: queryParamsFilter[filter],
    onChange: (value) => onFilterChange(filter, value),
});
export const stringify = (value) => String(value !== null && value !== void 0 ? value : '');
