import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, } from 'react';
import { cx } from '~/common/utils';
// TODO make a hook instead of component
// TODO: Handle maxSize of uploaded files
export const FileInput = ({ children, wrapperClassName, labelClassName, dragClassName, onChoose, onClick, outsideChildren, ...props }) => {
    const [isDragging, setIsDragging] = useState(false);
    const handleChange = (event) => {
        const files = event.currentTarget.files;
        if (files) {
            onChoose(files);
        }
    };
    const handleClick = (event) => {
        // Fix of browser default behaviour
        // More info: https://github.com/danialfarid/ng-file-upload/issues/372
        event.currentTarget.value = '';
        if (onClick) {
            onClick(event);
        }
    };
    const handleDrop = (event) => {
        event.preventDefault();
        setIsDragging(event.type === 'dragover');
        if (event.type === 'drop')
            onChoose(event.dataTransfer.files);
    };
    return (_jsxs("div", { className: cx(wrapperClassName, isDragging && dragClassName), onDragLeave: handleDrop, onDragOver: handleDrop, onDrop: handleDrop, children: [_jsxs("label", { className: labelClassName, children: [_jsx("input", { ...props, className: "hidden", onChange: handleChange, onClick: handleClick, type: "file" }), children instanceof Function ? children(isDragging) : children] }), outsideChildren] }));
};
