import { faBriefcase, faCode, faFunnelDollar, faPalette } from '@fortawesome/pro-regular-svg-icons';
import { format } from 'date-fns';
import { validator } from 'formoid';
import { getTimezonedLocalTime } from '~/common/utils';
export const typeToIcon = {
    design: faPalette,
    development: faCode,
    business: faBriefcase,
    sales: faFunnelDollar,
};
export const getOfficeTimeInfo = (timezone, opens, closes) => {
    const timezonedDate = getTimezonedLocalTime(timezone);
    const [hours, minutes] = format(timezonedDate, 'k:m').split(':');
    const timeNumber = Number(hours) + Number(minutes) / 60;
    return {
        isOpen: Number(opens) < timeNumber && timeNumber < Number(closes),
        currentTime: format(timezonedDate, 'iii k:mm').toLowerCase(),
    };
};
export const getAddressAndPostalCodeFromString = (str) => {
    const split = (str !== null && str !== void 0 ? str : '')
        // in case someone forgets to add space after ,
        .replace(',', ', ')
        // collapse multiple spaces to 1
        .replace(/\s+/, ' ')
        .trim()
        .split(', ');
    if (split.length > 1) {
        return {
            postal_code: split[split.length - 1],
            address: split.slice(0, split.length - 1).join(),
        };
    }
    return null;
};
export const addressValidator = () => {
    return validator.fromPredicate((value) => getAddressAndPostalCodeFromString(value) !== null, 'Address should match format: street, building, zip code');
};
export const workingHoursValidator = () => {
    return validator.fromPredicate(([start, end]) => Math.abs(start - end) > 5, 'Working hours should be more than 5');
};
export const transformFormValues = (values) => {
    const { workingHours, ...otherValues } = values;
    const patchedValues = otherValues;
    if (patchedValues.region === '') {
        patchedValues.region = null;
    }
    patchedValues.opens_at = Math.min(...workingHours);
    patchedValues.closes_at = Math.max(...workingHours);
    Object.assign(patchedValues, getAddressAndPostalCodeFromString(values.address));
    return patchedValues;
};
