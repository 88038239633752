import { useLayoutEffect, useState } from 'react';
export const useLingeringValue = (value, delay) => {
    const [lingering, setLingering] = useState();
    useLayoutEffect(() => {
        if (value === undefined) {
            setTimeout(() => setLingering(undefined), delay);
        }
    }, [delay, value]);
    useLayoutEffect(() => {
        if (value !== undefined) {
            setLingering(value);
        }
    }, [value]);
    return lingering;
};
