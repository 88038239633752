import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, PageDetailsCard, useModalContext } from '~/common/components';
import { useCustomerData } from '~/customers/hooks';
import { useCurrentUserData } from '~/root';
import { DedicatedTeamRow } from '../DedicatedTeamRow';
import { MultipleInnerUsersRow } from '../InnerUserRow';
import { EditDedicatedTeam } from './EditDedicatedTeam';
export const DedicatedTeam = () => {
    const { dedicated_team, project_managers } = useCustomerData();
    const { permissions } = useCurrentUserData();
    const { modalOpener } = useModalContext();
    if (!project_managers.length && !dedicated_team.design_team) {
        return null;
    }
    const designTeam = dedicated_team.design_team;
    return (_jsxs(PageDetailsCard, { label: "Dedicated team", actionButton: permissions.customersUpdate && (_jsx(Button, { onClick: modalOpener(EditDedicatedTeam), color: "grey", children: "Edit" })), bodyClassName: "grid grid-cols-[1fr,1fr] gap-x-1 gap-y-2 font-brand-b1 py-3", children: [_jsx(MultipleInnerUsersRow, { label: "Project manager", users: project_managers, placement: "left" }), _jsx(DedicatedTeamRow, { label: "Design team", team: designTeam })] }));
};
