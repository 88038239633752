import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { faReceipt } from '@fortawesome/pro-regular-svg-icons';
import { Link, useModalContext } from '~/common/components';
import { cx } from '~/common/utils';
import { useOrderData } from '~/orders/hooks';
import { EditPriceInfo, ManualCharge, SectionCell, SubSection } from '~/orders/Order';
import { discountToString } from '~/orders/utils';
export const Price = () => {
    var _a;
    const { modalOpener } = useModalContext();
    const { id, payment } = useOrderData();
    return (_jsx(SubSection, { title: "Price", onEdit: modalOpener(EditPriceInfo, { id, payment }), disabledReason: payment.status === 'paid'
            ? 'The order has been paid, this section cannot be modified'
            : undefined, children: _jsxs("div", { className: "flex justify-between space-x-4 w-full", children: [_jsxs("div", { className: "flex flex-col space-y-1 w-1/2", children: [_jsx(SectionCell, { title: "Order price", children: _jsxs("span", { className: "font-brand-t4m text-greyscale-400", children: [payment.currency, " ", payment.unit] }) }), _jsx(SectionCell, { title: "Coupon", children: _jsx("span", { className: "font-brand-t4m text-greyscale-400", children: (_a = payment.coupon) !== null && _a !== void 0 ? _a : '—' }) }), _jsx(SectionCell, { title: "Discount", children: _jsx("span", { className: "font-brand-t4m text-greyscale-400", children: payment.discount ? discountToString(payment.discount, payment.currency) : '—' }) }), _jsx(SectionCell, { title: "VAT", children: _jsxs("span", { className: "font-brand-t4m text-greyscale-400", children: [payment.currency, " ", payment.vat] }) }), _jsx(SectionCell, { title: "Total", children: _jsxs("span", { className: "font-brand-t4m", children: [payment.currency, " ", payment.amount] }) })] }), _jsxs("div", { className: "flex flex-col gap-1 w-1/2", children: [_jsx(SectionCell, { title: "Payment status", children: _jsx("span", { className: cx('capitalize font-brand-t4m', payment.status === 'unpaid' ? 'text-danger-400' : 'text-primary-300'), children: payment.status }) }), payment.invoice && (_jsx(Link, { external: true, newTab: true, size: "m-bold", className: "self-start", href: payment.invoice, icon: faReceipt, children: "Invoice" })), _jsx(SectionCell, { title: "7d payment trigger", children: _jsx("span", { className: cx('font-brand-t4m', payment.autocharge ? 'text-primary-300' : 'text-danger-400'), children: payment.autocharge ? 'On' : 'Off' }) }), _jsx(ManualCharge, {})] })] }) }));
};
