import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { IconBox } from '~/common/components';
import { cx, getSelectOptions, tw } from '~/common/utils';
const itemClassName = tw `
  flex flex-col items-center justify-center
  h-[94px] w-[166px]
  rounded-lg def:border border-solid def:border-greyscale-300
  cursor-pointer
  focus-visible:ring
`;
const mapOptions = (options, nameToIcon) => {
    const map = options.reduce((map, option) => {
        map[option.value] = option;
        return map;
    }, {});
    const result = [];
    for (const key in nameToIcon) {
        const option = map[key];
        if (option) {
            result.push(option);
        }
    }
    return result;
};
export const BlockSelect = ({ options, value, onChange, nameToIcon, }) => {
    const mappedOptions = useMemo(() => getSelectOptions({ value, onChange, options: mapOptions(options, nameToIcon) }), [nameToIcon, onChange, options, value]);
    return (_jsx("ul", { className: "flex flex-wrap gap-3", children: mappedOptions.map(({ name, value, selected, onClick }) => (_jsx("li", { children: _jsxs("button", { className: cx(itemClassName, {
                    'border-2 border-secondary-300': selected,
                }), onClick: onClick, children: [_jsx(IconBox, { size: "l", className: cx(selected && 'text-secondary-300'), icon: nameToIcon[value] }), _jsx("div", { className: cx('font-brand-b1 def:text-greyscale-600 mt-1 capitalize', {
                            'text-secondary-400': selected,
                        }), children: name })] }, value) }, value))) }));
};
