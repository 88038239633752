import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LayoutContent } from '~/common/components';
import { usePayments, usePaymentsInit } from '../hooks';
import { Header } from './Header';
import { PaymentsTable } from './PaymentsTable';
export const Payments = () => {
    const init = usePaymentsInit();
    const payments = usePayments();
    if (!payments.data || !init.data) {
        return _jsx(LayoutContent, { loading: true });
    }
    return (_jsxs("div", { children: [_jsx(Header, { filters: init.data.filter_options }), _jsx(PaymentsTable, { data: payments.data })] }));
};
