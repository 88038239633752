import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu, PageDetailsCard, useModalContext } from '~/common/components';
import { cx } from '~/common/utils';
import { NegativePromptContent, useCurrentUserData, usePrompt } from '~/root';
import { useDeleteUploadWindowMutation, useSubscriptionData } from '~/subscriptions/hooks';
import { UploadWindowBanner } from './Banner';
import { EditUploadWindow } from './EditUploadWindow';
const header = 'font-brand-t5 text-greyscale-500';
const useDeletePrompt = () => {
    const prompt = usePrompt();
    return (onSubmit) => {
        prompt({
            content: (_jsx(NegativePromptContent, { heading: "Delete upload window?", description: "You will not be able to restore it" })),
            submitText: 'Yes, delete',
            cancelText: 'Cancel',
            negativeAction: true,
            onSubmit,
        });
    };
};
export const UploadWindow = () => {
    const { upload_window } = useSubscriptionData();
    const { permissions } = useCurrentUserData();
    const { modalOpener } = useModalContext();
    const deletePrompt = useDeletePrompt();
    const { mutateAsync, isLoading } = useDeleteUploadWindowMutation();
    const deleteUploadWindow = () => {
        deletePrompt(mutateAsync);
    };
    if (!upload_window) {
        return null;
    }
    return (_jsxs(PageDetailsCard, { label: "Upload window", actionButton: permissions.subscriptionsUpdate && (_jsxs(Menu, { children: [_jsx(Menu.Item, { onClick: modalOpener(EditUploadWindow), children: "Edit" }), _jsx(Menu.Item, { onClick: deleteUploadWindow, children: "Delete" })] })), className: cx({ 'opacity-[0.3]': isLoading }), bodyClassName: "flex flex-col gap-y-2 pb-3", children: [_jsxs("p", { className: "font-brand-b1", children: ["Customer time zone - ", _jsx("span", { className: "font-brand-t3", children: upload_window.timezone })] }), _jsxs("div", { className: "grid grid-cols-[auto_1fr] gap-x-4 gap-y-[4px] font-brand-b2r", children: [_jsx("span", { className: header, children: "Submitting" }), _jsx("span", { className: header, children: "Deliver by" }), _jsxs("span", { children: ["before ", _jsx("strong", { children: upload_window.submitted_before_hour })] }), _jsxs("span", { children: [_jsx("strong", { children: upload_window.submitted_at_hour }), " on the", ' ', _jsx("strong", { children: upload_window.on_day === 'today' ? 'same' : 'next' }), " business day"] })] }), _jsx(UploadWindowBanner, {})] }));
};
