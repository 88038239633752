import { useCompositeForm } from 'formoid';
import { useCallback, useMemo } from 'react';
import { customValidator } from '~/common/utils';
import { useInitData, useOrderData, useUpdateOrder } from '~/orders/hooks';
import { getAssignedDesignTeam, getSelectedManger, tagTeamValidator } from '~/orders/utils';
export function useEditDesignTeamInfo(close) {
    const { managers, teams } = useInitData();
    const { pm, team, tag_teams } = useOrderData();
    const updateOrder = useUpdateOrder();
    const manager = getSelectedManger(managers, pm);
    const selectedTeam = getAssignedDesignTeam(teams, team);
    const tagTeamsInitialValues = useMemo(() => tag_teams.map(({ id, designers }) => ({
        team: id,
        designers: designers.map(({ value }) => value),
    })), [tag_teams]);
    const initialValues = useMemo(() => ({
        manager: manager ? manager.value : null,
        team: selectedTeam ? selectedTeam.value : null,
        designers: selectedTeam ? selectedTeam.designers.map(({ value }) => value) : [],
    }), [manager, selectedTeam]);
    const { form, fieldArray, handleSubmit, isSubmitting } = useCompositeForm({
        form: {
            initialValues,
            validationStrategy: 'onBlur',
            validators: () => ({
                manager: customValidator.required(),
                team: customValidator.required(),
                designers: null,
            }),
        },
        fieldArray: {
            initialValues: { tagTeams: tagTeamsInitialValues },
            validators: ({ fieldArray }) => ({
                tagTeams: {
                    team: tagTeamValidator(fieldArray.tagTeams),
                    designers: null,
                },
            }),
            validationStrategy: 'onBlur',
        },
    });
    const submit = () => handleSubmit(({ form, fieldArray }) => {
        const payload = {
            pm: form.manager,
            design_team: { id: form.team, designers: form.designers },
            tag_teams: fieldArray.tagTeams.map(({ team, designers }) => ({ id: team, designers })),
        };
        return updateOrder(payload).then(close);
    });
    const handleDesignTeamChange = (value) => {
        if (form.values.team === value)
            return;
        form.fieldProps('team').onChange(value);
        form.fieldProps('designers').onChange([]);
        fieldArray.tagTeams.groups.forEach(({ team }, index) => {
            if (team.value === value)
                fieldArray.tagTeams.remove(index);
        });
    };
    const getDesignerOptions = useCallback((designTeamId) => {
        if (designTeamId) {
            const team = teams.find(({ value }) => value === designTeamId);
            return team ? team.designers : [];
        }
        return [];
    }, [teams]);
    const availableTagTeams = useMemo(() => teams.filter((team) => team.value !== form.values.team), [teams, form.values.team]);
    return {
        fieldArray,
        form,
        handleDesignTeamChange,
        handleSubmit: submit,
        isSubmitting,
        options: {
            getDesignerOptions,
            managers,
            tagTeams: availableTagTeams,
            teams,
        },
    };
}
