import { Toast } from '../components';
export const notifyBackendError = () => {
    Toast.error({ title: 'Backend error', subTitle: 'Check console' });
};
export const notifyValidationError = () => {
    Toast.error({
        title: 'Unexpected data',
        subTitle: 'This was automatically reported, contact QA or product team to get a fix estimation',
    });
};
export const notifyVersionMismatchError = () => {
    Toast.error({
        title: 'Unexpected data',
        subTitle: 'Try refreshing the page to see if the issue persists',
    });
};
