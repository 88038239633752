import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faTrello } from '@fortawesome/free-brands-svg-icons';
import { faBuilding, faChartPieAlt, faEnvelope, faLink, faStar, faUser, faUserTag, } from '@fortawesome/pro-regular-svg-icons';
import { faPaintBrushAlt } from '@fortawesome/pro-solid-svg-icons';
import { useForm } from 'formoid';
import { z } from 'zod';
import { InputField, ModalHeader, ModalShell, SelectField } from '~/common/components';
import { fromZodSchema, propagateBackendValidationErrors } from '~/common/utils';
import { hasCalendlyUrl, hasTrelloId, isRoleDesigner } from '../domain';
import { useInitData, useUserData, useUserMutation } from '../hooks';
import { composePayload } from './utils';
export const EditUser = ({ onClose }) => {
    var _a, _b, _c, _d, _e;
    const user = useUserData();
    const init = useInitData();
    const { mutateAsync } = useUserMutation();
    const initialValues = {
        name: user.name,
        role: user.role,
        job_title: (_a = user.job_title) !== null && _a !== void 0 ? _a : '',
        email: user.email,
        office: user.office.id,
        capacity: 'capacity' in user ? user.capacity : null,
        team: 'team' in user ? (_c = (_b = user.team) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : null : null,
        trello_id: 'trello_id' in user ? (_d = user.trello_id) !== null && _d !== void 0 ? _d : '' : '',
        calendly_url: 'calendly_url' in user ? (_e = user.calendly_url) !== null && _e !== void 0 ? _e : '' : '',
    };
    const { fieldProps, handleSubmit, values, isSubmitting, setErrors } = useForm({
        initialValues,
        validationStrategy: 'onBlur',
        validators: ({ role }) => {
            return fromZodSchema({
                name: ['Name', z.string().min(2)],
                role: null,
                job_title: ['Job title', z.string().min(5)],
                email: ['Email', z.string().email()],
                office: null,
                capacity: isRoleDesigner(role) ? ['Capacity', z.number().nonnegative()] : null,
                team: null,
                trello_id: hasTrelloId(role) ? ['Trello ID', z.string().min(5)] : null,
                calendly_url: null,
            });
        },
    });
    const submit = () => handleSubmit((values) => {
        return mutateAsync(composePayload(values))
            .then(onClose)
            .catch((error) => {
            propagateBackendValidationErrors(error, setErrors);
        });
    });
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Edit user" }), _jsxs(ModalShell, { className: "w-[594px] p-3 pb-5 space-y-3", loading: isSubmitting, onClose: onClose, onSubmit: submit, children: [_jsx(InputField, { ...fieldProps('name'), icon: faUser, title: "Name", type: "text" }), _jsx(SelectField, { ...fieldProps('role'), icon: faUserTag, title: "User role", options: init.roles }), _jsx(InputField, { ...fieldProps('job_title'), icon: faStar, title: "Job title", type: "text" }), hasTrelloId(values.role) && (_jsx(InputField, { ...fieldProps('trello_id'), icon: faTrello, title: "Trello member ID", type: "text" })), hasCalendlyUrl(values.role) && (_jsx(InputField, { ...fieldProps('calendly_url'), icon: faLink, title: "Calendly URL (optional)", type: "text" })), isRoleDesigner(values.role) && (_jsx(InputField, { ...fieldProps('capacity'), icon: faChartPieAlt, title: "Default daily capacity", type: "number" })), isRoleDesigner(values.role) && (_jsx(SelectField, { ...fieldProps('team'), icon: faPaintBrushAlt, title: "Design team", options: init.teams, nullable: true })), _jsx(SelectField, { ...fieldProps('office'), icon: faBuilding, title: "Office", options: init.offices }), _jsx(InputField, { ...fieldProps('email'), icon: faEnvelope, title: "Email", type: "text" })] })] }));
};
