import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faCircle, faCommentAltEdit, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faBarsSort, faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { memo, useMemo, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Button, IconBox, Menu } from '~/common/components';
import { UserCard } from '~/common/kits/usercard';
import { cx, formatDate, getCSSTransitionClassNames } from '~/common/utils';
import { isSlide, useAnnotationInfo, useOrderData, useUpdateAnnotation, useUpdateAnnotationMassResolveRevision, useUpdateAnnotationMassResolveSlide, } from '~/orders/hooks';
import { useRevisionsContext } from '../revisionsContext';
import css from './Previews.module.scss';
const Header = ({ search, setSearch, sortedByUpdated, setSortedByUpdated, allAnnotationsResolved, }) => {
    const resolve = useUpdateAnnotationMassResolveRevision(allAnnotationsResolved);
    return (_jsxs("div", { className: cx('flex flex-shrink-0 items-end h-[36px] px-1 pb-[4px] gap-[4px]', 'font-brand-b1 text-greyscale-400 border-b border-solid border-greyscale-600'), children: [_jsx(IconBox, { size: "s", className: "m-[4px]", icon: faSearch }), _jsx("input", { className: "bg-transparent w-full", placeholder: "Search", value: search, onChange: (e) => setSearch(e.target.value) }), _jsxs(Menu, { trigger: (props) => (_jsx(Button.Icon, { ...props, "data-tt": "Sort", "data-tt-placement": "left", color: "grey-dark", icon: faBarsSort, active: sortedByUpdated })), children: [_jsx(Menu.Item, { selected: !sortedByUpdated, onClick: () => setSortedByUpdated(false), children: "Sort by slides" }), _jsx(Menu.Item, { selected: sortedByUpdated, onClick: () => setSortedByUpdated(true), children: "Sort by date" })] }), _jsx(Button.Icon, { "data-tt": allAnnotationsResolved ? 'Unresolve all' : 'Resolve all', "data-tt-placement": "left", color: "grey-dark", loading: resolve.isLoading, onClick: () => resolve.mutate(), icon: allAnnotationsResolved ? faCheckCircle : faCircle })] }));
};
const Annotation = memo(({ annotation, customer, selected, selectAnnotation }) => {
    const { revisionId, slideId } = useAnnotationInfo();
    const updateAnnotation = useUpdateAnnotation();
    const onResolveToggle = () => {
        updateAnnotation.mutateAsync({
            revisionId,
            slideId,
            annotationId: annotation.id,
            resolved: !annotation.resolved,
        });
    };
    const repliesAmount = annotation.comments.length - 1;
    const firstComment = annotation.comments[0];
    return (_jsxs("div", { className: cx('flex pr-2 py-[4px] hover:bg-greyscale-600 border-x border-solid border-greyscale-700 cursor-pointer', selected && 'bg-greyscale-550'), onClick: () => selectAnnotation(annotation.id), children: [_jsx("div", { className: "flex justify-center w-4 min-w-[32px]", children: _jsx(Button.Icon, { "data-tt": annotation.resolved ? 'Unresolve' : 'Resolve', "data-tt-placement": "left", className: cx('mt-[-4px]', !annotation.resolved && 'text-success-300'), color: "grey-dark", loading: updateAnnotation.isLoading, onClick: onResolveToggle, icon: annotation.resolved ? faCheckCircle : faCircle }) }), _jsxs("div", { className: cx('min-w-0 px-1 py-[4px] rounded text-greyscale-300 border border-solid font-brand-b2r', 'bg-greyscale-600', annotation.resolved ? 'border-greyscale-550' : 'border-success-300'), children: [_jsxs("div", { className: "flex items-center gap-1", children: [_jsx(UserCard, { id: customer.id, name: customer.name, avatar_link: customer.avatar_link, type: "customer", owner_id: customer.owner_id }), _jsxs("div", { className: "min-w-0", children: [_jsx("div", { className: "font-brand-t5 truncate", children: customer.name }), _jsxs("span", { className: "text-greyscale-500", children: ["#", annotation.index, " - ", formatDate(firstComment.created)] })] })] }), _jsx("div", { className: "mt-[4px]", children: firstComment.comment }), repliesAmount > 0 && (_jsxs("div", { className: "flex items-center text-greyscale-500 gap-[4px] mt-1", children: [_jsx(IconBox, { size: "s", className: "text-inherit", icon: faCommentAltEdit }), _jsxs("span", { children: [repliesAmount, " ", repliesAmount > 1 ? 'replies' : 'reply'] })] }))] })] }, annotation.id));
});
const Slide = ({ preview, customer, selectedAnnotationId, selectAnnotation, }) => {
    const allResolved = useMemo(() => preview.annotations.every((annotation) => annotation.resolved), [preview.annotations]);
    const resolveSlide = useUpdateAnnotationMassResolveSlide(preview.id, allResolved);
    return (_jsxs("div", { children: [_jsxs("div", { className: "flex items-start justify-between pt-1 pl-[12px] pr-1 h-[36px]", children: [_jsxs("div", { className: "font-brand-t4m text-greyscale-300", children: ["Slide ", preview.index] }), preview.annotations.length > 1 && (_jsx(Button.Icon, { "data-tt": "Resolve all on this slide", "data-tt-placement": "left", color: "grey-dark", loading: resolveSlide.isLoading, onClick: () => resolveSlide.mutate(), icon: allResolved ? faCheckCircle : faCircle }))] }), preview.annotations
                .filter((annotation) => annotation.comments.length)
                .map((annotation) => (_jsx(Annotation, { annotation: annotation, customer: customer, selected: selectedAnnotationId === annotation.id, selectAnnotation: selectAnnotation }, annotation.id)))] }));
};
const isSlideWithAnnotations = (preview) => isSlide(preview) && preview.annotations.length > 0;
export const AnnotationList = () => {
    const { customer } = useOrderData();
    const { annotationListOpened, previewsState, previewsActions } = useRevisionsContext();
    const [search, setSearch] = useState('');
    const [sortedByUpdated, setSortedByUpdated] = useState(false);
    const ref = useRef(null);
    const previewsWithAnnotations = useMemo(() => previewsState.previews.filter(isSlideWithAnnotations), [previewsState.previews]);
    const allAnnotationsResolved = useMemo(() => !previewsWithAnnotations.filter((preview) => preview.annotations.every((annotation) => !annotation.resolved)).length, [previewsWithAnnotations]);
    const annotationTextLookup = useMemo(() => {
        return previewsWithAnnotations.reduce((textById, slide) => {
            for (const annotation of slide.annotations) {
                textById[annotation.id] = annotation.comments
                    .map(({ comment }) => comment.toLowerCase())
                    .join('');
            }
            return textById;
        }, {});
    }, [previewsWithAnnotations]);
    const sortedPreviewsWithAnnotations = useMemo(() => {
        return previewsWithAnnotations
            .slice()
            .sort((a, b) => { var _a, _b, _c, _d; return ((_b = (_a = a.date) === null || _a === void 0 ? void 0 : _a.getTime()) !== null && _b !== void 0 ? _b : 0) - ((_d = (_c = b.date) === null || _c === void 0 ? void 0 : _c.getTime()) !== null && _d !== void 0 ? _d : 0); });
    }, [previewsWithAnnotations]);
    // TODO this is suboptimal, but let's go with more naive and simpler solution
    // first and see if it's worth to spend time optimizing
    const filteredPreviews = useMemo(() => {
        const previews = sortedByUpdated ? sortedPreviewsWithAnnotations : previewsWithAnnotations;
        const lowerCasedSearch = search.toLowerCase();
        return lowerCasedSearch
            ? previews
                .filter((slide) => slide.annotations
                .map(({ id }) => id)
                .some((id) => annotationTextLookup[id].includes(lowerCasedSearch)))
                .map((slide) => ({
                ...slide,
                annotations: slide.annotations.filter(({ id }) => annotationTextLookup[id].includes(lowerCasedSearch)),
            }))
            : previews;
    }, [
        search,
        sortedByUpdated,
        sortedPreviewsWithAnnotations,
        previewsWithAnnotations,
        annotationTextLookup,
    ]);
    return (_jsx(CSSTransition, { classNames: getCSSTransitionClassNames(css), in: annotationListOpened, timeout: 300, unmountOnExit: true, nodeRef: ref, children: _jsxs("div", { ref: ref, className: css.annotationList, children: [_jsx(Header, { search: search, setSearch: setSearch, sortedByUpdated: sortedByUpdated, setSortedByUpdated: setSortedByUpdated, allAnnotationsResolved: allAnnotationsResolved }), _jsx("div", { className: "overflow-y-auto", children: filteredPreviews.map((preview) => (_jsx(Slide, { preview: preview, customer: customer, selectedAnnotationId: previewsState.selectedAnnotationId, selectAnnotation: previewsActions.selectAnnotation }, preview.index))) })] }) }));
};
