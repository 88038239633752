import { jsx as _jsx } from "react/jsx-runtime";
import { useIsFetching } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
const PROGRESS_TIME_UPDATE = 500; // Milliseconds
const PROGRESS_LIMIT = 80; // Percentage
const PRORESS_STEP = 0.05; // Percentage
const INTIAL_PROGRESS_VALUE = 15; // Percentage
const LOADING_BAR_HEIGHT = 4; // Pixels
export const TopLoadingBar = () => {
    const loading = useIsFetching();
    const [progress, setProgress] = useState(0);
    const handleLoaderFinish = () => setProgress(0);
    const makeInterval = useCallback(() => {
        const interval = () => {
            setProgress((state) => {
                if (state > PROGRESS_LIMIT) {
                    return state;
                }
                return (state || INTIAL_PROGRESS_VALUE) + (100 - state) * PRORESS_STEP;
            });
        };
        if (loading) {
            interval();
            return setInterval(interval, PROGRESS_TIME_UPDATE);
        }
        return null;
    }, [loading]);
    useEffect(() => {
        const intervalId = makeInterval();
        return () => {
            if (intervalId) {
                setProgress(100);
                clearInterval(intervalId);
            }
        };
    }, [makeInterval]);
    return (_jsx(LoadingBar, { className: "top-loading-bar", color: "#7394E8", height: LOADING_BAR_HEIGHT, shadow: false, progress: progress, loaderSpeed: PROGRESS_TIME_UPDATE * 2, onLoaderFinished: handleLoaderFinish }));
};
