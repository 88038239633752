import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useIdParam } from '~/common/hooks';
import { httpClient, lastItem, map } from '~/common/utils';
import { CollaborationMessages } from '~/orders/domain';
import { qk } from '~/root/query-keys';
export const useMessages = (params) => {
    const { enabled = true } = params || {};
    const id = useIdParam();
    const query = useInfiniteQuery({
        queryKey: qk.collaborationMessages(id),
        queryFn: ({ pageParam = null }) => {
            return httpClient.get(`/v1/staff/orders/${id}/collaboration/comments`, {
                params: pageParam ? { offsetId: pageParam } : undefined,
                decoder: CollaborationMessages,
            });
        },
        getNextPageParam: (page) => page.metadata.next,
        getPreviousPageParam: (page) => page.metadata.prev,
        enabled,
    });
    const messages = useMemo(() => {
        if (!query.data) {
            return null;
        }
        // TODO consider storing page messages in arrays instead
        const allPagesCombined = map.combine(query.data.pages.map((page) => page.items)).values();
        const messages = Array.from(allPagesCombined).sort((a, b) => a.date.getTime() - b.date.getTime());
        return messages.length ? messages : null;
    }, [query.data]);
    const hasNextPage = query.data ? !!lastItem(query.data.pages).metadata.next : false;
    return { ...query, messages, hasNextPage };
};
