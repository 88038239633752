import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faCircleExclamation, faMessageLines, faSpinnerThird, } from '@fortawesome/pro-regular-svg-icons';
import { useLayoutEffect, useRef } from 'react';
import { IconBox, Show } from '~/common/components';
import { useIsMyMessage, useMessageEvents, useMessages, useOrderData, useReadMessage, useShouldReadMessage, } from '~/orders/hooks';
import { Container } from './Container';
import { Message } from './Message';
export const Messages = ({ isExtended, scrollToBottom, messagesRef: ref }) => {
    const { fetchNextPage, hasNextPage, isFetched, isFetchingNextPage, messages, status } = useMessages();
    const isMyMessage = useIsMyMessage();
    const shouldReadMessage = useShouldReadMessage();
    const previousScrollHeight = useRef(0);
    // Scroll to bottom when chat messages are initially loaded
    useLayoutEffect(() => {
        if (isFetched)
            scrollToBottom();
    }, [isFetched, scrollToBottom]);
    // Fetch next messages page and save previous scroll height
    useLayoutEffect(() => {
        const handler = (event) => {
            const { scrollHeight, scrollTop } = event.currentTarget;
            if (scrollTop === 0 && hasNextPage) {
                previousScrollHeight.current = scrollHeight;
                fetchNextPage();
            }
        };
        const container = ref.current;
        if (status === 'success')
            container === null || container === void 0 ? void 0 : container.addEventListener('scroll', handler);
        return () => container === null || container === void 0 ? void 0 : container.removeEventListener('scroll', handler);
    }, [fetchNextPage, hasNextPage, ref, status]);
    // Update scroll position when next page is being loaded
    useLayoutEffect(() => {
        if (ref.current) {
            ref.current.scrollTop = ref.current.scrollHeight - previousScrollHeight.current;
        }
    }, [isFetchingNextPage, ref]);
    useMessageEvents({ onCreated: scrollToBottom });
    const { customer } = useOrderData();
    const readMessage = useReadMessage();
    switch (status) {
        case 'loading': {
            return (_jsx(Container, { center: true, isExtended: isExtended, children: _jsx(IconBox, { size: "l", className: "text-secondary-300 animate-spin", icon: faSpinnerThird }) }));
        }
        case 'error': {
            return (_jsx(Container, { center: true, isExtended: isExtended, children: _jsxs("div", { className: "flex flex-col items-center justify-center space-y-1", children: [_jsx(IconBox, { size: "m", className: "text-greyscale-300", icon: faCircleExclamation }), _jsx("span", { className: "font-brand-b2r text-greyscale-300", children: "Could not load messages" })] }) }));
        }
        case 'success': {
            if (messages) {
                const canRemove = (message, index) => {
                    return isMyMessage(message) && index === messages.length - 1;
                };
                const handleRead = (message) => {
                    if (shouldReadMessage(message))
                        readMessage(message.id);
                };
                return (_jsxs(Container, { ref: ref, className: "overflow-y-scroll py-2", isExtended: isExtended, children: [_jsx(Show, { if: isFetchingNextPage, children: _jsx(IconBox, { size: "s", className: "text-greyscale-300 animate-spin mb-2 w-full", icon: faSpinnerThird }) }), messages.map((message, index) => (_jsx(Message, { canRemove: canRemove(message, index), message: message, ownerId: customer.owner_id, onRead: () => handleRead(message) }, message.id)))] }));
            }
            return (_jsx(Container, { center: true, isExtended: isExtended, children: _jsxs("div", { className: "flex flex-col items-center justify-center gap-[4px]", children: [_jsx(IconBox, { size: "m", className: "text-greyscale-300", icon: faMessageLines }), _jsx("span", { className: "font-brand-t4m text-greyscale-300", children: "No messages yet" })] }) }));
        }
    }
};
