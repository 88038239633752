import { tuple } from 'fp-ts';
import { tupled } from 'fp-ts/function';
export function keys(record) {
    return Object.keys(record);
}
export function values(record) {
    return Object.values(record);
}
export function entries(record) {
    return Object.entries(record);
}
export function fromEntries(entries) {
    return Object.fromEntries(entries);
}
export function map(record, fn) {
    return fromEntries(entries(record).map(tuple.mapSnd(fn)));
}
export function forEach(record, action) {
    return entries(record).forEach(tupled(action));
}
export function remove(record, key) {
    const { [key]: _, ...rest } = record;
    return rest;
}
export function shallowEqual(a, b) {
    return entries(a).every(([k, v]) => b[k] === v);
}
