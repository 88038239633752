import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { create } from 'zustand';
import { Button, IconBox, IconButton, ModalCore, ModalHeader } from '~/common/components';
import { cx } from '~/common/utils';
const usePromptInternal = create()((set) => ({
    params: null,
    setParams: (params) => set(() => ({ params })),
}));
export const usePrompt = () => {
    const { setParams } = usePromptInternal();
    return (params) => setParams(params);
};
export const Prompt = () => {
    const { params, setParams } = usePromptInternal();
    const onClose = () => {
        var _a;
        (_a = params === null || params === void 0 ? void 0 : params.onClose) === null || _a === void 0 ? void 0 : _a.call(params);
        setParams(null);
    };
    const onSubmit = () => {
        var _a;
        (_a = params === null || params === void 0 ? void 0 : params.onSubmit) === null || _a === void 0 ? void 0 : _a.call(params);
        setParams(null);
    };
    return (_jsx(ModalCore, { className: "w-[496px]", show: !!params, close: onClose, children: params && (_jsxs(_Fragment, { children: [params.title ? (_jsx(ModalHeader, { title: params.title, onClose: onClose })) : (_jsx(IconButton, { className: "ml-auto mr-[4px] w-6 h-6 text-[24px] text-secondary-300 focus-visible:ring", icon: faTimes, onClick: onClose })), params.content, _jsxs("div", { className: cx('flex items-center justify-end gap-3 px-3 py-2 w-full', 'border-t border-solid border-greyscale-200'), children: [params.cancelText && (_jsx(Button, { color: "grey", size: "medium", onClick: onClose, children: params.cancelText })), _jsx(Button, { color: params.negativeAction ? 'danger' : 'primary', size: "medium", onClick: onSubmit, children: params.submitText })] })] })) }));
};
export const NegativePromptContent = ({ icon = faTimesCircle, heading, description, }) => (_jsxs("div", { className: "flex flex-col items-center mb-6", children: [_jsx(IconBox, { className: "h-7 w-7 mt-5 text-danger-400 text-[56px]", icon: icon }), _jsx("div", { className: "font-brand-h1 text-greyscale-600 mt-3", children: heading }), _jsx("div", { className: "font-brand-b1 text-greyscale-500 mt-1", children: description })] }));
