import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
const DEFAULT_CHUNK_LENGTH = 5;
/**
 * Use this component when you need to render a large list in one go without
 * freezing, but don't want to resort to virtual lists, since it works fine
 * once rendered
 */
export const ChunkRenderer = ({ component: Component, propsList, chunkLength = DEFAULT_CHUNK_LENGTH, }) => {
    const [{ amount, done }, setState] = useState(() => ({
        amount: Math.min(propsList.length, chunkLength),
        done: false,
    }));
    useEffect(() => {
        setTimeout(() => {
            setState(({ amount }) => {
                const nextAmount = Math.min(amount + chunkLength, propsList.length);
                return {
                    amount: nextAmount,
                    done: nextAmount === propsList.length,
                };
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amount]);
    return (_jsx(_Fragment, { children: (done ? propsList : propsList.slice(0, amount)).map(({ key, ...props }) => (_jsx(Component, { ...props }, key))) }));
};
