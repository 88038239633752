import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '~/common/components';
import { useToggle } from '~/common/hooks';
import { generateFileKey, pluralize } from '~/common/utils';
import { File } from './File';
export const MAX_FILES_BEFORE_COLLAPSE = 5;
export const Files = ({ files, onRemove, onUpload }) => {
    const [isCollapsed, toggleCollapsed] = useToggle(files.length > MAX_FILES_BEFORE_COLLAPSE);
    const amountOfCollapsedFiles = files.length - MAX_FILES_BEFORE_COLLAPSE;
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "space-y-1", children: files.map((file, index) => (_jsx(File, { file: file, isHidden: isCollapsed && index >= MAX_FILES_BEFORE_COLLAPSE, onRemove: onRemove, onUpload: onUpload }, generateFileKey(file)))) }), files.length > MAX_FILES_BEFORE_COLLAPSE && (_jsx(Button, { className: "w-fit mt-2", color: "grey", size: "medium", onClick: toggleCollapsed, children: isCollapsed
                    ? `Show ${amountOfCollapsedFiles} more item${pluralize(amountOfCollapsedFiles)}`
                    : 'Show less' }))] }));
};
