import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { useState } from 'react';
import { cx } from '~/common/utils';
import { IconBox } from './IconContainers';
export const ImageLoader = (props) => {
    const [loaded, setLoaded] = useState(false);
    return (_jsxs(_Fragment, { children: [!loaded && (_jsx("div", { className: cx('flex items-center justify-center before:block def:before:mt-[75%]', props.className || 'w-full'), children: _jsx(IconBox, { size: "l", icon: faSpinnerThird, className: "text-greyscale-500 animate-spin h-fit w-fit" }) })), _jsx("img", { ...props, className: cx(props.className, {
                    hidden: !loaded,
                }), onLoad: () => setLoaded(true) })] }));
};
