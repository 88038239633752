import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment } from 'react';
import { cx } from '~/common/utils';
import { Button } from '../Interactives';
const defaultRenderTab = (props) => (_jsx(Button.Base, { "data-tt": cx(props.disabled && 'This feature is yet to be implemented, come back later'), disabled: props.disabled, onClick: props.onChange, className: cx('pb-1 mb-[-2px] border-b-[3px] transition-colors', props.isActive
        ? 'border-b-secondary-400 text-greyscale-500'
        : 'border-b-transparent text-greyscale-400', { 'cursor-not-allowed': props.disabled }), children: props.label }));
export const Tabs = ({ active, className, onChange, renderTab = defaultRenderTab, tabs, }) => {
    return (_jsx("div", { className: className, children: tabs.map((tab) => (_jsx(Fragment, { children: renderTab({
                label: tab.label,
                disabled: tab.disabled,
                isActive: tab.key === active,
                onChange: () => onChange(tab.key),
            }) }, tab.key))) }));
};
