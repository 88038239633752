export const getSum = (numbers) => numbers.reduce((total, current) => total + current);
export const getRatio = (current, total) => current / total || 0;
export const getPercentage = (current, total) => {
    return Math.round(getRatio(current, total) * 100);
};
export const megaBytesToBytes = (megaBytes) => megaBytes * 1024 * 1024;
export const twoDecimalsPrecision = (value) => Math.round(value * 100) / 100;
export const formatMoney = (value, currency = 'USD') => {
    const roundedValue = twoDecimalsPrecision(value);
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        maximumSignificantDigits: roundedValue.toString().length,
        currencyDisplay: 'symbol',
    })
        .format(roundedValue)
        .replace(/,/g, ' ');
};
export const formatNumber = (value) => {
    return new Intl.NumberFormat('en-US').format(twoDecimalsPrecision(value)).replace(/,/g, ' ');
};
