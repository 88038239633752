import { useMutation } from '@tanstack/react-query';
import { useIdParam } from '~/common/hooks';
import { httpClient } from '~/common/utils';
import { Order } from '~/orders/domain';
import { qk } from '~/root/query-keys';
import { useUpdateOrderCache } from './useUpdateOrderCache';
export function useUpdateOrder() {
    const id = useIdParam();
    const updateOrderCache = useUpdateOrderCache();
    const { mutateAsync } = useMutation({
        mutationFn: (data) => {
            return httpClient.patch(`v1/staff/orders/${id}`, { data, decoder: Order });
        },
        mutationKey: qk.orderUpdate(id),
        onSuccess: (response) => updateOrderCache((order) => ({ ...order, ...response })),
    });
    return mutateAsync;
}
