import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faSlidersH, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm, validator } from 'formoid';
import { Redirect, useHistory } from 'react-router-dom';
import { google, tfLogoDark } from '~/assets/images';
import { Button, IconBox, InputField, PasswordField } from '~/common/components';
import { useHideLayoutElements } from '~/common/hooks';
import { customValidator, cx, decodeOrNull, minLengthMessage, propagateBackendValidationErrors, } from '~/common/utils';
import { ENV, SERVER_URL } from '~/env';
import { qk } from '../query-keys';
import { HttpErrorOauthData } from './domain';
import { loginWithGoogle } from './google';
import { useLogin, useTokenCheck } from './hooks';
const GoogleButton = (props) => (_jsxs(Button.Base, { ...props, "data-tt": cx(props.disabled && 'Login through google in the opened window or close it'), className: cx('flex items-center justify-center gap-2 h-[54px] w-[400px] border border-greyscale-300 rounded shadow-md', 'hover:border-greyscale-400 hover:bg-greyscale-50 transition-colors', props.disabled ? 'cursor-not-allowed' : 'cursor-pointer', props.className), children: [_jsx("img", { className: "h-3 w-3", src: google }), _jsx("span", { className: "text-[20px] leading-[23px] font-['Roboto'] font-medium text-greyscale-500", children: "Log In with Google" })] }));
const RoundedWrapper = ({ children }) => (_jsx("div", { className: "row-[1/3] col-[1/3] h-full w-full min-h-[680px] bg-[#1e2860] p-5", children: _jsxs("div", { className: "relative flex flex-col items-center h-full w-full bg-greyscale-0 rounded-2xl pt-9", children: [_jsx("div", { className: "h-5", children: _jsx("img", { className: "block h-full w-auto", src: tfLogoDark, alt: "24Slides" }) }), children] }) }));
const useGoogleLogin = ({ redirect } = { redirect: true }) => {
    const client = useQueryClient();
    return useMutation({
        mutationFn: (oauthData) => {
            return loginWithGoogle(oauthData)
                .then((response) => {
                const redirectUrl = new URL(response.redirectUrl);
                const sameHost = redirectUrl.host === window.location.host;
                const emptyPath = redirectUrl.pathname.length <= 1;
                // redirect to routes like an SPA if it's the same app
                if (redirect && sameHost && !emptyPath) {
                    history.pushState(null, '', redirectUrl);
                }
                // redirect somewhere else if it's not some staff.playground
                if (redirect && !sameHost && !redirectUrl.host.startsWith('staff')) {
                    window.location.href = response.redirectUrl;
                }
                // sometimes we fetch too fast and there's no cookie yet for some reason
                // leading to 401 and nothing happening visually for the user
                return new Promise((resolve) => {
                    setTimeout(() => {
                        client.invalidateQueries(qk.init).then(resolve);
                    }, 50);
                });
            })
                .catch((error) => {
                console.error(error);
                return null;
            });
        },
    });
};
export const Invite = () => {
    useHideLayoutElements();
    const { mutateAsync, isLoading } = useGoogleLogin();
    const { data: name, isFetching } = useTokenCheck();
    if (isFetching) {
        return (_jsx(RoundedWrapper, { children: _jsx("div", { className: "flex items-center justify-center h-3/5", children: _jsx(IconBox, { className: "h-9 w-9 text-6xl text-secondary-300 animate-spin", icon: faSpinnerThird }) }) }));
    }
    if (name) {
        return (_jsxs(RoundedWrapper, { children: [_jsx("h2", { className: "text-[32px] leading-[40px] mt-11 [@media(min-width:1600px)]:mt-[8%] mb-3", children: "Welcome to the family!" }), _jsxs("p", { className: "font-brand-t1r text-greyscale-500 text-center mb-8", children: ["You\u2019ve been invited to the Inner platform", _jsx("br", {}), "Please, continue with your 24Slides Google account"] }), _jsx(GoogleButton, { disabled: isLoading, onClick: () => mutateAsync(null) })] }));
    }
    return (_jsxs(RoundedWrapper, { children: [_jsxs("h2", { className: "text-[32px] leading-[40px] mt-20 [@media(min-width:1600px)]:mt-[10%] mb-3", children: [_jsx("span", { children: "This link has expired" }), _jsx("span", { className: "ml-2", children: "\uD83D\uDC94" })] }), _jsxs("p", { className: "font-brand-t1r text-greyscale-500 text-center mb-8", children: ["Sorry, your invitation link has expired.", _jsx("br", {}), "Please, contact your manager to request another one"] })] }));
};
export const ProductionLogin = () => {
    useHideLayoutElements();
    const { mutateAsync, isLoading } = useGoogleLogin();
    return (_jsxs(RoundedWrapper, { children: [_jsx("h2", { className: "text-[32px] leading-[40px] mt-11 [@media(min-width:1600px)]:mt-[8%] mb-3", children: "Hello there!" }), _jsx("p", { className: "font-brand-t1r text-greyscale-500 text-center mb-8", children: "Please, continue with your 24Slides Google account" }), _jsx(GoogleButton, { disabled: isLoading, onClick: () => mutateAsync(null) })] }));
};
export const StagingLogin = () => {
    useHideLayoutElements();
    const login = useLogin();
    const gLogin = useGoogleLogin({ redirect: false });
    const history = useHistory();
    const initialValues = { email: '', password: '' };
    const { fieldProps, handleSubmit, isSubmitting, setErrors } = useForm({
        initialValues,
        validationStrategy: 'onBlur',
        validators: () => ({
            email: customValidator.email(),
            password: validator.minLength(2, minLengthMessage('Password', 2)),
        }),
    });
    const submit = () => {
        handleSubmit((values) => login
            .mutateAsync({ ...values, remember: true })
            .then(() => history.push('/'))
            .catch((error) => {
            const oauthData = decodeOrNull(error, HttpErrorOauthData);
            if (!oauthData) {
                return propagateBackendValidationErrors(error, setErrors);
            }
            return gLogin.mutateAsync(oauthData);
        }));
    };
    const handleLoginWithGoogle = () => {
        gLogin.mutateAsync(null);
    };
    const pendingLogin = isSubmitting || gLogin.isLoading;
    return (_jsxs(RoundedWrapper, { children: [_jsx("preview-switcher", { class: "absolute top-3 right-3", placement: "bottom-end", development: ENV.DEVELOPMENT, server: SERVER_URL, children: _jsx(Button.Icon, { color: "grey", size: "m", icon: faSlidersH }) }), _jsxs("div", { className: "w-[416px] space-y-2", children: [_jsx("h2", { className: "text-[32px] leading-[40px] mt-11 mb-2", children: "Log in to test environment" }), _jsx("p", { className: "text-greyscale-500", children: new URL(SERVER_URL).host }), _jsx(InputField, { ...fieldProps('email'), title: "Email", placeholder: "Enter your email", type: "text" }), _jsx(PasswordField, { ...fieldProps('password'), title: "Password", placeholder: "Enter a password" }), _jsx(Button, { "data-tt": cx(gLogin.isLoading && 'Login with google is pending'), className: "w-full", color: "primary", size: "medium", loading: login.isLoading, disabled: gLogin.isLoading, onClick: submit, children: "Log in" }), _jsxs(Button.Base, { "data-tt": cx(pendingLogin && 'Login through google in the opened window or close it'), disabled: pendingLogin, onClick: handleLoginWithGoogle, className: cx('flex items-center justify-center gap-2 h-5 w-full border border-greyscale-300 rounded shadow-md', 'hover:border-greyscale-400 hover:bg-greyscale-50 transition-colors', pendingLogin ? 'cursor-not-allowed' : 'cursor-pointer'), children: [_jsx("img", { className: "h-3 w-3", src: google }), _jsx("span", { className: "font-brand-t2m text-greyscale-500", children: "Log In with Google" })] })] })] }));
};
export const Login = ({ username }) => {
    if (username) {
        return _jsx(Redirect, { to: "/" });
    }
    if (ENV.PRODUCTION) {
        return _jsx(ProductionLogin, {});
    }
    return _jsx(StagingLogin, {});
};
