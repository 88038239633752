import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faShapes } from '@fortawesome/pro-regular-svg-icons';
import { LayoutContent, withModalContext } from '~/common/components';
import { Nothing, Table, useBackendTable } from '~/common/kits/table';
import { TEAMS_URL, useInit, useTeamsList } from '../hooks';
import { Header } from './Header';
import { tableConfig } from './table-config';
export const TeamsList = withModalContext(() => {
    const init = useInit();
    const teams = useTeamsList();
    const view = useBackendTable({
        data: teams.data,
        tableConfig,
        isFetching: teams.isFetching,
    });
    if (!teams.data || !init.data || !view) {
        return _jsx(LayoutContent, { loading: true });
    }
    return (_jsxs(LayoutContent, { children: [_jsx(Header, {}), _jsx(Table, { ...view, noDataPlaceholder: _jsx(Nothing, { icon: faShapes }), linkGetter: (row) => `${TEAMS_URL}/${row.id}` })] }));
});
