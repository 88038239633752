import { useMutation } from '@tanstack/react-query';
import ImageBlobReduce from 'image-blob-reduce';
import { useMemo, useRef, useState } from 'react';
import { useIdParam } from '~/common/hooks';
import { getPercentage, getSum, isAbortError, isNonNullable, rejectBySignal, tusUpload, } from '~/common/utils';
import { SERVER_URL } from '~/env';
import { qk } from '~/root/query-keys';
const MAX_WIDTH = 1280;
const SLOW_COMPRESSION_TIME = 4000;
const isUploadedFile = (res) => Boolean(res && 'id' in res);
const isFileError = (res) => Boolean(res && 'error' in res);
class CustomImageBlobReduce extends ImageBlobReduce {
    _calculate_size(env) {
        let scale_factor = env.opts.max / env.image.width;
        if (scale_factor > 1)
            scale_factor = 1;
        env.transform_width = Math.max(Math.round(env.image.width * scale_factor), 1);
        env.transform_height = Math.max(Math.round(env.image.height * scale_factor), 1);
        // Info for user plugins, to check if scaling applied
        env.scale_factor = scale_factor;
        return Promise.resolve(env);
    }
}
const reducer = new CustomImageBlobReduce();
const resize = async (src) => {
    const dest = await reducer.toBlob(src, { max: MAX_WIDTH });
    return new File([dest], src.name, {
        lastModified: src.lastModified,
        type: src.type,
    });
};
const uploader = async ({ files, signal, onDone, onSlowCompression }) => {
    let progress = 0;
    const onTotalProgress = () => {
        progress++;
        if (progress >= files.length) {
            onDone();
        }
    };
    for (const { file, onCompress, onProcess, onProgress } of files) {
        const start = Date.now();
        const compressed = await Promise.race([rejectBySignal(signal), resize(file)]).catch((error) => {
            // we're throwing when aborted to stop processing files, other errors are stored per-file
            if (isAbortError(error)) {
                throw error;
            }
            // TODO provide less generic error somehow? What could fail when resizing?
            onProcess({ filename: file.name, error: 'Compression failed' });
            onTotalProgress();
            return null;
        });
        const end = Date.now();
        if (end - start > SLOW_COMPRESSION_TIME) {
            onSlowCompression();
        }
        if (!compressed) {
            continue;
        }
        onCompress(compressed);
        tusUpload({
            file: compressed,
            tag: 'order_slide',
            signal,
            onProgress,
        })
            .then((uploadedFile) => {
            // direct access for uploaded file is blocked, so we need to compose
            // a regular BE link using uuid
            // TODO check if there's a better way to compose BE urls on FE using tags and uuids
            uploadedFile.link = `${SERVER_URL}/v1/staff/files/${uploadedFile.id}`;
            onProcess(uploadedFile);
        })
            // TODO inspect actual error to provide user with less generic error
            .catch(() => onProcess({ filename: compressed.name, error: 'Upload failed' }))
            .finally(onTotalProgress);
    }
};
const getInitialProgress = () => ({
    compressed: 0,
    total: 0,
    uploaded: 0,
    failed: 0,
    bytesPercentage: 0,
    slowCompression: false,
});
export function useUploadOrderSlides() {
    const id = useIdParam();
    const abortFn = useRef(null);
    const uploadMoreFn = useRef(null);
    const [progress, setProgress] = useState(getInitialProgress());
    const percentage = useMemo(() => {
        const uploadedPercentage = getPercentage(progress.uploaded + progress.failed, progress.total);
        return (progress.bytesPercentage + uploadedPercentage) / 2;
    }, [progress.bytesPercentage, progress.failed, progress.total, progress.uploaded]);
    const mutation = useMutation({
        mutationKey: qk.orderFiles(id),
        mutationFn: (files) => {
            return new Promise((resolve) => {
                const controller = new AbortController();
                // we will only hold promise until files are compressed here, since we
                // need to ensure that they are compressed sequentually
                //
                // after uploading all files onDone callback will be called with upload results
                const uploadDeck = async ({ files, onDeckDone, }) => {
                    const results = files.map(() => undefined);
                    const sizePerFile = files.map(() => 0);
                    const bytesSentPerFile = files.map(() => 0);
                    // at this point array only contains results
                    const onDone = () => onDeckDone(results);
                    // this array of files with actions attached makes good separation of
                    // concerns
                    const filesToProcess = files.map((file, index) => ({
                        file,
                        onCompress: (file) => {
                            sizePerFile[index] = file.size;
                            setProgress((prev) => {
                                return controller.signal.aborted
                                    ? prev
                                    : { ...prev, compressed: prev.compressed + 1 };
                            });
                        },
                        onProgress: (bytesSent) => {
                            bytesSentPerFile[index] = bytesSent;
                            const bytesPercentage = getPercentage(getSum(bytesSentPerFile), getSum(sizePerFile));
                            setProgress((prev) => {
                                return bytesPercentage - prev.bytesPercentage >= 1
                                    ? { ...prev, bytesPercentage }
                                    : prev;
                            });
                        },
                        onProcess: (result) => {
                            results[index] = result;
                            setProgress((prev) => {
                                if (controller.signal.aborted) {
                                    return prev;
                                }
                                return isUploadedFile(result)
                                    ? { ...prev, uploaded: prev.uploaded + 1 }
                                    : { ...prev, failed: prev.failed + 1 };
                            });
                        },
                    }));
                    await uploader({
                        files: filesToProcess,
                        onDone,
                        onSlowCompression: () => setProgress((prev) => ({ ...prev, slowCompression: true })),
                        signal: controller.signal,
                    });
                };
                let currentDeckPromise = Promise.resolve();
                const processedDecks = [];
                // this builds a sequence of decks, where in each deck files are
                // compressed sequentually, but uploaded as soon as compressed
                //
                // each deck will block the next deck with promise until all of it's
                // files are compressed
                uploadMoreFn.current = (files) => {
                    processedDecks.push(undefined);
                    const lastDeckIndex = processedDecks.length - 1;
                    currentDeckPromise = currentDeckPromise.then(() => {
                        return uploadDeck({
                            files,
                            onDeckDone: (results) => {
                                processedDecks[lastDeckIndex] = results;
                                if (processedDecks.every(isNonNullable)) {
                                    const allDecksFlat = processedDecks.flat();
                                    resolve({
                                        uploaded: allDecksFlat.filter(isUploadedFile),
                                        failed: allDecksFlat.filter(isFileError),
                                    });
                                }
                            },
                        });
                    });
                };
                abortFn.current = () => {
                    controller.abort();
                    abortFn.current = null;
                    uploadMoreFn.current = null;
                    resolve(null);
                };
                uploadMoreFn.current(files);
            });
        },
    });
    return {
        ...mutation,
        uploadPreviews: (files, onSuccess) => {
            if (!files.length) {
                return;
            }
            if (uploadMoreFn.current) {
                uploadMoreFn.current(files);
            }
            else {
                mutation.mutateAsync(files).then((files) => {
                    abortFn.current = null;
                    uploadMoreFn.current = null;
                    setProgress(getInitialProgress());
                    if (files) {
                        onSuccess(files);
                    }
                });
            }
            setProgress((prev) => ({ ...prev, total: prev.total + files.length }));
        },
        onCancel: () => { var _a; return (_a = abortFn.current) === null || _a === void 0 ? void 0 : _a.call(abortFn); },
        progress: { ...progress, percentage },
    };
}
