import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { addDays, differenceInDays, isToday, isWeekend } from 'date-fns';
import { useMemo } from 'react';
import { Chart } from 'react-chartjs-2';
import { Button, Collapse, IconBox, LayoutContainer } from '~/common/components';
import { useToggle } from '~/common/hooks';
import { barChartScaleParams, getBarParams, registerChartJSPlugins, stickyYAxisPlugin, tooltipCallbacks, tooltipParams, } from '~/common/other';
import { formatDayFullMonthYear, isNonNullable, range } from '~/common/utils';
import { useRmsContext } from '../context';
import { arbitraryLine } from './Utilization';
import { chartMinWidth, dateFormatter } from './utils';
registerChartJSPlugins();
const font = {
    size: 11,
    family: '"Poppins", sans-serif',
};
const getChartData = ({ dataset, start, end, allTeams }) => {
    const chartData = {
        day_capacity_left: [],
        labels: [],
        tooltips: [],
        weekend: new Set(),
        allTeams,
        today: formatDayFullMonthYear(start),
        todayIndex: null,
    };
    if (allTeams) {
        const sortedData = dataset.filter(isNonNullable).sort((a, b) => b.day_capacity_left - a.day_capacity_left);
        range(0, dataset.length - 1).forEach((index) => {
            const currentItem = sortedData[index];
            chartData.day_capacity_left.push(currentItem.day_capacity_left);
            chartData.labels.push(currentItem.team);
        });
        chartData.tooltips = chartData.labels;
        return chartData;
    }
    const diff = differenceInDays(end, start);
    range(0, diff - 1).forEach((index) => {
        const newDate = addDays(start, index);
        const isDateToday = isToday(newDate);
        const currentItem = dataset[index];
        chartData.day_capacity_left.push((currentItem === null || currentItem === void 0 ? void 0 : currentItem.day_capacity_left) || null);
        chartData.labels.push(dateFormatter(dataset.length, newDate));
        chartData.tooltips.push(formatDayFullMonthYear(newDate));
        if (isDateToday) {
            chartData.todayIndex = index;
        }
        if (isWeekend(newDate)) {
            chartData.weekend.add(index);
        }
    });
    return chartData;
};
const getChartProps = (chartData) => ({
    type: 'bar',
    plugins: [arbitraryLine, ChartDataLabels, stickyYAxisPlugin],
    options: {
        plugins: {
            arbitraryLine: {
                index: chartData.todayIndex,
            },
            stickyYAxis: {
                draw: chartData.allTeams,
                nodeId: 'rms-capacity-left-canvas',
            },
            datalabels: {
                color: 'white',
                font,
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: tooltipCallbacks(chartData.tooltips),
                ...tooltipParams,
            },
            legend: { display: false },
        },
        ...barChartScaleParams(chartData),
    },
    data: {
        labels: chartData.labels,
        datasets: [
            {
                backgroundColor: '#8A6DD9',
                data: chartData.day_capacity_left,
                ...getBarParams(),
            },
        ],
    },
});
export const CapacityLeft = ({ utilization }) => {
    const { date, endDate, allTeams } = useRmsContext();
    const chartData = useMemo(() => getChartData({ dataset: utilization, start: date, end: endDate, allTeams }), [utilization, date, endDate, allTeams]);
    const [show, toggleShow] = useToggle(true);
    const chartProps = useMemo(() => getChartProps(chartData), [chartData]);
    const itemsAmount = chartData.labels.length;
    return (_jsxs(LayoutContainer, { children: [_jsxs("div", { className: "flex items-center justify-between", children: [_jsxs("div", { className: "flex items-center justify-between gap-1 font-brand-t1r", children: [_jsx("span", { children: "Day Capacity Left, " }), _jsx("span", { className: "text-greyscale-500", children: "points" }), _jsx(IconBox, { "data-tt": "Capacity left per team for a day", size: "m", className: "inline text-greyscale-300", icon: faInfoCircle })] }), _jsx(Button.Icon, { size: "m", color: "grey-outline", icon: show ? faChevronUp : faChevronDown, onClick: toggleShow })] }), _jsx(Collapse, { in: show, children: _jsxs("div", { className: "relative mt-3", children: [_jsx("div", { className: "overflow-x-auto", children: _jsx("div", { className: "relative h-[375px]", style: chartMinWidth(itemsAmount, allTeams), children: _jsx(Chart, { ...chartProps }) }) }), allTeams && (_jsx("div", { className: "absolute left-0 top-0 bg-greyscale-0 pr-[4px]", children: _jsx("canvas", { id: "rms-capacity-left-canvas", className: "h-[375px]" }) }))] }) })] }));
};
