import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { useMemo } from 'react';
import { IconBox, Tooltip } from '~/common/components';
import { cx } from '~/common/utils';
// TODO move these to the customer module once we merge subscriptions into customer
export const IndividualPricingTable = ({ data }) => {
    const gridTemplateColumns = useMemo(() => `48px ${data === null || data === void 0 ? void 0 : data.labels.map(() => '1fr').join(' ')}`, [data === null || data === void 0 ? void 0 : data.labels]);
    return (_jsxs("div", { className: "grid gap-x-2 gap-y-1 overflow-x-scroll", style: { gridTemplateColumns }, children: [_jsx(IndividualPricingTableHead, { treatments: data.labels }), data.pricing.map(({ title, entries }) => (_jsx(IndividualPricingTableRow, { title: title, entries: entries }, title.hour)))] }));
};
export const IndividualPricingTableHead = ({ treatments }) => (_jsxs(_Fragment, { children: [_jsx("div", {}), treatments.map((treatment) => (_jsx("div", { className: "h-4 flex items-center justify-center bg-greyscale-100 rounded text-greyscale-500 px-[4px] min-w-10", children: _jsx("span", { className: "truncate font-brand-t4m inline-block", children: treatment.name }) }, treatment.id)))] }));
const cellClassnames = 'flex justify-around items-center font-brand-t4m border-b border-greyscale-200 p-1';
export const IndividualPricingTableRow = ({ title, entries }) => (_jsxs(_Fragment, { children: [_jsxs("div", { className: cellClassnames, children: [_jsxs("span", { children: [title.hour, "h"] }), title.hint && (_jsx(Tooltip, { content: title.hint, placement: "right", className: "max-w-[230px]", children: _jsx(IconBox, { size: "s", className: "pointer-events-auto ml-[4px]", icon: faInfoCircle }) }))] }), entries.map((price, key) => (_jsx("div", { className: cx(cellClassnames, 'min-w-10'), children: _jsx("span", { className: (price === null || price === void 0 ? void 0 : price.individual) ? 'text-secondary-400' : 'text-greyscale-400', children: (price === null || price === void 0 ? void 0 : price.value) !== null ? `$${price.value}` : '—' }) }, key)))] }));
