import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { assertQueryData, formatYearMonthDay, httpClient, nonNullable } from '~/common/utils';
import { qk } from '~/root/query-keys';
import { useRmsContext } from './context';
import { CapacityTotals, Init, OrderOverview, TeamBreakdown, TeamUtilization } from './domain';
export const RMS_URL = '/rms';
export const useInit = (teamId) => {
    const queryFn = () => httpClient.get(`/v1/staff/rms/init`, { decoder: Init, params: { team_id: teamId } });
    return useQuery({
        queryKey: qk.rmsInit,
        queryFn,
        staleTime: Infinity,
        keepPreviousData: true,
    });
};
export const useInitData = () => {
    const { teamId } = useRmsContext();
    return assertQueryData(useInit(teamId));
};
export const useCapacityTotals = () => {
    const { date, teamId, loaded } = useRmsContext();
    const formattedDate = formatYearMonthDay(date);
    const queryFn = () => httpClient.get(`/v1/staff/rms/totals/${formatYearMonthDay(date)}`, {
        decoder: CapacityTotals,
        params: { team_id: teamId !== null && teamId !== void 0 ? teamId : undefined },
    });
    return useQuery({
        queryKey: [...qk.rmsTotals, { formattedDate, teamId }],
        queryFn,
        keepPreviousData: true,
        enabled: loaded,
    });
};
export const useDailyUtilization = () => {
    const { date, endDate, teamId, loaded } = useRmsContext();
    const formattedStart = formatYearMonthDay(date);
    const formattedEnd = formatYearMonthDay(endDate);
    const queryFn = () => httpClient.get(`/v1/staff/rms/utilization`, {
        decoder: TeamUtilization,
        params: {
            team_id: teamId !== null && teamId !== void 0 ? teamId : undefined,
            from: formattedStart,
            to: teamId ? formattedEnd : formattedStart,
        },
    });
    return useQuery({
        queryKey: [...qk.rmsUtilization, { formattedStart, formattedEnd, teamId }],
        queryFn,
        keepPreviousData: true,
        enabled: loaded,
    });
};
export const useBreakdown = () => {
    const { breakdownStart, breakdownEnd, teamId, loaded } = useRmsContext();
    const formattedStart = formatYearMonthDay(breakdownStart);
    const formattedEnd = formatYearMonthDay(breakdownEnd);
    const init = useInit(teamId);
    const queryFn = () => {
        const { offices, shifts } = nonNullable(init.data);
        return httpClient.get(`/v1/staff/rms/breakdown`, {
            decoder: TeamBreakdown({ start: breakdownStart, end: breakdownEnd, shifts, offices }),
            params: {
                team_id: teamId !== null && teamId !== void 0 ? teamId : undefined,
                from: formattedStart,
                to: formattedEnd,
            },
        });
    };
    return useQuery({
        queryKey: [...qk.rmsBreakdown, { formattedStart, formattedEnd, teamId }],
        queryFn,
        keepPreviousData: true,
        enabled: loaded,
    });
};
export const useBreakdownData = () => {
    return assertQueryData(useBreakdown());
};
export const useCapacityChangingMutation = () => {
    const client = useQueryClient();
    return useMutation({
        mutationFn: ({ designer_id: id, ...data }) => httpClient.put(`/v1/staff/rms/designers/${id}`, { data }),
        onSuccess: () => {
            client.invalidateQueries(qk.rmsTotals);
        },
    });
};
export const useOrderOverview = (id) => {
    const queryFn = () => httpClient.get(`/v1/staff/rms/orders/${id}`, { decoder: OrderOverview });
    return useQuery({
        queryKey: qk.rmsOrder(id),
        queryFn,
        keepPreviousData: true,
    });
};
export const useDeleteCapacityChange = () => {
    const client = useQueryClient();
    return useMutation({
        mutationKey: qk.rmsCapacityChangeDelete,
        mutationFn: ({ designerId, capacityChangeId }) => {
            return httpClient.delete(`/v1/staff/rms/designers/${designerId}/${capacityChangeId}`);
        },
        onSuccess: () => {
            client.invalidateQueries(qk.rmsTotals);
            client.invalidateQueries(qk.rmsUtilization);
            client.invalidateQueries(qk.rmsBreakdown);
        },
    });
};
