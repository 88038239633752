import { jsxs as _jsxs } from "react/jsx-runtime";
import { faTrash, faUserPlus } from '@fortawesome/pro-solid-svg-icons';
import { useCallback, useMemo, useState } from 'react';
import { Toast } from '~/common/components';
import { createContextPair } from '~/common/kits/context';
import { useAddCustomersToOrganization, useDeleteCustomersFromOrganization, useOrganizationData, } from '~/organizations/hooks';
const useContextData = () => {
    const { customers, independent_customers, owner } = useOrganizationData();
    const tabs = [
        {
            key: 'customers',
            label: (_jsxs("div", { children: ["Added ", _jsxs("span", { className: "font-brand-t5 text-greyscale-400", children: ["(", customers.length, ")"] })] })),
        },
        {
            key: 'independent_customers',
            label: (_jsxs("div", { children: ["Independent", ' ', _jsxs("span", { className: "font-brand-t5 text-greyscale-400", children: ["(", independent_customers.length, ")"] })] })),
        },
    ];
    const [{ tab, search, filter, selectedIds, showSelected }, setState] = useState({
        tab: 'customers',
        search: '',
        filter: null,
        selectedIds: [],
        showSelected: false,
    });
    const tableData = useMemo(() => ({
        customers,
        independent_customers,
    }), [customers, independent_customers]);
    const allIds = useMemo(() => tableData[tab].map((c) => c.id), [tab, tableData]);
    const isAllChecked = tableData[tab].length === selectedIds.length;
    const isSomeChecked = selectedIds.length > 0 && !isAllChecked;
    // ----- FE State mutations
    const resetTabState = useCallback(() => {
        setState((prev) => ({
            ...prev,
            filter: null,
            search: '',
            selectedIds: [],
            showSelected: false,
        }));
    }, []);
    const setTab = useCallback((tab) => {
        setState(() => ({
            filter: null,
            search: '',
            tab,
            selectedIds: [],
            showSelected: false,
        }));
    }, []);
    const setSearch = useCallback((search) => {
        setState((prev) => ({
            ...prev,
            search,
            showSelected: false,
        }));
    }, []);
    const setFilter = useCallback((filter) => {
        setState((prev) => ({
            ...prev,
            filter,
            showSelected: false,
        }));
    }, []);
    const handleSelectItem = useCallback((id) => setState((prev) => ({
        ...prev,
        showSelected: prev.showSelected && prev.selectedIds.length > 1,
        selectedIds: prev.selectedIds.includes(id)
            ? prev.selectedIds.filter((s) => s !== id)
            : [...prev.selectedIds, id],
    })), []);
    const handleSelectAll = useCallback(() => setState((prev) => ({
        ...prev,
        showSelected: false,
        selectedIds: isAllChecked || isSomeChecked ? [] : allIds.filter((id) => id !== (owner === null || owner === void 0 ? void 0 : owner.id)),
    })), [allIds, isAllChecked, isSomeChecked, owner]);
    const toggleShowSelected = useCallback(() => {
        setState((prev) => ({
            ...prev,
            search: '',
            filter: null,
            showSelected: !prev.showSelected,
        }));
    }, []);
    // ----- Table filtering
    const searchIndex = useMemo(() => {
        return tableData[tab].map((item) => (item.name + item.email).toLowerCase());
    }, [tab, tableData]);
    const filteredData = useMemo(() => {
        let data = tableData[tab];
        const lowerCasedSearch = search.toLowerCase();
        if (showSelected) {
            data = data.filter((c) => selectedIds.includes(c.id));
        }
        if (filter) {
            data = data.filter((c) => (filter === 'subscription' ? c.products : !c.products));
        }
        if (search) {
            data = data.filter((_, index) => searchIndex[index].includes(lowerCasedSearch));
        }
        return data;
    }, [filter, search, searchIndex, selectedIds, showSelected, tab, tableData]);
    // ----- BE interacitons
    const { mutateAsync: mutateAddCustomers, isLoading: isAddCustomersLoading } = useAddCustomersToOrganization();
    const { mutateAsync: mutateDeleteCustomers, isLoading: isDeleteCustomersLoading } = useDeleteCustomersFromOrganization();
    const tableActions = {
        independent_customers: {
            cb: () => mutateAddCustomers({ customer_ids: selectedIds }).then(() => {
                resetTabState();
                Toast.success({
                    title: '✔ Successfully added',
                    subTitle: 'The customer(s) added to the organization',
                });
            }),
            name: 'Add',
            icon: faUserPlus,
            isLoading: isAddCustomersLoading,
        },
        customers: {
            cb: () => mutateDeleteCustomers({ customer_ids: selectedIds }).then(() => {
                resetTabState();
                Toast.success({
                    title: '✔ Successfully removed',
                    subTitle: 'The customer(s) removed from the organization',
                });
            }),
            name: 'Remove',
            icon: faTrash,
            isLoading: isDeleteCustomersLoading,
        },
    };
    return {
        tab,
        tabs,
        search,
        filter,
        selectedIds,
        showSelected,
        data: filteredData,
        action: tableActions[tab],
        isAllChecked,
        isSomeChecked,
        setTab,
        resetTabState,
        toggleShowSelected,
        setSearch,
        setFilter,
        handleSelectItem,
        handleSelectAll,
    };
};
const [, useCustomersTableContext, withCustomersTableContext] = createContextPair(useContextData);
export { useCustomersTableContext, withCustomersTableContext };
