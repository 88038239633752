import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ChipsInputField, SwitcherField } from '~/common/components';
import { isEmail, isWildcardEmail } from '~/common/utils';
import { FormSectionHeader } from './FormSectionHeader';
const parseChipInputPaste = (paste) => {
    return [
        ...new Set(paste
            .split(/[,\s\t\n]+/)
            .map((item) => item.trim())
            .filter((item) => isEmail(item) || isWildcardEmail(item))
            .map((item) => (isEmail(item) ? '*' + item.split('@')[1] : item))),
    ];
};
export const EditPortalAndDomainsInfoView = ({ fieldProps, values, isStandalone, }) => {
    const customPortalToggleHandler = (isCustomPortalEnabled) => {
        fieldProps('custom_portal_enabled').onChange(isCustomPortalEnabled);
        if (!isCustomPortalEnabled)
            fieldProps('autoassign_on_new_order').onChange(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(FormSectionHeader, { title: "Allowed email domains", hint: "Only users with these specific email domain will be allowed to join the organization" }), _jsxs("div", { className: "px-1 space-y-2 mb-4", children: [_jsx(ChipsInputField, { ...fieldProps('email_domains'), placeholder: "Enter domain(-s)", parsePaste: parseChipInputPaste, validateChip: isWildcardEmail }), _jsx(SwitcherField, { ...fieldProps('autoassign_on_signup'), theme: "dark", title: "Auto-adding new customers matching listed domains", className: "inline-flex flex-row-reverse gap-x-1 h-3" }), _jsx(SwitcherField, { ...fieldProps('custom_billing_enabled'), theme: "dark", title: "Enable custom billing process", className: "inline-flex flex-row-reverse gap-x-1 h-3", hint: "Do not enable without agreement with finance" }), isStandalone && (_jsx(SwitcherField, { ...fieldProps('sync_existing_customers'), theme: "dark", title: "Add existing customers matching listed domains", className: "inline-flex flex-row-reverse gap-x-1 h-3" }))] }), _jsx(FormSectionHeader, { title: "Custom portal", optional: true }), _jsxs("div", { className: "px-1 gap-2 flex flex-col items-start", children: [_jsx(SwitcherField, { ...fieldProps('custom_portal_enabled'), onChange: customPortalToggleHandler, theme: "dark", title: "Enable the ability to use custom portal", className: "inline-flex flex-row-reverse gap-x-1 h-3" }), _jsx(SwitcherField, { ...fieldProps('autoassign_on_new_order'), theme: "dark", disabled: !values.custom_portal_enabled, title: "Automatically add customers to this organization", className: "inline-flex flex-row-reverse gap-x-1 h-3" })] })] }));
};
