import { useMutation, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { z } from 'zod';
import { useIdParam } from '~/common/hooks';
import { UserAvatarInfo } from '~/common/kits/usercard';
import { assertQueryData, httpClient } from '~/common/utils';
import { GetOrder } from '~/orders/domain';
import { useCurrentUserData } from '~/root';
import { qk } from '~/root/query-keys';
import { useUpdateOrderCache } from './useUpdateOrderCache';
/**
 * This hook internally uses route param `id` to fetch order data.
 * If `id` param is not valid (is not of type `Integer`), then an error is
 * thrown
 */
export function useOrder() {
    const id = useIdParam();
    return useQuery({
        queryKey: qk.orderBase(id),
        queryFn: () => httpClient.get(`/v1/staff/orders/${id}`, { decoder: GetOrder }),
    });
}
export function useOrderData() {
    return assertQueryData(useOrder());
}
export const useOrderFollowers = () => {
    const currentUser = useCurrentUserData();
    const { followers, can_unfollow } = useOrderData();
    const isFollowing = useMemo(() => {
        return followers.some((follower) => follower.id === currentUser.id);
    }, [followers, currentUser === null || currentUser === void 0 ? void 0 : currentUser.id]);
    return {
        isFollowing,
        followers,
        canUnfollow: can_unfollow,
    };
};
export const useOrderFollowing = () => {
    const id = useIdParam();
    const updateCache = useUpdateOrderCache();
    return useMutation({
        mutationFn: (actionType) => httpClient.post(`v1/staff/orders/${id}/${actionType}`, { decoder: z.array(UserAvatarInfo) }),
        onSuccess: (followers) => {
            updateCache((order) => ({ ...order, followers }));
        },
    });
};
