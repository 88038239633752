import { faFunnelDollar, faPalette, faStars, faUserCrown, faUsers, } from '@fortawesome/pro-regular-svg-icons';
import { faHeadset, faMagic, faTasks } from '@fortawesome/pro-solid-svg-icons';
export const getFilterOptions = (init) => init
    ? {
        office: init.offices,
        team: init.teams,
        search: [],
    }
    : null;
export const roleToIcon = {
    designer: faPalette,
    design_manager: faMagic,
    regular_pm: faTasks,
    subscription_pm: faStars,
    customer_supporter: faHeadset,
    sales_manager: faFunnelDollar,
    admin: faUserCrown,
    other: faUsers,
};
