/* eslint-disable @typescript-eslint/ban-ts-comment */
//
// https://stackoverflow.com/questions/4331092/finding-all-combinations-cartesian-product-of-javascript-array-values :)
// should we type this better? I'm not sure...
// this is really hacky, but outside of this file everything works good :)
import { sortBy } from 'lodash-es';
function* cartesian(head, ...tail) {
    // @ts-ignore
    const remainder = tail.length ? cartesian(...tail) : [[]];
    for (const r of remainder)
        for (const h of head)
            yield [h, ...r];
}
export const getVariants = ({ variants, omit, sort, }) => {
    const keys = Object.keys(variants);
    // @ts-ignore
    const result = [...cartesian(...Object.values(variants))].reduce((variants, variantValues) => {
        const variant = {};
        for (let i = 0; i < keys.length; i++) {
            variant[keys[i]] = variantValues[i];
        }
        if (!omit || omit(variant)) {
            variants.push(variant);
        }
        return variants;
    }, []);
    return sort ? sortBy(result, sort) : result;
};
