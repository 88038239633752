import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Input } from '~/common/components';
import { isNonNullable } from '~/common/utils';
import { Errors } from './Errors';
const removeLeadingZerosRegex = (num) => num.toString().replace(/^0+(?=\d)/, '');
export const InputField = ({ errors, onChange, touched, type, value, nullable = true, ...props }) => {
    const handleChange = (event) => {
        switch (type) {
            case 'text':
                return onChange(event.currentTarget.value);
            case 'number':
                const value = event.currentTarget.valueAsNumber;
                return onChange(Number.isNaN(value) ? (nullable ? null : 0) : value);
        }
    };
    return (_jsxs("div", { children: [_jsx(Input, { ...props, onChange: handleChange, value: isNonNullable(value) ? (nullable ? value : removeLeadingZerosRegex(value)) : '', error: !!errors, type: type }), errors && _jsx(Errors, { errors: errors, color: true })] }));
};
