import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faCheck, faMinus } from '@fortawesome/pro-solid-svg-icons';
import { useMemo } from 'react';
import { Button, IconBox, useModalContext } from '~/common/components';
import { cx, tw } from '~/common/utils';
import { useCurrentUserData } from '~/root';
import { useInitData, useUserData } from '../hooks';
import { ManagePermissions } from './ManagePermissions';
import { getPermissionSections } from './utils';
const Permission = ({ text, active = false }) => {
    return (_jsxs("li", { className: "flex items-center font-brand-b1 text-greyscale-400", children: [_jsx(IconBox, { size: "s", className: cx({ 'text-primary-300': active }), icon: active ? faCheck : faMinus }), _jsx("span", { className: cx('ml-1', { 'text-greyscale-600': active }), children: text !== null && text !== void 0 ? text : '—' })] }));
};
const Section = ({ text, permissions }) => (_jsxs("div", { children: [_jsx("div", { className: "font-brand-t3 text-greyscale-600 uppercase", children: text }), _jsx("ul", { className: "m-0 mt-1 space-y-1", children: permissions.map((p) => (_jsx(Permission, { text: p.label, active: p.active }, p.name))) })] }));
const permissionHeadingClassName = tw `
  flex items-center justify-between
  pl-3 pr-1 h-6
  border-b border-b-greyscale-200 border-solid
`;
export const Permissions = () => {
    const init = useInitData();
    const user = useUserData();
    const { permissions } = useCurrentUserData();
    const { modalOpener } = useModalContext();
    const permissionsColumns = useMemo(() => {
        return getPermissionSections(init.permissions, user.permissions);
    }, [init.permissions, user.permissions]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: permissionHeadingClassName, children: [_jsx("span", { className: "font-brand-t3 text-greyscale-500 uppercase", children: "Permissions" }), permissions.usersPermissionsUpdate && (_jsx(Button, { onClick: modalOpener(ManagePermissions), color: "grey", children: "Edit" }))] }), _jsx("div", { className: "flex px-4 py-3 spacing-x-4", children: permissionsColumns.map((column, index) => (_jsx("div", { className: "w-1/2 space-y-4", children: column.map(({ name, permissions }) => (_jsx(Section, { text: name, permissions: permissions }, name))) }, index))) })] }));
};
