import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Container, DayHeader, getCellHeightStyle, StickyLeftTableCell, StickyTopTableCell, } from '~/common/components';
import { cx, formatMonthDay } from '~/common/utils';
import { useShiftsContext } from '../context';
import { useShiftEvents, useShiftsData } from '../hooks';
import { isDesignTeamId, SHIFTS_GRID_CONTAINER_ID, TEAM_COLUMN_WIDTH } from '../utils';
import { Row } from './Row';
import css from './Grid.module.scss';
const filterTeams = (tab, teams, officeId) => {
    let filteredTeams = teams;
    if (officeId) {
        filteredTeams = teams.reduce((acc, team) => {
            var _a;
            if ('office' in team) {
                if (((_a = team.office) === null || _a === void 0 ? void 0 : _a.id) === officeId) {
                    acc.push(team);
                }
            }
            if (team.members.some((member) => member.office_id === officeId)) {
                acc.push({
                    ...team,
                    members: team.members.filter((member) => member.office_id === officeId),
                });
            }
            return acc;
        }, []);
    }
    switch (tab) {
        case 'all':
            return filteredTeams;
        case 'pm':
            return filteredTeams.filter((team) => team.name === 'PM');
        case 'support':
            return filteredTeams.filter((team) => team.name === 'Support');
        case 'design':
            return filteredTeams.filter((team) => isDesignTeamId(team.id));
    }
};
export const Grid = () => {
    const todayLabel = useMemo(() => formatMonthDay(new Date()), []);
    const { days, teams } = useShiftsData();
    const { tab, officeId } = useShiftsContext();
    useShiftEvents();
    return (
    // we'd better avoid re-rendering whole subtree in case only column size
    // was recalculated, hence the container, not hook
    _jsx(Container, { id: SHIFTS_GRID_CONTAINER_ID, infoColumnWidth: TEAM_COLUMN_WIDTH, columns: days.length, className: "rounded bg-greyscale-0 select-none h-full overflow-auto", children: _jsxs("table", { className: cx(css.table, 'border-0'), children: [_jsx("thead", { children: _jsxs("tr", { style: getCellHeightStyle(40), children: [_jsx(StickyLeftTableCell, { className: "rounded-tl", width: TEAM_COLUMN_WIDTH }), days.map((day) => (_jsx(StickyTopTableCell, { className: cx(todayLabel === day.label && css.todayCell), children: _jsx(DayHeader, { label: day.label, today: todayLabel === day.label }) }, day.label)))] }) }), _jsx("tbody", { children: filterTeams(tab, teams, officeId).map((team, index, teams) => (_jsx(Row, { team: team, days: days, todayLabel: todayLabel, lastRow: index + 1 === teams.length }, team.id))) })] }) }));
};
