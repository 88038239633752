import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { Avatar, IconButton, LineBreaks, ParseLinks, Show } from '~/common/components';
import { cx, formatDate, getColorsFromNumber } from '~/common/utils';
import { useAnnotationInfo, useConfirmDeletion, useRemoveAnnotationComment } from '~/orders/hooks';
import { ConfirmDeletion } from '~/orders/Order';
export const Comment = ({ annotationId, canRemove, comment }) => {
    var _a;
    const { revisionId, slideId } = useAnnotationInfo();
    const { handleCancel, handleMouseEnter, handleMouseLeave, handleRemove, showConfirmation, showRemoveButton, } = useConfirmDeletion(canRemove);
    const removeAnnotationComment = useRemoveAnnotationComment();
    const handleConfirmRemove = () => {
        removeAnnotationComment.mutate({ revisionId, slideId, annotationId, commentId: comment.id });
    };
    return (_jsxs("div", { className: "px-2 space-y-1", onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, children: [_jsxs("div", { className: "flex items-center gap-2", children: [_jsx(Avatar, { colorSet: getColorsFromNumber(comment.user.id), url: (_a = comment.user.avatar) === null || _a === void 0 ? void 0 : _a.link, children: comment.user.initials }), _jsxs("div", { className: "flex flex-col", children: [_jsx("span", { className: "font-brand-t5 text-greyscale-600", children: comment.user.name }), _jsx("span", { className: "font-brand-c1r text-greyscale-500", children: formatDate(comment.created) })] }), _jsx(Show, { if: canRemove && !showConfirmation, children: _jsx(IconButton, { size: "m", className: cx('ml-auto outline-none transition-brand text-inherit hover:text-danger-400 active:text-danger-400', !showRemoveButton && 'hidden'), icon: faTrashAlt, onClick: handleRemove }) }), _jsx(Show, { if: showConfirmation, children: _jsx(ConfirmDeletion, { isLoading: removeAnnotationComment.isLoading, onCancel: handleCancel, onDelete: handleConfirmRemove }) })] }), _jsx(LineBreaks, { className: "font-brand-b1 text-greyscale-500", renderParagraph: (paragraph) => _jsx(ParseLinks, { children: paragraph }), children: comment.comment })] }));
};
