import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { number } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { Button, SelectField } from '~/common/components';
import { toggleElement } from '~/common/utils';
import { BadgeMultiSelect } from '~/orders/Order';
export const DesignTeam = ({ designTeams, designers, designersFieldProps, disabled, onRemoveTeam, teamFieldProps, }) => {
    const onToggleDesigner = (option) => designersFieldProps.onChange(pipe(designersFieldProps.value, toggleElement(option.value, number.Eq, number.Ord)));
    return (_jsxs("div", { className: "flex mt-2 space-x-4", children: [_jsxs("div", { className: "flex items-center flex-grow h-fit w-1/2 space-x-1", children: [_jsx(SelectField, { ...teamFieldProps, className: "w-full", disabled: disabled, options: designTeams, placeholder: "Choose from a list" }), onRemoveTeam && (_jsx(Button.Icon, { className: "self-start mt-[4px]", size: "m", icon: faTimes, onClick: onRemoveTeam }))] }), _jsx("div", { className: "w-1/2", children: _jsx(BadgeMultiSelect, { disabled: disabled, options: designers, onToggleOption: onToggleDesigner, selectedOptions: designersFieldProps.value, showOptionValue: number.Show }) })] }));
};
