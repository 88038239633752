/**
 * Narrow value's type if predicate is true, otherwise throws an error
 *
 * Useful when you want to narrow a type of some value which you definitely
 * sure about (no need to use type assertions when narrowing to some refined
 * type)
 *
 * Example:
 *
 * // Unsafe constructor for positive integers
 * const positiveInteger = unsafeRefine(isPositiveInteger);
 *
 * // Works just fine, posInt is of type PositiveInteger
 * const posInt = positiveInteger(10);
 *
 * // Throws an error
 * const wrongPosInt = positiveIntenger(-10)
 */
export function unsafeRefine(refinement) {
    return (input) => {
        if (refinement(input))
            return input;
        throw new Error('Invalid value provided!');
    };
}
