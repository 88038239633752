import { useMutation } from '@tanstack/react-query';
import { map, nonEmptyArray, option } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { z } from 'zod';
import { useIdParam } from '~/common/hooks';
import { eqPositiveInteger, httpClient } from '~/common/utils';
import { Message } from '~/orders/domain';
import { qk } from '~/root/query-keys';
import { useUpdateMessagesCache } from './useUpdateMessagesCache';
export function useSendMessage({ isNote, onSuccess }) {
    const id = useIdParam();
    const updateCache = useUpdateMessagesCache();
    const { mutateAsync, isLoading } = useMutation(qk.orderChatSendMessage(id), (params) => {
        const payload = typeof params === 'string'
            ? { content: params }
            : params.files.length
                ? { content: params.message, file_ids: params.files }
                : { content: params.message };
        return httpClient.post(`/v1/staff/orders/${id}/comments`, {
            data: {
                ...payload,
                is_note: isNote,
            },
            decoder: z.object({ comment: Message }),
        });
    }, {
        onSuccess: ({ comment: message }) => {
            // TODO dedupe with src/orders/hooks/chat/useMessageEvents.ts
            updateCache((messages) => ({
                ...messages,
                pages: pipe(messages.pages, nonEmptyArray.fromArray, option.map((pages) => pipe(pages, nonEmptyArray.modifyHead((page) => ({
                    ...page,
                    items: pipe(page.items, map.upsertAt(eqPositiveInteger)(message.id, message)),
                })))), option.getOrElse(() => messages.pages)),
            }));
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
        },
    });
    return [mutateAsync, isLoading];
}
