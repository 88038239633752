export const getScale = ({ scale, minScale, maxScale, scaleSensitivity, deltaScale, }) => {
    let newScale = scale + deltaScale / (scaleSensitivity / scale);
    newScale = Math.max(minScale, Math.min(newScale, maxScale));
    return newScale;
};
export const getInitialImageOffset = (containerRect, imageDimensions) => {
    return {
        x: (containerRect.width - imageDimensions.width) / 2,
        y: (containerRect.height - imageDimensions.height) / 2,
    };
};
export const getInitialImageDimensions = (containerRect, image) => {
    const { height: containerHeigth, width: containerWidth } = containerRect;
    const { naturalHeight, naturalWidth } = image;
    const containerAspectRatio = containerWidth / containerHeigth;
    const imageAspectRatio = naturalWidth / naturalHeight;
    if (naturalWidth > containerWidth || naturalHeight > containerHeigth) {
        return imageAspectRatio >= containerAspectRatio
            ? {
                width: containerWidth,
                height: (naturalHeight * containerWidth) / naturalWidth,
            }
            : {
                width: (naturalWidth * containerHeigth) / naturalHeight,
                height: containerHeigth,
            };
    }
    return { width: naturalWidth, height: naturalHeight };
};
export const getNewPanPosition = (newPosition, panLimits) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    if (!panLimits) {
        return newPosition;
    }
    return {
        x: Math.min(Math.max(newPosition.x, (_b = (_a = panLimits.x) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : newPosition.x), (_d = (_c = panLimits.x) === null || _c === void 0 ? void 0 : _c[1]) !== null && _d !== void 0 ? _d : newPosition.x),
        y: Math.min(Math.max(newPosition.y, (_f = (_e = panLimits.y) === null || _e === void 0 ? void 0 : _e[0]) !== null && _f !== void 0 ? _f : newPosition.y), (_h = (_g = panLimits.y) === null || _g === void 0 ? void 0 : _g[1]) !== null && _h !== void 0 ? _h : newPosition.y),
    };
};
const getLimitsRange = (limitation, dragLimit) => {
    if (dragLimit === undefined)
        return [undefined, undefined];
    return [-(limitation + dragLimit), dragLimit];
};
export const getPanLimitsRanges = (containerDimensions, imageDimensions, dragLimit) => {
    if (dragLimit === undefined) {
        return;
    }
    if (typeof dragLimit === 'number') {
        return {
            x: getLimitsRange(imageDimensions.width - containerDimensions.width, dragLimit),
            y: getLimitsRange(imageDimensions.height - containerDimensions.height, dragLimit),
        };
    }
    return {
        x: getLimitsRange(imageDimensions.width - containerDimensions.width, dragLimit.x),
        y: getLimitsRange(imageDimensions.height - containerDimensions.height, dragLimit.y),
    };
};
