import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faTrashAlt, faUserTag } from '@fortawesome/pro-regular-svg-icons';
import { useMemo, useState } from 'react';
import { Badge, Button, Show } from '~/common/components';
import { cx } from '~/common/utils';
import { useShiftsContext } from '../context';
import { isLeave, isShift, timelineToDiscrete, } from '../domain';
import { useInitData, useShiftsData, useTimelineCreation, useTimelineDeletion } from '../hooks';
import { getPrimaryManagerHasShifts, getTeamAndMemberFromIds, isDesignTeamId, isPrimaryManager, } from '../utils';
export const EditRegion = ({ teamId, userId, selection, selectedItems, hasSelectedItems, previousManagerState, previousOccupationId, onClose, }) => {
    const init = useInitData();
    const { teams } = useShiftsData();
    const { occupations, leaves } = init;
    const { date } = useShiftsContext();
    const { team } = useMemo(() => {
        return getTeamAndMemberFromIds(teamId, userId, teams);
    }, [teamId, userId, teams]);
    const isPrimary = isPrimaryManager(team, userId);
    const creation = useTimelineCreation();
    const deletion = useTimelineDeletion();
    const isLoading = creation.isLoading || deletion.isLoading;
    const [occupationId, setOccupationId] = useState(previousOccupationId);
    const [isManager, setIsManager] = useState(previousManagerState);
    const [pendingAction, setPendingAction] = useState(null);
    // TODO Refactor it, so this logic doesn't leak into the view
    // I guess view is only interested in passing bars there, and all discrete
    // handling logic should be outside
    const handleUpdate = () => {
        if (occupationId) {
            const timeline = timelineToDiscrete([{ ...selection, occupation: occupations[occupationId], is_manager: false }], date);
            creation
                .mutateAsync({
                team_id: teamId,
                user_id: userId,
                timeline: timeline.map((item) => {
                    var _a, _b;
                    return ({
                        ...item,
                        // if manager status for the selection was not changed and only
                        // occupation was selected, then try to preserve manager status for items
                        //
                        // if the item is of leave type however, a user can't be manager on that day
                        is_manager: isLeave(item.occupation)
                            ? false
                            : (_b = isManager !== null && isManager !== void 0 ? isManager : (_a = selectedItems.find((selectedItem) => selectedItem.offset === item.offset)) === null || _a === void 0 ? void 0 : _a.is_manager) !== null && _b !== void 0 ? _b : false,
                    });
                }),
                isPrimary,
            })
                .then(onClose);
        }
        if (!occupationId && isManager !== null) {
            creation
                .mutateAsync({
                team_id: teamId,
                user_id: userId,
                // if there are items in a selection, occupation is not selected and
                // manager status is changed then we only update manager status for
                // shift items in the selection
                timeline: selectedItems
                    .filter((item) => !isLeave(item.occupation))
                    .map((item) => ({ ...item, is_manager: true })),
                isPrimary,
            })
                .then(onClose);
        }
    };
    const handleClick = (action) => () => {
        if (
        // if selection contains no items
        !selectedItems.length ||
            // if only manager status is updated
            (isManager !== previousManagerState && occupationId === previousOccupationId) ||
            // when confirming an update
            pendingAction === 'update') {
            handleUpdate();
            return;
        }
        if (!pendingAction) {
            setPendingAction(action);
            return;
        }
        deletion
            .mutateAsync({
            team_id: teamId,
            user_id: userId,
            itemsToDelete: selectedItems.map(({ offset, date }) => ({ offset, date })),
        })
            .then(onClose);
    };
    const primaryManagerHasShifts = useMemo(() => {
        return getPrimaryManagerHasShifts(team, isPrimary, selection, date);
    }, [team, isPrimary, selection, date]);
    const shiftsSelected = selectedItems.filter(({ occupation }) => isShift(occupation)).length > 0;
    const noOccupation = occupationId ? isLeave(occupations[occupationId]) : !shiftsSelected;
    const disableSetTemporaryDM = noOccupation || primaryManagerHasShifts;
    const showUpdateExistingTooltip = !pendingAction && !occupationId && shiftsSelected;
    const occupationChanged = occupationId !== null && occupationId !== previousOccupationId;
    const isManagerChanged = isManager !== null && isManager !== previousManagerState;
    const isConfirming = pendingAction !== null;
    const canApply = occupationChanged || (isManagerChanged && hasSelectedItems) || isConfirming;
    const handleOccupationClick = (occupation) => {
        setOccupationId(occupation.id);
        if (isLeave(occupation)) {
            setIsManager(false);
        }
        if (occupationId && isLeave(occupations[occupationId]) && !isLeave(occupation)) {
            setIsManager(previousManagerState);
        }
    };
    return (_jsxs("div", { "data-stop-propagation": true, className: "pb-1", children: [_jsxs(Show, { if: !pendingAction, children: [_jsx("h3", { className: "font-brand-t4m mb-1", children: "Shifts" }), _jsx("div", { className: "flex flex-wrap gap-x-1 gap-y-[6px]", children: init.getShifts(teamId).map((shift) => (_jsx(Badge.Button, { color: occupationId === shift.id ? 'primary' : 'lighter-grey', onClick: () => handleOccupationClick(shift), children: `${shift.sign} (${shift.range})` }, shift.id))) }), _jsx("hr", { className: "mt-[12px] border-t border-t-solid border-t-greyscale-300" }), _jsx("h3", { className: "font-brand-t4m mb-1 mt-[12px]", children: "Leaves" }), _jsx("div", { className: "flex flex-wrap gap-1", children: leaves.map((leave) => (_jsx(Badge.Button, { color: occupationId === leave.id ? 'primary' : 'lighter-grey', onClick: () => handleOccupationClick(leave), children: leave.leave_type }, leave.id))) }), _jsx("hr", { className: "my-[12px] border-t border-t-solid border-t-greyscale-300" }), isDesignTeamId(teamId) && !isPrimary && (_jsx(Button, { "data-tt": cx((noOccupation && 'Please, choose a shift before') ||
                            (primaryManagerHasShifts && 'DM is on shift in selected period')), color: "text-secondary", size: "xs", onClick: () => setIsManager((v) => !v), icon: faUserTag, disabled: disableSetTemporaryDM, children: `${isManager ? 'Remove' : 'Set'} as temporary DM` })), _jsx(Button, { className: "mt-1 flex", color: "text-secondary", size: "xs", onClick: handleClick('clear'), icon: faTrashAlt, disabled: !hasSelectedItems, children: "Clear the section" })] }), _jsx(Show, { if: pendingAction !== null, children: _jsx("div", { className: "mt-1 font-brand-t4m", children: `Do you want to ${pendingAction === 'update' ? 'override' : 'clear'} selected shifts?` }) }), _jsxs("div", { className: "flex items-center justify-end gap-2 mt-2", children: [_jsx(Button, { color: "grey", onClick: onClose, disabled: isLoading, children: pendingAction ? 'No' : 'Cancel' }), _jsx(Button, { "data-tt": cx((showUpdateExistingTooltip && 'DM status will only change in existing shifts') ||
                            (!canApply && "You haven't changed anything")), color: "secondary", onClick: handleClick('update'), disabled: !canApply, loading: isLoading, children: pendingAction ? 'Yes' : 'Apply' })] })] }));
};
