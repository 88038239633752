import { z } from 'zod';
// We don't want to know any specifics about the feature flag, these are for controlling BE logic
// Pretty simple, ha :)
export const InitFeatures = z.object({
    items: z.array(z.object({
        id: z.string(),
        label: z.string(),
        description: z.string().nullable(),
    })),
    targetable: z.string(),
});
// We are not validating any of the features fields, feature key here is InitFeatures.items[number].id
export const FeatureValues = z.record(z.boolean());
