import { array, map, nonEmptyArray, option } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { useIdParam } from '~/common/hooks';
import { usePrivateChannel } from '~/common/kits/socket';
import { eqPositiveInteger } from '~/common/utils';
import { Message, MessageRemoved } from '~/orders/domain';
import { useUpdateMessagesCache } from './useUpdateMessagesCache';
export function useMessageEvents(params) {
    const { onCreated = () => null, enabled = true } = params || {};
    const id = useIdParam();
    const updateCache = useUpdateMessagesCache();
    usePrivateChannel({
        channelName: `staff.order.${id}`,
        enabled,
        events: [
            {
                event: '.order.comment.created',
                decoder: Message,
                callback: (message) => {
                    updateCache((messages) => ({
                        ...messages,
                        pages: pipe(messages.pages, nonEmptyArray.fromArray, option.map((pages) => pipe(pages, nonEmptyArray.modifyHead((page) => ({
                            ...page,
                            items: pipe(page.items, map.upsertAt(eqPositiveInteger)(message.id, message)),
                        })))), option.getOrElse(() => messages.pages)),
                    }));
                    // Dirty hack to call onCreated function after messages cache is updated
                    setTimeout(() => onCreated(message), 0);
                },
            },
            {
                event: '.order.comment.updated',
                decoder: Message,
                callback: (message) => {
                    updateCache((messages) => ({
                        ...messages,
                        pages: pipe(messages.pages, array.findIndex((page) => pipe(page.items, map.member(eqPositiveInteger)(message.id))), option.chain((index) => pipe(messages.pages, array.modifyAt(index, (page) => ({
                            ...page,
                            items: pipe(page.items, map.updateAt(eqPositiveInteger)(message.id, message), option.getOrElse(() => page.items)),
                        })))), option.getOrElse(() => messages.pages)),
                    }));
                },
            },
            {
                event: '.order.comment.deleted',
                decoder: MessageRemoved,
                callback: (id) => updateCache((messages) => ({
                    ...messages,
                    pages: pipe(messages.pages, array.findIndex((page) => pipe(page.items, map.member(eqPositiveInteger)(id))), option.chain((index) => pipe(messages.pages, array.modifyAt(index, (page) => ({
                        ...page,
                        items: pipe(page.items, map.deleteAt(eqPositiveInteger)(id)),
                    })))), option.getOrElse(() => messages.pages)),
                })),
            },
        ],
    });
}
