import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cx } from '~/common/utils';
import { Errors } from '../Input';
import styles from './RangeSelect.module.scss';
const defaultRender = (value) => value;
export const RangeSelect = ({ className, value, onChange, min, max, title, render = defaultRender, touched, errors, ...props }) => {
    const handleChange = (thumb) => (e) => {
        const next = [...value];
        next[thumb] = Number(e.target.value);
        onChange(next);
    };
    const [start, end] = value;
    const style = {
        '--min': min,
        '--max': max,
        '--a': start,
        '--b': end,
    };
    return (_jsxs("div", { children: [title && _jsx("span", { className: styles.title, children: title }), _jsx("div", { className: styles.outerWrap, children: _jsxs("div", { ...props, className: cx(styles.innerWrap, className), style: style, children: [_jsx("input", { className: styles.range, type: "range", min: min, max: max, value: start, onChange: handleChange(0) }), _jsx("input", { className: styles.range, type: "range", min: min, max: max, value: end, onChange: handleChange(1) }), _jsx("div", { className: styles.start, children: render(start) }), _jsx("div", { className: styles.end, children: render(end) })] }) }), errors && _jsx(Errors, { errors: errors, color: true })] }));
};
