import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useUpdateCache } from '~/common/hooks';
import { usePrivateChannel } from '~/common/kits/socket';
import { assertQueryData, formatYearMonthDay, httpClient, nonNullable, record, } from '~/common/utils';
import { qk } from '~/root/query-keys';
import { useShiftsContext } from './context';
import { Init, isShift, Shifts, TimelineDeleted, timelineToByOffset, timelineToRange, TimelineUpdated, } from './domain';
import { isDesignTeamId } from './utils';
export const SHIFTS_URL = '/shifts';
export const useInit = () => {
    return useQuery({
        queryKey: qk.shiftsInit,
        queryFn: () => httpClient.get(`/v1/staff/shifts/init`, { decoder: Init }),
        staleTime: Infinity,
        keepPreviousData: true,
    });
};
export const useInitData = () => assertQueryData(useInit());
export const useShifts = () => {
    const { formattedStart, formattedEnd, date, endDate } = useShiftsContext();
    const init = useInit();
    return useQuery({
        queryKey: [...qk.shiftsAll, { formattedStart, formattedEnd }],
        queryFn: () => {
            const { occupations, holidays } = nonNullable(init.data);
            return httpClient.get('/v1/staff/shifts', {
                decoder: Shifts(date, endDate, occupations, holidays),
                params: {
                    from: formattedStart,
                    to: formattedEnd,
                },
            });
        },
        keepPreviousData: true,
        enabled: Boolean(init.data),
        // TODO this is fragile
        //
        // refetch only after going to the other module and back,
        // not when selection mounts a popover with shifts data
        refetchOnMount: (query) => query.getObserversCount() === 2,
    });
};
export const useShiftsData = () => assertQueryData(useShifts());
export const useTimelineCreation = () => {
    const { date, endDate, formattedStart, formattedEnd } = useShiftsContext();
    const update = useUpdateCache([...qk.shiftsAll, { formattedStart, formattedEnd }], 'Shifts cache is not defined');
    return useMutation({
        mutationFn: ({ team_id, user_id, timeline }) => {
            const newTimeline = timelineToByOffset(timeline);
            return httpClient
                .post(`/v1/staff/shifts/timeline`, {
                data: {
                    timeline: timeline.map(({ date, occupation, is_manager }) => ({
                        user_id,
                        date: formatYearMonthDay(date),
                        occupation_id: occupation.id,
                        is_manager,
                        // virtual team_id's are used for the view updates here, but we
                        // shouldn't send them to the backend for non-designers
                        team_id: isDesignTeamId(team_id) ? team_id : null,
                    })),
                },
            })
                .then(() => newTimeline);
        },
        onSuccess: (newTimeline, { team_id, user_id, isPrimary }) => {
            // TODO revisit this logic with tests, feels like it does unneeded extra steps
            //
            // if current designer's updated timeline contains days with manager status,
            // then all other designers should get their manager days revoked
            // same if it is a primary design manager one
            const daysToUpdate = isPrimary
                ? record.values(newTimeline).filter((item) => isShift(item.occupation))
                : record.values(newTimeline).filter((item) => item.is_manager);
            update((prev) => ({
                ...prev,
                teams: prev.teams.map((team) => team_id === team.id
                    ? {
                        ...team,
                        members: team.members.map((member) => {
                            if (member.id === user_id) {
                                return updateMemberTimeline(member, newTimeline, date, endDate);
                            }
                            // updating other users design manager status
                            // if current member is not a designer or there weren't any
                            // manager days set, then manager days of other users
                            // won't be updated
                            if (team.id < 0 || !daysToUpdate.length) {
                                return member;
                            }
                            const timeline = { ...member.timeline };
                            daysToUpdate.forEach((day) => {
                                const item = timeline[day.offset];
                                if (item) {
                                    item.is_manager = false;
                                }
                            });
                            return {
                                ...member,
                                timeline,
                                rangeTimeline: timelineToRange(timeline, date, endDate),
                            };
                        }),
                    }
                    : team),
            }));
        },
    });
};
const filterMemberTimeline = (member, offsetsToDelete, date, endDate) => {
    const filteredItems = record
        .values(member.timeline)
        .filter(({ offset }) => !offsetsToDelete.has(offset));
    const timeline = timelineToByOffset(filteredItems);
    return {
        ...member,
        timeline,
        rangeTimeline: timelineToRange(timeline, date, endDate),
    };
};
const updateMemberTimeline = (member, newTimeline, date, endDate) => {
    const timeline = { ...member.timeline, ...newTimeline };
    return {
        ...member,
        timeline,
        rangeTimeline: timelineToRange(timeline, date, endDate),
    };
};
export const useTimelineDeletion = () => {
    const { date, endDate, formattedStart, formattedEnd } = useShiftsContext();
    const update = useUpdateCache([...qk.shiftsAll, { formattedStart, formattedEnd }], 'Shifts cache is not defined');
    return useMutation({
        mutationFn: ({ team_id, user_id, itemsToDelete }) => httpClient
            .delete(`/v1/staff/shifts/timeline`, {
            data: itemsToDelete.map(({ date }) => ({
                team_id: isDesignTeamId(team_id) ? team_id : null,
                user_id,
                date: formatYearMonthDay(date),
            })),
        })
            .then(() => ({
            team_id,
            user_id,
            offsetsToDelete: new Set(itemsToDelete.map(({ offset }) => offset)),
        })),
        onSuccess: ({ team_id, user_id, offsetsToDelete }) => {
            update((prev) => ({
                ...prev,
                teams: prev.teams.map((team) => team_id === team.id
                    ? {
                        ...team,
                        members: team.members.map((member) => user_id !== member.id
                            ? member
                            : filterMemberTimeline(member, offsetsToDelete, date, endDate)),
                    }
                    : team),
            }));
        },
    });
};
export const useShiftEvents = () => {
    const { date, endDate, formattedStart, formattedEnd } = useShiftsContext();
    const update = useUpdateCache([...qk.shiftsAll, { formattedStart, formattedEnd }], 'Shifts cache is not defined');
    const init = useInitData();
    const client = useQueryClient();
    usePrivateChannel({
        channelName: 'staff.shifts',
        events: [
            {
                event: '.shift.timeline.updated',
                decoder: TimelineUpdated(date, init.occupations),
                callback: (message) => {
                    update((prev) => ({
                        ...prev,
                        teams: prev.teams.map((team) => message.timeline[team.id]
                            ? {
                                ...team,
                                members: team.members.map((member) => {
                                    const eventTimeline = message.timeline[team.id][member.id];
                                    if (!eventTimeline) {
                                        return member;
                                    }
                                    return updateMemberTimeline(member, eventTimeline, date, endDate);
                                }),
                            }
                            : team),
                    }));
                },
            },
            {
                event: '.shift.timeline.deleted',
                decoder: TimelineDeleted(date),
                callback: (message) => {
                    update((prev) => ({
                        ...prev,
                        teams: prev.teams.map((team) => message.offsetsMapByUser[team.id]
                            ? {
                                ...team,
                                members: team.members.map((member) => {
                                    const offsetsToDelete = message.offsetsMapByUser[team.id][member.id];
                                    if (!offsetsToDelete) {
                                        return member;
                                    }
                                    return filterMemberTimeline(member, offsetsToDelete, date, endDate);
                                }),
                            }
                            : team),
                    }));
                },
            },
        ],
    });
    useEffect(() => {
        return () => {
            // once user leaves shifts, we should invalidate the resource, so next
            // time he comes back - we fetch fresh
            client.invalidateQueries(qk.shiftsAll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
