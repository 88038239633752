import { useMutation } from '@tanstack/react-query';
import { useIdParam } from '~/common/hooks';
import { httpClient } from '~/common/utils';
import { Order, OrderStatusMap } from '~/orders/domain';
import { qk } from '~/root/query-keys';
import { useUpdateOrderCache, } from '.';
/**constructors */
const open = (data) => ({
    status: 'Open',
    data,
});
const approved = (data) => ({
    status: 'Approved',
    data,
});
const designInProgress = (data) => ({
    status: 'DesignInProgress',
    data,
});
const completed = (data) => ({
    status: 'Completed',
    data,
});
const cancelled = (data) => ({
    status: 'Cancelled',
    data,
});
const other = (status) => ({
    status,
    data: null,
});
export const changeStatusPayload = {
    approved,
    cancelled,
    completed,
    designInProgress,
    open,
    other,
};
export function useChangeOrderStatus() {
    const id = useIdParam();
    const updateOrderCache = useUpdateOrderCache();
    const { mutateAsync } = useMutation({
        mutationFn: (payload) => httpClient.post(`v1/staff/orders/${id}/transition/${OrderStatusMap.forward[payload.status]}`, {
            data: payload.data,
            decoder: Order,
        }),
        mutationKey: qk.orderChangeStatus(id),
        onSuccess: (response) => updateOrderCache((order) => ({ ...order, ...response })),
    });
    return mutateAsync;
}
