import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { differenceInCalendarDays } from 'date-fns';
import { DatepickerField, SwitcherField } from '~/common/components';
import { pluralizeWord } from '~/common/utils';
import { SubscriptionSection } from './SubscriptionSection';
export const SubscriptionSchedule = ({ form: { fieldProps, values }, }) => {
    const onTrialEndChange = (date) => {
        fieldProps('trial_ends_at').onChange(date);
        if (date && (!values.activate_at || date > values.activate_at)) {
            fieldProps('activate_at').onChange(date);
        }
    };
    const onActivateAtChange = (date) => {
        fieldProps('activate_at').onChange(date);
        if (date && (!values.trial_ends_at || date < values.trial_ends_at)) {
            fieldProps('trial_ends_at').onChange(date);
        }
    };
    const freeTrialDuration = values.trial_ends_at
        ? differenceInCalendarDays(values.trial_ends_at, new Date())
        : null;
    return (_jsxs(SubscriptionSection, { title: "Subscription schedule", children: [_jsx(SwitcherField, { ...fieldProps('is_trial'), title: "Include a free trial period", hint: "No charge will be made or invoice sent while on the trial", className: "inline-flex flex-row-reverse gap-x-1" }), values.is_trial && (_jsxs("div", { className: "flex gap-x-2 mt-3", children: [_jsx(DatepickerField, { title: "Trial period ends on", ...fieldProps('trial_ends_at'), className: "w-[272px]", onChange: onTrialEndChange }), freeTrialDuration !== null && (_jsx("span", { className: "font-brand-t4m text-greyscale-500 mt-[35px]", children: freeTrialDuration
                            ? pluralizeWord('free trial day', freeTrialDuration)
                            : 'Activate free trial just for today' }))] })), _jsx(DatepickerField, { title: "Schedule activation on", ...fieldProps('activate_at'), className: "w-[272px] mt-3", onChange: onActivateAtChange }), _jsx("p", { className: "font-brand-b1 mt-[4px]", children: "The day when customer will be billed" })] }));
};
