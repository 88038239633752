import { useCallback, useEffect, useRef } from 'react';
import { useEvent } from '~/common/hooks';
import { getPanLimitsRanges, getScale } from './helpers';
import { usePanAndZoomState } from './usePanAndZoomState';
export const usePanAndZoom = ({ onZoom, minScale = 1, maxScale = 3, scaleSensitivity = 10, dragLimit, }) => {
    const containerRef = useRef(null);
    const imageWrapperRef = useRef(null);
    const { scale, position, startPan, pan, zoom, reset } = usePanAndZoomState();
    const onMouseMoveInWindow = useCallback((event) => {
        var _a, _b;
        event.preventDefault();
        if (dragLimit === undefined) {
            pan(event);
            return;
        }
        const containerRect = (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        const imageWrapperRect = (_b = imageWrapperRef.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect();
        if (!(containerRect && imageWrapperRect)) {
            return;
        }
        const panLimitsRanges = getPanLimitsRanges(containerRect, imageWrapperRect, dragLimit);
        pan(event, panLimitsRanges);
    }, [containerRef, dragLimit, imageWrapperRef, pan]);
    const onMouseUpInWindow = () => {
        if (containerRef.current) {
            containerRef.current.style.cursor = 'grab';
            window.removeEventListener('mouseup', onMouseUpInWindow);
            window.removeEventListener('mousemove', onMouseMoveInWindow);
        }
    };
    const onMouseDown = (event) => {
        if (containerRef.current) {
            startPan(event);
            containerRef.current.style.cursor = 'grabbing';
            containerRef.current.style.userSelect = 'none';
            window.addEventListener('mouseup', onMouseUpInWindow);
            window.addEventListener('mousemove', onMouseMoveInWindow);
        }
    };
    const onWheel = useEvent((event) => {
        var _a;
        if (!event.ctrlKey || event.target.closest('[data-stop-propagation]')) {
            return;
        }
        event.preventDefault();
        if (event.deltaY !== 0 && containerRef.current) {
            onZoom && onZoom();
            const containerRect = containerRef.current.getBoundingClientRect();
            const deltaScale = Math.sign(event.deltaY) > 0 ? -1 : 1;
            if ((scale === maxScale && deltaScale === 1) || (scale === minScale && deltaScale == -1)) {
                return;
            }
            const newScale = getScale({
                scale: scale,
                deltaScale,
                minScale,
                maxScale,
                scaleSensitivity,
            });
            if (dragLimit === undefined) {
                zoom({ scale: newScale, containerRect, event });
                return;
            }
            const imageWrapperRect = (_a = imageWrapperRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
            if (imageWrapperRect) {
                const panLimits = getPanLimitsRanges(containerRect, imageWrapperRect, dragLimit);
                zoom({ scale: newScale, containerRect, event, panLimits: panLimits });
            }
        }
    });
    useEffect(() => {
        const ref = containerRef.current;
        if (ref) {
            ref.addEventListener('wheel', onWheel, { passive: false });
            return () => {
                ref.removeEventListener('wheel', onWheel);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const changeZoom = useCallback((newZoom) => {
        if (containerRef.current) {
            const containerRect = containerRef.current.getBoundingClientRect();
            zoom({ scale: Math.max(minScale, Math.min(newZoom, maxScale)), containerRect });
        }
    }, [containerRef, maxScale, minScale, zoom]);
    return {
        scale,
        position,
        containerRef,
        imageWrapperRef,
        onMouseDown,
        changeZoom,
        reset,
    };
};
