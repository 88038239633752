import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faSitemap } from '@fortawesome/pro-regular-svg-icons';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ORGANIZATIONS_URL } from './hooks';
import { Organization, General, Activity } from './Organization';
import { OrganizationsList } from './OrganizationsList';
const Organizations = () => {
    const { path } = useRouteMatch();
    return (_jsxs(Switch, { children: [_jsx(Route, { exact: true, path: path, component: OrganizationsList }), _jsx(Route, { exact: true, path: `${path}/:id(\\d+)`, render: () => (_jsx(Organization, { children: _jsx(General, {}) })) }), _jsx(Route, { exact: true, path: `${path}/:id(\\d+)/activity`, render: () => (_jsx(Organization, { children: _jsx(Activity, {}) })) })] }));
};
const nav = {
    icon: faSitemap,
    label: 'Organizations',
    path: ORGANIZATIONS_URL,
};
export const organizationsModule = {
    canShow: (permissions) => permissions.organizationsView,
    route: {
        path: ORGANIZATIONS_URL,
        component: Organizations,
    },
    nav,
};
