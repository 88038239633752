import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { faSlidersH } from '@fortawesome/pro-regular-svg-icons';
import { useModalContext } from '~/common/components';
import { cx } from '~/common/utils';
import { useOrderData } from '~/orders/hooks';
import { CapacityDistribution, SectionButton } from '~/orders/Order';
import { hasAssignedDesigners } from '~/orders/utils';
export const Trigger = ({ type, ...props }) => {
    const order = useOrderData();
    const isDisabled = !hasAssignedDesigners(order.team);
    return (_jsxs(SectionButton, { ...props, "data-tt": cx(isDisabled && 'No designers assigned to the order'), disabled: isDisabled, icon: faSlidersH, children: ["Capacity ", type] }));
};
export const CapacityDistributionModal = ({ type }) => {
    const { modalOpener } = useModalContext();
    return _jsx(Trigger, { onClick: modalOpener(CapacityDistribution, { type }), type: type });
};
