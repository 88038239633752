import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { cx, noop } from '~/common/utils';
import { PaginationItem } from './PaginationItem';
import { getPages } from './utils';
export const Pagination = ({ page, pageAmount, className, onClick = noop }) => {
    const firstPage = page === 1;
    const lastPage = page === pageAmount;
    const clickNext = () => onClick(page - 1);
    const clickPrevious = () => onClick(page + 1);
    const makePage = ({ key, value }) => {
        const active = value === page;
        const disabled = typeof value !== 'number';
        const clickPage = () => onClick(+value);
        return (_jsx(PaginationItem, { active: active, disabled: disabled, onClick: clickPage, children: value }, key));
    };
    const Pages = getPages(page, pageAmount).map(makePage);
    return (_jsxs("ul", { className: cx('flex justify-center space-x-[4px]', className), children: [_jsx(PaginationItem, { disabled: firstPage, onClick: clickNext, icon: faChevronLeft }), Pages, _jsx(PaginationItem, { disabled: lastPage, onClick: clickPrevious, icon: faChevronRight })] }));
};
