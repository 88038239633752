import { z } from 'zod';
import { InitFeatures } from '~/common/kits/features';
import { Treatment, TreatmentsTable } from '~/common/other';
import { DateFromIsoString, Email, formatMonthToYearsAndMonth } from '~/common/utils';
import { Currency, CustomerType, getListDecoder, NumericIdNamePair, StringIdNamePair, } from '~/root';
import { RetainerProductPlan, TechPackProductPlan } from './customer';
export const TeamMemberStatus = CustomerType;
export const FilterOptions = z.object({
    custom_portal: z.array(StringIdNamePair),
    customer_role: z.array(NumericIdNamePair),
    customer_type: z.array(CustomerType),
    statuses: z.array(TeamMemberStatus),
});
export const ProductOptions = z
    .object({
    allowed_renewal_periods: z.array(z.number()),
})
    .transform(({ allowed_renewal_periods }) => ({
    allowed_renewal_periods: allowed_renewal_periods.map((period) => ({
        name: formatMonthToYearsAndMonth(period),
        value: period,
    })),
}));
export const Reasons = z.object({
    customer_suspension: z.array(StringIdNamePair),
    customer_unsuspension: z.array(NumericIdNamePair),
});
export const Manager = NumericIdNamePair;
export const Init = z
    .object({
    project_managers: z.array(Manager),
    sales: z.array(Manager),
    design_teams: z.array(NumericIdNamePair),
    reasons: Reasons,
    filter_options: FilterOptions,
    features: InitFeatures,
    currencies: z.array(StringIdNamePair),
    plans: z.object({
        retainer: z.array(RetainerProductPlan),
        techpack: z.array(TechPackProductPlan),
    }),
    product_options: z.object({
        subscription: ProductOptions,
        topup: ProductOptions,
    }),
    supported_currencies: z.array(Currency),
    treatments: z.array(Treatment),
    default_treatments: TreatmentsTable,
})
    .transform(({ supported_currencies, plans, ...init }) => ({
    ...init,
    roles: init.filter_options.customer_role,
    // TODO once new roles are added - add a separate field on BE to provide roles for the invite popup
    invite_roles: init.filter_options.customer_role.slice(1),
    statuses: init.filter_options.statuses,
    supported_currencies: supported_currencies.map((currency) => ({
        name: currency,
        value: currency,
    })),
    plans: { ...plans, all: Object.values(plans).flat() },
}));
export const CustomerListItem = z.object({
    id: z.number(),
    owner_id: z.number(),
    name: z.string(),
    email: Email,
    role: z.string(),
    type: z.array(z.number()),
    company: z.string().nullable(),
    is_deleted: z.boolean(),
    signed_up: DateFromIsoString,
    last_update: DateFromIsoString.nullable(),
});
export const CustomersList = getListDecoder(CustomerListItem);
