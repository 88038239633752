import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import { useRef, useState } from 'react';
import { Button, useModalContext } from '~/common/components';
import { cx, isNonBlankString } from '~/common/utils';
import { useSendMessage } from '~/orders/hooks';
import { SendFiles } from './SendFiles';
import css from './Chat.module.scss';
export const SendMessage = ({ isExtended, scrollToBottom }) => {
    const ref = useRef(null);
    const [message, setMessage] = useState('');
    const { modalOpener } = useModalContext();
    const [sendMessage, isLoading] = useSendMessage({
        isNote: false,
        onSuccess: () => {
            setMessage('');
            if (ref.current)
                ref.current.style.height = '24px';
            // hack works :)
            setTimeout(scrollToBottom);
        },
    });
    const handleSend = () => {
        var _a;
        if (isNonBlankString(message)) {
            sendMessage(message);
            (_a = ref.current) === null || _a === void 0 ? void 0 : _a.blur();
        }
    };
    const handleChange = (event) => {
        setMessage(event.currentTarget.value);
        if (ref.current) {
            ref.current.style.height = '24px';
            ref.current.style.height = `${ref.current.scrollHeight}px`;
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        handleSend();
    };
    return (_jsxs("div", { className: cx('flex items-end gap-1', isExtended ? 'px-2 py-[12px]' : 'p-1'), children: [_jsx(Button.Icon, { onClick: modalOpener(SendFiles), size: "m", icon: faPaperclip }), _jsx("textarea", { ref: ref, onFocus: (e) => {
                    isExtended && e.currentTarget.scrollIntoView({ behavior: 'smooth' });
                }, className: css.field, onChange: handleChange, placeholder: "Leave a message...", value: message }), _jsx(Button.Icon, { color: "secondary", type: "submit", loading: isLoading, onClick: handleSubmit, size: "m", icon: faPaperPlane })] }));
};
