/**
 *
 * A bidirectional map is a model from computer science where key – value pairs
 * have a bijective 1-1 relationship between the keys and the values. This
 * allows us not only to query by key and get a value, but also to query by the
 * value and get the key.
 *
 * Example:
 *
 * export const OrderStatusBiMap = biMap.make({
 *   New: 1,
 *   Open: 2,
 *   Approved: 3,
 *   DesignInProgress: 4,
 *   ReadyForReview: 5,
 *   ReadyToSend: 6,
 *   WaitingForCustomer: 7,
 *   FeedbackReceived: 8,
 *   Completed: 9,
 *   Cancelled: 10,
 * } as const);
 *
 * const value = OrderStatusBiMap.forward['New'];  // value infers type as 1;
 * const key = OrderStatusBiMap.reverse[1]; // value infers type as 'New' | 'Open' | 'Approved'...;
 *
 * Problem:
 *
 * Instead of creating large switch-case functions to map one union type to
 * another, we can use square-brackets notation, to easily map one type (keys in BiMap)
 * to another (values in BiMap) and vice versa.
 *
 * const OrderStatusEncoder = {
 *   encode: (status: 'New' | 'Open' | 'Approved'...): 1 | 2 | 3... => {
 *     switch (status) {
 *       case 'New':
 *         return 1;
 *       case 'Open':
 *         return 2;
 *       case 'Approved':
 *         return 3;
 *
 *       ...etc
 *     }
 *   }
 * }
 *
 * Solution:
 *
 * const OrderStatusEncoder = {
 *   encode: (status: 'New' | 'Open'...): 1 | 2... => OrderStatusBiMap.original[status],
 * };
 *
 * const OrderStatusDecoder = pipe(
 *   D.literal(1, 2, 3, 4, 5, 6, 7, 8, 9, 10),
 *   D.map((status: 1 | 2...): 'New' | 'Open'... => OrderStatusBiMap.reverse[status]),
 * );
 */
import { array, tuple } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { entries, fromEntries } from './record';
function reverse(bm) {
    return pipe(bm, entries, array.map(tuple.swap), fromEntries);
}
export function make(bm) {
    return {
        forward: bm,
        reverse: reverse(bm),
    };
}
