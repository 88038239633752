import { jsx as _jsx } from "react/jsx-runtime";
import { hsluvToHex } from 'hsluv';
import { InputField } from '~/common/components';
import { usePersistentState } from '~/common/hooks';
import { noop, range, record } from '~/common/utils';
import { AvatarList } from './AvatarList';
const defaultColorParams = {
    fgSaturation: 65,
    fgLightness: 65,
    bgSaturation: 30,
    bgLightness: 95,
    brSaturation: 35,
    brLightness: 90,
    quantity: 30,
};
const titles = {
    fgSaturation: 'Color saturation',
    fgLightness: 'Color lightness',
    bgSaturation: 'Background saturation',
    bgLightness: 'Background lightness',
    brSaturation: 'Border saturation',
    brLightness: 'Border lightness',
    quantity: 'Quantity',
};
const getColors = (i, colorParams) => {
    const hue = i * Math.round(360 / colorParams.quantity);
    return {
        color: hsluvToHex([hue, colorParams.fgSaturation, colorParams.fgLightness]),
        backgroundColor: hsluvToHex([hue, colorParams.bgSaturation, colorParams.bgLightness]),
        borderColor: hsluvToHex([hue, colorParams.brSaturation, colorParams.brLightness]),
    };
};
export const GeneratedAvatars = ({ isColorSelected, toggleColor }) => {
    const [colorParams, setColorParams] = usePersistentState('colorParams', defaultColorParams);
    const setParams = (field) => ({
        onChange: (value) => {
            const limit = (value) => {
                const upper = field === 'quantity' ? 360 : 100;
                return value > upper ? upper : value < 0 ? 0 : value;
            };
            setColorParams((params) => ({
                ...params,
                [field]: limit(value),
            }));
        },
        value: colorParams[field],
    });
    const colorSets = range(0, colorParams.quantity - 1).map((i) => getColors(i, colorParams));
    return (_jsx(AvatarList, { title: "Generated Avatars", colorSets: colorSets, isColorSelected: isColorSelected, toggleColor: toggleColor, children: record.keys(colorParams).map((key) => (_jsx(InputField, { className: "w-20", title: titles[key], onBlur: noop, disabled: false, touched: false, errors: null, type: "number", size: "small", ...setParams(key) }, key))) }));
};
