import { isFuture } from 'date-fns';
import { validateForm } from 'formoid';
import { useEffect, useMemo, useState } from 'react';
import { useEvent } from '~/common/hooks';
import { propagateBackendValidationErrors, record, scrollToError } from '~/common/utils';
import { getProductInitialValues, paymentMethodIdValidator, productsSharedValidators, transformFormValuesToProductInput, transformProductPlanToFormValues, useProductPrice, } from '~/customers/Customer';
import { subscriptionStatusMap, } from '~/customers/domain';
import { useInitData, useSubscriptionData, useSubscriptionUpdateMutation, } from '~/subscriptions/hooks';
export const useUpdateSubscriptionForm = ({ form, values, initialValues, products, }) => {
    var _a;
    const { filter_options: { plan: productsList }, project_managers, design_teams, supported_currencies, product_options, default_treatments, } = useInitData();
    const subscription = useSubscriptionData();
    const productPrice = useProductPrice(subscription.owner_id);
    const updateSubscription = useSubscriptionUpdateMutation();
    const [initialPrice, setInitialPrice] = useState();
    const haveIndividualPricing = useMemo(() => JSON.stringify(subscription.treatments) !== JSON.stringify(default_treatments), [subscription.treatments, default_treatments]);
    const isInvoicePaymentMethodSelected = form.values.payment_method_id === ((_a = subscription.invoicePaymentMethod) === null || _a === void 0 ? void 0 : _a.id);
    const isGracePeriod = Boolean(subscription.status.value === subscriptionStatusMap.cancelled &&
        subscription.ends_at &&
        isFuture(subscription.ends_at));
    const updates = getUpdateChanges({
        formValues: {
            ...form.values,
            retainer_credits: form.values.retainer_supports_credits ? form.values.retainer_credits : 0,
            retainer_daily_points: form.values.retainer_supports_daily_points
                ? form.values.retainer_daily_points
                : 0,
        },
        initialValues,
        paymentMethods: subscription.payment_methods,
        productsList: productsList.all,
    });
    const isDirty = !record.shallowEqual(form.values, initialValues);
    const isPriceChanged = productPrice.price
        ? record
            .keys(productPrice.price)
            .some((fieldName) => { var _a; return ((_a = productPrice.price) === null || _a === void 0 ? void 0 : _a[fieldName]) !== (initialPrice === null || initialPrice === void 0 ? void 0 : initialPrice[fieldName]); })
        : false;
    const productsMap = useMemo(() => {
        const freeOptionsMap = products.list.reduce((accum, { type }) => {
            if (type) {
                accum[type] = false;
            }
            return accum;
        }, Object.keys(productsList).reduce((accum, key) => {
            if (key !== 'all') {
                accum[key] = true;
            }
            return accum;
        }, {}));
        const freeOptions = Object.entries(freeOptionsMap).reduce((accum, [key, val]) => {
            if (val) {
                accum = [
                    ...accum,
                    ...productsList[key].map(({ value, name }) => ({ name, value })),
                ];
            }
            return accum;
        }, []);
        return products.list.map((product) => {
            let options;
            if (product.type) {
                const ownTypeOptions = productsList[product.type].map(({ value, name }) => ({
                    name,
                    value,
                }));
                options = product.id ? ownTypeOptions : [...ownTypeOptions, ...freeOptions];
            }
            else {
                options = freeOptions;
            }
            return {
                product,
                options,
                removable: !product.id,
            };
        });
    }, [products.list, productsList]);
    const calculatePrice = async (payload) => productPrice.calculate({ ...payload, subscription_id: subscription.id }).catch((error) => {
        propagateBackendValidationErrors(error, form.setErrors);
        productPrice.reset();
    });
    const validatePriceCalculation = useEvent(async (values) => {
        const productValidators = products.validators(values);
        const validateValues = await validateForm(values, {
            ...productValidators,
            ...productsSharedValidators,
            ...paymentMethodIdValidator,
        });
        if (validateValues.id === 'Success' && !products.list.some(({ type }) => type === null)) {
            calculatePrice({
                product_type: 'subscription',
                subscription_id: subscription.id,
                payment_method_id: values.payment_method_id,
                currency: values.currency,
                renewal_period: values.renewal_period,
                products: products.list
                    .map(({ id, type }) => transformFormValuesToProductInput({
                    id,
                    type: type,
                    values,
                }))
                    .filter(Boolean),
            });
        }
        else {
            productPrice.reset();
        }
    });
    const onPriceRelatedFieldChange = (fieldName) => (value) => {
        form.fieldProps(fieldName).onChange(value);
        if (value === null) {
            productPrice.reset();
        }
        else {
            validatePriceCalculation({
                ...form.values,
                [fieldName]: value,
            });
        }
    };
    const onPriceRelatedFieldBlur = (fieldName) => () => {
        form.fieldProps(fieldName).onBlur();
        validatePriceCalculation(form.values);
    };
    const onPaymentMethodChange = (value) => {
        var _a, _b, _c;
        onPriceRelatedFieldChange('payment_method_id')(value);
        const invoiceMethodPO = (_a = subscription.invoicePaymentMethod) === null || _a === void 0 ? void 0 : _a.po_number;
        if (form.values.payment_method_id === ((_b = subscription.invoicePaymentMethod) === null || _b === void 0 ? void 0 : _b.id)) {
            form.setValues((prevFormValues) => ({
                ...prevFormValues,
                po_number: '',
                po_expires_at: null,
                prevent_sending_invoice: false,
            }));
        }
        else if (value === ((_c = subscription.invoicePaymentMethod) === null || _c === void 0 ? void 0 : _c.id) && invoiceMethodPO) {
            form.setValues((prevFormValues) => ({
                ...prevFormValues,
                po_number: invoiceMethodPO,
            }));
        }
    };
    const setPlanValues = useEvent(({ value, type }) => {
        form.setValues((prevFormValues) => {
            const plan = productsList.all.find((product) => product.value === value);
            const transformedPlanValues = transformProductPlanToFormValues(plan);
            validatePriceCalculation({
                ...prevFormValues,
                ...transformedPlanValues,
                [`${type}_plan`]: value,
            });
            return {
                ...prevFormValues,
                ...transformedPlanValues,
            };
        });
    });
    const onPlanChange = (index) => (value) => {
        var _a;
        const type = (_a = productsList.all.find(({ value: productId }) => value === productId)) === null || _a === void 0 ? void 0 : _a.type;
        form.fieldProps(`${type}_plan`).onChange(value);
        products.updateProductType({
            index,
            type,
        }, () => setPlanValues({ value, type }));
    };
    const addProduct = (type) => {
        products.addProduct(type);
    };
    const removeProduct = (index) => {
        const { type } = products.removeProduct(index, () => validatePriceCalculation(form.values));
        if (type) {
            const removedProductResetValues = getProductInitialValues(type);
            form.setValues((values) => ({
                ...values,
                ...removedProductResetValues,
            }));
        }
    };
    const getPayloadFromForm = (formValues) => formValuesToPayload({
        formValues,
        invoicePaymentMethod: subscription.invoicePaymentMethod,
        products: products.list,
    });
    const submit = (onSuccess) => form.handleSubmit({
        onSuccess: (formValues) => updateSubscription
            .mutateAsync(getPayloadFromForm(formValues))
            .then(() => {
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
        })
            .catch((error) => {
            scrollToError();
            propagateBackendValidationErrors(error, form.setErrors);
        }),
        onFailure: scrollToError,
    });
    useEffect(() => {
        const formValues = values ? { ...form.values, ...values } : form.values;
        productPrice
            .calculate({
            product_type: 'subscription',
            subscription_id: subscription.id,
            payment_method_id: formValues.payment_method_id,
            currency: formValues.currency,
            renewal_period: formValues.renewal_period,
            products: products.list
                .map(({ id, type }) => transformFormValuesToProductInput({
                id,
                type: type,
                values: formValues,
            }))
                .filter(Boolean),
        })
            .then(setInitialPrice)
            .catch((error) => {
            scrollToError();
            propagateBackendValidationErrors(error, form.setErrors);
            productPrice.reset();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return {
        form,
        updates,
        isDirty,
        productsMap,
        isGracePeriod,
        haveIndividualPricing,
        ownerId: subscription.owner_id,
        isCustomerTrusted: subscription.is_trusted,
        price: isPriceChanged ? productPrice.price : undefined,
        isLoading: productPrice.isLoading || updateSubscription.isLoading,
        invoicePaymentMethod: subscription.invoicePaymentMethod,
        renewalPeriodOptions: product_options.subscription.allowed_renewal_periods,
        supportedCurrencies: supported_currencies,
        projectManagers: project_managers,
        designTeams: design_teams,
        invoicePreviewData: form.values.payment_method_id !== null &&
            isInvoicePaymentMethodSelected &&
            form.values.renewal_period
            ? {
                product_type: 'subscription',
                subscription_id: subscription.id,
                payment_method_id: form.values.payment_method_id,
                products: products.list
                    .map(({ id, type }) => transformFormValuesToProductInput({
                    id,
                    type: type,
                    values: form.values,
                }))
                    .filter(Boolean),
                currency: form.values.currency,
                renewal_period: form.values.renewal_period,
                custom_lines: form.values.custom_lines,
                attention: form.values.attention,
            }
            : undefined,
        paymentMethods: subscription.payment_methods,
        isInvoicePaymentMethodSelected,
        onPriceRelatedFieldChange,
        onPriceRelatedFieldBlur,
        onPaymentMethodChange,
        getPayloadFromForm,
        removeProduct,
        onPlanChange,
        addProduct,
        submit,
    };
};
const fieldName = {
    renewal_period: 'Renewal period',
    payment_method_id: 'Payment method',
    po_number: 'PO number',
    attention: 'Attention to',
    custom_lines: 'Custom invoice lines',
    techpack_plan: 'Product',
    techpack_amount: 'Monthly price',
    techpack_enable_custom_portal: 'Custom portal',
    techpack_enable_custom_fields: 'Custom fields',
    techpack_enable_sso: 'Single sign on',
    retainer_plan: 'Product',
    retainer_amount: 'Monthly price',
    retainer_credits: 'Monthly credits',
    retainer_daily_points: 'Daily points',
    retainer_enable_topup: 'Automatic top up',
    retainer_enable_addons: 'Proofreading/editing',
};
const generalUpdateFields = [
    'renewal_period',
    'payment_method_id',
    'po_number',
    'attention',
    'custom_lines',
];
const techpackUpdateFields = [
    'techpack_plan',
    'techpack_amount',
    'techpack_enable_custom_portal',
    'techpack_enable_custom_fields',
    'techpack_enable_sso',
];
const retainerUpdateFields = [
    'retainer_plan',
    'retainer_amount',
    'retainer_credits',
    'retainer_daily_points',
    'retainer_enable_topup',
    'retainer_enable_addons',
];
const formatUpdateValue = (value) => {
    if (typeof value === 'boolean') {
        return value ? 'On' : 'Off';
    }
    if (value === null) {
        return value;
    }
    return String(value);
};
const formatPaymentMethod = (value, paymentMethods) => {
    if (value === null) {
        return null;
    }
    const paymentMethod = paymentMethods.find(({ id }) => id === value);
    if ('brand' in paymentMethod) {
        return `${paymentMethod.brand} **** ${paymentMethod.last4}`;
    }
    return 'bank transfer';
};
const formatProductPlan = (productId, productsList) => {
    if (productId === null) {
        return null;
    }
    const product = productsList.find(({ value }) => value === productId);
    return product.name;
};
const getUpdatesExtractor = ({ formValues, initialValues, paymentMethods, productsList, }) => (fieldsArray) => fieldsArray.reduce((updates, field) => {
    if (formValues[field] === initialValues[field]) {
        return updates;
    }
    let before, after;
    if (field === 'payment_method_id') {
        before = formatPaymentMethod(initialValues[field], paymentMethods);
        after = formatPaymentMethod(formValues[field], paymentMethods);
    }
    else if (field === 'retainer_plan' || field === 'techpack_plan') {
        before = formatProductPlan(initialValues[field], productsList);
        after = formatProductPlan(formValues[field], productsList);
    }
    else {
        before = formatUpdateValue(initialValues[field]);
        after = formatUpdateValue(formValues[field]);
    }
    updates.push({
        name: fieldName[field],
        before,
        after,
    });
    return updates;
}, []);
const getUpdateChanges = (args) => {
    const extractUpdates = getUpdatesExtractor(args);
    const general = extractUpdates(generalUpdateFields);
    const techpack = extractUpdates(techpackUpdateFields);
    const retainer = extractUpdates(retainerUpdateFields);
    const changes = { general };
    if (techpack.length) {
        changes.techpack = techpack;
    }
    if (retainer.length) {
        changes.retainer = retainer;
    }
    return changes;
};
export const formValuesToPayload = ({ formValues: { currency, renewal_period, payment_method_id, po_number, po_expires_at, attention, custom_lines, prevent_sending_invoice, schedule_update_at, ...values }, invoicePaymentMethod, products, }) => {
    const subscription = {
        currency,
        renewal_period,
        schedule_update_at,
    };
    const payload = {
        subscription,
        payment_method: {
            payment_method_id,
            po_number: po_number || undefined,
            po_expires_at: po_expires_at || undefined,
        },
        products: products
            .filter(({ type }) => Boolean(type))
            .map(({ id, type }) => transformFormValuesToProductInput({
            id,
            type: type,
            values,
        })),
    };
    if (payment_method_id === (invoicePaymentMethod === null || invoicePaymentMethod === void 0 ? void 0 : invoicePaymentMethod.id)) {
        payload.invoice_details = { attention, custom_lines, prevent_sending_invoice };
    }
    return payload;
};
