import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faCog } from '@fortawesome/pro-solid-svg-icons';
import posthog from 'posthog-js';
import { Link as RouterLink } from 'react-router-dom';
import { Avatar, Button, Menu } from '~/common/components';
import { addQueryParams, cx, getColorsFromNumber, getInitials } from '~/common/utils';
import { ENV, SERVER_URL } from '~/env';
import { NotificationsPopover } from '~/notifications';
import styles from './LayoutTopHeader.module.scss';
const NOT_LOGGED_IN_COLOR_SET_INDEX = 10;
export const LayoutTopHeader = ({ user }) => {
    const isLoggedIn = Boolean(user);
    const username = (user === null || user === void 0 ? void 0 : user.name) || 'Not logged in';
    const onLogout = () => {
        posthog.reset();
        window.location.href = addQueryParams(`${SERVER_URL}/v1/users/logout`, {
            redirect: window.location,
        });
    };
    return (_jsxs("div", { className: cx('layout-top-header', styles.container), children: [!ENV.PRODUCTION && (_jsx("div", { className: "flex items-center justify-center h-7 w-7 opacity-0 hover:opacity-100 transition-opacity", children: _jsx("preview-switcher", { placement: "bottom", development: ENV.DEVELOPMENT, server: SERVER_URL, children: _jsx(Button.Icon, { size: "m", iconSize: "s", icon: faCog }) }) })), isLoggedIn && _jsx(NotificationsPopover, {}), _jsxs(Menu, { trigger: (props) => {
                    var _a;
                    return (_jsxs("div", { ...(isLoggedIn ? props : undefined), className: cx('flex items-center font-brand-t4m text-greyscale-500', isLoggedIn && 'cursor-pointer'), children: [_jsx(Avatar, { colorSet: getColorsFromNumber((user === null || user === void 0 ? void 0 : user.id) || NOT_LOGGED_IN_COLOR_SET_INDEX), className: "mr-1", url: (_a = user === null || user === void 0 ? void 0 : user.avatar) === null || _a === void 0 ? void 0 : _a.link, children: getInitials(username) }), _jsx("span", { children: username })] }));
                }, children: [_jsx(Menu.Item, { children: _jsx("a", { href: user === null || user === void 0 ? void 0 : user.legacyAdmin, target: "_blank", rel: "noreferrer", children: "Legacy admin panel" }) }), _jsx(Menu.Item, { children: _jsx(RouterLink, { to: "/user/settings", className: "flex", children: "Settings" }) }), _jsx(Menu.Item, { onClick: onLogout, children: "Logout" })] })] }));
};
