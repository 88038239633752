import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faCalendarDay, faClock } from '@fortawesome/pro-regular-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';
import { Badge, Button, Copyable, CountryFlag, InfoBar, useModalContext, } from '~/common/components';
import { useTickState } from '~/common/hooks';
import { cx, joinTruthy } from '~/common/utils';
import { useCurrentUserData } from '~/root';
import { useOfficeData } from '../hooks';
import { getOfficeTimeInfo } from '../utils';
import { EditOffice } from './EditOffice';
export const OfficeInfo = () => {
    const office = useOfficeData();
    const { permissions } = useCurrentUserData();
    const { modalOpener } = useModalContext();
    const [{ isOpen, currentTime }] = useTickState(() => getOfficeTimeInfo(office.timezone, office.opens_at, office.closes_at));
    const officeLocation = joinTruthy([office.address, office.city, office.region, office.postal_code, office.country.name], ', ');
    return (_jsxs("div", { className: "w-3/4 rounded bg-greyscale-0", children: [_jsx("div", { className: "relative flex items-center justify-end h-6 px-1 bg-greyscale-200 rounded-t", children: permissions.officesManage && (_jsx(Button, { onClick: modalOpener(EditOffice), color: "grey", children: "Edit" })) }), _jsxs("div", { className: "relative mt-8 mx-auto px-2 max-w-[524px]", children: [_jsx(CountryFlag, { className: "absolute left-0 -top-12", countryCode: office.country.country_code, size: 80 }), _jsxs("div", { className: "flex items-center justify-between mt-[-85px]", children: [_jsx("span", { className: "font-brand-t1m", children: office.name || `${office.country.name} Office` }), _jsx("span", { className: "font-brand-b2r text-greyscale-400", children: currentTime })] }), _jsxs("div", { className: "flex items-center justify-between mt-1", children: [_jsxs("span", { className: "font-brand-b1 text-greyscale-400 capitalize", children: [office.type, " office"] }), _jsx("span", { className: cx('flex items-center h-3 px-1 mt-[-4px] font-brand-t5 rounded-[32px]', {
                                    'bg-primary-50 text-primary-400': isOpen,
                                    'bg-greyscale-100 text-greyscale-500': !isOpen,
                                }), children: isOpen ? 'Open' : 'Closed' })] }), _jsxs("ul", { className: "mt-2 mb-4", children: [_jsx(InfoBar, { truncate: true, icon: faMapMarkerAlt, text: _jsx(Copyable, { text: officeLocation }), hint: "Location" }), _jsx(InfoBar, { icon: faCalendarDay, text: `Monday - Friday ${office.opens_at}:00 - ${office.closes_at}:00`, hint: "Working hours" }), office.shifts.length > 0 && (_jsx(InfoBar, { icon: faClock, text: _jsx("div", { className: "flex flex-wrap gap-2", children: office.shifts.map((shift) => (_jsxs(Badge, { color: "lighter-grey", className: "flex gap-2 items-center", children: [_jsx("span", { className: "font-brand-t4b", children: shift.sign }), _jsx("span", { className: "text-greyscale-400 font-brand-c1r", children: shift.label })] }, shift.id))) }), hint: "Shifts" }))] })] })] }));
};
