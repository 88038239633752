import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { forwardRef, useRef, useState } from 'react';
import { Errors, FormElementLabel, IconBox } from '~/common/components';
import { cx, EMAIL_REG_EXP, ignoreHandled, nonNullable } from '~/common/utils';
export const extendedSplitter = ['Enter', ' ', ',', ';'];
export const ChipsInput = forwardRef(({ title, hint, optional, error = false, value: chips = [], className, splitter = 'Enter', validateChip, parsePaste = (paste) => paste.toLowerCase().match(new RegExp(EMAIL_REG_EXP, 'g')), chipRenderer = defaultChipRenderer, onChange, onBlur, onClick, placeholder, ...props }, ref) => {
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null);
    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };
    const handleInputKeyPress = (event) => {
        if (event.key === 'Backspace' && !inputValue && chips.length) {
            event.preventDefault();
            const newChips = chips.slice();
            // we've checked that chips length is non-zero
            const chipToEdit = nonNullable(newChips.pop());
            onChange(newChips);
            setInputValue(chipToEdit.value);
            return;
        }
        if (typeof splitter === 'string'
            ? event.key === splitter
            : splitter.some((s) => event.key === s)) {
            event.preventDefault();
            addChip();
        }
    };
    const handlePaste = (e) => {
        e.preventDefault();
        const paste = e.clipboardData.getData('text');
        const items = parsePaste(paste);
        if (!items) {
            return;
        }
        const chipValues = chips.map(({ value }) => value);
        const toBeAdded = items.filter((item) => !chipValues.includes(item));
        onChange([
            ...chips,
            ...toBeAdded.map((value) => ({
                value: value,
                isValid: validateChip ? validateChip(value) : true,
            })),
        ]);
    };
    const addChip = () => {
        const chipValue = inputValue.trim();
        if (!chipValue || chips.find((chip) => chip.value === chipValue)) {
            return;
        }
        const isValid = validateChip ? validateChip(chipValue) : true;
        onChange([...chips, { value: chipValue, isValid }]);
        setInputValue('');
    };
    const removeChip = (chip) => {
        onChange(chips.filter((c) => c.value !== chip));
    };
    const handleContainerClick = (e) => {
        var _a;
        onClick && onClick(e);
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    };
    const handleBlur = (e) => {
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
        addChip();
    };
    const handleChipClick = (value) => {
        var _a;
        if (inputValue.replace(/\s/g, '') === '') {
            setInputValue(value);
            removeChip(value);
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    };
    return (_jsxs("label", { ref: ref, className: className, children: [_jsx(FormElementLabel, { title: title, hint: hint, optional: optional }), _jsxs("div", { ...props, className: cx('flex flex-wrap items-start content-start gap-1 min-h-[80px] p-1 border rounded font-brand-b1', 'overflow-y-scroll resize-y cursor-text', error ? 'border-danger-400' : 'border-greyscale-300'), onClick: ignoreHandled(handleContainerClick), children: [chips.map(({ value, isValid }) => chipRenderer({ value, isValid, remove: removeChip, onClick: handleChipClick })), _jsx("input", { ref: inputRef, className: "inline flex-grow-[2] order-2 peer", value: inputValue, onChange: handleInputChange, onBlur: handleBlur, onKeyDown: handleInputKeyPress, onPaste: handlePaste }), placeholder && !chips.length && (_jsx("span", { className: "peer-focus:hidden peer-focus-within:hidden order-1 font-brand-b1 text-greyscale-500", children: placeholder }))] })] }));
});
const defaultChipRenderer = ({ value, isValid, remove, onClick, }) => (_jsxs("div", { className: cx('inline-flex items-center px-1 gap-1 border rounded-[32px] font-brand-t5 cursor-pointer', isValid
        ? 'border-greyscale-300 bg-greyscale-50 text-greyscale-500'
        : 'border-danger-300 bg-danger-50 text-danger-400'), onClick: ignoreHandled(() => onClick === null || onClick === void 0 ? void 0 : onClick(value)), children: [value, _jsx(IconBox, { size: "s", "data-stop-propagation": true, icon: faTimesCircle, onClick: () => remove(value), className: cx(!isValid && 'text-danger-400') })] }, value));
export const ChipsInputField = ({ className, errors, touched, ...props }) => {
    return (_jsxs("div", { className: className, children: [_jsx(ChipsInput, { ...props, error: errors !== null }), errors && _jsx(Errors, { errors: errors, color: true })] }));
};
