import { useCallback, useEffect, useRef } from 'react';
import { createContextPair } from '~/common/kits/context';
import { useOrderData } from '~/orders/hooks';
const useContextData = () => {
    const { iterations } = useOrderData();
    const distribution = useRef(null);
    const setDistribution = useCallback((value) => {
        distribution.current = value;
    }, []);
    useEffect(() => {
        setDistribution(null);
    }, [iterations, setDistribution]);
    return {
        distribution,
        setDistribution,
    };
};
const [, useDistributionContext, withDistributionContext] = createContextPair(useContextData);
export { useDistributionContext, withDistributionContext };
