import { customValidator } from '~/common/utils';
export const initialPaymentMethodValues = {
    payment_method_id: null,
    po_number: '',
    po_expires_at: null,
    prevent_sending_invoice: false,
};
export const paymentMethodIdValidator = {
    payment_method_id: customValidator.required('Payment method is required'),
};
export const paymentMethodFormValidators = ({ po_expires_at }) => ({
    ...paymentMethodIdValidator,
    po_number: po_expires_at === null ? null : customValidator.nonEmptyString(),
    po_expires_at: null,
    prevent_sending_invoice: null,
});
