import { useUpdateOrderCache } from '~/orders/hooks';
export function useUpdateAnnotationCache() {
    const updateOrderCache = useUpdateOrderCache();
    return ({ revisionId, slideId, annotationId }, update) => updateOrderCache((order) => ({
        ...order,
        revisions: order.revisions.map((revision) => revision.id === revisionId
            ? {
                ...revision,
                slides: revision.slides.map((slide) => slide.id === slideId
                    ? {
                        ...slide,
                        annotations: slide.annotations.map((annotation) => annotation.id === annotationId ? update(annotation) : annotation),
                    }
                    : slide),
            }
            : revision),
    }));
}
