import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { Button, IconBox, useModalContext, PageDetailsCard, Copyable } from '~/common/components';
import { pluralizeWord } from '~/common/utils';
import { useCustomerData } from '~/customers/hooks';
import { useCurrentUserData } from '~/root';
import { EditBillingSettings } from './EditBillingSettings';
export const BillingSettings = () => {
    var _a;
    const customer = useCustomerData();
    const { permissions } = useCurrentUserData();
    const { modalOpener } = useModalContext();
    const email = customer.billing_settings.email;
    return (_jsxs(PageDetailsCard, { label: "Billing settings", actionButton: permissions.customersUpdate && (_jsx(Button, { onClick: modalOpener(EditBillingSettings), color: "grey", children: "Edit" })), bodyClassName: "grid grid-cols-[auto,1fr] gap-x-1 gap-y-2 font-brand-b1 py-3", children: [_jsx(BillingSettingsRow, { label: "Finance email", hint: "The primary finance contact and recipient of the invoices", children: email ? _jsx(Copyable, { text: email }) : '—' }), _jsx(BillingSettingsRow, { label: "CC emails", value: ((_a = customer.billing_settings.cc) === null || _a === void 0 ? void 0 : _a.join(', ')) || null, hint: "The emails where copy of invoices will be sent" }), _jsx(BillingSettingsRow, { label: "Invoice payment method", value: customer.billing_settings.invoice_enabled ? 'Enabled' : 'Disabled' }), _jsx(BillingSettingsRow, { label: "Add credits immediately", value: customer.billing_settings.add_credits_immediately ? 'Enabled' : 'Disabled', hint: "Credits will be added to the customer account before we receive the payment" }), _jsx(BillingSettingsRow, { label: "Payment term", value: pluralizeWord('day', customer.billing_settings.payment_term), hint: "The amount of time what the customer has to pay the invoice" }), _jsx(BillingSettingsRow, { label: "Custom invoice lines", value: customer.billing_settings.invoice_lines, hint: "The additional information that will be displayed on invoice" }), _jsx(BillingSettingsRow, { label: "Auto charge orders", value: customer.billing_settings.enable_auto_charge ? 'Enabled' : 'Disabled', hint: "Enable auto charge logic on orders after 7 days of inactivity" })] }));
};
const BillingSettingsRow = ({ label, value, hint, children }) => (_jsxs(_Fragment, { children: [_jsxs("span", { className: "text-greyscale-500 font-brand-t4m inline-flex items-center", children: [label, hint && (_jsx(IconBox, { "data-tt": hint, "data-tt-class": "max-w-[260px]", "data-tt-placement": "right", size: "s", className: "cursor-help ml-[4px]", icon: faInfoCircle }))] }), children !== null && children !== void 0 ? children : _jsx("span", { className: "truncate", children: value || '—' })] }));
