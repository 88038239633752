import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, useModalContext } from '~/common/components';
import { pluralizeWord } from '~/common/utils';
import { useOrderData } from '~/orders/hooks';
import { Files, SubSection } from '~/orders/Order';
import { DownloadButton } from '../../DownloadButton';
import { FileItem } from './FileItem';
export const FilesSubSection = ({ files, tag, title, children }) => {
    const { id } = useOrderData();
    const { modalOpener } = useModalContext();
    return (_jsx(SubSection, { title: title, children: _jsxs("div", { className: "px-1 space-y-1", children: [!!files.length && (_jsxs(_Fragment, { children: [_jsx("div", { className: "space-y-1", children: files.slice(0, 2).map((file, key) => (_jsx(FileItem, { ...file }, key))) }), _jsxs("div", { className: "flex items-center justify-between mt-[12px]", children: [_jsxs(Button, { onClick: modalOpener(Files, {
                                        files: files,
                                        orderId: id,
                                        tag,
                                    }), color: "grey", children: ["See all ", pluralizeWord('file', files.length)] }), _jsx(DownloadButton, { tag: tag, orderId: id, className: "ml-auto", children: "Download all as ZIP" })] })] })), children] }) }));
};
