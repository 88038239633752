import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { record } from '~/common/utils';
import { useOrderData } from '~/orders/hooks';
import { Stars } from '../components';
import { Section } from './Section';
export const FeedbackSection = () => {
    const order = useOrderData();
    if (order.status === 'Completed' && order.feedback) {
        const { ratings, qa } = order.feedback;
        return (_jsxs(Section, { color: "gold", type: "feedback", children: [_jsx("div", { className: "grid grid-cols-2 gap-4 p-2", children: record.entries(ratings).map(([title, rating], key) => (_jsxs("div", { className: "flex flex-col gap-[4px]", children: [_jsx("span", { className: "font-brand-b1 text-greyscale-500", children: title }), _jsx(Stars, { rating: rating, className: "gap-1" })] }, key))) }), _jsx("div", { className: "flex flex-col gap-2 p-2", children: record.entries(qa).map(([question, answer], key) => (_jsxs("div", { className: "flex flex-col gap-1", children: [_jsx("span", { className: "font-brand-b1 text-greyscale-500", children: question }), _jsx("span", { className: "font-brand-b1 text-greyscale-600", children: answer !== null && answer !== void 0 ? answer : '—' })] }, key))) })] }));
    }
    return null;
};
