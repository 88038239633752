import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faUsers } from '@fortawesome/pro-regular-svg-icons';
import { useMemo } from 'react';
import { LayoutContent, withModalContext } from '~/common/components';
import { Nothing, Table, useBackendTable } from '~/common/kits/table';
import { useInit, USERS_URL, useUsersList } from '../hooks';
import { Header } from './Header';
import { getTableConfigFromRole } from './table-config';
import { getFilterOptions } from './utils';
export const UsersList = withModalContext(({ role }) => {
    const users = useUsersList(role);
    const view = useBackendTable({
        data: users.data,
        tableConfig: getTableConfigFromRole(role),
        isFetching: users.isFetching,
    });
    const init = useInit();
    // TODO move this transform to users/domain
    const filterOptions = useMemo(() => getFilterOptions(init.data), [init]);
    if (!users.data || !filterOptions || !view) {
        return _jsx(LayoutContent, { loading: true });
    }
    return (_jsxs(LayoutContent, { children: [_jsx(Header, { userAmount: users.data.metadata.total, role: role, filters: filterOptions }), _jsx(Table, { ...view, noDataPlaceholder: _jsx(Nothing, { icon: faUsers }), linkGetter: (row) => `${USERS_URL}/${row.id}` })] }));
});
