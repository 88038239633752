import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faUserTag } from '@fortawesome/pro-regular-svg-icons';
import { useForm } from 'formoid';
import { InputField, ModalHeader, ModalShell, SelectField } from '~/common/components';
import { customValidator, propagateBackendValidationErrors } from '~/common/utils';
import { useEditTeamMember, useInitData } from '~/customers/hooks';
import { roleTooltipContent } from './InviteTeamMember';
export const EditTeamMember = ({ onClose, id, email, role }) => {
    const { mutateAsync } = useEditTeamMember();
    const init = useInitData();
    const initialValues = {
        email,
        role,
    };
    const { fieldProps, handleSubmit, handleReset, setErrors, isSubmitting } = useForm({
        initialValues,
        validationStrategy: 'onBlur',
        validators: () => ({
            email: customValidator.email(),
            role: customValidator.required(),
        }),
    });
    const handleClose = () => {
        onClose();
        handleReset();
    };
    const submit = () => {
        handleSubmit((values) => {
            return mutateAsync({ id, ...values })
                .then(handleClose)
                .catch((error) => {
                propagateBackendValidationErrors(error, setErrors);
            });
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: handleClose, title: "Edit team member" }), _jsxs(ModalShell, { className: "p-3 pb-5 space-y-3 w-[608px]", onClose: handleClose, onSubmit: submit, submitText: "Save", loading: isSubmitting, children: [_jsx(InputField, { ...fieldProps('email'), title: "Email", type: "text" }), _jsx(SelectField, { ...fieldProps('role'), title: "Role", placeholder: "Select a role", hint: roleTooltipContent, options: init.roles, icon: faUserTag })] })] }));
};
