import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { IconBox } from '~/common/components';
import { formatTimeAtDay } from '~/common/utils';
import { SettingsTabHeader } from '../SettingsTabHeader';
import { useLoginActivity } from './hooks';
export const LoginActivity = () => {
    var _a;
    const loginActivity = useLoginActivity();
    return (_jsxs("div", { className: "flex flex-col gap-1 px-3 pt-3 bg-greyscale-0", children: [_jsx(SettingsTabHeader, { title: "Login activity", description: "Displaying the last three logins and a maximum of five devices on your account" }), loginActivity.isLoading ? (_jsx("div", { className: "flex justify-center items-center h-30", children: _jsx(IconBox, { size: "l", className: "text-secondary-300 animate-spin", icon: faSpinnerThird }) })) : (_jsx("ol", { className: "list-none", children: (_a = loginActivity.data) === null || _a === void 0 ? void 0 : _a.map((item, index) => _jsx(ActivityEntry, { ...item }, index)) }))] }));
};
const ActivityEntry = ({ os, device, browser, activity }) => {
    return (_jsxs("li", { className: "py-3 border-b-greyscale-200 border-b last:border-b-0", children: [_jsxs("h3", { className: "font-brand-t2m mb-2", children: [os, " ", device, " ", browser] }), _jsx("ul", { className: "list-none", children: activity.map((item, index) => (_jsx(DeviceActivity, { ...item }, index))) })] }));
};
const DeviceActivity = ({ location, ip, timestamp }) => {
    return (_jsxs("li", { className: "mb-1 last:mb-0 font-brand-b1", children: [location, _jsxs("span", { className: "text-greyscale-500", children: [" - ", ip] }), _jsxs("span", { className: "text-greyscale-500", children: [" - ", formatTimeAtDay(timestamp)] })] }));
};
