import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Button, LayoutHeader, LayoutTitle, ListCount, SearchField, useModalContext, } from '~/common/components';
import { Filter, getFilterProps, stringify, useParsedQuery, } from '~/common/kits/table';
import { useCurrentUserData } from '~/root';
import { listRoleToListHeading } from '../domain';
import { CreateUser } from './CreateUser';
export const Header = ({ role, userAmount, filters }) => {
    const { queryParams, onFilterChange } = useParsedQuery();
    const { permissions } = useCurrentUserData();
    const { modalOpener } = useModalContext();
    const filterProps = getFilterProps(filters, queryParams.filter, onFilterChange);
    return (_jsxs(LayoutHeader, { className: "flex justify-between items-center", children: [_jsx(LayoutTitle, { children: listRoleToListHeading[role] }), _jsx(ListCount, { name: "active user", className: "font-brand-b1 text-greyscale-400 ml-2 mt-[6px]", amount: userAmount }), _jsx(SearchField, { className: "ml-auto w-[272px]", value: stringify(queryParams.filter.search), onChange: (value) => onFilterChange('search', value) }), role === 'designer' && (_jsx(Filter, { ...filterProps('team'), placeholder: "All design teams", className: "ml-2 w-25" })), _jsx(Filter, { ...filterProps('office'), placeholder: "All offices", className: "ml-2 w-25" }), permissions.usersCreate && (_jsx(Button, { onClick: modalOpener(CreateUser, { role }), color: "primary", icon: faPlus, className: "ml-2", children: "Add user" }))] }));
};
