import { randomUUID } from '~/common/utils';
import { getEcho } from './echo';
export const sharedWsChannel = new BroadcastChannel('shared_ws_channel');
const isEnabled = !localStorage.getItem('_shared_ws_disabled');
const log = (...args) => {
    if (isEnabled) {
        console.debug(...args);
    }
};
const postMessage = (event) => sharedWsChannel.postMessage(event);
const tabId = `shared_ws_${randomUUID()}`;
const removeItem = (array, item) => {
    const index = array.indexOf(item);
    if (index >= 0) {
        array.splice(index, 1);
    }
};
let isPrimaryTab = false;
let echo;
const localSubscribers = {};
const localTeardowns = {};
const otherTabs = {
    subs: {},
    callbacks: {},
};
const unlocks = [];
const localPresenceSubscribers = {};
const localPresenceTeardowns = {};
const otherPresenceTabs = {
    subs: {},
    callbacks: {},
};
// it doesn't leave channel automagically for some reason as evident in
// messages in the network tab, hence this manual stupidity, which leaves the
// channel manually if there's no callbacks attached to it ¯\_(ツ)_/¯
const leaveChannelIfNoSubs = (channel) => {
    for (const key in localSubscribers) {
        if (key.startsWith(channel)) {
            return;
        }
    }
    for (const key in otherTabs.subs) {
        if (key.startsWith(channel)) {
            return;
        }
    }
    for (const key in localPresenceSubscribers) {
        if (key === channel) {
            return;
        }
    }
    for (const key in otherPresenceTabs.subs) {
        if (key === channel) {
            return;
        }
    }
    log(`%cleaving ${channel}, no subs left`, 'color: #FBB1B1');
    echo.leave(channel);
};
log('PRESS ALT+G TO LOG CURRENT STATE');
const sub = (key) => {
    const [channel, event] = key.split('|');
    const privateChannel = echo.private(channel);
    const listener = (data) => {
        localSubscribers[key].forEach((callback) => callback(data));
    };
    privateChannel.listen(event, listener);
    return () => privateChannel.stopListening(event, listener);
};
const subPresence = (channel) => {
    const callback = () => null;
    const presenceChannel = echo.join(channel);
    presenceChannel.here(callback);
    return () => presenceChannel.stopListening('here', callback);
};
const unsubOther = (key) => {
    const [channel, event] = key.split('|');
    echo.private(channel).stopListening(event, otherTabs.callbacks[key]);
    delete otherTabs.subs[key];
    delete otherTabs.callbacks[key];
    log(`secondary %cunsub ${key}`, 'color: #FBB1B1');
    leaveChannelIfNoSubs(channel);
};
const unsubOtherPresence = (channel) => {
    echo.join(channel).stopListening('here', otherPresenceTabs.callbacks[channel]);
    delete otherPresenceTabs.subs[channel];
    log(`secondary %cunsub ${channel}|here`, 'color: #FBB1B1');
    leaveChannelIfNoSubs(channel);
};
export const subscribe = ({ channel, event, callback, }) => {
    const key = `${channel}|${event}`;
    if (isPrimaryTab && !localSubscribers[key]) {
        localSubscribers[key] = [];
        localTeardowns[key] = sub(key);
    }
    if (!isPrimaryTab && !localSubscribers[key]) {
        localSubscribers[key] = [];
        postMessage({ type: 'subscribe', channel, event, tabId });
    }
    localSubscribers[key].push(callback);
    if (isPrimaryTab) {
        log(`%csubbed ${key}: ${localSubscribers[key].length}`, 'color: #46B688');
    }
    else {
        log(`%crequested sub ${key}: ${localSubscribers[key].length}`, 'color: #46B688');
    }
    return () => {
        var _a, _b;
        removeItem(localSubscribers[key], callback);
        if (isPrimaryTab && !localSubscribers[key].length) {
            log(`%cunsubbed ${key}`, 'color: #FBB1B1');
            localTeardowns[key]();
            delete localTeardowns[key];
            delete localSubscribers[key];
            leaveChannelIfNoSubs(key.split('|')[0]);
        }
        if (!isPrimaryTab && !localSubscribers[key].length) {
            postMessage({ type: 'unsubscribe', channel, event, tabId });
            delete localSubscribers[key];
            log(`%crequested unsub ${key}`, 'color: #FBB1B1');
        }
        if (isPrimaryTab && ((_a = localSubscribers[key]) === null || _a === void 0 ? void 0 : _a.length)) {
            log(`%creducing subs ${key}: ${localSubscribers[key].length}`, 'color: #FBB1B1');
        }
        if (!isPrimaryTab && ((_b = localSubscribers[key]) === null || _b === void 0 ? void 0 : _b.length)) {
            log(`%creducing subs ${key}`, 'color: #FBB1B1');
        }
    };
};
export const subscribePresence = (channel) => {
    if (isPrimaryTab && !localPresenceSubscribers[channel]) {
        localPresenceSubscribers[channel] = 0;
        localPresenceTeardowns[channel] = subPresence(channel);
    }
    if (!isPrimaryTab && !localPresenceSubscribers[channel]) {
        localPresenceSubscribers[channel] = 0;
        postMessage({ type: 'subscribe-presence', channel, tabId });
    }
    localPresenceSubscribers[channel]++;
    if (isPrimaryTab) {
        log(`%csubbed ${channel}|here: ${localPresenceSubscribers[channel]}`, 'color: #46B688');
    }
    else {
        log(`%crequested sub ${channel}|here`, 'color: #46B688');
    }
    return () => {
        localPresenceSubscribers[channel]--;
        if (isPrimaryTab && !localPresenceSubscribers[channel]) {
            localPresenceTeardowns[channel]();
            delete localPresenceTeardowns[channel];
            delete localPresenceSubscribers[channel];
            log(`%cunsubbed ${channel}|here`, 'color: #FBB1B1');
            leaveChannelIfNoSubs(channel);
        }
        if (!isPrimaryTab && !localPresenceSubscribers[channel]) {
            postMessage({ type: 'unsubscribe-presence', channel, tabId });
            delete localPresenceSubscribers[channel];
            log(`%crequested unsub ${channel}|here`, 'color: #FBB1B1');
        }
        if (isPrimaryTab && localPresenceSubscribers[channel]) {
            log(`%creducing subs ${channel}|here: ${localPresenceSubscribers[channel]}`, 'color: #FBB1B1');
        }
        if (!isPrimaryTab && localPresenceSubscribers[channel]) {
            log(`%creducing subs ${channel}|here`, 'color: #FBB1B1');
        }
    };
};
const arrayToCount = (record) => {
    return Object.fromEntries(Object.entries(record).map(([key, value]) => [
        key,
        Array.isArray(value) ? value.length : value instanceof Function ? true : null,
    ]));
};
window.addEventListener('keydown', (event) => {
    if ((event.altKey || event.metaKey) && event.key.toUpperCase() === 'G') {
        event.preventDefault();
        log({
            localSubscribers: arrayToCount(localSubscribers),
            localTeardowns: arrayToCount(localTeardowns),
            otherTabs: arrayToCount(otherTabs.subs),
            localPresenceSubscribers: localPresenceSubscribers,
            localPresenceTeardowns: arrayToCount(localPresenceTeardowns),
            otherPresenceTabs: arrayToCount(otherPresenceTabs.subs),
            unlocks,
        });
    }
});
navigator.locks.request(isEnabled ? 'shared_ws_lock' : 'individual_' + tabId, () => {
    isPrimaryTab = true;
    echo = getEcho();
    postMessage({ type: 'hop' });
    // this is for converting secondary tab to primary, local subscriptions that
    // were dependent on other primary tab need to sub fr now
    for (const key in localSubscribers) {
        localTeardowns[key] = sub(key);
        log(`%csubbed ${key}: ${localSubscribers[key].length}`, 'color: #46B688');
    }
    for (const channel in localPresenceSubscribers) {
        localPresenceTeardowns[channel] = subPresence(channel);
        log(`%csubbed ${channel}|here: ${localPresenceSubscribers[channel]}`, 'color: #46B688');
    }
    log('%cLOCK ACQUIRED, THIS TAB IS PRIMARY', 'color: #46B688');
    // we'll disconnect echo if this lock is unlocked
    return new Promise(() => null);
});
// holds uniquely named lock for primary tab to understand when it could unsub
// secondary tab's requested ws
navigator.locks.request(tabId, () => {
    log(`ACQUIRED LOCK FOR TAB_ID: ${tabId}`);
    return new Promise(() => null);
});
sharedWsChannel.onmessage = ({ data }) => {
    // subscribe requests are only processed on primary tab
    if (!isEnabled || (!isPrimaryTab && data.type !== 'update' && data.type !== 'hop')) {
        return;
    }
    // got subscribe request from one of secondary tabs, subscription count will
    // increase by one and primary tab will subscribe and start emit events to
    // other tabs
    if (isPrimaryTab && data.type === 'subscribe') {
        const key = `${data.channel}|${data.event}`;
        if (!unlocks.includes(data.tabId)) {
            log(`adding unlock waiter for tab: ${data.tabId}`);
            unlocks.push(data.tabId);
            navigator.locks.request(data.tabId, () => {
                log(`tab: ${data.tabId} got closed, removing subs`);
                for (const key in otherTabs.subs) {
                    if (otherTabs.subs[key].indexOf(data.tabId) === -1) {
                        continue;
                    }
                    otherTabs.subs[key] = otherTabs.subs[key].filter((id) => id !== data.tabId);
                    if (!otherTabs.subs[key].length) {
                        unsubOther(key);
                    }
                    else {
                        log(`secondary %creduced subs ${key}: ${otherTabs.subs[key].length}`, 'color: #FBB1B1');
                    }
                }
                for (const channel in otherPresenceTabs.subs) {
                    if (otherPresenceTabs.subs[channel].indexOf(data.tabId) === -1) {
                        continue;
                    }
                    otherPresenceTabs.subs[channel] = otherPresenceTabs.subs[channel].filter((id) => id !== data.tabId);
                    if (!otherPresenceTabs.subs[channel].length) {
                        unsubOtherPresence(channel);
                    }
                    else {
                        log(`secondary %creduced subs ${channel}|here: ${otherTabs.subs[channel].length}`, 'color: #FBB1B1');
                    }
                }
                removeItem(unlocks, data.tabId);
                return Promise.resolve();
            });
        }
        if (otherTabs.callbacks[key]) {
            otherTabs.subs[key].push(data.tabId);
            log(`secondary %csub ${key}: ${otherTabs.subs[key].length}`, 'color: #46B688');
            return;
        }
        // when getting the first subscribe request from a secondary tab, we'll
        // wait until it gets closed to unsubscribe from all it's requested ws
        otherTabs.subs[key] = [data.tabId];
        otherTabs.callbacks[key] = (update) => {
            log(`sent live update to secondary tabs ${key}`, update);
            postMessage({
                type: 'update',
                channel: data.channel,
                event: data.event,
                data: update,
            });
        };
        echo.private(data.channel).listen(data.event, otherTabs.callbacks[key]);
        log(`secondary %csub ${key}: ${otherTabs.subs[key].length}`, 'color: #46B688');
    }
    // got unsubscribe request from one of secondary tabs, subscription count
    // will be reduced by one and primary tab will unsubscribe once subscription
    // count reaches zero
    if (isPrimaryTab && data.type === 'unsubscribe') {
        const key = `${data.channel}|${data.event}`;
        if (otherTabs.subs[key] === undefined) {
            return;
        }
        removeItem(otherTabs.subs[key], data.tabId);
        if (otherTabs.subs[key].length) {
            log(`secondary %creduced subs ${key}: ${otherTabs.subs[key].length}`, 'color: #FBB1B1');
            return;
        }
        unsubOther(key);
    }
    // got subscribe request from one of secondary tabs, subscription count will
    // increase by one and primary tab will be present on the channel
    if (isPrimaryTab && data.type === 'subscribe-presence') {
        if (otherPresenceTabs.subs[data.channel]) {
            otherPresenceTabs.subs[data.channel].push(data.tabId);
            log(`secondary %csub ${data.channel}|here: ${otherPresenceTabs.subs[data.channel].length}`, 'color: #46B688');
            return;
        }
        otherPresenceTabs.subs[data.channel] = [data.tabId];
        otherPresenceTabs.callbacks[data.channel] = () => null;
        const presenceChannel = echo.join(data.channel);
        presenceChannel.here(otherPresenceTabs.callbacks[data.channel]);
        log(`secondary %csub ${data.channel}|here: ${otherPresenceTabs.subs[data.channel].length}`, 'color: #46B688');
    }
    // got unsubscribe request from one of secondary tabs, subscription count
    // will be reduced by one and once subscription count reaches zero primary
    // tab will stop being present on the channel
    if (isPrimaryTab && data.type === 'unsubscribe-presence') {
        if (otherPresenceTabs.subs[data.channel] === undefined) {
            return;
        }
        removeItem(otherPresenceTabs.subs[data.channel], data.tabId);
        if (otherPresenceTabs.subs[data.channel].length) {
            log(`secondary %creduced subs ${data.channel}|here: ${otherPresenceTabs.subs[data.channel].length}`, 'color: #FBB1B1');
            return;
        }
        unsubOtherPresence(data.channel);
    }
    // another tab has acquired a lock due to ex-primary tab getting closed
    // each secondary tab should send subscription request so it can subscribe to
    // all needed channels and events
    if (!isPrimaryTab && data.type === 'hop') {
        log('got hop, sending list of subscriptions to new primary tab', localSubscribers);
        // it seems like we need an array of subscribers which we'll gonna call
        for (const key in localSubscribers) {
            if (localSubscribers[key]) {
                const [channel, event] = key.split('|');
                postMessage({ type: 'subscribe', channel, event, tabId });
            }
        }
        for (const channel in localPresenceSubscribers) {
            if (localPresenceSubscribers[channel]) {
                postMessage({ type: 'subscribe-presence', channel, tabId });
            }
        }
    }
    if (data.type === 'update') {
        log('got a live update from primary', data.data);
        const key = `${data.channel}|${data.event}`;
        if (localSubscribers[key]) {
            localSubscribers[key].forEach((callback) => callback(data.data));
        }
    }
};
