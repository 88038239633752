import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Badge, Checkbox, IconBox } from '~/common/components';
import { UserCard } from '~/common/kits/usercard';
import { cx, ignoreHandled } from '~/common/utils';
import { CUSTOMERS_URL } from '~/customers/hooks';
import { useCurrentUserData } from '~/root';
const tableCellClassName = 'flex items-center min-h-[56px] py-1 px-[12px] group-last-of-type:border-none border-b border-greyscale-200';
export const Row = ({ data, isSelected, handleSelect }) => {
    const { permissions } = useCurrentUserData();
    const className = cx(tableCellClassName, isSelected && 'bg-secondary-50', permissions.organizationsUpdate &&
        (data.is_owner ? 'cursor-not-allowed' : 'group-hover:bg-greyscale-50 cursor-pointer'));
    return (_jsxs("div", { className: "contents font-brand-b2r group", onClick: ignoreHandled(() => {
            if (data.is_owner || !permissions.organizationsUpdate)
                return;
            handleSelect(data.id);
        }), children: [permissions.organizationsUpdate && (_jsx("div", { className: className, children: _jsx(Checkbox, { disabled: !!data.is_owner, checkboxHint: data.is_owner && (_jsxs(_Fragment, { children: [_jsx("p", { children: "You can not edit the owner of the organization." }), _jsx("p", { children: "To make changes, please specify the new one" })] })), "data-stop-propagation": true, color: "secondary", value: isSelected, onChange: () => handleSelect(data.id) }) })), _jsxs("div", { className: className, children: [_jsx(UserCard, { id: data.id, children: _jsx(Link, { "data-stop-propagation": true, className: "text-secondary-300 hover:text-secondary-400 transition-brand truncate cursor-pointer", to: `${CUSTOMERS_URL}/${data.id}`, children: data.name }) }), data.is_owner && (_jsx(IconBox, { "data-tt": "The owner of the organization", size: "s", icon: faStar, className: "text-warning-300 ml-1 cursor-help" }))] }), _jsx("div", { className: className, children: _jsx("span", { className: "truncate", children: data.email }) }), _jsx("div", { className: className, children: _jsx("span", { className: "truncate", children: data.members_count }) }), _jsx("div", { className: className, children: data.orders_count }), _jsx("div", { className: cx(className, 'gap-x-[4px]'), children: data.products ? _jsx(Products, { products: data.products }) : '—' })] }));
};
export const Products = ({ products }) => {
    return (_jsx(_Fragment, { children: products.map(({ name, value }) => (_jsx(Badge, { color: "grey", children: name }, value))) }));
};
