import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useLayoutEffect, useState, } from 'react';
import { cx } from '~/common/utils';
const sizes = {
    big: 'h-10 w-10 border-[3px] font-brand-h1',
    small: 'h-4 w-4 border font-brand-c1m',
    xs: 'h-3 w-3 border font-brand-c2m',
};
export const Avatar = forwardRef(({ colorSet, size = 'small', url, className, children, imgProps, ...props }, ref) => {
    const [loaded, setLoaded] = useState(false);
    const style = loaded ? props.style : { ...colorSet, ...props.style };
    useLayoutEffect(() => setLoaded(false), [url]);
    return (_jsxs("div", { ref: ref, ...props, style: style, className: cx('flex flex-none items-center justify-center border-solid rounded-full overflow-hidden', className, sizes[size], { 'border-greyscale-200': loaded }), children: [url && (_jsx("img", { ...imgProps, src: url, className: cx(imgProps === null || imgProps === void 0 ? void 0 : imgProps.className, 'h-full w-full object-cover bg-greyscale-0', {
                    hidden: !loaded,
                }), onLoad: () => setLoaded(true) })), !loaded && children] }));
});
