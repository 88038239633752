import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { array, map, nonEmptyArray, option } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { useCallback, useMemo } from 'react';
import { Toast } from '~/common/components';
import { useHasNextPage, useUpdateCache } from '~/common/hooks';
import { usePrivateChannel } from '~/common/kits/socket';
import { addQueryParams, eqUUID, httpClient, map as ourMap } from '~/common/utils';
import { useCurrentUserData } from '~/root';
import { qk } from '~/root/query-keys';
import { FlashMessage, Notification, Notifications, NotificationUpdated, ordNotification, } from './domain';
import { getNotificationLink, setReadState } from './utils';
export function useNotifications() {
    const query = useInfiniteQuery({
        queryKey: qk.notifications,
        queryFn: ({ pageParam = 1 }) => {
            return httpClient.get(addQueryParams('/v1/staff/notifications', { page: pageParam }), {
                decoder: Notifications,
            });
        },
        getNextPageParam: (page) => page.metadata.next,
        getPreviousPageParam: (page) => page.metadata.prev,
        // this query is always active, so having only staleTime is enough
        staleTime: Infinity,
    });
    const hasNextPage = useHasNextPage(query.data);
    const notifications = useMemo(() => {
        return pipe(query.data, option.fromNullable, option.map((data) => pipe(data.pages, array.chain((page) => pipe(page.items, map.values(ordNotification))))));
    }, [query.data]);
    const hasUnread = useMemo(() => {
        return pipe(notifications, option.exists(array.some(({ is_read }) => !is_read)));
    }, [notifications]);
    return { ...query, notifications, hasUnread, hasNextPage };
}
function useUpdateNotificationsCache() {
    return useUpdateCache(qk.notifications, 'Notifications cache is not defined!');
}
const toBody = (action) => {
    switch (action.type) {
        case 'read':
            return { read: action.payload instanceof Array ? action.payload : [action.payload] };
        case 'unread':
            return { unread: action.payload instanceof Array ? action.payload : [action.payload] };
    }
};
const update = ({ type, payload }) => {
    return (notifications) => {
        if (payload instanceof Array) {
            return {
                ...notifications,
                pages: notifications.pages.map((page) => ({
                    ...page,
                    items: pipe(payload, array.reduce(page.items, (result, id) => pipe(result, map.modifyAt(eqUUID)(id, setReadState(type === 'read')), option.getOrElse(() => result)))),
                })),
            };
        }
        return {
            ...notifications,
            pages: pipe(notifications.pages, array.findIndex((page) => pipe(page.items, map.member(eqUUID)(payload))), option.chain((index) => pipe(notifications.pages, array.modifyAt(index, (page) => ({
                ...page,
                items: pipe(page.items, map.modifyAt(eqUUID)(payload, setReadState(type === 'read')), option.getOrElse(() => page.items)),
            })))), option.getOrElse(() => notifications.pages)),
        };
    };
};
const readAll = (prev) => ({
    ...prev,
    pages: prev.pages.map((page) => ({
        ...page,
        // TODO rename ourMap to map once we get rid of fp-ts here
        items: ourMap.map(page.items, (notification) => ({ ...notification, is_read: true })),
    })),
});
export function useUpdateNotifications() {
    const updateCache = useUpdateNotificationsCache();
    const { mutateAsync, isLoading } = useMutation({
        mutationKey: qk.notifications,
        mutationFn: (action) => {
            return httpClient.patch('/v1/staff/notifications', { data: toBody(action) });
        },
        onSuccess: (_, action) => updateCache(update(action)),
    });
    const read = useCallback((payload) => mutateAsync({ type: 'read', payload }), [mutateAsync]);
    const unread = useCallback((payload) => mutateAsync({ type: 'unread', payload }), [mutateAsync]);
    return [{ read, unread }, isLoading];
}
export function useReadAllNotifications() {
    const updateCache = useUpdateNotificationsCache();
    const client = useQueryClient();
    return useMutation({
        mutationKey: qk.notificationsReadAll,
        mutationFn: () => httpClient.post('/v1/staff/notifications/read-all'),
        onSuccess: () => {
            updateCache(readAll);
            // to reset notifications on the orders list, since we can't know on FE
            // which orders were "read" by clicking read all
            client.invalidateQueries(qk.ordersList);
        },
    });
}
export function useNotificationEvents() {
    const currentUser = useCurrentUserData();
    const updateCache = useUpdateNotificationsCache();
    usePrivateChannel({
        channelName: `user.${currentUser.id}`,
        events: [
            {
                event: '.notification.created',
                decoder: Notification,
                callback: (notification) => {
                    updateCache((notifications) => ({
                        ...notifications,
                        pages: pipe(notifications.pages, nonEmptyArray.fromArray, option.map((pages) => pipe(pages, nonEmptyArray.modifyLast((page) => ({
                            ...page,
                            items: pipe(page.items, map.upsertAt(eqUUID)(notification.id, notification)),
                        })))), option.getOrElse(() => notifications.pages)),
                    }));
                    Toast.default({
                        reversed: notification.event.model === 'Order',
                        title: notification.content,
                        subTitle: notification.event.model === 'Order' ? `Order ${notification.event.id}` : undefined,
                        link: getNotificationLink(notification.event),
                    });
                },
            },
            {
                event: '.notification.updated',
                decoder: NotificationUpdated,
                callback: (data) => {
                    updateCache((notifications) => ({
                        ...notifications,
                        pages: pipe(notifications.pages, array.findIndex((page) => pipe(page.items, map.member(eqUUID)(data.id))), option.chain((index) => pipe(notifications.pages, array.modifyAt(index, (page) => ({
                            ...page,
                            items: pipe(page.items, map.modifyAt(eqUUID)(data.id, setReadState(data.is_read)), option.getOrElse(() => page.items)),
                        })))), option.getOrElse(() => notifications.pages)),
                    }));
                },
            },
            {
                event: '.notification.flash',
                decoder: FlashMessage,
                callback: (flash) => Toast[flash.type]({ title: flash.message }),
            },
            {
                event: '.notification.read-all',
                callback: () => updateCache(readAll),
            },
        ],
    });
}
