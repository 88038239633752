import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useBillingInfoMutation, useCustomerData, useInitData } from '~/customers/hooks';
import { BillingForm, getBillingInitialValues, useBillingForm } from '../BillingForm';
export const EditBillingAddress = ({ onClose }) => {
    const init = useInitData();
    const customer = useCustomerData();
    const { mutateAsync } = useBillingInfoMutation();
    const [initialValues] = useState(getBillingInitialValues(customer.billing_address, init.currencies[0].value));
    const form = useBillingForm(getBillingInitialValues(customer.billing_address, init.currencies[0].value));
    return (_jsx(BillingForm, { title: "Edit billing information", initialValues: initialValues, onClose: onClose, onSubmit: mutateAsync, ...form }));
};
