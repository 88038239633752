import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { ModalBody, ModalHeader, Link, Badge } from '~/common/components';
import { UserCard } from '~/common/kits/usercard';
import { cx } from '~/common/utils';
import { CUSTOMERS_URL } from '~/customers/hooks';
import { useOrderData } from '~/orders/hooks';
const cellClassName = 'pl-3 h-7 flex items-center';
export const SharedMembers = ({ onClose }) => {
    const { customer } = useOrderData();
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Shared members" }), _jsxs(ModalBody, { className: "w-[850px]", children: [_jsxs("span", { className: "font-brand-t1m", children: [customer.name, " shared this order with"] }), _jsx("ul", { className: "grid grid-cols-[250px_1fr_auto_155px] mt-[12px]", children: customer.members.map(({ id, name, role, email, permission }) => (_jsxs(React.Fragment, { children: [_jsxs("span", { className: cx('gap-1', cellClassName), children: [_jsx(UserCard, { id: id, name: name, avatar_link: null, type: "customer", owner_id: customer.owner_id }), _jsx(Link, { to: `${CUSTOMERS_URL}/${customer.owner_id}`, children: name })] }), _jsx("span", { className: cx('flex items-center font-brand-b2r min-w-0', cellClassName), children: email }), _jsx("span", { className: cx('font-brand-b1 text-greyscale-500 capitalize', cellClassName), children: _jsx(Badge, { color: "grey", children: role }) }), _jsx("div", { className: cx('font-brand-b1 text-greyscale-500 pr-3 justify-end', cellClassName), children: `Can ${permission}` }), _jsx("hr", { className: "col-span-full border-greyscale-200 last-of-type:hidden" })] }, id))) })] }), _jsx("div", { className: "bg-greyscale-50 border-t border-secondary-100 p-3 flex justify-end rounded-b-md", children: _jsx(Link.Button, { to: `${CUSTOMERS_URL}/${customer.owner_id}/members`, children: "See all team members" }) })] }));
};
