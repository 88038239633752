import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { pipe } from 'fp-ts/function';
import { DateDistance, useModalContext } from '~/common/components';
import { cx, formatYearMonthDay } from '~/common/utils';
import { useOrderData } from '~/orders/hooks';
import { CapacityDistributionModal, CustomerCapacityLink, EditDeliveryInfo, SectionCell, SubSection, } from '~/orders/Order';
import { isDeadlineExpired, isStatusAfter, iterationToColor } from '~/orders/utils';
import { useCurrentUserData } from '~/root';
import { IterationItem } from './IterationItem';
import { IterationsBar } from './IterationsBar';
export const Delivery = () => {
    var _a, _b, _c, _d;
    const { permissions } = useCurrentUserData();
    const order = useOrderData();
    const { modalOpener } = useModalContext();
    const deadlineExpired = isDeadlineExpired(order.deadline);
    const totalPoints = (_b = (_a = order.iterations) === null || _a === void 0 ? void 0 : _a.reduce((result, { points }) => result + points, 0)) !== null && _b !== void 0 ? _b : 0;
    const isAfterDesignInProgress = pipe(order.status, isStatusAfter('DesignInProgress'));
    return (_jsx(SubSection, { title: "Delivery", onEdit: modalOpener(EditDeliveryInfo), children: _jsxs("div", { className: "flex justify-between space-x-4 w-full", children: [_jsxs("div", { className: "flex flex-col gap-1 w-1/2", children: [_jsx(SectionCell, { title: "Deadline", children: _jsx(DateDistance, { className: cx('font-brand-t4m', deadlineExpired && 'text-danger-400'), to: order.deadline }, order.deadline.toString()) }), order.customer_capacity && (_jsxs(_Fragment, { children: [_jsx(SectionCell, { title: "Start", children: _jsx("span", { className: "font-brand-t4m", children: order.customer_capacity.start
                                            ? formatYearMonthDay(order.customer_capacity.start)
                                            : '—' }) }), _jsx(SectionCell, { title: "End", children: _jsx("span", { className: "font-brand-t4m", children: order.customer_capacity.end
                                            ? formatYearMonthDay(order.customer_capacity.end)
                                            : '—' }) })] })), _jsx(SectionCell, { title: "Total design capacity", titleClassName: "font-brand-t4m text-greyscale-600", children: _jsx("span", { className: "font-brand-t4m", children: order.points + order.extra_points }) }), _jsx("div", { className: "flex justify-end w-full", children: _jsxs("div", { className: "space-y-1 w-1/2", children: [_jsx(SectionCell, { title: "Order", children: _jsx("span", { className: "font-brand-t4m", children: order.points }) }), _jsx(SectionCell, { title: "Extra", children: _jsx("span", { className: "font-brand-t4m", children: order.extra_points }) })] }) }), ((_c = order.customer.subscription) === null || _c === void 0 ? void 0 : _c.daily) && order.customer.subscription.daily > 0 ? (_jsx(CustomerCapacityLink, { className: "mr-auto" })) : null] }), _jsxs("div", { className: "flex flex-col items-start space-y-1 w-1/2", children: [_jsxs("div", { className: "space-y-1 w-full", children: [order.iterations && (_jsx(IterationsBar, { iterations: order.iterations, totalPoints: totalPoints })), (_d = order.iterations) === null || _d === void 0 ? void 0 : _d.map((iteration, index) => (_jsx(IterationItem, { color: iterationToColor[index], points: iteration.points, title: iteration.name }, index)))] }), permissions.ordersUpdate && isAfterDesignInProgress && order.iterations && (_jsx(CapacityDistributionModal, { type: "distribution" }))] })] }) }));
};
