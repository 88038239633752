import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BarController, BubbleController, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineController, LineElement, PointElement, Tooltip, } from 'chart.js';
import { cx, nonNullable, pluralize } from '../utils';
export const LegendItem = ({ label, className }) => (_jsxs("div", { className: "flex items-center gap-1", children: [_jsx("div", { className: cx('h-1 w-1 rounded-full', className) }), _jsx("div", { className: "font-brand-b2r text-greyscale-500", children: label })] }));
export const registerChartJSPlugins = () => {
    ChartJS.register(LinearScale, CategoryScale, BarController, BarElement, PointElement, LineController, LineElement, Legend, Tooltip, BubbleController);
};
const BORDER_RADIUS = 4;
export const getBarParams = (params) => {
    const { flatBottom, barThickness } = { flatBottom: false, barThickness: 24, ...params };
    return {
        type: 'bar',
        borderRadius: {
            topLeft: BORDER_RADIUS,
            topRight: BORDER_RADIUS,
            bottomLeft: flatBottom ? 0 : BORDER_RADIUS,
            bottomRight: flatBottom ? 0 : BORDER_RADIUS,
        },
        borderSkipped: false,
        borderColor: 'white',
        borderWidth: 1,
        barThickness,
    };
};
export const stickyYAxisPlugin = {
    id: 'stickyYAxis',
    afterDraw: (chart, _, options) => {
        if (!options.draw) {
            return;
        }
        const height = chart.ctx.canvas.height;
        const width = (chart.chartArea.left - 8) * window.devicePixelRatio;
        const element = document.getElementById(options.nodeId);
        const targetCtx = nonNullable(element.getContext('2d'));
        targetCtx.canvas.width = width;
        targetCtx.canvas.height = height;
        targetCtx.drawImage(chart.ctx.canvas, 0, 0, width, height, 0, 0, width, height);
    },
};
const font = {
    size: 11,
    family: '"Poppins", sans-serif',
};
const lineColor = '#e3e9f2';
const fontColor = '#6d7395';
const weekendFontColor = '#e14949';
export const barChartScaleParams = (chartData) => ({
    font,
    maintainAspectRatio: false,
    scales: {
        x: {
            stacked: true,
            ticks: {
                font: chartData.todayIndex !== undefined
                    ? (value) => ({
                        ...font,
                        weight: value.index === chartData.todayIndex ? 'bold' : 'regular',
                    })
                    : undefined,
                color: (value) => (chartData.weekend.has(value.index) ? weekendFontColor : fontColor),
            },
            grid: {
                offset: false,
                drawOnChartArea: false,
                drawBorder: false,
                tickColor: lineColor,
                color: lineColor,
            },
        },
        y: {
            ticks: {
                font,
            },
            stacked: true,
            grid: {
                drawBorder: false,
                tickColor: lineColor,
                color: lineColor,
            },
        },
    },
    interaction: {
        mode: 'index',
    },
});
export const tooltipParams = {
    titleMarginBottom: 16,
    padding: 16,
    bodySpacing: 12,
    boxPadding: 8,
    boxHeight: 6,
    boxWidth: 6,
    usePointStyle: true,
    borderColor: 'transparent',
    bodyFont: {
        size: 13,
        weight: 'semibold',
        family: 'JetBrains Mono',
    },
};
export const tooltipCallbacks = (tooltips) => ({
    title: (obj) => {
        const getTitle = (obj) => tooltips[obj.dataIndex];
        return Array.isArray(obj) ? getTitle(obj[0]) : getTitle(obj);
    },
    label: ({ raw, label }) => {
        let value = raw;
        if (label === 'Day capacity') {
            value -= 1;
        }
        return `${value} point${pluralize(value)}`;
    },
});
