import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SelectFactory } from '~/common/components';
import { tw } from '~/common/utils';
const inputClassName = tw `
  absolute top-0 left-0 right-0 bottom-0
  h-4 w-full
  font-brand-h1
  bg-transparent
  selection:bg-transparent selection:text-inherit
  cursor-pointer
`;
export const RevisionSelect = ({ value, onChange, onBlur, options, className, ...props }) => {
    // this is needed to prevent annoying input blurring and re-focusing
    const handleMouseDown = (e) => e.preventDefault();
    const Input = ({ ref, children, ...inputProps }) => (_jsxs("label", { ref: ref, className: "relative inline-flex font-brand-h1 text-greyscale-300", children: [_jsx("span", { className: "opacity-0 h-4", children: inputProps.value }), _jsx("input", { className: inputClassName, ...props, ...inputProps }), _jsx("div", { className: "flex items-center justify-center text-[20px] ml-1 pt-[4px]", onMouseDown: handleMouseDown, children: children })] }));
    return (
    // this div is needed to prevent tailwind space utilities from margin-moving dropdown
    _jsx("div", { className: className, children: _jsx(SelectFactory, { value: value, onChange: onChange, onBlur: onBlur, options: options, noSearch: true, theme: "darken", inputField: Input }) }));
};
