import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faShapes } from '@fortawesome/pro-regular-svg-icons';
import { LayoutContent } from '~/common/components';
import { Nothing, Table, useBackendTable } from '~/common/kits/table';
import { OFFICES_URL, useInit, useOfficeList } from '../hooks';
import { Header } from './Header';
import { tableConfig } from './table-config';
export const OfficesList = () => {
    const init = useInit();
    const offices = useOfficeList();
    const view = useBackendTable({
        data: offices.data,
        tableConfig,
        isFetching: offices.isFetching,
    });
    if (!offices.data || !init.data || !view) {
        return _jsx(LayoutContent, { loading: true });
    }
    return (_jsxs(LayoutContent, { children: [_jsx(Header, {}), _jsx(Table, { ...view, noDataPlaceholder: _jsx(Nothing, { icon: faShapes }), linkGetter: (row) => `${OFFICES_URL}/${row.id}` })] }));
};
