import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { Button, Link } from '~/common/components';
import { copyToClipboard } from '~/common/utils';
import { useOrderData } from '~/orders/hooks';
import { FilesSubSection } from './FilesSubSection';
export const FilesSection = () => {
    var _a, _b;
    const { brief, files } = useOrderData();
    if (!((_a = brief.google_slides_urls) === null || _a === void 0 ? void 0 : _a.length) && !files.length) {
        return null;
    }
    return (_jsx(FilesSubSection, { files: files, tag: "customer_file", title: "File to work", children: !!((_b = brief.google_slides_urls) === null || _b === void 0 ? void 0 : _b.length) && (_jsxs("div", { children: [_jsx("p", { className: "font-brand-t4m text-greyscale-600 mb-[4xp]", children: "Google Slide" }), brief.google_slides_urls.map((url) => (_jsxs("div", { className: "flex gap-1 justify-between", children: [_jsx(Link, { newTab: true, href: url, className: "truncate font-brand-b1 text-secondary-300", children: url }), _jsx(Button.Icon, { "data-tt": "Copy link", icon: faCopy, onClick: () => copyToClipboard(url) })] }, url)))] })) }));
};
