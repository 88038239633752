import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { invoice } from '~/assets/images';
import { IconBox, Link, Tooltip } from '~/common/components';
import { cardBrandToImg, cx, formatDayMonthYear, formatMonthToYearsAndMonth } from '~/common/utils';
import { useCurrentUserData } from '~/root';
import { Header } from './CreateSubscription';
export const SubscriptionView = ({ subscription, }) => {
    const { permissions } = useCurrentUserData();
    const retainerProduct = subscription.products.find(({ plan: { type } }) => type === 'retainer');
    return (_jsxs(_Fragment, { children: [_jsx(Header, {}), _jsxs(PlanInfoGrid, { className: "border-t border-solid border-greyscale-200", children: [_jsx(PlanInfoRow, { title: "Capacity", text: retainerProduct ? (_jsxs("div", { className: "flex justify-between items-center w-full", children: [_jsx("span", { children: retainerProduct.daily_points }), permissions.subscriptionsView && subscription.capacity_overview_link && (_jsx(Link, { size: "m-bold", external: true, newTab: true, href: subscription.capacity_overview_link, children: "Overview" }))] })) : ('-') }), _jsx(PlanInfoRow, { title: "Renewal", text: formatMonthToYearsAndMonth(subscription.renewal_period) }), _jsx(PlanInfoRow, { title: "Next renewal", text: subscription.renews_at && formatDayMonthYear(subscription.renews_at) }), _jsx(PlanPaymentRow, { payment_method: subscription.payment_method }), _jsx(PlanInfoRow, { title: "Top up", text: (retainerProduct === null || retainerProduct === void 0 ? void 0 : retainerProduct.enable_topup) ? 'on' : 'off' })] })] }));
};
export const PlanInfoGrid = ({ children, className, }) => (_jsx("div", { className: cx('grid grid-cols-[auto_1fr] items-center gap-x-5 px-3 py-2 w-full', className), children: children }));
export const PlanPaymentRow = ({ payment_method }) => (_jsx(PlanInfoRow, { title: "Payment", text: payment_method &&
        ('brand' in payment_method ? (_jsx(CardPaymentMethod, { brand: payment_method.brand, last4: payment_method.last4 })) : (_jsx(InvoicePaymentMethod, {}))) }));
export const PlanInfoRow = ({ title, text, hint, }) => {
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex gap-x-[4px] items-center", children: [_jsx("span", { className: "font-brand-t4m text-greyscale-400 whitespace-nowrap", children: title }), hint && (_jsx(Tooltip, { content: hint, placement: "right", className: "max-w-[230px]", children: _jsx(IconBox, { size: "s", className: "pointer-events-auto", icon: faInfoCircle }) }))] }), _jsx("div", { className: "flex items-center font-brand-b2r h-5", children: text || '—' })] }));
};
const CardPaymentMethod = ({ last4, brand }) => (_jsxs("div", { className: "font-brand-b2r flex items-center gap-x-2", children: [_jsx("img", { className: "w-[36px] h-3", src: cardBrandToImg(brand), alt: brand }), _jsxs("span", { className: "whitespace-nowrap", children: ["**** ", last4] })] }));
const InvoicePaymentMethod = () => (_jsxs("div", { className: "font-brand-b2r flex items-center gap-x-1", children: [_jsx("img", { className: "w-[36px] h-3", src: invoice, alt: "invoice method" }), _jsx("span", { className: "whitespace-nowrap", children: "Bank transfer" })] }));
