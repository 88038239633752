import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Button, useModalContext } from '~/common/components';
import { useCustomerData } from '~/customers/hooks';
import { useCreateSubscriptionData, ActivateSubscription } from './CreateSubscription';
import { SubscriptionView } from './SubscriptionView';
const subscriptionWrapper = 'bg-greyscale-0 rounded overflow-hidden';
export const Subscription = () => {
    const { subscription } = useCustomerData();
    if (!subscription) {
        return (_jsx("div", { className: subscriptionWrapper, children: _jsx(NoSubscriptionView, {}) }));
    }
    return (_jsx("div", { className: subscriptionWrapper, children: _jsx(SubscriptionView, { subscription: subscription }) }));
};
const NoSubscriptionView = () => {
    const { modalOpener } = useModalContext();
    return (_jsxs("div", { className: "flex items-center justify-between px-3 py-2 gap-x-1", children: [_jsx("h2", { className: "font-brand-t1m", children: "No Subscription" }), _jsx(Button, { color: "primary", size: "medium", icon: faPlus, onClick: modalOpener(ActivateSubscription, { useCreateSubscriptionData }), children: "Add subscription" })] }));
};
