import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { tusUpload, getFriendlyUploadError } from '~/common/utils';
import { useEvent } from './useEvent';
export const useFileUploadState = ({ tag, props, onError, preprocess, existingFilename = null, }) => {
    const [filename, setFilename] = useState(existingFilename);
    const resetErrors = () => onError(null);
    const onBlur = useEvent(props.onBlur);
    const mutation = useMutation({
        mutationFn: async (file) => {
            let _file = file;
            if (preprocess) {
                _file = await preprocess(file);
            }
            return tusUpload({ file: _file, tag }).catch((error) => {
                // the reason we're catching here is because otherwise we can't prevent
                // react-query from screaming about handled errors, and we can't make
                // it vocal about not handled errors
                const { message, shouldThrow } = getFriendlyUploadError(error);
                onError(message);
                if (shouldThrow) {
                    throw error;
                }
                return undefined;
            });
        },
        onMutate: resetErrors,
        onSuccess: (next) => {
            if (next) {
                props.onChange(next.id);
                setTimeout(onBlur, 50);
            }
        },
    });
    return {
        ...props,
        value: filename,
        onChange: (file) => {
            if (file) {
                setFilename(file.name);
                mutation.mutateAsync(file);
            }
            else {
                setFilename(null);
                props.onChange(null);
                resetErrors();
            }
        },
        loading: mutation.isLoading,
    };
};
