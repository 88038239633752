import { useMemo } from 'react';
import { useSubscriptionData, useInitData } from '~/subscriptions/hooks';
export const useCreateSubscriptionData = () => {
    const subscription = useSubscriptionData();
    const init = useInitData();
    const haveIndividualPricing = useMemo(() => JSON.stringify(subscription.treatments) !== JSON.stringify(init.default_treatments), [subscription.treatments, init.default_treatments]);
    return {
        sales: init.sales,
        supportedCurrencies: init.supported_currencies,
        renewalPeriodOptions: init.product_options.subscription.allowed_renewal_periods,
        projectManagers: init.project_managers,
        designTeams: init.design_teams,
        ownerId: subscription.owner_id,
        isCustomerTrusted: subscription.is_trusted,
        invoicePaymentMethod: subscription.invoicePaymentMethod,
        paymentMethods: subscription.payment_methods,
        productsList: init.filter_options.plan,
        haveIndividualPricing,
    };
};
