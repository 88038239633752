import { $isListItemNode, $isListNode, INSERT_CHECK_LIST_COMMAND, INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, ListNode, } from '@lexical/list';
import { $isDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode';
import { $createHeadingNode, $createQuoteNode, $isHeadingNode, $isQuoteNode, } from '@lexical/rich-text';
import { $setBlocksType } from '@lexical/selection';
import { $getNearestNodeOfType, $findMatchingParent, $getNearestBlockElementAncestorOrThrow, } from '@lexical/utils';
import { $createParagraphNode, $getSelection, $isRangeSelection, $isRootOrShadowRoot, $isTextNode, FORMAT_TEXT_COMMAND, } from 'lexical';
import { useEffect, useState } from 'react';
import { capitalize, record } from '~/common/utils';
import { EDIT_LINK_COMMAND } from './EditLinkPlugin';
import { getIsSelectionInLink } from './utils';
// mostly copypaste of
// https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/plugins/ToolbarPlugin/index.tsx
const blockTypeToBlockName = {
    bullet: 'Bulleted List',
    check: 'Check List',
    code: 'Code Block',
    h1: 'Heading 1',
    h2: 'Heading 2',
    h3: 'Heading 3',
    h4: 'Heading 4',
    h5: 'Heading 5',
    h6: 'Heading 6',
    number: 'Numbered List',
    paragraph: 'Normal',
    quote: 'Quote',
};
const formattingStates = ['bold', 'italic', 'underline', 'strikethrough'];
const initialState = record.fromEntries(formattingStates.map((state) => [state, false]));
const actions = (editor) => {
    return record.fromEntries(formattingStates.map((state) => [
        `format${capitalize(state)}`,
        () => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, state);
        },
    ]));
};
export const useFormatting = (editor) => {
    const [state, setState] = useState(initialState);
    const [isLink, setIsLink] = useState(false);
    const [blockType, setBlockType] = useState(null);
    useEffect(() => {
        return editor.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {
                const selection = $getSelection();
                if (!$isRangeSelection(selection))
                    return;
                const anchorNode = selection.anchor.getNode();
                let element = anchorNode.getKey() === 'root'
                    ? anchorNode
                    : $findMatchingParent(anchorNode, (e) => {
                        const parent = e.getParent();
                        return parent !== null && $isRootOrShadowRoot(parent);
                    });
                if (element === null) {
                    element = anchorNode.getTopLevelElementOrThrow();
                }
                const elementKey = element.getKey();
                const elementDOM = editor.getElementByKey(elementKey);
                if (elementDOM !== null) {
                    if ($isListNode(element)) {
                        const parentList = $getNearestNodeOfType(anchorNode, ListNode);
                        const type = parentList ? parentList.getListType() : element.getListType();
                        setBlockType(type);
                    }
                    else {
                        const type = $isHeadingNode(element) ? element.getTag() : element.getType();
                        if (type in blockTypeToBlockName) {
                            setBlockType(type);
                        }
                    }
                }
                setState(record.fromEntries(formattingStates.map((state) => [state, selection.hasFormat(state)])));
                // Update links
                setIsLink(getIsSelectionInLink());
            });
        });
    }, [editor]);
    const formatParagraph = () => {
        editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                $setBlocksType(selection, () => $createParagraphNode());
            }
        });
    };
    const formatHeading = (headingSize) => {
        if (blockType !== headingSize) {
            editor.update(() => {
                const selection = $getSelection();
                $setBlocksType(selection, () => $createHeadingNode(headingSize));
            });
        }
        else {
            formatParagraph();
        }
    };
    const formatBulletList = () => {
        if (blockType !== 'bullet') {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
        }
        else {
            formatParagraph();
        }
    };
    const formatCheckList = () => {
        if (blockType !== 'check') {
            editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
        }
        else {
            formatParagraph();
        }
    };
    const formatNumberedList = () => {
        if (blockType !== 'number') {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
        }
        else {
            formatParagraph();
        }
    };
    const formatQuote = () => {
        if (blockType !== 'quote') {
            editor.update(() => {
                const selection = $getSelection();
                $setBlocksType(selection, () => $createQuoteNode());
            });
        }
        else {
            formatParagraph();
        }
    };
    const clearFormatting = () => {
        editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                const anchor = selection.anchor;
                const focus = selection.focus;
                const nodes = selection.getNodes();
                if (anchor.key === focus.key && anchor.offset === focus.offset) {
                    return;
                }
                // Check if the selection is at the start and end of a block node
                if (nodes.length > 0) {
                    const firstNode = nodes[0];
                    const lastNode = nodes[nodes.length - 1];
                    const parentNode = firstNode.getParent();
                    // Exclude the root node
                    if (!parentNode || parentNode.getKey() === 'root') {
                        return;
                    }
                    if ($isListItemNode(parentNode)) {
                        // If the parent node is a list item, check if the entire list is selected
                        const parentListNode = parentNode.getParent();
                        if (parentListNode &&
                            $isListNode(parentListNode) &&
                            parentListNode.getFirstChild() === parentNode &&
                            parentListNode.getLastChild() === lastNode.getParent()) {
                            // If the selection spans from the first to the last list item, include the list node
                            nodes.unshift(parentListNode);
                        }
                    }
                    else if (($isHeadingNode(parentNode) || $isQuoteNode(parentNode)) &&
                        parentNode.getFirstChild() === firstNode &&
                        parentNode.getLastChild() === lastNode) {
                        // Include the parent block node in the nodes to be processed
                        nodes.unshift(parentNode);
                    }
                }
                nodes.forEach((node, idx) => {
                    // We split the first and last node by the selection
                    // So that we don't format unselected text inside those nodes
                    if ($isTextNode(node)) {
                        // Use a separate variable to ensure TS does not lose the refinement
                        let textNode = node;
                        if (idx === 0 && anchor.offset !== 0) {
                            textNode = textNode.splitText(anchor.offset)[1] || textNode;
                        }
                        if (idx === nodes.length - 1) {
                            textNode = textNode.splitText(focus.offset)[0] || textNode;
                        }
                        if (textNode.__style !== '') {
                            textNode.setStyle('');
                        }
                        if (textNode.__format !== 0) {
                            textNode.setFormat(0);
                            $getNearestBlockElementAncestorOrThrow(textNode).setFormat('');
                        }
                        node = textNode;
                    }
                    else if ($isHeadingNode(node) || $isQuoteNode(node) || $isListNode(node)) {
                        node.replace($createParagraphNode(), true);
                    }
                    else if ($isDecoratorBlockNode(node)) {
                        node.setFormat('');
                    }
                });
            }
        });
    };
    const toggleLink = () => {
        editor.dispatchCommand(EDIT_LINK_COMMAND, null);
    };
    return {
        ...actions(editor),
        isBold: state.bold,
        isItalic: state.italic,
        isUnderline: state.underline,
        isStrikethrough: state.strikethrough,
        isNumber: blockType === 'number',
        isBullet: blockType === 'bullet',
        isCheck: blockType === 'check',
        isQuote: blockType === 'quote',
        isLink,
        blockType,
        formatBulletList,
        formatNumberedList,
        formatCheckList,
        formatQuote,
        formatHeading,
        clearFormatting,
        toggleLink,
    };
};
