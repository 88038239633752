import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { tfIcon, tfLogo } from '~/assets/images';
import { cx } from '~/common/utils';
import { NavigationItem } from './NavigationItem';
import css from './Navigation.module.scss';
export const Navigation = ({ navs }) => {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
    return (_jsx("div", { className: cx('navigation', css.nav), children: _jsxs("div", { onFocus: () => setSidebarCollapsed(false), onBlur: () => setSidebarCollapsed(true), onMouseEnter: () => setSidebarCollapsed(false), onMouseLeave: () => setSidebarCollapsed(true), className: cx(css.inner, { [css.collapsed]: sidebarCollapsed }), children: [_jsx(RouterLink, { className: css.link, to: "/", children: _jsx("img", { className: "h-3 w-auto", src: sidebarCollapsed ? tfIcon : tfLogo, alt: "24Slides" }) }), _jsx("ul", { className: "h-full overflow-auto", children: navs === null || navs === void 0 ? void 0 : navs.map((item, key) => _jsx(NavigationItem, { ...item }, key)) })] }) }));
};
