import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { set } from '~/common/utils';
export const sortFilesAlphanumerically = (files) => {
    return files.slice().sort((a, b) => {
        return a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base',
        });
    });
};
export const sortingBehaviors = ['Sort all', 'Sort deck', 'As is'];
export const isSlide = (preview) => 'annotations' in preview;
export const isFile = (preview) => !('annotations' in preview);
const getPreviewById = (id, previews) => {
    var _a;
    return (_a = previews.find((preview) => preview.id === id)) !== null && _a !== void 0 ? _a : null;
};
export function usePreviewsState({ slides, resetKey, addingNewRevision, editing, urlAnnotationId, }) {
    const slideLookup = useMemo(() => {
        const map = {};
        if (slides) {
            for (const preview of slides) {
                if (isSlide(preview)) {
                    for (const annotation of preview.annotations) {
                        map[annotation.id] = preview.id;
                    }
                }
            }
        }
        return map;
    }, [slides]);
    const getState = useCallback(() => {
        var _a, _b;
        return {
            previews: (slides !== null && slides !== void 0 ? slides : []),
            selectedPreviewId: urlAnnotationId
                ? slideLookup[urlAnnotationId]
                : (_b = (_a = slides === null || slides === void 0 ? void 0 : slides[0]) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null,
            selectedPreviewIds: new Set(),
            selectedAnnotationId: urlAnnotationId !== null && urlAnnotationId !== void 0 ? urlAnnotationId : null,
            sortingBehavior: 'Sort all',
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slides, resetKey]);
    const [state, setState] = useState(getState());
    const addPreviews = useCallback((previews) => {
        setState((state) => ({
            ...state,
            previews: [...state.previews, ...previews],
        }));
    }, []);
    const choosePreview = useCallback((id) => {
        setState((state) => ({
            ...state,
            selectedPreviewId: id,
        }));
    }, []);
    const removePreview = useCallback((id) => {
        setState((state) => ({
            ...state,
            previews: state.previews.filter((preview) => preview.id !== id),
            selectedPreviewId: state.selectedPreviewId === id ? null : state.selectedPreviewId,
        }));
    }, []);
    const removeSelectedPreviews = useCallback(() => {
        setState((state) => ({
            ...state,
            previews: state.previews.filter((preview) => !state.selectedPreviewIds.has(preview.id)),
            selectedPreviewIds: new Set(),
        }));
    }, []);
    const removeAllPreviews = useCallback(() => {
        setState((state) => ({
            ...state,
            previews: [],
            selectedPreviewIds: new Set(),
        }));
    }, []);
    const swapPreviews = useCallback((previews) => {
        setState((state) => ({
            ...state,
            previews,
        }));
    }, []);
    const sortPreviews = useCallback(() => {
        setState((state) => ({
            ...state,
            previews: sortFilesAlphanumerically(state.previews),
        }));
    }, []);
    const setSortingBehavior = useCallback((sortingBehavior) => {
        setState((state) => ({
            ...state,
            sortingBehavior,
        }));
    }, []);
    const toggleSelectPreview = useCallback((id) => {
        setState((state) => ({
            ...state,
            selectedPreviewIds: set.toggle(state.selectedPreviewIds, id),
        }));
    }, []);
    const toggleSelectAll = useCallback(() => {
        setState((state) => {
            const partiallySelected = state.selectedPreviewIds.size < state.previews.length;
            return {
                ...state,
                selectedPreviewIds: new Set(partiallySelected ? state.previews.map(({ id }) => id) : []),
            };
        });
    }, []);
    const slideIds = useMemo(() => Object.keys(slideLookup).map((k) => parseInt(k)), [slideLookup]);
    const selectAnnotation = useCallback((id) => {
        setState((state) => state.selectedAnnotationId === id || id === null
            ? { ...state, selectedAnnotationId: null }
            : {
                ...state,
                selectedAnnotationId: id,
                selectedPreviewId: slideLookup[id],
            });
    }, [slideLookup]);
    const selectPrevAnnotation = useCallback(() => {
        setState((state) => {
            var _a;
            const prevIndex = slideIds.findIndex((id) => id === state.selectedAnnotationId) - 1;
            const id = (_a = slideIds[prevIndex]) !== null && _a !== void 0 ? _a : state.selectedAnnotationId;
            return {
                ...state,
                selectedAnnotationId: id,
                selectedPreviewId: slideLookup[id],
            };
        });
    }, [slideIds, slideLookup]);
    const selectNextAnnotation = useCallback(() => {
        setState((state) => {
            var _a;
            const nextIndex = slideIds.findIndex((id) => id === state.selectedAnnotationId) + 1;
            const id = (_a = slideIds[nextIndex]) !== null && _a !== void 0 ? _a : state.selectedAnnotationId;
            return {
                ...state,
                selectedAnnotationId: id,
                selectedPreviewId: slideLookup[id],
            };
        });
    }, [slideIds, slideLookup]);
    const firstAnnotationSelected = useMemo(() => {
        return slideIds.findIndex((id) => id === state.selectedAnnotationId) === 0;
    }, [slideIds, state.selectedAnnotationId]);
    const lastAnnotationSelected = useMemo(() => {
        const length = slideIds.findIndex((id) => id === state.selectedAnnotationId) + 1;
        return length === slideIds.length;
    }, [slideIds, state.selectedAnnotationId]);
    const chosenPreview = useMemo(() => {
        return getPreviewById(state.selectedPreviewId, state.previews);
    }, [state.previews, state.selectedPreviewId]);
    useLayoutEffect(() => {
        if (editing) {
            return;
        }
        setState((state) => {
            var _a;
            if (addingNewRevision) {
                return {
                    ...state,
                    previews: [],
                    selectedPreviewId: null,
                    selectedPreviewIds: new Set(),
                    selectedAnnotationId: null,
                };
            }
            const defaultState = getState();
            const chosenPreview = getPreviewById(state.selectedPreviewId, defaultState.previews);
            return {
                ...defaultState,
                // to avoid resetting selected preview after leaving a comment in annotation
                selectedPreviewId: chosenPreview ? state.selectedPreviewId : defaultState.selectedPreviewId,
                selectedAnnotationId: (_a = state.selectedAnnotationId) !== null && _a !== void 0 ? _a : defaultState.selectedAnnotationId,
            };
        });
    }, [addingNewRevision, editing, getState]);
    return {
        state: {
            ...state,
            chosenPreview,
            firstAnnotationSelected,
            lastAnnotationSelected,
        },
        action: {
            addPreviews,
            choosePreview,
            removePreview,
            removeSelectedPreviews,
            removeAllPreviews,
            swapPreviews,
            sortPreviews,
            setSortingBehavior,
            toggleSelectPreview,
            toggleSelectAll,
            selectAnnotation,
            selectPrevAnnotation,
            selectNextAnnotation,
        },
    };
}
