import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faRotateLeft } from '@fortawesome/pro-solid-svg-icons';
import { useForm } from 'formoid';
import { z } from 'zod';
import { Button, ButtonGroup, InputField, ModalHeader, SelectField, } from '~/common/components';
import { fromZodSchema, propagateBackendValidationErrors } from '~/common/utils';
import { useBriefDetailsMutation, useOrderData, usePreviousBriefDetails } from '~/orders/hooks';
export const EditBriefDetails = ({ onClose }) => {
    var _a;
    const { brief } = useOrderData();
    const initialValues = {
        aspect_ration: brief.answers.aspect_ration,
        color_preference: brief.answers.color_preference,
        file_output: brief.answers.file_output,
        preferred_fonts: brief.answers.preferred_fonts,
        illustration_preference: brief.answers.illustration_preference,
        redrawable_elements: brief.answers.redrawable_elements,
        branding_sources: brief.answers.branding_sources,
    };
    const briefDetailsMutation = useBriefDetailsMutation();
    const previousBriefDetails = usePreviousBriefDetails();
    const { fieldProps, setErrors, handleSubmit, setValues } = useForm({
        initialValues,
        validationStrategy: 'onBlur',
        validators: () => fromZodSchema({
            aspect_ration: null,
            color_preference: ['Color preferences', z.string().max(80).nullable()],
            file_output: null,
            preferred_fonts: ['Fonts', z.string().max(80).nullable()],
            illustration_preference: null,
            redrawable_elements: ['Redraw slides', z.string().min(0).max(80).nullable()],
            branding_sources: ['Web reference', z.string().min(0).max(1000).nullable()],
        }),
    });
    const onSubmit = () => handleSubmit((values) => briefDetailsMutation
        .mutateAsync(values)
        .then(onClose)
        .catch((error) => {
        propagateBackendValidationErrors(error, setErrors);
    }));
    const onRestore = () => {
        var _a;
        if (!((_a = previousBriefDetails.data) === null || _a === void 0 ? void 0 : _a.answers) || previousBriefDetails.isLoading) {
            return;
        }
        const { answers } = previousBriefDetails.data;
        setValues(() => answers);
    };
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Details" }), _jsxs("div", { className: "grid grid-cols-3 gap-x-3 gap-y-2 p-3 w-[712px]", children: [_jsx(SelectField, { ...fieldProps('aspect_ration'), options: brief.questions.aspect_ration, title: "Page size", placeholder: "\u2014" }), _jsx(InputField, { ...fieldProps('color_preference'), title: "Color preferences", placeholder: "\u2014", type: "text" }), _jsx(InputField, { ...fieldProps('preferred_fonts'), title: "Font", placeholder: "\u2014", type: "text" }), _jsx(SelectField, { ...fieldProps('illustration_preference'), options: brief.questions.illustration_preference, title: "Photo vs Illustration", placeholder: "\u2014" }), _jsx(SelectField, { ...fieldProps('file_output'), options: brief.questions.file_output, title: "File output", placeholder: "\u2014" }), _jsx(InputField, { ...fieldProps('redrawable_elements'), title: "Redraw slide", placeholder: "\u2014", type: "text" }), _jsx(InputField, { ...fieldProps('branding_sources'), title: "Web reference", placeholder: "\u2014", type: "text" })] }), _jsxs(ButtonGroup, { children: [_jsx(Button, { color: "text-secondary", size: "xs", className: "w-fit mr-auto", icon: faRotateLeft, onClick: onRestore, disabled: !((_a = previousBriefDetails.data) === null || _a === void 0 ? void 0 : _a.answers) || briefDetailsMutation.isLoading, loading: previousBriefDetails.isLoading, children: "Keep as previous order" }), _jsx(Button, { size: "medium", onClick: onClose, disabled: briefDetailsMutation.isLoading, children: "Cancel" }), _jsx(Button, { color: "primary", onClick: onSubmit, size: "medium", loading: briefDetailsMutation.isLoading, children: "Save" })] })] }));
};
