import { jsx as _jsx } from "react/jsx-runtime";
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { useEffect, useState } from 'react';
import { IconButton, Input } from '~/common/components';
export const SearchField = ({ value, onChange, onChangeStrategy = 'onSubmit', placeholder = 'Search', size = 'small', ...props }) => {
    const [search, setSearch] = useState(value);
    const handleChange = (event) => {
        setSearch(event.target.value);
        onChangeStrategy === 'onChange' && onChange(event.target.value);
    };
    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            onChange(search);
        }
    };
    const handleClear = () => {
        onChange('');
        setSearch('');
    };
    useEffect(() => setSearch(value), [value]);
    return (_jsx(Input, { ...props, icon: faSearch, value: search, onChange: handleChange, onKeyDown: handleEnter, placeholder: placeholder, size: size, noIconBorder: true, children: value && (_jsx(IconButton, { size: "s", className: "text-greyscale-300 hover:text-greyscale-500 focus-within:text-greyscale-500", icon: faTimesCircle, onClick: handleClear })) }));
};
