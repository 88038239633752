import { useForm } from 'formoid';
import { useCallback, useEffect, useRef, useState } from 'react';
import { customValidator, record } from '~/common/utils';
import { advancedInvoiceOptionsFormValidators, getUUIDValidators, paymentMethodFormValidators, productsSharedValidators, } from '~/customers/Customer';
const updateSubscriptionValidators = (isScheduled) => (values) => ({
    ...paymentMethodFormValidators(values),
    ...advancedInvoiceOptionsFormValidators(values),
    ...productsSharedValidators,
    schedule_update_at: isScheduled ? customValidator.required() : null,
});
export const useSubscriptionForm = ({ initialProducts, initialValues, productsList, isScheduled, values, }) => {
    // Some sort of hacks to be able to receive updated list of products from outside of this hook for whatever reason
    // Just provide callback to any of exported methods as second argument
    // and you'll receive new products as this callback first argument
    const callback = useRef();
    const [products, setProducts] = useState(initialProducts || [{ type: null }]);
    const productsValidators = useCallback((values) => productsList.reduce((validators, productType) => {
        validators = {
            ...validators,
            ...getUUIDValidators({
                type: productType,
                values,
                enabled: products.some(({ type }) => type === productType),
            }),
        };
        return validators;
    }, {}), [productsList, products]);
    const form = useForm({
        initialValues,
        validators: (values) => ({
            ...updateSubscriptionValidators(isScheduled)(values),
            ...productsValidators(values),
        }),
        validationStrategy: 'onBlur',
    });
    const addProduct = (type, cb) => {
        const newProduct = {
            type: type !== null && type !== void 0 ? type : null,
        };
        setProducts((products) => [...products, newProduct]);
        if (cb) {
            callback.current = cb;
        }
    };
    const removeProduct = (index, cb) => {
        const newProducts = [...products];
        const [removedElement] = newProducts.splice(index, 1);
        setProducts(newProducts);
        if (cb) {
            callback.current = cb;
        }
        return removedElement;
    };
    const updateProductType = ({ type, index }, cb) => {
        const newProducts = [...products];
        newProducts[index] = { ...newProducts[index], type };
        setProducts(newProducts);
        if (cb) {
            callback.current = cb;
        }
        return newProducts;
    };
    useEffect(() => {
        if (callback.current) {
            callback.current(products);
            callback.current = null;
        }
    }, [products]);
    useEffect(() => {
        if (values) {
            record.entries(values).forEach(([key, value]) => {
                if (initialValues[key] !== value) {
                    form.fieldProps(key).onChange(value);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return {
        form,
        products: {
            list: products,
            validators: productsValidators,
            addProduct,
            removeProduct,
            updateProductType,
        },
    };
};
