import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge, Tooltip } from '~/common/components';
export const TeamsView = ({ name, tag_teams }) => {
    return (_jsxs("div", { className: "flex gap-1 items-center", children: [_jsx("span", { className: "block truncate", children: name }), _jsx(TagTeamBubble, { tag_teams: tag_teams })] }));
};
export const TagTeamBubble = ({ tag_teams }) => {
    if (!tag_teams.length) {
        return null;
    }
    return (_jsx(Tooltip, { content: _jsx("div", { className: "flex flex-col gap-1", children: tag_teams.map(({ id, name }) => (_jsx("div", { children: name }, id))) }), noArrow: true, className: "font-brand-b2r px-2 py-1", placement: "bottom-start", compensateOffset: 10, children: _jsxs(Badge, { size: "small", color: "lighter-grey", children: ["+", tag_teams.length] }) }));
};
