import { useQuery, useMutation } from '@tanstack/react-query';
import { useAdvancedUpdateCache, useIdParam } from '~/common/hooks';
import { usePrivateChannel } from '~/common/kits/socket';
import { httpClient, nonNullable } from '~/common/utils';
import { qk } from '~/root/query-keys';
import { Checklist, ChecklistItem, } from './domain';
export const useUpdateChecklistCache = () => {
    const id = useIdParam();
    return useAdvancedUpdateCache(qk.collaborationChecklist(id));
};
export const useChecklist = (params) => {
    const { enabled = true } = params || {};
    const id = useIdParam();
    return useQuery({
        queryKey: qk.collaborationChecklist(id),
        queryFn: () => {
            return httpClient.get(`/v1/staff/orders/${id}/collaboration/checklist`, {
                decoder: Checklist,
            });
        },
        enabled,
    });
};
export const useAddChecklistItem = () => {
    const id = useIdParam();
    return useMutation({
        mutationKey: qk.collaborationChecklistUpdate(id),
        mutationFn: (data) => {
            return httpClient.post(`/v1/staff/orders/${id}/collaboration/checklist`, {
                data,
                decoder: ChecklistItem,
            });
        },
    });
};
export const useReorderChecklist = () => {
    const id = useIdParam();
    const updateCache = useUpdateChecklistCache();
    return useMutation({
        mutationKey: qk.collaborationChecklistUpdate(id),
        mutationFn: (items) => {
            return httpClient.post(`/v1/staff/orders/${id}/collaboration/checklist/reorder`, {
                data: {
                    items: items.map(({ id, index }) => ({ id, index })),
                },
            });
        },
        onMutate: async (items) => {
            await updateCache.cancelQuery();
            const prev = updateCache.getQuery();
            updateCache.setQuery({ items });
            return prev;
        },
        onError: (_error, _variables, prev) => updateCache.setQuery(prev),
    });
};
export const useUpdateChecklistItem = () => {
    const id = useIdParam();
    const { setQuery } = useUpdateChecklistCache();
    return useMutation({
        mutationKey: qk.collaborationChecklistUpdate(id),
        mutationFn: ({ id: itemId, ...data }) => {
            return httpClient.put(`/v1/staff/orders/${id}/collaboration/checklist/${itemId}`, {
                data,
                decoder: ChecklistItem,
            });
        },
        onSuccess: (updatedItem) => {
            setQuery(({ items }) => ({
                items: items.map((item) => (updatedItem.id === item.id ? updatedItem : item)),
            }));
        },
    });
};
export const useToggleChecklistItem = () => {
    const id = useIdParam();
    const updateCache = useUpdateChecklistCache();
    return useMutation({
        mutationKey: qk.collaborationChecklistToggle(id),
        mutationFn: (itemId) => {
            var _a;
            const prevDone = nonNullable((_a = updateCache.getQuery().items.find((item) => item.id === itemId)) === null || _a === void 0 ? void 0 : _a.done);
            return httpClient.put(`/v1/staff/orders/${id}/collaboration/checklist/${itemId}`, {
                data: { done: prevDone },
                decoder: ChecklistItem,
            });
        },
        onMutate: async (itemId) => {
            await updateCache.cancelQuery();
            const prev = updateCache.getQuery();
            updateCache.setQuery(({ items }) => ({
                items: items.map((item) => (item.id === itemId ? { ...item, done: !item.done } : item)),
            }));
            return prev;
        },
        onError: (_error, _variables, prev) => updateCache.setQuery(prev),
        onSuccess: (updatedItem) => {
            updateCache.setQuery(({ items }) => ({
                items: items.map((item) => (updatedItem.id === item.id ? updatedItem : item)),
            }));
        },
    });
};
export const useDeleteChecklistItem = () => {
    const id = useIdParam();
    const updateCache = useUpdateChecklistCache();
    return useMutation({
        mutationKey: qk.collaborationChecklistDelete(id),
        mutationFn: (itemId) => {
            return httpClient.delete(`/v1/staff/orders/${id}/collaboration/checklist/${itemId}`);
        },
        onMutate: async (itemId) => {
            await updateCache.cancelQuery();
            const prev = updateCache.getQuery();
            updateCache.setQuery(({ items }) => ({
                items: items.filter((item) => item.id !== itemId),
            }));
            return prev;
        },
        onError: (_error, _variables, prev) => updateCache.setQuery(prev),
    });
};
export const useCheckListEvents = (params) => {
    const { enabled = true } = params || {};
    const id = useIdParam();
    const updateCache = useUpdateChecklistCache();
    usePrivateChannel({
        channelName: `staff.order.${id}`,
        enabled,
        events: [
            {
                event: '.order.collaboration.checklist.sync',
                decoder: Checklist,
                callback: updateCache.setQuery,
            },
        ],
    });
};
