import { computePosition, arrow, flip, shift, offset, autoUpdate, } from '@floating-ui/dom';
import { cx } from '~/common/utils';
import css from '../Tooltip.module.scss';
const staticSides = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
};
const tooltipNodesMap = new Map();
const tooltipsObserver = new MutationObserver(() => {
    tooltipNodesMap.forEach((node) => {
        if (!node.ref.isConnected) {
            tooltipCleanUp(node.ref);
        }
    });
});
//@internal
export const mountGlobalTooltipPortal = () => {
    const tooltipPortal = document.createElement('div');
    tooltipPortal.setAttribute('data-24slides-tooltip-portal', '');
    tooltipPortal.style.zIndex = '10000';
    document.body.appendChild(tooltipPortal);
    tooltipsObserver.observe(document.body, {
        childList: true,
        subtree: true,
    });
};
//@internal
export const unmountGlobalTooltipPortal = () => {
    const tooltipPortal = document.querySelector('[data-24slides-tooltip-portal]');
    if (!tooltipPortal)
        throw new Error('24slides Global tooltip portal not found');
    tooltipsObserver.disconnect();
    document.body.removeChild(tooltipPortal);
};
const getGlobalTooltipPortal = () => {
    const tooltipPortal = document.querySelector('[data-24slides-tooltip-portal]');
    if (!tooltipPortal)
        throw new Error('24slides Global tooltip portal not found');
    return tooltipPortal;
};
//@internal
export const mountOrUpdateGlobalTooltip = (ref) => {
    if (tooltipNodesMap.has(ref)) {
        //TODO there should be a way to update the tooltip without unmounting and mounting again
        return;
    }
    mountGlobalTooltip(ref);
};
const enterAnimation = (ref) => {
    const node = tooltipNodesMap.get(ref);
    if (!node)
        return;
    const { tooltip } = node;
    tooltip.style.transitionDelay = '300ms';
    tooltip.classList.remove(css.exit);
    tooltip.classList.remove(css.exitActive);
    tooltip.classList.add(css.enter);
    //This forces repaint to make sure transition is triggered
    //https://github.com/reactjs/react-transition-group/blob/5007303e729a74be66a21c3e2205e4916821524b/src/CSSTransition.js#L208-L215
    tooltip.scrollTop;
    tooltip.classList.add(css.enterActive);
};
const exitAnimation = (ref) => {
    const node = tooltipNodesMap.get(ref);
    if (!node)
        return;
    const { tooltip } = node;
    tooltip.style.transitionDelay = '0ms';
    tooltip.classList.remove(css.enter);
    tooltip.classList.remove(css.enterActive);
    tooltip.classList.add(css.exit);
    tooltip.classList.add(css.exitActive);
};
const tooltipCleanUp = (ref) => {
    const node = tooltipNodesMap.get(ref);
    if (!node)
        return;
    const { tooltip, cleanup } = node;
    cleanup();
    tooltip.remove();
    tooltipNodesMap.delete(ref);
};
const validateTooltipDataColor = (color) => {
    if (!color)
        return 'white';
    return ['white', 'grey', 'danger', 'lightGrey'].includes(color) ? color : 'white';
};
const mountGlobalTooltip = (ref) => {
    const portal = getGlobalTooltipPortal();
    const tooltip = document.createElement('div');
    const tooltipDataClassName = ref.getAttribute('data-tt-class');
    const tooltipDataColor = validateTooltipDataColor(ref.getAttribute('data-tt-color'));
    tooltip.className = cx(css.tooltip, css[tooltipDataColor], css.enter, tooltipDataClassName);
    tooltip.style.position = 'absolute';
    const arrowElement = document.createElement('div');
    arrowElement.className = css.arrow;
    arrowElement.style.position = 'absolute';
    const content = document.createElement('div');
    content.innerText = ref.getAttribute('data-tt') || ref.textContent;
    tooltip.appendChild(arrowElement);
    tooltip.appendChild(content);
    portal.appendChild(tooltip);
    //Todo figure how to clean this up, or do I need to
    tooltip.addEventListener('transitionend', () => {
        if (tooltip.classList.contains(css.exit)) {
            tooltipCleanUp(ref);
        }
    });
    const tooltipPlacement = ref.getAttribute('data-tt-placement') || 'top';
    const compensateOffset = Number(ref.getAttribute('data-tt-compensate-offset')) || 0;
    const cleanup = autoUpdate(ref, tooltip, async () => {
        return computePosition(ref, tooltip, {
            placement: tooltipPlacement,
            middleware: [
                offset({
                    mainAxis: 12 - compensateOffset,
                }),
                flip(),
                shift(),
                arrow({ element: arrowElement }),
            ],
        }).then(({ x, y, middlewareData, placement }) => {
            const staticSide = staticSides[placement.split('-')[0]];
            Object.assign(tooltip.style, {
                transform: `translate(${x}px, ${y}px)`,
                top: 0,
                left: 0,
            });
            if (middlewareData.arrow) {
                const { x: arrowX, y: arrowY } = middlewareData.arrow;
                Object.assign(arrowElement.style, {
                    left: arrowX ? `${arrowX}px` : '',
                    top: arrowY ? `${arrowY}px` : '',
                    [staticSide]: '-4px',
                });
            }
            Object.values(staticSides).forEach((side) => {
                tooltip.classList.remove(css[side]);
            });
            tooltip.classList.add(css[staticSide]);
        });
    });
    tooltipNodesMap.set(ref, { ref, tooltip, mountTime: Date.now(), cleanup });
    enterAnimation(ref);
};
//@internal
export const unmountGlobalTooltip = (ref) => {
    const node = tooltipNodesMap.get(ref);
    if (!node)
        return;
    if (Date.now() - node.mountTime <= 300)
        return tooltipCleanUp(ref);
    if (node.tooltip.classList.contains(css.exit))
        return;
    exitAnimation(ref);
};
