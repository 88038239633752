import { useQuery } from '@tanstack/react-query';
import { getFlatQuery, useParsedQuery } from '~/common/kits/table';
import { assertQueryData, httpClient } from '~/common/utils';
import { ReviewsInit, ReviewsList } from '~/orders/domain';
import { qk } from '~/root/query-keys';
export function useReviews() {
    const { queryParams } = useParsedQuery();
    const params = getFlatQuery(queryParams);
    return useQuery({
        queryKey: [...qk.ordersReviewsList, params],
        queryFn: () => httpClient.get('/v1/staff/orders/reviews', { decoder: ReviewsList, params }),
        keepPreviousData: true,
    });
}
export const useReviewsData = () => assertQueryData(useReviews());
export function useReviewsInit() {
    return useQuery({
        queryKey: qk.ordersReviewsInit,
        queryFn: () => httpClient.get('/v1/staff/orders/reviews/init', { decoder: ReviewsInit }),
    });
}
export const useReviewsInitData = () => assertQueryData(useReviewsInit());
