import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, UsersGroup } from '~/common/components';
import { UserCard } from '~/common/kits/usercard';
export const InnerUserRow = ({ label, user, placement, }) => (_jsxs(_Fragment, { children: [_jsx("span", { className: "text-greyscale-500 font-brand-t4m", children: label }), user ? (_jsx("div", { children: _jsx(UserCard, { placement: placement, id: user.id, children: _jsx("span", { children: user.name }) }) })) : (_jsx("span", { className: "truncate", children: "\u2014" }))] }));
export const MultipleInnerUsersRow = ({ label, users, placement, }) => {
    if (!users.length) {
        return (_jsxs(_Fragment, { children: [_jsx("span", { className: "text-greyscale-500 font-brand-t4m", children: label }), _jsx("span", { children: "\u2014" })] }));
    }
    return (_jsxs(_Fragment, { children: [_jsx("span", { className: "text-greyscale-500 font-brand-t4m", children: label }), users.length > 1 ? (_jsx("div", { children: _jsx(UsersGroup, { maxVisible: 6, users: users }) })) : (
            // TODO check how it looks, do we still need a link there since avatar is clickable?
            _jsxs("div", { className: "flex gap-1", children: [_jsx(UserCard, { placement: placement, ...users[0] }), _jsx(Link, { to: `/users/${users[0].id}`, children: users[0].name })] }))] }));
};
