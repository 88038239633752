import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, FiltersButton, LayoutHeader, LayoutTitle, ListCount, Popover, } from '~/common/components';
import { DateRangeFilter, getFilterProps, getSearchFilterDataFromOptions, Multiselect, Search, useParsedQuery, } from '~/common/kits/table';
import { useReviewsInitData } from '~/orders/hooks';
import { ReviewsFilterBar } from './ReviewsFilterBar';
const searchFilters = ['customer', 'company', 'order'];
const placeholderMapper = (option) => {
    switch (option) {
        case 'customer':
            return 'Enter email';
        case 'company':
            return 'Enter company name';
        case 'order':
            return 'Enter ID or title';
    }
};
export const ReviewsHeader = ({ reviewsCount }) => {
    const { filter_options } = useReviewsInitData();
    const { queryParams, onFilterChange, updateFilters } = useParsedQuery();
    const searchFilterData = getSearchFilterDataFromOptions(searchFilters, queryParams.filter);
    const filterProps = getFilterProps(filter_options, queryParams.filter, onFilterChange);
    const appliedFiltersAmount = Object.keys(queryParams.filter).filter((key) => {
        return (!searchFilters.includes(key) && !['from', 'to', 'rating'].includes(key));
    }).length;
    const resetFilters = () => updateFilters(() => ({}));
    return (_jsxs(LayoutHeader, { children: [_jsx("div", { className: "flex flex-col gap-1", children: _jsxs("div", { className: "flex justify-between items-center", children: [_jsxs("div", { className: "flex items-baseline", children: [_jsx(LayoutTitle, { children: "Reviews" }), reviewsCount !== undefined && (_jsx(ListCount, { name: "review", className: "font-brand-b1 text-greyscale-400 ml-2", amount: reviewsCount }))] }), _jsxs("div", { className: "ml-auto flex", children: [_jsx(Search, { className: "mr-2", filters: searchFilters, selectedFilter: searchFilterData.selectedFilter, value: searchFilterData.value, onChange: onFilterChange, updateFilters: updateFilters, placeholderMapper: placeholderMapper }), _jsx(Multiselect, { ...filterProps('rating'), placeholder: "All ratings", className: "w-[240px] mr-2", theme: "dark" }), _jsx(DateRangeFilter, {}), _jsx(Popover, { compensateOffset: 8, className: "space-y-2 p-2 pt-[12px]", placement: "bottom-end", trigger: (props) => (_jsx(FiltersButton, { ...props, appliedFiltersAmount: appliedFiltersAmount })), content: () => (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex items-center justify-between mb-[-4px]", children: [_jsx("h3", { className: "font-brand-t2m text-greyscale-600", children: "Filters" }), appliedFiltersAmount > 0 && (_jsx(Button, { color: "text-secondary", size: "xs", onClick: resetFilters, children: "Reset" }))] }), _jsx(Multiselect, { placeholder: "Customer type", ...filterProps('customer_type') }), _jsx(Multiselect, { placeholder: "PM", ...filterProps('manager') }), _jsx(Multiselect, { placeholder: "Design team", ...filterProps('team') }), _jsx(Multiselect, { placeholder: "Custom portal", ...filterProps('custom_portal') }), _jsx(Multiselect, { placeholder: "Organization", ...filterProps('organisation') })] })) })] })] }) }), _jsx(ReviewsFilterBar, {})] }));
};
