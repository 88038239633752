import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faCircleExclamation, faMessageLines, faSpinnerThird, } from '@fortawesome/pro-regular-svg-icons';
import { IconBox } from '~/common/components';
import { cx } from '~/common/utils';
import { useIsMyMessage, useOrderData } from '~/orders/hooks';
import { Container } from '../components';
import { useAutoReadMessages, useChatScrollFetch, useMessageEvents, useMessages } from './hooks';
import { Message } from './Message';
export const Messages = ({ scrollToBottom, messagesRef: ref }) => {
    const { collaboration } = useOrderData();
    const { fetchNextPage, hasNextPage, isFetched, isFetchingNextPage, messages, isError, isLoading, isSuccess, } = useMessages();
    const isMyMessage = useIsMyMessage();
    useChatScrollFetch({
        ref,
        isFetchingNextPage,
        isFetched,
        isSuccess,
        hasNextPage,
        fetchNextPage,
        scrollToBottom,
    });
    useMessageEvents({ onCreated: scrollToBottom });
    useAutoReadMessages(ref);
    return (_jsxs(Container, { ref: ref, center: !messages, className: cx(messages && 'overflow-y-scroll py-2 space-y-3 min-h-[50%]'), children: [isFetchingNextPage && (_jsx(IconBox, { size: "s", className: "text-greyscale-300 animate-spin mb-2 w-full", icon: faSpinnerThird })), isError ? (
            // TODO there should be better approach that would allow container
            // persistence for ref usage while still being readable
            _jsxs("div", { className: "flex flex-col items-center justify-center space-y-1", children: [_jsx(IconBox, { size: "m", className: "text-greyscale-300", icon: faCircleExclamation }), _jsx("span", { className: "font-brand-b2r text-greyscale-300", children: "Could not load messages" })] })) : isLoading ? (_jsx(IconBox, { size: "l", className: "text-secondary-300 animate-spin", icon: faSpinnerThird })) : !messages ? (_jsxs("div", { className: "flex flex-col items-center justify-center gap-[4px]", children: [_jsx(IconBox, { size: "m", className: "text-greyscale-300", icon: faMessageLines }), _jsx("span", { className: "font-brand-t4m text-greyscale-300", children: "No messages yet" })] })) : (messages.map((message, index) => {
                var _a;
                return (_jsx(Message, { canRemove: isMyMessage(message) && index === messages.length - 1, message: message, 
                    // we're considering all messages newer than last_read_at to be unread
                    isUnread: message.date.getTime() > (((_a = collaboration.last_read_at) === null || _a === void 0 ? void 0 : _a.getTime()) || 0), isMyMessage: isMyMessage(message) }, message.id));
            }))] }));
};
