import { useMemo } from 'react';
import { useInitData, useOrderData } from '.';
const pickCountryFields = ({ country_code, timezone_offset }) => ({
    country_code,
    timezone_offset,
});
const getTeamOffice = (team, init) => {
    var _a, _b;
    if (!team) {
        return null;
    }
    const teamOfficeId = (_a = init.teams.find(({ value }) => value === team.id)) === null || _a === void 0 ? void 0 : _a.office_id;
    if (!teamOfficeId) {
        return null;
    }
    return (_b = init.offices.find((office) => office.id === teamOfficeId)) !== null && _b !== void 0 ? _b : null;
};
export const useSortedOrderCountries = () => {
    const init = useInitData();
    const order = useOrderData();
    return useMemo(() => {
        const teamOffice = getTeamOffice(order.team, init);
        const countries = init.offices
            .filter((office) => office.country_code !== (teamOffice === null || teamOffice === void 0 ? void 0 : teamOffice.country_code))
            .map(pickCountryFields);
        if (order.country) {
            countries.unshift({
                country_code: order.country,
                timezone_offset: order.timezone,
            });
        }
        if (teamOffice) {
            countries.unshift(pickCountryFields(teamOffice));
        }
        return countries;
    }, [init, order.country, order.team, order.timezone]);
};
