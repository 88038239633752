import { jsx as _jsx } from "react/jsx-runtime";
import { CountryFlagLabel } from '~/common/components';
export const tableConfig = {
    columns: [
        { key: 'id', name: 'ID', width: '72px' },
        {
            key: 'name',
            name: 'Name',
        },
        { key: 'description', name: 'Description', getClassName: () => 'whitespace-normal' },
        { key: 'total_members', name: 'Total members', width: '240px' },
        {
            key: 'office',
            name: 'Office',
            cellRenderer: ({ cellData }) => (_jsx(CountryFlagLabel, { country: cellData.country, countryCode: cellData.country_code })),
            width: 'minmax(min-content, 240px)',
        },
    ],
    sortable: ['name', 'description', 'total_members'],
};
