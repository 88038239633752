import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faSpinnerThird, faSquare, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons';
import { Button, IconBox, Tooltip } from '~/common/components';
import { cx, pluralize } from '~/common/utils';
import { useRevisionsContext } from '../revisionsContext';
import { DetailedProgress } from './DetailedProgress';
import css from './Previews.module.scss';
export const EditHeader = ({ isLoading, onCancel, progress }) => {
    const { editing, previewsState, previewsActions } = useRevisionsContext();
    const slidesAmount = previewsState.previews.length;
    const slidesAmountText = `${slidesAmount} slide${pluralize(slidesAmount)}`;
    const allPreviewsSelected = previewsState.previews.length === previewsState.selectedPreviewIds.size;
    return (_jsx("div", { className: css.editHeader, children: isLoading ? (_jsxs(_Fragment, { children: [_jsx(IconBox, { size: "s", className: "mx-1 animate-spin", icon: faSpinnerThird }), _jsx(Tooltip, { content: isLoading && _jsx(DetailedProgress, { ...progress }), children: _jsxs("span", { className: "text-greyscale-100 mr-auto", children: [progress.uploaded, " / ", progress.total, " uploaded"] }) }), _jsx(Button.Icon, { "data-tt": "Cancel upload", color: "grey-dark", size: "m", icon: faTimes, onClick: onCancel })] })) : editing ? (_jsxs(_Fragment, { children: [_jsx(IconBox, { size: "s", onClick: previewsActions.toggleSelectAll, icon: allPreviewsSelected ? faCheckSquare : faSquare, className: cx('mx-1 cursor-pointer', allPreviewsSelected ? 'text-primary-300' : 'text-greyscale-400') }), _jsx(Button, { size: "xs", color: "text-dark", onClick: previewsActions.removeSelectedPreviews, children: "Delete" })] })) : (_jsx("span", { className: "text-greyscale-100 ml-[12px]", children: slidesAmountText })) }));
};
