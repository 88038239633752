import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useHasNextPage, useIdParam } from '~/common/hooks';
import { addQueryParams, httpClient, map } from '~/common/utils';
import { Messages } from '~/orders/domain';
import { qk } from '~/root/query-keys';
export function useMessages() {
    const id = useIdParam();
    const query = useInfiniteQuery({
        queryKey: qk.orderChatMessages(id),
        queryFn: ({ pageParam = 1 }) => {
            return httpClient.get(addQueryParams(`/v1/staff/orders/${id}/comments`, { page: pageParam }), {
                decoder: Messages,
            });
        },
        getNextPageParam: (page) => page.metadata.next,
        getPreviousPageParam: (page) => page.metadata.prev,
    });
    const messages = useMemo(() => {
        if (!query.data) {
            return null;
        }
        // TODO consider storing page messages in arrays instead
        const allPagesCombined = map.combine(query.data.pages.map((page) => page.items)).values();
        const messages = Array.from(allPagesCombined).sort((a, b) => a.date.getTime() - b.date.getTime());
        return messages.length ? messages : null;
    }, [query.data]);
    const hasNextPage = useHasNextPage(query.data);
    return { ...query, messages, hasNextPage };
}
