import { map as mapFpTs, option } from 'fp-ts';
import { pipe } from 'fp-ts/function';
export function toEntries(map) {
    return Array.from(map.entries());
}
export function modifyAt(eq, key, update) {
    return (m) => pipe(m, mapFpTs.modifyAt(eq)(key, update), option.getOrElse(() => m));
}
/** Adds value to map efficiently and immutably */
export const add = (map, key, value) => {
    const next = new Map(map);
    next.set(key, value);
    return next;
};
/** Removes value from map efficiently and immutably */
export const remove = (map, key) => {
    const next = new Map(map);
    next.delete(key);
    return next;
};
/** Toggles a value in map efficiently and immutably */
export const toggle = (map, key, value) => {
    const next = new Map(map);
    if (next.has(key)) {
        next.delete(key);
    }
    else {
        next.set(key, value);
    }
    return next;
};
/** Combines multiple maps efficiently */
export const combine = (args) => {
    const result = new Map();
    args.forEach((map) => {
        map.forEach((value, key) => {
            result.set(key, value);
        });
    });
    return result;
};
/** Filter map efficiently and immutably */
export const filter = (arg, fn) => {
    const result = new Map(arg);
    for (const [key, value] of result.entries()) {
        if (!fn(key, value)) {
            result.delete(key);
        }
    }
    return result;
};
/** Map over map efficiently and immutably */
export const map = (arg, fn) => {
    const result = new Map(arg);
    for (const [key, value] of result.entries()) {
        result.set(key, fn(value));
    }
    return result;
};
