import { captureException } from '@sentry/react';
import { notifyVersionMismatchError, safeDecode } from '~/common/utils';
import { useRootStore } from '~/root';
export function handleEvent(params) {
    return (data) => {
        if (!('decoder' in params)) {
            return params.callback();
        }
        const result = safeDecode({
            value: data,
            decoder: params.decoder,
            getInfo: () => {
                const payload = JSON.stringify(data, null, 2);
                return [`WS Event: ${params.event}`, `Payload: ${payload}`].join('\n');
            },
        });
        if (result.error === null) {
            return params.callback(result.success);
        }
        // we're only going to send WS validation error if it's the latest version
        //
        // since we can't get X-Version header from BE when receiving WS events,
        // we'll check updateRequired, which should be set by any http request
        if (useRootStore.getState().updateRequired) {
            notifyVersionMismatchError();
        }
        else {
            captureException(result.error);
            console.error(result.error);
        }
    };
}
