import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useForm } from 'formoid';
import { number, string } from 'fp-ts';
import { useCallback, useState } from 'react';
import { ModalHeader, ModalShell } from '~/common/components';
import { usePatchedBlur } from '~/common/hooks';
import { capitalize, propagateBackendValidationErrors, record } from '~/common/utils';
import { useCalculateOrderPrice, useUpdateOrder } from '~/orders/hooks';
import { InlineInputField, ManualCharge, Price, Switcher } from '~/orders/Order';
import { discountToString } from '~/orders/utils';
export const EditPriceInfo = (props) => {
    var _a, _b;
    const [currentDiscount, setCurrentDiscount] = useState((_a = props.payment.discount) !== null && _a !== void 0 ? _a : undefined);
    const initialValues = {
        amount: props.payment.amount,
        coupon: (_b = props.payment.coupon) !== null && _b !== void 0 ? _b : '',
        discount: currentDiscount ? discountToString(currentDiscount, props.payment.currency) : '',
        paymentStatus: capitalize(props.payment.status),
        paymentTrigger: props.payment.autocharge,
        unit: props.payment.unit,
        vat: props.payment.vat,
    };
    const { fieldProps, handleReset, handleSubmit, isSubmitting, setErrors, values } = useForm({
        initialValues,
        validationStrategy: 'onBlur',
        validators: () => record.map(initialValues, () => null),
    });
    const updateOrder = useUpdateOrder();
    const submit = () => handleSubmit((values) => {
        const payload = {
            payment: {
                autocharge: values.paymentTrigger,
                coupon: values.coupon === '' ? undefined : values.coupon,
                discount: currentDiscount,
                unit: values.unit,
            },
        };
        return updateOrder(payload).then(props.onClose);
    });
    const { mutateAsync, isLoading } = useCalculateOrderPrice({
        onSuccess: ({ coupon, discount, ...data }) => {
            if (discount) {
                setCurrentDiscount(discount);
            }
            handleReset((values) => ({
                ...values,
                ...data,
                coupon: coupon !== null && coupon !== void 0 ? coupon : '',
                discount: discount ? discountToString(discount, props.payment.currency) : '',
            }));
        },
        onError: (error) => propagateBackendValidationErrors(error, setErrors),
    });
    const calculatePrice = useCallback((discount) => {
        mutateAsync({
            coupon: values.coupon,
            discount: discount !== null && discount !== void 0 ? discount : currentDiscount,
            unit: values.unit,
        });
    }, [mutateAsync, values.coupon, values.unit, currentDiscount]);
    const patchDisabledProp = useCallback(({ disabled, ...props }) => ({ ...props, disabled: disabled || isLoading }), [isLoading]);
    const unitFieldProps = usePatchedBlur({
        blurCallback: calculatePrice,
        eq: number.Eq,
        fieldProps: patchDisabledProp(fieldProps('unit')),
    });
    const couponFieldProps = usePatchedBlur({
        blurCallback: calculatePrice,
        eq: string.Eq,
        fieldProps: patchDisabledProp(fieldProps('coupon')),
    });
    const handleApplyDiscount = (nextDiscount) => {
        setCurrentDiscount(nextDiscount);
        fieldProps('discount').onChange(discountToString(nextDiscount, props.payment.currency));
        calculatePrice(nextDiscount);
    };
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: props.onClose, title: "Price" }), _jsx(ModalShell, { className: "p-3 w-[712px]", disabled: isLoading, disabledHint: "You haven't changed anything", loading: isSubmitting, onClose: props.onClose, onSubmit: submit, children: _jsxs("div", { className: "flex items-start space-x-2", children: [_jsx("div", { className: "space-y-1 w-1/2", children: _jsx(Price, { currentDiscount: currentDiscount, fieldProps: {
                                    amount: patchDisabledProp(fieldProps('amount')),
                                    coupon: couponFieldProps,
                                    discount: patchDisabledProp(fieldProps('discount')),
                                    unit: unitFieldProps,
                                    vat: patchDisabledProp(fieldProps('vat')),
                                }, onApplyDiscount: handleApplyDiscount, payment: props.payment }) }), _jsxs("div", { className: "space-y-1 w-1/2", children: [_jsx(InlineInputField, { ...fieldProps('paymentStatus'), readOnly: true, textAlignment: "right", title: "Payment status", type: "text" }), _jsx(Switcher, { ...fieldProps('paymentTrigger'), title: "7d payment trigger" }), _jsx(ManualCharge, {})] })] }) })] }));
};
