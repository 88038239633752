import { useMutation } from '@tanstack/react-query';
import { useIdParam } from '~/common/hooks';
import { httpClient } from '~/common/utils';
import { Annotation } from '~/orders/domain';
import { useRevisionsContext } from '~/orders/Order';
import { qk } from '~/root/query-keys';
import { useUpdateOrderCache } from '../useUpdateOrderCache';
import { useUpdateAnnotationCache } from './useUpdateAnnotationCache';
export function useUpdateAnnotation() {
    const id = useIdParam();
    const updateAnnotationCache = useUpdateAnnotationCache();
    return useMutation({
        mutationKey: qk.resolveAnnotation(id),
        mutationFn: ({ annotationId, resolved }) => httpClient.patch(`/v1/staff/orders/${id}/annotations/${annotationId}`, {
            data: { resolved },
            decoder: Annotation,
        }),
        onSuccess: (response, params) => updateAnnotationCache(params, () => response),
    });
}
export function useUpdateAnnotationMassResolveRevision(resolved) {
    const id = useIdParam();
    const updateCache = useUpdateOrderCache();
    const { revisionSelect } = useRevisionsContext();
    const revisionId = revisionSelect.value;
    const action = resolved ? 'unresolve' : 'resolve';
    return useMutation({
        mutationKey: qk.massResolveRevision(id),
        mutationFn: () => httpClient.post(`v1/staff/orders/${id}/revisions/${revisionId}/${action}`),
        onSuccess: () => updateCache((order) => ({
            ...order,
            revisions: order.revisions.map((revision) => revision.id === revisionId
                ? {
                    ...revision,
                    slides: revision.slides.map((slide) => ({
                        ...slide,
                        annotations: slide.annotations.map((annotation) => ({
                            ...annotation,
                            resolved: !resolved,
                        })),
                    })),
                }
                : revision),
        })),
    });
}
export function useUpdateAnnotationMassResolveSlide(slideId, resolved) {
    const id = useIdParam();
    const updateCache = useUpdateOrderCache();
    const { revisionSelect } = useRevisionsContext();
    const revisionId = revisionSelect.value;
    const action = resolved ? 'unresolve' : 'resolve';
    return useMutation({
        mutationKey: qk.massResolveSlide(id, slideId),
        mutationFn: () => httpClient.post(`v1/staff/orders/${id}/revisions/${revisionId}/slides/${slideId}/${action}`),
        onSuccess: () => updateCache((order) => ({
            ...order,
            revisions: order.revisions.map((revision) => revision.id === revisionId
                ? {
                    ...revision,
                    slides: revision.slides.map((slide) => slide.id === slideId
                        ? {
                            ...slide,
                            annotations: slide.annotations.map((annotation) => ({
                                ...annotation,
                                resolved: !resolved,
                            })),
                        }
                        : slide),
                }
                : revision),
        })),
    });
}
