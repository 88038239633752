import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot, $isParagraphNode } from 'lexical';
import { useEffect } from 'react';
const PLACEHOLDER_ATTRIBUTE = 'data-lexical-placeholder';
export const PlaceholderPlugin = ({ placeholder }) => {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        if (!placeholder) {
            return;
        }
        return editor.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {
                const editorElement = editor.getRootElement();
                if (editorElement) {
                    editorElement.setAttribute('data-placeholder-text', placeholder);
                }
            });
        });
    }, [editor, placeholder]);
    useEffect(() => {
        return editor.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {
                const editorElement = editor.getRootElement();
                if (!editorElement) {
                    return;
                }
                const root = $getRoot();
                const firstChild = root.getFirstChild();
                const singleChild = root.getChildrenSize() === 1;
                const isEmpty = singleChild && $isParagraphNode(firstChild) && firstChild.isEmpty();
                if (isEmpty) {
                    editorElement.setAttribute(PLACEHOLDER_ATTRIBUTE, 'true');
                }
                else {
                    editorElement.removeAttribute(PLACEHOLDER_ATTRIBUTE);
                }
            });
        });
    }, [editor]);
    return null;
};
