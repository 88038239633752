import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faArrowDownLong, faArrowUpLong } from '@fortawesome/pro-regular-svg-icons';
import { throttle } from 'lodash-es';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from '~/common/components';
import { cx } from '~/common/utils';
const controlsVisibilityConditions = {
    up: (scrollPercentage) => scrollPercentage >= 10,
    down: (scrollPercentage) => scrollPercentage < 100,
};
const isControlsAvailable = (listHeight, listContainerHeight) => listHeight * 0.75 > listContainerHeight;
export const ScrollableList = ({ children, className }) => {
    const listRef = useRef(null);
    const [showUp, setShowUp] = useState(false);
    const [showDown, setShowDown] = useState(false);
    const [listHeight, setListHeight] = useState(0);
    const [listContainerHeight, setListContainerHeight] = useState(0);
    useEffect(() => {
        const ref = listRef.current;
        if (!ref) {
            return;
        }
        const resizeObserver = new ResizeObserver(throttle((entries) => {
            for (const entry of entries) {
                const height = entry.target.offsetHeight;
                setListContainerHeight(height);
            }
        }, 1000));
        resizeObserver.observe(ref);
        return () => {
            resizeObserver.unobserve(ref);
            resizeObserver.disconnect();
        };
    }, []);
    useEffect(() => {
        if (!listRef.current) {
            return;
        }
        const height = listRef.current.scrollHeight;
        setListHeight(height);
    }, [children]);
    const getScrollPercentage = useCallback(() => {
        if (!listRef.current) {
            return;
        }
        const scrollTop = listRef.current.scrollTop;
        const scrollHeight = listRef.current.scrollHeight;
        const clientHeight = listRef.current.clientHeight;
        const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
        return scrollPercentage;
    }, []);
    const getControlsVisibility = useCallback((listHeight, listContainerHeight) => {
        if (!isControlsAvailable(listHeight, listContainerHeight)) {
            setShowUp(false);
            setShowDown(false);
            return;
        }
        const scrollPercentage = getScrollPercentage();
        if (scrollPercentage === undefined) {
            return;
        }
        setShowUp(controlsVisibilityConditions.up(scrollPercentage));
        setShowDown(controlsVisibilityConditions.down(scrollPercentage));
    }, [getScrollPercentage]);
    useEffect(() => {
        getControlsVisibility(listHeight, listContainerHeight);
    }, [listHeight, listContainerHeight, getControlsVisibility]);
    const handleScroll = () => {
        getControlsVisibility(listHeight, listContainerHeight);
    };
    const scrollToBottom = () => {
        if (!listRef.current) {
            return;
        }
        listRef.current.scrollTo({ top: listRef.current.scrollHeight, behavior: 'smooth' });
    };
    const scrollToTop = () => {
        if (!listRef.current) {
            return;
        }
        listRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (_jsxs("div", { ref: listRef, onScroll: handleScroll, className: cx('overflow-y-auto h-full', className), children: [children, _jsxs("div", { className: "absolute bottom-[4px] right-[4px] flex flex-col gap-y-1", children: [_jsx(Button.Icon, { icon: faArrowUpLong, color: "secondary", size: "m", iconSize: "s", className: cx('rounded-full bg-greyscale-650', !showUp && 'invisible'), onClick: scrollToTop }), _jsx(Button.Icon, { icon: faArrowDownLong, color: "secondary", size: "m", iconSize: "s", className: cx('rounded-full bg-greyscale-650', !showDown && 'invisible'), onClick: scrollToBottom })] })] }));
};
