import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SelectFactory } from '~/common/components';
export const BorderlessSelect = ({ value, onChange, onBlur, options, className, ...props }) => {
    // this is needed to prevent annoying input blurring and re-focusing
    const handleMouseDown = (e) => e.preventDefault();
    const Input = ({ ref, children, ...inputProps }) => (_jsxs("label", { ref: ref, className: "relative flex font-brand-t1m text-greyscale-500", children: [_jsx("span", { className: "opacity-0 h-3 min-w-[48px]", children: inputProps.value || '...' }), _jsx("input", { className: "absolute top-0 left-0 right-0 bottom-0 h-3 w-full font-brand-t1 bg-transparent cursor-pointer select-none", ...props, ...inputProps }), _jsx("div", { className: "flex items-center justify-center text-[20px] ml-1", onMouseDown: handleMouseDown, children: children })] }));
    return (
    // this div is needed to prevent tailwind space utilities from margin-moving dropdown
    _jsx("div", { className: className, children: _jsx(SelectFactory, { value: value, onChange: onChange, onBlur: onBlur, options: options, inputField: Input, placement: "bottom-end", containerClassName: "w-25 max-h-[320px]", noSearch: true }) }));
};
