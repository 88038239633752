import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faArrowFromBottom } from '@fortawesome/pro-regular-svg-icons';
import { FiltersButton, LayoutHeader, LayoutTitle, Link, ListCount, Popover, SearchField, } from '~/common/components';
import { Filter, getFilterProps, getFlatQuery, stringify, useParsedQuery, } from '~/common/kits/table';
import { addQueryParams } from '~/common/utils';
import { SERVER_URL } from '~/env';
export const Header = ({ tableFilters, subscriptionsCount }) => {
    // const init = useInitData();
    const { queryParams, onFilterChange } = useParsedQuery();
    // const { modalOpener } = useModalContext();
    const filterProps = getFilterProps({ ...tableFilters, plan: tableFilters.plan.all }, queryParams.filter, onFilterChange);
    const appliedFiltersAmount = Object.keys(queryParams.filter).filter((key) => key !== 'search').length;
    return (_jsxs(LayoutHeader, { className: "flex justify-between items-center", children: [_jsxs("div", { className: "flex items-baseline", children: [_jsx(LayoutTitle, { children: "Subscriptions" }), _jsx(ListCount, { name: "subscription", className: "font-brand-b1 text-greyscale-400 ml-2", amount: subscriptionsCount })] }), _jsxs("div", { className: "flex", children: [_jsx(SearchField, { className: "w-[272px]", placeholder: "Search", value: stringify(queryParams.filter.search), onChange: (value) => onFilterChange('search', value) }), _jsx(Popover, { compensateOffset: 4, className: "p-2 pt-[12px] w-[275px]", placement: "bottom-end", trigger: (props) => (_jsx(FiltersButton, { ...props, appliedFiltersAmount: appliedFiltersAmount })), content: () => (_jsxs(_Fragment, { children: [_jsx("h3", { className: "font-brand-t2m text-greyscale-600 mb-[12px]", children: "Filters" }), _jsxs("div", { className: "space-y-2", children: [_jsx(Filter, { ...filterProps('pm'), placeholder: "All PM", theme: "light" }), _jsx(Filter, { ...filterProps('sales'), placeholder: "All CSM", theme: "light" }), _jsx(Filter, { ...filterProps('plan'), placeholder: "All products", theme: "light" }), _jsx(Filter, { ...filterProps('status'), placeholder: "All statuses", theme: "light" })] })] })) }), _jsx(DownloadCSVButton, {})] })] }));
};
const DownloadCSVButton = () => {
    const { queryParams } = useParsedQuery();
    const params = getFlatQuery(queryParams);
    const href = addQueryParams(`${SERVER_URL}/v1/staff/subscriptions/export`, params);
    return (_jsx(Link.Icon, { color: "grey-outline", className: "ml-2", size: "m", iconSize: "s", icon: faArrowFromBottom, href: href }));
};
