import { z } from 'zod';
import { unsafeRefine } from './common';
export const isNonEmptyString = (s) => s !== '';
export const nonEmptyString = unsafeRefine(isNonEmptyString);
export const NonEmptyString = z.string().refine(isNonEmptyString);
export const isNonBlankString = (s) => s.trim() !== '';
export const nonBlankString = unsafeRefine(isNonBlankString);
export const NonBlankString = z.string().refine(isNonBlankString);
/**
 * Email
 */
// RFC 2822 standard email validation
export const EMAIL_REG_EXP = new RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
export const isEmail = (s) => {
    // for some reason with this pretty extensive regexp two @ pass :shrug:
    const hasSingleAt = s.replace(/[^@]/g, '').length === 1;
    return EMAIL_REG_EXP.test(s.toLowerCase()) && hasSingleAt;
};
const WILDCARD_REG_EXP = new RegExp(/^[a-z0-9\-_.*]+$/);
export const isWildcardEmail = (s) => {
    return WILDCARD_REG_EXP.test(s);
};
export const email = unsafeRefine(isEmail);
export const Email = z.string().refine(isEmail);
