import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { faCreditCard } from '@fortawesome/pro-regular-svg-icons';
import { invoice } from '~/assets/images';
import { Button, PageDetailsCard, useModalContext } from '~/common/components';
import { cardBrandToImg, joinTruthy } from '~/common/utils';
import { useCustomerData, useJoinedCustomerData } from '~/customers/hooks';
import { useCurrentUserData } from '~/root';
import { Empty } from '../Empty';
import { AddInvoicePaymentMethod } from './AddInvoicePaymentMethod';
import { EditPaymentMethods } from './EditPaymentMethods';
export const PaymentMethods = () => {
    const customer = useCustomerData();
    const { permissions } = useCurrentUserData();
    const { modalOpener } = useModalContext();
    return (_jsx(PageDetailsCard, { label: "Payment methods", actionButton: permissions.customersUpdate &&
            (customer.payment_methods.length ? (_jsx(Button, { onClick: modalOpener(EditPaymentMethods), color: "grey", children: "Edit" })) : (_jsx(AddInvoicePaymentModal, {}))), children: customer.payment_methods.length ? (_jsxs(_Fragment, { children: [customer.payment_methods.map(({ id, ...paymentMethod }) => 'brand' in paymentMethod ? (_jsx(CardPaymentMethod, { ...paymentMethod }, id)) : (_jsx(InvoicePaymentMethod, { email: customer.billing_settings.email, ...paymentMethod }, id))), !customer.invoicePaymentMethod && _jsx(AddInvoicePaymentModal, { className: "mt-3" })] })) : (_jsx(Empty, { icon: faCreditCard, title: "No payment methods yet" })) }));
};
const CardPaymentMethod = ({ last4, brand, expires, default: isDefault, }) => (_jsxs("div", { className: "font-brand-b2r flex items-center py-1", children: [_jsx("img", { className: "mr-2 w-[36px] h-3", src: cardBrandToImg(brand), alt: brand }), _jsxs("span", { className: "mr-3 whitespace-nowrap", children: ["**** **** **** ", last4] }), _jsx("span", { children: expires }), isDefault && _jsx("span", { className: "ml-auto font-brand-b1 text-success-400", children: "Default" })] }));
const InvoicePaymentMethod = ({ company, city, address, zip_code, email, currency, default: isDefault, }) => {
    const { invoiceCountry } = useJoinedCustomerData();
    return (_jsxs("div", { className: "font-brand-b2r flex py-1", children: [_jsx("img", { className: "mr-2 w-[36px] h-3", src: invoice, alt: "Invoice payment method" }), _jsxs("div", { className: "inline", children: [_jsxs("h4", { children: [company, " ", currency && `(${currency})`] }), _jsx("p", { className: "text-greyscale-400", children: joinTruthy([address, city, zip_code, invoiceCountry === null || invoiceCountry === void 0 ? void 0 : invoiceCountry.name], ', ') }), email && _jsx("span", { className: "text-greyscale-400", children: email })] }), isDefault && _jsx("span", { className: "ml-auto font-brand-b1 text-success-400", children: "Default" })] }));
};
const AddInvoicePaymentModal = ({ className }) => {
    const { modalOpener } = useModalContext();
    return (_jsx(Button, { onClick: modalOpener(AddInvoicePaymentMethod), color: "grey", className: className, children: "+ Add invoice method" }));
};
