import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faPaintBrushAlt } from '@fortawesome/pro-solid-svg-icons';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useVisited } from '~/root';
import { TEAMS_URL } from './hooks';
import { Team } from './Team';
import { TeamsList } from './TeamsList';
const Teams = () => {
    const { path } = useRouteMatch();
    useVisited('teams');
    return (_jsxs(Switch, { children: [_jsx(Route, { exact: true, path: path, component: TeamsList }), _jsx(Route, { path: `${path}/:id(\\d+)`, component: Team })] }));
};
const nav = {
    icon: faPaintBrushAlt,
    label: 'Teams',
    path: TEAMS_URL,
};
export const teamsModule = {
    canShow: (permissions) => permissions.designTeamsView,
    route: {
        path: TEAMS_URL,
        component: Teams,
    },
    nav,
};
