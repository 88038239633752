import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { isToday } from 'date-fns';
import { record } from 'fp-ts';
import { useMemo, useRef } from 'react';
import { ModalHeader, ModalShell, useModalContext } from '~/common/components';
import { Summary, getDefaultCreateSubscriptionInitialValues, getProductInitialValues, useCreateSubscriptionForm, useSubscriptionForm, } from '~/customers/Customer';
import { AdvancedInvoiceOptions } from './AdvancedInvoiceOptions';
import { PaymentMethod } from './PaymentMethod';
import { SubscriptionProducts } from './SubscriptionProducts';
import { SubscriptionSchedule } from './SubscriptionSchedule';
import { SuccessTeam } from './SuccessTeam';
export const ActivateSubscription = ({ useCreateSubscriptionData, initialValues = getDefaultCreateSubscriptionInitialValues(['retainer', 'techpack']), initialProducts, onClose, }) => {
    const { modalOpener } = useModalContext();
    const { ownerId, productsList, supportedCurrencies, sales, projectManagers, designTeams, isCustomerTrusted, invoicePaymentMethod, paymentMethods, renewalPeriodOptions, haveIndividualPricing, } = useCreateSubscriptionData();
    const productsTypes = useMemo(() => {
        const { all, ...types } = productsList;
        return record.keys(types);
    }, [productsList]);
    const fullInitialValues = useRef({
        ...productsTypes.reduce((initialValues, productType) => ({
            ...initialValues,
            ...getProductInitialValues(productType),
        }), {}),
        ...initialValues,
    });
    const { form, products } = useSubscriptionForm({
        initialValues: fullInitialValues.current,
        initialProducts,
        productsList: productsTypes,
    });
    const { invoicePreviewData, isInvoicePaymentMethodSelected, billingDate, productsMap, isLoading, price, submit, addProduct, onPlanChange, removeProduct, onPaymentMethodChange, onPriceRelatedFieldBlur, onPriceRelatedFieldChange, resetCreateSubscriptionForm, } = useCreateSubscriptionForm({
        form,
        ownerId,
        products,
        productsList,
        invoicePaymentMethod,
    });
    const onModalClose = () => {
        onClose();
        resetCreateSubscriptionForm();
    };
    const submitText = form.values.is_trial
        ? 'Schedule trial'
        : form.values.activate_at !== null && !isToday(form.values.activate_at)
            ? 'Schedule activation'
            : 'Activate now';
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onModalClose, title: "Create subscription" }), _jsxs(ModalShell, { className: "px-5 py-4 space-y-5 w-[1010px]", onClose: onModalClose, onSubmit: () => submit(onClose), loading: isLoading, submitText: submitText, children: [_jsx(SubscriptionProducts, { form: form, showIndividualPricingBanner: haveIndividualPricing && form.values.retainer_plan !== null, removeProduct: removeProduct, addProduct: addProduct, productsMap: productsMap, supportedCurrencies: supportedCurrencies, renewalPeriodOptions: renewalPeriodOptions, projectManagers: projectManagers, designTeams: designTeams, onPlanChange: onPlanChange, onPriceRelatedFieldBlur: onPriceRelatedFieldBlur, onPriceRelatedFieldChange: onPriceRelatedFieldChange }), _jsx(SubscriptionSchedule, { form: form }), _jsx(PaymentMethod, { form: form, paymentMethods: paymentMethods, isCustomerTrusted: isCustomerTrusted, onPaymentMethodChange: onPaymentMethodChange, onInvoiceMethodModalClose: modalOpener(ActivateSubscription, {
                            useCreateSubscriptionData,
                            initialValues: form.values,
                            initialProducts: products.list,
                        }) }), _jsx(SuccessTeam, { form: form, sales: sales }), isInvoicePaymentMethodSelected && _jsx(AdvancedInvoiceOptions, { form: form }), _jsx(Summary, { price: price, ownerId: ownerId, billing_date: billingDate, invoicePreviewData: invoicePreviewData })] })] }));
};
