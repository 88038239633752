import { hasCalendlyUrl, hasTrelloId, isRoleDesigner } from '../domain';
export const getPermissionSections = (initPermissions, userPermissions) => {
    const map = {};
    for (const permission of initPermissions) {
        if (!map[permission.category])
            map[permission.category] = [];
        map[permission.category].push({
            name: permission.name,
            label: permission.label,
            active: userPermissions.includes(permission.name),
        });
    }
    const columns = [
        [
            'orders',
            'rms',
            'shift_planning',
            'inner_users',
            'organisations',
            'user_permissions',
            'holidays',
        ],
        [
            'design_teams',
            'offices',
            'customers',
            'subscriptions',
            'customers_billing',
            'settings',
            'dev_tools',
        ],
    ];
    return columns.map((col) => col.map((name) => ({ name: name.replace('_', ' '), permissions: map[name] })));
};
export const composePayload = (values) => {
    const { name, role, job_title, email, office } = values;
    const patchedValues = {
        name,
        role,
        job_title,
        email,
        office,
    };
    if (isRoleDesigner(values.role)) {
        patchedValues.capacity = values.capacity;
        patchedValues.team = values.team;
    }
    if (hasTrelloId(values.role) && values.trello_id) {
        patchedValues.trello_id = values.trello_id;
    }
    if (hasCalendlyUrl(values.role) && values.calendly_url) {
        patchedValues.calendly_url = values.calendly_url;
    }
    return patchedValues;
};
