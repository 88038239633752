import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListNode, ListItemNode } from '@lexical/list';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { useEffect } from 'react';
import { cx } from '~/common/utils';
import { ChangeHotkeysPlugin } from './ChangeHotkeysPlugin';
import { EditLinkPlugin } from './EditLinkPlugin';
import { LinksNewTabPlugin } from './LinksNewTabPlugin';
import { MentionNode } from './MentionNode';
import { MentionsPlugin } from './MentionsPlugin';
import { PlaceholderPlugin } from './PlaceholderPlugin';
import { theme } from './theme';
import css from './Editor.module.scss';
const URL_MATCHER = /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
const MATCHERS = [
    (text) => {
        const match = URL_MATCHER.exec(text);
        if (match === null) {
            return null;
        }
        const fullMatch = match[0];
        return {
            index: match.index,
            length: fullMatch.length,
            text: fullMatch,
            url: fullMatch.startsWith('http') ? fullMatch : `https://${fullMatch}`,
            // attributes: { rel: 'noreferrer', target: '_blank' }, // Optional link attributes
        };
    },
];
const validateUrl = (url) => URL_MATCHER.test(url);
const initialConfig = {
    namespace: 'MyEditor',
    onError: console.error,
    theme,
    nodes: [
        AutoLinkNode,
        CodeNode,
        HeadingNode,
        HorizontalRuleNode,
        LinkNode,
        ListItemNode,
        ListNode,
        QuoteNode,
        MentionNode,
    ],
};
const LoadInitialStatePlugin = ({ initialState, }) => {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        var _a, _b;
        if (!initialState) {
            return;
        }
        // skips setting state with empty children since we could have those broken
        // messages and checklist items in the db, crashing the editor
        if (typeof initialState !== 'string' && !((_b = (_a = initialState === null || initialState === void 0 ? void 0 : initialState.root) === null || _a === void 0 ? void 0 : _a.children) === null || _b === void 0 ? void 0 : _b.length)) {
            return;
        }
        const state = editor.parseEditorState(initialState);
        editor.setEditorState(state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};
export const LexicalEditor = ({ initialState, onChange, editable = false, className, children, placeholder, mentions, mentionsLookupHook, }) => (_jsxs(LexicalComposer, { initialConfig: { ...initialConfig, editable }, children: [_jsx(RichTextPlugin, { contentEditable: 
            // it just renders children, who cares
            // https://github.com/facebook/lexical/blob/e3615cd48dd16fc05e6b878cf9d381b9362c6784/packages/lexical-react/src/LexicalRichTextPlugin.tsx#L35
            (children || (_jsx(LexicalEditorField, { className: cx('focus:outline-none', className) }))), placeholder: null, ErrorBoundary: LexicalErrorBoundary }), _jsx(LinksNewTabPlugin, {}), mentions && _jsx(MentionsPlugin, { mentionsLookupHook: mentionsLookupHook }), editable && _jsx(ChangeHotkeysPlugin, {}), editable && _jsx(AutoFocusPlugin, {}), editable && _jsx(AutoLinkPlugin, { matchers: MATCHERS }), editable && _jsx(LinkPlugin, { validateUrl: validateUrl }), editable && _jsx(EditLinkPlugin, {}), editable && _jsx(CheckListPlugin, {}), editable && _jsx(ListPlugin, {}), editable && _jsx(HistoryPlugin, {}), editable && _jsx(MarkdownShortcutPlugin, {}), editable && onChange && _jsx(OnChangePlugin, { onChange: onChange }), editable && placeholder && _jsx(PlaceholderPlugin, { placeholder: placeholder }), _jsx(LoadInitialStatePlugin, { initialState: initialState })] }));
export const LexicalEditorField = (props) => (_jsx(ContentEditable, { ...props, className: cx(css.editor, props.className) }));
