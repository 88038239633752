import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faSlack, faTrello } from '@fortawesome/free-brands-svg-icons';
import { faBuilding, faCommentAltLines, faUserFriends } from '@fortawesome/pro-regular-svg-icons';
import { useForm, validator } from 'formoid';
import { FileUploadField, InputField, ModalHeader, ModalShell, SelectField, } from '~/common/components';
import { useFileUploadState } from '~/common/hooks';
import { notifyBackendError, propagateBackendValidationErrors, requiredMessage, resizeImage, } from '~/common/utils';
import { useInitData, useTeamData, useTeamMutation } from '../hooks';
const MAX_SIZE = 600; // px
export const EditTeam = ({ onClose }) => {
    var _a, _b, _c, _d, _e, _f;
    const team = useTeamData();
    const init = useInitData();
    const { mutateAsync } = useTeamMutation();
    const initialValues = {
        name: team.name,
        description: team.description,
        office_id: team.office.id,
        slack_channel: (_a = team.slack_channel) !== null && _a !== void 0 ? _a : '',
        trello_board_id: (_b = team.trello_board_id) !== null && _b !== void 0 ? _b : '',
        team_photo_id: ((_d = (_c = team.avatar) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : null),
    };
    const { fieldProps, handleSubmit, isSubmitting, setErrors } = useForm({
        initialValues,
        validationStrategy: 'onBlur',
        validators: () => ({
            name: validator.minLength(1, requiredMessage('Name')),
            description: validator.minLength(1, requiredMessage('Description')),
            slack_channel: validator.minLength(1, requiredMessage('Slack channel')),
            trello_board_id: validator.minLength(1, requiredMessage('Trello board ID')),
            office_id: null,
            team_photo_id: validator.defined('Team photo is required.'),
        }),
    });
    const uploadFieldProps = useFileUploadState({
        tag: 'team_photo',
        props: fieldProps('team_photo_id'),
        onError: (errors) => setErrors('team_photo_id', errors),
        existingFilename: (_f = (_e = team.avatar) === null || _e === void 0 ? void 0 : _e.name) !== null && _f !== void 0 ? _f : null,
        preprocess: (file) => resizeImage(file, { max: MAX_SIZE }),
    });
    const submit = () => handleSubmit((values) => {
        var _a;
        const { team_photo_id, ...patchedValues } = values;
        if (((_a = team.avatar) === null || _a === void 0 ? void 0 : _a.id) !== team_photo_id) {
            patchedValues.team_photo_id = team_photo_id;
        }
        return mutateAsync(patchedValues)
            .then(onClose)
            .catch((error) => propagateBackendValidationErrors(error, setErrors))
            .catch(notifyBackendError);
    });
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Edit design team" }), _jsxs(ModalShell, { className: "p-3 pb-5 space-y-3 w-[712px]", loading: isSubmitting, onClose: onClose, onSubmit: submit, children: [_jsx(InputField, { ...fieldProps('name'), icon: faUserFriends, title: "Name", placeholder: "Enter a team name", type: "text" }), _jsx(InputField, { ...fieldProps('description'), icon: faCommentAltLines, title: "Description", placeholder: "Enter a description", type: "text" }), _jsx(SelectField, { ...fieldProps('office_id'), icon: faBuilding, title: "Office", placeholder: "Choose the office", options: init.offices }), _jsx(InputField, { ...fieldProps('slack_channel'), icon: faSlack, title: "Slack channel", placeholder: "Enter a slack channel", type: "text" }), _jsx(InputField, { ...fieldProps('trello_board_id'), icon: faTrello, title: "Trello board ID", placeholder: "Enter trello board ID", type: "text" }), _jsx(FileUploadField, { ...uploadFieldProps, title: "Team photo", hintLeft: "Supports: PNG, JPG", hintRight: "Will be resized to be 600px/600px max", accept: ".png, .jpg, .jpeg" })] })] }));
};
