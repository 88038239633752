import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { Fragment, memo } from 'react';
import { Chart } from 'react-chartjs-2';
import { cx } from '~/common/utils';
import { IconBox } from '../../IconContainers';
const StatsText = ({ heading, value, description, hint }) => (_jsxs("div", { children: [_jsxs("div", { className: "flex items-center gap-[6px] font-brand-t4m text-greyscale-500 mb-[12px] whitespace-nowrap", children: [_jsx("span", { children: heading }), hint && _jsx(IconBox, { icon: faCircleInfo, className: "text-greyscale-500", "data-tt": hint })] }), _jsx("div", { className: "text-2xl leading-[24px] font-medium mb-1 whitespace-nowrap", children: value }), _jsx("div", { className: "font-brand-c1r text-greyscale-400 whitespace-nowrap", children: description })] }));
const StatsTrendGraph = memo(({ data, color }) => {
    const chartProps = {
        type: 'line',
        options: {
            animation: false,
            maintainAspectRatio: false,
            layout: {
                padding: {
                    // fixes choppy lines
                    top: 1,
                    bottom: 1,
                },
            },
            plugins: {
                tooltip: {
                    enabled: false,
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                x: {
                    display: false,
                },
                y: {
                    display: false,
                },
            },
        },
        data: {
            labels: data,
            datasets: [
                {
                    data,
                    borderColor: color,
                    pointRadius: 0,
                    borderWidth: 2,
                },
            ],
        },
    };
    return (
    // grid prevents chart from growing past container size
    _jsx("div", { className: "grid grid-cols-[minmax(0,1fr)] h-[36px] w-full mb-[4px]", children: _jsx(Chart, { ...chartProps }) }));
});
const StatsBlockItem = ({ generalProps, graphProps, action }) => {
    return (_jsxs(_Fragment, { children: [_jsx(StatsText, { ...generalProps }), (graphProps || action) && (_jsxs("div", { className: "flex flex-col grow items-end justify-between", children: [action || _jsx("div", {}), graphProps && _jsx(StatsTrendGraph, { ...graphProps })] }))] }));
};
export const StatsBlock = ({ children, className }) => {
    return (_jsx("div", { className: cx('rounded bg-greyscale-0 grid grid-cols-[1fr_10fr] gap-x-8 gap-y-2 px-3 py-2', className), children: children.map((child, i) => {
            return (_jsxs(Fragment, { children: [child, _jsx("div", { className: "h-[1px] col-span-full -mx-3 last:hidden bg-greyscale-200" })] }, i));
        }) }));
};
export const StatsCard = (props) => {
    return (_jsx("div", { className: cx(props.className, 'rounded bg-greyscale-0 grid grid-cols-[auto_1fr] def:gap-x-8 gap-y-2 px-3 py-2'), children: _jsx(StatsBlockItem, { ...props }) }));
};
StatsBlock.Item = StatsBlockItem;
