import { map, nonEmptyArray, option, set } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { useCallback, useEffect, useState } from 'react';
import { useDebounce, useIdParam } from '~/common/hooks';
import { eqPositiveInteger, httpClient, ordPositiveInteger } from '~/common/utils';
import { useUpdateMessagesCache } from './useUpdateMessagesCache';
function useReadMessages() {
    const id = useIdParam();
    const updateCache = useUpdateMessagesCache();
    return useCallback(async (msgs) => {
        await httpClient.post(`/v1/staff/orders/${id}/comments/read`, {
            data: { comments_ids: msgs },
        });
        return updateCache((messages) => ({
            ...messages,
            pages: messages.pages.map((page) => ({
                ...page,
                items: pipe(page.items, map.filterMap((message) => option.some(msgs.includes(message.id) ? { ...message, is_read: true } : message))),
            })),
        }));
    }, [id, updateCache]);
}
export function useReadMessage() {
    const [readMessages, setReadMessages] = useState(new Set());
    const debouncedResult = useDebounce(readMessages, 1000);
    const read = useReadMessages();
    useEffect(() => {
        const result = pipe(debouncedResult, set.toArray(ordPositiveInteger), nonEmptyArray.fromArray, option.toNullable);
        if (result) {
            read(result).then(() => setReadMessages(new Set()));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedResult]);
    return useCallback((id) => {
        setReadMessages(set.insert(eqPositiveInteger)(id));
    }, []);
}
