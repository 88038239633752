import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LayoutContainer, LayoutContent } from '~/common/components';
import { DefaultActivityTable } from '~/common/kits/activity';
import { useOrganizationActivity } from '../../hooks';
import { Header } from './Header';
export const Activity = () => {
    const { data: activity, isFetching } = useOrganizationActivity();
    if (!activity) {
        return _jsx(LayoutContent, { loading: true });
    }
    return (_jsxs(LayoutContainer, { className: "p-0 flex flex-col", children: [_jsx(Header, {}), _jsx(DefaultActivityTable, { isFetching: isFetching, data: activity })] }));
};
