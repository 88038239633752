import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { Button, UsersGroup } from '~/common/components';
import { cx } from '~/common/utils';
import { useOrderFollowers, useOrderFollowing } from '~/orders/hooks';
export const FollowOrder = () => {
    const { followers, canUnfollow, isFollowing } = useOrderFollowers();
    const { mutateAsync: updateOrderFollowingStatus, isLoading } = useOrderFollowing();
    return (_jsxs(_Fragment, { children: [!!followers.length && (_jsxs("div", { className: "flex flex-center", children: [_jsx("span", { className: "mr-1 font-brand-c1m text-greyscale-500", children: "Followers" }), _jsx(UsersGroup, { maxVisible: 3, users: followers })] })), _jsx(Button.Icon, { "data-tt": cx(!isFollowing
                    ? 'Follow the order to receive notifications'
                    : canUnfollow
                        ? 'Unfollow'
                        : 'Seems you are too important to unfollow the order'), "data-tt-class": "max-w-[200px]", "data-tt-placement": "right", onClick: () => {
                    updateOrderFollowingStatus(isFollowing ? 'unfollow' : 'follow');
                }, color: "grey-outline", icon: isFollowing ? faEyeSlash : faEye, loading: isLoading, disabled: !canUnfollow, size: "m", iconSize: "s" })] }));
};
