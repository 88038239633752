import { jsx as _jsx } from "react/jsx-runtime";
import { faPlus, faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import { Button, IconBox, useModalContext } from '~/common/components';
import { useCustomer, useInit } from '~/customers/hooks';
import { useCountries } from '~/root';
import { AddInvoicePaymentMethod } from '../../../PaymentMethods';
const LoadableAddInvoicePaymentMethod = (props) => {
    const init = useInit();
    const customer = useCustomer();
    const countries = useCountries();
    if (!init.data || !customer.data || !countries.data) {
        return (
        // TODO consider extracting and re-using these loaders :shrug:
        _jsx("div", { className: "flex items-center justify-center h-[706px] w-[624px]", children: _jsx(IconBox, { className: "h-9 w-9 text-6xl text-secondary-300 animate-spin", icon: faSpinnerThird }) }));
    }
    return _jsx(AddInvoicePaymentMethod, { ...props });
};
export const AddInvoicePaymentMethodButton = ({ onInvoiceMethodModalClose, }) => {
    const { modalOpener } = useModalContext();
    return (_jsx(Button, { color: "grey", icon: faPlus, onClick: modalOpener(LoadableAddInvoicePaymentMethod, {
            onClose: onInvoiceMethodModalClose,
        }), children: "Add invoice payment method" }));
};
