import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import React from 'react';
import { Avatar, Badge, IconBox, Link, Tooltip } from '~/common/components';
import { UserCard, useAddUserCardId, useUserCards } from '~/common/kits/usercard';
import { formatDateDistance, getColorsFromNumber, getInitials } from '~/common/utils';
import { CUSTOMERS_URL } from '~/customers/hooks';
export const UsersGroup = ({ customer }) => {
    useAddUserCardId(...customer.members.map((member) => member.id));
    if (customer.members.length <= 1) {
        return (_jsx(UserCard, { id: customer.id, name: customer.name, avatar_link: customer.avatar_link, type: "customer", owner_id: customer.owner_id }));
    }
    const avatar = (_jsx(Avatar, { colorSet: getColorsFromNumber(customer.id), url: customer.avatar_link || undefined, className: "relative", children: getInitials(customer.name) }));
    return (_jsx(Tooltip, { className: "w-fit p-0", content: _jsx(MembersCardContent, { members: customer.members }), placement: "bottom", clickable: true, children: _jsxs("div", { className: "relative flex cursor-default", children: [customer.owner_id ? (_jsx(Link.Base, { to: `${CUSTOMERS_URL}/${customer.owner_id}`, children: avatar })) : (avatar), _jsxs("span", { className: "rounded-full bg-greyscale-50 border-solid border border-greyscale-300 text-greyscale-500 h-4 w-4 font-brand-c1m grid place-items-center ml-[-10px]", children: ["+", customer.members.length - 1] })] }) }));
};
const MembersCardContent = ({ members }) => {
    const cards = useUserCards(members.map((member) => member.id));
    if (cards.isLoading) {
        return (_jsx("div", { className: "flex items-center justify-center w-[360px] h-10", children: _jsx(IconBox, { size: "l", icon: faSpinnerThird, className: "h-fit w-fit animate-spin" }) }));
    }
    if (!cards.data) {
        return (_jsx("div", { className: "px-[12px] py-1", children: _jsx("span", { children: "Sorry, we can't show it to you \uD83D\uDE14" }) }));
    }
    const memberCards = members.map((member) => ({
        ...cards.data.find((card) => (card === null || card === void 0 ? void 0 : card.id) === member.id),
        ...member,
    }));
    return (_jsx("div", { className: "flex flex-col overflow-y-auto max-h-[384px] w-[360px]", children: memberCards.map((card) => {
            return (_jsxs(React.Fragment, { children: [_jsxs("div", { className: "flex justify-between items-center text-greyscale-500 px-[12px] py-1 gap-1 w-full", children: [_jsxs("div", { className: "flex h-[38px] items-center gap-1 min-w-0", children: [_jsx(Avatar, { colorSet: getColorsFromNumber(card.id), children: getInitials(card.name) }), _jsxs("div", { className: "min-w-0", children: [_jsx("p", { className: "font-brand-t5 text-greyscale-600 truncate", children: card.name }), 'last_active_at' in card && card.last_active_at && (_jsxs("p", { className: "font-brand-c1r truncate", children: ["Last active ", formatDateDistance(card.last_active_at, new Date())] }))] })] }), _jsxs("div", { className: "text-right", children: [_jsx(Badge, { color: "grey", className: "capitalize mb-[2px] w-fit ml-auto", size: "small", children: card.role }), _jsxs("span", { className: "font-brand-c1r whitespace-nowrap", children: ["can ", card.permission] })] })] }), _jsx("hr", { className: "text-greyscale-200 last-of-type:hidden" })] }, card.id));
        }) }));
};
