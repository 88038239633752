import { useMutation } from '@tanstack/react-query';
import { useIdParam } from '~/common/hooks';
import { httpClient } from '~/common/utils';
export const useBriefDetailsMutation = () => {
    const id = useIdParam();
    return useMutation({
        mutationFn: (data) => httpClient.post(`v1/staff/orders/${id}/brief-questions`, {
            data,
        }),
    });
};
