import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faExclamationTriangle, faFileCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { memo, useEffect, useRef, useState } from 'react';
import { CircularProgress, IconBox, Button } from '~/common/components';
import { cx } from '~/common/utils';
import { useDeleteRemoteFile, useFileUploadProgress } from '~/orders/hooks';
const LOADING_PROGRESS_ANIMATION_MS = 500;
const DELAY_AFTER_LOADING_ANIMATION_FINISH = 250;
export const File = memo(({ file, isHidden, onRemove, onUpload }) => {
    const animationTimeoutId = useRef(null);
    const [deleteRemoteFile, isFileDeleting] = useDeleteRemoteFile();
    const fileUpload = useFileUploadProgress({
        file,
        tag: 'order_comment',
        onSuccess: (uploadedFile) => {
            setUploadedFileId(uploadedFile.id);
            onUpload(uploadedFile.id);
        },
    });
    const [uploadedFileId, setUploadedFileId] = useState();
    // State for displaying full loading animation
    const [loadingInProgress, setLoadingInProgress] = useState(true);
    useEffect(() => {
        setLoadingInProgress(true);
        fileUpload.mutate();
        return () => {
            animationTimeoutId.current && clearTimeout(animationTimeoutId.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileUpload.mutate]);
    // This effect allows to display full loading animation
    useEffect(() => {
        if (fileUpload.isSuccess) {
            animationTimeoutId.current = setTimeout(() => {
                setLoadingInProgress(() => {
                    animationTimeoutId.current = null;
                    return false;
                });
            }, LOADING_PROGRESS_ANIMATION_MS + DELAY_AFTER_LOADING_ANIMATION_FINISH);
        }
        if (fileUpload.error) {
            setLoadingInProgress(() => {
                animationTimeoutId.current = null;
                return false;
            });
        }
    }, [fileUpload.isSuccess, fileUpload.error]);
    if (isHidden) {
        return null;
    }
    const handleRemoveClick = async () => {
        if (uploadedFileId !== undefined) {
            await deleteRemoteFile(uploadedFileId);
        }
        onRemove(file, uploadedFileId);
    };
    const textColorClassname = fileUpload.error ? 'text-danger-500' : 'text-secondary-300';
    return (_jsxs("div", { className: "bg-greyscale-50 border border-greyscale-100 rounded flex items-center gap-1 p-1", children: [loadingInProgress ? (_jsx(CircularProgress, { size: 16, strokeWidth: 2, percentage: fileUpload.progress, animationTime: LOADING_PROGRESS_ANIMATION_MS })) : (_jsx(IconBox, { "data-tt": cx(fileUpload === null || fileUpload === void 0 ? void 0 : fileUpload.error), "data-tt-color": "danger", size: "s", className: textColorClassname, icon: fileUpload.error ? faExclamationTriangle : faFileCheck })), _jsx("span", { className: cx('font-brand-t4m', textColorClassname), children: file.name }), _jsx(Button.Icon, { icon: faTimes, size: "m", onClick: handleRemoveClick, disabled: fileUpload.isLoading, loading: isFileDeleting, className: "ml-auto" })] }));
});
