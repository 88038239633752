import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { autoUpdate, flip, FloatingNode, FloatingPortal, offset, shift, useFloating, useFloatingNodeId, } from '@floating-ui/react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LexicalTypeaheadMenuPlugin, MenuOption, useBasicTypeaheadTriggerMatch, } from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { $createTextNode } from 'lexical';
import { Fragment, useCallback, useMemo, useState, } from 'react';
// TODO consider sharing common styles for popover, tooltip, select
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import styles from '~/common/components/UI/Select/Select.module.scss';
import { cx } from '~/common/utils';
// stupid circular deps :/
import { withFloatingTree } from '../../components/UI/Floating';
import { $createMentionNode } from './MentionNode';
const PUNCTUATION = '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;';
const NAME = '\\b[A-Z][^\\s' + PUNCTUATION + ']';
const DocumentMentionsRegex = {
    NAME,
    PUNCTUATION,
};
const PUNC = DocumentMentionsRegex.PUNCTUATION;
const TRIGGERS = ['@'].join('');
// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = '[^' + TRIGGERS + PUNC + '\\s]';
// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS = '(?:' +
    '\\.[ |$]|' + // E.g. "r. " in "Mr. Smith"
    ' |' + // E.g. " " in "Josh Duck"
    '[' +
    PUNC +
    ']|' + // E.g. "-' in "Salier-Hellendag"
    ')';
const LENGTH_LIMIT = 75;
const AtSignMentionsRegex = new RegExp('(^|\\s|\\()(' +
    '[' +
    TRIGGERS +
    ']' +
    '((?:' +
    VALID_CHARS +
    VALID_JOINS +
    '){0,' +
    LENGTH_LIMIT +
    '})' +
    ')$');
// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50;
// Regex used to match alias.
const AtSignMentionsRegexAliasRegex = new RegExp('(^|\\s|\\()(' +
    '[' +
    TRIGGERS +
    ']' +
    '((?:' +
    VALID_CHARS +
    '){0,' +
    ALIAS_LENGTH_LIMIT +
    '})' +
    ')$');
// At most, 5 suggestions are shown in the popup.
const SUGGESTION_LIST_LENGTH_LIMIT = 5;
function checkForAtSignMentions(text, minMatchLength) {
    let match = AtSignMentionsRegex.exec(text);
    if (match === null) {
        match = AtSignMentionsRegexAliasRegex.exec(text);
    }
    if (match !== null) {
        // The strategy ignores leading whitespace but we need to know it's
        // length to add it to the leadOffset
        const maybeLeadingWhitespace = match[1];
        const matchingString = match[3];
        if (matchingString.length >= minMatchLength) {
            return {
                leadOffset: match.index + maybeLeadingWhitespace.length,
                matchingString,
                replaceableString: match[2],
            };
        }
    }
    return null;
}
function getPossibleQueryMatch(text) {
    return checkForAtSignMentions(text, 1);
}
class MentionTypeaheadOption extends MenuOption {
    constructor(entity) {
        super(entity.name);
        this.id = entity.id;
        this.name = entity.name;
        this.type = entity.type;
    }
}
const MentionsTypeaheadMenuItem = ({ isSelected, option, inputValue, ...props }) => {
    const re = new RegExp(`(${inputValue})`, 'gi'); // Use capturing parentheses to keep the text
    const parts = option.name.split(re); // Splitting with capturing regex to preserve the match
    const highlight = parts.filter(Boolean).map((part, index) => (_jsxs(Fragment, { children: [part.startsWith(' ') ? _jsx("span", { children: "\u00A0" }) : null, _jsx("span", { className: re.test(part) ? 'font-semibold' : undefined, children: part }), part.endsWith(' ') ? _jsx("span", { children: "\u00A0" }) : null] }, index)));
    return (_jsx("li", { ...props, ref: option.setRefElement, tabIndex: -1, className: cx(styles.option, isSelected && styles.optionActive, 'whitespace-nowrap', props.className), role: "option", "aria-selected": isSelected, children: highlight }));
};
const DropdownContainer = withFloatingTree((props) => {
    const nodeId = useFloatingNodeId();
    const { refs, floatingStyles } = useFloating({
        nodeId,
        placement: 'bottom-start',
        middleware: [
            offset(14),
            flip({ fallbackPlacements: ['bottom-start', 'top-start'] }),
            shift({ padding: 8 }),
        ],
        elements: {
            reference: props.anchorElement,
        },
        whileElementsMounted: autoUpdate,
    });
    return (_jsx(FloatingNode, { id: nodeId, children: _jsx(FloatingPortal, { children: _jsx("div", { ref: refs.setFloating, className: cx(styles.container, 'max-h-[162px]'), style: floatingStyles, children: props.children }) }) }));
});
const NO_RESULTS = [];
// TODO consider adding extension point for option renderer
export const MentionsPlugin = ({ mentionsLookupHook: useMentionLookup, }) => {
    const [editor] = useLexicalComposerContext();
    const [queryString, setQueryString] = useState(null);
    const results = (useMentionLookup === null || useMentionLookup === void 0 ? void 0 : useMentionLookup(queryString)) || NO_RESULTS;
    const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
        minLength: 0,
    });
    const options = useMemo(() => {
        return results
            .slice(0, SUGGESTION_LIST_LENGTH_LIMIT)
            .map((result) => new MentionTypeaheadOption(result));
    }, [results]);
    const onSelectOption = useCallback(({ id, name, type }, nodeToReplace, closeMenu) => {
        editor.update(() => {
            const mentionNode = $createMentionNode({ id, name, type });
            const spaceNode = $createTextNode(' ');
            if (nodeToReplace) {
                nodeToReplace.replace(mentionNode);
            }
            mentionNode.insertAfter(spaceNode);
            spaceNode.select();
            closeMenu();
        });
    }, [editor]);
    const checkForMentionMatch = useCallback((text) => {
        // TODO figure out why do we need to check for slash and bail out in case
        // it was found
        const slashMatch = checkForSlashTriggerMatch(text, editor);
        if (slashMatch !== null) {
            return null;
        }
        return getPossibleQueryMatch(text);
    }, [checkForSlashTriggerMatch, editor]);
    return (_jsx(LexicalTypeaheadMenuPlugin, { anchorClassName: "h-0 mt-[5px]", onQueryChange: setQueryString, onSelectOption: onSelectOption, triggerFn: checkForMentionMatch, options: options, menuRenderFn: (anchorElementRef, { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }) => anchorElementRef.current && results.length ? (_jsx(DropdownContainer, { anchorElement: anchorElementRef.current, children: _jsx("ul", { className: styles.options, children: options.map((option, i) => (_jsx(MentionsTypeaheadMenuItem, { isSelected: selectedIndex === i, onClick: () => {
                        setHighlightedIndex(i);
                        selectOptionAndCleanUp(option);
                    }, onMouseEnter: () => setHighlightedIndex(i), option: option, inputValue: queryString, id: `typeahead-item-${i}` }, option.id + option.type))) }) })) : null }));
};
