/**
 * Utility types
 */
import { formatNumber } from './data';
export const tw = (stringsArray) => stringsArray.join(' ').replace(/\s+/g, ' ').trim();
export const toggleAny = (oldState, newState) => {
    return oldState === newState ? null : newState;
};
export const pluralize = (n) => (n === 1 ? '' : 's');
// TODO this feels kinda dumb
export const pluralizeWord = (word, amount) => `${formatNumber(amount)} ${word}${pluralize(amount)}`;
export const capitalize = (s) => (s.charAt(0).toUpperCase() + s.slice(1));
export const errorContains = (error, ...substrings) => Boolean(error instanceof Error && error.message.match(new RegExp(substrings.join('|'))));
export const is404 = (error) => errorContains(error, 404);
// TODO: Fix this method when countries endpoint introduced
export const formatPhoneNumber = (dialCode, number) => `+${dialCode} ${number}`;
export const noop = () => { };
export const slugify = (text) => {
    // Normalize the string to decomposed Unicode characters
    return text
        .normalize('NFD') // Decompose to normal form D (NFD) to separate characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, '') // Remove diacritical marks
        .toLowerCase() // Convert to lowercase
        .trim() // Trim whitespace from both sides
        .replace(/[^a-z0-9 ]/g, '') // Remove non-alphanumeric characters except spaces
        .replace(/\s+/g, '-') // Replace spaces with dashes
        .replace(/-+/g, '-'); // Replace multiple dashes with a single dash
};
export const addSlug = (text) => {
    const result = slugify(text);
    return result ? '-' + result : result;
};
