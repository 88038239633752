import { z } from 'zod';
import { DateFromIsoString } from '~/common/utils';
import { UploadedFile, getListDecoder, Office, OfficeForSelect, NumericIdNamePair } from '~/root';
const DesignerInit = NumericIdNamePair;
const Designer = z.object({
    id: z.number().int().positive(),
    name: z.string(),
    capacity: z.number().int().nonnegative(),
    added: DateFromIsoString,
    on_shift: z.boolean(),
    job_title: z.string(),
    avatar: UploadedFile.nullable(),
});
export const TeamTable = z.object({
    id: z.number().int().positive(),
    name: z.string(),
    description: z.string(),
    total_members: z.number().int().nonnegative(),
    office: Office,
});
export const Team = TeamTable.extend({
    daily_capacity: z.number().int().nonnegative(),
    slack_channel: z.string().nullable(),
    trello_board_id: z.string().nullable(),
    avatar: UploadedFile.nullable(),
    updated: DateFromIsoString,
    designers: z.array(Designer),
    leader: z.number().int().positive().nullable(),
}).transform((team) => ({
    ...team,
    designers: team.designers
        .map((designer) => ({
        ...designer,
        leader: team.leader === designer.id,
    }))
        .sort((designer) => (designer.leader ? -1 : 1)),
}));
export const TeamsList = getListDecoder(TeamTable);
export const Init = z.object({
    designers: z.array(DesignerInit),
    teamless_designers: z.array(DesignerInit),
    offices: z.array(OfficeForSelect),
});
