import { success, failure } from 'formoid';
import { record } from '../data';
import { createErrorMap } from './error-map';
export function fromZod(zod, errorMap) {
    return function (value) {
        return zod
            .safeParseAsync(value, { errorMap })
            .then((result) => result.success
            ? success(result.data)
            : failure(result.error.errors.map(({ message }) => message)));
    };
}
export function fromZodSchema(schema) {
    return record.fromEntries(record.entries(schema).map(([key, tuple]) => {
        return [key, tuple === null ? null : fromZod(tuple[1], createErrorMap(tuple[0]))];
    }));
}
