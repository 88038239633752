import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { useMemo } from 'react';
import { IconBox, Popover } from '~/common/components';
import { cx, getOptionLabel } from '~/common/utils';
import styles from './TransitionStatus.module.scss';
export const Select = ({ onChange, options, value }) => {
    const label = useMemo(() => getOptionLabel(options, value), [options, value]);
    const hasMoreThanOneOption = options.length > 1;
    return (_jsx(Popover, { className: "bg-greyscale-0 border border-greyscale-300 font-brand-b1 flex flex-col -mt-1 p-0", content: () => options.map((option, key) => (_jsx("button", { className: styles.option, onClick: () => onChange(option.value), title: option.name, children: _jsx("span", { children: option.name }) }, key))), placement: "bottom-start", trigger: (props) => (_jsxs("button", { ...(hasMoreThanOneOption ? props : {}), className: cx(styles.badge, {
                [styles.completed]: value === 'Completed',
                [styles.canceleted]: value === 'Cancelled',
            }), children: [_jsx("span", { children: label }), hasMoreThanOneOption && _jsx(IconBox, { size: "s", className: "ml-1", icon: faChevronDown })] })) }));
};
