import { jsx as _jsx } from "react/jsx-runtime";
import { faChartBar } from '@fortawesome/pro-regular-svg-icons';
import { RmsContext } from './context';
import { RMS_URL } from './hooks';
import { Overview } from './Overview';
const Rms = () => (_jsx(RmsContext, { children: _jsx(Overview, {}) }));
const nav = {
    icon: faChartBar,
    label: 'RMS',
    path: RMS_URL,
};
export const rmsModule = {
    canShow: (permissions) => permissions.rmsView,
    route: {
        path: RMS_URL,
        component: Rms,
    },
    nav,
};
