import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';
import { Button, Input, Popover } from '~/common/components';
export const PopoverStand = () => {
    const [value, setValue] = useState('test');
    const [outsideValue, setOutsideValue] = useState('default value');
    const handleApply = (close) => () => {
        setOutsideValue(value);
        close();
    };
    return (_jsxs("div", { className: "bg-greyscale-0 p-2 rounded", children: [_jsx("div", { className: "font-brand-h1 w-full mb-3", children: "Popover" }), _jsxs("div", { className: "mb-2", children: ["value: ", outsideValue] }), _jsx(Popover, { className: "w-30 h-40 text-greyscale-600", placement: "right", trigger: (props) => _jsx(Button, { ...props, children: "Show" }), content: (props) => (_jsxs(_Fragment, { children: [_jsx(Button.Icon, { size: "m", color: "secondary", icon: faTimes, onClick: props.onClose, className: "ml-auto flex" }), _jsxs("div", { className: "space-y-2", children: [_jsx("div", { className: "font-brand-t2m", children: "Update something" }), _jsx(Input, { value: value, onChange: (e) => setValue(e.target.value) }), _jsx(Button, { onClick: handleApply(props.onClose), children: "Apply" })] })] })) })] }));
};
