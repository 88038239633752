const AMOUNT_OF_PAGES_MAP = {
    least: [2, 6],
    more: [3, 8],
    most: [4, 16],
};
const DOTS = '...';
export const getPages = (currentPage, pagesAmount, visiblePagesAmount = 'least') => {
    const [normalDelta, compensation] = AMOUNT_OF_PAGES_MAP[visiblePagesAmount];
    const compensatedDelta = compensation - Math.min(currentPage - 1, pagesAmount - currentPage);
    const delta = Math.max(normalDelta, compensatedDelta);
    const pages = [];
    let key = 'left';
    for (let page = 1; page <= pagesAmount; page++) {
        const isFirstOrLast = page === 1 || page === pagesAmount;
        const isInDeltaRange = page - delta <= currentPage && page + delta >= currentPage;
        if (isFirstOrLast || isInDeltaRange) {
            pages.push({ key: page, value: page });
            continue;
        }
        if (pages[pages.length - 1].value !== DOTS) {
            pages.push({ key, value: DOTS });
            key = 'right';
        }
    }
    return pages;
};
