import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm, validator } from 'formoid';
import { ModalHeader, ModalShell } from '~/common/components';
import { customValidator, notifyBackendError, propagateBackendValidationErrors, requiredMessage, } from '~/common/utils';
import { EditOrganizationInfoView } from '~/organizations/common';
import { useEditOrganizationInfo, useOrganizationData, } from '~/organizations/hooks';
export const EditOrganizationInfoPopup = ({ onClose }) => {
    var _a, _b;
    const organization = useOrganizationData();
    const initialValues = {
        name: organization.name,
        url: organization.url || '',
        owner_email: ((_a = organization.owner) === null || _a === void 0 ? void 0 : _a.email) || '',
        company_logo_id: ((_b = organization.logo) === null || _b === void 0 ? void 0 : _b.id) || null,
    };
    const { fieldProps, setErrors, handleSubmit } = useForm({
        initialValues,
        validationStrategy: 'onChange',
        validators: () => ({
            name: validator.minLength(1, requiredMessage('Name')),
            url: customValidator.link(),
            owner_email: customValidator.email(),
            company_logo_id: null,
        }),
    });
    const { mutateAsync, isLoading } = useEditOrganizationInfo();
    const submit = () => handleSubmit((values) => {
        var _a;
        //Strip out company_logo_id if it's not changed
        const payload = {
            name: values.name,
            url: values.url,
            owner_email: values.owner_email,
        };
        if (values.company_logo_id !== ((_a = organization.logo) === null || _a === void 0 ? void 0 : _a.id)) {
            payload.company_logo_id = values.company_logo_id;
        }
        return mutateAsync(payload)
            .then(onClose)
            .catch((error) => propagateBackendValidationErrors(error, setErrors))
            .catch(notifyBackendError);
    });
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Edit organization information" }), _jsx(ModalShell, { loading: isLoading, onClose: onClose, onSubmit: submit, className: "w-[608px]", children: _jsx("div", { className: "p-3 space-y-3 mb-2 w-[608px]", children: _jsx(EditOrganizationInfoView, { organization: organization, fieldProps: fieldProps, setErrors: setErrors }) }) })] }));
};
