import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import Authorizer from 'pusher-js-auth';
import { PUSHER_KEY, SERVER_URL } from '~/env';
Pusher.Runtime.createXHR = function () {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    return xhr;
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.Pusher = Pusher;
const serverUrl = new URL(SERVER_URL);
export const getEcho = () => {
    // TODO only initialize echo under a web lock, so we don't waste resources on secondary tabs
    // although having auth delay might hurt and miss some events when switching them, ooof...
    return new Echo({
        authEndpoint: `${serverUrl}broadcasting/auth/batch`,
        authorizer: Authorizer,
        broadcaster: 'pusher',
        disableStats: false,
        enabledTransports: ['ws', 'wss'],
        forceTLS: false,
        key: PUSHER_KEY,
        wsHost: serverUrl.host.replace(`:${serverUrl.port}`, ''),
        wsPort: parseInt(serverUrl.port) || undefined,
    });
};
