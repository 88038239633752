import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Banner } from '~/common/components';
import { getInitialImageOffset, usePanAndZoom } from '~/common/kits/image-pan-and-zoom';
import { cropImage, cx, ignoreHandled, resizeImage } from '~/common/utils';
import css from './Crop.module.scss';
export const getInitialImageDimensions = (containerRect, image) => {
    const { height: containerHeigth, width: containerWidth } = containerRect;
    const { naturalHeight, naturalWidth } = image;
    const containerAspectRatio = containerWidth / containerHeigth;
    const imageAspectRatio = naturalWidth / naturalHeight;
    return imageAspectRatio >= containerAspectRatio
        ? {
            width: (naturalWidth * containerHeigth) / naturalHeight,
            height: containerHeigth,
        }
        : {
            width: containerWidth,
            height: (naturalHeight * containerWidth) / naturalWidth,
        };
};
// To clearly separate responsibilities and use the least amount of
// weirdly-named props I had to resort to render props
//
// This component is only concerned with cropping and allows having full layout
// control in the parent
export const Crop = ({ file, onResize, contentRenderer, maxSize = 150, imageContainerSize = 360, imageCircleDiameter = 336, }) => {
    var _a;
    const [isImageInited, setIsImageInited] = useState(false);
    const imageUrl = useMemo(() => (file ? URL.createObjectURL(file) : undefined), [file]);
    const imageDimensions = useRef();
    const naturalImageDimensions = useRef();
    const circleOffset = (imageContainerSize - imageCircleDiameter) / 2; // px
    const { containerRef, imageWrapperRef, position, scale, reset, onMouseDown } = usePanAndZoom({
        maxScale: 5,
        dragLimit: 0,
    });
    const resetPanAndZoom = useCallback(() => {
        if (containerRef.current && imageDimensions.current) {
            reset({
                position: getInitialImageOffset(containerRef.current.getBoundingClientRect(), imageDimensions.current),
            });
        }
    }, [containerRef, reset]);
    useLayoutEffect(() => {
        setIsImageInited(false);
    }, [imageUrl]);
    const onLoad = (event) => {
        if (containerRef.current) {
            naturalImageDimensions.current = {
                width: event.currentTarget.naturalWidth,
                height: event.currentTarget.naturalHeight,
            };
            imageDimensions.current = getInitialImageDimensions(containerRef.current.getBoundingClientRect(), event.currentTarget);
            resetPanAndZoom();
            setIsImageInited(true);
        }
    };
    const handleSave = async () => {
        if (!(imageDimensions.current && naturalImageDimensions.current && imageUrl && file)) {
            return;
        }
        const selectedAreaX = -position.x + circleOffset;
        const selectedAreaY = -position.y + circleOffset;
        const initialImageScale = imageDimensions.current.width / naturalImageDimensions.current.width;
        const overallScale = initialImageScale * scale;
        const sourceX = selectedAreaX / overallScale;
        const sourceY = selectedAreaY / overallScale;
        const selectedAreaDimensions = imageCircleDiameter / overallScale;
        const cropImageOptions = {
            sourceX,
            sourceY,
            sourceWidth: selectedAreaDimensions,
            sourceHeight: selectedAreaDimensions,
        };
        const croppedImage = await cropImage(imageUrl, file, cropImageOptions);
        const resizedImage = await resizeImage(croppedImage, { max: maxSize });
        onResize(resizedImage);
    };
    const children = (
    // feel free to move this banner and the wrapper out of this crop to
    // the component that uses it if you need other layout for a different
    // case
    _jsxs("div", { className: "flex flex-col gap-2 px-2", children: [_jsx(Banner, { title: "Zoom and drag your picture until you like it", color: "info" }), _jsxs("div", { className: css.container, style: { width: `${imageContainerSize}px`, height: `${imageContainerSize}px` }, ref: containerRef, onDoubleClick: ignoreHandled(resetPanAndZoom), onMouseDown: ignoreHandled(onMouseDown), children: [_jsx("div", { className: css.circle, style: {
                            height: `${imageCircleDiameter}px`,
                            width: `${imageCircleDiameter}px`,
                            top: `${circleOffset}px`,
                            left: `${circleOffset}px`,
                        } }), _jsx("div", { ref: imageWrapperRef, style: {
                            transform: `translate(${position.x}px, ${position.y}px)`,
                            width: `${(((_a = imageDimensions.current) === null || _a === void 0 ? void 0 : _a.width) || 0) * scale}px`,
                        }, className: "relative", children: file && (_jsx("img", { className: cx(css.image, { [css.notInited]: !isImageInited }), alt: "New avatar", src: imageUrl, onLoad: onLoad })) })] })] }));
    return _jsx(_Fragment, { children: contentRenderer({ handleSave, children }) });
};
