import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, Fragment } from 'react';
import { isLink } from '~/common/utils';
import { Link } from './Interactives';
const ParsedLink = ({ children }) => (_jsx(Link, { newTab: true, external: true, className: "break-all", size: "m", href: children, children: children }));
export const ParseLinks = forwardRef(({ children, ...props }, ref) => {
    const words = children.split(' ');
    const elements = [];
    let accumulatedText = '';
    words.forEach((word, index) => {
        if (isLink(word)) {
            if (accumulatedText) {
                elements.push(_jsx(Fragment, { children: accumulatedText }, index + '-text'));
                accumulatedText = '';
            }
            elements.push(_jsx(ParsedLink, { children: word }, index + '-link'));
        }
        else {
            accumulatedText += word + ' ';
        }
    });
    if (accumulatedText) {
        elements.push(_jsx(Fragment, { children: accumulatedText }, words.length + '-text'));
    }
    return (_jsx("p", { ref: ref, ...props, children: elements }));
});
