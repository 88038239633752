import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { Banner, DragDrop } from '~/common/components';
import { ImageZoomAndPan } from '~/common/kits/image-pan-and-zoom';
import { cx } from '~/common/utils';
import { isSlide, sortFilesAlphanumerically } from '~/orders/hooks';
import { mimeTypes } from '~/orders/utils';
import { useCurrentUserData, usePrompt } from '~/root';
import { useRevisionsContext } from '../revisionsContext';
import { AddPreview } from './AddPreview';
import { Annotation } from './Annotation';
import { AnnotationList } from './AnnotationList';
import { EditHeader } from './EditHeader';
import { PreviewItem } from './PreviewItem';
import { ScrollableList } from './ScrollableList';
import { UploadPreviews } from './UploadPreviews';
import css from './Previews.module.scss';
const FailedList = ({ title, children, belowChildren, icon = 'sad', }) => (_jsxs("div", { className: "mx-3 mb-4 font-brand-b1", children: [_jsxs("h3", { className: "flex gap-1 font-brand-t1m mb-2", children: [_jsx("span", { children: title }), _jsx("span", { children: icon === 'sad' ? '😔' : '🤔' })] }), _jsx("ul", { className: "mb-4", children: children('flex items-center justify-between pt-[12px] pb-[4px] border-b border-greyscale-200 text-greyscale-500') }), belowChildren] }));
const FilteredFiles = ({ filtered, all = false }) => (_jsx(FailedList, { title: `${all ? 'All' : 'Some'} files were filtered out`, belowChildren: _jsx("p", { children: "These types of files are not supported." }), icon: "thinking", children: (className) => filtered.map((entry) => (_jsx("li", { className: className, children: entry }, entry))) }));
const FailedUploads = ({ failed }) => (_jsx(FailedList, { title: "Some files weren't uploaded", belowChildren: _jsx(Banner, { color: "info", children: "If you have only one deck, you can try uploading them all again. Only failed ones will be re-uploaded. Results will be sorted." }), children: (className) => failed.map((entry) => (_jsxs("li", { className: className, children: [_jsx("span", { children: entry.filename }), _jsx("span", { className: "text-danger-400", children: entry.error })] }, entry.filename))) }));
export const Previews = () => {
    const { permissions } = useCurrentUserData();
    const prompt = usePrompt();
    const { editing, lastRevisionSelected, previewsState, previewsActions, previewsUpload, annotationListOpened, } = useRevisionsContext();
    const [imageInited, setImageInited] = useState(false);
    const uploadedFilenames = useMemo(() => {
        return new Set(previewsState.previews.map((preview) => preview.name.toLowerCase()));
    }, [previewsState.previews]);
    const notUploaded = (file) => !uploadedFilenames.has(file.name.toLowerCase());
    const chosenPreview = previewsState.chosenPreview;
    const handleUploadSuccess = (previews) => {
        previewsActions.addPreviews(previews);
        if (previewsState.sortingBehavior === 'Sort all') {
            previewsActions.sortPreviews();
        }
        if (previews.length) {
            previewsActions.choosePreview(previews[0].id);
        }
    };
    const { uploadPreviews, onCancel, isLoading, progress } = previewsUpload;
    const onUpload = (list) => {
        const files = Array.from(list);
        const allowedFiles = files.filter(mimeTypes.preview.isAllowed);
        const onSuccess = ({ uploaded, failed }) => {
            if (failed.length) {
                prompt({
                    content: _jsx(FailedUploads, { failed: failed }),
                    submitText: 'Okay',
                });
            }
            handleUploadSuccess(uploaded);
        };
        const upload = () => {
            if (previewsState.sortingBehavior === 'Sort all') {
                return uploadPreviews(allowedFiles.filter(notUploaded), onSuccess);
            }
            if (previewsState.sortingBehavior === 'Sort deck') {
                return uploadPreviews(sortFilesAlphanumerically(allowedFiles), onSuccess);
            }
            return uploadPreviews(allowedFiles, onSuccess);
        };
        if (allowedFiles.length === files.length) {
            return upload();
        }
        const filtered = files
            .filter((file) => !mimeTypes.preview.isAllowed(file))
            .map((file) => file.name);
        if (!allowedFiles.length) {
            return prompt({
                content: _jsx(FilteredFiles, { all: true, filtered: filtered }),
                submitText: 'Okay',
            });
        }
        prompt({
            content: _jsx(FilteredFiles, { filtered: filtered }),
            submitText: 'Yes, continue without them',
            cancelText: 'No, cancel upload',
            onSubmit: upload,
            negativeAction: true,
        });
    };
    if (!previewsState.previews.length) {
        return (_jsx(UploadPreviews, { isLoading: isLoading, onCancel: onCancel, onUpload: onUpload, progress: progress }));
    }
    return (_jsxs("div", { className: css.wrapper, children: [_jsxs("div", { className: cx(css.previewsWrapper, annotationListOpened && css.annotationListOpened), children: [_jsxs("div", { className: css.previews, children: [_jsx(EditHeader, { isLoading: isLoading, onCancel: onCancel, progress: progress }), _jsxs(ScrollableList, { className: "space-y-2", children: [_jsx(DragDrop, { useCustomDraggableTarget: true, containerClassName: "w-full space-y-2", items: previewsState.previews, onChange: previewsActions.swapPreviews, itemRenderer: ({ item: preview, removeItem, ...props }) => {
                                            return (_jsx(PreviewItem, { ...props, editing: !isLoading && editing, isActive: previewsState.selectedPreviewId === preview.id, isSelected: previewsState.selectedPreviewIds.has(preview.id), onChoose: () => previewsActions.choosePreview(preview.id), onRemove: removeItem, onToggle: () => previewsActions.toggleSelectPreview(preview.id), preview: preview }));
                                        } }), editing && _jsx(AddPreview, { onUpload: onUpload })] })] }), chosenPreview && (_jsx("div", { className: css.preview, children: _jsx(ImageZoomAndPan, { alt: "Preview", imageClassname: "bg-greyscale-0 rounded", src: chosenPreview.link, onZoom: () => previewsActions.selectAnnotation(null), onInited: () => setImageInited(true), children: imageInited &&
                                isSlide(chosenPreview) &&
                                chosenPreview.annotations.map((annotation) => (_jsx(Annotation, { annotation: annotation, canEdit: permissions.ordersUpdate && lastRevisionSelected, index: annotation.index, isSelected: annotation.id === previewsState.selectedAnnotationId, onSelect: () => previewsActions.selectAnnotation(annotation.id) }, annotation.id))) }) }))] }), _jsx(AnnotationList, {})] }));
};
