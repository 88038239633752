import { useForm } from 'formoid';
import { customValidator } from '~/common/utils';
import { changeStatusPayload } from '~/orders/hooks';
export function useTransitionCompleted(onSubmit) {
    const initialValues = {
        reason: '',
    };
    const { fieldProps, handleSubmit, isSubmitting } = useForm({
        initialValues,
        validationStrategy: 'onBlur',
        validators: () => ({
            reason: customValidator.nonBlankString(),
        }),
    });
    const submit = () => handleSubmit(({ reason }) => onSubmit(changeStatusPayload.completed({ reason })));
    return {
        fieldProps,
        handleSubmit: submit,
        isSubmitting,
    };
}
