import { useMutation } from '@tanstack/react-query';
import { useIdParam } from '~/common/hooks';
import { httpClient, map } from '~/common/utils';
import { qk } from '~/root/query-keys';
import { useUpdateMessagesCache } from './useUpdateMessagesCache';
export const useRemoveMessage = ({ onSuccess }) => {
    const id = useIdParam();
    const updateCache = useUpdateMessagesCache();
    return useMutation({
        mutationKey: qk.collaborationRemoveMessage(id),
        mutationFn: (messageId) => {
            return httpClient
                .delete(`/v1/staff/orders/${id}/collaboration/comments/${messageId}`)
                .then(() => messageId);
        },
        onSuccess: (id) => {
            // TODO for some reason ws updates don't work for removals
            // TODO dedupe with useMessageEvents
            updateCache((messages) => ({
                ...messages,
                pages: messages.pages.map((page) => {
                    return page.items.has(id)
                        ? {
                            ...page,
                            items: map.remove(page.items, id),
                        }
                        : page;
                }),
            }));
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
        },
    });
};
