/**
 * Add notification badge (pill) to favicon in browser tab
 * @url stackoverflow.com/questions/65719387/
 */
export class Badger {
    constructor(options = {}) {
        var _a;
        this.backgroundColor = '#f00';
        this.color = '#fff';
        this.size = 0.6;
        this.position = 'top-right';
        this.radius = 8;
        this.src = '';
        this.onChange = () => { };
        this.canvas = document.createElement('canvas');
        this.ctx = this.canvas.getContext('2d');
        this.faviconSize = 0;
        this.badgeSize = 0;
        this.offset = { x: 0, y: 0 };
        this.faviconEL = document.querySelector('link[rel$=icon]');
        this.img = null;
        this._value = 0;
        if (options.backgroundColor !== undefined)
            this.backgroundColor = options.backgroundColor;
        if (options.color !== undefined)
            this.color = options.color;
        if (options.size !== undefined)
            this.size = options.size;
        if (options.position !== undefined)
            this.position = options.position;
        if (options.radius !== undefined)
            this.radius = options.radius;
        if (options.src !== undefined)
            this.src = options.src;
        if (options.onChange !== undefined)
            this.onChange = options.onChange;
        this.src = this.src || ((_a = this.faviconEL) === null || _a === void 0 ? void 0 : _a.getAttribute('href')) || '';
    }
    _drawIcon() {
        if (!this.ctx || !this.faviconSize)
            return;
        this.ctx.clearRect(0, 0, this.faviconSize, this.faviconSize);
        this.ctx.drawImage(this.img, 0, 0, this.faviconSize, this.faviconSize);
    }
    _drawShape() {
        if (!this.ctx)
            return;
        const r = this.radius;
        const xa = this.offset.x;
        const ya = this.offset.y;
        const xb = this.offset.x + this.badgeSize;
        const yb = this.offset.y + this.badgeSize;
        this.ctx.beginPath();
        this.ctx.moveTo(xb - r, ya);
        this.ctx.quadraticCurveTo(xb, ya, xb, ya + r);
        this.ctx.lineTo(xb, yb - r);
        this.ctx.quadraticCurveTo(xb, yb, xb - r, yb);
        this.ctx.lineTo(xa + r, yb);
        this.ctx.quadraticCurveTo(xa, yb, xa, yb - r);
        this.ctx.lineTo(xa, ya + r);
        this.ctx.quadraticCurveTo(xa, ya, xa + r, ya);
        this.ctx.fillStyle = this.backgroundColor;
        this.ctx.fill();
        this.ctx.closePath();
    }
    _drawVal() {
        if (!this.ctx)
            return;
        const margin = (this.badgeSize * 0.18) / 2;
        this.ctx.beginPath();
        this.ctx.textBaseline = 'middle';
        this.ctx.textAlign = 'center';
        this.ctx.font = `bold ${this.badgeSize * 0.82}px Arial`;
        this.ctx.fillStyle = this.color;
        this.ctx.fillText(this._value.toString(), this.badgeSize / 2 + this.offset.x, this.badgeSize / 2 + this.offset.y + margin);
        this.ctx.closePath();
    }
    _drawFavicon() {
        var _a;
        (_a = this.faviconEL) === null || _a === void 0 ? void 0 : _a.setAttribute('href', this.dataURL);
    }
    _draw() {
        this._drawIcon();
        if (this._value)
            this._drawShape();
        if (this._value)
            this._drawVal();
        this._drawFavicon();
    }
    _setup() {
        if (!this.img)
            return;
        this.faviconSize = this.img.naturalWidth;
        this.badgeSize = this.faviconSize * this.size;
        this.canvas.width = this.faviconSize;
        this.canvas.height = this.faviconSize;
        const sd = this.faviconSize - this.badgeSize;
        const sd2 = sd / 2;
        this.offset = {
            top: { x: sd2, y: 0 },
            right: { x: sd, y: sd2 },
            bottom: { x: sd2, y: sd },
            left: { x: 0, y: sd2 },
            'top-left': { x: 0, y: 0 },
            'top-right': { x: sd, y: 0 },
            'bottom-left': { x: 0, y: sd },
            'bottom-right': { x: sd, y: sd },
        }[this.position];
    }
    // Public functions/methods:
    update(value) {
        this._value = Math.min(99, value || 0);
        if (this.img) {
            this._draw();
            if (this.onChange)
                this.onChange.call(this);
        }
        else {
            this.img = new Image();
            this.img.addEventListener('load', () => {
                this._setup();
                this._draw();
                if (this.onChange)
                    this.onChange.call(this);
            });
            this.img.src = this.src;
        }
    }
    get dataURL() {
        return this.canvas.toDataURL();
    }
    get value() {
        return this._value;
    }
}
