import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRouteMatch } from 'react-router-dom';
import { z } from 'zod';
import { assertQueryData, decodeOrNull, httpClient, nonNullable } from '~/common/utils';
import { qk } from '~/root/query-keys';
import { CurrentUser } from '../domain';
import { LoginResponse } from './domain';
import { userStore } from './globalUser';
export const useCurrentUser = () => useQuery({
    queryKey: qk.init,
    queryFn: () => httpClient.get('/v1/staff/init', { decoder: CurrentUser }),
    onSuccess: ({ id, name, email, role }) => userStore.setState({ id: String(id), name, email, role }),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
});
export const useCurrentUserData = () => assertQueryData(useCurrentUser());
export const useLogin = () => {
    const client = useQueryClient();
    return useMutation({
        mutationFn: (data) => httpClient.post('/v1/users/login', {
            data,
            decoder: LoginResponse,
        }),
        onSuccess: () => {
            client.invalidateQueries(qk.init);
        },
    });
};
const Token = z.object({ token: z.string() }).transform(({ token }) => token);
const Name = z.object({ name: z.string() }).transform(({ name }) => name);
export const useTokenCheck = () => {
    const { params } = useRouteMatch();
    const token = decodeOrNull(params, Token);
    return useQuery({
        queryKey: qk.invitationsToken,
        queryFn: () => httpClient.get('/v1/staff/invitations/' + nonNullable(token), {
            decoder: Name,
        }),
        staleTime: Infinity,
        enabled: token !== null,
    });
};
