import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { autoUpdate, flip, FloatingFocusManager, FloatingNode, FloatingPortal, offset, shift, useClick, useDismiss, useFloating, useFloatingNodeId, useInteractions, useTransitionStatus, } from '@floating-ui/react';
import { useCallback, useState } from 'react';
import { MAX_TAB_INDEX } from '~/common/hooks';
import { cx } from '~/common/utils';
import fade from '~/styles/fade.module.scss';
import { FloatingDiv, outsidePress, withFloatingTree } from './Floating';
import { styles } from './Tooltip';
export const Popover = withFloatingTree(({ trigger, color = 'white', className, placement = 'bottom', fallbackPlacements, compensateOffset = 0, onClose, onClick, externalState, content, }) => {
    const internalState = useState(false);
    const [open, setOpen] = externalState !== null && externalState !== void 0 ? externalState : internalState;
    const close = useCallback(() => setOpen(false), [setOpen]);
    const nodeId = useFloatingNodeId();
    const { refs, context, floatingStyles } = useFloating({
        nodeId,
        placement,
        open,
        onOpenChange: setOpen,
        middleware: [
            offset(12 - compensateOffset),
            flip({ fallbackPlacements }),
            shift({ padding: 8 }),
        ],
        whileElementsMounted: autoUpdate,
    });
    const { getReferenceProps, getFloatingProps } = useInteractions([
        useClick(context),
        useDismiss(context, {
            ancestorScroll: true,
            outsidePress,
        }),
    ]);
    const { isMounted, status } = useTransitionStatus(context);
    return (_jsxs(_Fragment, { children: [trigger(getReferenceProps({ ref: refs.setReference })), isMounted && (_jsx(FloatingNode, { id: nodeId, children: _jsx(FloatingPortal, { children: _jsx(FloatingFocusManager, { context: context, initialFocus: MAX_TAB_INDEX, children: _jsx(FloatingDiv, { ...getFloatingProps({
                                ref: refs.setFloating,
                                className: cx('pointer-events-auto z-20 focus-visible:outline-none', styles.tooltip, styles[color], fade.floating, className),
                                style: floatingStyles,
                                onClick,
                            }), "data-status": status, onTransitionEnd: () => status === 'close' && (onClose === null || onClose === void 0 ? void 0 : onClose()), children: content({ onClose: close }) }) }) }) }))] }));
});
