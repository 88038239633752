import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faPencil, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useForm, validator } from 'formoid';
import { useState } from 'react';
import { Button, FormElementLabel, InputField, ModalHeader, ModalShell, Popover, Link, } from '~/common/components';
import { customValidator, cx, minLengthMessage, propagateBackendValidationErrors, } from '~/common/utils';
import { useCustomerData, useCustomerLinksMutation } from '~/customers/hooks';
const EditLink = ({ onSubmit, onClose, url, label, updating = false, }) => {
    const initialValues = {
        url: url !== null && url !== void 0 ? url : '',
        label: label !== null && label !== void 0 ? label : '',
    };
    const { fieldProps, handleSubmit } = useForm({
        initialValues,
        validationStrategy: 'onBlur',
        validators: () => ({
            url: customValidator.link(),
            label: validator.minLength(2, minLengthMessage('Title', 5)),
        }),
    });
    const submit = () => handleSubmit((values) => {
        return Promise.resolve(onSubmit(values)).then(onClose);
    });
    return (_jsxs(_Fragment, { children: [_jsx(InputField, { ...fieldProps('label'), title: "Title to display", placeholder: "Add the title", type: "text", size: "small" }), _jsx(InputField, { ...fieldProps('url'), title: "Link", placeholder: "Add link url", type: "text", size: "small" }), _jsxs("div", { className: "flex justify-end gap-2", children: [_jsx(Button, { color: "grey", onClick: onClose, children: "Cancel" }), _jsx(Button, { color: "secondary", onClick: submit, children: updating ? 'Update' : 'Add link' })] })] }));
};
export const EditCustomerLinks = ({ onClose }) => {
    const customer = useCustomerData();
    const { mutateAsync } = useCustomerLinksMutation();
    const [editingLink, setEditingLink] = useState(null);
    const initialValues = {
        calendly_link: customer.calendly_link || '',
        links: customer.links || [],
    };
    const { fieldProps, handleSubmit, setErrors, isSubmitting, values } = useForm({
        initialValues,
        validationStrategy: 'onBlur',
        validators: ({ calendly_link }) => ({
            calendly_link: calendly_link !== '' ? customValidator.link() : null,
            links: null,
        }),
    });
    const submit = () => handleSubmit((values) => {
        return mutateAsync({
            ...values,
            calendly_link: (values.calendly_link || null),
        })
            .then(onClose)
            .catch((error) => {
            propagateBackendValidationErrors(error, setErrors);
        });
    });
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Edit customer links" }), _jsxs(ModalShell, { className: "p-3 w-[494px]", onClose: onClose, onSubmit: submit, loading: isSubmitting, children: [_jsx(InputField, { ...fieldProps('calendly_link'), className: "mb-3", title: "Calendly link", placeholder: "Add the link", type: "text", hint: "Customer support Calendly link", optional: true }), _jsxs("div", { className: "mb-1", children: [_jsx(FormElementLabel, { title: "Custom links", optional: true }), values.links.map((link) => (_jsxs("div", { className: cx('flex items-center h-4 px-1 font-brand-b1 text-greyscale-500 hover:bg-greyscale-100 min-w-0', 'transition-colors group rounded', editingLink === link.url && 'bg-greyscale-100'), children: [_jsx(Link, { external: true, newTab: true, size: "m", href: link.url, children: link.label }), _jsx(Popover, { placement: "bottom-end", className: "w-[352px] space-y-2 text-greyscale-600 p-2 ml-4", trigger: ({ onClick, ...props }) => (_jsx(Button.Icon, { ...props, onClick: (e) => {
                                                setEditingLink(link.url);
                                                onClick(e);
                                            }, className: "ml-auto opacity-0 group-hover:opacity-100 transition-opacity", icon: faPencil })), content: ({ onClose }) => (_jsx(EditLink, { ...link, onClose: () => {
                                                setEditingLink(null);
                                                onClose();
                                            }, onSubmit: (next) => fieldProps('links').onChange(values.links.map((prev) => (prev.url === link.url ? next : prev))), updating: true })), onClose: () => setEditingLink(null) }, link.url), _jsx(Button.Icon, { className: "opacity-0 group-hover:opacity-100 transition-opacity", icon: faTimes, onClick: () => {
                                            fieldProps('links').onChange(values.links.filter((prev) => prev.url !== link.url));
                                        } })] }, link.url)))] }), _jsx(Popover, { placement: "bottom-start", trigger: (props) => (_jsx(Button, { ...props, "data-tt": cx(values.links.length >= 5 && 'You can add only 5 links'), "data-tt-placement": "right", icon: faPlus, color: "text-secondary", size: "xs", disabled: values.links.length >= 5, className: "w-fit", children: "Add link" })), className: "w-[352px] space-y-2 text-greyscale-600 p-2", content: (props) => (_jsx(EditLink, { ...props, onSubmit: (link) => fieldProps('links').onChange([...values.links, link]) })) })] })] }));
};
