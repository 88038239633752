import { useMutation } from '@tanstack/react-query';
import { httpClient, nonNullable } from '~/common/utils';
import { FeatureValues } from './domain';
export const useFeatureFlags = ({ initFeatures, targetFeatures, targetable_id, onMutationSuccess, }) => {
    const mutation = useMutation({
        mutationFn: (values) => {
            const data = {
                targetable_type: initFeatures.targetable,
                targetable_id: targetable_id,
                ...values,
            };
            return httpClient.put('v1/staff/features', { data, decoder: FeatureValues });
        },
        onSuccess: onMutationSuccess,
    });
    return {
        items: Object.entries(targetFeatures).map(([key, value]) => {
            return {
                ...nonNullable(initFeatures.items.find((feature) => feature.id === key)),
                value,
            };
        }),
        formValues: targetFeatures,
        mutation,
    };
};
