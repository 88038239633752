import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faShapes } from '@fortawesome/pro-regular-svg-icons';
import { LayoutContent } from '~/common/components';
import { Nothing, Table, useBackendTable } from '~/common/kits/table';
import { CUSTOMERS_URL, useCustomersList, useInit } from '~/customers/hooks';
import { Header } from './Header';
import { tableConfig } from './table-config';
const getCustomerLink = (row) => {
    const base = `${CUSTOMERS_URL}/${row.owner_id}`;
    return row.owner_id !== row.id ? `${base}/members?filter[search]=${row.email}` : base;
};
export const CustomersList = () => {
    const init = useInit();
    const customers = useCustomersList();
    const view = useBackendTable({
        data: customers.data,
        tableConfig,
        isFetching: customers.isFetching,
    });
    if (!customers.data || !init.data || !view) {
        return _jsx(LayoutContent, { loading: true });
    }
    return (_jsxs(LayoutContent, { children: [_jsx(Header, { tableFilters: init.data.filter_options, customersCount: customers.data.metadata.total }), _jsx(Table, { ...view, noDataPlaceholder: _jsx(Nothing, { icon: faShapes }), linkGetter: getCustomerLink })] }));
};
