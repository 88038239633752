import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faCalendarDay, faList, faUserTag } from '@fortawesome/pro-regular-svg-icons';
import { faChevronLeft, faChevronRight, faTag } from '@fortawesome/pro-solid-svg-icons';
import { Button, Datepicker, IconBox, LayoutHeader, LayoutTitle, Pattern, Popover, Select, } from '~/common/components';
import { cx, formatDayFullMonthYear, getDateWithoutTime, moveDate, periods } from '~/common/utils';
import { useShiftsContext } from '../context';
import { useInitData } from '../hooks';
const TypeItem = ({ className, label, children, }) => (_jsxs("div", { className: "flex items-center", children: [_jsx("div", { className: cx('h-3 w-4 def:border border-solid rounded-2xl overflow-hidden', className), children: children }), _jsx("div", { className: "ml-1 whitespace-nowrap", children: label })] }));
const LeaveItem = ({ sign, label }) => (_jsxs("div", { className: "flex items-center h-[20px]", children: [_jsx("div", { className: "font-brand-t4b text-danger-400 w-[22px]", children: sign }), _jsx("div", { className: "ml-1", children: label })] }));
const Heading = ({ children }) => (_jsx("h4", { className: "font-brand-t5 mb-[4px] text-greyscale-600", children: children }));
const IconWithLabel = ({ icon, label }) => (_jsxs("div", { className: "flex items-center h-[20px] col-start-2", children: [_jsx(IconBox, { size: "s", icon: icon }), _jsx("div", { className: "ml-[4px]", children: label })] }));
const Legend = () => {
    const init = useInitData();
    return (_jsxs(_Fragment, { children: [_jsx("h3", { className: "font-brand-t2m mb-[12px] text-greyscale-600", children: "Legend" }), _jsx(Heading, { children: "Shifts" }), _jsxs("div", { className: "grid grid-cols-2 gap-y-1 gap-x-3", children: [_jsx(TypeItem, { className: "bg-secondary-50 border-secondary-200", label: "Morning shifts" }), _jsx(TypeItem, { className: "bg-info-25 border-info-200", label: "Night shifts" }), _jsx(TypeItem, { className: "bg-success-50 border-success-100", label: "Day shifts" }), _jsx(TypeItem, { className: "bg-danger-50 border-danger-300", label: "Leaves" }), _jsx(TypeItem, { className: "bg-warning-50 border-warning-300", label: "Evening shifts" }), _jsx(TypeItem, { className: "bg-greyscale-50 border-greyscale-200", label: "Unavailable period", children: _jsx(Pattern, { className: "text-greyscale-200" }) })] }), _jsx("hr", { className: "border-t-0 border-b border-solid border-greyscale-200 my-2" }), _jsx(Heading, { children: "Leaves" }), _jsx("div", { className: "grid grid-cols-2 gap-y-1 gap-x-3", children: init.leaves.map((leave) => (_jsx(LeaveItem, { sign: leave.sign, label: leave.leave_type }, leave.sign))) }), _jsx("hr", { className: "border-t-0 border-b border-solid border-greyscale-200 my-2" }), _jsx(Heading, { children: "Other" }), _jsxs("div", { className: "grid grid-cols-2 gap-x-3", children: [_jsx(TypeItem, { className: "bg-greyscale-50 border-greyscale-300", label: "Your schedule" }), _jsx(IconWithLabel, { icon: faTag, label: "DM of the team" }), _jsx(IconWithLabel, { icon: faUserTag, label: "Temporary DM" })] })] }));
};
export const Header = () => {
    const { date, setDate, period, setPeriod, setOffice, officeId } = useShiftsContext();
    const { offices } = useInitData();
    const setToday = () => setDate(getDateWithoutTime());
    const chosenPeriod = (forward = false) => moveDate(date, period, forward);
    const handlePrev = () => setDate(chosenPeriod());
    const handleNext = () => setDate(chosenPeriod(true));
    return (_jsx(LayoutHeader, { children: _jsxs("div", { className: "flex items-center justify-between", children: [_jsx("div", { className: "flex items-baseline", children: _jsx(LayoutTitle, { children: "Shifts schedule" }) }), _jsxs("div", { className: "ml-auto flex items-center gap-2", children: [_jsx(Button.Icon, { icon: faChevronLeft, size: "m", color: "secondary", onClick: handlePrev }), _jsx(Button.Icon, { icon: faChevronRight, size: "m", color: "secondary", onClick: handleNext, className: "-ml-2" }), _jsx(Button, { color: "grey", onClick: setToday, children: "Today" }), _jsx(Select, { className: "w-13 font-brand-t4m", value: period, onChange: setPeriod, options: periods, theme: "dark", size: "small", noSearch: true }), _jsx(Popover, { trigger: (props) => (_jsx(Button, { icon: faCalendarDay, color: "grey", ...props, children: formatDayFullMonthYear(date) })), content: ({ onClose }) => (_jsx(Datepicker, { value: date, onChange: (date) => {
                                    setDate(date);
                                    onClose();
                                } })) }), _jsx(Select, { className: "w-23 font-brand-t4m", options: offices, value: officeId, onChange: setOffice, theme: "dark", size: "small", noSearch: true }), _jsx(Popover, { trigger: (props) => (_jsx(Button, { color: "grey", icon: faList, ...props, children: "Legend" })), className: "h-[436px] w-[384px] font-brand-b2r py-[12px] px-2", content: () => _jsx(Legend, {}) })] })] }) }));
};
