import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { faCloudUpload } from '@fortawesome/pro-solid-svg-icons';
import { useEffect, useRef } from 'react';
import { Errors, FileInput, IconBox } from '~/common/components';
import { cx } from '~/common/utils';
import { useFilesState } from '~/orders/hooks';
import { mimeTypes } from '~/orders/utils';
import { Files } from './Files';
import styles from './Chat.module.scss';
const MAX_FILES = 5;
export const FilesUploadField = ({ title, errors, touched, children, disabled, className, value, maxFilesAmount = MAX_FILES, onChange, onBlur, ...props }) => {
    const filesIds = useRef(value);
    const [files, action] = useFilesState(maxFilesAmount);
    useEffect(() => {
        filesIds.current = value;
    }, [value]);
    const maxUploadedFilesAmountReached = files.length === maxFilesAmount;
    const isFileInputDisabled = disabled || maxUploadedFilesAmountReached;
    const onFileRemove = (file, uploadedFileId) => {
        action.removeFile(file);
        onChange(value.filter((fileId) => fileId !== uploadedFileId));
    };
    const onFileUpload = (uploadedFileId) => {
        onChange([...filesIds.current, uploadedFileId]);
    };
    return (_jsxs("div", { className: "flex flex-col", children: [title && _jsx("label", { className: "font-brand-b1 mb-[4px]", children: title }), _jsx(FileInput, { accept: mimeTypes.message.accept, labelClassName: cx(styles.uploadFile, isFileInputDisabled ? 'cursor-not-allowed' : 'cursor-pointer'), disabled: isFileInputDisabled, dragClassName: styles.dragging, multiple: true, onChoose: action.addFiles, ...props, children: (isDragging) => {
                    const textColorClassName = isDragging ? 'text-secondary-300' : 'text-greyscale-500';
                    return (_jsxs(_Fragment, { children: [_jsx(IconBox, { size: "l", className: textColorClassName, icon: faCloudUpload }), _jsx("span", { className: cx('text-center', textColorClassName), children: maxUploadedFilesAmountReached ? (_jsxs(_Fragment, { children: ["You've already uploaded ", maxFilesAmount, " files.", _jsx("br", {}), "If you want to add more, gather them", _jsx("br", {}), "in an archive or add a new note"] })) : ('Choose files') })] }));
                } }), _jsxs("span", { className: "font-brand-c1r text-greyscale-400 mt-1 mb-2", children: ["Upload up to ", maxFilesAmount, " files"] }), errors && _jsx(Errors, { color: true, errors: errors }), !!files.length && (_jsxs("p", { className: "font-brand-b1 mb-[4px]", children: ["Uploaded ", value.length, value.length < files.length && (_jsxs("span", { className: "text-greyscale-300", children: ["/", files.length] })), ' ', "files"] })), !!files.length && _jsx(Files, { files: files, onRemove: onFileRemove, onUpload: onFileUpload })] }));
};
