import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AppGridLayout, GlobalTooltip, LayoutTopHeader, Navigation, ThemeLoader, TopLoadingBar, } from '~/common/components';
import { TESTING } from '~/env';
import { SettingsPage as Settings } from '~/settings';
import { Invite, Login, useCurrentUser } from './Auth';
import { Effects } from './Effects';
import { Prompt } from './prompt';
import { NoPermissions, NotFound } from './SystemPages';
import { UpdateAvailable } from './UpdateAvailable';
import { PHOTO_NAGGING_ELIGIBLE_ROLES, UploadPhoto } from './UploadPhoto';
export const Root = ({ modules }) => {
    const { data: currentUser, isLoading } = useCurrentUser();
    const { navs, moduleList } = useMemo(() => {
        if (!currentUser) {
            return { navs: null, moduleList: null };
        }
        const moduleList = modules.map((module) => ({
            ...module,
            canShow: module.canShow(currentUser.permissions),
        }));
        return {
            moduleList,
            navs: moduleList.filter((module) => module.canShow && module.nav).map(({ nav }) => nav),
        };
    }, [currentUser, modules]);
    const username = currentUser === null || currentUser === void 0 ? void 0 : currentUser.name;
    const defaultRedirectTo = useMemo(() => {
        var _a, _b;
        return moduleList
            ? (_b = (_a = moduleList.find(({ canShow }) => canShow)) === null || _a === void 0 ? void 0 : _a.route.path) !== null && _b !== void 0 ? _b : '/orders'
            : '/login';
    }, [moduleList]);
    if (isLoading) {
        return _jsx(ThemeLoader, {});
    }
    return (_jsx(Router, { children: _jsxs(AppGridLayout, { children: [_jsx(TopLoadingBar, {}), _jsx(LayoutTopHeader, { user: currentUser }), _jsx(Navigation, { navs: navs }), _jsxs(Switch, { children: [moduleList === null || moduleList === void 0 ? void 0 : moduleList.map(({ route, canShow }) => (_jsx(Route, { path: route.path, component: canShow ? route.component : NoPermissions }, route.path))), _jsx(Route, { path: "/login", children: _jsx(Login, { username: username }, currentUser === null || currentUser === void 0 ? void 0 : currentUser.uniqueId) }), currentUser && (_jsx(Route, { path: "/user/settings", children: _jsx(Settings, { user: currentUser }) })), _jsx(Route, { path: "/invite/:token", children: _jsx(Invite, {}) }), _jsx(Route, { path: "/404", children: _jsx(NotFound, {}) }), _jsx(Redirect, { to: defaultRedirectTo })] }), _jsx(GlobalTooltip, {}), _jsx(Prompt, {}), _jsx(ToastContainer, {}), _jsx(UpdateAvailable, {}), !TESTING && currentUser && PHOTO_NAGGING_ELIGIBLE_ROLES.includes(currentUser.role) && (_jsx(UploadPhoto, {})), _jsx(Effects, { userId: currentUser === null || currentUser === void 0 ? void 0 : currentUser.id })] }) }));
};
