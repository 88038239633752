import { useCallback, useEffect, useRef, useState } from 'react';
export function useIsVisible(ref) {
    const [isVisible, setIsVisible] = useState(false);
    const handler = useCallback((entries, observer) => {
        entries.forEach((entry) => {
            setIsVisible(entry.isIntersecting);
            if (entry.isIntersecting)
                observer.unobserve(entry.target);
        });
    }, []);
    const observer = useRef(new IntersectionObserver(handler, { threshold: 1 }));
    useEffect(() => {
        const element = ref.current;
        const observerInstance = observer.current;
        if (element)
            observerInstance.observe(element);
        return () => {
            if (element)
                observerInstance.unobserve(element);
        };
    }, [ref]);
    return isVisible;
}
