import { z } from 'zod';
import { Currency } from '~/root';
export const ProductPrice = z.object({
    unit: z.number(),
    discount: z.number(),
    vat_percentage: z.number(),
    vat_unit: z.number(),
    currency: Currency,
    amount: z.number(),
    unit_per_day: z.number().nullable(),
    remaining_billing_cycle_days: z.number().nullable(),
});
export const InvoicePreviewUrl = z.object({
    invoice_url: z.string(),
});
