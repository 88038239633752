import { useCallback, useState } from 'react';
import { useCalculateProductPriceMutation } from '~/customers/hooks';
export const useProductPrice = (owner_id) => {
    const [price, setPrice] = useState();
    const calculateProductPriceMutation = useCalculateProductPriceMutation();
    const calculate = useCallback(async (data) => calculateProductPriceMutation.mutateAsync({ data, id: owner_id }).then((newPrice) => {
        setPrice(newPrice);
        return newPrice;
    }), [calculateProductPriceMutation, owner_id]);
    const reset = () => {
        setPrice(undefined);
    };
    return {
        price,
        isLoading: calculateProductPriceMutation.isLoading,
        calculate,
        reset,
    };
};
