import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faCheckSquare, faCommentAltDots, faSquare, faTrashAlt, } from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';
import { Button, IconBox, ImageLoader, Show } from '~/common/components';
import { cx, ignoreHandled } from '~/common/utils';
import { isSlide } from '~/orders/hooks';
import { CheckBox } from '~/orders/Order';
import css from './Previews.module.scss';
const renderIcon = ({ checked }) => {
    if (checked) {
        return _jsx(IconBox, { size: "s", className: "text-primary-300", icon: faCheckSquare });
    }
    return _jsx(IconBox, { size: "s", icon: faSquare });
};
export const PreviewItem = ({ editing, index, isActive, isSelected, isDragging, isDraggedOver, isSnapshot, onChoose, onRemove, onToggle, preview, }) => {
    const [showRemoveButton, setShowRemoveButton] = useState(false);
    return (_jsxs("div", { className: "flex justify-between", children: [_jsxs("div", { className: "flex flex-col justify-between pt-[4px] w-4 min-w-[32px]", children: [_jsx("span", { className: cx('font-brand-b2r text-center', isActive ? 'text-greyscale-300' : 'text-greyscale-400'), children: index + 1 }), editing && (
                    //TODO Maybe we can use standart checkbox here?
                    _jsx(CheckBox, { className: "text-[14px] h-4 w-4 justify-center", checked: isSelected, onChange: onToggle, renderIcon: renderIcon }))] }), _jsxs("div", { className: cx(css.previewItem, !isSnapshot && !isDragging && isActive && css.active, isSnapshot && 'bg-transparent z-10 [&]:after:border-none'), onClick: ignoreHandled(onChoose), onMouseEnter: () => !isDragging && setShowRemoveButton(true), onMouseLeave: () => !isDragging && setShowRemoveButton(false), draggable: true, children: [_jsx(ImageLoader, { alt: preview.name, src: preview.link, style: { opacity: isDraggedOver ? 0.35 : 1 }, className: cx('h-full before:mt-0 w-full', isSnapshot && ''), draggable: false }), _jsxs(Show, { if: !isDragging, children: [_jsx(Show, { if: editing && showRemoveButton, children: _jsx(Button.Icon, { "data-stop-propagation": true, icon: faTrashAlt, onClick: onRemove, color: "grey-outline", className: "absolute top-[4px] right-[4px] hover:text-danger-400 hover:border-danger-100 text-[12px]" }) }), _jsx(Show, { if: isSlide(preview) && preview.annotations.length > 0 && (!editing || !showRemoveButton), children: _jsx(IconBox, { className: css.annotationsIcon, icon: faCommentAltDots }) })] })] })] }));
};
