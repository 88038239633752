import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { encodeQueryString, parseQuery } from '../utils';
export const useParsedQuery = () => {
    const { search } = useLocation();
    const history = useHistory();
    const queryParams = useMemo(() => parseQuery(search), [search]);
    const setQueryParams = (callback) => {
        const updatedQuery = callback(queryParams);
        history.push({ search: encodeQueryString(updatedQuery) });
    };
    const onFilterChange = (name, value) => {
        setQueryParams((params) => ({
            ...params,
            page: 1,
            filter: { ...params.filter, [name]: value },
        }));
    };
    const updateFilters = (filterUpdater) => {
        setQueryParams((params) => ({
            ...params,
            page: 1,
            filter: filterUpdater(params.filter),
        }));
    };
    return { queryParams, setQueryParams, onFilterChange, updateFilters };
};
