import ImageBlobReduce from 'image-blob-reduce';
export const cropImage = async (src, file, { sourceX = 0, sourceY = 0, sourceWidth, sourceHeight }) => {
    const originalImage = new Image();
    originalImage.src = src;
    return new Promise((resolve) => {
        const canvas = document.createElement('canvas');
        canvas.width = sourceWidth;
        canvas.height = sourceHeight;
        const ctx = canvas.getContext('2d');
        ctx === null || ctx === void 0 ? void 0 : ctx.drawImage(originalImage, sourceX, sourceY, sourceWidth, sourceHeight, 0, 0, sourceWidth, sourceHeight);
        canvas.toBlob((blob) => {
            if (blob) {
                resolve(new File([blob], file.name, {
                    type: file.type,
                    lastModified: file.lastModified,
                }));
            }
        });
    });
};
export const resizeImage = async (file, options) => {
    const reducer = new ImageBlobReduce();
    const dest = await reducer.toBlob(file, options);
    return new File([dest], file.name, {
        lastModified: file.lastModified,
        type: file.type,
    });
};
export const getDimensionsFromFile = (file) => {
    return new Promise((resolve) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
            resolve({ width: img.naturalWidth, height: img.naturalHeight });
            URL.revokeObjectURL(img.src);
        };
    });
};
export const getImageElementFromSrc = (src) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        const load = () => {
            resolve(img);
            img.removeEventListener('error', error);
        };
        const error = () => {
            reject(img);
            img.removeEventListener('load', load);
        };
        img.addEventListener('load', load, { once: true });
        img.addEventListener('error', error, { once: true });
    });
};
