import { Toast } from '~/common/components';
import { getEcho } from '~/common/kits/socket';
import { decodeOrNull, FlashMessage, httpClient } from '~/common/utils';
import { LoginResponse, OauthData } from './domain';
const openOauthWindow = (url) => {
    if (!window.top) {
        throw new Error('No window.top');
    }
    const windowWidth = 450;
    const windowHeight = 600;
    const posX = window.top.outerWidth / 2 + window.top.screenX - windowWidth / 2;
    const posY = window.top.outerHeight / 2 + window.top.screenY - windowHeight / 2;
    return window.open(url, 'provider', `width=${windowWidth}, height=${windowHeight}, left=${posX}, top=${posY}`);
};
export const loginWithGoogle = (oauthData) => {
    return new Promise(async (resolve, reject) => {
        const response = oauthData !== null && oauthData !== void 0 ? oauthData : (await httpClient.get('/v1/oauth/login/google', {
            decoder: OauthData,
        }));
        let echo = getEcho();
        const leave = () => {
            echo === null || echo === void 0 ? void 0 : echo.disconnect();
            echo = undefined;
        };
        echo.listen(response.channel, '.authenticated', (data) => {
            leave();
            const loginResponse = decodeOrNull(data, LoginResponse);
            if (loginResponse) {
                resolve(loginResponse);
            }
            else {
                reject(data);
            }
        });
        echo.listen(response.channel, '.notification', (data) => {
            leave();
            const flash = decodeOrNull(data, FlashMessage);
            if (flash) {
                Toast[flash.type]({ title: flash.message });
            }
            reject(data);
        });
        const oauthWindow = openOauthWindow(response.redirectUrl);
        const check = setInterval(() => {
            if (oauthWindow === null || oauthWindow === void 0 ? void 0 : oauthWindow.closed) {
                clearInterval(check);
                // it's possible that auth ws message could arrive after oauth window
                // was closed, so let's wait for that case
                setTimeout(() => {
                    leave();
                    reject(new Error('oauth window was closed before auth attempt'));
                }, 5000);
            }
        }, 500);
    });
};
