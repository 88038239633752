import { nonNullable } from '~/common/utils';
import { useRevisionsContext } from '~/orders/Order';
export const useAnnotationInfo = () => {
    const { revisionSelect, previewsState } = useRevisionsContext();
    // this component won't spawn unless we have chosenPreview available
    return {
        slideId: nonNullable(previewsState.selectedPreviewId),
        revisionId: nonNullable(revisionSelect.value),
    };
};
