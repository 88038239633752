import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faUsers } from '@fortawesome/pro-regular-svg-icons';
import { BackButton, LayoutContent, LayoutHeader, LayoutTitle, LayoutTwinPane, Menu, NotFound, useModalContext, withModalContext, } from '~/common/components';
import { is404 } from '~/common/utils';
import { useCurrentUserData } from '~/root';
import { TEAMS_URL, useInit, useTeam } from '../hooks';
import { DeletePrompt } from './Delete';
import { Members } from './Members';
import { TeamInfo } from './TeamInfo';
export const Team = withModalContext(() => {
    const team = useTeam();
    const init = useInit();
    const { permissions } = useCurrentUserData();
    const { modalOpener } = useModalContext();
    if (is404(team.error)) {
        return _jsx(NotFound, { icon: faUsers, text: "There's no team with this id", safeLocation: TEAMS_URL });
    }
    if (!team.data || !init.data) {
        return _jsx(LayoutContent, { loading: true });
    }
    return (_jsxs(LayoutContent, { children: [_jsxs(LayoutHeader, { className: "flex justify-between items-center gap-2", children: [_jsx(BackButton, { to: TEAMS_URL }), _jsx(LayoutTitle, { className: "-ml-2 mr-auto", children: "Team profile" }), permissions.designTeamsDelete && team.data.designers.length === 0 && (_jsx(Menu, { children: _jsx(Menu.Item, { onClick: modalOpener(DeletePrompt), children: "Delete team" }) }))] }), _jsx(LayoutTwinPane, { side: _jsx(TeamInfo, {}), main: _jsx(Members, {}) })] }));
});
