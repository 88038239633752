import { jsx as _jsx } from "react/jsx-runtime";
import { Errors, Tooltip } from '~/common/components';
import { isNonNullable } from '~/common/utils';
import { InlineInput } from './InlineInput';
export const InlineInputField = ({ errors, onChange, touched, type, value, ...props }) => {
    const handleChange = (event) => {
        switch (type) {
            case 'text':
                return onChange(event.currentTarget.value);
            case 'number':
                const value = event.currentTarget.valueAsNumber;
                return onChange(Number.isNaN(value) ? null : value);
        }
    };
    return (_jsx(Tooltip, { color: "danger", content: errors && _jsx(Errors, { errors: errors }), children: _jsx(InlineInput, { ...props, onChange: handleChange, type: type, value: isNonNullable(value) ? value : '', error: errors !== null }) }));
};
