import { validator } from 'formoid';
import { isLink, isInteger, isNonBlankString, isNonEmptyString, isNonNegativeInteger, isNonNegativeNumber, isPositiveInteger, isPositiveNumber, isEmail, } from '~/common/utils';
export function required(message) {
    return validator.defined(message || 'This field is required');
}
export function nonNegativeNumber(message) {
    return validator.fromPredicate(isNonNegativeNumber, message || 'Please enter 0 or a bigger number');
}
export function positiveNumber(message) {
    return validator.fromPredicate(isPositiveNumber, message || 'Please enter a number bigger than 0');
}
export function integer(message) {
    return validator.fromPredicate(isInteger, message || 'Please enter a whole number');
}
export function nonNegativeInteger(message) {
    return validator.fromPredicate(isNonNegativeInteger, message || 'Please enter 0 or a bigger number');
}
export function positiveInteger(message) {
    return validator.fromPredicate(isPositiveInteger, message || 'Please enter a number bigger than 0');
}
export function nonEmptyString(message) {
    return validator.fromPredicate(isNonEmptyString, message || 'Please fill out this field');
}
export function nonBlankString(message) {
    return validator.fromPredicate(isNonBlankString, message || 'Please fill out this field');
}
export function link(message) {
    return validator.fromPredicate(isLink, message || 'Please enter a link');
}
export function email(message) {
    return validator.fromPredicate(isEmail, message || 'Please enter a valid email');
}
export const bounty = (bountyType) => {
    switch (bountyType) {
        case 'percent':
            return validator.sequence(validator.max(100, 'Value must be less than or equal to 100!'), nonNegativeInteger());
        case 'absolute':
            return validator.sequence(validator.max(10000, 'Value must be less than or equal to 10000!'), nonNegativeNumber());
    }
};
