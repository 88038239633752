import { useLayoutEffect } from 'react';
import { mountCompareContainer, transferNecessaryStyles, unmountCompareContainer, } from './compareContainer';
import { mountGlobalTooltipPortal, unmountGlobalTooltipPortal, mountOrUpdateGlobalTooltip, unmountGlobalTooltip, } from './utils';
/*Mount it anywhere globally to have a tooltip on any element that has data-tt attribute or truncate class
 *You can add data-tt-placement attribute to the element to change the placement of the tooltip
 */
export const GlobalTooltip = () => {
    useLayoutEffect(() => {
        const compareContainer = mountCompareContainer();
        mountGlobalTooltipPortal();
        const mouseEnterEventHandler = ({ target, relatedTarget }) => {
            var _a;
            if (compareContainer.innerHTML) {
                compareContainer.innerHTML = '';
            }
            if (!(target instanceof Element) || !(relatedTarget instanceof Element))
                return;
            const truncateTarget = target === null || target === void 0 ? void 0 : target.closest('.truncate');
            if (truncateTarget) {
                compareContainer.innerText = truncateTarget.textContent || '';
                transferNecessaryStyles(truncateTarget, compareContainer);
                if (truncateTarget.clientWidth < compareContainer.clientWidth) {
                    mountOrUpdateGlobalTooltip(truncateTarget);
                }
            }
            const previousTtTarget = relatedTarget === null || relatedTarget === void 0 ? void 0 : relatedTarget.closest('[data-tt]');
            const ttTarget = target === null || target === void 0 ? void 0 : target.closest('[data-tt]');
            if (ttTarget && ((_a = ttTarget.getAttribute('data-tt')) === null || _a === void 0 ? void 0 : _a.length)) {
                if (ttTarget === previousTtTarget)
                    return;
                return mountOrUpdateGlobalTooltip(ttTarget);
            }
        };
        const mouseLeaveEventHandler = ({ target, relatedTarget }) => {
            if (!(target instanceof Element) || !(relatedTarget instanceof Element))
                return;
            const truncateTarget = target === null || target === void 0 ? void 0 : target.closest('.truncate');
            if (truncateTarget) {
                if (truncateTarget.clientWidth < compareContainer.clientWidth) {
                    unmountGlobalTooltip(truncateTarget);
                }
            }
            const ttTarget = target === null || target === void 0 ? void 0 : target.closest('[data-tt]');
            const previousTtTarget = relatedTarget === null || relatedTarget === void 0 ? void 0 : relatedTarget.closest('[data-tt]');
            if (ttTarget && previousTtTarget && ttTarget === previousTtTarget)
                return;
            if (ttTarget) {
                return unmountGlobalTooltip(ttTarget);
            }
        };
        document.addEventListener('mouseover', mouseEnterEventHandler, true);
        document.addEventListener('mouseout', mouseLeaveEventHandler);
        return () => {
            unmountGlobalTooltipPortal();
            unmountCompareContainer();
            document.removeEventListener('mouseover', mouseEnterEventHandler);
            document.removeEventListener('mouseout', mouseLeaveEventHandler);
        };
    }, []);
    return null;
};
