import { z } from 'zod';
import { DateFromIsoString } from '~/common/utils';
import { Metadata, NumericIdNamePair } from '~/root';
export const Status = z.object({
    id: z.number().int().nonnegative(),
    name: z.string(),
    color: z.enum(['success', 'warning', 'danger']),
});
export const PaymentBase = z.object({
    id: z.number().int().positive(),
    description: z.string(),
    created_at: DateFromIsoString,
    status: Status,
    amount: z.string(),
    credit_balance: z.string(),
    invoice_url: z.string().nullable(),
    invoice_number: z.string().nullable(),
});
export const PaymentExtra = z.object({
    payment_method: NumericIdNamePair,
    credits: z.number(),
});
export const Payment = PaymentBase.merge(PaymentExtra);
export const Payments = z.object({
    items: z.array(Payment),
    metadata: Metadata,
});
export const PaymentsInit = z.object({
    filter_options: z
        .object({
        payment_methods: z.array(NumericIdNamePair),
    })
        .transform(({ payment_methods }) => ({ payment_method: payment_methods })),
});
