import { useMemo } from 'react';
import { formatDateDistance, getOptionLabel } from '~/common/utils';
import { useInitData, useOrderData, useSortedOrderCountries } from '~/orders/hooks';
import { getTransitions, isDeadlineExpired } from '~/orders/utils';
export function useInformationBar() {
    var _a;
    const order = useOrderData();
    const init = useInitData();
    const relativeDeadline = useMemo(() => formatDateDistance(order.deadline), [order.deadline]);
    const deadlineExpired = isDeadlineExpired(order.deadline);
    const designTeam = useMemo(() => {
        var _a;
        return ({
            name: getOptionLabel(init.teams, (_a = order.team) === null || _a === void 0 ? void 0 : _a.id),
            tagged: order.tag_teams.map((tagTeam) => getOptionLabel(init.teams, tagTeam.id)),
        });
    }, [init.teams, (_a = order.team) === null || _a === void 0 ? void 0 : _a.id, order.tag_teams]);
    const customer = order.customer;
    const slides = order.slides;
    const customName = order.title;
    const manager = useMemo(() => getOptionLabel(init.managers, order.pm), [init.managers, order.pm]);
    const countries = useSortedOrderCountries();
    const statusLabel = useMemo(() => getOptionLabel(init.statuses.order, order.status), [init.statuses.order, order.status]);
    const transitions = useMemo(() => getTransitions(init.statuses.order, order.status), [init.statuses.order, order.status]);
    return {
        deadline: {
            absolute: order.deadline,
            expired: deadlineExpired,
            relative: relativeDeadline,
        },
        designTeam,
        iterations: order.iterations,
        order: {
            slides,
            customName,
            status: order.status,
            statusLabel,
            paymentStatus: order.payment.status,
            cancellationReason: order.cancellation_reason,
        },
        customer,
        manager,
        countries,
        transitions,
    };
}
