import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import { memo } from 'react';
import { Link } from '~/common/components';
import { formatDate } from '~/common/utils';
const searchFor = '://';
const dots = '...';
const MAX_LINK_SYMBOLS = 50;
const truncate = (link, maxSymbols = MAX_LINK_SYMBOLS) => {
    const indexOfProtocolEnd = link.indexOf(searchFor);
    const isProtocolFound = indexOfProtocolEnd !== -1;
    const protocolLength = isProtocolFound ? indexOfProtocolEnd + searchFor.length : 0;
    const linkLength = link.length - protocolLength;
    const truncateLink = linkLength > maxSymbols;
    const truncatedLink = link.slice(protocolLength, truncateLink ? protocolLength + (maxSymbols - dots.length) : link.length);
    return truncateLink ? truncatedLink + dots : truncatedLink;
};
//TODO The only prop used in the project is link, maybe we need to remove the others?
export const LinkItem = memo(({ icon = faLink, date, link, maxLength }) => {
    const truncatedLink = truncate(link, maxLength);
    return (_jsxs(Link, { className: "justify-start", size: "m-bold", icon: icon, href: link, external: true, newTab: true, children: [truncatedLink, date && _jsx("span", { className: "font-brand-b1 text-greyscale-500", children: formatDate(date) })] }));
});
