import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useIdParam, useVisibleElements } from '~/common/hooks';
import { httpClient } from '~/common/utils';
import { useOrderData, useUpdateOrderCache } from '~/orders/hooks';
import { qk } from '~/root/query-keys';
const useGetMessagePages = () => {
    const id = useIdParam();
    const client = useQueryClient();
    return useCallback(() => {
        var _a;
        return (_a = client.getQueryData([
            'orders',
            'item',
            { id },
            'collaboration',
            'messages',
        ])) === null || _a === void 0 ? void 0 : _a.pages;
    }, [client, id]);
};
export const useReadMessage = () => {
    const id = useIdParam();
    const updateOrder = useUpdateOrderCache();
    const getMessagePages = useGetMessagePages();
    return useMutation({
        mutationKey: qk.collaborationReadMessage(id),
        mutationFn: (messageId) => {
            return httpClient
                .post(`/v1/staff/orders/${id}/collaboration/comments/${messageId}/read`)
                .then(() => messageId);
        },
        onSuccess: (messageId) => {
            var _a;
            const messageDate = (_a = getMessagePages()) === null || _a === void 0 ? void 0 : _a.reduce((_messageDate, page) => { var _a; return (_a = page.items.get(messageId)) === null || _a === void 0 ? void 0 : _a.date; }, undefined);
            updateOrder((order) => ({
                ...order,
                collaboration: {
                    ...order.collaboration,
                    last_read_comment_id: messageId,
                    last_read_at: messageDate || null,
                    // TODO if we're going to consider the case where we snapshot last
                    // visible message since we started scrolling top for future reading
                    // this should be changed
                    unread_comments_count: 0,
                },
            }));
        },
    });
};
const OBSERVER_OPTIONS = {
    threshold: 1,
};
// since we're marking all messages that came before as read we need to
// make sure user has all the time in the world to scroll up and see them
//
// because of this, we'll wait until user has scrolled up enough to see
// messages that he has read already
//
// after that's done, we'll wait until he's at the bottom again to begin
// debounced read of all messages
export const useAutoReadMessages = (containerRef) => {
    const getMessagePages = useGetMessagePages();
    const [readUnblocked, setReadUnblocked] = useState(false);
    const [reachedBottom, setReachedBottom] = useState(true);
    const { mutate } = useReadMessage();
    const lastReadMessageId = useOrderData().collaboration.last_read_comment_id;
    const shouldReadMessages = readUnblocked && reachedBottom;
    useVisibleElements({
        containerRef,
        onVisibleChange: (elements) => {
            var _a;
            // TODO explore the case when user has left the tab and there's a wall of unread messages
            // we'd need to toggle this thing again
            //
            // consider ws chat events to block auto-reading again?
            //
            // TODO we should prevent reading messages with a fresh state until we're
            // scrolled to the top and there's no more message pages available
            if (((_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.scrollTop) === 0 ||
                elements.some((element) => element.target.getAttribute('data-is-read'))) {
                setReadUnblocked(true);
            }
        },
        observerOptions: OBSERVER_OPTIONS,
    });
    useEffect(() => {
        const handler = (event) => {
            const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
            setReachedBottom(Math.abs(scrollHeight - scrollTop - clientHeight) < 1);
        };
        const container = containerRef.current;
        container === null || container === void 0 ? void 0 : container.addEventListener('scroll', handler);
        return () => container === null || container === void 0 ? void 0 : container.removeEventListener('scroll', handler);
    }, [containerRef]);
    useEffect(() => {
        const timeout = setTimeout(() => {
            const pages = getMessagePages();
            if (!shouldReadMessages || !pages) {
                return;
            }
            // TODO another reason storing messages in maps is not a good idea
            let lastMessage = null;
            let lastReadMessage = null;
            for (const { items } of pages) {
                for (const message of items.values()) {
                    if (lastMessage === null || lastMessage.date <= message.date) {
                        lastMessage = message;
                    }
                    if (lastReadMessageId === message.id) {
                        lastReadMessage = message;
                    }
                }
            }
            if (lastMessage && lastMessage.date.getTime() > ((lastReadMessage === null || lastReadMessage === void 0 ? void 0 : lastReadMessage.date.getTime()) || 0)) {
                // TODO consider reading actual last seen message
                // meaning when user starts to scroll up to read a ton of stuff for some time,
                // store the id of the last message at the bottom
                //
                // maybe we're fine though :shrug:
                mutate(lastMessage.id);
            }
        }, 3000);
        return () => clearTimeout(timeout);
    }, [getMessagePages, lastReadMessageId, mutate, shouldReadMessages]);
    return containerRef;
};
