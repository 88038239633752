import { useEffect, useRef } from 'react';
export const useScrollIntoView = (condition) => {
    const ref = useRef(null);
    useEffect(() => {
        var _a;
        if (condition) {
            (_a = ref.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ block: 'center' });
        }
    }, [condition]);
    return ref;
};
