import { z } from 'zod';
import { InitFeatures } from '~/common/kits/features';
import { Treatment, TreatmentsTable } from '~/common/other';
import { DateFromIsoString, range } from '~/common/utils';
import { Manager, ProductOptions, RenewalPeriod, RetainerProductPlan, SubscriptionStatus, TechPackProductPlan, } from '~/customers/domain';
import { Currency, NumericIdNamePair, StringIdNamePair, getListDecoder } from '~/root';
export const TreatmentOption = z.record(z.record(z.number().nullable()));
export const FilterOptions = z
    .object({
    plan: z.object({
        retainer: z.array(RetainerProductPlan),
        techpack: z.array(TechPackProductPlan),
    }),
    status: z.array(SubscriptionStatus),
})
    .transform(({ plan, ...filters }) => ({
    ...filters,
    plan: { ...plan, all: Object.values(plan).flat() },
}));
export const Init = z
    .object({
    cancellation_reasons: z.array(StringIdNamePair),
    default_treatments: TreatmentsTable,
    design_teams: z.array(NumericIdNamePair),
    features: InitFeatures,
    filter_options: FilterOptions,
    product_options: z.object({
        subscription: ProductOptions,
        topup: ProductOptions,
    }),
    project_managers: z.array(Manager),
    sales: z.array(Manager),
    supported_currencies: z.array(Currency),
    treatments: z.array(Treatment),
    upload_window_timezones: z.array(StringIdNamePair),
})
    .transform(({ supported_currencies, filter_options, ...init }) => ({
    ...init,
    filter_options: {
        ...filter_options,
        pm: init.project_managers,
        sales: init.sales,
    },
    hoursList: range(0, 23).map((hour) => {
        const formattedHour = `${hour.toString().padStart(2, '0')}:00`;
        return { name: formattedHour, value: formattedHour };
    }),
    supported_currencies: supported_currencies.map((currency) => ({
        name: currency,
        value: currency,
    })),
}));
export const SubscriptionListItem = z.object({
    id: z.number(),
    owner_id: z.number(),
    customer_name: z.string(),
    company_name: z.string().nullable(),
    products: z.array(NumericIdNamePair.and(z.object({
        plan_id: z.number(),
    }))),
    monthly_cost: z.string().nullable(),
    // TODO check out why it's a string sometimes
    monthly_cost_usd: z.union([z.string(), z.number()]).transform((value) => +value),
    status: SubscriptionStatus.nullable(),
    renews_at: DateFromIsoString.nullable(),
    renewal_period: RenewalPeriod,
    updated_at: DateFromIsoString,
});
export const SubscriptionsList = getListDecoder(SubscriptionListItem);
