import { useQuery } from '@tanstack/react-query';
import { Activity } from '~/common/kits/activity';
import { getFlatQuery, useParsedQuery } from '~/common/kits/table';
import { httpClient } from '~/common/utils';
import { useCustomerParams } from '~/customers/Customer';
import { qk } from '~/root/query-keys';
export function useSubscriptionActivity() {
    const { queryParams } = useParsedQuery();
    const params = {
        ...getFlatQuery(queryParams),
        limit: 25,
    };
    const { subscription_id } = useCustomerParams();
    return useQuery({
        queryKey: [...qk.subscriptionActivity(subscription_id), params],
        queryFn: () => httpClient.get(`/v1/staff/subscriptions/${subscription_id}/activity`, {
            decoder: Activity,
            params,
        }),
        keepPreviousData: true,
    });
}
