import { jsx as _jsx } from "react/jsx-runtime";
import { format } from 'date-fns';
import { CountryFlagLabel, Ticker } from '~/common/components';
import { getTimezonedLocalTime } from '~/common/utils';
export const tableConfig = {
    columns: [
        { key: 'id', name: 'ID', width: '72px' },
        {
            key: 'country',
            name: 'Name',
            cellRenderer: ({ rowData }) => {
                var _a;
                return (_jsx(CountryFlagLabel, { country: (_a = rowData.name) !== null && _a !== void 0 ? _a : rowData.country.name, countryCode: rowData.country.country_code }));
            },
            getClassName: () => 'w-full',
        },
        { key: 'type', name: 'Type', getClassName: () => 'capitalize' },
        { key: 'working_hours', name: 'Working hours', width: '240px' },
        {
            key: 'timezone',
            name: 'Local time',
            cellRenderer: ({ cellData }) => (_jsx(Ticker, { callback: () => format(getTimezonedLocalTime(cellData), 'HH:mm, EEEE') })),
            width: '200px',
        },
    ],
    sortable: [],
};
