import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Flipper, Flipped } from 'react-flip-toolkit';
import { Button, DragDrop, Input, Radio } from '~/common/components';
export const DragDropStand = () => {
    const [items, setItems] = useState([
        { id: 1, name: 'Item 1' },
        { id: 2, name: 'Item 2' },
        { id: 3, name: 'Item 3' },
        { id: 4, name: 'Item 4' },
        { id: 5, name: 'Item 5' },
    ]);
    const [value, setValue] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [size, setSize] = useState('m');
    return (_jsxs("div", { className: "bg-greyscale-0 p-2 gap-2 rounded flex", children: [_jsx("div", { className: "flex flex-col gap-2", children: _jsxs("div", { className: "flex flex-col gap-2", children: [_jsxs("div", { className: "flex items-center gap-2 w-40", children: [_jsx(Input, { value: value, onChange: (e) => setValue(e.target.value) }), _jsx(Button, { onClick: () => setItems((prev) => [...prev, { name: value, id: Math.random() }]), children: "Add" })] }), _jsx("h1", { children: "Drag and drop " }), _jsxs("div", { className: "flex flex-col items-start gap-2", children: [_jsx(Button, { onClick: () => setDisabled((prev) => !prev), color: "grey", children: disabled ? 'Enable' : 'Disable' }), _jsxs("span", { className: "flex items-center gap-1", children: ["Size: ", _jsx(Radio, { title: "S", onChange: () => setSize('s'), value: size === 's' }), _jsx(Radio, { title: "M", onChange: () => setSize('m'), value: size === 'm', color: "" })] })] }), _jsx(DragDrop, { items: items, onChange: setItems, disabled: disabled, size: size, containerClassName: size === 's' ? 'space-y-[4px]' : 'space-y-1' })] }) }), _jsxs("div", { className: "mt-2", children: [_jsx("p", { children: "Keyboard navigation" }), _jsx("p", { children: "By pressing \"Tab\" you can select the item you want" }), _jsx("p", { children: "By holding \"Shift\" and pressing \"Arrow Up/Down\" you can reposition items" }), _jsx("p", { className: "mt-2", children: "By dragging item out of container you will cancel reordering" }), _jsx("hr", { className: "my-2" }), _jsx("p", { children: "Result:" }), _jsx(Flipper, { flipKey: items.map((item) => item.id).join(' '), children: _jsx("ul", { children: items.map((item) => (_jsx(Flipped, { flipId: item.id, children: _jsx("li", { children: item.name }) }, item.id))) }) })] })] }));
};
