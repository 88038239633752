import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm } from 'formoid';
import { InputField, SelectField } from '~/common/components';
import { customValidator } from '~/common/utils';
import { ButtonGroup } from '../ButtonGroup';
const discountTypeOptions = (currency) => [
    { name: '%', value: 'percent' },
    { name: currency, value: 'absolute' },
];
export const AdjustDiscount = ({ currency, discount, onApply, onClose }) => {
    const { fieldProps, handleSubmit } = useForm({
        initialValues: (discount || { type: 'percent', value: 0 }),
        validationStrategy: 'onBlur',
        validators: ({ type }) => ({
            value: customValidator.bounty(type),
            type: null,
        }),
    });
    const submit = () => handleSubmit((values) => Promise.resolve(onApply(values)).then(onClose));
    return (_jsxs("div", { className: "space-y-2", children: [_jsxs("div", { className: "space-y-1", children: [_jsx("p", { className: "font-normal text-greyscale-500 text-sm", children: "Discount" }), _jsxs("div", { className: "flex items-start space-x-1", children: [_jsx("div", { className: "w-8/12", children: _jsx(InputField, { ...fieldProps('value'), min: 0, max: 100, textAlignment: "center", type: "number" }) }), _jsx("div", { className: "w-4/12", children: _jsx(SelectField, { ...fieldProps('type'), noSearch: true, options: discountTypeOptions(currency), textAlignment: "center" }) })] })] }), _jsx(ButtonGroup, { onApply: submit, onCancel: onClose })] }));
};
