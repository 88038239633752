import { jsx as _jsx } from "react/jsx-runtime";
import { cx } from '~/common/utils';
import css from './ProgressBar.module.scss';
const DEFAULT_ANIMATION_SPEED = 500; // ms
export const ProgressBar = ({ className, progress, progressColor = 'secondary', backgroundColor = 'grey', animationSpeed = DEFAULT_ANIMATION_SPEED, max = 100, }) => {
    return (_jsx("div", { className: cx(css.progressContainer, className, css[`bg-${backgroundColor}`]), children: _jsx("div", { className: cx(css.progress, css[progressColor]), style: {
                width: `${(Number(progress) * 100) / Number(max)}%`,
                transition: `width ${animationSpeed}ms ease-in-out`,
            } }) }));
};
