import { jsx as _jsx } from "react/jsx-runtime";
import { faArrowAltToBottom } from '@fortawesome/pro-regular-svg-icons';
import { Link } from '~/common/components';
import { useIdParam } from '~/common/hooks';
import { SERVER_URL } from '~/env';
export const DownloadButton = ({ commentId, children }) => {
    const orderId = useIdParam();
    const orderFilesLink = `${SERVER_URL}/v1/staff/orders/${orderId}/comments/${commentId}/files`;
    return (_jsx(Link.Button, { size: "small", download: true, href: orderFilesLink, icon: faArrowAltToBottom, children: children }));
};
