import { createStore } from 'zustand';
/**
 *
 * The purpose of this store is to reactively connect some global-scope
 * optional dependencies like sentry or openreplay to the logged in user,
 * which sits in react-land, without polluting react-land with optional deps */
export const userStore = createStore()(() => null);
/**
 *
 * The purpose of this store is to reactively connect some global-scope
 * optional dependencies like posthog to location, because for some reason
 * there's no native API for that */
export const locationStore = createStore()(() => window.location);
