import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faBriefcase, faEnvelope, faInfoCircle, faPhoneAlt, faUserFriends, } from '@fortawesome/pro-regular-svg-icons';
import { faTasks } from '@fortawesome/pro-solid-svg-icons';
import { CountryFlag, IconBox, useModalContext, Link, Copyable } from '~/common/components';
import { UserCard } from '~/common/kits/usercard';
import { addQueryParams, formatNumber, pluralizeWord } from '~/common/utils';
import { CUSTOMERS_URL } from '~/customers/hooks';
import { ORDERS_URL } from '~/orders';
import { useOrderData } from '~/orders/hooks';
import { useCountriesData } from '~/root';
import { CustomerCapacityLink, IndividualPricing, PaymentMethods, ResourceOverviewLink, Section, SectionCell, SectionEntry, SectionButton, SharedMembers, } from '.';
export const CustomerSection = () => {
    var _a, _b, _c;
    const { customer, team, customer_capacity } = useOrderData();
    const countries = useCountriesData();
    const { modalOpener } = useModalContext();
    // TODO ask backend to provide country names and remove this mapping
    const country = (_a = countries.find(({ country_code }) => customer.country === country_code)) === null || _a === void 0 ? void 0 : _a.name;
    return (_jsxs(Section, { type: "customer", children: [_jsxs("div", { className: "flex items-end space-x-1 mb-2", children: [_jsx("h3", { className: "font-brand-t1m", children: _jsx(UserCard, { id: customer.id, children: customer.owner_id ? (_jsx(Link, { size: "xl-bold", to: `${CUSTOMERS_URL}/${customer.owner_id}`, children: customer.name })) : (_jsx("span", { children: customer.name })) }) }), _jsx("span", { className: "font-brand-t4m text-primary-300 capitalize", children: customer.role })] }), _jsxs("div", { className: "grid grid-cols-2 gap-2", children: [_jsxs("div", { className: "flex flex-col items-start space-y-1", children: [customer.phone && _jsx(SectionEntry, { icon: faPhoneAlt, children: customer.phone }), _jsx(SectionEntry, { icon: faEnvelope, children: _jsx(Copyable, { text: customer.email }) }), _jsxs("div", { className: "flex items-center gap-1 font-brand-t4m w-full", children: [customer.country ? (_jsx(CountryFlag, { countryCode: customer.country })) : (_jsx("div", { className: "h-2 w-2 rounded-full bg-greyscale-100 border border-solid border-greyscale-200" })), _jsx("span", { className: "truncate text-greyscale-600", children: country || 'No country' })] }), customer.company && _jsx(SectionEntry, { icon: faBriefcase, children: customer.company }), _jsxs("div", { className: "flex gap-1 items-center", children: [_jsxs(SectionButton, { onClick: customer.owner_id ? modalOpener(SharedMembers) : undefined, icon: faUserFriends, children: ["Shared with ", pluralizeWord('member', customer.members.length)] }), _jsx(IconBox, { "data-tt": customer.owner_id
                                            ? 'People inside this list can edit or give comment on the order'
                                            : "This customer was deleted, team members can't be shown", "data-tt-placement": "right", "data-tt-class": "max-w-[180px]", size: "s", className: "cursor-help", icon: faInfoCircle })] }), _jsx(Link, { size: "m-bold", icon: faTasks, to: addQueryParams(ORDERS_URL, { 'filter[customer]': customer.email }), children: "Previous orders" })] }), _jsxs("div", { className: "flex flex-col items-start space-y-1", children: [_jsx(PaymentMethods, {}), _jsx(IndividualPricing, {}), _jsx(SectionCell, { className: "font-brand-b1", title: "Credits", children: _jsx("span", { className: "font-brand-t4m", children: formatNumber(customer.credits) }) }), customer.subscription && (_jsx(SectionCell, { className: "font-brand-b1", title: "Subscription", children: _jsx("span", { className: "font-brand-t4m", children: (_b = customer.subscription.products) === null || _b === void 0 ? void 0 : _b.map(({ name }) => name).join(' + ') }) })), customer_capacity && (_jsx(SectionCell, { className: "font-brand-b1", title: "Daily points", children: _jsx("span", { className: "font-brand-t4m", children: customer_capacity.daily }) })), ((_c = customer.subscription) === null || _c === void 0 ? void 0 : _c.daily) && customer.subscription.daily > 0 ? (_jsx(CustomerCapacityLink, { className: "mr-auto" })) : null, team && _jsx(ResourceOverviewLink, { className: "mr-auto" })] })] })] }));
};
