import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getCellHeightStyle, Pattern, StickyLeftTableCell, TableCell } from '~/common/components';
import { cx } from '~/common/utils';
import { getBarPositionStyle, TEAM_COLUMN_WIDTH } from '../utils';
import { TeamView } from './TeamView';
import { TimelineContainer } from './TimelineContainer';
import css from './Grid.module.scss';
const getHolidaysFromUserOrTeam = (team, user) => {
    return 'holidays' in team && team.holidays.length > 0 ? team.holidays : user.holidays;
};
export const Row = ({ team, days, todayLabel, lastRow }) => {
    const height = team.members.length * 40 + (lastRow ? 24 : 64);
    return (_jsxs("tr", { style: getCellHeightStyle(height), children: [_jsx(StickyLeftTableCell, { width: TEAM_COLUMN_WIDTH, children: _jsx(TeamView, { team: team }) }), days.map((day, index) => (_jsxs(TableCell, { className: cx('relative', todayLabel === day.label && css.todayCell), children: [day.isWeekend && _jsx(Pattern, { className: "text-greyscale-200" }), index === 0 &&
                        team.members.map((member, memberIndex) => (_jsxs(TimelineContainer, { index: memberIndex, teamId: team.id, userId: member.id, timeline: member.timeline, rangeTimeline: member.rangeTimeline, validRange: member.validRange, children: [getHolidaysFromUserOrTeam(team, member).map((range) => (_jsx("div", { style: getBarPositionStyle(range.offset, range.width, true), className: "absolute h-5", children: _jsx(Pattern, { className: "text-greyscale-200" }) }, `${range.offset}_${range.width}`))), member.invalidRanges.map((range) => (_jsx("div", { style: getBarPositionStyle(range.offset, range.width), className: cx('absolute flex h-4 ml-[4px] z-[1] rounded-2xl overflow-hidden', 'bg-greyscale-50 border border-solid border-greyscale-200'), children: _jsx(Pattern, { className: "text-greyscale-200" }) }, `${range.offset}_${range.width}`)))] }, memberIndex)))] }, day.label)))] }));
};
