import { useMutation } from '@tanstack/react-query';
import { useIdParam } from '~/common/hooks';
import { httpClient } from '~/common/utils';
import { qk } from '~/root/query-keys';
export function useRemoveMessage() {
    const id = useIdParam();
    const { mutateAsync, isLoading } = useMutation({
        mutationKey: qk.orderChatRemoveMessage(id),
        mutationFn: (messageId) => {
            return httpClient.delete(`/v1/staff/orders/${id}/comments/${messageId}`);
        },
    });
    return [mutateAsync, isLoading];
}
