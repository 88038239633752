export const backends = [
    {
        name: 'Serenity',
        server: 'https://api-serenity.24slides.dev',
    },
    {
        name: 'Staging',
        server: 'https://api-staging.24slides.dev',
    },
    {
        name: 'Karma',
        server: 'https://api-karma.24slides.dev',
    },
    {
        name: 'Testing',
        server: 'https://api-testing.24slides.dev',
    },
    {
        name: 'Victim',
        server: 'https://api-victim.24slides.dev',
    },
    {
        name: 'Playground',
        server: 'https://api-playground.24slides.dev',
    },
    {
        name: 'Forge',
        server: 'https://api-forge.24slides.dev',
    },
    {
        name: 'Destiny',
        server: 'https://api-destiny.24slides.dev',
    },
];
export const defaultBackend = backends[1].server;
