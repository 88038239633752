import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LayoutContent, Tabs } from '~/common/components';
import { useVisited } from '~/root';
import { useShiftsContext } from '../context';
import { tabs } from '../domain';
import { useInit, useShifts } from '../hooks';
import { Grid } from './Grid';
import { Header } from './Header';
export const Shifts = () => {
    const { data: init } = useInit();
    const { data: shifts } = useShifts();
    const { tab, setTab } = useShiftsContext();
    useVisited('shifts');
    if (!init || !shifts) {
        return _jsx(LayoutContent, { loading: true });
    }
    return (_jsxs(LayoutContent, { children: [_jsxs("div", { className: "sticky top-0 z-10", children: [_jsx(Header, {}), _jsx(Tabs, { className: "flex items-center gap-5 mb-2 font-brand-t1m border-b-2 border-b-solid border-b-greyscale-200 bg-greyscale-75", tabs: tabs, active: tab, onChange: setTab })] }), _jsx(Grid, {})] }));
};
