import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Button } from '~/common/components';
import { DesignTeam, ResourceOverviewLink, SectionCell } from '~/orders/Order';
import { Content } from '../Content';
import { useTransitionDesignInProgress } from './hooks';
export const TransitionDesignInProgress = ({ currentStatusLabel, nextStatus, nextStatusLabel, onChangeStatus, onClose, onSubmit, transitions, }) => {
    const { fieldArray, form, handleDesignTeamChange, handleSubmit, isSubmitting, options } = useTransitionDesignInProgress(onSubmit);
    return (_jsxs(Content, { currentStatusLabel: currentStatusLabel, loading: isSubmitting, nextStatus: nextStatus, nextStatusLabel: nextStatusLabel, onChangeNextStatus: onChangeStatus, onClose: onClose, onSubmit: handleSubmit, transitions: transitions, children: [_jsx(ResourceOverviewLink, { className: "mt-2 w-fit", newTab: true }), _jsxs("div", { className: "mt-1 w-full", children: [_jsx(SectionCell, { title: "Design team", titleClassName: "font-brand-t4m text-greyscale-500" }), _jsx(DesignTeam, { designTeams: options.designTeams, designers: options.getDesigners(form.fieldProps('team').value), designersFieldProps: form.fieldProps('designers'), disabled: isSubmitting, teamFieldProps: { ...form.fieldProps('team'), onChange: handleDesignTeamChange } })] }), _jsxs("div", { className: "mt-2 w-full", children: [_jsx(SectionCell, { title: "Tag teams", titleClassName: "font-brand-t4m text-greyscale-500" }), fieldArray.tagTeams.groups.map(({ team, designers }, index) => (_jsx(DesignTeam, { designTeams: options.tagTeams, designers: options.getDesigners(team.value), designersFieldProps: designers, disabled: isSubmitting, onRemoveTeam: () => fieldArray.tagTeams.remove(index), teamFieldProps: team }, index))), _jsx("div", { className: "mt-2", children: _jsx(Button, { size: "xs", color: "text-secondary", onClick: () => fieldArray.tagTeams.append({ team: null, designers: [] }), disabled: isSubmitting, icon: faPlus, children: "Tag team" }) })] })] }));
};
