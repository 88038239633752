import { useForm, validator } from 'formoid';
import { nonEmptyArray } from 'fp-ts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { customValidator, nonNullable, scrollToError } from '~/common/utils';
import { otherReasonPrefixes } from '~/orders/domain';
import { useInitData, useUpdateIteration } from '~/orders/hooks';
import { useDistributionContext } from './context';
import { getDistributedPoints, toUpdateIteration, updatePoints, } from './utils';
function useCapacityReasonForm(iteration) {
    const init = useInitData();
    const reasons = init.reasons.adjust_extra_capacity;
    const initialValues = useMemo(() => {
        var _a, _b;
        if (iteration.type === 'revision' && iteration.reason) {
            const prefix = otherReasonPrefixes.find((prefix) => { var _a; return (_a = iteration.reason) === null || _a === void 0 ? void 0 : _a.startsWith(prefix); });
            const customReason = (prefix && ((_a = iteration.reason) === null || _a === void 0 ? void 0 : _a.replace(prefix, ''))) || '';
            const reason = nonNullable(prefix ? (_b = reasons.find((reason) => reason.prefix === prefix)) === null || _b === void 0 ? void 0 : _b.name : iteration.reason);
            return { reason, customReason };
        }
        return { reason: null, customReason: '' };
    }, [iteration, reasons]);
    const { fieldProps, handleReset, handleSubmit } = useForm({
        initialValues,
        validationStrategy: 'onBlur',
        validators: ({ reason }) => {
            var _a;
            return ({
                customReason: iteration.type === 'revision' && !!((_a = reasons.find((r) => r.name === reason)) === null || _a === void 0 ? void 0 : _a.prefix)
                    ? validator.sequence(customValidator.required(), customValidator.nonBlankString())
                    : null,
                reason: iteration.type === 'revision' ? customValidator.required() : null,
            });
        },
    });
    useEffect(() => handleReset(() => initialValues), [handleReset, initialValues]);
    return { fieldProps, handleSubmit };
}
export function useCapacityDistribution({ iterations, }) {
    var _a;
    // TODO kinda ugly way of temporary storing current distribution
    // so we can restore it after modal was accidentaly closed
    const { distribution, setDistribution } = useDistributionContext();
    const lastIteration = nonEmptyArray.last(iterations);
    const [state, setState] = useState(((_a = distribution.current) === null || _a === void 0 ? void 0 : _a.id) === lastIteration.id
        ? distribution.current
        : {
            id: lastIteration.id,
            distribution: lastIteration.teams,
        });
    useEffect(() => {
        setDistribution(state);
    }, [setDistribution, state]);
    /**
     * Since we definitely have iteration state initialized with non-nullable
     * value and the only way we can change iteration state is through iteration
     * select, we can be sure that finding iteration by id will always give a
     * non-nullable result
     */
    const getIteration = useCallback((id) => nonNullable(iterations.find((iteration) => iteration.id === id)), [iterations]);
    const currentIteration = useMemo(() => getIteration(state.id), [getIteration, state.id]);
    const onChangeIteration = (id) => {
        setState(() => ({ id, distribution: getIteration(id).teams }));
    };
    const iterationOptions = useMemo(() => {
        return iterations.map((iteration) => ({ name: iteration.name, value: iteration.id }));
    }, [iterations]);
    const onUpdatePoints = (payload) => {
        setState((iteration) => ({
            ...iteration,
            distribution: updatePoints(iteration.distribution, payload),
        }));
    };
    const remainingPoints = useMemo(() => currentIteration.points_max - getDistributedPoints(state.distribution), [currentIteration.points_max, state.distribution]);
    const { fieldProps, handleSubmit } = useCapacityReasonForm(currentIteration);
    const { updateIteration, isLoading } = useUpdateIteration(currentIteration.id);
    const init = useInitData();
    const reasons = init.reasons.adjust_extra_capacity;
    const onSubmit = (close) => {
        handleSubmit({
            onSuccess: ({ customReason, reason }) => {
                var _a;
                const prefix = (_a = reasons.find((r) => r.name === reason)) === null || _a === void 0 ? void 0 : _a.prefix;
                const payload = currentIteration.type === 'firstDraft'
                    ? {
                        type: 'firstDraft',
                        distribution: state.distribution,
                    }
                    : {
                        type: 'revision',
                        distribution: state.distribution,
                        reason: (prefix ? prefix + customReason : reason),
                    };
                return updateIteration(toUpdateIteration(payload)).then(close);
            },
            onFailure: scrollToError,
        });
    };
    return {
        currentIteration,
        distribution: {
            onUpdatePoints,
            remainingPoints,
            state: state.distribution,
        },
        fieldProps,
        isLoading,
        iterationSelect: {
            onChange: onChangeIteration,
            options: iterationOptions,
            value: state.id,
        },
        onSubmit,
    };
}
