import axios, { AxiosError } from 'axios';
import posthog from 'posthog-js';
import { z } from 'zod';
// TODO structure_refactoring there's so much app-specific stuff getting
// imported here, root, components, etc.
// httpClient should either be moved to ~/root, because it depends on the
// application so much, or those app-specific dependencies like toast,
// checkVersion and env should be dep injected
import { Toast } from '~/common/components';
import { CLIENT_VERSION, ENV, SERVER_URL } from '~/env';
import { checkVersion } from '~/root/UpdateAvailable';
import { addQueryParams, joinTruthy } from './data';
import { FlashMessageFailure, FlashMessageSuccess, unsafeDecode } from './decoder';
import { decodeBackendErrors } from './form';
export function isAbortError(error) {
    return ((error instanceof AxiosError && error.name === 'CanceledError') ||
        (error instanceof Error && error.name === 'AbortError'));
}
export class HttpError extends Error {
    constructor(payload) {
        super();
        this.payload = payload;
    }
}
export function decodeAxiosError(contentDecoder) {
    return z
        .object({
        response: z.object({
            data: contentDecoder,
        }),
    })
        .transform((error) => { var _a; return (_a = error.response) === null || _a === void 0 ? void 0 : _a.data; });
}
export function handleCloudflareAccessRedirect(response) {
    var _a;
    // handle cloudflare access redirect
    // since browser returns response from redirect, not from initial resource
    // we can only look for the url to cloudflare access with some ugly regexp
    if (response.headers['content-type'] !== 'text/html') {
        return;
    }
    const link = (_a = response.data.match('https://.*?(?=")')) === null || _a === void 0 ? void 0 : _a[0];
    if (!link) {
        return;
    }
    const updatedLink = link.replace(/uri=.*?(?=&)/, `uri=${window.location.href}`);
    // TODO remove this testing condition once we find out what's wrong with these redirects
    const shouldRedirect = confirm('Cloudflare access wants you to login\n\n' + updatedLink);
    if (!shouldRedirect) {
        return;
    }
    window.location.href = updatedLink;
}
const makeAxiosInstance = () => {
    const headers = {
        'Content-Type': 'application/json',
        'X-Client-Version': CLIENT_VERSION,
    };
    // cloudflare access returns 401 instead of 302 when this header is present
    // and we need that redirect for accessing *.24slides.dev servers in dev mode
    //
    // in production we won't have cloudflare access, and this header is somehow
    // protects from csrf, so we enable it there only
    // https://stackoverflow.com/a/22533680
    if (ENV.PRODUCTION) {
        headers['X-Requested-With'] = 'XMLHttpRequest';
    }
    const instance = axios.create({
        baseURL: SERVER_URL,
        withCredentials: true,
        headers,
    });
    instance.interceptors.response.use((response) => {
        handleCloudflareAccessRedirect(response);
        const result = z.object({ flashMessage: FlashMessageSuccess }).safeParse(response.data);
        if (result.success) {
            Toast.success({ title: 'Success', subTitle: result.data.flashMessage.message });
        }
        return Promise.resolve(response);
    }, (error) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        const message = (_b = (_a = decodeAxiosError(z.object({ flashMessage: FlashMessageFailure })).safeParse(error)) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.flashMessage.message;
        // TODO refactor this
        const status = (_c = error === null || error === void 0 ? void 0 : error.response) === null || _c === void 0 ? void 0 : _c.status;
        if (status === 401 && !error.request.responseURL.endsWith('/v1/staff/init')) {
            posthog.reset();
            window.location.href = addQueryParams(`${SERVER_URL}/v1/users/logout`, {
                redirect: window.location,
            });
        }
        if (status === 403 && ((_e = (_d = error === null || error === void 0 ? void 0 : error.response) === null || _d === void 0 ? void 0 : _d.config) === null || _e === void 0 ? void 0 : _e.method) !== 'get') {
            const message = (_g = (_f = error === null || error === void 0 ? void 0 : error.response) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.message;
            Toast.error({
                title: 'Failure',
                subTitle: message !== null && message !== void 0 ? message : 'Insufficient permissions for this action, if you believe this is an error - contact product team',
            });
        }
        if (status === 422) {
            const uploadError = (_j = (_h = decodeBackendErrors(error)) === null || _h === void 0 ? void 0 : _h.file) === null || _j === void 0 ? void 0 : _j[0];
            Toast.error({
                title: 'Failure',
                subTitle: (_k = uploadError !== null && uploadError !== void 0 ? uploadError : error.response.data.message) !== null && _k !== void 0 ? _k : 'Incorrect request, contact product team',
            });
        }
        if (message) {
            Toast.error({ title: 'Failure', subTitle: message });
        }
        return Promise.reject(error);
    });
    return instance;
};
export const axiosInstance = makeAxiosInstance();
export async function get(url, { decoder, ...config }) {
    const { data, headers } = await axiosInstance.get(url, config);
    const mismatch = checkVersion(headers);
    return unsafeDecode({
        value: data,
        decoder,
        getInfo: () => joinTruthy([`GET ${url}`, mismatch]),
    });
}
async function internal({ url, noAuth, decoder, method, ...config }) {
    // TODO blocking self-inflicted websockets when they should propagate to
    // secondary tabs actually prevents this websocket deduping method from
    // working properly
    //
    // find a way to propagate WS updates, but avoid operating on them on the
    // primary tab, perhaps with some in-event parameter?
    // normalize delete and post payload param differences
    const response = method === 'delete'
        ? await axiosInstance[method](url, config)
        : await axiosInstance[method](url, config.data, config);
    const mismatch = checkVersion(response.headers);
    if (decoder) {
        return unsafeDecode({
            value: response.data,
            decoder,
            getInfo: () => joinTruthy([`${method.toUpperCase()} ${url}`, mismatch]),
        });
    }
    return response.data;
}
function post(url, params) {
    return internal({ ...params, method: 'post', url });
}
function postForm(url, data, params) {
    return internal({ ...params, data, method: 'postForm', url });
}
function put(url, params) {
    return internal({ ...params, method: 'put', url });
}
function putForm(url, data, decoder) {
    return internal({ data, decoder, method: 'putForm', url });
}
function patch(url, params) {
    return internal({ ...params, method: 'patch', url });
}
function patchForm(url, data, decoder) {
    return internal({ data, decoder, method: 'patchForm', url });
}
function deleteReq(url, params) {
    return internal({ ...params, method: 'delete', url });
}
export const httpClient = {
    delete: deleteReq,
    get,
    patch,
    patchForm,
    post,
    postForm,
    put,
    putForm,
};
