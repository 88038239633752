import { americanExpress, masterCard, visa, unionPay } from '~/assets/images';
export function cardBrandToImg(brand) {
    switch (brand) {
        case 'american express':
            return americanExpress;
        case 'mastercard':
            return masterCard;
        case 'visa':
            return visa;
        case 'unionpay':
            return unionPay;
        default:
            return '';
    }
}
