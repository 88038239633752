import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { faTrello } from '@fortawesome/free-brands-svg-icons';
import { faClockRotateLeft, faLock } from '@fortawesome/pro-regular-svg-icons';
import { Button, IconBox, LayoutHeader, LayoutTitle, Link, Tags, useModalContext, } from '~/common/components';
import { useOrderData } from '~/orders/hooks';
import { TeamSpace } from '../TeamSpace';
import { Activity } from './Activity';
import { FollowOrder } from './FollowOrder';
import { OrderMenu } from './Menu';
import { Viewers } from './Viewers';
export const Header = () => {
    const order = useOrderData();
    const { modalOpener } = useModalContext();
    return (_jsxs(LayoutHeader, { className: "flex items-center", children: [_jsxs("div", { className: "flex items-center gap-2 min-w-0 grow mr-1", children: [_jsxs("div", { className: "flex flex-none items-center gap-[4px] font-brand-b1 text-greyscale-500", children: [_jsxs(LayoutTitle, { className: "truncate max-w-[480px]", children: ["Order ", order.id] }), order.is_confidential && (_jsx(IconBox, { icon: faLock, size: "m", className: "text-greyscale-500", "data-tt": "This order is restricted" }))] }), _jsx(Tags, { labels: order.labels, className: "grow base-0 w-full min-w-0" })] }), _jsxs("div", { className: "flex items-center flex-none space-x-2", children: [_jsx(Viewers, {}), _jsx(FollowOrder, {}), _jsx(Button.Icon, { onClick: modalOpener(Activity), color: "grey-outline", icon: faClockRotateLeft, size: "m", 
                        // TODO it's kinda weird to have to specify s icons for m icon buttons
                        // since m icons are too big
                        iconSize: "s", "data-tt": "Order activity", "data-tt-placement": "bottom" }), order.links.trello_url && (_jsx(Link.Button, { newTab: true, href: order.links.trello_url, icon: faTrello, children: "Card" })), _jsx(TeamSpace, {}), _jsx(OrderMenu, {})] })] }));
};
