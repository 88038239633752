import { jsx as _jsx } from "react/jsx-runtime";
import { FloatingFocusManager, FloatingNode, FloatingPortal, autoUpdate, flip, offset, shift, useDismiss, useFloating, useFloatingNodeId, useInteractions, useTransitionStatus, } from '@floating-ui/react';
import { useCallback, useState } from 'react';
import { MAX_TAB_INDEX } from '~/common/hooks';
import { cx, noop } from '~/common/utils';
import fade from '~/styles/fade.module.scss';
import { styles } from '../../components/UI/Tooltip';
import { outsidePress } from './Floating';
export const useImperativePopover = (params) => {
    const { placement = 'top', fallbackPlacements = ['bottom'], onClose = noop } = params || {};
    const [open, setOpen] = useState(false);
    const nodeId = useFloatingNodeId();
    const { refs, context, floatingStyles } = useFloating({
        nodeId,
        placement,
        open,
        onOpenChange: () => {
            setReference(null);
            onClose();
        },
        middleware: [offset(6), flip({ fallbackPlacements }), shift({ padding: 8 })],
        whileElementsMounted: autoUpdate,
    });
    const { getFloatingProps } = useInteractions([
        useDismiss(context, {
            ancestorScroll: true,
            outsidePress,
        }),
    ]);
    const { isMounted, status } = useTransitionStatus(context);
    const setReference = useCallback((node) => {
        refs.setPositionReference(node);
        setOpen(!!node);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refs.setReference]);
    const floatingProps = getFloatingProps({
        ref: refs.setFloating,
        style: floatingStyles,
        'data-status': status,
    });
    return {
        popoverProps: { open: isMounted, nodeId, floatingProps, context },
        setReference,
    };
};
export const ImperativePopover = (props) => {
    if (!props.open) {
        return null;
    }
    return (_jsx(FloatingNode, { id: props.nodeId, children: _jsx(FloatingPortal, { children: _jsx(FloatingFocusManager, { context: props.context, initialFocus: MAX_TAB_INDEX, children: _jsx("div", { ...props.floatingProps, className: cx('pointer-events-auto z-20 p-2 text-inherit', styles.tooltip, styles.white, fade.floating, props.className), children: props.children }) }) }) }));
};
