import { jsx as _jsx } from "react/jsx-runtime";
import { LayoutContent, Link, PageDetailsCard } from '~/common/components';
import { useCustomerParams } from '~/customers/Customer';
import { SUBSCRIPTIONS_URL, usePayments, usePaymentsInit } from '~/subscriptions/hooks';
import { PaymentsTable } from './PaymentsTable';
export const PaymentsWidget = () => {
    const { subscription_id, customer_id } = useCustomerParams();
    const init = usePaymentsInit();
    const payments = usePayments();
    if (!payments.data || !init.data) {
        return _jsx(LayoutContent, { loading: true });
    }
    return (_jsx(PageDetailsCard, { actionButton: _jsx(Link.Button, { to: `${SUBSCRIPTIONS_URL}/${subscription_id}/customer/${customer_id}/payments`, children: "All payments" }), label: "Payments history", bodyClassName: "p-0", children: _jsx(PaymentsTable, { minified: true, data: payments.data }) }));
};
