import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faArrowFromBottom } from '@fortawesome/pro-regular-svg-icons';
import { Link } from '~/common/components';
import { DateRangeFilter } from '~/common/kits/table';
import { useCustomerParams } from '../General';
import { getExportUrl } from './utils';
export const Header = () => {
    const { customer_id } = useCustomerParams();
    return (_jsxs("div", { className: "flex justify-between items-center bg-greyscale-0 pl-3 p-2 border-b border-b-greyscale-200 border-solid", children: [_jsx("div", { className: "flex", children: _jsx(DateRangeFilter, {}) }), _jsx(Link.Icon, { size: "m", iconSize: "s", icon: faArrowFromBottom, color: "grey-outline", href: getExportUrl(customer_id) })] }));
};
