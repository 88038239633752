import { string } from 'fp-ts';
import { v4, v5 } from 'uuid';
import { z } from 'zod';
import { unsafeRefine } from './common';
const NAMESPACE = '00000000-0000-0000-0000-000000000000';
const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
export const isUUID = (s) => UUID_REGEX.test(s);
export const uuid = unsafeRefine(isUUID);
export const randomUUID = () => v4();
export const seededUUID = (seed) => v5(seed, NAMESPACE);
export const UUID = z.string().refine(isUUID);
export const eqUUID = string.Eq;
