import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IconButton } from '~/common/components';
import { cx, ignoreHandled } from '~/common/utils';
/**
 * autoClose option is set to 5000ms by default, but it doesn't have any effect;
 * closeToast should be used instead of deleteToast, but it doesn't work either;
 */
const ToastContent = ({ deleteToast, subTitle, title, type, link, reversed = false, onClick, }) => {
    useEffect(() => {
        const timeout = setTimeout(deleteToast, 5000);
        return () => clearTimeout(timeout);
    }, [deleteToast]);
    const color = useMemo(() => {
        switch (type) {
            case 'error':
                return 'text-danger-500';
            case 'info':
                return 'text-info-400';
            case 'success':
                return 'text-primary-300';
            case 'warning':
                return 'text-warning-500';
            case 'default':
                return 'text-secondary-400';
        }
    }, [type]);
    const handleClick = () => {
        onClick && onClick();
        deleteToast();
    };
    const handleDelete = (event) => {
        // so link doesn't trigger
        event.preventDefault();
        deleteToast();
    };
    return (_jsx(Wrapper, { link: link, children: _jsxs("div", { className: "flex justify-between gap-1 font-brand-b1", onClick: ignoreHandled(handleClick), children: [_jsxs("div", { className: cx('flex flex-col', reversed && 'flex-col-reverse'), children: [_jsx("p", { className: "text-greyscale-600", children: title }), subTitle && _jsx("p", { className: "text-greyscale-500", children: subTitle })] }), _jsx(IconButton, { "data-stop-propagation": true, size: "s", className: cx('w-3 h-3 mt-[-4px] mr-[-4px] cursor-pointer mb-auto', color), icon: faTimes, onClick: handleDelete })] }) }));
};
const commonOptions = {
    closeButton: false,
    theme: 'colored',
    icon: false,
};
const makeToast = (type) => (props) => 
// TODO: Refactor as soon as react-toastify will remove deprecated props like 'onClick'
toast(({ toastProps: { onClick, ...tProps } }) => _jsx(ToastContent, { ...tProps, ...props }), {
    ...commonOptions,
    type,
});
export const Toast = {
    error: makeToast('error'),
    info: makeToast('info'),
    success: makeToast('success'),
    warning: makeToast('warning'),
    // TODO: check possible extention of possible notification types for React-toastify via d.ts
    default: makeToast('default'),
};
function Wrapper({ link, children }) {
    return link ? (_jsx(RouterLink, { className: "contents", to: link, children: children })) : (_jsx(_Fragment, { children: children }));
}
