import { useIdParam } from '~/common/hooks';
import { usePrivateChannel } from '~/common/kits/socket';
import { map } from '~/common/utils';
import { MessageRemoved, CollaborationMessage } from '~/orders/domain';
import { useIsMyMessage, useUpdateOrderCache } from '~/orders/hooks';
import { useUpdateMessagesCache } from './useUpdateMessagesCache';
export function useMessageEvents(params) {
    const { onCreated = () => null, enabled = true } = params || {};
    const id = useIdParam();
    const updateMessages = useUpdateMessagesCache();
    const updateOrder = useUpdateOrderCache();
    const isMyMessage = useIsMyMessage();
    usePrivateChannel({
        channelName: `staff.order.${id}`,
        enabled,
        events: [
            {
                event: '.order.collaboration.comment.created',
                decoder: CollaborationMessage,
                callback: (message) => {
                    updateMessages((messages) => ({
                        ...messages,
                        pages: messages.pages.map((page, index, pages) => {
                            return page.items.has(message.id) || index === pages.length - 1
                                ? {
                                    ...page,
                                    items: map.add(page.items, message.id, message),
                                }
                                : page;
                        }),
                    }));
                    updateOrder((order) => ({
                        ...order,
                        collaboration: isMyMessage(message)
                            ? {
                                last_read_comment_id: message.id,
                                last_read_at: message.date,
                                unread_comments_count: 0,
                                total_comments_count: order.collaboration.total_comments_count + 1,
                            }
                            : {
                                ...order.collaboration,
                                total_comments_count: order.collaboration.total_comments_count + 1,
                                unread_comments_count: (order.collaboration.unread_comments_count || 0) + 1,
                            },
                    }));
                    // Dirty hack to call onCreated function after messages cache is updated
                    setTimeout(() => onCreated(message), 0);
                },
            },
            {
                event: '.order.collaboration.comment.updated',
                decoder: CollaborationMessage,
                callback: (message) => {
                    updateMessages((messages) => ({
                        ...messages,
                        pages: messages.pages.map((page) => {
                            return page.items.has(message.id)
                                ? {
                                    ...page,
                                    items: map.add(page.items, message.id, message),
                                }
                                : page;
                        }),
                    }));
                },
            },
            {
                event: '.order.collaboration.comment.deleted',
                decoder: MessageRemoved,
                callback: (id) => {
                    updateMessages((messages) => ({
                        ...messages,
                        pages: messages.pages.map((page) => {
                            return page.items.has(id)
                                ? {
                                    ...page,
                                    items: map.remove(page.items, id),
                                }
                                : page;
                        }),
                    }));
                },
            },
        ],
    });
}
