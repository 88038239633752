import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useForm, validator } from 'formoid';
import { InputField, ModalHeader, ModalShell, RangeSelect, SelectField } from '~/common/components';
import { propagateBackendValidationErrors, minLengthMessage } from '~/common/utils';
import { BadgeSelect } from '../BadgeSelect';
import { useInitData, useOfficeData, useOfficeMutation } from '../hooks';
import { addressValidator, transformFormValues, workingHoursValidator } from '../utils';
export const EditOffice = ({ onClose }) => {
    var _a, _b;
    const init = useInitData();
    const office = useOfficeData();
    const { mutateAsync } = useOfficeMutation();
    const initialValues = {
        type: office.type,
        name: (_a = office.name) !== null && _a !== void 0 ? _a : '',
        country_id: office.country.value,
        region: (_b = office.region) !== null && _b !== void 0 ? _b : '',
        city: office.city,
        address: `${office.address}, ${office.postal_code}`,
        workingHours: [Number(office.opens_at), Number(office.closes_at)],
        shift_ids: office.shifts.map(({ id }) => id),
    };
    const { fieldProps, handleSubmit, isSubmitting, setErrors } = useForm({
        initialValues,
        validationStrategy: 'onBlur',
        validators: ({ region }) => ({
            type: null,
            name: validator.minLength(2, minLengthMessage('Name', 2)),
            country_id: null,
            region: region !== '' ? validator.minLength(2, minLengthMessage('Region', 2)) : null,
            city: validator.minLength(2, minLengthMessage('City', 2)),
            address: addressValidator(),
            workingHours: workingHoursValidator(),
            shift_ids: null,
        }),
    });
    const submit = () => handleSubmit((values) => {
        return mutateAsync(transformFormValues(values))
            .then(onClose)
            .catch((error) => {
            propagateBackendValidationErrors(error, setErrors);
        });
    });
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Edit office" }), _jsxs(ModalShell, { className: "flex flex-col gap-3 min-h-[424px] w-[712px] p-3", submitText: "Save", onClose: onClose, onSubmit: submit, loading: isSubmitting, children: [_jsx(InputField, { ...fieldProps('name'), title: "Name", placeholder: "Enter an office name", type: "text" }), _jsx(SelectField, { ...fieldProps('type'), title: "Type", options: init.office_type }), _jsx(SelectField, { ...fieldProps('country_id'), title: "Country", options: init.countries, type: "text" }), _jsxs("div", { className: "grid grid-cols-2 gap-5", children: [_jsx(InputField, { ...fieldProps('region'), title: "Region / state (optional)", placeholder: "Enter a region or state", type: "text" }), _jsx(InputField, { ...fieldProps('city'), title: "City", placeholder: "Enter a city", type: "text" })] }), _jsx(InputField, { ...fieldProps('address'), title: "Address", placeholder: "Enter street, building, zip code", type: "text" }), _jsx(RangeSelect, { ...fieldProps('workingHours'), title: "Working hours", render: (value) => `${value}:00`, min: 0, max: 23 }), _jsx(BadgeSelect, { ...fieldProps('shift_ids'), title: "Shifts", optional: true, badges: init.shifts })] })] }));
};
