import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useForm, validator } from 'formoid';
import { InputField, ModalHeader, ModalShell, SwitcherField, TextArea, ChipsInputField, extendedSplitter, } from '~/common/components';
import { customValidator, isEmail, propagateBackendValidationErrors, } from '~/common/utils';
import { useBillingSettingsMutation, useCustomerData } from '~/customers/hooks';
export const EditBillingSettings = ({ onClose }) => {
    var _a;
    const customer = useCustomerData();
    const { mutateAsync } = useBillingSettingsMutation();
    // TODO we should avoid changing field names for fun between read/write
    const initialValues = {
        finance_email: customer.billing_settings.email || '',
        cc_emails: ((_a = customer.billing_settings.cc) === null || _a === void 0 ? void 0 : _a.map((value) => ({ value, isValid: true }))) || [],
        invoice_enabled: customer.billing_settings.invoice_enabled,
        payment_term: customer.billing_settings.payment_term,
        is_trusted: customer.billing_settings.add_credits_immediately,
        enable_auto_charge: customer.billing_settings.enable_auto_charge,
        comment: customer.billing_settings.invoice_lines || '',
    };
    const { fieldProps, handleSubmit, setErrors, isSubmitting, values } = useForm({
        initialValues,
        validationStrategy: 'onBlur',
        validators: ({ invoice_enabled }) => ({
            finance_email: customValidator.email(),
            cc_emails: validator.fromPredicate((value) => {
                return value.filter(({ isValid }) => isValid).length === value.length;
            }, 'All emails should be valid. Delete or correct invalid ones'),
            invoice_enabled: null,
            payment_term: invoice_enabled ? customValidator.positiveInteger() : null,
            is_trusted: null,
            enable_auto_charge: null,
            comment: null,
        }),
    });
    const submit = () => handleSubmit((values) => {
        const patchedValues = {
            ...values,
            cc_emails: values.cc_emails.length ? values.cc_emails.map(({ value }) => value) : undefined,
            comment: values.comment || undefined,
            payment_term: values.invoice_enabled ? values.payment_term : undefined,
            is_trusted: values.invoice_enabled ? values.is_trusted : undefined,
        };
        return mutateAsync(patchedValues)
            .then(onClose)
            .catch((error) => {
            propagateBackendValidationErrors(error, setErrors);
        });
    });
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Billing settings" }), _jsxs(ModalShell, { className: "p-3 pb-5 space-y-3 w-[608px]", onClose: onClose, onSubmit: submit, loading: isSubmitting, children: [_jsx(InputField, { ...fieldProps('finance_email'), title: "Finance email", placeholder: "Enter finance email", type: "text", hint: "The primary finance contact and recipient of the invoices" }), _jsx(ChipsInputField, { ...fieldProps('cc_emails'), title: "CC emails", hint: "The emails where copy of invoices will be sent. For adding multiple emails use space \u201C,\u201D \u201C;\u201D or new line to separate them", splitter: extendedSplitter, validateChip: isEmail, optional: true }), _jsxs("div", { className: "grid grid-cols-2 gap-x-5", children: [_jsx(SwitcherField, { ...fieldProps('invoice_enabled'), title: "Enable invoice payment" }), values.invoice_enabled && (_jsxs("div", { className: "space-y-2", children: [_jsxs("div", { className: "relative", children: [_jsx(InputField, { ...fieldProps('payment_term'), title: "Payment term", placeholder: "Enter payment term", type: "number", hint: "The amount of time what the customer has to pay the invoice" }), _jsx("span", { className: "absolute top-[35px] right-1 font-brand-b1 text-greyscale-500", children: "days" })] }), _jsx(SwitcherField, { ...fieldProps('is_trusted'), title: "Add credits immediately", hint: "Credits will be added to the customer account before we receive the payment" })] }))] }), _jsx("div", { className: "grid grid-cols-2 gap-x-5", children: _jsx(SwitcherField, { ...fieldProps('enable_auto_charge'), title: "Auto charge orders", hint: "Enable auto charge logic on orders after 7 days of inactivity" }) }), _jsx(TextArea, { ...fieldProps('comment'), title: "Custom invoice lines", placeholder: "Add some custom information to your invoice", hint: "The additional information that will be displayed on Invoice", optional: true })] })] }));
};
