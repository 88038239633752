import { format } from 'date-fns';
import { date, map, number } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { map as myMap } from '~/common/utils';
function sum(ord) {
    return map.reduce(ord)(0, (total, element) => total + element);
}
export function getDesignerTotalPoints(designer) {
    return pipe(designer.distribution, sum(date.Ord));
}
export function getTeamTotalPoints(team) {
    return pipe(team.designers, map.map(getDesignerTotalPoints), sum(number.Ord));
}
export function getTeamPointsPerDay(team, day) {
    return pipe(team.designers, map.filterMap((designer) => pipe(designer.distribution, map.lookup(date.Eq)(day))), sum(number.Ord));
}
export function getDistributedPoints(distribution) {
    return pipe(distribution, map.map(getTeamTotalPoints), sum(number.Ord));
}
export function toUpdateIteration(payload) {
    const teams = myMap.toEntries(payload.distribution).map(([id, team]) => ({
        id,
        designers: myMap.toEntries(team.designers).map(([id, designer]) => ({
            id,
            distribution: myMap.toEntries(designer.distribution).map(([date, points]) => ({
                date: format(date, 'yyyy-MM-dd'),
                points,
            })),
        })),
    }));
    return { reason: payload.type === 'revision' ? payload.reason : undefined, teams };
}
export function updatePoints(distribution, payload) {
    return pipe(distribution, myMap.modifyAt(number.Eq, payload.teamId, (team) => ({
        ...team,
        designers: pipe(team.designers, myMap.modifyAt(number.Eq, payload.designerId, (designer) => ({
            ...designer,
            distribution: pipe(designer.distribution, myMap.modifyAt(date.Eq, payload.date, () => payload.points)),
        }))),
    })));
}
