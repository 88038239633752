import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { Popover } from '~/common/components';
import { cx, ignoreHandled } from '~/common/utils';
import { useCurrentUserData } from '~/root';
import { useHoveredUserStore } from '../context';
import { getBarPositionStyle, getInfoFromSelection, getTimelineContainerPositionStyle, isInValidRange, pointerMoveUtils, } from '../utils';
import { EditRegion } from './EditRegion';
import { TimelineItem } from './TimelineItem';
import css from './Grid.module.scss';
// amazing optimization, hover will only re-render 4 divs at max
const TimelineContainerBg = ({ userId, index, editable, handleDown, children, }) => {
    const hovered = useHoveredUserStore((store) => store.userId === userId);
    const setUserId = useHoveredUserStore((store) => store.setUserId);
    return (_jsx("div", { className: cx('absolute h-5 pointer-events-auto transition-colors', hovered && 'bg-[rgba(238,243,248,0.4)]', editable && 'cursor-pointer'), style: getTimelineContainerPositionStyle(index), onPointerDown: handleDown, onPointerOver: () => setUserId(userId), onPointerOut: () => setUserId(null), children: children }));
};
export const TimelineContainer = ({ teamId, userId, index, timeline, rangeTimeline, validRange, children, }) => {
    const [selection, setSelection] = useState(null);
    const [show, setShow] = useState(false);
    const { permissions } = useCurrentUserData();
    // to prevent deleting selection when a new one is created, but popover is
    // still fading out
    const blockDelete = useRef(false);
    const handleDown = permissions.shiftsUpdate
        ? ignoreHandled((event) => {
            // left click only
            if (event.button !== 0) {
                return;
            }
            pointerMoveUtils({
                event,
                onMove: (placement) => {
                    // do not allow selection growing past valid range
                    setSelection((prev) => (isInValidRange(validRange, placement) ? placement : prev));
                },
                onDown: (placement) => {
                    // skip creating selection if clicked outside of the valid range
                    if (!isInValidRange(validRange, placement)) {
                        return false;
                    }
                    blockDelete.current = true;
                },
                onUp: () => {
                    blockDelete.current = false;
                    setShow(true);
                },
            });
        })
        : undefined;
    const handleClose = () => {
        if (!blockDelete.current) {
            setSelection(null);
        }
    };
    const handleTimelineItemClick = permissions.shiftsUpdate
        ? (item) => {
            setSelection({ offset: item.offset, width: item.width });
            setShow(true);
        }
        : undefined;
    return (_jsxs(TimelineContainerBg, { userId: userId, index: index, editable: permissions.shiftsUpdate, handleDown: handleDown, children: [children, rangeTimeline.map((item) => (_jsx(TimelineItem, { placement: { offset: item.offset, width: item.width }, sign: item.occupation.sign, range: item.occupation.range, occupationId: item.occupation.id, isManager: item.is_manager, teamId: teamId, userId: userId, validRange: validRange, resizeable: permissions.shiftsUpdate, onClick: handleTimelineItemClick ? () => handleTimelineItemClick(item) : undefined }, `${item.offset}_${item.width}`))), selection && (_jsx(Popover, { className: "w-[326px] text-greyscale-600 focus-visible:outline-none rounded-lg", externalState: [show, setShow], placement: "bottom-start", fallbackPlacements: ['right', 'top', 'left'], compensateOffset: 6, onClose: handleClose, trigger: (props) => (_jsx("div", { ref: props.ref, className: css.selection, style: getBarPositionStyle(selection.offset, selection.width) })), content: (props) => (_createElement(EditRegion, { ...props, ...getInfoFromSelection(selection, timeline), 
                    // reset internal state when selection has been changed
                    key: `${selection.offset}_${selection.width}`, teamId: teamId, userId: userId, selection: selection })) }))] }));
};
