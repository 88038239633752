import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LayoutContent } from '~/common/components';
import { useInit, useOrders } from '~/orders/hooks';
import { Header } from '../Header';
import { ListTable } from './ListTable';
export const ListView = () => {
    var _a;
    const orders = useOrders();
    const init = useInit();
    return (_jsxs(LayoutContent, { children: [init.data && _jsx(Header, { ordersCount: (_a = orders.data) === null || _a === void 0 ? void 0 : _a.metadata.total }), init.data ? _jsx(ListTable, {}) : _jsx(LayoutContent, { loading: true })] }));
};
