import { option } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { z } from 'zod';
import { decodeAxiosError } from '~/common/utils';
import { isCardPaymentMethod } from '~/orders/domain';
export const makeCardMethod = (method) => pipe(method, option.fromPredicate(isCardPaymentMethod), option.map((card) => ({ type: 'card', value: card })));
export const methodToString = (method) => method.type === 'credits' ? 'credits' : method.value.id.toString();
// TODO VALIDATION consider extracting this as a generic 422 error message getter
export const getErrorMessage = (error) => {
    var _a, _b;
    return (((_b = (_a = decodeAxiosError(z.object({ message: z.string() })).safeParse(error)) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) || null);
};
