import { useEffect } from 'react';
import { create } from 'zustand';
export const useUserCardIdsStore = create()((set) => ({
    // ids are mutable to have better performance
    ids: new Set(),
    idsAmount: 0,
    addId: (id) => {
        set(({ ids }) => {
            ids.add(id);
            return { ids, idsAmount: ids.size };
        });
    },
}));
/** Use this to populate user ids to fetch all usercards for them on hover */
export const useAddUserCardId = (...ids) => {
    const addId = useUserCardIdsStore((store) => store.addId);
    // Add user ids to the store to fetch all of them on hover
    useEffect(() => ids.forEach(addId), [addId, ids]);
};
