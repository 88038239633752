import { Toast } from '../components';
export const copyToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        document.execCommand('copy');
        Toast.success({ title: 'Copied!' });
    }
    catch (err) {
        Toast.error({ title: 'Oops, unable to copy' });
    }
    document.body.removeChild(textArea);
};
