import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { z } from 'zod';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useIdParam } from '~/common/hooks';
import { MentionEntity, getEditorStateJSON } from '~/common/kits/editor';
import { handleStateAction, httpClient, record } from '~/common/utils';
import { qk } from '~/root/query-keys';
const createUsePersistentMessage = (name) => {
    const usePersistentMessage = create()(persist((set, get) => ({
        messages: {},
        setMessages: (stateOrCb) => set({ messages: handleStateAction(stateOrCb, get().messages) }),
    }), { name }));
    return () => {
        const id = useIdParam();
        const persist = usePersistentMessage();
        const stateRef = useRef(null);
        useEffect(() => {
            return () => {
                const message = getEditorStateJSON(stateRef.current);
                if (message) {
                    persist.setMessages((messages) => ({ ...messages, [id]: message }));
                }
                else {
                    persist.setMessages((messages) => record.remove(messages, id));
                }
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
        return {
            state: persist.messages[id] || undefined,
            onChange: (state) => (stateRef.current = state),
            onSuccess: () => {
                stateRef.current = null;
                persist.setMessages((messages) => record.remove(messages, id));
            },
        };
    };
};
export const usePersistentChatMessage = createUsePersistentMessage('collaboration-chat-message');
export const usePersistentChecklistItem = createUsePersistentMessage('collaboration-check-item');
export const useTeamSpaceMentions = (mentionString) => {
    const id = useIdParam();
    const mentionable = useQuery({
        queryKey: qk.collaborationMentions(id),
        queryFn: () => {
            return httpClient.get(`/v1/staff/orders/${id}/collaboration/mentionable`, {
                decoder: z.array(MentionEntity),
            });
        },
    });
    if (!mentionString || !mentionable.data) {
        return [];
    }
    const lowerCasedMention = mentionString.toLowerCase();
    return mentionable.data.filter((mention) => {
        return mention.name.toLowerCase().includes(lowerCasedMention);
    });
};
