import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { useMemo } from 'react';
import { Badge, IconBox } from '~/common/components';
const getUpdatedFilterValue = (value, newValue) => {
    if (Array.isArray(value)) {
        return value.filter((v) => v !== newValue);
    }
    return undefined;
};
export const FilterBarRow = ({ title, value, options, onChange, children }) => {
    const values = useMemo(() => {
        if (value) {
            return options.filter((option) => Array.isArray(value) ? value.includes(option.value) : value === option.value);
        }
    }, [value, options]);
    return (_jsxs("div", { className: "flex items-center gap-1", children: [_jsxs("span", { className: "text-greyscale-400 font-brand-b2r", children: [title, ":"] }), _jsxs("div", { className: "flex gap-1 flex-wrap", children: [values === null || values === void 0 ? void 0 : values.map((val) => (_jsxs(Badge.Button, { color: "lighter-grey", onClick: () => onChange(getUpdatedFilterValue(value, val.value)), className: "flex gap-[10px] items-center", children: [_jsx("span", { children: val.name }), _jsx(IconBox, { size: "s", icon: faTimesCircle })] }, val.value))), children] })] }));
};
