import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { cx } from '~/common/utils';
import { TESTING } from '~/env';
import { getButtonIconProps, getButtonProps, getButtonStateStyles, getButtonStyles, getIconStyles, } from './utils';
const ButtonBase = forwardRef((props, ref) => {
    var _a;
    return (_jsx("button", { ...props, 
        // apparently firefox only ignores onClick, while chrome ignores every
        // event, breaking tooltips on disabled elements
        disabled: TESTING ? props.disabled : false, onClick: props.disabled ? undefined : props.onClick, type: (_a = props.type) !== null && _a !== void 0 ? _a : 'button', ref: ref }));
});
export const Button = forwardRef((props, ref) => {
    const className = cx(getButtonStyles(props), getButtonStateStyles(props), props.className);
    const fullProps = getButtonProps({ ...props, className });
    return _jsx(ButtonBase, { ...fullProps, ref: ref });
});
const DivButton = forwardRef((props, ref) => {
    const className = cx(getButtonStyles(props), getButtonStateStyles(props), props.className);
    const buttonProps = getButtonProps({ ...props, className });
    return _jsx("div", { ...buttonProps, ref: ref });
});
const ButtonIcon = forwardRef((props, ref) => {
    const className = cx(getButtonStateStyles(props), getIconStyles(props), props.className);
    const buttonProps = getButtonIconProps({ ...props, className });
    return _jsx(ButtonBase, { ...buttonProps, ref: ref });
});
Button.Div = DivButton;
Button.Icon = ButtonIcon;
Button.Base = ButtonBase;
