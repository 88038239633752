import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCompositeForm, validator } from 'formoid';
import { number, string } from 'fp-ts';
import { constNull } from 'fp-ts/function';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Checkbox, Show } from '~/common/components';
import { usePatchedBlur } from '~/common/hooks';
import { customValidator, formatDateDistance, formatYearMonthDay, getDateWithoutTime, record, } from '~/common/utils';
import { eqTreatments } from '~/orders/domain';
import { changeStatusPayload, useCalculateOrderPrice, useInitData, useOrderData, } from '~/orders/hooks';
import { Addons, CustomerCapacity, Deadline, DesignCapacity, Price, PriceChangeBanner, SectionCell, Treatment, } from '~/orders/Order';
import { capacityEndDateValidator, capacityStartDateValidator, discountToString, propagateTreatmentsBackendValidationErrors, } from '~/orders/utils';
import { Content } from '../Content';
export const TransitionApproved = ({ currentStatusLabel, nextStatus, nextStatusLabel, onChangeStatus, onClose, onSubmit, transitions, }) => {
    var _a, _b, _c, _d;
    const order = useOrderData();
    const init = useInitData();
    const [currentDeadline, setCurrentDeadline] = useState(order.deadline);
    const currentDeadlineText = useMemo(() => formatDateDistance(currentDeadline), [currentDeadline]);
    const [currentDiscount, setDiscount] = useState(order.payment.discount || undefined);
    const [deadlinePayload, setDeadlinePayload] = useState();
    const [extraCapacityPayload, setExtraCapacityPayload] = useState();
    const initialValues = {
        addons: order.addons.map(({ value }) => value),
        amount: order.payment.amount,
        coupon: order.payment.coupon || '',
        deadline: currentDeadlineText,
        discount: currentDiscount ? discountToString(currentDiscount, order.payment.currency) : '',
        'designCapacity.extra': 0,
        'designCapacity.order': order.points,
        'designCapacity.total': order.points,
        // TODO timezone should this get the date of order.created based on UTC time ?
        'customerCapacity.start': (_b = (_a = order.customer_capacity) === null || _a === void 0 ? void 0 : _a.start) !== null && _b !== void 0 ? _b : getDateWithoutTime(order.created),
        'customerCapacity.end': (_d = (_c = order.customer_capacity) === null || _c === void 0 ? void 0 : _c.end) !== null && _d !== void 0 ? _d : getDateWithoutTime(),
        orderedSlides: order.slides,
        unit: order.payment.unit,
        vat: order.payment.vat,
    };
    const validators = {
        ...record.map(initialValues, constNull),
        unit: validator.range(0, 100000, 'Value must be between 0 and 100000'),
    };
    const { form, fieldArray, handleSubmit, isSubmitting } = useCompositeForm({
        form: {
            initialValues,
            validators: ({ form: values }) => order.customer.subscription
                ? {
                    ...validators,
                    'customerCapacity.start': capacityStartDateValidator(order.created),
                    'customerCapacity.end': capacityEndDateValidator(values['customerCapacity.start']),
                }
                : validators,
            validationStrategy: 'onBlur',
        },
        fieldArray: {
            initialValues: { treatments: order.treatments },
            validators: () => ({
                treatments: {
                    id: null,
                    name: null,
                    value: customValidator.nonNegativeInteger(),
                },
            }),
            validationStrategy: 'onBlur',
        },
    });
    const [acceptPrice, setAcceptPrice] = useState(true);
    const [updatedPrice, setUpdatedPrice] = useState(null);
    const handleAcceptPriceChange = (acceptPrice) => {
        setAcceptPrice(acceptPrice);
        if (!updatedPrice) {
            return;
        }
        form.setValues((form) => {
            var _a, _b;
            return ({
                ...form,
                ...(acceptPrice
                    ? {
                        ...updatedPrice,
                        coupon: (_a = updatedPrice.coupon) !== null && _a !== void 0 ? _a : '',
                        discount: updatedPrice.discount
                            ? discountToString(updatedPrice.discount, order.payment.currency)
                            : '',
                    }
                    : {
                        amount: order.payment.amount,
                        coupon: (_b = order.payment.coupon) !== null && _b !== void 0 ? _b : '',
                        discount: order.payment.discount
                            ? discountToString(order.payment.discount, order.payment.currency)
                            : '',
                        unit: order.payment.unit,
                        vat: order.payment.vat,
                    }),
            });
        });
    };
    const submit = () => handleSubmit(({ form, fieldArray }) => {
        var _a;
        return onSubmit(changeStatusPayload.approved({
            addons: form.addons,
            deadline: deadlinePayload,
            extra_points: extraCapacityPayload,
            customer_capacity: order.customer.subscription
                ? {
                    start: formatYearMonthDay(form['customerCapacity.start']),
                    end: formatYearMonthDay(form['customerCapacity.end']),
                }
                : undefined,
            treatments: record.fromEntries(fieldArray.treatments.map(({ id, value }) => [id, value])),
            payment: {
                coupon: (_a = form.coupon) !== null && _a !== void 0 ? _a : undefined,
                discount: currentDiscount,
                unit: form.unit,
            },
        }));
    });
    const handleApplyExtraCapacity = (payload) => {
        form.fieldProps('designCapacity.extra').onChange(payload.value);
        form
            .fieldProps('designCapacity.total')
            .onChange(form.fieldProps('designCapacity.total').value + payload.value);
        setExtraCapacityPayload(payload);
    };
    const handleApplyDeadline = (payload) => {
        setCurrentDeadline(payload.value);
        form.fieldProps('deadline').onChange(formatDateDistance(payload.value));
        setDeadlinePayload(payload);
        mutateAsync({
            addons: form.values.addons,
            deadline: payload.value,
            treatments: getTreatments(),
            discount: currentDiscount,
            coupon: form.values.coupon,
        }).then((data) => {
            if (acceptPrice)
                setUpdatedPrice(data);
        });
    };
    const { mutateAsync, isLoading } = useCalculateOrderPrice({
        onSuccess: ({ coupon, discount, points, ...data }) => {
            form.setValues((formValues) => ({
                ...formValues,
                ...data,
                coupon: coupon !== null && coupon !== void 0 ? coupon : '',
                discount: discount ? discountToString(discount, order.payment.currency) : '',
                'designCapacity.order': points,
                'designCapacity.total': formValues['designCapacity.extra'] + points,
            }));
            form.setErrors('coupon', null);
            form.setErrors('discount', null);
            form.setErrors('designCapacity.order', null);
            form.setErrors('designCapacity.total', null);
        },
        onError: propagateTreatmentsBackendValidationErrors(fieldArray.treatments, form.setErrors),
    });
    const getTreatments = () => {
        return record.fromEntries(fieldArray.treatments.values.map(({ id, value }) => [id, value]));
    };
    const handleApplyDiscount = (discount) => {
        form.fieldProps('discount').onChange(discountToString(discount, order.payment.currency));
        setDiscount(discount);
        mutateAsync({ discount, unit: form.values.unit }).then(() => setUpdatedPrice(null));
    };
    const patchDisabledProp = useCallback(({ disabled, ...props }) => ({ ...props, disabled: disabled || isLoading }), [isLoading]);
    const unitFieldProps = usePatchedBlur({
        blurCallback: () => {
            mutateAsync({
                unit: form.values.unit,
            }).then(() => setUpdatedPrice(null));
        },
        eq: number.Eq,
        fieldProps: patchDisabledProp(form.fieldProps('unit')),
    });
    const couponFieldProps = usePatchedBlur({
        blurCallback: () => {
            mutateAsync({
                coupon: form.values.coupon,
                unit: form.values.unit,
            }).then(() => setUpdatedPrice(null));
        },
        eq: string.Eq,
        fieldProps: patchDisabledProp(form.fieldProps('coupon')),
    });
    const treatmentPreviousValues = useRef(fieldArray.treatments.values);
    const recalculatePrice = (addons) => {
        mutateAsync({
            treatments: getTreatments(),
            addons,
            deadline: currentDeadline,
            coupon: form.values.coupon,
            discount: currentDiscount,
        }).then((data) => {
            setUpdatedPrice(data);
        });
    };
    const treatmentFieldGroups = fieldArray.treatments.groups.map((group) => ({
        ...group,
        value: {
            ...group.value,
            onBlur: () => {
                if (!eqTreatments.equals(fieldArray.treatments.values, treatmentPreviousValues.current)) {
                    treatmentPreviousValues.current = fieldArray.treatments.values;
                    recalculatePrice(form.values.addons);
                }
                group.value.onBlur();
            },
        },
    }));
    const addonProps = {
        ...form.fieldProps('addons'),
        onChange: (newAddons) => {
            form.fieldProps('addons').onChange(newAddons);
            recalculatePrice(newAddons);
        },
    };
    const orderedSlidesProps = useMemo(() => ({
        ...form.fieldProps('orderedSlides'),
        value: fieldArray.treatments.values.reduce((result, treatment) => result + treatment.value, 0),
    }), [fieldArray.treatments.values, form]);
    return (_jsx(Content, { currentStatusLabel: currentStatusLabel, loading: isSubmitting || isLoading, nextStatus: nextStatus, nextStatusLabel: nextStatusLabel, onChangeNextStatus: onChangeStatus, onClose: onClose, onSubmit: submit, transitions: transitions, children: _jsxs("div", { className: "mt-3 space-y-3", children: [_jsxs("div", { className: "flex items-start space-x-2", children: [_jsx("div", { className: "w-1/2", children: _jsx(Addons, { ...addonProps, addons: init.addons }) }), _jsx("div", { className: "w-1/2", children: _jsx(Treatment, { treatmentFieldGroups: treatmentFieldGroups, orderedSlidesProps: orderedSlidesProps }) })] }), _jsxs("div", { className: "flex items-start space-x-2", children: [_jsx("div", { className: "w-1/2", children: _jsx(DesignCapacity, { fieldProps: form.fieldProps, onApply: handleApplyExtraCapacity, reasons: init.reasons }) }), _jsx("div", { className: "w-1/2", children: _jsx(Show, { if: order.customer.subscription !== null, children: _jsx(CustomerCapacity, { fieldProps: form.fieldProps }) }) })] }), _jsxs("div", { className: "flex items-start space-x-2", children: [_jsx("div", { className: "w-1/2", children: _jsx(Deadline, { currentDeadlineText: currentDeadlineText, deadline: order.deadline, onApply: handleApplyDeadline, reasons: init.reasons }) }), _jsxs("div", { className: "space-y-1 w-1/2", children: [_jsx(SectionCell, { title: "Price", titleClassName: "font-brand-t4m text-greyscale-500" }), _jsx(Price, { currentDiscount: currentDiscount, fieldProps: {
                                        amount: patchDisabledProp(form.fieldProps('amount')),
                                        coupon: couponFieldProps,
                                        discount: patchDisabledProp(form.fieldProps('discount')),
                                        unit: unitFieldProps,
                                        vat: patchDisabledProp(form.fieldProps('vat')),
                                    }, onApplyDiscount: handleApplyDiscount, payment: order.payment })] })] }), updatedPrice && updatedPrice.unit !== order.payment.unit && (_jsxs("div", { className: "space-y-1", children: [_jsx(PriceChangeBanner, { from: order.payment.unit, to: updatedPrice.unit, currency: order.payment.currency }), _jsx(Checkbox, { text: "Accept price change", value: acceptPrice, onChange: handleAcceptPriceChange })] }))] }) }));
};
