import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { invoice } from '~/assets/images';
import { Errors, SelectFactory } from '~/common/components';
import { cardBrandToImg } from '~/common/utils';
const getPaymentMethodImage = (brand) => {
    if (!brand) {
        return { src: invoice, alt: 'Invoice payment method' };
    }
    return { src: cardBrandToImg(brand), alt: brand };
};
export const PaymentMethodSelectField = ({ className, errors, touched, value, onChange, options, ...props }) => {
    const paymentMethodsOptions = useMemo(() => options.map((paymentMethod) => {
        if ('brand' in paymentMethod) {
            return {
                name: `**** ${paymentMethod.last4}`,
                value: paymentMethod.id,
                brand: paymentMethod.brand,
                isDefault: paymentMethod.default,
            };
        }
        return {
            name: paymentMethod.company || 'Invoice method',
            value: paymentMethod.id,
            isDefault: paymentMethod.default,
        };
    }), [options]);
    const selectedOption = paymentMethodsOptions.find(({ value: paymentMethodId }) => paymentMethodId === value);
    const paymentMethodImage = value ? getPaymentMethodImage(selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.brand) : undefined;
    return (_jsxs("div", { children: [_jsx(SelectFactory, { ...props, options: paymentMethodsOptions, onChange: onChange, nullable: true, value: value, inputField: ({ ref, children, ...inputProps }) => {
                    return (_jsxs("label", { ref: ref, className: "flex flex-col", children: [_jsx("span", { className: "font-brand-b1 mb-[4px] whitespace-nowrap", children: props.title }), _jsxs("div", { className: "flex items-center gap-x-1 relative h-5 px-1 rounded border border-solid border-greyscale-300", children: [paymentMethodImage && _jsx("img", { className: "w-[24px] h-2", ...paymentMethodImage }), _jsx("input", { ...inputProps, className: "font-brand-b1 min-w-0 w-full h-full", placeholder: "Select payment method", autoComplete: "off" }), _jsx("div", { className: "flex justify-center items-center text-greyscale-500 h-4 w-4 ml-auto", onMouseDown: (event) => event.preventDefault(), children: children })] })] }));
                }, itemRenderer: paymentMethodRenderer }), errors && _jsx(Errors, { color: true, errors: errors })] }));
};
const paymentMethodRenderer = ({ option, inputValue, ...props }) => (_jsx("li", { ...props, children: _jsxs("div", { className: "flex gap-1 items-center w-full", children: [_jsx("img", { className: "mr-2 w-[24px] h-2", ...getPaymentMethodImage(option.brand) }), option.name, option.isDefault && _jsx("span", { className: "text-greyscale-500", children: "(Default)" })] }) }));
