import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Badge, Errors, InputField, Show } from '~/common/components';
import { useInitData } from '~/orders/hooks';
import { SectionCell } from '~/orders/Order';
export const ExtraCapacityReason = ({ fieldProps }) => {
    const init = useInitData();
    const reasons = init.reasons.adjust_extra_capacity;
    const { errors, onChange, onBlur, value } = fieldProps('reason');
    const handleToggleReason = (reason) => {
        onChange(value === reason ? null : reason);
        onBlur();
    };
    const showInput = useMemo(() => !!reasons.find((reason) => reason.name === value && reason.prefix), [value, reasons]);
    return (_jsxs("div", { children: [_jsx(SectionCell, { className: "mb-1", title: "Reason" }), _jsxs("div", { className: "flex gap-4", children: [_jsxs("div", { className: "w-1/2", children: [_jsx("div", { className: "flex flex-wrap items-center gap-[4px] my-[4px]", children: reasons.map((reason, key) => (_jsx(Badge.Button, { onClick: () => handleToggleReason(reason.name), color: reason.name === value ? 'primary' : 'grey', onBlur: onBlur, children: reason.name }, key))) }), errors && _jsx(Errors, { color: true, errors: errors })] }), _jsx("div", { className: "w-1/2", children: _jsx(Show, { if: showInput, children: _jsx(InputField, { ...fieldProps('customReason'), placeholder: "Custom reason", type: "text" }) }) })] })] }));
};
