import { util, ZodParsedType, ZodIssueCode, } from 'zod';
const getError = (issue, ctx, fieldName) => {
    switch (issue.code) {
        case ZodIssueCode.invalid_type:
            if (issue.received === ZodParsedType.undefined) {
                return `${fieldName} is required`;
            }
            else {
                return `Expected ${issue.expected}, received ${issue.received}`;
            }
        case ZodIssueCode.invalid_string:
            if (typeof issue.validation === 'object') {
                if ('includes' in issue.validation) {
                    const message = `${fieldName} must include "${issue.validation.includes}"`;
                    if (typeof issue.validation.position === 'number') {
                        return `${message} at one or more positions greater than or equal to ${issue.validation.position}`;
                    }
                    else {
                        return message;
                    }
                }
                else if ('startsWith' in issue.validation) {
                    return `${fieldName} must start with "${issue.validation.startsWith}"`;
                }
                else if ('endsWith' in issue.validation) {
                    return `${fieldName} must end with "${issue.validation.endsWith}"`;
                }
                else {
                    util.assertNever(issue.validation);
                }
            }
            else if (issue.validation !== 'regex') {
                return `${fieldName} is invalid ${issue.validation}`;
            }
            else {
                return `${fieldName} is invalid`;
            }
        case ZodIssueCode.too_small:
            if (issue.type === 'array')
                return `${fieldName} must contain ${issue.exact ? 'exactly' : issue.inclusive ? `at least` : `more than`} ${issue.minimum} element(s)`;
            else if (issue.type === 'string')
                return `${fieldName} must contain ${issue.exact ? 'exactly' : issue.inclusive ? `at least` : `over`} ${issue.minimum} character(s)`;
            else if (issue.type === 'number')
                return `${fieldName} must be ${issue.exact
                    ? `exactly equal to `
                    : issue.inclusive
                        ? `greater than or equal to `
                        : `greater than `}${issue.minimum}`;
            else if (issue.type === 'date')
                return `${fieldName} must be ${issue.exact
                    ? `exactly equal to `
                    : issue.inclusive
                        ? `greater than or equal to `
                        : `greater than `}${new Date(Number(issue.minimum))}`;
            else
                return 'Invalid input';
        case ZodIssueCode.too_big:
            if (issue.type === 'array')
                return `${fieldName} must contain ${issue.exact ? `exactly` : issue.inclusive ? `at most` : `less than`} ${issue.maximum} element(s)`;
            else if (issue.type === 'string')
                return `${fieldName} must contain ${issue.exact ? `exactly` : issue.inclusive ? `at most` : `under`} ${issue.maximum} character(s)`;
            else if (issue.type === 'number')
                return `${fieldName} must be ${issue.exact ? `exactly` : issue.inclusive ? `less than or equal to` : `less than`} ${issue.maximum}`;
            else if (issue.type === 'bigint')
                return `${fieldName} must be ${issue.exact ? `exactly` : issue.inclusive ? `less than or equal to` : `less than`} ${issue.maximum}`;
            else if (issue.type === 'date')
                return `${fieldName} must be ${issue.exact ? `exactly` : issue.inclusive ? `smaller than or equal to` : `smaller than`} ${new Date(Number(issue.maximum))}`;
            else
                return 'Invalid input';
        case ZodIssueCode.custom:
            return `Invalid input`;
        case ZodIssueCode.not_multiple_of:
            return `${fieldName} must be a multiple of ${issue.multipleOf}`;
        case ZodIssueCode.not_finite:
            return `${fieldName} must be finite`;
    }
    return ctx.defaultError;
};
export const createErrorMap = (fieldName) => (issue, ctx) => ({ message: getError(issue, ctx, fieldName) || ctx.defaultError });
