import { useRouteMatch } from 'react-router-dom';
export const useCustomerParams = () => {
    const { params } = useRouteMatch();
    const customer_id = parseCustomerParam(params.customer_id, 'customer id');
    const subscription_id = parseCustomerParam(params.subscription_id, 'subscription id');
    return { customer_id, subscription_id };
};
const parseCustomerParam = (param, paramName) => {
    // By default -1 for excluding addinional check for "undefined" in every use case and
    // more obvious bugfix if needed
    let paramValue = -1;
    if (param) {
        paramValue = parseInt(param);
        if (isNaN(paramValue)) {
            throw new Error(`Invalid ${paramName}`);
        }
    }
    return paramValue;
};
