import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useForm } from 'formoid';
import { ModalHeader, ModalShell, SelectField } from '~/common/components';
import { propagateBackendValidationErrors } from '~/common/utils';
import { useInitData, useSubscriptionData, useUpdateUploadWindowMutation, } from '~/subscriptions/hooks';
import { UploadWindowBanner } from './Banner';
const initialValues = {
    timezone: 'PST',
    on_day: 'today',
    submitted_before_hour: '07:00',
    submitted_at_hour: '18:00',
};
const deliveryDayOptions = [
    { name: 'same', value: 'today' },
    { name: 'next', value: 'tomorrow' },
];
export const EditUploadWindow = ({ onClose }) => {
    var _a;
    const subscription = useSubscriptionData();
    const init = useInitData();
    const { mutateAsync } = useUpdateUploadWindowMutation();
    const { fieldProps, handleSubmit, setErrors, isSubmitting } = useForm({
        initialValues: (_a = subscription.upload_window) !== null && _a !== void 0 ? _a : initialValues,
        validationStrategy: 'onBlur',
        validators: () => ({
            timezone: null,
            on_day: null,
            submitted_before_hour: null,
            submitted_at_hour: null,
        }),
    });
    const submit = () => handleSubmit((values) => {
        return mutateAsync(values)
            .then(onClose)
            .catch((error) => {
            propagateBackendValidationErrors(error, setErrors);
        });
    });
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Upload window" }), _jsxs(ModalShell, { className: "p-3 pb-5 w-[859px] flex flex-col gap-3 font-brand-t2r", onClose: onClose, onSubmit: submit, loading: isSubmitting, submitText: "Save", cancelText: "Cancel", children: [_jsxs("div", { className: "flex items-baseline gap-2", children: ["Customer timezone", _jsx(SelectField, { noSearch: true, size: "small", ...fieldProps('timezone'), className: "max-w-[90px]", options: init.upload_window_timezones })] }), _jsxs("div", { className: "flex items-center gap-1", children: ["If order submitted before", _jsx(SelectField, { noSearch: true, size: "small", ...fieldProps('submitted_before_hour'), className: "max-w-[90px]", options: init.hoursList }), ", we deliver on the", _jsx(SelectField, { noSearch: true, size: "small", ...fieldProps('on_day'), className: "max-w-[120px]", options: deliveryDayOptions }), "business day at", _jsx(SelectField, { noSearch: true, size: "small", ...fieldProps('submitted_at_hour'), className: "max-w-[90px]", options: init.hoursList })] }), _jsx(UploadWindowBanner, {})] })] }));
};
