import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIdParam } from '~/common/hooks';
import { httpClient } from '~/common/utils';
import { qk } from '~/root/query-keys';
export const useDeleteOrder = () => {
    const client = useQueryClient();
    const id = useIdParam();
    return useMutation({
        mutationFn: () => httpClient.delete(`/v1/staff/orders/${id}`),
        mutationKey: qk.orderDelete(id),
        onSuccess: () => {
            // TODO shouldn't backend send list refetch here?
            setTimeout(() => {
                client.removeQueries(qk.orderAll(id));
                client.invalidateQueries(qk.ordersList);
            });
        },
    });
};
