import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faCalendarDay, faInfoCircle, faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { useForm, validator } from 'formoid';
import { useState } from 'react';
import { Datepicker, DateRangePicker, IconBox, Input, InputField, ModalHeader, ModalShell, Popover, SelectField, Switcher, } from '~/common/components';
import { customValidator, cx, formatDayFullMonthYear, getDateWithoutTime, minLengthMessage, noop, notifyBackendError, propagateBackendValidationErrors, record, } from '~/common/utils';
import { useCapacityChangingMutation, useInitData } from '../hooks';
import { transformFormValues } from '../utils';
const isOtherReason = (reason) => reason === 'Other reason';
const CapacityField = ({ points, errors, onChange, value, touched, disabled, ...props }) => {
    return (_jsxs("div", { children: [_jsx("div", { className: "font-brand-b1 mb-[4px]", children: "Capacity change" }), _jsxs("div", { className: "flex items-center gap-1 font-brand-t1m text-greyscale-500 mb-1", children: [_jsx("div", { "aria-label": "Capacity before change", className: "h-7 w-16 flex items-center justify-center bg-greyscale-200 rounded", children: points }), _jsx(IconBox, { size: "s", className: cx(disabled && 'text-greyscale-300'), icon: faLongArrowRight }), _jsx("input", { ...props, "data-tt": cx(disabled && 'Please, choose a team and designer'), "aria-label": "Capacity after change", className: cx('h-7 w-16 flex items-center justify-center text-center border border-solid def:border-greyscale-200 rounded', errors
                            ? 'text-danger-400 bg-danger-100 border-danger-100 focus:border-danger-400'
                            : 'focus:border-secondary-300', disabled && 'border-greyscale-300 cursor-not-allowed'), readOnly: disabled, onChange: (e) => onChange(e.target.valueAsNumber || null), value: value !== null && value !== void 0 ? value : '', type: "number" })] }), _jsxs("div", { className: "flex items-center gap-1 font-brand-b1 text-greyscale-500", children: [_jsx(IconBox, { size: "m", icon: faInfoCircle }), _jsx("span", { children: "If the person is on leave, please mark it on the shifts page" })] })] }));
};
export const CapacityChanging = ({ onClose, teamId, designerId, date, plan }) => {
    const [multiday, setMultiday] = useState(false);
    const { mutateAsync } = useCapacityChangingMutation();
    const init = useInitData();
    const initialValues = {
        team_id: teamId,
        designer_id: designerId !== null && designerId !== void 0 ? designerId : null,
        date_range: { start: date !== null && date !== void 0 ? date : getDateWithoutTime(), end: date !== null && date !== void 0 ? date : getDateWithoutTime() },
        reason: '',
        capacity: null,
        comment: '',
    };
    const { fieldProps, handleSubmit, isSubmitting, setErrors, values } = useForm({
        initialValues,
        validationStrategy: 'onBlur',
        validators: ({ reason }) => ({
            team_id: validator.defined('Team is required.'),
            designer_id: validator.defined('Designer is required.'),
            date_range: null,
            reason: validator.minLength(2, minLengthMessage('Reason', 2)),
            capacity: validator.sequence(customValidator.required(), customValidator.nonNegativeInteger()),
            comment: isOtherReason(reason)
                ? validator.minLength(5, minLengthMessage('Comment', 5))
                : null,
        }),
    });
    const submit = () => handleSubmit((values) => {
        return mutateAsync(transformFormValues(values))
            .then(onClose)
            .catch((error) => propagateBackendValidationErrors(error, setErrors))
            .catch(notifyBackendError);
    });
    const designers = values.team_id ? init.designers_popup_options[values.team_id] : [];
    const points = plan !== null && plan !== void 0 ? plan : (values.designer_id ? init.designers_points[values.designer_id] : null);
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Capacity changing" }), _jsxs(ModalShell, { className: "w-[600px] p-3 space-y-3", loading: isSubmitting, onClose: onClose, onSubmit: submit, submitText: "Change capacity", children: [_jsxs("div", { className: "grid grid-cols-2 gap-3", children: [_jsx(SelectField, { ...fieldProps('team_id'), title: "Design team", placeholder: "Choose a design team", options: init.teams_popup_options }), _jsx(SelectField, { ...fieldProps('designer_id'), title: "Designer", placeholder: "Choose from list", options: designers }), _jsx(Popover, { trigger: (props) => (_jsx(Input, { ...props, title: "Date", className: "w-100", icon: faCalendarDay, value: multiday
                                        ? record.values(values.date_range).map(formatDayFullMonthYear).join(' - ')
                                        : formatDayFullMonthYear(values.date_range.start), readOnly: true })), content: ({ onClose }) => multiday ? (_jsx(DateRangePicker, { minDate: new Date(), maxDate: init.max_date, value: fieldProps('date_range').value, onChange: (value) => {
                                        fieldProps('date_range').onChange(value);
                                        onClose();
                                    } })) : (_jsx(Datepicker, { minDate: new Date(), maxDate: init.max_date, value: fieldProps('date_range').value.start, onChange: (date) => {
                                        fieldProps('date_range').onChange({ start: date, end: date });
                                        onClose();
                                    } })) }), _jsxs("label", { className: "flex items-center h-5 space-x-1 self-end", children: [_jsx(Switcher, { value: multiday, onChange: setMultiday, onBlur: noop, theme: "dark" }), _jsx("span", { className: "font-brand-t4m text-greyscale-500", children: "Multiday" })] })] }), _jsx(CapacityField, { ...fieldProps('capacity'), points: points, disabled: !values.team_id || !values.designer_id }), _jsx(SelectField, { ...fieldProps('reason'), title: "Reason", placeholder: "Choose from list", options: init.reasons }), _jsx(InputField, { ...fieldProps('comment'), title: cx('Comment', !isOtherReason(values.reason) && '(optional)'), placeholder: "Leave a comment", type: "text" })] })] }));
};
