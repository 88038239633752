import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { faLink, faSitemap, faUser } from '@fortawesome/pro-regular-svg-icons';
import { FileUploadField, InputField } from '~/common/components';
import { useFileUploadState } from '~/common/hooks';
export const EditOrganizationInfoView = ({ fieldProps, setErrors, organization, }) => {
    var _a, _b;
    const uploadFieldProps = useFileUploadState({
        tag: 'organisation_logo',
        props: fieldProps('company_logo_id'),
        onError: (errors) => setErrors('company_logo_id', errors),
        existingFilename: (_b = (_a = organization === null || organization === void 0 ? void 0 : organization.logo) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : null,
    });
    return (_jsxs(_Fragment, { children: [_jsx(InputField, { ...fieldProps('name'), icon: faSitemap, title: "Name of organization", placeholder: "Enter a name", type: "text" }), _jsx(InputField, { ...fieldProps('url'), icon: faLink, title: "Company URL", placeholder: "Enter a link", type: "text" }), _jsx(InputField, { ...fieldProps('owner_email'), icon: faUser, title: "Owner", hint: _jsxs(_Fragment, { children: ["Enter a valid email address. It should follow the format", ' ', _jsx("b", { className: "font-brand-c1m", children: "example@example.com" }), " and be associated with an active email account"] }), placeholder: "Enter an owner", type: "text" }), _jsx(FileUploadField, { ...uploadFieldProps, title: "Company logo (optional)", required: false, hintLeft: "Supports: PNG, JPG", hintRight: "Max size is 2 MB and 600x600 px", accept: ".png, .jpg, .jpeg" })] }));
};
