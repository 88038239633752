import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { useIdParam } from '~/common/hooks';
import { httpClient, map } from '~/common/utils';
import { CollaborationMessage } from '~/orders/domain';
import { useUpdateOrderCache } from '~/orders/hooks';
import { qk } from '~/root/query-keys';
import { useUpdateMessagesCache } from './useUpdateMessagesCache';
export const useSendMessage = ({ onSuccess }) => {
    const id = useIdParam();
    const updateMessages = useUpdateMessagesCache();
    const updateOrder = useUpdateOrderCache();
    return useMutation({
        mutationKey: qk.collaborationSendMessage(id),
        mutationFn: (data) => {
            return httpClient.post(`/v1/staff/orders/${id}/collaboration/comments`, {
                data,
                decoder: z.object({ comment: CollaborationMessage }),
            });
        },
        onSuccess: ({ comment: message }) => {
            // TODO dedupe with useMessageEvents
            updateMessages((messages) => ({
                ...messages,
                pages: messages.pages.map((page, index, pages) => {
                    return page.items.has(message.id) || index === pages.length - 1
                        ? {
                            ...page,
                            items: map.add(page.items, message.id, message),
                        }
                        : page;
                }),
            }));
            updateOrder((order) => ({
                ...order,
                collaboration: {
                    last_read_comment_id: message.id,
                    last_read_at: message.date,
                    unread_comments_count: 0,
                    total_comments_count: order.collaboration.total_comments_count + 1,
                },
            }));
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
        },
    });
};
