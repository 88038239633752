import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Button, LayoutHeader, LayoutTitle, SearchField, useModalContext, } from '~/common/components';
import { Filter, getFilterProps, stringify, useParsedQuery } from '~/common/kits/table';
import { useCurrentUserData } from '~/root';
import { useInitData } from '../hooks';
import { AddTeam } from './AddTeam';
const getTransformedFilters = (offices) => ({
    office: offices,
    search: [],
});
export const Header = () => {
    const { queryParams, onFilterChange } = useParsedQuery();
    const { permissions } = useCurrentUserData();
    const { offices } = useInitData();
    const { modalOpener } = useModalContext();
    const filterProps = getFilterProps(getTransformedFilters(offices), queryParams.filter, onFilterChange);
    return (_jsxs(LayoutHeader, { className: "flex justify-between items-center", children: [_jsx(LayoutTitle, { children: "Design teams" }), _jsx(SearchField, { className: "ml-auto w-[272px]", value: stringify(queryParams.filter.search), onChange: (value) => onFilterChange('search', value) }), _jsx(Filter, { ...filterProps('office'), placeholder: "All offices", className: "ml-2 w-25" }), permissions.designTeamsCreate && (_jsx(Button, { onClick: modalOpener(AddTeam), color: "primary", icon: faPlus, className: "ml-2", children: "Add team" }))] }));
};
