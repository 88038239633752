import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faUsdSquare } from '@fortawesome/pro-regular-svg-icons';
import { useMemo } from 'react';
import { ModalBody, ModalHeader, useModalContext } from '~/common/components';
import { cx } from '~/common/utils';
import { useOrderData } from '~/orders/hooks';
import { SectionButton } from '~/orders/Order';
import { normalizeCustomerTreatments } from '~/orders/utils';
import { Head } from './Head';
import { Row } from './Row';
const Content = ({ onClose }) => {
    const { customer, treatments } = useOrderData();
    const data = useMemo(() => {
        return customer.treatments
            ? normalizeCustomerTreatments(customer.treatments, treatments)
            : null;
    }, [customer.treatments, treatments]);
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Individual pricing" }), _jsxs(ModalBody, { className: "w-[712px]", children: [_jsx(Head, { treatments: treatments }), _jsx("div", { className: "space-y-1", children: data === null || data === void 0 ? void 0 : data.map(([hour, entries], key) => _jsx(Row, { hour: hour, entries: entries }, key)) })] })] }));
};
export const IndividualPricing = () => {
    const { customer } = useOrderData();
    const { modalOpener } = useModalContext();
    const isDisabled = !customer.treatments;
    return (_jsx(SectionButton, { "data-tt": cx(isDisabled && 'No individual pricing for this customer'), disabled: isDisabled, onClick: modalOpener(Content), icon: faUsdSquare, children: "Individual pricing" }));
};
