import { jsx as _jsx } from "react/jsx-runtime";
import { Errors, Tooltip } from '~/common/components';
import { InlineSectionedSelect } from './InlineSectionedSelect';
export const InlineSectionedSelectField = ({ errors, touched, ...props }) => (
// It is too hard to merge two callback refs from Floating UI correctly:
// Tooltip & Dropdown, and even then useFocus stops showing tooltip on input
// focus the way it should.
// It is safer to resort to div wrapping.
//
// This solution should work on paper, but sadly rabbit hole is too deep for comfort:
// https://github.com/floating-ui/floating-ui/issues/1503
// forwardRefs with generics are ugly anyway:
// https://fettblog.eu/typescript-react-generic-forward-refs/
_jsx(Tooltip, { color: "danger", content: errors && _jsx(Errors, { errors: errors }), children: _jsx("div", { children: _jsx(InlineSectionedSelect, { ...props, error: errors !== null }) }) }));
