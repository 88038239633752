class PlaceholderError extends Error {
    constructor(libraryName, environment) {
        super();
        this.name = 'PlaceholderError';
        this.message = `You are using library placeholder for ${libraryName} in ${environment}. 
    Troubleshooting steps: 
    - Check out webpack alias configuration
    - Check out node_modules_placeholders
    `;
    }
}
export const throwPlaceholderErrorIf = (condition, libraryName) => {
    if (condition) {
        throw new PlaceholderError(libraryName, process.env.REACT_APP_ENV);
    }
    else {
        console.warn(`${libraryName}: local development placeholder is used`);
    }
};
