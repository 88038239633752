import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Button, PageDetailsCard, Show, useModalContext } from '~/common/components';
import { cx } from '~/common/utils';
import { useCustomerData } from '~/customers/hooks';
import { useCurrentUserData } from '~/root';
import { useCustomerContext } from '../context';
import { InviteTeamMember } from '../TeamMembers';
const Count = ({ label, value, className, }) => (_jsxs("div", { className: cx('text-center', label.length < 8 && 'w-[70px]'), children: [_jsx("div", { className: cx('text-[32px] font-medium leading-[32px]', className), children: value }), _jsx("div", { className: "font-brand-t2m text-greyscale-500", children: label })] }));
export const TeamMembersBlock = () => {
    const { permissions } = useCurrentUserData();
    const { members } = useCustomerData();
    const { setActiveTab } = useCustomerContext();
    const { modalOpener } = useModalContext();
    const hasMembers = members.active + members.deleted + members.pending > 0;
    return (_jsxs(PageDetailsCard, { label: "Team members", bodyClassName: "flex items-center justify-center gap-4 py-4", actionButton: hasMembers ? (
        // TODO use routing for that
        _jsx(Button, { color: "grey", onClick: () => setActiveTab('members'), children: "See all" })) : (permissions.customersMembersInvite && (_jsx(Button, { onClick: modalOpener(InviteTeamMember), color: "grey", icon: faPlus, className: "ml-2", children: "Invite" }))), children: [_jsxs(Show, { if: hasMembers, children: [_jsx(Count, { label: "active", value: members.active, className: "text-primary-300" }), _jsx(Count, { label: "invited", value: members.pending, className: "text-warning-400" }), _jsx(Count, { label: "deleted", value: members.deleted, className: "text-danger-400" })] }), _jsx(Show, { if: !hasMembers, children: _jsx(Count, { label: "team members", value: 0, className: "text-greyscale-400" }) })] }));
};
