import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faSquare } from '@fortawesome/pro-regular-svg-icons';
import { faCheckSquare, faSquareMinus } from '@fortawesome/pro-solid-svg-icons';
import { forwardRef, useEffect, useRef } from 'react';
import { IconBox, Tooltip } from '~/common/components';
import { cx, tw } from '~/common/utils';
import css from './Checkbox.module.scss';
const checkboxLabelClassName = tw `
  relative flex items-center
  m-0 gap-1
  font-brand-b1 text-greyscale-500
  select-none cursor-pointer
`;
export const Checkbox = forwardRef(({ text, color = 'primary', value: checked, className, onChange, checkboxHint, indeterminate = false, disabled = false, ...props }, ref) => {
    const inputRef = useRef(null);
    useEffect(() => {
        if (inputRef.current)
            inputRef.current.indeterminate = indeterminate;
    }, [indeterminate]);
    return (_jsxs("label", { ...props, className: cx(checkboxLabelClassName, className, 'group'), ref: ref, children: [_jsx("input", { ref: inputRef, className: "peer absolute opacity-0 w-[1px] h-[1px]", type: "checkbox", checked: checked, onChange: () => onChange(!checked), disabled: disabled }), _jsx(Tooltip, { content: checkboxHint, children: _jsx(IconBox, { size: "m", role: "checkbox", "aria-checked": checked, icon: indeterminate ? faSquareMinus : checked ? faCheckSquare : faSquare, className: cx('peer-disabled:text-greyscale-200 peer-disabled:cursor-not-allowed', css.checkbox, checked || indeterminate ? css[color] : 'text-greyscale-400') }) }), text] }));
});
