import { jsx as _jsx } from "react/jsx-runtime";
import { faReceipt } from '@fortawesome/pro-regular-svg-icons';
import { useMemo } from 'react';
import { LayoutContent } from '~/common/components';
import { Nothing, Table, useBackendTable } from '~/common/kits/table';
import { getTableConfig } from './table-config';
export const PaymentsTable = ({ minified = false, data }) => {
    const tableConfig = useMemo(() => getTableConfig(minified), [minified]);
    const view = useBackendTable({
        data,
        tableConfig,
        isFetching: false,
    });
    if (!data || !view) {
        return _jsx(LayoutContent, { loading: true });
    }
    return (_jsx(Table, { ...view, totalPages: minified ? 1 : view.totalPages, items: minified ? view.items.slice(0, 3) : view.items, containerClassName: "mt-0", noDataPlaceholder: _jsx(Nothing, { icon: faReceipt, className: minified ? 'h-[188px]' : 'h-[490px]', text: "No payments yet", size: minified ? 'medium' : 'large' }) }));
};
