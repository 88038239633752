import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faArrowFromBottom } from '@fortawesome/pro-regular-svg-icons';
import { Link } from '~/common/components';
import { useIdParam } from '~/common/hooks';
import { DateRangeFilter } from '~/common/kits/table';
import { getExportUrl } from './utils';
export const Header = () => {
    const id = useIdParam();
    return (_jsxs("div", { className: "flex justify-between items-center bg-greyscale-0 pl-3 p-2 border-b border-b-greyscale-200 border-solid", children: [_jsx("div", { className: "flex", children: _jsx(DateRangeFilter, {}) }), _jsx(Link.Icon, { size: "m", iconSize: "s", color: "grey-outline", icon: faArrowFromBottom, href: getExportUrl(id) })] }));
};
