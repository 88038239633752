if (navigator.userAgent.includes('Chrome')) {
    document.body.classList.add('is-chrome');
}
// TODO refactor table filters, allow some schema or configuration, which would
// describe filter value types (number, string, number[], string[], boolean,
// etc.)
//
// that way we can get rid of a workaround like this, which adds brackets for
// user's bookmarked urls for certain fields, which is uglier than removing
// brackets for any filter value
const url = window.location.href;
if (url.includes('orders') && url.includes('filter')) {
    const url = new URL(window.location.href);
    for (const filter of [
        'status',
        'customer_type',
        'manager',
        'team',
        'paymentStatus',
        'custom_portal',
    ]) {
        const filterKey = `filter[${filter}]`;
        const value = url.searchParams.get(filterKey);
        if (value && !value.includes('[')) {
            url.searchParams.set(filterKey, `[${value}]`);
        }
    }
    window.history.replaceState(null, 'Inner platform', url);
}
export {};
