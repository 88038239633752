import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { FloatingTree, useFloatingParentNodeId, } from '@floating-ui/react';
import { forwardRef } from 'react';
import { useTabIndex } from '~/common/hooks';
const FloatingTreeHoc = ({ children }) => {
    const parentId = useFloatingParentNodeId();
    // This is a root, so we wrap it with the tree
    if (parentId === null) {
        return _jsx(FloatingTree, { children: children });
    }
    return _jsx(_Fragment, { children: children });
};
export function withFloatingTree(Component) {
    return (props) => {
        return _jsx(FloatingTreeHoc, { children: Component && _jsx(Component, { ...props }) });
    };
}
export const FloatingDiv = forwardRef((props, ref) => {
    const tabIndex = useTabIndex();
    return _jsx("div", { ...props, ref: ref, tabIndex: tabIndex });
});
export const useArrow = (arrowRef, placement) => {
    const staticSide = {
        top: 'bottom',
        right: 'left',
        bottom: 'top',
        left: 'right',
    }[placement.split('-')[0]];
    const arrowProps = (middlewareData) => {
        var _a, _b, _c, _d;
        return ({
            ref: arrowRef,
            style: {
                left: ((_a = middlewareData.arrow) === null || _a === void 0 ? void 0 : _a.x) != null ? `${(_b = middlewareData.arrow) === null || _b === void 0 ? void 0 : _b.x}px` : '',
                top: ((_c = middlewareData.arrow) === null || _c === void 0 ? void 0 : _c.y) != null ? `${(_d = middlewareData.arrow) === null || _d === void 0 ? void 0 : _d.y}px` : '',
                [staticSide]: '-4px',
            },
        });
    };
    return { arrowProps, staticSide };
};
// TODO extend this once we know what other extensions our users want to use
export const outsidePress = (event) => {
    const target = event.target;
    return !target.shadowRoot && !target.closest('.Toastify');
};
