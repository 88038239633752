import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { invoice } from '~/assets/images';
import { cardBrandToImg, cx } from '~/common/utils';
import styles from './PaymentMethods.module.scss';
export const Method = (props) => {
    var _a, _b;
    switch (props.type) {
        case 'card':
            return (_jsxs("div", { className: cx(styles.container, 'items-center', { [styles.default]: props.default }), children: [_jsx("img", { className: "mr-3", src: cardBrandToImg(props.brand), alt: props.brand }), _jsxs("span", { className: "mr-3", children: ["**** **** **** ", props.last4] }), _jsx("span", { children: props.expires }), props.default && _jsx("span", { className: "ml-auto text-success-400", children: "Default" })] }));
        case 'invoice':
            return (_jsxs("div", { className: cx(styles.container, 'space-x-3', { [styles.default]: props.default }), children: [_jsx("img", { className: "mb-auto", src: invoice, alt: "Invoice" }), _jsxs("div", { className: "flex flex-col space-y-1", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx("span", { className: "font-light text-sm text-greyscale-400", children: "Company details" }), _jsx("span", { className: "text-sm text-greyscale-600", children: (_a = props.company) !== null && _a !== void 0 ? _a : '—' })] }), _jsxs("div", { className: "flex flex-col", children: [_jsx("span", { className: "font-light text-sm text-greyscale-400", children: "PO number" }), _jsx("span", { className: "text-sm text-greyscale-600", children: (_b = props.poNumber) !== null && _b !== void 0 ? _b : '—' })] })] }), _jsxs("div", { className: "flex flex-col", children: [_jsx("span", { className: "font-light text-sm text-greyscale-400", children: "Billing information" }), _jsx("span", { className: "text-sm text-greyscale-600", children: props.address })] })] }));
    }
};
