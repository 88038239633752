import { useForm, validateForm } from 'formoid';
import { useEffect, useMemo, useState } from 'react';
import { customValidator, propagateBackendValidationErrors, scrollToError } from '~/common/utils';
import { advancedInvoiceOptionsFormValidators, paymentMethodFormValidators, paymentMethodIdValidator, useProductPrice, } from '~/customers/Customer';
import { useInitData, useSubscriptionData, useTopupSubscriptionMutation, } from '~/subscriptions/hooks';
const subscriptionPlanFormValidators = {
    subscription_product_id: null,
    amount: customValidator.positiveInteger(),
    quantity: customValidator.positiveInteger('Should be > 0'),
    credits: customValidator.positiveInteger(),
};
export const useTopupForm = () => {
    var _a, _b, _c;
    const subscription = useSubscriptionData();
    const { supported_currencies, product_options } = useInitData();
    const productPrice = useProductPrice(subscription.owner_id);
    const topupSubscription = useTopupSubscriptionMutation();
    const [topupProducts, productOptions] = useMemo(() => {
        const canTopup = subscription.products.filter((product) => 'credits' in product);
        return [canTopup, canTopup.map(({ id, plan: { name } }) => ({ value: id, name }))];
    }, [subscription.products]);
    const form = useForm({
        initialValues: {
            subscription_product_id: topupProducts[0].id,
            amount: topupProducts[0].amount,
            quantity: 1,
            credits: topupProducts[0].credits,
            payment_method_id: (_b = (_a = subscription.payment_method) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null,
            po_number: subscription.payment_method &&
                'po_number' in subscription.payment_method &&
                subscription.payment_method.po_number !== null
                ? subscription.payment_method.po_number
                : '',
            po_expires_at: subscription.payment_method && 'po_expires_at' in subscription.payment_method
                ? subscription.payment_method.po_expires_at
                : null,
            prevent_sending_invoice: !subscription.send_invoice,
            attention: subscription.payment_method &&
                'attention' in subscription.payment_method &&
                subscription.payment_method.attention !== null
                ? subscription.payment_method.attention
                : '',
            custom_lines: subscription.payment_method &&
                'invoice_lines' in subscription.payment_method &&
                subscription.payment_method.invoice_lines !== null
                ? subscription.payment_method.invoice_lines
                : '',
        },
        validationStrategy: 'onBlur',
        validators: (values) => ({
            ...subscriptionPlanFormValidators,
            ...paymentMethodFormValidators(values),
            ...advancedInvoiceOptionsFormValidators(values),
        }),
    });
    const isInvoicePaymentMethodSelected = form.values.payment_method_id === ((_c = subscription.invoicePaymentMethod) === null || _c === void 0 ? void 0 : _c.id);
    const product = useMemo(() => ({
        product_type: 'topup',
        subscription_product_id: form.values.subscription_product_id,
        subscription_id: subscription.id,
        payment_method_id: form.values.payment_method_id,
        quantity: form.values.quantity,
        credits: form.values.credits,
        amount: form.values.amount,
    }), [
        form.values.amount,
        form.values.credits,
        form.values.payment_method_id,
        form.values.quantity,
        form.values.subscription_product_id,
        subscription.id,
    ]);
    const [invoicePreviewData, setInvoicePreviewData] = useState();
    useEffect(() => {
        if (!isInvoicePaymentMethodSelected) {
            return undefined;
        }
        const previewData = {
            ...product,
            custom_lines: form.values.custom_lines,
            attention: form.values.attention,
        };
        validateForm(previewData, {
            ...subscriptionPlanFormValidators,
            ...paymentMethodIdValidator,
            ...advancedInvoiceOptionsFormValidators(previewData),
            product_type: null,
            subscription_id: null,
        }).then((result) => {
            setInvoicePreviewData(result.id === 'Success' ? previewData : undefined);
        });
    }, [form.values.attention, form.values.custom_lines, isInvoicePaymentMethodSelected, product]);
    const calculatePrice = async (payload) => {
        productPrice.calculate(payload).catch((error) => {
            propagateBackendValidationErrors(error, form.setErrors);
            productPrice.reset();
        });
    };
    const validatePriceCalculation = async (values) => {
        const result = await validateForm(values, {
            ...subscriptionPlanFormValidators,
            ...paymentMethodIdValidator,
        });
        if (result.id === 'Success') {
            calculatePrice(values);
        }
        else {
            productPrice.reset();
        }
    };
    const resetForm = () => {
        productPrice.reset();
        form.handleReset();
    };
    const onPriceRelatedFieldChange = (fieldName) => (value) => {
        form.fieldProps(fieldName).onChange(value);
        if (value === null) {
            productPrice.reset();
        }
        else {
            const payload = {
                ...product,
                [fieldName]: value,
            };
            validatePriceCalculation(payload);
        }
    };
    const onPaymentMethodChange = (value) => {
        var _a, _b, _c;
        onPriceRelatedFieldChange('payment_method_id')(value);
        const invoiceMethodPO = (_a = subscription.invoicePaymentMethod) === null || _a === void 0 ? void 0 : _a.po_number;
        if (form.values.payment_method_id === ((_b = subscription.invoicePaymentMethod) === null || _b === void 0 ? void 0 : _b.id)) {
            form.setValues((prevFormValues) => ({
                ...prevFormValues,
                po_number: '',
                po_expires_at: null,
                prevent_sending_invoice: false,
            }));
        }
        else if (value === ((_c = subscription.invoicePaymentMethod) === null || _c === void 0 ? void 0 : _c.id) && invoiceMethodPO) {
            form.setValues((prevFormValues) => ({
                ...prevFormValues,
                po_number: invoiceMethodPO,
            }));
        }
    };
    const submit = (onSuccess) => {
        form.handleSubmit({
            onSuccess: ({ subscription_product_id, amount, credits, quantity, payment_method_id, po_number, po_expires_at, prevent_sending_invoice, attention, custom_lines, }) => {
                var _a;
                const payload = {
                    subscription_product_id,
                    amount,
                    credits,
                    quantity,
                    payment_method: {
                        payment_method_id,
                        po_number: po_number || undefined,
                        po_expires_at: po_expires_at || undefined,
                    },
                };
                if (payment_method_id === ((_a = subscription.invoicePaymentMethod) === null || _a === void 0 ? void 0 : _a.id)) {
                    payload.invoice_details = { attention, custom_lines, prevent_sending_invoice };
                }
                return topupSubscription
                    .mutateAsync(payload)
                    .then(() => {
                    onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
                    resetForm();
                })
                    .catch((error) => {
                    scrollToError();
                    propagateBackendValidationErrors(error, form.setErrors);
                });
            },
            onFailure: scrollToError,
        });
    };
    useEffect(() => {
        validatePriceCalculation(product);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return {
        form,
        invoicePreviewData,
        products: productOptions,
        price: productPrice.price,
        isInvoicePaymentMethodSelected,
        ownerId: subscription.owner_id,
        currency: subscription.currency,
        billingDate: subscription.renews_at,
        supportedCurrencies: supported_currencies,
        isCustomerTrusted: subscription.is_trusted,
        paymentMethods: subscription.payment_methods,
        isLoading: topupSubscription.isLoading || productPrice.isLoading,
        renewalPeriodOptions: product_options.subscription.allowed_renewal_periods,
        submit,
        resetForm,
        onPaymentMethodChange,
        onPriceRelatedFieldChange,
    };
};
