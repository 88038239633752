import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { CountryFlag, Tooltip } from '~/common/components';
import { cx } from '~/common/utils';
import { getTimezoneDate } from '../utils';
export const CountriesTime = ({ date = new Date(), className, countries }) => {
    const [mainCountry, ...tooltipContent] = countries.map(({ country_code, timezone_offset }) => ({
        country_code,
        time: getTimezoneDate(date, timezone_offset),
    }));
    return (_jsx(Tooltip, { content: tooltipContent.map(({ country_code, time }, key) => (_jsx(CountryTimeItem, { countryCode: country_code, time: time }, key))), placement: "bottom-end", compensateOffset: 5, children: _jsx(CountryTimeItem, { className: className, countryCode: mainCountry.country_code, time: mainCountry.time }) }));
};
const CountryTimeItem = forwardRef(({ countryCode, time, className }, ref) => (_jsxs("div", { ref: ref, className: cx('def:flex items-center space-x-1 cursor-default', className), children: [_jsx(CountryFlag, { countryCode: countryCode }), _jsx("span", { className: "text-greyscale-400 font-brand-t4m", children: time })] })));
