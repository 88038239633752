import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge, CountryFlag, IconBox, Tooltip } from '~/common/components';
import { cx } from '~/common/utils';
import { useTimezonesData } from '~/root';
export const InfoBar = ({ icon, text, hint, hintClickable, className, onClick, truncate, }) => {
    if (text === false) {
        return null;
    }
    return (_jsxs("li", { className: cx('flex items-start gap-2 py-[12px] border-b border-greyscale-200 last:border-b-0', 'font-brand-b1 text-greyscale-600', className), onClick: onClick, children: [_jsx(Tooltip, { content: hint, placement: "right", clickable: hintClickable, children: _jsx(IconBox, { size: "m", className: "ml-1", icon: icon }) }), typeof text === 'string' || text === null ? (_jsx("span", { "aria-label": typeof hint === 'string' ? hint : undefined, className: cx(truncate && 'truncate'), children: text || '—' })) : (text)] }));
};
export const CountryInfoBar = ({ country_code, name, className, timezone_id, }) => {
    const timezones = useTimezonesData();
    const timezone = timezones.find(({ id }) => id === timezone_id);
    return (_jsxs("li", { className: cx('flex items-start gap-2 py-2 border-b border-greyscale-200', 'font-brand-b1 text-greyscale-600', className), children: [_jsx(CountryFlag, { "data-tt": "Country", "data-tt-placement": "right", size: 24, countryCode: country_code, className: "ml-1" }), _jsx("span", { "aria-label": "country", className: "truncate", children: name }), !!timezone && (_jsx(Badge, { "data-tt": "Owner's timezone", color: "grey", className: "shrink-0", children: timezone === null || timezone === void 0 ? void 0 : timezone.shortName }))] }));
};
