import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faBriefcase } from '@fortawesome/pro-regular-svg-icons';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Customer, General, TeamMembers, TransactionsList, Activity } from './Customer';
import { CustomersList } from './CustomersList';
import { CUSTOMERS_URL } from './hooks';
// fixes circular dependency
// eslint-disable-next-line import/order
import { useCurrentUserData, useVisited } from '~/root';
const Customers = () => {
    const { path } = useRouteMatch();
    const { permissions } = useCurrentUserData();
    useVisited('customers');
    return (_jsxs(Switch, { children: [_jsx(Route, { exact: true, path: path, component: CustomersList }), _jsx(Route, { exact: true, path: `${path}/:customer_id(\\d+)`, render: () => (_jsx(Customer, { children: _jsx(General, {}) })) }), _jsx(Route, { exact: true, path: `${path}/:customer_id(\\d+)/members`, render: () => (_jsx(Customer, { children: _jsx(TeamMembers, {}) })) }), permissions.customersTransactionsView && (_jsx(Route, { exact: true, path: `${path}/:customer_id(\\d+)/transactions`, render: () => (_jsx(Customer, { children: _jsx(TransactionsList, {}) })) })), _jsx(Route, { exact: true, path: `${path}/:customer_id(\\d+)/activity`, render: () => (_jsx(Customer, { children: _jsx(Activity, {}) })) })] }));
};
const nav = {
    icon: faBriefcase,
    label: 'Customers',
    path: CUSTOMERS_URL,
};
export const customersModule = {
    canShow: (permissions) => permissions.customersView,
    route: {
        path: CUSTOMERS_URL,
        component: Customers,
    },
    nav,
};
