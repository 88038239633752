export const PRICING_HOURS = ['10', '24', '48', '72'];
export const normalizeTreatments = (customerTreatments, treatments, defaultTreatments, options) => {
    const presentTreatments = treatments.filter(({ id }) => customerTreatments[id]);
    return {
        labels: presentTreatments,
        pricing: PRICING_HOURS.map((hour) => {
            const is10hFeature = hour === '10';
            const title = { hour };
            if (is10hFeature) {
                title.hint =
                    'Available for users with Pro subscription or other users with this feature enabled';
            }
            if (is10hFeature && !options.is10hEnabled) {
                return {
                    title,
                    entries: presentTreatments.map(() => ({
                        value: null,
                        individual: false,
                    })),
                };
            }
            return {
                title,
                entries: presentTreatments.map(({ id }) => ({
                    value: customerTreatments[id][hour],
                    individual: customerTreatments[id][hour] !== defaultTreatments[id][hour],
                })),
            };
        }),
    };
};
