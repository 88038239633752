import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { StickyLeftTableCell } from '~/common/components';
import { cx, getColorByShiftRange } from '~/common/utils';
import { TableCell } from '../common';
import css from './CapacityItem.module.scss';
const CapacityCell = memo(({ shift }) => {
    const { range, capacity } = shift;
    const color = getColorByShiftRange(range);
    return (_jsx("span", { className: cx('inline-flex justify-center py-[2px] w-[43px] font-brand-t5 rounded-full', css.capacityItem, css[color !== null && color !== void 0 ? color : 'red']), children: capacity }));
});
export const TeamRow = ({ name, days, capacityPerShift }) => (_jsxs("tr", { children: [_jsx(StickyLeftTableCell, { width: 248, children: _jsx("div", { className: "flex items-center justify-between h-full pl-1 pointer-events-auto min-h-[39px] box-content", children: _jsx("div", { className: "font-brand-t3 truncate uppercase", children: name }) }) }), days.map((_, index) => {
            var _a;
            return (_jsx(TableCell, { className: "relative", children: _jsx("div", { className: "flex h-full items-center justify-center gap-1 flex-wrap py-1", children: (_a = capacityPerShift === null || capacityPerShift === void 0 ? void 0 : capacityPerShift[index]) === null || _a === void 0 ? void 0 : _a.map((shift) => shift.capacity > 0 && _jsx(CapacityCell, { shift: shift }, shift.id)) }) }, index));
        })] }));
