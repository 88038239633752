import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faRotateLeft, faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faCircleMinus, faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { Button } from '~/common/components';
import { useEvent, useToggle } from '~/common/hooks';
import { cx, ignoreHandled } from '~/common/utils';
import { getInitialImageDimensions, getInitialImageOffset } from './helpers';
import { usePanAndZoom } from './usePanAndZoom';
import css from './ImagePanAndZoom.module.scss';
const ZOOM_PANEL_AUTOCLOSE_PERIOD = 10000;
export const ImageZoomAndPan = ({ src, alt, imageClassname, className, children, onZoom, onInited, }) => {
    var _a;
    const zoomPanelToggleTimeout = useRef();
    const imageDimensions = useRef();
    const [isImageInited, setIsImageInited] = useState(false);
    const [isZoomPanelOpen, toggleZooomPanel] = useToggle(false);
    const handleZoom = () => {
        onZoom && onZoom();
        if (zoomPanelToggleTimeout.current) {
            clearTimeout(zoomPanelToggleTimeout.current);
        }
        if (!isZoomPanelOpen) {
            toggleZooomPanel();
        }
        zoomPanelToggleTimeout.current = setTimeout(toggleZooomPanel, ZOOM_PANEL_AUTOCLOSE_PERIOD);
    };
    const zoomPanelManualToggle = useEvent(() => {
        if (zoomPanelToggleTimeout.current) {
            clearTimeout(zoomPanelToggleTimeout.current);
        }
        toggleZooomPanel();
    });
    const { containerRef, imageWrapperRef, position, scale, reset, changeZoom, onMouseDown } = usePanAndZoom({ maxScale: 5, onZoom: handleZoom });
    const onLoad = (event) => {
        if (containerRef.current) {
            imageDimensions.current = getInitialImageDimensions(containerRef.current.getBoundingClientRect(), event.currentTarget);
            resetPanAndZoom();
            setIsImageInited(true);
            onInited && onInited();
        }
    };
    const resetPanAndZoom = useCallback(() => {
        if (containerRef.current && imageDimensions.current) {
            reset({
                position: getInitialImageOffset(containerRef.current.getBoundingClientRect(), imageDimensions.current),
            });
        }
    }, [containerRef, reset]);
    useLayoutEffect(() => {
        setIsImageInited(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src]);
    return (_jsxs("div", { className: cx(className, css.container), ref: containerRef, onDoubleClick: ignoreHandled(resetPanAndZoom), onMouseDown: ignoreHandled(onMouseDown), children: [_jsxs("div", { ref: imageWrapperRef, style: {
                    transform: `translate(${position.x}px, ${position.y}px)`,
                    width: `${(((_a = imageDimensions.current) === null || _a === void 0 ? void 0 : _a.width) || 0) * scale}px`,
                }, className: css.imageWrapper, children: [children, _jsx("img", { className: cx(imageClassname, css.image, { [css.notInited]: !isImageInited }), alt: alt, src: src, onLoad: onLoad })] }), _jsx(ZoomPanel, { zoom: scale, updateZoom: changeZoom, reset: resetPanAndZoom, externalToggle: [isZoomPanelOpen, zoomPanelManualToggle] })] }));
};
function ZoomPanel({ externalToggle, zoom, zoomStep = 0.5, updateZoom, reset, }) {
    const [isOpen, toggle] = externalToggle;
    return (_jsx("div", { "data-stop-propagation": true, className: cx(css.controlPanel, { [css.zoomPanelOpen]: isOpen }), children: _jsxs("div", { className: "flex gap-1", children: [_jsxs("div", { className: "flex relative", children: [_jsx(Button.Icon, { color: "secondary", icon: isOpen ? faTimes : faSearch, size: "m", iconSize: "s", className: "rounded-full bg-greyscale-650 z-10", onClick: toggle }), _jsx("div", { className: css.contentWrapper, children: _jsx("div", { className: cx('bg-greyscale-525 rounded-r-full', css.zoomPanelContent, {
                                    [css.zoomPanelOpen]: isOpen,
                                }), children: _jsxs("div", { className: css.buttonsBlock, children: [_jsx(Button.Icon, { color: "grey-dark", icon: faCircleMinus, className: "bg-transparent", onClick: () => updateZoom(zoom - zoomStep) }), _jsxs("span", { className: "text-greyscale-200 font-brand-t4m select-none", children: [Math.floor(zoom * 100), "%"] }), _jsx(Button.Icon, { color: "grey-dark", icon: faCirclePlus, className: "bg-transparent", onClick: () => updateZoom(zoom + zoomStep) })] }) }) })] }), _jsx(Button.Icon, { color: "secondary", icon: faRotateLeft, size: "m", iconSize: "s", className: cx('rounded-full bg-greyscale-650', css.resetButton), onClick: reset })] }) }));
}
