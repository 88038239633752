import { nonNullable } from './refinements';
/**
 * Use this function inside of child components to get query data without
 * prop-drilling. If query data is not defined, then an error is thrown.
 *
 * Example:
 *
 * ```
 * const order = assertQueryData(useOrder());
 * ```
 */
export function assertQueryData(result) {
    return nonNullable(result.data);
}
