import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { faLink, faCircle } from '@fortawesome/pro-regular-svg-icons';
import { faChevronLeft, faChevronRight, faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { useLayoutEffect, useRef } from 'react';
import { Button, Show } from '~/common/components';
import { addQueryParams, copyToClipboard } from '~/common/utils';
import { useAnnotationInfo, useUpdateAnnotation } from '~/orders/hooks';
import { useCurrentUserData } from '~/root';
import { useRevisionsContext } from '../../revisionsContext';
import { AddComment } from './AddComment';
import { Comment } from './Comment';
export const Dialog = (props) => {
    const { previewsState, previewsActions } = useRevisionsContext();
    const { revisionId, slideId } = useAnnotationInfo();
    const updateAnnotation = useUpdateAnnotation();
    const commentsBox = useRef(null);
    const currentUser = useCurrentUserData();
    const canRemoveComment = (comment, index) => {
        return (props.canEdit && currentUser.id === comment.user.id && index === props.comments.length - 1);
    };
    const onResolveToggle = () => {
        updateAnnotation.mutateAsync({
            revisionId,
            slideId,
            annotationId: props.id,
            resolved: !props.resolved,
        });
    };
    useLayoutEffect(() => {
        if (commentsBox.current) {
            commentsBox.current.scrollTop = commentsBox.current.scrollHeight;
        }
    }, [props.comments]);
    const handleCopy = () => {
        const link = addQueryParams(window.location.href, { 'annotation-id': props.id });
        copyToClipboard(link);
    };
    return (_jsxs("div", { className: "w-[400px] border-2 border-primary-300 bg-greyscale-0 rounded", "data-stop-propagation": true, children: [_jsxs("div", { className: "flex items-center px-2 h-5 border-b border-b-greyscale-100", children: [_jsxs("span", { className: "font-brand-t1m text-greyscale-600 mr-2", children: ["#", props.index] }), _jsx(Button.Icon, { size: "m", icon: faChevronLeft, onClick: previewsActions.selectPrevAnnotation, disabled: previewsState.firstAnnotationSelected }), _jsx(Button.Icon, { size: "m", icon: faChevronRight, onClick: previewsActions.selectNextAnnotation, disabled: previewsState.lastAnnotationSelected, className: "ml-[4px]" }), _jsx(Button.Icon, { "data-tt": "Copy link", "data-tt-placement": "bottom", size: "m", icon: faLink, onClick: handleCopy, className: "ml-auto" }), _jsx(Button.Icon, { "data-tt": props.resolved ? 'Unresolve' : 'Resolve', "data-tt-placement": "bottom", size: "m", className: "ml-[4px]", icon: props.resolved ? faCheckCircle : faCircle, loading: updateAnnotation.isLoading, onClick: onResolveToggle })] }), _jsx("div", { className: "overflow-y-auto max-h-[350px] space-y-1 py-2", ref: commentsBox, children: props.comments.map((comment, index) => (_jsx(Comment, { annotationId: props.id, canRemove: canRemoveComment(comment, index), comment: comment }, comment.id))) }), _jsx(Show, { if: props.canEdit, children: _jsx(AddComment, { annotationId: props.id }) })] }));
};
