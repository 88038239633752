import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { Checkbox, ModalHeader, ModalShell } from '~/common/components';
import { toggleSet } from '~/common/utils';
import { useInitData, usePermissionsMutation, useUserData } from '../hooks';
import { getPermissionSections } from './utils';
const Section = ({ text, children }) => (_jsxs("div", { children: [_jsx("div", { className: "font-brand-t3 text-greyscale-600 uppercase", children: text }), _jsx("ul", { className: "m-0 mt-1 space-y-1", children: children })] }));
export const ManagePermissions = ({ onClose }) => {
    const user = useUserData();
    const init = useInitData();
    const [permissions, setPermissions] = useState(new Set(user.permissions));
    const { isLoading, mutateAsync } = usePermissionsMutation();
    const permissionsColumns = useMemo(() => {
        return getPermissionSections(init.permissions, user.permissions);
    }, [init.permissions, user.permissions]);
    const uploadPermissions = () => {
        mutateAsync({ permissions: [...permissions] }).then(onClose);
    };
    const togglePermission = toggleSet(setPermissions);
    useEffect(() => {
        setPermissions(new Set(user.permissions));
    }, [user.permissions]);
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Manage permissions" }), _jsx(ModalShell, { className: "flex gap-2 p-3 spacing-x-4 w-[712px]", loading: isLoading, onClose: onClose, onSubmit: uploadPermissions, children: permissionsColumns.map((column, index) => (_jsx("div", { className: "w-1/2 space-y-4", children: column.map(({ name, permissions: sectionPermissions }) => (_jsx(Section, { text: name, children: sectionPermissions.map((p) => (_jsx("li", { children: _jsx(Checkbox, { text: p.label, value: permissions.has(p.name), onChange: () => togglePermission(p.name) }) }, p.name))) }, name))) }, index))) })] }));
};
