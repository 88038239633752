import { useForm } from 'formoid';
import { useCallback, useEffect, useRef, useState } from 'react';
import { advancedInvoiceOptionsFormValidators, getProductInitialValues, getUUIDValidators, initialAdvancedInvoiceOptionsValues, initialPaymentMethodValues, initialProductsSharedValues, initialSubscriptionScheduleValues, initialSuccessTeamValues, paymentMethodFormValidators, productsSharedValidators, subscriptionScheduleFormValidators, successTeamFormValidators, } from '~/customers/Customer';
export const getDefaultCreateSubscriptionInitialValues = (productsList) => {
    const initialProductsValues = productsList.reduce((initialValues, productType) => ({
        ...initialValues,
        ...getProductInitialValues(productType),
    }), {});
    return {
        ...initialProductsValues,
        ...initialProductsSharedValues,
        ...initialSubscriptionScheduleValues,
        ...initialPaymentMethodValues,
        ...initialSuccessTeamValues,
        ...initialAdvancedInvoiceOptionsValues,
        activate_at: new Date(),
    };
};
const createSubscriptionValidators = (values) => ({
    ...subscriptionScheduleFormValidators(values),
    ...paymentMethodFormValidators(values),
    ...successTeamFormValidators(values),
    ...advancedInvoiceOptionsFormValidators(values),
    ...productsSharedValidators,
    keep_individual_prices: null,
});
export const useSubscriptionForm = ({ initialValues, initialProducts, productsList, }) => {
    // Some sort of hacks to be able to receive updated list of products from outside of this hook for whatever reason
    // Just provide callback to any of exported methods as second argument
    // and you'll receive new products as this callback first argument
    const callback = useRef();
    const [products, setProducts] = useState(initialProducts || []);
    const productsValidators = useCallback((values) => {
        const newValidators = productsList.reduce((validators, productType) => {
            validators = {
                ...validators,
                ...getUUIDValidators({
                    type: productType,
                    values,
                    enabled: products.some(({ type }) => type === productType),
                }),
            };
            return validators;
        }, {});
        return newValidators;
    }, [productsList, products]);
    const form = useForm({
        initialValues,
        validators: (values) => ({
            ...createSubscriptionValidators(values),
            ...productsValidators(values),
        }),
        validationStrategy: 'onBlur',
    });
    const addProduct = (type) => {
        const newProduct = {
            type: type !== null && type !== void 0 ? type : null,
        };
        setProducts((products) => [...products, newProduct]);
    };
    const removeProduct = (index, cb) => {
        const newProducts = [...products];
        const [removedElement] = newProducts.splice(index, 1);
        setProducts(newProducts);
        if (cb) {
            callback.current = cb;
        }
        return removedElement;
    };
    const updateProductType = ({ type, index }, cb) => {
        const newProducts = [...products];
        newProducts[index] = { ...newProducts[index], type };
        setProducts(newProducts);
        if (cb) {
            callback.current = cb;
        }
        return newProducts;
    };
    useEffect(() => {
        if (!products.length) {
            addProduct();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products.length]);
    useEffect(() => {
        if (callback.current) {
            callback.current(products);
            callback.current = null;
        }
    }, [products]);
    return {
        form,
        products: {
            list: products,
            validators: productsValidators,
            addProduct,
            removeProduct,
            updateProductType,
        },
    };
};
