import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faSortCircle, faSortCircleDown, faSortCircleUp } from '@fortawesome/pro-solid-svg-icons';
import { useMemo } from 'react';
import { DayHeader, IconBox, Popover } from '~/common/components';
import { cx, formatMonthDay, getDateWithoutTime, ignoreHandled } from '~/common/utils';
import { TableCell } from '../common';
const handleSortOrder = (state) => {
    if (state.ascending === null)
        return { ...state, ascending: true };
    if (state.ascending)
        return { ...state, ascending: false };
    return { shiftId: null, dayIndex: null, ascending: null };
};
export const Header = ({ days, sort, setSort, filter }) => {
    const today = useMemo(() => getDateWithoutTime(), []);
    const todayStr = useMemo(() => formatMonthDay(today), [today]);
    const handleSorting = (index) => {
        setSort((prev) => handleSortOrder({ ...prev, shiftId: filter.shiftId, dayIndex: index }));
    };
    return (_jsx(_Fragment, { children: days.map((day, index) => (_jsx(Popover, { compensateOffset: 8, placement: "bottom-start", className: "p-0 py-[4px] flex flex-col bg-greyscale-50", trigger: (props) => {
                const hasShifts = !!day.availableShifts.length;
                const isActive = todayStr === day.date;
                return (_jsx(TableCell, { ...props, onClick: ignoreHandled((e) => {
                        if (hasShifts)
                            filter.shiftId ? handleSorting(index) : props.onClick(e);
                    }), className: cx('h-4', hasShifts ? 'cursor-pointer' : 'cursor-not-allowed'), active: isActive, children: _jsxs("div", { className: "group flex items-center justify-center h-full relative", children: [_jsx(DayHeader, { isWeekend: day.isWeekend, label: day.date }), _jsx("div", { className: "absolute right-1 bottom-1 text-sm", children: index === sort.dayIndex ? (_jsx(IconBox, { size: "s", "data-stop-propagation": true, className: "text-secondary-400", onClick: () => {
                                        setSort((prev) => handleSortOrder({ ...prev, dayIndex: index }));
                                    }, icon: sort.ascending ? faSortCircleUp : faSortCircleDown })) : (_jsx(IconBox, { size: "s", className: cx('group-hover:opacity-100 opacity-0 transition-opacity text-greyscale-500', !hasShifts && 'invisible'), icon: faSortCircle })) })] }) }));
            }, content: ({ onClose }) => day.availableShifts.map((shift) => {
                return (_jsxs("button", { className: cx('py-[5px] px-1 text-start', 'font-brand-b1 hover:bg-greyscale-200 hover:text-secondary-400', sort.shiftId === shift.id &&
                        sort.dayIndex === index &&
                        'bg-greyscale-200 text-secondary-400'), onClick: () => {
                        setSort((prev) => {
                            const ascending = prev.dayIndex === index && prev.shiftId === shift.id
                                ? !prev.ascending
                                : true;
                            return { shiftId: shift.id, dayIndex: index, ascending };
                        });
                        onClose();
                    }, children: ["Sort by shift ", shift.sign] }, shift.id));
            }) }, day.date))) }));
};
