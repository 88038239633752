import { useMutation } from '@tanstack/react-query';
import { useIdParam } from '~/common/hooks';
import { httpClient } from '~/common/utils';
import { CalculatePrice } from '~/orders/domain';
import { qk } from '~/root/query-keys';
export function useCalculateOrderPrice({ onSuccess, onError, }) {
    const id = useIdParam();
    const { mutateAsync, isLoading } = useMutation({
        mutationFn: (data) => {
            return httpClient.post(`v1/staff/orders/${id}/calculate`, {
                data,
                decoder: CalculatePrice,
            });
        },
        mutationKey: qk.orderCalcPrice(id),
        onSuccess,
        onError,
    });
    return { mutateAsync, isLoading };
}
