import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu, useModalContext } from '~/common/components';
import { useIdParam } from '~/common/hooks';
import { cx, httpClient } from '~/common/utils';
import { useOrderData } from '~/orders/hooks';
import { useCurrentUserData } from '~/root';
import { TimeLine } from '../Modals';
import { ConfirmDelete } from './Delete';
export const OrderMenu = () => {
    const id = useIdParam();
    const order = useOrderData();
    const canDelete = order.status === 'Cancelled' || order.status === 'Completed';
    const { permissions } = useCurrentUserData();
    const { modalOpener } = useModalContext();
    const handleTrelloSync = () => httpClient.post(`/v1/orders/${id}/trello/sync`);
    return (_jsxs(Menu, { children: [_jsx(Menu.Item, { onClick: handleTrelloSync, children: "Sync with Trello" }), _jsx(Menu.Item, { onClick: modalOpener(TimeLine), children: "Status timeline" }), permissions.ordersUpdate && (_jsx(Menu.Item, { "data-tt": cx(!canDelete && 'You need first to change the status to Cancelled or Completed'), onClick: modalOpener(ConfirmDelete), disabled: !canDelete, children: "Delete order" }))] }));
};
