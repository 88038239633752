import { useHistory, useLocation } from 'react-router-dom';
import { createContextPair } from '~/common/kits/context';
import { nonNullable } from '~/common/utils';
import { CUSTOMERS_URL } from '../hooks';
import { useCustomerParams } from './General';
export const tabs = [
    { key: 'general', label: 'General' },
    { key: 'transactions', label: 'Transactions' },
    { key: 'members', label: 'Team members' },
    { key: 'activity', label: 'Activity' },
];
const getActiveTab = (pathname) => {
    const lastElement = nonNullable(pathname.split('/').pop());
    return isNaN(+lastElement) ? lastElement : 'general';
};
const useContextData = () => {
    const { pathname } = useLocation();
    const history = useHistory();
    const { customer_id } = useCustomerParams();
    return {
        activeTab: getActiveTab(pathname),
        setActiveTab: (tab) => {
            history.push({
                pathname: `${CUSTOMERS_URL}/${customer_id}` + (tab === 'general' ? '' : `/${tab}`),
                search: '',
            });
        },
    };
};
const [, useCustomerContext, withCustomerContext] = createContextPair(useContextData);
export { useCustomerContext, withCustomerContext };
