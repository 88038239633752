import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { arrow, autoUpdate, flip, FloatingPortal, offset, safePolygon, shift, useDelayGroup, useDelayGroupContext, useDismiss, useFloating, useFocus, useHover, useInteractions, useRole, useTransitionStatus, } from '@floating-ui/react';
import { cloneElement, useCallback, useRef, useState } from 'react';
import { cx } from '~/common/utils';
import fade from '~/styles/fade.module.scss';
import { useArrow } from '../Floating';
import styles from './Tooltip.module.scss';
export const Tooltip = ({ content, children, color = 'white', className, placement = 'top', delay = 300, compensateOffset = 0, noArrow = false, clickable = false, }) => {
    const [open, setOpen] = useState(false);
    const arrowRef = useRef(null);
    // delayGroup
    const { delay: groupDelay, setCurrentId } = useDelayGroupContext();
    const onOpenChange = useCallback((open) => {
        setOpen(open);
        if (open) {
            setCurrentId(content);
        }
    }, [content, setCurrentId]);
    const { context, refs, middlewareData, placement: floatingPlacement, floatingStyles, } = useFloating({
        placement,
        open: open && !!content,
        // delayGroup
        onOpenChange,
        middleware: [
            offset(12 - compensateOffset),
            flip(),
            shift({ padding: 8 }),
            arrow({ element: arrowRef }),
        ],
        whileElementsMounted: autoUpdate,
    });
    const { arrowProps, staticSide } = useArrow(arrowRef, floatingPlacement);
    const { getReferenceProps, getFloatingProps } = useInteractions([
        useHover(context, {
            // delayGroup
            delay: groupDelay === 0 ? { open: delay, close: 50 } : groupDelay,
            restMs: 40,
            handleClose: clickable ? safePolygon() : undefined,
        }),
        useFocus(context),
        useRole(context, { role: 'tooltip' }),
        useDismiss(context),
        // delayGroup
        useDelayGroup(context, { id: content }),
    ]);
    const { isMounted, status } = useTransitionStatus(context);
    return (
    // TODO use render-props instead of cloneElement to be able to merge
    // multiple ref callbacks from above, make multiRef(ref1, ref2) utility
    // use-case - dropdown popover & tooltip on select fields on the same node
    _jsxs(_Fragment, { children: [cloneElement(children, getReferenceProps({ ref: refs.setReference, ...children.props })), isMounted && (_jsx(FloatingPortal, { children: _jsxs("div", { ...getFloatingProps({
                        ref: refs.setFloating,
                        className: cx(styles.tooltip, styles[color], styles[staticSide], className, fade.floating, clickable && 'pointer-events-auto'),
                        style: floatingStyles,
                        onClick: () => setOpen(false),
                    }), "data-status": status, children: [!noArrow && _jsx("div", { ...arrowProps(middlewareData), className: styles.arrow }), content] }) }))] }));
};
