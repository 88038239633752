import { useCallback, useState } from 'react';
export function useConfirmDeletion(canRemove) {
    const [showRemoveButton, setShowRemoveButton] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const handleMouseEnter = useCallback(() => {
        if (canRemove)
            setShowRemoveButton(true);
    }, [canRemove]);
    const handleMouseLeave = useCallback(() => {
        if (canRemove)
            setShowRemoveButton(false);
    }, [canRemove]);
    const handleRemove = useCallback(() => {
        setShowConfirmation(true);
    }, []);
    const handleCancel = useCallback(() => {
        setShowConfirmation(false);
    }, []);
    return {
        handleCancel,
        handleMouseEnter,
        handleMouseLeave,
        handleRemove,
        showConfirmation,
        showRemoveButton,
    };
}
