import { useCallback, useRef, useState } from 'react';
import { getNewPanPosition } from './helpers';
const DEFAULT_SCALE = 1;
const DEFAULT_INITIAL_POSITION = { x: 0, y: 0 };
const INITIAL_MOUSE_POSITION = { x: 0, y: 0 };
export const usePanAndZoomState = (params) => {
    const previousMousePosition = useRef(INITIAL_MOUSE_POSITION);
    const [scale, setScale] = useState((params === null || params === void 0 ? void 0 : params.initialScale) || DEFAULT_SCALE);
    const [position, setPosition] = useState((params === null || params === void 0 ? void 0 : params.initialPosition) || DEFAULT_INITIAL_POSITION);
    const startPan = useCallback((event) => {
        previousMousePosition.current = {
            x: event.clientX,
            y: event.clientY,
        };
    }, []);
    // TODO: Limit panning to left at least half of the image inside container
    const pan = useCallback((event, panLimits) => {
        setPosition((prevPosition) => {
            const deltaMouseX = event.clientX - previousMousePosition.current.x;
            const deltaMouseY = event.clientY - previousMousePosition.current.y;
            const newPositionX = prevPosition.x + deltaMouseX;
            const newPositionY = prevPosition.y + deltaMouseY;
            return getNewPanPosition({
                x: newPositionX,
                y: newPositionY,
            }, panLimits);
        });
        previousMousePosition.current = { x: event.clientX, y: event.clientY };
    }, []);
    const zoom = useCallback(({ scale: newScale, panLimits, containerRect, event, }) => {
        const zoomFactor = newScale / scale;
        setPosition((prevPosition) => {
            const { left, top, width, height } = containerRect;
            const origin = {
                x: (event === null || event === void 0 ? void 0 : event.clientX) ? event.clientX - left : width / 2,
                y: (event === null || event === void 0 ? void 0 : event.clientY) ? event.clientY - top : height / 2,
            };
            const offsetFromOrigin = { x: origin.x - prevPosition.x, y: origin.y - prevPosition.y };
            const newOffsetFromOrigin = {
                x: offsetFromOrigin.x * zoomFactor,
                y: offsetFromOrigin.y * zoomFactor,
            };
            return getNewPanPosition({
                x: prevPosition.x - (newOffsetFromOrigin.x - offsetFromOrigin.x),
                y: prevPosition.y - (newOffsetFromOrigin.y - offsetFromOrigin.y),
            }, panLimits);
        });
        setScale(newScale);
    }, [scale]);
    const reset = (partialUpdates) => {
        var _a, _b;
        setPosition((_a = partialUpdates === null || partialUpdates === void 0 ? void 0 : partialUpdates.position) !== null && _a !== void 0 ? _a : DEFAULT_INITIAL_POSITION);
        setScale((_b = partialUpdates === null || partialUpdates === void 0 ? void 0 : partialUpdates.scale) !== null && _b !== void 0 ? _b : DEFAULT_SCALE);
        previousMousePosition.current = INITIAL_MOUSE_POSITION;
    };
    return {
        scale,
        position,
        startPan,
        reset,
        zoom,
        pan,
    };
};
