import { addDays, differenceInDays, format, isWeekend, max, min } from 'date-fns';
import { z } from 'zod';
import { lastItem, nonNullable, range, TimelessDateFromIsoString } from '~/common/utils';
import { NumericIdNamePair, Office, Shift, UploadedFile } from '~/root';
import { getInvalidRangesFromValid } from './utils';
const VIRTUAL_PM_ID = -1;
const VIRTUAL_SUPPORT_ID = -2;
const areSameType = (a, b) => {
    return a.is_manager === b.is_manager && a.occupation.id === b.occupation.id;
};
export const timelineToDiscrete = (items, start) => {
    const result = [];
    for (const { width, ...item } of items) {
        for (const day of range(0, width - 1)) {
            result.push({
                ...item,
                offset: item.offset + day,
                date: addDays(start, item.offset + day),
            });
        }
    }
    return result;
};
export const offsetWidthToDiscrete = (placement, start) => range(0, placement.width - 1).map((day) => ({
    offset: placement.offset + day,
    date: addDays(start, placement.offset + day),
}));
export const timelineToRange = (byOffset, rangeStart, rangeEnd) => {
    const result = [];
    const offsetRange = range(0, differenceInDays(rangeEnd, rangeStart) - 1);
    for (const index of offsetRange) {
        const previousItem = byOffset[index - 1];
        const currentItem = byOffset[index];
        const lastInsertedBar = lastItem(result);
        if (!currentItem && !lastInsertedBar) {
            continue;
        }
        const shouldInsertNew = currentItem &&
            (!previousItem ||
                !lastInsertedBar ||
                (previousItem && lastInsertedBar && !areSameType(currentItem, previousItem)));
        if (shouldInsertNew) {
            result.push({ ...currentItem, offset: index, width: 1 });
            continue;
        }
        if (currentItem && previousItem && lastInsertedBar && areSameType(currentItem, previousItem)) {
            lastInsertedBar.width += 1;
        }
    }
    return result;
};
export const timelineToByOffset = (timeline) => {
    return timeline.reduce((timelines, item) => {
        timelines[item.offset] = item;
        return timelines;
    }, {});
};
export const Leave = z.object({
    id: z.number(),
    sign: z.string(),
    range: z.literal(null),
    leave_type: z
        .string()
        .nullable()
        .transform((value) => value !== null && value !== void 0 ? value : ''),
});
const Occupation = z.union([Shift, Leave]);
export const isLeave = (occupation) => occupation.range === null;
export const isShift = (occupation) => occupation.range !== null;
export const Init = z
    .object({
    occupations: z.array(Occupation),
    role_shifts: z.object({
        regular_pm: z.array(z.string()),
        subscription_pm: z.array(z.string()),
        customer_supporter: z.array(z.string()),
        designer: z.array(z.string()),
        design_manager: z.array(z.string()),
    }),
    holidays: z.array(z.object({
        office_ids: z.array(z.number()),
        team_ids: z.array(z.number()),
        dates: z.object({
            start: TimelessDateFromIsoString,
            end: TimelessDateFromIsoString,
        }),
    })),
    offices: z.array(NumericIdNamePair),
})
    .transform(({ occupations, holidays, role_shifts, offices }) => {
    const shiftsMap = occupations.reduce((map, occupation) => {
        if (isShift(occupation)) {
            map[occupation.sign] = occupation;
        }
        return map;
    }, {});
    const result = {
        occupations: occupations.reduce((occupations, occupation) => {
            occupations[occupation.id] = occupation;
            return occupations;
        }, {}),
        leaves: occupations.filter(isLeave),
        // TODO fix, this is weird
        getShifts(teamId) {
            switch (teamId) {
                case VIRTUAL_PM_ID:
                    return this.pm_shifts;
                case VIRTUAL_SUPPORT_ID:
                    return this.cs_shifts;
                default:
                    return this.design_shifts;
            }
        },
        cs_shifts: role_shifts.customer_supporter.map((ch) => shiftsMap[ch]),
        pm_shifts: role_shifts.regular_pm.map((ch) => shiftsMap[ch]),
        design_shifts: role_shifts.designer.map((ch) => shiftsMap[ch]),
        holidays,
        offices: [{ name: 'All offices', value: null }, ...offices],
    };
    return result;
});
export const TimelineItem = (start, occupations) => z
    .object({
    date: TimelessDateFromIsoString,
    is_manager: z.boolean(),
    occupation_id: z.number(),
})
    .transform((timelineItem) => ({
    date: timelineItem.date,
    offset: differenceInDays(timelineItem.date, start),
    is_manager: timelineItem.is_manager,
    occupation: occupations[timelineItem.occupation_id],
}));
const UserTimeline = (start, end, occupations, holidays) => z
    .object({
    id: z.number(),
    avatar: UploadedFile.nullable(),
    name: z.string(),
    timeline: z.array(TimelineItem(start, occupations)),
    office_id: z.number().nullable().catch(null),
})
    .transform((user) => {
    const timeline = timelineToByOffset(user.timeline);
    return {
        ...user,
        timeline,
        rangeTimeline: timelineToRange(timeline, start, end),
        holidays: user.office_id
            ? holidays.filter((holiday) => holiday.office_ids.has(nonNullable(user.office_id)))
            : [],
    };
});
export const Team = (start, end, occupations, holidays) => z
    .object({
    id: z.number(),
    name: z.string(),
    primary_manager_id: z.number().nullable(),
    office: Office,
    members: z.array(UserTimeline(start, end, occupations, holidays)),
})
    .transform((team) => ({
    ...team,
    holidays: holidays
        .filter((holiday) => holiday.office_ids.has(team.office.id) || holiday.team_ids.has(team.id))
        .map(({ offset, width }) => ({ offset, width })),
}));
const calculateOffsetRange = (rangeStart, start, end) => {
    return {
        start: differenceInDays(start, rangeStart),
        end: differenceInDays(end, rangeStart),
    };
};
export const Shifts = (start, end, occupations, holidays) => {
    const holidaysInRange = holidays
        .filter(
    // all holidays that at least partly in range
    ({ dates }) => {
        const startTime = start.getTime();
        const endTime = end.getTime();
        const dateStartTime = dates.start.getTime();
        const dateEndTime = dates.end.getTime();
        return ((dateStartTime > startTime || dateEndTime > startTime) &&
            (dateStartTime < endTime || dateEndTime < endTime));
    })
        .map(({ dates, team_ids, office_ids }) => {
        const constrainedDates = {
            start: max([dates.start, start]),
            end: min([dates.end, end]),
        };
        const result = {
            offset: differenceInDays(constrainedDates.start, start),
            width: differenceInDays(constrainedDates.end, constrainedDates.start) + 1,
            team_ids: new Set(team_ids),
            office_ids: new Set(office_ids),
        };
        return result;
    });
    return z
        .object({
        pms: z.array(UserTimeline(start, end, occupations, holidaysInRange)),
        supporters: z.array(UserTimeline(start, end, occupations, holidaysInRange)),
        teams: z.array(Team(start, end, occupations, holidaysInRange)),
        // constrain valid ranges to the current timeline
        design_team_valid_ranges: z
            .array(z.object({
            team_id: z.number(),
            user_id: z.number(),
            dates: z.object({
                start: TimelessDateFromIsoString.transform((date) => max([date, start])),
                end: z.union([
                    z.literal(null).transform(() => end),
                    TimelessDateFromIsoString.transform((date) => min([date, end])),
                ]),
            }),
        }))
            .transform((validRanges) => validRanges.reduce((validRanges, { team_id, user_id, dates }) => {
            if (!validRanges[team_id]) {
                validRanges[team_id] = {};
            }
            validRanges[team_id][user_id] = calculateOffsetRange(start, dates.start, dates.end);
            return validRanges;
        }, {})),
    })
        .transform(({ design_team_valid_ranges: validRangesMap, ...shifts }) => {
        const defaultRange = {
            start: 0,
            end: differenceInDays(end, start),
        };
        return {
            teams: [
                {
                    id: VIRTUAL_PM_ID,
                    name: 'PM',
                    members: shifts.pms.map((member) => ({
                        ...member,
                        validRange: defaultRange,
                        invalidRanges: [],
                    })),
                },
                {
                    id: VIRTUAL_SUPPORT_ID,
                    name: 'Support',
                    members: shifts.supporters.map((member) => ({
                        ...member,
                        validRange: defaultRange,
                        invalidRanges: [],
                    })),
                },
                ...shifts.teams.map((team) => ({
                    ...team,
                    members: team.members.map((member) => {
                        var _a, _b;
                        const validRange = (_b = (_a = validRangesMap[team.id]) === null || _a === void 0 ? void 0 : _a[member.id]) !== null && _b !== void 0 ? _b : defaultRange;
                        return {
                            ...member,
                            validRange,
                            invalidRanges: getInvalidRangesFromValid(validRange, defaultRange),
                        };
                    }),
                })),
            ],
            days: range(0, differenceInDays(end, start) - 1).map((dayNr) => {
                const date = addDays(start, dayNr);
                return {
                    date,
                    label: format(date, 'iii d').toLowerCase(),
                    isWeekend: isWeekend(date),
                };
            }),
            rangeStart: start,
            rangeEnd: end,
        };
    });
};
export const TimelineUpdated = (start, occupations) => z
    .object({
    timelines: z.array(z.object({
        team_id: z.number().nullable().catch(null),
        user_id: z.number(),
        date: TimelessDateFromIsoString,
        is_manager: z.boolean(),
        occupation_id: z.number(),
    })),
})
    .transform(({ timelines }) => {
    const timelineMapByUser = {};
    for (const { team_id: optionalTeamId, user_id, occupation_id, ...timelineItem } of timelines) {
        // pm and supports have virtual ids
        // we'll put timeline items without team_id there
        const team_id = optionalTeamId !== null && optionalTeamId !== void 0 ? optionalTeamId : VIRTUAL_PM_ID;
        if (!timelineMapByUser[team_id]) {
            timelineMapByUser[team_id] = {};
        }
        if (!timelineMapByUser[team_id][user_id]) {
            timelineMapByUser[team_id][user_id] = {};
        }
        const offset = differenceInDays(timelineItem.date, start);
        timelineMapByUser[team_id][user_id][offset] = {
            ...timelineItem,
            offset,
            occupation: occupations[occupation_id],
        };
    }
    // this will allow pm and support categories with virtual ids to get
    // timeline item updates using either of the maps without complicating
    // updating code
    timelineMapByUser[VIRTUAL_SUPPORT_ID] = timelineMapByUser[VIRTUAL_PM_ID];
    return { timeline: timelineMapByUser };
});
export const TimelineDeleted = (start) => z
    .object({
    timelines: z.array(z.object({
        team_id: z.number().nullable(),
        user_id: z.number(),
        date: TimelessDateFromIsoString,
    })),
})
    .transform(({ timelines }) => {
    const offsetsMapByUser = {};
    for (const { team_id: optionalTeamId, user_id, date } of timelines) {
        // pm and supports have virtual ids
        // we'll put timeline items without team_id there
        const team_id = optionalTeamId !== null && optionalTeamId !== void 0 ? optionalTeamId : VIRTUAL_PM_ID;
        if (!offsetsMapByUser[team_id]) {
            offsetsMapByUser[team_id] = {};
        }
        if (!offsetsMapByUser[team_id][user_id]) {
            offsetsMapByUser[team_id][user_id] = new Set();
        }
        const offset = differenceInDays(date, start);
        offsetsMapByUser[team_id][user_id].add(offset);
    }
    // this will allow pm and support categories with virtual ids to get
    // timeline item updates using either of the maps without complicating
    // updating code
    offsetsMapByUser[VIRTUAL_SUPPORT_ID] = offsetsMapByUser[VIRTUAL_PM_ID];
    return { offsetsMapByUser };
});
export const tabs = [
    { key: 'all', label: 'All teams' },
    { key: 'pm', label: 'PM' },
    { key: 'support', label: 'Support' },
    { key: 'design', label: 'Design' },
];
const tabsKeys = new Set(tabs.map(({ key }) => key));
export const isValidTab = (tab) => tabsKeys.has(tab);
