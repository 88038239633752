import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { UsersGroup } from '~/common/components';
import { useIdParam } from '~/common/hooks';
import { useInitData } from '~/orders/hooks';
export const Viewers = () => {
    const id = useIdParam();
    const init = useInitData();
    const viewers = init.visitors[id];
    if (!(viewers === null || viewers === void 0 ? void 0 : viewers.length)) {
        return null;
    }
    return (_jsxs("div", { className: "flex flex-center", children: [_jsx("span", { className: "mr-1 font-brand-c1m text-greyscale-500", children: "Viewing" }), _jsx(UsersGroup, { maxVisible: 3, users: viewers })] }));
};
