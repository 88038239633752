import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faBuilding } from '@fortawesome/pro-regular-svg-icons';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Office } from './Office';
import { OfficesList } from './OfficesList';
export const OFFICES_URL = '/offices';
const Offices = () => {
    const { path } = useRouteMatch();
    return (_jsxs(Switch, { children: [_jsx(Route, { exact: true, path: path, component: OfficesList }), _jsx(Route, { path: `${path}/:id(\\d+)`, component: Office })] }));
};
const nav = {
    icon: faBuilding,
    label: 'Offices',
    path: OFFICES_URL,
};
export const officesModule = {
    canShow: (permissions) => permissions.officesView,
    route: {
        path: OFFICES_URL,
        component: Offices,
    },
    nav,
};
