import { jsx as _jsx } from "react/jsx-runtime";
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { IconBox } from '~/common/components';
import { cx } from '~/common/utils';
export const LayoutContent = ({ children, className, loading }) => {
    if (loading) {
        return (_jsx("div", { className: "flex items-center justify-center def:h-[600px]", children: _jsx(IconBox, { className: "text-6xl text-secondary-300 animate-spin", icon: faSpinnerThird }) }));
    }
    return (_jsx("main", { className: cx('p-2 duration-300 ease-in-out grid def:grid-rows-[min-content,_fit-content(100%)] grid-cols-1 overflow-auto', className), children: children }));
};
