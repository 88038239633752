import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { array, option } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { useEffect, useMemo, useState } from 'react';
import { Button, Popover, Show } from '~/common/components';
import { useRootStore } from '~/root/store';
import { Header } from './Header';
import { useNotificationEvents, useNotifications, useReadAllNotifications } from './hooks';
import { Notifications } from './Notifications';
import { group } from './utils';
export const NotificationsPopover = () => {
    const { fetchNextPage, hasNextPage, hasUnread, isFetchingNextPage, notifications, status } = useNotifications();
    const unreadNotifications = useMemo(() => {
        return pipe(notifications, option.map(array.filter(({ is_read }) => !is_read)));
    }, [notifications]);
    const setUnreadCount = useRootStore((store) => store.setUnreadCount);
    useEffect(() => {
        setUnreadCount(pipe(unreadNotifications, option.map((unread) => unread.length), option.toNullable));
        return () => setUnreadCount(null);
    }, [setUnreadCount, unreadNotifications]);
    const readAll = useReadAllNotifications();
    const handleReadAll = (onClose) => {
        readAll.mutate(undefined, { onSuccess: onClose });
    };
    const unreadCount = useMemo(() => {
        return pipe(unreadNotifications, option.filter(array.isNonEmpty), option.map(array.size), option.toNullable);
    }, [unreadNotifications]);
    const tabs = [
        { key: 'all', label: 'All notifications' },
        { key: 'unread', label: unreadCount ? `Unread (${unreadCount})` : 'Unread' },
    ];
    const [activeTab, setActiveTab] = useState(tabs[0].key);
    const grouped = useMemo(() => {
        return pipe(activeTab === 'unread' ? unreadNotifications : notifications, option.filter(array.isNonEmpty), option.map(group), option.toNullable);
    }, [activeTab, notifications, unreadNotifications]);
    useNotificationEvents();
    return (_jsx(Popover, { color: "lightGrey", className: "p-0 w-[500px]", content: (props) => (_jsxs(_Fragment, { children: [_jsx(Header, { activeTab: activeTab, isLoading: readAll.isLoading, onChangeTab: setActiveTab, onRead: () => handleReadAll(props.onClose), tabs: tabs }), _jsx(Notifications, { ...props, activeTab: activeTab, fetchNextPage: fetchNextPage, hasNextPage: hasNextPage, isFetchingNextPage: isFetchingNextPage, notifications: grouped, status: status })] })), trigger: (props) => (_jsx(Button.Icon, { size: "m", ...props, icon: faBell, className: "mr-2", children: _jsx(Show, { if: hasUnread, children: _jsx("div", { className: "absolute bottom-[4px] right-[4px] bg-primary-300 rounded-full h-1 w-1" }) }) })) }));
};
