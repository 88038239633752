import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LayoutContent, withModalContext } from '~/common/components';
import { Breakdown } from '../Breakdown';
import { CapacityPerShift } from '../CapacityPerShift';
import { useBreakdown, useCapacityTotals, useDailyUtilization } from '../hooks';
import { CapacityLeft } from './CapacityLeft';
import { DayOverview } from './DayOverview';
import { Header } from './Header';
import { Utilization } from './Utilization';
export const Overview = withModalContext(() => {
    const { data: totals } = useCapacityTotals();
    const { data: utilization } = useDailyUtilization();
    const { data: breakdown } = useBreakdown();
    if (!totals || !utilization || !breakdown) {
        return _jsx(LayoutContent, { loading: true });
    }
    return (_jsxs(LayoutContent, { children: [_jsx(Header, {}), _jsx("div", { className: "overflow-auto h-full", children: _jsxs("div", { className: "relative grid grid-cols-[minmax(0px,_3fr)_minmax(386px,_1fr)] gap-2 w-full", children: [_jsxs("div", { className: "col-span-1 space-y-3", children: [_jsx(Utilization, { utilization: utilization }), _jsx(CapacityLeft, { utilization: utilization }), _jsx(Breakdown, {}), _jsx(CapacityPerShift, { ...breakdown })] }), _jsx("div", { className: "col-span-1 flex flex-col sticky top-0 p-2 rounded bg-greyscale-0 h-[calc(100vh-152px)]", children: _jsx(DayOverview, { capacityTotals: totals }) })] }) })] }));
});
