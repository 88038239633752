import { useCallback, useState } from 'react';
const isFilesEqual = (file1, file2) => ['type', 'size', 'name', 'lastModified'].every((field) => file1[field] === file2[field]);
export function useFilesState(maxLength) {
    const [filesList, setFilesList] = useState([]);
    const addFiles = useCallback((filesToAdd) => {
        setFilesList((files) => {
            const newUploadedFiles = Array.from(filesToAdd).filter((newFile) => files.every((prevFile) => !isFilesEqual(prevFile, newFile)));
            const avaliableSpotsForNewFiles = maxLength - files.length;
            const shouldTruncate = newUploadedFiles.length > avaliableSpotsForNewFiles;
            if (shouldTruncate) {
                newUploadedFiles.length = avaliableSpotsForNewFiles;
            }
            return [...newUploadedFiles, ...files];
        });
    }, [maxLength]);
    const removeFile = useCallback((fileToRemove) => {
        setFilesList((files) => files.filter((file) => !isFilesEqual(fileToRemove, file)));
    }, []);
    return [filesList, { addFiles, removeFile }];
}
