import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { DecodingError, errorContains, notifyValidationError, notifyVersionMismatchError, } from '~/common/utils';
import { ENV } from '~/env';
import { VERSION_MISMATCH } from './UpdateAvailable';
const handleDecodingError = (error) => {
    if (!(error instanceof DecodingError)) {
        return;
    }
    // we're only going to send validation error if it's the latest version
    if (error.message.includes(VERSION_MISMATCH)) {
        notifyVersionMismatchError();
    }
    else {
        notifyValidationError();
        captureException(error);
        console.error(error);
    }
};
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // will refetch when opening a modal that is subscribed to react-query
            // cache, after staleTime
            refetchOnMount: true,
            // won't refetch when going to another browser tab and back
            refetchOnWindowFocus: false,
            /**
             * will only refetch after 3 minutues and only when cache subscribers
             * are going from 0 to 1, meaning you'd need to leave e.g. order page and
             * get back for it to refetch
             *
             * basically it will only rely on ws to keep the data fresh */
            staleTime: 180000,
            retry: (count, error) => {
                if (errorContains(error, 401, 403, 404, 500) || error instanceof DecodingError) {
                    return false;
                }
                return !ENV.PRODUCTION && count < 2;
            },
            onError: handleDecodingError,
        },
        mutations: {
            onError: handleDecodingError,
        },
    },
});
