export function rejectBySignal(signal) {
    if (signal === null || signal === void 0 ? void 0 : signal.aborted) {
        return Promise.reject(createAbortError());
    }
    return new Promise((_, reject) => {
        signal === null || signal === void 0 ? void 0 : signal.addEventListener('abort', () => reject(createAbortError()), { once: true });
    });
}
export function createAbortError() {
    return new DOMException('Aborted', 'AbortError');
}
