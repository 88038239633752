import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Button, PageDetailsCard, useModalContext } from '~/common/components';
import { IndividualPricingTable, normalizeTreatments } from '~/common/other';
import { EditIndividualPricingPropsable } from '~/customers/Customer';
import { useCurrentUserData } from '~/root';
import { useInitData, useSubscriptionData } from '~/subscriptions/hooks';
export const IndividualPricing = () => {
    const init = useInitData();
    const subscription = useSubscriptionData();
    const { permissions } = useCurrentUserData();
    const { modalOpener } = useModalContext();
    const data = useMemo(() => {
        return normalizeTreatments(subscription.treatments, init.treatments, init.default_treatments, {
            is10hEnabled: subscription.features.ten_turnaround,
        });
    }, [subscription.treatments, subscription.features, init.treatments, init.default_treatments]);
    return (_jsx(PageDetailsCard, { label: "Individual pricing", actionButton: permissions.customersUpdate && (_jsx(Button, { color: "grey", onClick: modalOpener(EditIndividualPricingPropsable, {
                treatments: init.treatments,
                defaultTreatments: init.default_treatments,
                customerTreatments: subscription.treatments,
            }), children: "Edit" })), children: _jsx(IndividualPricingTable, { data: data }) }));
};
