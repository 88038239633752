import { z } from 'zod';
import { decodeAxiosError } from '~/common/utils';
/**
 * BackEnd Validation
 */
const BackEndValidationErrors = z.object({
    status: z.literal('error'),
    errors: z.record(z.array(z.string()).nonempty()),
});
export function decodeBackendErrors(error) {
    var _a;
    const result = decodeAxiosError(BackEndValidationErrors).safeParse(error);
    return result.success ? (_a = result.data) === null || _a === void 0 ? void 0 : _a.errors : null;
}
export function propagateBackendValidationErrors(error, setErrors) {
    const formErrors = decodeBackendErrors(error);
    if (formErrors) {
        for (const key in formErrors) {
            setErrors(key, formErrors[key]);
        }
    }
    else {
        throw error;
    }
}
// https://gist.github.com/wojtekmaj/fe811af47fad12a7265b6f7df1017c83
const findScrollContainer = (element) => {
    let parent = element.parentElement;
    while (parent) {
        const { overflow } = window.getComputedStyle(parent);
        if (overflow.split(' ').every((o) => o === 'auto' || o === 'scroll')) {
            return parent;
        }
        parent = parent.parentElement;
    }
    return document.documentElement;
};
export const scrollToError = () => {
    setTimeout(() => {
        const error = document.querySelector('[aria-label=form-error]');
        if (!error) {
            return;
        }
        const { top, left } = error.getBoundingClientRect();
        const scrollContainer = findScrollContainer(error);
        scrollContainer.scrollTo({
            top: top + scrollContainer.scrollTop - 64,
            left,
            behavior: 'smooth',
        });
    });
};
export const minLengthMessage = (field, quantity) => `${field} must be at least ${quantity} characters.`;
export const requiredMessage = (field) => `${field} is required.`;
export const getFirstStepWithErrors = (errors, fieldStepMap) => {
    for (const step in fieldStepMap) {
        for (const field of fieldStepMap[step]) {
            if (errors[field]) {
                return Number(step);
            }
        }
    }
    return null;
};
export * as customValidator from './validator';
export * from './zod';
