import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { useIdParam } from '~/common/hooks';
import { httpClient, map } from '~/common/utils';
import { CollaborationMessage } from '~/orders/domain';
import { qk } from '~/root/query-keys';
import { useUpdateMessagesCache } from './useUpdateMessagesCache';
export const useUpdateMessage = ({ onSuccess = () => null }) => {
    const id = useIdParam();
    const updateCache = useUpdateMessagesCache();
    return useMutation({
        mutationKey: qk.collaborationUpdateMessage(id),
        mutationFn: ({ id: messageId, ...data }) => {
            return httpClient.put(`/v1/staff/orders/${id}/collaboration/comments/${messageId}`, {
                data,
                decoder: z.object({ comment: CollaborationMessage }),
            });
        },
        onSuccess: ({ comment: message }) => {
            // TODO dedupe with useMessageEvents
            updateCache((messages) => ({
                ...messages,
                pages: messages.pages.map((page) => {
                    return page.items.has(message.id)
                        ? {
                            ...page,
                            items: map.add(page.items, message.id, message),
                        }
                        : page;
                }),
            }));
            // setTimeout fixes Message component getting a render with old content
            // state, which is enough for it to remain stale forever
            setTimeout(onSuccess);
        },
    });
};
