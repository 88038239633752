import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { faTasks } from '@fortawesome/pro-regular-svg-icons';
import { IconBox, LayoutContent, ModalBody, ModalHeader } from '~/common/components';
import { Actor } from '~/common/kits/activity';
import { cx, formatTimeAtDay } from '~/common/utils';
import { useOrderActivity, useOrderActivityData, useSortedOrderCountries } from '~/orders/hooks';
import { CountriesTime } from '../CountriesTime';
const tableHeadingClassName = 'flex items-center sticky z-10 top-0 h-5 px-3 bg-greyscale-50 text-greyscale-500';
const tableCellClassName = 'flex flex-col min-h-[52px] px-3 py-[10px] border-t border-solid border-greyscale-200';
export const Activity = ({ onClose }) => {
    const { data: activity } = useOrderActivity();
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Order activity" }), _jsx(ModalBody, { className: "h-[604px] w-[874px] pt-0 overflow-y-scroll", children: activity ? _jsx(OrderActivityTable, {}) : _jsx(LayoutContent, { loading: true }) })] }));
};
const OrderActivityTable = () => {
    const activity = useOrderActivityData();
    return activity.items.length ? (_jsxs("div", { className: "grid grid-cols-[180px_400px_1fr] mt-3 border-b border-solid border-greyscale-200", children: [_jsxs("div", { className: "contents font-brand-t5 text-greyscale-500", children: [_jsx("div", { className: tableHeadingClassName, children: "Date" }), _jsx("div", { className: tableHeadingClassName, children: "Action" }), _jsx("div", { className: tableHeadingClassName, children: "Actor" })] }), activity.items.map((activityItem) => (_jsxs("div", { className: "contents font-brand-b2r", children: [_jsx("div", { className: tableCellClassName, children: _jsx("div", { className: "pt-[6px]", children: formatTimeAtDay(activityItem.date) }) }), _jsx("div", { className: tableCellClassName, children: _jsx("div", { className: "pt-[5px]", children: _jsx(Action, { action: activityItem.action }) }) }), _jsx("div", { className: cx(tableCellClassName, 'items-start'), children: _jsx(Actor, { actor: activityItem.actor }) })] }, activityItem.id)))] })) : (_jsxs("div", { className: "flex flex-col items-center justify-center h-27 text-greyscale-400", children: [_jsx(IconBox, { size: "m", icon: faTasks }), _jsx("div", { className: "font-brand-b1 mt-1", children: "No order activity yet" })] }));
};
const Action = ({ action }) => {
    var _a;
    const countries = useSortedOrderCountries();
    return (_jsxs(_Fragment, { children: [action.text && _jsx("p", { className: "font-brand-b1", children: action.text }), (_a = action.meta) === null || _a === void 0 ? void 0 : _a.map(({ key, value }, index) => (_jsxs("div", { className: "font-brand-b1 text-greyscale-500 truncate", children: [_jsxs("span", { className: "font-brand-t4m  text-greyscale-600", children: [key, ": "] }), typeof value === 'string' ? (value) : (_jsx(CountriesTime, { date: value, countries: countries, className: "inline-flex align-bottom" }))] }, index)))] }));
};
