import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faUsers } from '@fortawesome/pro-regular-svg-icons';
import { BackButton, LayoutContent, LayoutHeader, LayoutTitle, Menu, NotFound, useModalContext, withModalContext, } from '~/common/components';
import { is404 } from '~/common/utils';
import { useCurrentUserData } from '~/root';
import { OFFICES_URL, useInit, useOffice } from '../hooks';
import { DeletePrompt } from './Delete';
import { Holidays } from './Holidays';
import { OfficeInfo } from './OfficeInfo';
export const Office = withModalContext(() => {
    const office = useOffice();
    const init = useInit();
    const { permissions } = useCurrentUserData();
    const { modalOpener } = useModalContext();
    if (is404(office.error)) {
        return (_jsx(NotFound, { icon: faUsers, text: "There's no office with this id", safeLocation: OFFICES_URL }));
    }
    if (!office.data || !init.data) {
        return _jsx(LayoutContent, { loading: true });
    }
    return (_jsxs(LayoutContent, { children: [_jsxs(LayoutHeader, { className: "flex justify-between items-center gap-2", children: [_jsx(BackButton, { to: OFFICES_URL }), _jsx(LayoutTitle, { className: "-ml-2 mr-auto", children: "Office profile" }), permissions.officesManage && (_jsx(Menu, { children: _jsx(Menu.Item, { onClick: modalOpener(DeletePrompt), children: "Delete office" }) }))] }), _jsxs("div", { className: "flex items-start gap-3", children: [_jsx(OfficeInfo, {}), _jsx(Holidays, {})] })] }));
});
