import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faTrello } from '@fortawesome/free-brands-svg-icons';
import { faBuilding, faChartPieAlt, faEnvelope, faLink, faUserTag, } from '@fortawesome/pro-regular-svg-icons';
import { faPaintBrushAlt } from '@fortawesome/pro-solid-svg-icons';
import { AvatarPane, Button, Copyable, InfoBar, Link, useModalContext } from '~/common/components';
import { date, getColorsFromNumber } from '~/common/utils';
import { useCurrentUserData } from '~/root';
import { TEAMS_URL } from '~/teams/hooks';
import { roleToTitle } from '../domain';
import { useUserData } from '../hooks';
import { EditUser } from './EditUser';
export const UserInfo = () => {
    var _a;
    const user = useUserData();
    const { permissions } = useCurrentUserData();
    const { modalOpener } = useModalContext();
    const colorSet = getColorsFromNumber(user.id);
    return (_jsxs(_Fragment, { children: [_jsx(AvatarPane, { name: user.name, avatarLink: (_a = user.avatar) === null || _a === void 0 ? void 0 : _a.link, colorSet: colorSet, children: permissions.usersUpdate && (_jsx(Button, { onClick: modalOpener(EditUser), color: "grey", children: "Edit" })) }), _jsxs("div", { className: "px-4 mt-8", children: [_jsx("div", { className: "font-brand-t1m text-greyscale-600", children: user.name }), _jsxs("div", { className: "font-brand-b2r text-greyscale-500 mt-1", children: ["Last update: ", date.formatDateShort(user.updated)] }), user.invitation_expired && (_jsx("div", { className: "font-brand-b2r text-greyscale-500 mt-1", children: "Invitation expired" })), user.invitation_pending && (_jsx("div", { className: "font-brand-b2r text-greyscale-500 mt-1", children: "Pending invitation" })), _jsxs("ul", { className: "mt-2", children: [_jsx(InfoBar, { icon: faUserTag, text: `${roleToTitle[user.role]} / ${user.job_title}`, hint: "Role / Job title" }), _jsx(InfoBar, { icon: faTrello, text: 'trello_id' in user && user.trello_id, hint: "Trello ID" }), 'calendly_url' in user && (_jsx(InfoBar, { icon: faLink, text: user.calendly_url ? _jsx(Copyable, { text: user.calendly_url }) : null, hint: "Calendly URL" })), _jsx(InfoBar, { icon: faChartPieAlt, text: 'capacity' in user && `${user.capacity} points`, hint: "Daily capacity points" }), _jsx(InfoBar, { icon: faPaintBrushAlt, text: 'team' in user && user.team ? (_jsx(Link, { size: "m", to: TEAMS_URL + '/' + user.team.id, children: user.team.name })) : ('—'), hint: "Design team" }), _jsx(InfoBar, { icon: faBuilding, text: `${user.office.country} Office`, hint: "Office" }), _jsx(InfoBar, { truncate: true, icon: faEnvelope, text: _jsx(Copyable, { text: user.email }), hint: "Email" })] })] })] }));
};
