import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '~/common/components';
import { SubscriptionSection } from '../SubscriptionSection';
import { SubscriptionProduct } from './SubscriptionProduct';
const PRODUCTS_LIMIT = 2;
export const SubscriptionProducts = ({ form, productsMap, isGracePeriod, supportedCurrencies, renewalPeriodOptions, showIndividualPricingBanner, projectManagers, designTeams, addProduct, removeProduct, onPlanChange, onPriceRelatedFieldBlur, onPriceRelatedFieldChange, }) => {
    var _a;
    const productsLimitAchieved = productsMap.length >= PRODUCTS_LIMIT;
    const lastProductUnselected = ((_a = productsMap[productsMap.length - 1]) === null || _a === void 0 ? void 0 : _a.product.type) === null;
    const disableAddProductButton = productsLimitAchieved || lastProductUnselected || isGracePeriod;
    return (_jsx(SubscriptionSection, { title: "Product", subtitle: "Add one or few products to the subscription", children: _jsxs("div", { className: "space-y-3", children: [productsMap.map((p, index) => (_jsx(SubscriptionProduct, { index: index, form: form, product: p.product, productOptions: p.options, supportedCurrencies: supportedCurrencies, renewalPeriodOptions: renewalPeriodOptions, showIndividualPricingBanner: showIndividualPricingBanner, projectManagers: projectManagers, designTeams: designTeams, onRemove: p.removable ? () => removeProduct(index) : undefined, onPlanChange: onPlanChange(index), onPriceRelatedFieldBlur: onPriceRelatedFieldBlur, onPriceRelatedFieldChange: onPriceRelatedFieldChange }, p.product.type))), _jsx(Button, { size: "small", color: "secondary", disabled: disableAddProductButton, onClick: () => addProduct(), children: "+ Add product" })] }) }));
};
