import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { pipe } from 'fp-ts/function';
import { useModalContext } from '~/common/components';
import { getOptionLabel } from '~/common/utils';
import { useInitData, useOrderData } from '~/orders/hooks';
import { CapacityDistributionModal, EditDesignTeamInfo, PmShiftsOverviewLink, ResourceOverviewLink, SectionCell, SubSection, } from '~/orders/Order';
import { isStatusAfter } from '~/orders/utils';
import { DesignTeam } from './DesignTeam';
export const PmAndDesignTeam = () => {
    const order = useOrderData();
    const init = useInitData();
    const { modalOpener } = useModalContext();
    const manager = getOptionLabel(init.managers, order.pm);
    const isAfterDesignInProgress = pipe(order.status, isStatusAfter('DesignInProgress'));
    return (_jsx(SubSection, { title: "PM & Design team", onEdit: modalOpener(EditDesignTeamInfo), children: _jsxs("div", { className: "flex justify-between space-x-4 w-full", children: [_jsxs("div", { className: "flex flex-col space-y-1 w-1/2", children: [_jsx(SectionCell, { title: "PM", children: _jsx("span", { className: "font-brand-t4m", children: manager || '—' }) }), _jsx(PmShiftsOverviewLink, { className: "mr-auto" })] }), _jsxs("div", { className: "flex flex-col space-y-1 w-1/2", children: [_jsx(DesignTeam, { team: order.team }), order.tag_teams.map((team, key) => (_jsx(DesignTeam, { isTagTeam: true, team: team }, key))), isAfterDesignInProgress && order.iterations && (_jsx(CapacityDistributionModal, { type: "overview" })), _jsx(ResourceOverviewLink, { className: "mr-auto" })] })] }) }));
};
