import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { addDays } from 'date-fns';
import { Fragment, useMemo, useState } from 'react';
import { Button, Collapse, Container, DayHeader, getCellHeightStyle, LayoutContainer, StickyLeftTableCell, useModalContext, } from '~/common/components';
import { useToggle } from '~/common/hooks';
import { useAddUserCardId } from '~/common/kits/usercard';
import { formatMonthDay, getDateWithoutTime, toggleSet } from '~/common/utils';
import { TableCell } from '../common';
import { useRmsContext } from '../context';
import { useBreakdownData } from '../hooks';
import { CapacityChanging } from '../Overview';
import { DesignerRow, TeamRow } from './Row';
import css from './Breakdown.module.scss';
export const Breakdown = () => {
    const { teams, days } = useBreakdownData();
    const { setDateByIndex, allTeams, breakdownStart } = useRmsContext();
    const { modalOpener } = useModalContext();
    const today = useMemo(() => getDateWithoutTime(), []);
    const todayStr = useMemo(() => formatMonthDay(today), [today]);
    const [openedTeamIds, setOpenedTeamIds] = useState(new Set());
    const toggleTeam = toggleSet(setOpenedTeamIds);
    const shouldShowDesigners = (teamId) => openedTeamIds.has(teamId) || !allTeams;
    const [show, toggleShow] = useToggle(true);
    // avatars are collapsed in breakdown, so we can't rely on automagic here
    useAddUserCardId(...teams.map((team) => team.designers.map((designer) => designer.id)).flat());
    const getOnCellClick = (data) => {
        return (index, plan) => {
            const date = getDateWithoutTime(addDays(breakdownStart, index));
            return date.getTime() >= today.getTime()
                ? modalOpener(CapacityChanging, { ...data, date, plan })
                : undefined;
        };
    };
    return (_jsxs(LayoutContainer, { children: [_jsxs("div", { className: "flex justify-between items-center", children: [_jsx("div", { className: "font-brand-t1r", children: "Daily Capacity Breakdown" }), _jsx(Button.Icon, { className: "ml-2", size: "m", color: "grey-outline", icon: show ? faChevronUp : faChevronDown, onClick: toggleShow })] }), _jsx(Collapse, { in: show, children: _jsx(Container, { className: "overflow-x-auto overflow-y-hidden mt-2", minColumnWidth: 100, infoColumnWidth: 248, columns: Math.max(days.length, 3), children: _jsxs("table", { style: getCellHeightStyle(50), className: css.table, children: [_jsx("thead", { children: _jsxs("tr", { style: getCellHeightStyle(40), children: [_jsx(StickyLeftTableCell, { width: 248 }), days.map((day, index) => (_jsx(TableCell, { className: "cursor-pointer", onClick: () => setDateByIndex(index), active: todayStr === day.date, children: _jsx(DayHeader, { isWeekend: day.isWeekend, label: day.date, today: todayStr === day.date }) }, day.date)))] }) }), _jsx("tbody", { children: teams.map((team) => (_jsxs(Fragment, { children: [allTeams && (_jsx(TeamRow, { name: team.name, days: days, daysData: team.days, onClick: () => toggleTeam(team.id), onCellClick: getOnCellClick({ teamId: team.id }), opened: openedTeamIds.has(team.id) })), shouldShowDesigners(team.id) &&
                                            team.designers.map((designer) => (_jsx(DesignerRow, { designer: designer, days: days, leader: team.leader_id === designer.id, onCellClick: getOnCellClick({ teamId: team.id, designerId: designer.id }), darkBackground: allTeams }, designer.id)))] }, team.id))) })] }) }) })] }));
};
