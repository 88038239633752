import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { InputField, RangeSelect } from '~/common/components';
import { noop } from '~/common/utils';
export const Range = () => {
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(23);
    const [value, setValue] = useState([min + 9, max - 6]);
    return (_jsxs("div", { className: "bg-greyscale-0 p-2 rounded space-y-3", children: [_jsx("div", { className: "font-brand-h1 w-full mb-3", children: "Range select" }), _jsxs("div", { className: "flex space-x-3", children: [_jsx(InputField, { className: "w-20", title: "Min.", onBlur: noop, disabled: false, touched: false, errors: null, type: "number", size: "small", value: min, onChange: setMin }), _jsx(InputField, { className: "w-20", title: "Max.", onBlur: noop, disabled: false, touched: false, errors: null, type: "number", size: "small", value: max, onChange: setMax })] }), _jsx(RangeSelect, { className: "w-40", disabled: false, errors: null, touched: false, value: value, onChange: setValue, onBlur: noop, min: min, max: max, title: "Working hours", render: (value) => `${value}:00` })] }));
};
