import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faRobot } from '@fortawesome/pro-regular-svg-icons';
import { Avatar, IconBox, Link } from '~/common/components';
import { UserCard, useAddUserCardId } from '~/common/kits/usercard';
import { getColorsFromNumber } from '~/common/utils';
import { CUSTOMERS_URL } from '~/customers/hooks';
import { USERS_URL } from '~/users';
const ACTOR_COLOR_SET_INDEX = 7;
const ExistingActor = ({ actor }) => {
    useAddUserCardId(actor.id);
    const cardProps = actor.is_customer
        ? {
            type: 'customer',
            owner_id: actor.owner_id,
        }
        : {
            type: 'staff',
        };
    return (_jsx("div", { className: "min-w-0", children: _jsxs("div", { className: "flex items-center justify-center gap-1 min-w-0", children: [_jsx(UserCard, { id: actor.id, name: actor.name, avatar_link: actor.avatar_link, ...cardProps }), cardProps.type === 'staff' || cardProps.owner_id ? (_jsx(Link, { color: "grey", to: cardProps.type === 'customer'
                        ? `${CUSTOMERS_URL}/${cardProps.owner_id}`
                        : `${USERS_URL}/${actor.id}`, tabIndex: -1, children: actor.name })) : (_jsx("span", { className: "truncate", children: actor.name }))] }) }));
};
export const Actor = ({ actor }) => {
    if (actor === null) {
        return (_jsxs("div", { className: "flex items-center gap-1 self-start", children: [_jsx(Avatar, { colorSet: getColorsFromNumber(ACTOR_COLOR_SET_INDEX), children: _jsx(IconBox, { size: "s", className: "text-inherit", icon: faRobot }) }), _jsx("span", { className: "font-brand-b2r text-greyscale-600 truncate", children: "System" })] }));
    }
    return _jsx(ExistingActor, { actor: actor });
};
