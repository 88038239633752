import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faTimesCircle, faUser } from '@fortawesome/pro-solid-svg-icons';
import { IconBox, Input, Tooltip } from '~/common/components';
import { getVariants } from '../utils';
const propVariants = getVariants({
    variants: {
        disabled: [false, true],
        error: [true, false],
        noIconBorder: [false, true],
        size: ['small'],
        icon: [undefined, faUser],
        defaultValue: [undefined, 'test'],
        placeholder: ['placeholder'],
        title: [undefined, 'title'],
        children: [
            _jsx(IconBox, { size: "s", icon: faTimesCircle, className: "cursor-pointer hover:text-primary-300 transition-brand" }, "example-icon"),
        ],
    },
    omit: (variant) => {
        // There won't be a case where we have noIconBorder and no icon
        if (variant.noIconBorder && !variant.icon) {
            return false;
        }
        if (variant.error && variant.disabled) {
            return false;
        }
        return true;
    },
    sort: ['error', 'disabled', 'size'],
});
export const Inputs = () => {
    return (_jsxs("div", { className: "flex flex-wrap bg-greyscale-0 p-2 rounded", children: [_jsx("div", { className: "font-brand-h1 w-full mb-3", children: "Inputs" }), propVariants.map((variant, index) => (_jsx("div", { className: "flex items-end justify-center h-11 w-40 p-2 text-[12px]", children: _jsx(Tooltip, { content: _jsx("pre", { className: "mt-1 overflow-hidden", children: JSON.stringify(variant, null, 2) }), className: "overflow-hidden max-w-xs", placement: "bottom", delay: 2000, children: _jsx(Input, { ...variant }) }) }, index)))] }));
};
