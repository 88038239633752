import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faShapes } from '@fortawesome/pro-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { LayoutContent, withModalContext } from '~/common/components';
import { Nothing, Table, useBackendTable } from '~/common/kits/table';
import { ORDERS_URL } from '..';
import { useReviews, useReviewsInit } from '../hooks';
import { ReviewModal } from './ReviewModal';
import { ReviewsHeader } from './ReviewsHeader';
import { tableConfig } from './table-config';
export const ReviewsList = withModalContext(() => {
    const reviews = useReviews();
    const init = useReviewsInit();
    const view = useBackendTable({ ...reviews, tableConfig });
    const location = useLocation();
    if (!reviews.data || !init.data || !view) {
        return _jsx(LayoutContent, { loading: true });
    }
    return (_jsxs(_Fragment, { children: [_jsxs(LayoutContent, { className: "grid-rows-[min-content,_1fr]", children: [_jsx(ReviewsHeader, { reviewsCount: reviews.data.metadata.total }), _jsx(Table, { ...view, noDataPlaceholder: _jsx(Nothing, { icon: faShapes }), linkGetter: (row) => `${ORDERS_URL}/reviews/${row.id}${location.search}` })] }), _jsx(ReviewModal, {})] }));
});
