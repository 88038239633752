import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { nonNullable } from '../utils';
/**
 * A generic hook for updating query cache
 * Provide a query key and a data type (as a generic type parameter)
 */
export function useUpdateCache(key, errorMessage) {
    const queryClient = useQueryClient();
    return useCallback((update) => {
        queryClient.setQueryData(key, (data) => {
            if (data) {
                return update(data);
            }
            console.error(errorMessage);
        });
    }, [errorMessage, key, queryClient]);
}
export const useAdvancedUpdateCache = (queryKey) => {
    const client = useQueryClient();
    return {
        cancelQuery: () => client.cancelQueries(queryKey),
        getQuery: () => nonNullable(client.getQueryData(queryKey)),
        setQuery: (stateOrCb) => {
            return client.setQueryData(queryKey, stateOrCb instanceof Function ? (prev) => prev && stateOrCb(prev) : stateOrCb);
        },
    };
};
