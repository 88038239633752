import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, StatsBlock } from '~/common/components';
import { addQueryParams, formatMoney } from '~/common/utils';
import { useCustomerData, useStatsData } from '~/customers/hooks';
import { ORDERS_URL } from '~/orders';
export const Stats = () => {
    const stats = useStatsData();
    const customer = useCustomerData();
    return (_jsxs(StatsBlock, { children: [_jsx(StatsBlock.Item, { generalProps: {
                    heading: 'Revenue',
                    value: formatMoney(stats.revenueTotal),
                    description: 'for last 12 months',
                }, graphProps: { data: stats.revenue, color: '#F86666' }, action: _jsx(Link, { size: "m-bold", to: `${window.location.pathname}/transactions`, children: "All transactions" }) }), _jsx(StatsBlock.Item, { generalProps: {
                    heading: 'Orders',
                    value: stats.ordersTotal,
                    description: 'for last 12 months',
                }, graphProps: { data: stats.orders, color: '#7394E8' }, action: _jsx(Link, { size: "m-bold", to: addQueryParams(ORDERS_URL, {
                        'filter[customer_account]': customer.customer_account_id,
                    }), children: "All orders" }) }), _jsx(StatsBlock.Item, { generalProps: {
                    heading: 'Feedback',
                    value: stats.feedbackAvg,
                    description: 'for last 12 months',
                }, graphProps: { data: stats.feedback, color: '#1EC1A4' } })] }));
};
