import { jsx as _jsx } from "react/jsx-runtime";
import { format } from 'date-fns';
import { option } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { Datepicker, Errors, Popover, Tooltip } from '~/common/components';
import { noop } from '~/common/utils';
import { InlineInput } from './InlineInput';
export const InlineDateField = ({ disabled, errors, onBlur, onChange, touched, value, title, ...datePickerProps }) => {
    return (_jsx(Popover, { trigger: (triggerProps) => (_jsx("div", { ...triggerProps, children: _jsx(Tooltip, { color: "danger", content: errors && _jsx(Errors, { errors: errors }), children: _jsx(InlineInput, { onBlur: onBlur, onChange: noop, disabled: disabled, error: errors !== null, size: "regular", textAlignment: "right", title: title, type: "text", value: pipe(option.tryCatch(() => format(value, 'd MMM yyyy')), option.getOrElse(() => '')) }) }) })), content: ({ onClose }) => (_jsx(Datepicker, { ...datePickerProps, onChange: (date) => {
                onChange(date);
                onBlur();
                onClose();
            }, value: value })) }));
};
