import { useQuery } from '@tanstack/react-query';
import { httpClient } from '~/common/utils';
import { qk } from '~/root/query-keys';
import { LoginActivity } from '../domain';
export const useLoginActivity = () => {
    return useQuery({
        queryKey: qk.userActivity,
        queryFn: () => httpClient.get('/v1/staff/users/sessions', { decoder: LoginActivity }),
    });
};
