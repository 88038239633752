import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useForm, validator } from 'formoid';
import { Banner, InputField, ModalHeader, ModalShell, SelectField } from '~/common/components';
import { minLengthMessage, notifyBackendError, propagateBackendValidationErrors, } from '~/common/utils';
import { useCustomerSuspension, useCustomerUnsuspension, useInitData } from '../hooks';
const isOtherReason = (reason) => reason === 'other';
export const Suspend = ({ onClose }) => {
    const init = useInitData();
    const { mutateAsync } = useCustomerSuspension();
    const initialValues = {
        reason: null,
        comment: '',
    };
    const { fieldProps, handleSubmit, isSubmitting, setErrors, values } = useForm({
        initialValues,
        validationStrategy: 'onBlur',
        validators: ({ reason }) => ({
            reason: validator.defined('Reason is required'),
            comment: isOtherReason(reason)
                ? validator.minLength(5, minLengthMessage('Description', 5))
                : null,
        }),
    });
    const submit = () => handleSubmit((values) => {
        return mutateAsync(values)
            .then(onClose)
            .catch((error) => propagateBackendValidationErrors(error, setErrors))
            .catch(notifyBackendError);
    });
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Suspend customer" }), _jsxs(ModalShell, { className: "p-3 pb-5 space-y-3 w-[608px]", loading: isSubmitting, onClose: onClose, onSubmit: submit, submitText: "Suspend", negativeAction: true, children: [_jsx(Banner, { color: "warning", children: "If you suspend the customer, he will still be able to submit new orders, but he will see a warning, as well as CS when reviewing an order" }), _jsx(SelectField, { ...fieldProps('reason'), title: "Reason of suspending", options: init.reasons.customer_suspension, placeholder: "Choose from the list" }), isOtherReason(values.reason) && (_jsx(InputField, { ...fieldProps('comment'), title: "Describe a reason", placeholder: "Describe your reason", type: "text" }))] })] }));
};
export const Unsuspend = ({ onClose }) => {
    const { mutateAsync, isLoading } = useCustomerUnsuspension();
    const onSubmit = () => {
        mutateAsync().then(onClose);
    };
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose }), _jsxs(ModalShell, { className: "flex flex-col items-center mb-8 w-[608px]", loading: isLoading, onSubmit: onSubmit, onClose: onClose, submitText: "Unsuspend", children: [_jsx("div", { className: "font-brand-h1 text-greyscale-600 mt-[44px]", children: "Unsuspend the customer?" }), _jsxs("div", { className: "font-brand-b1 text-greyscale-500 mt-[12px] text-center", children: ["After doing this action we will process new orders.", _jsx("br", {}), "Please, check if the customer has a valid payment methods"] })] })] }));
};
