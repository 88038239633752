import { useMutation, useQueryClient } from '@tanstack/react-query';
import { httpClient } from '~/common/utils';
import { InvoicePreviewUrl, ProductPrice, Subscription, } from '~/customers/domain';
import { qk } from '~/root/query-keys';
export const useSubscriptionCreateMutation = () => {
    const client = useQueryClient();
    return useMutation({
        mutationFn: async ({ data, id }) => {
            const res = await httpClient.post(`/v1/staff/customers/${id}/subscription`, {
                data,
                decoder: Subscription,
            });
            return { res, id };
        },
        onSuccess: ({ res, id }) => {
            client.setQueryData(qk.subscription(res.id), res);
            client.invalidateQueries(qk.customer(id));
            client.invalidateQueries(qk.subscriptionsList);
        },
    });
};
export const useCalculateProductPriceMutation = () => {
    return useMutation({
        mutationFn: ({ data, id }) => httpClient.post(`/v1/staff/customers/${id}/billing/calculate-product`, {
            data,
            decoder: ProductPrice,
        }),
    });
};
export const useInvoicePreviewMutation = () => {
    return useMutation({
        mutationFn: ({ data, id }) => httpClient.post(`/v1/staff/customers/${id}/billing/invoice-preview`, {
            data,
            decoder: InvoicePreviewUrl,
        }),
    });
};
