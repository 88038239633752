import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef } from 'react';
import { Badge, UsersGroup } from '~/common/components';
import { getOptionLabel } from '~/common/utils';
import { useInitData, useOrderData } from '~/orders/hooks';
import { Messages } from './Messages';
import { SendMessage } from './SendMessage';
export const InternalChat = () => {
    const ref = useRef(null);
    const { team, tag_teams, followers } = useOrderData();
    const { teams } = useInitData();
    // TODO do you like what you see? sure why not
    const teamLabel = team ? getOptionLabel(teams, team.id) : '—';
    const scrollToBottom = useCallback(() => {
        if (ref.current)
            ref.current.scrollTop = ref.current.scrollHeight;
    }, [ref]);
    return (_jsxs("div", { className: "flex flex-col basis-7/12 gap-1 pb-2", children: [_jsxs("div", { className: "flex-none flex items-center gap-1 h-8 -mb-1", children: [_jsx("div", { className: "font-brand-t2m text-greyscale-500", children: teamLabel }), tag_teams.map((team) => (_jsx(Badge, { size: "small", children: getOptionLabel(teams, team.id) }, team.id))), _jsx(UsersGroup, { users: followers, maxVisible: 8, className: "ml-auto" })] }), _jsx(Messages, { messagesRef: ref, scrollToBottom: scrollToBottom }), _jsx(SendMessage, { scrollToBottom: scrollToBottom })] }));
};
