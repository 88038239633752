import { jsx as _jsx } from "react/jsx-runtime";
import { faArrowAltToBottom } from '@fortawesome/pro-regular-svg-icons';
import { Link } from '~/common/components';
import { addQueryParams } from '~/common/utils';
import { SERVER_URL } from '~/env';
export const DownloadButton = ({ orderId, selectedFiles, children, className, tag }) => {
    const orderFilesLink = addQueryParams(`${SERVER_URL}/v1/staff/orders/${orderId}/files`, {
        ids: selectedFiles === null || selectedFiles === void 0 ? void 0 : selectedFiles.join(','),
        tag: !selectedFiles ? tag : undefined,
    });
    return (_jsx(Link.Button, { className: className, color: "secondary", download: true, href: orderFilesLink, icon: faArrowAltToBottom, children: children }));
};
