import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faCircle, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle, faPencil } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { invoice } from '~/assets/images';
import { Button, IconBox, ModalHeader, ModalShell, useModalContext } from '~/common/components';
import { cardBrandToImg, cx, joinTruthy } from '~/common/utils';
import { useCustomerData, useJoinedCustomerData, usePaymentMethodsMutation, } from '~/customers/hooks';
import { CheckBox } from '~/orders/Order';
import { AddInvoicePaymentMethod } from './AddInvoicePaymentMethod';
import { EditInvoicePaymentMethod } from './EditInvoicePaymentMethod';
const getCustomerDefaultPaymentMethod = (customer) => { var _a; return (_a = customer.payment_methods.find(({ default: isDefault }) => isDefault)) === null || _a === void 0 ? void 0 : _a.id; };
export const EditPaymentMethods = ({ onClose }) => {
    const customer = useCustomerData();
    const { mutateAsync, isLoading } = usePaymentMethodsMutation();
    const [defaultCustomerPaymentMethod] = useState(getCustomerDefaultPaymentMethod(customer));
    const [defaultMethod, setDefaultMethod] = useState(defaultCustomerPaymentMethod);
    const [paymentMethods, setPaymentMethods] = useState(customer.payment_methods);
    const { modalOpener } = useModalContext();
    const deletePaymentMethod = (id) => {
        setPaymentMethods((methods) => {
            const newPaymentMethods = methods.filter((paymentMethod) => paymentMethod.id !== id);
            if (defaultMethod === id) {
                setDefaultMethod(newPaymentMethods[0].id);
            }
            return newPaymentMethods;
        });
    };
    const submit = () => {
        mutateAsync({ ids: paymentMethods.map(({ id }) => id), default_id: defaultMethod }).then(onClose);
    };
    const isDirty = defaultMethod !== defaultCustomerPaymentMethod ||
        customer.payment_methods.length !== paymentMethods.length ||
        customer.payment_methods.some(({ id }, i) => id !== paymentMethods[i].id);
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Edit payment methods" }), _jsxs(ModalShell, { className: "p-3 pb-4 space-y-2 w-[712px]", onClose: onClose, onSubmit: submit, loading: isLoading, disabled: !isDirty, children: [paymentMethods.map((paymentMethod) => {
                        const isCard = 'brand' in paymentMethod;
                        return (_jsx(PaymentMethod, { isDefault: paymentMethod.id === defaultMethod, isCard: isCard, id: paymentMethod.id, markDefault: setDefaultMethod, deleteMethod: paymentMethods.length > 1 ? deletePaymentMethod : undefined, children: 'brand' in paymentMethod ? (_jsx(CardPaymentMethod, { ...paymentMethod })) : (_jsx(InvoicePaymentMethod, { email: customer.billing_settings.email, ...paymentMethod })) }, paymentMethod.id));
                    }), !customer.invoicePaymentMethod && (_jsx(Button, { onClick: modalOpener(AddInvoicePaymentMethod, {
                            onClose: modalOpener(EditPaymentMethods),
                        }), color: "grey", children: "Add invoice method" }))] })] }));
};
const CardPaymentMethod = ({ last4, brand, expires, }) => (_jsxs("div", { className: "font-brand-b2r flex items-center w-full min-w-0", children: [_jsx("img", { className: "mr-2 w-[36px] h-3", src: cardBrandToImg(brand), alt: brand }), _jsxs("span", { className: "mr-3 whitespace-nowrap", children: ["**** **** **** ", last4] }), _jsx("span", { children: expires })] }));
const InvoicePaymentMethod = ({ company, city, address, vat_number, zip_code, email, po_number, }) => {
    const { invoiceCountry, invoiceCurrency } = useJoinedCustomerData();
    return (_jsxs("div", { className: "font-brand-b2r flex w-full min-w-0", children: [_jsx("img", { className: "mr-2 w-[36px] h-3", src: invoice, alt: "Invoice payment method" }), _jsxs("div", { className: "grid grid-cols-2 gap-x-2 max-w-full", children: [_jsxs("div", { className: "flex flex-col gap-1 min-w-0", children: [_jsx(InvoicePaymentMethodBlock, { title: "Company details", info: [company, email] }), _jsx(InvoicePaymentMethodBlock, { title: "Address", info: joinTruthy([address, city, zip_code, invoiceCountry === null || invoiceCountry === void 0 ? void 0 : invoiceCountry.name], ', '), noTruncate: true })] }), _jsxs("div", { className: "flex flex-col gap-1 min-w-0", children: [_jsx(InvoicePaymentMethodBlock, { title: "VAT number", info: vat_number }), _jsx(InvoicePaymentMethodBlock, { title: "Currency", info: invoiceCurrency }), _jsx(InvoicePaymentMethodBlock, { title: "PO number", info: po_number })] })] })] }));
};
const InvoicePaymentMethodBlock = ({ title, info, noTruncate = false, }) => {
    return (_jsxs("div", { className: "flex flex-col", children: [_jsx("h5", { className: "font-brand-c1r text-greyscale-400 mb-[2px]", children: title }), Array.isArray(info) ? (info.map((infoLine, index) => (_jsx("p", { className: "font-brand-b2 truncate", children: infoLine || '—' }, index)))) : (_jsx("p", { className: cx('font-brand-b2', !noTruncate && 'truncate'), children: info || '—' }))] }));
};
const PaymentMethod = ({ id, isDefault, isCard, children, markDefault, deleteMethod, }) => {
    const { modalOpener } = useModalContext();
    const handleChange = (value) => {
        if (!isDefault && value) {
            markDefault(id);
        }
    };
    const handleDeleteClick = () => {
        deleteMethod && deleteMethod(id);
    };
    return (_jsxs("div", { className: "flex gap-x-2 w-full", children: [_jsxs("label", { className: cx('flex items-start gap-x-1 min-w-0 py-[12px] px-2 w-full border border-solid rounded', isDefault ? 'border-success-400' : 'border-greyscale-200'), children: [children, _jsx(CheckBox, { label: "Default", checked: isDefault, className: cx('rounded font-brand-b1 flex-none mt-[1px]', isDefault ? 'text-success-300' : 'text-greyscale-500'), onChange: handleChange, renderIcon: () => (_jsx(IconBox, { size: "s", className: "text-inherit", icon: isDefault ? faCheckCircle : faCircle })) })] }), _jsxs("div", { className: cx('flex w-3 flex-col py-[4px] flex-none', { 'justify-center': isCard }), children: [!isCard && (_jsx(Button.Icon, { size: "m", icon: faPencil, onClick: modalOpener(EditInvoicePaymentMethod, {
                            onClose: modalOpener(EditPaymentMethods),
                        }), "aria-label": `Edit ${isDefault && 'default '}payment method` })), deleteMethod && (_jsx(Button.Icon, { size: "m", icon: faTrashAlt, onClick: handleDeleteClick, "aria-label": `Delete ${isDefault && 'default '}payment method` }))] })] }));
};
