import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge, Link } from '~/common/components';
import { UserCard } from '~/common/kits/usercard';
import { formatDayMonthYear } from '~/common/utils';
import { CUSTOMERS_URL } from '~/customers/hooks';
export const tableConfig = {
    columns: [
        {
            key: 'id',
            name: 'ID',
            width: '72px',
            getClassName: () => 'overflow-visible',
        },
        {
            key: 'name',
            name: 'Organization',
            cellRenderer: ({ cellData, rowData }) => {
                return (_jsxs(_Fragment, { children: [_jsx("span", { className: "truncate", children: cellData }), rowData.tags && (_jsx("div", { className: "flex items-center absolute top-[38px] h-[18px] gap-[4px]", children: rowData.tags.map((item) => (_jsx(Badge, { size: "small", color: item.color, children: item.name }, item.value))) }))] }));
            },
            getClassName: () => 'grid overflow-visible relative',
        },
        {
            key: 'owner',
            name: 'Owner',
            cellRenderer: ({ cellData }) => cellData && (_jsx(UserCard, { id: cellData.id, children: _jsx(Link, { className: "z-[1]", to: `${CUSTOMERS_URL}/${cellData.id}`, children: cellData.name }) })),
        },
        {
            key: 'orders_count',
            name: 'Orders',
            width: '120px',
        },
        {
            key: 'customers_count',
            name: 'Customers',
            width: '120px',
        },
        {
            key: 'members_count',
            name: 'Members',
            width: '120px',
        },
        {
            key: 'updated_at',
            name: 'Last update',
            cellRenderer: ({ cellData }) => formatDayMonthYear(cellData),
            width: '120px',
        },
        {
            key: 'custom_portal_url',
            name: 'Portal',
            cellRenderer: ({ cellData }) => cellData && _jsx(Link, { href: cellData, external: true, newTab: true, className: "z-[1]" }),
            getClassName: () => 'justify-center',
            width: 'min-content',
        },
    ],
    sortable: ['id', 'orders_count', 'customers_count', 'updated_at', 'members_count'],
    getCellClassName: () => 'h-8 items-baseline pt-[14px]',
};
