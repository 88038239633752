import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faTrello } from '@fortawesome/free-brands-svg-icons';
import { faChartPieAlt, faEnvelope, faLink, faStar, faUser, } from '@fortawesome/pro-regular-svg-icons';
import { faPaintBrushAlt } from '@fortawesome/pro-solid-svg-icons';
import { useForm, validator } from 'formoid';
import { useEffect } from 'react';
import { Banner, BlockSelect, InputField, ModalHeader, ModalStepShell, OfficeSelect, SelectField, Show, } from '~/common/components';
import { useStep } from '~/common/hooks';
import { customValidator, getFirstStepWithErrors, minLengthMessage, propagateBackendValidationErrors, } from '~/common/utils';
import { hasCalendlyUrl, hasTrelloId, isRoleDesigner } from '../domain';
import { useInitData, useUserCreation } from '../hooks';
import { composePayload } from '../User';
import { roleToIcon } from './utils';
// WE ARE STARTING FROM ZERO :D
const MAX_STEP = 3;
const firstStepWithErrors = (errors) => {
    return getFirstStepWithErrors(errors, {
        2: ['name', 'job_title', 'capacity', 'team', 'trello_id', 'calendly_url'],
    });
};
export const CreateUser = ({ onClose, role }) => {
    var _a, _b;
    const init = useInitData();
    const { mutateAsync } = useUserCreation();
    const { step, toPrevStep, toNextStep, toStep } = useStep(MAX_STEP);
    const initialValues = {
        name: '',
        role,
        job_title: '',
        email: '',
        // we should select first possible id from the init, because 1 could not be there
        office: (_b = (_a = init.offices[0]) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : null,
        capacity: null,
        team: null,
        trello_id: '',
        calendly_url: '',
    };
    const { fieldProps, handleSubmit, values, isSubmitting, errors, setErrors } = useForm({
        initialValues,
        validationStrategy: 'onBlur',
        validators: ({ role }) => ({
            role: null,
            office: null,
            name: step === 2 ? validator.minLength(2, minLengthMessage('Name', 2)) : null,
            job_title: step === 2 ? validator.minLength(5, minLengthMessage('Job title', 5)) : null,
            capacity: step === 2 && isRoleDesigner(role)
                ? validator.sequence(customValidator.required(), customValidator.nonNegativeInteger())
                : null,
            team: null,
            trello_id: step === 2 && hasTrelloId(role)
                ? validator.minLength(5, minLengthMessage('Trello ID', 5))
                : null,
            calendly_url: null,
            email: step === 3 ? customValidator.email() : null,
        }),
    });
    const submit = () => handleSubmit((values) => {
        // xD
        if (step < MAX_STEP) {
            toNextStep();
            return Promise.resolve();
        }
        return mutateAsync(composePayload(values))
            .then(onClose)
            .catch((error) => {
            propagateBackendValidationErrors(error, setErrors);
        });
    });
    useEffect(() => {
        const stepWithErrors = firstStepWithErrors(errors);
        if (stepWithErrors) {
            toStep(stepWithErrors);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Create user" }), _jsxs(ModalStepShell, { className: "relative flex flex-col gap-3 p-3 pb-4 h-[393px] w-[596px]", submitText: "Create user", step: step, toPrevStep: toPrevStep, maxStep: MAX_STEP, onClose: onClose, onSubmit: submit, loading: isSubmitting, children: [_jsx(Show, { if: step === 0, children: _jsx(BlockSelect, { ...fieldProps('role'), options: init.roles, nameToIcon: roleToIcon }) }), _jsx(Show, { if: step === 1, children: _jsx(OfficeSelect, { ...fieldProps('office'), options: init.offices }) }), _jsxs(Show, { if: step === 2, children: [_jsx(InputField, { ...fieldProps('name'), icon: faUser, title: "Name", type: "text" }), _jsx(InputField, { ...fieldProps('job_title'), icon: faStar, title: "Job title", type: "text" }), hasTrelloId(values.role) && (_jsx(InputField, { ...fieldProps('trello_id'), icon: faTrello, title: "Trello member ID", type: "text" })), hasCalendlyUrl(values.role) && (_jsx(InputField, { ...fieldProps('calendly_url'), icon: faLink, title: "Calendly URL (optional)", type: "text" })), isRoleDesigner(values.role) && (_jsx(InputField, { ...fieldProps('capacity'), icon: faChartPieAlt, title: "Default daily capacity", type: "number" })), isRoleDesigner(values.role) && (_jsx(SelectField, { ...fieldProps('team'), icon: faPaintBrushAlt, title: "Design team", options: init.teams, type: "text", nullable: true }))] }), _jsxs(Show, { if: step === 3, children: [_jsx(InputField, { ...fieldProps('email'), icon: faEnvelope, title: "Email", type: "text" }), _jsx(Banner, { color: "info", className: "absolute bottom-0 w-[calc(100%-48px)]", children: "Email with an invitation will be sent to user" })] })] })] }));
};
