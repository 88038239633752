import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { addMonths } from 'date-fns';
import { getDateWithoutTime } from '~/common/utils';
import { useOrderData } from '~/orders/hooks';
import { InlineDateField, SectionCell } from '~/orders/Order';
export const CustomerCapacity = ({ fieldProps }) => {
    const order = useOrderData();
    return (_jsxs("div", { className: "space-y-1", children: [_jsx(SectionCell, { title: "Customer capacity", titleClassName: "font-brand-t4m text-greyscale-500" }), _jsx(InlineDateField, { ...fieldProps('customerCapacity.start'), 
                // TODO timezone should this get the date according to +0 ?
                minDate: getDateWithoutTime(order.created), title: "Start" }), _jsx(InlineDateField, { ...fieldProps('customerCapacity.end'), minDate: fieldProps('customerCapacity.start').value, maxDate: addMonths(fieldProps('customerCapacity.start').value, 1), title: "End" })] }));
};
