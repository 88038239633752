import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faUsers } from '@fortawesome/pro-regular-svg-icons';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { record } from '~/common/utils';
import { listRoleToListHeading } from './domain';
import { USERS_URL } from './hooks';
import { User } from './User';
import { UsersList } from './UsersList';
export const roleToLink = {
    designer: `${USERS_URL}/designers`,
    regular_pm: `${USERS_URL}/regular-pms`,
    subscription_pm: `${USERS_URL}/subscription-pms`,
    customer_supporter: `${USERS_URL}/supporters`,
    sales_manager: `${USERS_URL}/sales`,
    admin: `${USERS_URL}/admins`,
    other: `${USERS_URL}/other`,
};
const nav = {
    icon: faUsers,
    items: record.entries(listRoleToListHeading).map(([role, label]) => ({
        label,
        path: roleToLink[role],
    })),
    mainPath: roleToLink.designer,
    path: USERS_URL,
    label: 'Users',
};
const Users = () => {
    const { path } = useRouteMatch();
    const { pathname } = useLocation();
    return (_jsxs(Switch, { children: [record.entries(roleToLink).map(([role, path]) => (_jsx(Route, { path: path, render: () => _jsx(UsersList, { role: role }, pathname) }, role))), _jsx(Route, { path: `${path}/:id(\\d+)`, component: User }), _jsx(Redirect, { to: roleToLink.designer })] }));
};
export const usersModule = {
    canShow: (permissions) => permissions.usersView,
    route: {
        path: USERS_URL,
        component: Users,
    },
    nav,
};
// We could move this module init stuff to the other file and leave only
// re-exports here, but it should be autosorted first, otherwise if we name it
// as something like routing.ts, it will be imported after hooks are imported,
// and hooks need USERS_URL, which won't be available
export * from './hooks';
