import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LayoutContent, withModalContext } from '~/common/components';
import { FeatureFlags } from '~/common/other';
import { useOrganizationFeatureFlags, useStats } from '~/organizations/hooks';
import { useCurrentUserData } from '~/root';
import { CustomersTable } from './CustomersTable';
import { CustomPortalAndDomainsInfo } from './CustomPortalAndDomainsInfo';
import { OrganizationInfo } from './OrganizationInfo';
import { Stats } from './Stats';
export const General = withModalContext(() => {
    const { data: stats } = useStats();
    const { permissions } = useCurrentUserData();
    const featuresFlagsData = useOrganizationFeatureFlags();
    if (!stats)
        return _jsx(LayoutContent, { loading: true });
    return (_jsxs("div", { className: "grid max-h-full grid-cols-[auto_minmax(0,1fr)] grid-rows-[min-content_100%] gap-x-2 overflow-auto", children: [_jsxs("div", { className: "w-[445px] flex flex-col gap-2 row-span-full", children: [_jsx(OrganizationInfo, {}), _jsx(CustomPortalAndDomainsInfo, {}), _jsx(FeatureFlags, { ...featuresFlagsData, canEdit: permissions.organizationsUpdate })] }), _jsx(Stats, {}), _jsx(CustomersTable, {})] }));
});
