import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm, validator } from 'formoid';
import { useMemo, useState } from 'react';
import { Button, Input, InputField } from '~/common/components';
import { customValidator, formatDayMonthYear, propagateBackendValidationErrors, } from '~/common/utils';
import { SERVER_URL } from '~/env';
import { SettingsTabHeader } from '../SettingsTabHeader';
import { useProfileMutation } from './hooks';
import { UserAvatar } from './UserAvatar';
const NAME_MIN_LENGTH = 2;
const NAME_MAX_LENGTH = 30;
const NAME_REG_EXP = new RegExp(/^[a-z ,.\'-]+$/i);
export const Profile = ({ user }) => {
    var _a;
    const [avatar, setAvatar] = useState(user.avatar);
    const { fieldProps, handleSubmit, handleReset, values, setErrors } = useForm({
        initialValues: {
            name: user.name,
            avatar_file_id: ((_a = user.avatar) === null || _a === void 0 ? void 0 : _a.id) || null,
        },
        validationStrategy: 'onBlur',
        validators: () => ({
            name: validator.sequence(customValidator.required(), customValidator.nonBlankString(), validator.minLength(NAME_MIN_LENGTH, `Name must be at least ${NAME_MIN_LENGTH} characters.`), validator.maxLength(NAME_MAX_LENGTH, `Name should not be longer than ${NAME_MAX_LENGTH} characters.`), validator.fromPredicate((value) => NAME_REG_EXP.test(value.toLocaleLowerCase()), 'Please enter a propper name')),
            avatar_file_id: null,
        }),
    });
    const { mutateAsync, isLoading } = useProfileMutation();
    const isActionsAvaliable = useMemo(() => { var _a; return values.name !== user.name || values.avatar_file_id !== (((_a = user.avatar) === null || _a === void 0 ? void 0 : _a.id) || null); }, [user, values]);
    const discard = () => {
        handleReset();
        setAvatar(user.avatar);
    };
    const submit = () => handleSubmit((values) => {
        var _a;
        const payload = { name: values.name };
        if (values.avatar_file_id !== ((_a = user.avatar) === null || _a === void 0 ? void 0 : _a.id)) {
            payload['avatar_file_id'] = values.avatar_file_id;
        }
        return mutateAsync(payload).catch((error) => {
            propagateBackendValidationErrors(error, setErrors);
        });
    });
    const onFileUpload = (uploadedFile) => {
        fieldProps('avatar_file_id').onChange(uploadedFile.id);
        // direct access for uploaded file is blocked, so we need to compose
        // a regular BE link using uuid
        // TODO check if there's a better way to compose BE urls on FE using tags and uuids
        uploadedFile.link = `${SERVER_URL}/v1/staff/files/${uploadedFile.id}`;
        setAvatar(uploadedFile);
    };
    const removeProfileAvatar = async () => {
        fieldProps('avatar_file_id').onChange(null);
        setAvatar(null);
    };
    return (_jsxs("div", { className: "flex flex-col gap-2 bg-greyscale-0", children: [_jsxs("div", { className: "flex flex-col p-3", children: [_jsx(SettingsTabHeader, { title: "My profile", description: `Member since ${formatDayMonthYear(user.created_at)}` }), _jsxs("div", { className: "mt-2", children: [_jsx(ProfileSection, { label: "Name", description: "This is how other users or clients will refer to you", children: _jsx(InputField, { ...fieldProps('name'), placeholder: "Please enter your name", type: "text" }) }), _jsx(ProfileSection, { label: "Email", children: _jsx(Input, { value: user.email, disabled: true, type: "text" }) }), _jsx(ProfileSection, { label: "Your photo", description: "This will be displayed for all users", children: _jsx(UserAvatar, { user: user, avatar: avatar, onFileUpload: onFileUpload, removeProfileAvatar: removeProfileAvatar }) })] })] }), _jsx("hr", { className: "text-greyscale-200 h-[1px]" }), _jsxs("div", { className: "flex flex-row-reverse gap-3 px-3 pb-2", children: [_jsx(Button, { color: "primary", disabled: !isActionsAvaliable, loading: isLoading, onClick: submit, children: "Save" }), _jsx(Button, { color: "grey", disabled: !isActionsAvaliable || isLoading, onClick: discard, children: "Discard changes" })] })] }));
};
const ProfileSection = ({ label, description, children, }) => (_jsxs("div", { className: "flex gap-5 border-b-greyscale-200 border-b last:border-b-0 py-3 px-1", children: [_jsxs("div", { className: "flex-none w-[280px]", children: [_jsx("h3", { className: "font-brand-t4m", children: label }), description && _jsx("p", { className: "font-brand-b2r text-greyscale-400", children: description })] }), _jsx("div", { className: "max-w-[560px] w-full", children: children })] }));
