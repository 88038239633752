import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { cx } from '~/common/utils';
import { IconBox } from '../IconContainers';
import { getButtonStyles, getIconStyles, getLinkStyles, getNewTabProps } from './utils';
const LinkBase = forwardRef(({ newTab, ...props }, ref) => {
    if (props.href === '' || props.to === '') {
        throw new Error(`Link must have either "to" or "href" prop as a non empty string. Check console for more info`);
    }
    if (props.href) {
        return _jsx("a", { ref: ref, ...props, ...getNewTabProps(newTab) });
    }
    return _jsx(RouterLink, { ref: ref, ...props, ...getNewTabProps(newTab) });
});
export const Link = forwardRef((props, ref) => {
    const { icon, children, external, className, ...restProps } = props;
    return (_jsxs(LinkBase, { ...restProps, className: cx(getLinkStyles(props), className), ref: ref, children: [icon && _jsx(IconBox, { size: "s", icon: icon, className: "text-inherit" }), children && _jsx("span", { className: "truncate", children: children }), external && _jsx(IconBox, { size: "s", icon: faExternalLink, className: "text-inherit" })] }));
});
const LinkButton = forwardRef((props, ref) => {
    const { icon, children, external, className, ...restProps } = props;
    return (_jsxs(LinkBase, { ...restProps, className: cx(getButtonStyles(props), className), ref: ref, children: [icon && _jsx(IconBox, { size: "s", icon: icon, className: "text-inherit" }), children && _jsx("span", { className: "truncate", children: children }), external && _jsx(IconBox, { size: "s", icon: faExternalLink, className: "text-inherit" })] }));
});
const LinkIcon = forwardRef((props, ref) => {
    const { icon, children, external, className, size = 's', iconSize, ...restProps } = props;
    return (_jsxs(LinkBase, { ...restProps, className: cx(getIconStyles(props), className), ref: ref, children: [icon && _jsx(IconBox, { className: "text-inherit", size: iconSize || size, icon: icon }), children && _jsx("span", { className: "truncate", children: children })] }));
});
Link.Button = LinkButton;
Link.Icon = LinkIcon;
Link.Base = LinkBase;
