import { useForm } from 'formoid';
import { useMemo } from 'react';
import { customValidator } from '~/common/utils';
import { changeStatusPayload, useInitData, useOrderData } from '~/orders/hooks';
export function useTransitionOpen(onSubmit) {
    const { managers, teams } = useInitData();
    const { pm, team } = useOrderData();
    const initialValues = useMemo(() => ({ designTeam: team ? team.id : null, pm }), [pm, team]);
    const { fieldProps, handleSubmit, isSubmitting, values } = useForm({
        initialValues,
        validationStrategy: 'onBlur',
        validators: () => ({
            pm: customValidator.required(),
            designTeam: customValidator.required(),
        }),
    });
    const submit = () => handleSubmit((values) => onSubmit(changeStatusPayload.open({
        pm: values.pm,
        design_team: { id: values.designTeam },
    })));
    return {
        fieldProps,
        handleSubmit: submit,
        isSubmitting,
        options: { managers, teams },
        values,
    };
}
