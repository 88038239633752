import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faArrowFromBottom } from '@fortawesome/pro-regular-svg-icons';
import { Link } from '~/common/components';
import { DateRangeFilter, Filter, getFilterProps, useParsedQuery, } from '~/common/kits/table';
import { useCustomerParams } from '~/customers/Customer';
import { getExportUrl } from './utils';
export const Header = ({ filters }) => {
    const { subscription_id } = useCustomerParams();
    const { queryParams, onFilterChange } = useParsedQuery();
    const filterProps = getFilterProps(filters, queryParams.filter, onFilterChange);
    return (_jsxs("div", { className: "flex justify-between items-center bg-greyscale-0 pl-3 p-2 border-b border-b-greyscale-200 border-solid", children: [_jsxs("div", { className: "flex", children: [_jsx(DateRangeFilter, {}), _jsx(Filter, { ...filterProps('payment_method'), placeholder: "All payment method", className: "ml-1 w-25" })] }), _jsx(Link.Icon, { color: "grey-outline", size: "m", iconSize: "s", icon: faArrowFromBottom, href: getExportUrl(subscription_id) })] }));
};
