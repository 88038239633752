import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Checkbox, ModalBody, ModalHeader } from '~/common/components';
import { toggleSet } from '~/common/utils';
import { FileItem } from '~/orders/Order';
import { DownloadButton } from '../DownloadButton';
export const Files = ({ files, onClose, orderId, tag }) => {
    const [selectedFilesIds, setSelectedFilesIds] = useState(new Set());
    const toggleSelected = toggleSet(setSelectedFilesIds);
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Files from customer" }), _jsxs(ModalBody, { className: "space-y-2 w-[712px]", children: [_jsxs("p", { className: "font-normal text-greyscale-400", children: [files.length, " uploaded files"] }), _jsx("div", { className: "space-y-1", children: files.map((file) => {
                            const checked = selectedFilesIds.has(file.id);
                            return (_jsx(FileItem, { ...file, children: _jsx(Checkbox, { color: "secondary", value: checked, onChange: () => toggleSelected(file.id) }) }, file.id));
                        }) }), _jsxs("div", { className: "flex justify-between flex-row-reverse", children: [_jsx(DownloadButton, { orderId: orderId, tag: tag, children: "Download all as ZIP" }), !!selectedFilesIds.size && (_jsx(DownloadButton, { tag: tag, orderId: orderId, selectedFiles: [...selectedFilesIds], children: "Download selected as ZIP" }))] })] })] }));
};
