import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { createContextPair } from '~/common/kits/context';
import { ModalCore } from './ModalCore';
const useContextData = () => {
    const [state, setState] = useState(null);
    const modalOpener = (component, props) => () => {
        // who cares about those anys if we can typecheck props against the component outside
        const next = { component, props };
        if (state) {
            setState(null);
            setTimeout(() => setState(next), 150);
        }
        else {
            setState(next);
        }
    };
    return {
        state,
        setState,
        modalOpener,
    };
};
const ModalSlot = () => {
    const { state, setState } = useModalContext();
    const onClose = () => {
        var _a, _b;
        setState(null);
        (_b = (_a = state === null || state === void 0 ? void 0 : state.props) === null || _a === void 0 ? void 0 : _a.onClose) === null || _b === void 0 ? void 0 : _b.call(_a);
    };
    return (_jsx(ModalCore, { className: "w-fit", show: !!state, close: onClose, children: state && _jsx(state.component, { ...state.props, onClose: onClose }) }));
};
const [, useModalContext, withModalContext] = createContextPair(useContextData, _jsx(ModalSlot, {}));
/**
 * Wrap root route node, e.g. Customer.tsx with `withModalContext` hoc
 * and enjoy using modalOpener like this:
 * modalOpener(EditIndividualPricing, { data: something, onClose: something() })
 */
export { useModalContext, withModalContext };
