import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useForm } from 'formoid';
import { useRef } from 'react';
import { ModalHeader, ModalShell, SelectField } from '~/common/components';
import { propagateBackendValidationErrors, record } from '~/common/utils';
import { useSuccessTeamMutation } from '~/customers/hooks';
export const EditSuccessTeamModal = ({ success_team, sales, onClose, }) => {
    var _a, _b, _c;
    const { mutateAsync } = useSuccessTeamMutation();
    const initialValues = useRef({
        success_manager_id: ((_a = success_team.success_manager) === null || _a === void 0 ? void 0 : _a.id) || null,
        business_partner_id: ((_b = success_team.business_partner) === null || _b === void 0 ? void 0 : _b.id) || null,
        account_manager_id: ((_c = success_team.account_manager) === null || _c === void 0 ? void 0 : _c.id) || null,
    });
    const { fieldProps, handleSubmit, setErrors, isSubmitting, values } = useForm({
        initialValues: initialValues.current,
        validationStrategy: 'onBlur',
        validators: () => ({
            success_manager_id: null,
            business_partner_id: null,
            account_manager_id: null,
        }),
    });
    const submit = () => handleSubmit((values) => {
        return mutateAsync(record.keys(values).reduce((successTeam, memberKey) => {
            if (values[memberKey] !== null) {
                successTeam[memberKey] = values[memberKey];
            }
            return successTeam;
        }, {}))
            .then(onClose)
            .catch((error) => {
            propagateBackendValidationErrors(error, setErrors);
        });
    });
    const isDirty = !record.shallowEqual(values, initialValues.current);
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { onClose: onClose, title: "Set sales and customer success managers" }), _jsxs(ModalShell, { className: "p-3 pb-5 space-y-3 w-[608px]", onClose: onClose, onSubmit: submit, loading: isSubmitting, disabled: !isDirty, children: [_jsx(SelectField, { ...fieldProps('business_partner_id'), title: "New business manager", options: sales, nullable: true, placeholder: "Choose new business manager" }), _jsx(SelectField, { ...fieldProps('success_manager_id'), title: "Customer success manager", options: sales, nullable: true, placeholder: "Choose customer success manager" }), _jsx(SelectField, { ...fieldProps('account_manager_id'), title: "Account manager", options: sales, nullable: true, placeholder: "Choose account manager" })] })] }));
};
