import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import { forwardRef, useRef } from 'react';
import { Button } from '~/common/components';
import { LexicalEditor, LexicalEditorField, ToolbarPlugin, getEditorStateJSON, } from '~/common/kits/editor';
import { useTeamSpaceMentions } from '../hooks';
export const useRichTextField = ({ onChange, onSubmit }) => {
    const editorStateRef = useRef(null);
    return {
        onSubmit: () => {
            const state = getEditorStateJSON(editorStateRef.current);
            if (state) {
                onSubmit(state);
            }
        },
        onChange: (state) => {
            editorStateRef.current = state;
            onChange === null || onChange === void 0 ? void 0 : onChange(state);
        },
    };
};
export const RichTextField = forwardRef((props, ref) => (_jsxs(LexicalEditor, { initialState: props.inititalState, onChange: props.onChange, placeholder: props.placeholder, editable: true, mentions: props.mentions, mentionsLookupHook: useTeamSpaceMentions, children: [_jsx(ToolbarPlugin, { children: props.children }), _jsxs("div", { ref: ref, className: "flex gap-1 border border-solid border-greyscale-200 focus-within:border-secondary-400 rounded p-1 font-brand-b1 transition-colors min-h-[81px]", children: [_jsx(LexicalEditorField, { className: "grow focus:outline-none overflow-y-auto overscroll-y-contain", onKeyDown: (e) => {
                        if (e.code === 'Enter' && e.ctrlKey) {
                            props.onSubmit();
                        }
                    } }), _jsx(Button.Icon, { color: "secondary", type: "submit", loading: props.isLoading, onClick: props.onSubmit, size: "m", icon: props.icon || faPaperPlane })] })] })));
