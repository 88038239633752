import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faCalendarDay } from '@fortawesome/pro-regular-svg-icons';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { formatDayFullMonthYear, getDateWithoutTime } from '~/common/utils';
import { IconBox } from '../IconContainers';
import { Errors, Input } from '../Input';
import { Popover } from '../Popover';
import { Datepicker } from './Datepicker';
export const DatepickerField = ({ errors, value, placeholder = 'Choose a date', 
// so you can set it as undefined above atleast :shrug:
minDate = new Date(), maxDate, onBlur, onChange, nullable, touched, ...inputProps }) => {
    return (_jsx(Popover, { onClose: onBlur, trigger: (props) => (_jsxs("div", { children: [_jsx(Input, { ...props, ...inputProps, placeholder: placeholder, icon: faCalendarDay, value: value ? formatDayFullMonthYear(value) : '', readOnly: true, error: !!errors, children: nullable && value !== null && (_jsx(IconBox, { size: "s", className: "cursor-pointer", icon: faTimes, onClick: (e) => {
                            e.preventDefault();
                            onChange(null);
                            onBlur();
                        } })) }), errors && _jsx(Errors, { errors: errors, color: true })] })), content: (props) => (_jsx(Datepicker, { minDate: minDate, maxDate: maxDate, value: value || getDateWithoutTime(), onChange: (date) => {
                onChange(date);
                props.onClose();
            } })) }));
};
