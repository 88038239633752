import { jsx as _jsx } from "react/jsx-runtime";
import { useHistory } from 'react-router-dom';
import { DeleteShell } from '~/common/components';
import { OFFICES_URL, useOfficeDeletion } from '../hooks';
export const DeletePrompt = ({ onClose }) => {
    const { mutateAsync, isLoading } = useOfficeDeletion();
    const history = useHistory();
    const goBack = () => history.push(OFFICES_URL);
    const handleDelete = () => mutateAsync().then(goBack);
    return (_jsx(DeleteShell, { className: "w-[496px]", loading: isLoading, heading: "Delete the office?", description: "You will not be able to recover it", submitText: "Yes, delete office", cancelText: "No, keep office", onSubmit: handleDelete, onClose: onClose }));
};
