import { createElement as _createElement } from "react";
import { useRef, useState } from 'react';
import { RichTextField, useRichTextField } from '../components';
import { usePersistentChatMessage } from '../hooks';
import { useSendMessage } from './hooks';
export const SendMessage = ({ scrollToBottom }) => {
    const ref = useRef(null);
    const [resetEditor, setResetEditor] = useState(0);
    const { state, onChange, onSuccess } = usePersistentChatMessage();
    const sendMessage = useSendMessage({
        onSuccess: () => {
            onSuccess();
            setResetEditor((n) => n + 1);
            // hack works :)
            setTimeout(scrollToBottom);
        },
    });
    const props = useRichTextField({
        onSubmit: (content) => {
            var _a;
            sendMessage.mutate({ content });
            (_a = ref.current) === null || _a === void 0 ? void 0 : _a.blur();
        },
        onChange,
    });
    return (_createElement(RichTextField, { ...props, key: resetEditor, ref: ref, inititalState: state, isLoading: sendMessage.isLoading, placeholder: "Leave a message...", mentions: true }));
};
