import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FeatureFlags } from '~/common/other';
import { useCustomerFeatureFlags } from '~/customers/hooks';
import { useCurrentUserData } from '~/root';
import { BillingAddress } from './BillingAddress';
import { BillingSettings } from './BillingSettings';
import { CustomerInfo } from './CustomerInfo';
import { CustomerLinks } from './CustomerLinks';
import { DedicatedTeam } from './DedicatedTeam';
import { IndividualPricing } from './IndividualPricing';
import { Organization } from './Organization';
import { PaymentMethods } from './PaymentMethods';
import { Stats } from './Stats';
import { Subscription } from './Subscription';
import { SuccessTeam } from './SuccessTeam';
import { TeamMembersBlock } from './TeamMembers';
export const General = () => {
    const { permissions } = useCurrentUserData();
    const featuresFlagsData = useCustomerFeatureFlags();
    return (_jsxs("div", { className: "grid grid-cols-3 gap-x-2", children: [_jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(CustomerInfo, {}), _jsx(CustomerLinks, {}), _jsx(FeatureFlags, { ...featuresFlagsData, canEdit: permissions.customersUpdate }), _jsx(Organization, {}), _jsx(TeamMembersBlock, {})] }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(Subscription, {}), _jsx(PaymentMethods, {}), _jsx(BillingAddress, {}), _jsx(BillingSettings, {})] }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx(Stats, {}), _jsx(SuccessTeam, {}), _jsx(DedicatedTeam, {}), _jsx(IndividualPricing, {})] })] }));
};
