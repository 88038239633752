import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { useCallback, useMemo } from 'react';
import { Collapse, IconBox } from '~/common/components';
import { cx } from '~/common/utils';
import { useSettings } from '~/root';
const sectionToTitle = {
    brief: 'Brief & The Details',
    customer: 'Customer',
    feedback: 'Customer feedback',
    files: 'Files',
    info: 'Order Information',
};
export const Section = ({ children, color = 'grey', type }) => {
    const sections = useSettings((store) => store.sections);
    const setSections = useSettings((store) => store.setSections);
    const isCollapsed = useMemo(() => sections.includes(type), [type, sections]);
    const onToggle = useCallback(() => {
        setSections((sections) => isCollapsed ? sections.filter((s) => s !== type) : [...sections, type]);
    }, [type, isCollapsed, setSections]);
    const styles = useMemo(() => {
        switch (color) {
            case 'grey':
                return {
                    container: 'bg-greyscale-0',
                    title: 'text-greyscale-500',
                    border: 'border-t-greyscale-200',
                };
            case 'gold':
                return {
                    container: 'bg-warning-50 border border-warning-100',
                    title: 'text-warning-500',
                    border: 'border-t-warning-100',
                };
        }
    }, [color]);
    return (_jsxs("div", { className: cx('rounded', styles.container), children: [_jsxs("button", { className: "flex items-center justify-between px-2 py-[12px] w-full", onClick: onToggle, children: [_jsx("h5", { className: cx('font-brand-t3 uppercase', styles.title), children: sectionToTitle[type] }), _jsx(IconBox, { size: "s", className: "text-secondary-300", icon: isCollapsed ? faChevronDown : faChevronUp })] }), _jsx(Collapse, { in: !isCollapsed, children: _jsx("div", { className: cx('border-t border-solid p-2', styles.border), children: children }) })] }));
};
