import { jsx as _jsx } from "react/jsx-runtime";
import { FloatingFocusManager, FloatingNode, FloatingOverlay, FloatingPortal, useClick, useDismiss, useFloating, useFloatingNodeId, useInteractions, useRole, useTransitionStatus, } from '@floating-ui/react';
import { MAX_TAB_INDEX } from '~/common/hooks';
import { cx } from '~/common/utils';
import { FloatingDiv, outsidePress, withFloatingTree } from '../Floating';
import styles from './ModalCore.module.scss';
export const ModalCore = withFloatingTree(({ show, close, children, onClose, className }) => {
    const nodeId = useFloatingNodeId();
    const { refs, context } = useFloating({
        nodeId,
        open: show,
        onOpenChange: (state) => {
            if (!state) {
                close === null || close === void 0 ? void 0 : close();
            }
        },
    });
    const { getFloatingProps } = useInteractions([
        useClick(context),
        useRole(context),
        useDismiss(context, { outsidePress }),
    ]);
    const { isMounted, status } = useTransitionStatus(context);
    if (!isMounted) {
        return null;
    }
    return (_jsx(FloatingNode, { id: nodeId, children: _jsx(FloatingPortal, { children: _jsx(FloatingOverlay, { lockScroll: true, className: styles.backdrop, "data-status": status, children: _jsx(FloatingFocusManager, { context: context, initialFocus: MAX_TAB_INDEX, children: _jsx(FloatingDiv, { ...getFloatingProps({
                            ref: refs.setFloating,
                            className: cx(styles.modal, className, 'focus-visible:outline-none'),
                        }), "data-status": status, onTransitionEnd: () => status === 'close' && (onClose === null || onClose === void 0 ? void 0 : onClose()), children: children }) }) }) }) }));
});
