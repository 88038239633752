import { addDays, addMonths, addWeeks } from 'date-fns';
export const moveDate = (date, period, forward = false) => {
    const multiply = (n) => (forward ? n : n * -1);
    switch (period) {
        case null:
            return addDays(date, multiply(1));
        case '1-week':
            return addWeeks(date, multiply(1));
        case '2-weeks':
            return addWeeks(date, multiply(2));
        case '1-month':
            return addMonths(date, multiply(1));
    }
};
export const periods = [
    { name: '1 week', value: '1-week' },
    { name: '2 weeks', value: '2-weeks' },
    { name: '1 month', value: '1-month' },
];
const periodNames = new Set(periods.map(({ value }) => value));
export const isValidPeriod = (period) => {
    return periodNames.has(period);
};
