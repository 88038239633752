import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { Collapse, IconButton } from '~/common/components';
import { useToggle } from '~/common/hooks';
import { cx } from '~/common/utils';
export const SubscriptionSection = ({ title, subtitle, className, collapsed, children, }) => {
    const [isCollapsed, toggleCollapsed] = useToggle(collapsed);
    const isCollapsable = collapsed !== undefined;
    return (_jsxs("section", { className: cx('pb-3 border-solid border-b border-greyscale-200', className), children: [_jsxs("div", { className: "flex gap-x-2 items-center", onClick: isCollapsable ? toggleCollapsed : undefined, children: [_jsx("h2", { className: "font-brand-t1m", children: title }), isCollapsable && _jsx(IconButton, { size: "m", icon: isCollapsed ? faChevronDown : faChevronUp })] }), subtitle && !isCollapsed && _jsx("p", { className: "font-brand-b1 mt-1", children: subtitle }), _jsx(Collapse, { in: !isCollapsed, className: "mt-3", children: children })] }));
};
