import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Toast } from '~/common/components';
import { httpClient, nonNullable } from '~/common/utils';
import { TeamMembersList } from '~/customers/domain';
import { qk } from '~/root/query-keys';
import { useCustomerParams } from '../Customer';
import { useInit } from './customers';
export function useTeamMembersList() {
    const { customer_id } = useCustomerParams();
    const init = useInit();
    return useQuery({
        enabled: !!init.data,
        queryKey: qk.customerMembers(customer_id),
        queryFn: () => {
            var _a, _b;
            return httpClient.get(`/v1/staff/customers/${customer_id}/members`, {
                decoder: TeamMembersList(nonNullable((_a = init.data) === null || _a === void 0 ? void 0 : _a.roles), nonNullable((_b = init.data) === null || _b === void 0 ? void 0 : _b.statuses)),
            });
        },
        keepPreviousData: true,
    });
}
export const useInviteTeamMembers = () => {
    const client = useQueryClient();
    const { customer_id } = useCustomerParams();
    return useMutation({
        mutationFn: (data) => httpClient.post(`/v1/staff/customers/${customer_id}/members/invite`, { data }),
        onSuccess: () => {
            client.invalidateQueries(qk.customer(customer_id));
        },
    });
};
export const useEditTeamMember = () => {
    const client = useQueryClient();
    const { customer_id } = useCustomerParams();
    return useMutation({
        mutationFn: ({ id: memberToEditId, email, role, }) => httpClient.patch(`/v1/staff/customers/${customer_id}/members/${memberToEditId}`, {
            data: { email, role },
        }),
        onSuccess: () => {
            client.invalidateQueries(qk.customerMembers(customer_id));
        },
    });
};
export const useDeleteTeamMembers = () => {
    const client = useQueryClient();
    const { customer_id } = useCustomerParams();
    return useMutation({
        mutationFn: (memberToRemoveId) => httpClient.delete(`/v1/staff/customers/${customer_id}/members/${memberToRemoveId}`),
        onSuccess: () => {
            client.invalidateQueries(qk.customerMembers(customer_id));
        },
    });
};
export const useResendInvitation = () => {
    const client = useQueryClient();
    const { customer_id } = useCustomerParams();
    return useMutation({
        mutationFn: (invitationId) => httpClient.post(`/v1/staff/customers/${customer_id}/members/invitations/${invitationId}/resend`),
        onSuccess: () => {
            client.invalidateQueries(qk.customerMembers(customer_id));
        },
    });
};
export const useCancelInvitation = () => {
    const client = useQueryClient();
    const { customer_id } = useCustomerParams();
    return useMutation({
        mutationFn: (invitationId) => httpClient.delete(`/v1/staff/customers/${customer_id}/members/invitations/${invitationId}`),
        onSuccess: () => {
            client.invalidateQueries(qk.customerMembers(customer_id));
            Toast.success({ title: 'Invitation was successfully cancelled' });
        },
    });
};
